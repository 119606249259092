// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_blueLinkText__9pr6S {\n  color: var(--blue1);\n  font-weight: 400;\n  font-size: 12px;\n  cursor: pointer;\n}", "",{"version":3,"sources":["webpack://./src/pages/ipd/create-ipd-main-layout/view-all-modal/style.module.scss"],"names":[],"mappings":"AAEA;EACI,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,eAAA;AADJ","sourcesContent":["@import '../../../../styles/mixin.scss';\n\n.blueLinkText {\n    color: var(--blue1);\n    font-weight: 400;\n    font-size: 12px;\n    cursor: pointer;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blueLinkText": "style_blueLinkText__9pr6S"
};
export default ___CSS_LOADER_EXPORT___;
