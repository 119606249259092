import {
  CONTACT_PERSON_NAME,
  HL7_BRANCH,
  HL7_EMAIL,
  HL7_NAME,
  HL7_PHONE,
  MESSAGE_LIST,
  MESSAGE_VARIENT,
} from '../constants/constant'
import { t } from 'i18next'

export const hl7FormValidators = {
  [HL7_NAME]: {
    required: t('SupplierMaster.name_validation'),
  },
  [HL7_BRANCH]: {
    required: t('OnlinePaymentValidator.SelectBranch'),
  },
  [MESSAGE_VARIENT]: {
    required: t('hl7.version_validation'),
  },
  [MESSAGE_LIST]: {
    required: t('hl7.message_validation'),
  },
  [CONTACT_PERSON_NAME]: {
    required: t('hl7.contact_person_placeholder'),
  },
  [HL7_PHONE]: {
    required: t('hl7.phone_validation'),
  },
  [HL7_EMAIL]: {
    required: t('ShareQuestionnaireValidators.SenderEmail'),
    pattern: {
      value: /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,4})+$/,
      message: t('ShareQuestionnaireValidators.SenderEmailRegex'),
    },
  },
}
