import axios from "axios";
import { IAPIPayload } from "../../../interfaces/apiInterface";
import {
  GET_ALL_IPD_BOOKING_PATIENT,
  GET_IPD_BOOK,
  GET_IPD_BOOK_DOCTORS,
  GET_IPD_AVAILABLE_BEDS,
  GET_IPD_ALL_BEDS,
  GET_IPD_BOOK_DROPDOWN_DATA,
  SUBMIT_IPD_BOOK_DATA,
  GET_ALL_BOOKED_BED_URL,
  IPD_CASE_SUMMARY,
} from "../../../config/config";

export const getAllIpdBookingPatient = (data: IAPIPayload) => {
  return axios.post(GET_ALL_IPD_BOOKING_PATIENT, data);
};

export const getIpdBook = (data: IAPIPayload) => {
  return axios.post(GET_IPD_BOOK, data);
};

export const getIpdBookDoctors = (data: IAPIPayload) => {
  return axios.post(GET_IPD_BOOK_DOCTORS, data);
};

export const getIpdAvailableBeds = (data: IAPIPayload) => {
  return axios.post(GET_IPD_AVAILABLE_BEDS, data);
};

export const getIpdAllBeds = (data: IAPIPayload) => {
  return axios.post(GET_IPD_ALL_BEDS, data);
};

export const getIpdBookDropdownData = (data: IAPIPayload) => {
  return axios.post(GET_IPD_BOOK_DROPDOWN_DATA, data);
};

export const updateIpdBookData = (data: IAPIPayload) => {
  return axios.post(SUBMIT_IPD_BOOK_DATA, data);
};

export const bookedBed = (data: IAPIPayload) => {
  return axios.post(GET_ALL_BOOKED_BED_URL, data);
};
// ipd case summary widgets
export const ipdCaseSummary = (data: IAPIPayload) => {
  return axios.post(IPD_CASE_SUMMARY, data);
};
