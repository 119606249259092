import { FC, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { RadiologyJobTabData } from "../../constants/data";
import Tabs from "../../components/core/tab/Tabs";
import { useAppDispatch } from "../../hooks";
import { getAllNotificationList } from "../../redux/features/app-notifications/appNotificationAsyncActions";
import { requestGenerator } from "../../utils/payloadGenerator";

interface IJobLayout {}

const RadiologyJobLayout: FC<IJobLayout> = (props) => {
  const navigate = useNavigate();
  const location = useLocation().pathname;
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (location === "/radiology-job") {
      navigate("createjobs");
    }
  }, [location]);

  useEffect(() => {
    dispatch(getAllNotificationList(requestGenerator({})));
  }, []);

  return (
    <>
      <Tabs tabData={RadiologyJobTabData} />
      <Outlet />
    </>
  );
};
export default RadiologyJobLayout;
