// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".attachSingleFileV2_changeButtonStyle__pjlgR {\n  width: 100px;\n  height: 42px;\n  margin: 60px 0px 78px 30px;\n}", "",{"version":3,"sources":["webpack://./src/components/common/attach-files/single-file-V2/attachSingleFileV2.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,YAAA;EACA,0BAAA;AACF","sourcesContent":[".changeButtonStyle {\n  width: 100px;\n  height: 42px;\n  margin: 60px 0px 78px 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"changeButtonStyle": "attachSingleFileV2_changeButtonStyle__pjlgR"
};
export default ___CSS_LOADER_EXPORT___;
