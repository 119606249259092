// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* ChatLoader.module.scss */\n.chatloader_chatLoaderContainer__gbVd2 {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 10px; /* Adjust as needed */\n  background-color: #f7f7f7;\n  border-top: 1px solid #ddd;\n  border-bottom: 1px solid #ddd;\n  /* Add more styles as needed */\n  margin: 200px auto;\n  width: 55px;\n  height: 55px;\n  border: 10px solid var(--white1);\n  border-top: 10px solid var(--grey4);\n  border-radius: 50%;\n  animation: chatloader_spinner__wHWCQ 0.9s linear infinite;\n}\n@keyframes chatloader_spinner__wHWCQ {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/common/chat-loader/chatloader.module.scss"],"names":[],"mappings":"AAAA,2BAAA;AACA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA,EAAA,qBAAA;EACA,yBAAA;EACA,0BAAA;EACA,6BAAA;EACA,8BAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,gCAAA;EACA,mCAAA;EACA,kBAAA;EACA,yDAAA;AACF;AAAE;EACE;IACE,uBAAA;EAEJ;EAAE;IACE,yBAAA;EAEJ;AACF","sourcesContent":["/* ChatLoader.module.scss */\n.chatLoaderContainer {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 10px; /* Adjust as needed */\n  background-color: #f7f7f7;\n  border-top: 1px solid #ddd;\n  border-bottom: 1px solid #ddd;\n  /* Add more styles as needed */\n  margin:  200px auto;\n  width: 55px;\n  height: 55px;\n  border: 10px solid var(--white1);\n  border-top: 10px solid var(--grey4);\n  border-radius: 50%;\n  animation: spinner 0.9s linear infinite;\n  @keyframes spinner {\n    0% {\n      transform: rotate(0deg);\n    }\n    100% {\n      transform: rotate(360deg);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chatLoaderContainer": "chatloader_chatLoaderContainer__gbVd2",
	"spinner": "chatloader_spinner__wHWCQ"
};
export default ___CSS_LOADER_EXPORT___;
