import {
  ADD_WAAPI_TEMPLATE,
  CHECK_WHATSUP_CONFIG,
  GET_WAAPI_TEMPLATE,
  GET_WHATSUP_DETAILS,
  UPDATE_WHATSUP_CONFIG,
} from "../../../constants/asyncActionsType";
import createAsyncThunkForSlice from "../../../utils/utils";
import {
  checkWhatsUpConfigurations,
  createWaapiTemplate,
  geteWaapiTemplate,
  getWhatsUpConfigurations,
  updateWhatsUpConfigurations,
} from "./whatsupCrud";

export const checkWhatsupConfigurationsDetails = createAsyncThunkForSlice(
  CHECK_WHATSUP_CONFIG,
  checkWhatsUpConfigurations,
  {
    isToast: true,
  }
);

export const updateWhatsUpConfigurationsDetails = createAsyncThunkForSlice(
  UPDATE_WHATSUP_CONFIG,
  updateWhatsUpConfigurations,
  {
    isToast: true,
  }
);

export const getWhatsupDetails = createAsyncThunkForSlice(
  GET_WHATSUP_DETAILS,
  getWhatsUpConfigurations,
);

export const getWaapiTemplates = createAsyncThunkForSlice(
  GET_WAAPI_TEMPLATE,
  geteWaapiTemplate,
);
export const createWappiTemplates = createAsyncThunkForSlice(
  ADD_WAAPI_TEMPLATE,
  createWaapiTemplate,
  {
    isToast: true,
  }
);