import LandingPage from "../../components/common/landing-page/LandingPage";
import Login from "../../pages/login/Login";
import ForgotPassword from "../../pages/login/forgot-password/ForgotPassword";
import OtpVerfication from "../../pages/login/otp-verfication/OtpVerfication";
import RecoveryPassword from "../../pages/login/recovery-password/RecoveryPassword";
import SubmitQuestionnaireLayout from "../../pages/submit-questionnaire/submit-questionnaire-layout/SubmitQuestionnaireLayout";
import SubmitQuestionnaireForm from "../../pages/submit-questionnaire/submit-questionnaire-form/SubmitQuestionnaireForm";
import QuestionnaireSubmit from "../../pages/submit-questionnaire/questionnaire-submit/QuestionnaireSubmit";
import SubmitOtp from "../../pages/submit-otp/SubmitOtp";
import AuthWrapper from "../../components/app/routes/AuthWrapper";
import MainLayout from "../../pages/main-layout/MainLayout";
import MobileAppConfigLayout from "../../pages/mobileapp-configuraion/MobileAppConfigLayout";
import MobileAppAppointment from "../../pages/mobileapp-configuraion/mobile-app-appointment/MobileAppAppointment";
import MobileAppNews from "../../pages/mobileapp-configuraion/mobile-app-news/MobileAppNews";
import ManageUsersLayout from "../../pages/manage-users/ManageUsersLayout";
import ManageUsers from "../../pages/manage-users/manage-user-grid/ManageUsers";
import CreateUsers from "../../pages/manage-users/create-user/CreateUsers";
import CreateUserPrimary from "../../pages/manage-users/create-user/create-user-primary/CreateUserPrimary";
import CreateUserSecondary from "../../pages/manage-users/create-user/create-user-secondary/CreateUserSecondary";
import ManageUserGroupsLayout from "../../pages/manage-usergroups/ManageUserGroupsLayout";
import UserGroupList from "../../pages/manage-usergroups/usergrouplist/UserGroupList";
import ManageUserGroup from "../../pages/manage-usergroups/usergrouplist/manage-usergroup/ManageUsergroup";
import PrimaryUserGroup from "../../pages/manage-usergroups/usergrouplist/manage-usergroup/primary/PrimaryUsergroup";
import SecondaryUsergroup from "../../pages/manage-usergroups/usergrouplist/manage-usergroup/secondary/SecondaryUsergroup";
import DashboardLayout from "../../pages/dashboard/DashboardLayout";
import MedicalCenterBranch from "../../pages/dashboard/branch/MedicalCenterBranch";
import BranchList from "../../pages/dashboard/branch/branch-list/BranchList";
import ManageBranch from "../../pages/dashboard/branch/manage-branch/ManageBranch";
import MedicalCenterDepartment from "../../pages/dashboard/department/MedicalCenterDepartment";
import DepartmentList from "../../pages/dashboard/department/department-list/DepartmentList";
import ManageDepartment from "../../pages/dashboard/department/manage-department/ManageDepartment";
import NotFound from "../../components/common/not-found/NotFound";
import PatientEmrLayout from "../../pages/patient-emr/PatientEmrLayout";
import PatientEmrDashboard from "../../pages/patient-emr/patient-emr-dashboard/PatientEmrDashboard";
import ShareQuestionEmail from "../../pages/patient-emr/share-question-email/ShareQuestionEmail";
import MainSchedular from "../../pages/appointment/SchedularMainLayout";
import BookingSchedularFunctional from "../../pages/appointment/BookingFunctional";
import Receptionist from "../../pages/receptionist/Receptionist";
import InvoiceLayout from "../../pages/invoice/InvoiceLayout";
import InvoiceInformation from "../../pages/invoice/invoice-information/InvoiceInformation";
import InvoiceServices from "../../pages/invoice/invoice-services/InvoiceServices";
import InvoicePayment from "../../pages/invoice/invoice-payment/InvoicePayment";
import ReceiptLayout from "../../pages/receipt/ReceiptLayout";
import Receipt from "../../pages/receipt/receipt-landing-page/Receipt";
import CreateNewFormsLayout from "../../pages/create-new-forms/CreateNewFormsLayout";
import CreateNewFormList from "../../pages/create-new-forms/create-new-form-list/CreateNewFormList";
import CreateNewFormBuilder from "../../pages/create-new-forms/create-new-form-builder/CreateNewFormBuilder";
import Invoice from "../../pages/doctor-diagnosis/invoice/Invoice";
import TreatmentPlanList from "../../pages/doctor-diagnosis/treatment/treatment-plan-list/TreatmentPlanList";
import Referral from "../../pages/doctor-diagnosis/referal/Referral";
import Request from "../../pages/doctor-diagnosis/request/Request";
import Medication from "../../pages/doctor-diagnosis/medication/Medication";
import DiagnosisForm from "../../pages/doctor-diagnosis/diagnosis/diagnosis-form/DiagnosisForm";
import DoctorDiagnosisLayout from "../../pages/doctor-diagnosis/DoctorDiagnosisLayout";
import DoctorAppointmentDashboard from "../../pages/doctor-dashboard/doctor-appointments/DoctorAppointmentDashboard";
import LabRequestLayout from "../../pages/lab-request/LabRequestLayout";
import LabRequest from "../../pages/lab-request/labRequest/LabRequest";
import LabInvoiceLayout from "../../pages/lab-invoice/LabInvoiceLayout";
import LabInformation from "../../pages/lab-invoice/lab-information/LabInformation";
import LabServices from "../../pages/lab-invoice/lab-services/LabServices";
import Configuration from "../../pages/configuration/Configuration";
import JobLayout from "../../pages/job/JobLayout";
import CreateJobs from "../../pages/job/create-jobs/CreateJobs";
import ViewJobs from "../../pages/job/view-jobs/ViewJobs";
import RadiologyJobLayout from "../../pages/radiology-jobs/JobLayout";
import RadioLogyRequestLayout from "../../pages/radiology-request/RadioLogyRequestLayout";
import RadiologyInvoiceLayout from "../../pages/radiology-invoice/RadiologyInvoiceLayout";
import RadiologyConfiguration from "../../pages/radiology-configuration/RadiologyConfiguration";
import RadiologyInvoicePayment from "../../pages/radiology-invoice/radiology-invoice-payment/RadiologyInvoicePayment";
import RadiologyInvoiceService from "../../pages/radiology-invoice/radiology-invoice-service/RadiologyInvoiceService";
import RadiologyInvoiceInformation from "../../pages/radiology-invoice/radiology-invoice-information/RadiologyInvoiceInformation";
import RadiologyRequest from "../../pages/radiology-request/radioLogyRequest/RadioLogyRequest";
import RadiologyCreateJobs from "../../pages/radiology-jobs/create-jobs/CreateJobs";
import RadiologyViewJobs from "../../pages/radiology-jobs/view-jobs/ViewJobs";
import ManageMasterTableNewtLayout from "../../pages/manageMasterTable-New/ManageMasterTableNewLayout";
import InsuranceMaster from "../../pages/insurance-master/InsuranceMaster";
import OnGoingClaims from "../../pages/insurance/ongoing-claims/onGoingClaims";
import Services from "../../pages/PatientEmrServices/service-landing-page/Service";
import ManageMasterNew from "../../pages/manageMasterTable-New/manageMasterNew/ManageMasterNew";
import ManageAppoinmentStatus from "../../pages/manageMasterTable-New/manageAppoinmentStatus/ManageAppoinmentStatus";
import ManageCategoryValues from "../../pages/manageMasterTable-New/MasterCategoryValues/ManageCategoryValues";
import ManageAssignTag from "../../pages/manageMasterTable-New/manageAssignTag/ManageAssignTag";
import LabPayment from "../../pages/lab-invoice/lab-payment/LabPayment";
import RequestLayout from "../../pages/request/RequestLayout";
import BranchStoreLayout from "../../pages/branchstore/BranchStoreLayout";
import BranchStore from "../../pages/branchstore/branchstore/BranchStore";
import MainStoreLayout from "../../pages/mainstore/MainStoreLayout";
import MainStore from "../../pages/mainstore/mainstore/MainStore";
import PurchaseInvoiceLayout from "../../pages/purchase-invoice/PurchaseInvoiceLayout";
import PurchaseInvoice from "../../pages/purchase-invoice/purchase-invoice/PurchaseInvoice";
import InventoryRequest from "../../pages/request/request/Request";
import DentistAppointmentDashboard from "../../pages/dentist-dashboard/dentist-appointments/DentistAppointmentDashboard";
import DentistDiagnosisLayout from "../../pages/dentist-diagnosis/DentistDiagnosisLayout";
import IPD from "../../pages/ipd/Ipd";
import IpdDashboard from "../../pages/ipd/ipd-dashboard/IpdDashboard";
import CreateIpdMainLayout from "../../pages/ipd/create-ipd-main-layout/CreateIpdMainLayout";
import IpdInvoiceLayout from "../../pages/ipd-invoice/IpdInvoiceLayout";
import PatientIPDInfo from "../../pages/ipd-invoice/patient-ipd-info/PatientIPDInfo";
import PatientConsumble from "../../pages/ipd-invoice/patient-consumable/PatientConsumble";
import PatientCharges from "../../pages/ipd-invoice/patient-charges/PatientCharges";
import PatientIPDServices from "../../pages/ipd-invoice/patient-services/PatientIPDServices";
import Consultation from "../../pages/ipd/create-ipd-main-layout/ipd-timeline/consultation/Consultation";
import DentalDiagnosisForm from "../../pages/dentist-diagnosis/diagnosis/diagnosis-form/DiagnosisForm";
import DentalMedication from "../../pages/dentist-diagnosis/medication/Medication";
import DentalRequest from "../../pages/dentist-diagnosis/request/Request";
import DentalReferral from "../../pages/dentist-diagnosis/referal/Referral";
import DentalTreatmentPlanList from "../../pages/dentist-diagnosis/treatment/treatment-plan-list/TreatmentPlanList";
import UnitTypeMainLayout from "../../pages/unit-type-master/UnitTypeMainLayout";
import MedicalCenterWard from "../../pages/dashboard/ward/MedicalCenterWard";
import WardList from "../../pages/dashboard/ward/ward-list/WardList";
import ManageWard from "../../pages/dashboard/ward/manage-ward/ManageWard";
import MedicalCenterRoom from "../../pages/dashboard/room/MedicalCenterRoom";
import ManageRoom from "../../pages/dashboard/room/manage-room/ManageRoom";
import RoomList from "../../pages/dashboard/room/room-list/RoomList";
import MedicalCenterBed from "../../pages/dashboard/bed/MedicalCenterBed";
import ManageBed from "../../pages/dashboard/bed/manage-bed/ManageBed";
import BedList from "../../pages/dashboard/bed/bed-list/BedList";
import ManageInventoryUnitType from "../../pages/manageMasterTable-New/manageInventoryUnitType/ManageInventoryUnitType";
import BedSchedular from "../../pages/appointment/bed/BedSchedular";
import PatientPayment from "../../pages/ipd-invoice/patient-payment/PatientPayment";
import InventoryMasterTableLayout from "../../pages/admin-inventory-master/InventoryMasterTableLayout";
import InventoryMasterTable from "../../pages/admin-inventory-master/InventoryMasterTable";
import InventoryItemTableLayout from "../../pages/inventory-item-master/InventoryItemMasterTableLayout";
import InventoryItemTable from "../../pages/inventory-item-master/InventoryItemMasterTable";

import AddNewSuplier from "../../pages/admin-inventory-master/add-new-supplier/AddNewSuplier";
import AddNewItem from "../../pages/inventory-item-master/add-new-item/AddNewItem";
import IpdCreateForm from "../../pages/ipd/create-ipd-main-layout/ipd-create-form/IpdCreateForm";
import ReportBuilder from "../../pages/report-builder/ReportBuilder";
import CallCenterDashboardLayout from "../../pages/call-center-admin/admin/CallCenterLayout";
import Call from "../../pages/call-center-admin/admin/admin/CallCenterDashboard";
import LeadLayout from "../../pages/call-center-admin/lead/LeadLayout";
import Internal from "../../pages/call-center-admin/lead/internal/Internal";
import External from "../../pages/call-center-admin/lead/external/External";
import AgentsLayout from "../../pages/call-center-admin/agents/AgentsLayout";

import CampaignsLayout from "../../pages/call-center-admin/campaign/CampaignsLayout";
import CreateCampaigns from "../../pages/call-center-admin/campaign/create-campaigns/CreateCampaigns";
import OnGoingCampaigns from "../../pages/call-center-admin/campaign/on-going-campaigns/OnGoingCampaigns";
import Agents from "../../pages/call-center-admin/agents/agents/Agents";
import Leads from "../../pages/call-center-admin/lead/total-leads/Leads";
import AgentDashboardLayout from "../../pages/agent/AgentDashboardLayout";

import { MyRoutes } from "../../interfaces/interfaces";
import AgentLeads from "../../pages/agent/agent-info-table/agent-leads/page";
import FollowUp from "../../pages/agent/agent-info-table/follow-up/page";
import {
  ACCOUNT_DASHBOARD,
  ATTENDANCE,
  BRANCH_STORE,
  CC_ADMIN_DASHBOARD,
  CC_AGENT,
  CC_AGENT_DASHBOARD,
  CC_CAMPAIGN,
  CC_LEAD_POOL,
  CRM_ADMIN_KEY,
  DC_DASHBOARD,
  DC_DIAGNOSIS,
  DNT_DASHBOARD,
  DNT_DIAGNOSIS,
  DOCUMENTS,
  DYNAMIC_TEMPLATE,
  EMP_MANAGEMENT,
  FORM_BUILDER,
  HR_DASHBOARD,
  IMPORT_UTILITY,
  INS_MASTERS,
  INVENTORY_ITEM_MASTERS,
  INVENTORY_MASTER_TABLE,
  INVENTORY_REQUEST,
  INVENTORY_UNIT_TYPE_MAP,
  INVOICE,
  IPD_CREATE_CASE,
  IPD_ID,
  IPD_INVOICE,
  LAB_CONFIGURATION,
  LAB_INVOICE,
  LAB_JOB,
  LAB_REQUEST,
  LEAVE_MANAGEMENT,
  MAIN_STORE,
  MANAGE_MASTER_TABLE,
  MANAGE_STAFF,
  MANAGE_USER_ROLE,
  MC_DASHBOARD,
  MOB_APP_CONFIGURATION,
  ONGING_CLAIMS,
  PATIENT_EMR,
  PAYROLL,
  PHARMACY_MAIN_STORE,
  PHARMACY_POS,
  PHARMACY_STORE,
  PURCHASE_INVOICE,
  RADIOLOGY_CONFIGURAION,
  RADIOLOGY_INVOICE,
  RADIOLOGY_JOB,
  RADIOLOGY_REQUEST,
  RECEIPT,
  RECEP_DASHBOARD,
  REPORTS,
  REPORT_BUILDER,
  REPORT_GENERATOR,
  REPORT_MAPPING,
  SCHEDULAR,
  SER_MASTERS,
  UNAVAILABILITY,
  UPAY_CONFIG,
  VIEW_CHART_OF_ACCOUNTS,
  VOUCHERS,
} from "../../config/modules";
import CrmAdminLayout from "../../pages/crm-admin/page";
import CrmNew from "../../pages/crm-admin/crm-new-tab/page";
import CrmSent from "../../pages/crm-admin/crm-sent-tab/page";
import PharmacyLayout from "../../pages/pharmacy/PharmacyLayout";
import PharmacyInfoForm from "../../pages/pharmacy/pharmacy-info/PharmacyInfoForm";
import PharmacyStore from "../../pages/pharmacy/pharmacy-store/PharmacyStore";
import PharmacyPaymentNew from "../../pages/pharmacy/pharmacy-payment-new/PharmacyPaymentNew";
import PharmacyOnhold from "../../pages/pharmacy/pharmacy-onhold/PharmacyOnhold";
import ManageSpecialitiesNew from "../../pages/dashboard/department/manage-specialities/manageSpecialityNew";
import ErrorBoundaryHoc from "../../components/hoc/error-boundary/ErrorBoundaryHoc";
import DynamicFormLayout from "../../pages/dynamic-form-layout/DynamicFormLayout";
import DynamicFormSubmit from "../../pages/dynamic-form-layout/dynamic-form-submit/DynamicFormSubmit";
import ReportMapping from "../../pages/report-mapping/ReportMapping";
import ReportBuilderLayout from "../../pages/reports-poc/ReportBuilderLayout";
import ReportBuilderList from "../../pages/reports-poc/ReportBuilderList";
import CreateReport from "../../pages/reports-poc/CreateReport";
import PrivacyPolicy from "../../pages/login/privacy-policy/PrivacyPolicy";
import HRDashboardLayout from "../../pages/hr-dashboard/HRDashboardLayout";
import HrDashboard from "../../pages/hr-dashboard/dashboard/HrDashboard";
import Departments from "../../pages/hr-dashboard/departments/Departments";
import EmployeesLayout from "../../pages/employees/EmployeesLayout";
import Employees from "../../pages/employees/employees/Employees";
import AddEmployee from "../../pages/employees/add-employee/AddEmployee";
import DocumentsLayout from "../../pages/documents/DocumnetsLayout";
import Documnets from "../../pages/documents/documnets/Documents";
import AddDocument from "../../pages/documents/add-new/AddDocument";
import PayrollLayout from "../../pages/payroll/PayrollLayout";
import Payroll from "../../pages/payroll/payroll/Payroll";
import Create from "../../pages/payroll/creare-salary-slip/Create-salary";
import EndofServices from "../../pages/payroll/end-of-services/End-of-services";
import New from "../../pages/payroll/add-new/Add-new";
import AttendaceLayout from "../../pages/attendance/AttendanceLayout";
import ManageAttendance from "../../pages/attendance/manage-attendance/ManageAttendance";
import LeaveManagementLayout from "../../pages/leave-management/LeaveManagementLayout";
import LeaveManagement from "../../pages/leave-management/leave-management/LeaveManagement";
import LeaveEncashment from "../../pages/leave-management/leave-encashment/LeaveEncashment";
import LeaveFormData from "../../pages/leave-management/leave-encashment/leave-form-data/LeaveFormData";
import ApplyLeave from "../../pages/leave-management/apply-leave/ApplyLeave";
import AccountDashboardLayout from "../../pages/account-dashboard/dashboard/DashboardLayout";
import Dashboard from "../../pages/account-dashboard/dashboard/dashboard/Dashboard";
import ViewChartOfAccountsLayout from "../../pages/view-chart-of-accounts/ViewChartOfAccountsLayout";
import Level2List from "../../pages/view-chart-of-accounts/level2/Level2List";
import Level3List from "../../pages/view-chart-of-accounts/level3/Level3List";
import Level4List from "../../pages/view-chart-of-accounts/level4/Level4List";
import Level5List from "../../pages/view-chart-of-accounts/level5/Level5List";
import VouchersLayout from "../../pages/vouchers/VouchersLayout";
import Vouchers from "../../pages/vouchers/vouchers/Vouchers";
import ReportsLayout from "../../pages/reports/ReportsLayout";
import Ledger from "../../pages/reports/ledger/Ledger";
import TrialBalance from "../../pages/reports/trial-balance/TrialBalance";
import ProfitLoss from "../../pages/reports/profit-loss/ProfitLoss";
import BalanceSheet from "../../pages/reports/balance-sheet/BalanceSheet";
import ContactUS from "../../pages/login/contact-us/ContactUs";
import ManagePaymentModes from "../../pages/manageMasterTable-New/managePaymentmodes/ManagePaymentModes";
import RoomSchedular from "../../pages/appointment/room/RoomSchedular";
import UnavailabilityLayout from "../../pages/unavailability/UnavailabilityLayout";
import DoctorUnavailablity from "../../pages/unavailability/doctor/DoctorUnavailablity";
import UpayConfiguration from "../../pages/upay/UpayConfiguration";
import ImportUtility from "../../pages/import-utility/ImportUtility";
import DynamicTemplateLayout from "../../pages/dynamic_template/DynamicTemplateLayout";
import DynamicTemplate from "../../pages/dynamic_template/dynamic_template_grid/DynamicTemplateGrid";
import ManageDynamicTemplate from "../../pages/dynamic_template/manage-dynamic-template/ManageDynamicTemplate";
import StaffPrivacyPolicy from "../../pages/login/privacy-policy/StaffPrivacyPolicy";
import Configurations from "../../pages/configurations/Configurations";
import Whatsup from "../../pages/configurations/whatsup/Whatsup";
import CurrencyDetails from "../../pages/configurations/configurationsDetails/ConfigurationDetails";
import ConfigurationDetails from "../../pages/configurations/configurationsDetails/ConfigurationDetails";

export const commonRoutes: any[] = [
  {
    path: "/",
    element: (
      <ErrorBoundaryHoc errorMessage="Something Went Wrong...">
        <LandingPage />
      </ErrorBoundaryHoc>
    ),
    children: [
      {
        path: "",
        element: <Login />,
        children: [],
        header: "",
        location: "/",
      },
      {
        path: "forgotpassword",
        element: <ForgotPassword />,
        children: [],
        header: "",
        location: "/forgotpassword",
      },
      {
        path: "otpverfication",
        element: <OtpVerfication />,
        children: [],
        header: "",
        location: "/otpverfication",
      },
      {
        path: "resetpassword",
        element: <RecoveryPassword />,
        children: [],
        header: "",
        location: "/resetpassword",
      },
      {
        path: "contactus",
        element: <ContactUS />,
        children: [],
        header: "",
        location: "/contactus",
      },
    ],
    header: "",
    location: "/",
  },
  {
    path: "submitquestionnairelayout",
    element: (
      <ErrorBoundaryHoc errorMessage="Something Went Wrong...">
        <SubmitQuestionnaireLayout />
      </ErrorBoundaryHoc>
    ),
    children: [
      {
        path: "",
        element: <SubmitQuestionnaireForm />,
        children: [],
        header: "",
        location: "/submitquestionnairelayout",
      },
      {
        path: "questionnairesubmit",
        element: <QuestionnaireSubmit />,
        children: [],
        header: "",
        location: "/submitquestionnairelayout/questionnairesubmit",
      },
    ],
    header: "",
    location: "/submitquestionnairelayout",
  },
  {
    path: "dynamicformsubmitLayout",
    element: <DynamicFormLayout />,
    children: [
      {
        path: "",
        element: <DynamicFormSubmit />,
        children: [],
        header: "",
        location: "/dynamicformsubmitLayout",
      },
    ],
    header: "",
    location: "/dynamicformsubmitLayout",
  },
  {
    path: "submitotp",
    element: (
      <ErrorBoundaryHoc errorMessage="Something Went Wrong...">
        <SubmitOtp />
      </ErrorBoundaryHoc>
    ),
    children: [],
    header: "",
    location: "/submitotp",
  },
  {
    path: "privacy-policy",
    element: <PrivacyPolicy />,
    children: [],
    header: "",
    location: "/privacy-policy",
  },
  {
    path: "privacy-policy-healthbox-staff",
    element: <StaffPrivacyPolicy />,
    children: [],
    header: "",
    location: "/privacy-policy-healthbox-staff",
  },
];

export const authRoutes: any[] = [
  {
    path: "/*",
    element: (
      <AuthWrapper>
        <MainLayout />
      </AuthWrapper>
    ),
    children: [
      // mc admin routes
      {
        id: MOB_APP_CONFIGURATION,
        path: "mobileappconfiguration/*",
        element: <MobileAppConfigLayout />,
        children: [
          {
            path: "appointment",
            element: (
              <ErrorBoundaryHoc errorMessage="Something Went Wrong...">
                <MobileAppAppointment />
              </ErrorBoundaryHoc>
            ),
            children: [],
            header: "Mobile App Configurations",
            location: "/mobileappconfiguration/appointment",
            roles: ["MC Admin"],
          },
          {
            path: "news",
            element: (
              <ErrorBoundaryHoc errorMessage="Something Went Wrong...">
                <MobileAppNews />
              </ErrorBoundaryHoc>
            ),
            children: [],
            header: "Mobile App Configurations",
            location: "/mobileappconfiguration/news",
            roles: ["MC Admin"],
          },
        ],
        header: "Mobile App Configurations",
        location: "/mobileappconfiguration",
        roles: ["MC Admin"],
      },
      {
        id: MANAGE_STAFF,
        path: "manageusers/*",
        element: (
          <ErrorBoundaryHoc errorMessage="Something Went Wrong...">
            <ManageUsersLayout />
          </ErrorBoundaryHoc>
        ),
        children: [
          {
            path: "",
            element: <ManageUsers />,
            children: [],
            header: "Manage Staff",
            location: "/manageusers",
            roles: ["MC Admin"],
          },
          {
            path: "createusers/*",
            element: <CreateUsers />,
            children: [
              {
                path: "primary",
                element: <CreateUserPrimary />,
                children: [],
                header: "Manage Staff",
                location: "/manageusers/createusers/primary",
                roles: ["Super Admin"],
              },
              {
                path: "secondary",
                element: <CreateUserSecondary />,
                children: [],
                header: "Manage Staff",
                location: "/manageusers/createusers/secondary",
                roles: ["Super Admin"],
              },
            ],
            header: "Add Medical Center Users",
            location: "/manageusers/createusers",
            roles: ["Super Admin"],
          },
        ],
        header: "Manage Staff",
        location: "/manageusers",
        roles: ["MC Admin"],
      },

      {
        id: MANAGE_MASTER_TABLE,
        path: "mastertablemanagenew/*",
        element: (
          <ErrorBoundaryHoc errorMessage="Something Went Wrong...">
            <ManageMasterTableNewtLayout />
          </ErrorBoundaryHoc>
        ),
        children: [
          {
            path: "",
            element: <ManageMasterTableNewtLayout />,
            children: [],
            header: "Manage Master Tables - Manage Category",
            location: "/mastertablemanagenew",
            roles: ["MC Admin"],
          },
          {
            path: "managemasternew",
            element: <ManageMasterNew />,
            children: [],
            header: "Manage Master Tables - Manage Category",
            location: "/mastertablemanagenew/managemasternew",
            roles: ["MC Admin"],
          },
          {
            path: "manageappoinmentstatus",
            element: <ManageAppoinmentStatus />,
            children: [],
            header: "Manage Master Tables - Appoinment Status",
            location: "/mastertablemanagenew/manageappoinmentstatus",
            roles: ["MC Admin"],
          },
          {
            path: "managecategoryvalues",
            element: <ManageCategoryValues />,
            children: [],
            header: "Manage Master Tables - Manage Category Value",
            location: "/mastertablemanagenew/managecategoryvalues",
            roles: ["MC Admin"],
          },
          {
            path: "manageassigntag",
            element: <ManageAssignTag />,
            children: [],
            header: "Manage Master Tables - Manage Assign Tag",
            location: "/mastertablemanagenew/manageassigntag",
            roles: ["MC Admin"],
          },
          {
            path: "manageinventorytype",
            element: <ManageInventoryUnitType />,
            children: [],
            header: "Manage Master Tables - Manage Inventory Type",
            location: "/mastertablemanagenew/manageinventorytype",
            roles: ["MC Admin"],
          },
          {
            path: "managepaymentmode",
            element: <ManagePaymentModes />,
            children: [],
            header: "Manage Master Tables - Manage Payment Modes",
            location: "/mastertablemanagenew/managepaymentmode",
            roles: ["MC Admin"],
          },
        ],
        header: "Manage Master Tables",
        location: "/mastertablemanage",
        roles: ["MC Admin"],
      },
      {
        id: MANAGE_USER_ROLE,
        path: "usergroups/*",
        element: <ManageUserGroupsLayout />,
        children: [
          {
            path: "",
            element: (
              <ErrorBoundaryHoc errorMessage="Something Went Wrong...">
                <UserGroupList />
              </ErrorBoundaryHoc>
            ),
            children: [],
            header: "Manage User Roles",
            location: "/usergroups",
            roles: ["MC Admin"],
          },
          {
            path: "manageusergroups/*",
            element: (
              <ErrorBoundaryHoc errorMessage="Something Went Wrong...">
                <ManageUserGroup />
              </ErrorBoundaryHoc>
            ),
            children: [
              {
                path: "primary",
                element: <PrimaryUserGroup />,
                children: [],
                header: "Manage User Roles",
                location: "/manageusergroups/usergroups",
                roles: ["MC Admin"],
              },
              {
                path: "secondary",
                element: <SecondaryUsergroup />,
                children: [],
                header: "Manage User Roles",
                location: "/manageusergroups/usergroups/secondary",
                roles: ["MC Admin"],
              },
            ],
            header: "Manage User Roles",
            location: "/usergroups/manageusergroups",
            roles: ["MC Admin"],
          },
        ],
        header: "Manage User Roles",
        location: "/usergroup",
        roles: ["MC Admin"],
      },
      {
        id: MC_DASHBOARD,
        path: "medicalcenter/*",
        element: <DashboardLayout />,
        children: [
          {
            path: "branch",
            element: (
              <ErrorBoundaryHoc errorMessage="Something Went Wrong...">
                <MedicalCenterBranch />
              </ErrorBoundaryHoc>
            ),
            children: [
              {
                path: "",
                element: <BranchList />,
                children: [],
                header: "Manage Medical Center Setup",
                location: "/medicalcenter/branch",
                roles: ["Medical Center Admin"],
              },
              {
                path: "managebranch",
                element: <ManageBranch />,
                children: [],
                header: "Manage Medical Center Setup",
                location: "/medicalcenter/branch/managebranch",
                roles: ["Medical Center Admin"],
              },
            ],
            header: "Manage Medical Center Setup",
            location: "/medicalcenter/branch",
            roles: ["Medical Center Admin"],
          },
          {
            path: "department",
            element: (
              <ErrorBoundaryHoc errorMessage="Something Went Wrong...">
                <MedicalCenterDepartment />
              </ErrorBoundaryHoc>
            ),
            children: [
              {
                path: "",
                element: <DepartmentList />,
                children: [],
                header: "Manage Medical Center Setup",
                location: "/medicalcenter/department",
                roles: ["Medical Center Admin"],
              },
              {
                path: "managedepartment",
                element: <ManageDepartment />,
                children: [],
                header: "Manage Medical Center Setup",
                location: "/medicalcenter/department/managedepartment",
                roles: ["Medical Center Admin"],
              },
              {
                path: "managespecialities",
                // element: <ManageSpecialities />,
                element: <ManageSpecialitiesNew />,
                children: [],
                header: "Manage Medical Center Setup",
                location: "/medicalcenter/department/managespecialities",
                roles: ["Medical Center Admin"],
              },
            ],
            header: "Manage Medical Center Setup",
            location: "/medicalcenter/department",
            roles: ["Medical Center Admin"],
          },
          {
            path: "ward",
            element: (
              <ErrorBoundaryHoc errorMessage="Something Went Wrong...">
                <MedicalCenterWard />
              </ErrorBoundaryHoc>
            ),
            children: [
              {
                path: "",
                element: <WardList />,
                children: [],
                header: "Manage Medical Center Setup",
                location: "/medicalcenter/ward",
                roles: ["Medical Center Admin"],
              },
              {
                path: "manageward",
                element: <ManageWard />,
                children: [],
                header: "Manage Medical Center Setup",
                location: "/medicalcenter/ward/manageward",
                roles: ["Medical Center Admin"],
              },
            ],
            header: "Manage Medical Center Setup",
            location: "/medicalcenter/ward",
            roles: ["Medical Center Admin"],
          },
          {
            path: "room",
            element: (
              <ErrorBoundaryHoc errorMessage="Something Went Wrong...">
                <MedicalCenterRoom />
              </ErrorBoundaryHoc>
            ),
            children: [
              {
                path: "",
                element: <RoomList />,
                children: [],
                header: "Manage Medical Center Setup",
                location: "/medicalcenter/room",
                roles: ["Medical Center Admin"],
              },
              {
                path: "manageroom",
                element: <ManageRoom />,
                children: [],
                header: "Manage Medical Center Setup",
                location: "/medicalcenter/room/manageroom",
                roles: ["Medical Center Admin"],
              },
            ],
            header: "Manage Medical Center Setup",
            location: "/medicalcenter/room",
            roles: ["Medical Center Admin"],
          },
          {
            path: "bed",
            element: (
              <ErrorBoundaryHoc errorMessage="Something Went Wrong...">
                <MedicalCenterBed />
              </ErrorBoundaryHoc>
            ),
            children: [
              {
                path: "",
                element: <BedList />,
                children: [],
                header: "Manage Medical Center Setup",
                location: "/medicalcenter/bed",
                roles: ["Medical Center Admin"],
              },
              {
                path: "managebed",
                element: <ManageBed />,
                children: [],
                header: "Manage Medical Center Setup",
                location: "/medicalcenter/bed/managebed",
                roles: ["Medical Center Admin"],
              },
            ],
            header: "Manage Medical Center Setup",
            location: "/medicalcenter/bed",
            roles: ["Medical Center Admin"],
          },
        ],
        header: "Medical Center - Branch Setup",
        location: "/medicalcenter/branch",
        roles: ["Super Admin"],
      },
      {
        id: INS_MASTERS,
        path: "insurancemaster",
        element: (
          <ErrorBoundaryHoc errorMessage="Something Went Wrong...">
            <InsuranceMaster />
          </ErrorBoundaryHoc>
        ),
        children: [],
        header: "Insurance Masters",
        location: "/insurancemaster",
        roles: ["Medical Center Admin"],
      },
      {
        id: ONGING_CLAIMS,
        path: "ongoing-claims",
        element: (
          <ErrorBoundaryHoc errorMessage="Something Went Wrong...">
            <OnGoingClaims />
          </ErrorBoundaryHoc>
        ),
        children: [],
        header: "Insurance Claims",
        location: "/ongoing-claims",
        roles: ["Medical Center Admin"],
      },
      {
        id: SER_MASTERS,
        path: "services",
        element: (
          <ErrorBoundaryHoc errorMessage="Something Went Wrong...">
            <Services />
          </ErrorBoundaryHoc>
        ),
        children: [],
        header: "Services Masters",
        location: "/services",
        roles: ["Medical Center Admin"],
      },
      {
        id: INVENTORY_MASTER_TABLE,
        path: "inventorymastertable/*",
        element: (
          <ErrorBoundaryHoc errorMessage="Something Went Wrong...">
            <InventoryMasterTableLayout />
          </ErrorBoundaryHoc>
        ),
        children: [
          {
            path: "",
            element: <InventoryMasterTable />,
            children: [],
            header: "Supplier Master",
            location: "/inventorymastertable",
            roles: ["Medical Center Admin"],
          },
          {
            path: "addnewsuplier",
            element: <AddNewSuplier />,
            children: [],
            header: "Add New Supplier",
            location: "/addnewsuplier",
            roles: ["Medical Center Admin"],
          },
        ],
        header: "Supplier Master ",
        location: "/inventorymastertable",
        roles: ["Medical Center Admin"],
      },
      {
        id: INVENTORY_ITEM_MASTERS,
        path: "inventoryitemtable/*",
        element: (
          <ErrorBoundaryHoc errorMessage="Something Went Wrong...">
            <InventoryItemTableLayout />
          </ErrorBoundaryHoc>
        ),
        children: [
          {
            path: "",
            element: <InventoryItemTable />,
            children: [],
            header: "Inventory Item Tables",
            location: "/inventoryitemtable",
            roles: ["Medical Center Admin"],
          },
          {
            path: "addnewitem",
            element: <AddNewItem />,
            children: [],
            header: "Add New Item",
            location: "/addnewitem",
            roles: ["Medical Center Admin"],
          },
        ],
        header: "Inventory Item Tables",
        location: "/inventoryitemtable",
        roles: ["Medical Center Admin"],
      },
      // Unit Type Master
      {
        id: INVENTORY_UNIT_TYPE_MAP,
        path: "unitTypeMap/*",
        element: (
          <ErrorBoundaryHoc errorMessage="Something Went Wrong...">
            <UnitTypeMainLayout />
          </ErrorBoundaryHoc>
        ),
        children: [],
        header: "Unit Type Master",
        location: "/unitTypeMap",
        roles: ["Medical Center Admin"],
      },

      // receptionist routes
      {
        id: RECEP_DASHBOARD,
        path: "receptionist/*",
        element: (
          <ErrorBoundaryHoc errorMessage="Something Went Wrong...">
            <Receptionist />
          </ErrorBoundaryHoc>
        ),
        children: [],
        header: "Receptionist",
        location: "/receptionist",
        roles: ["Receptionist"],
      },
      {
        id: PATIENT_EMR,
        path: "patientemr/*",
        element: <PatientEmrLayout />,
        children: [
          {
            path: "",
            element: (
              <ErrorBoundaryHoc errorMessage="Something Went Wrong...">
                <PatientEmrDashboard />
              </ErrorBoundaryHoc>
            ),
            children: [],
            header: "Patient EMR Dashboard",
            location: "/patientemr",
            roles: ["MC Admin"],
          },
          {
            path: "sharequestionnaireemail",
            element: (
              <ErrorBoundaryHoc errorMessage="Something Went Wrong...">
                <ShareQuestionEmail />
              </ErrorBoundaryHoc>
            ),
            children: [],
            header: "Patient Emr Dashboard",
            location: "patientemr/sharequestionnaireemail",
            roles: ["MC Admin"],
          },
        ],
        header: "Patient Emr Dashboard",
        location: "/patientemr",
        roles: ["MC Admin"],
      },
      {
        id: SCHEDULAR,
        path: "schedular/*",
        element: (
          <ErrorBoundaryHoc errorMessage="Something Went Wrong...">
            <MainSchedular />
          </ErrorBoundaryHoc>
        ),
        children: [
          {
            path: "",
            element: (
              <ErrorBoundaryHoc errorMessage="Something Went Wrong...">
                <BookingSchedularFunctional />
              </ErrorBoundaryHoc>
            ),
            children: [],
            header: "Manage Appointments",
            location: "/schedular",
            roles: ["MC Admin"],
          },
          {
            path: "beds",
            element: (
              <ErrorBoundaryHoc errorMessage="Something Went Wrong...">
                <BedSchedular />
              </ErrorBoundaryHoc>
            ),
            children: [],
            header: "Manage Appointments",
            location: "/schedular/beds",
            roles: ["MC Admin"],
          },
          {
            path: "nurse",
            element: (
              <ErrorBoundaryHoc errorMessage="Something Went Wrong...">
                <BookingSchedularFunctional />
              </ErrorBoundaryHoc>
            ),
            children: [],
            header: "Patient Emr Dashboard",
            location: "/schedular/nurse",
            roles: ["MC Admin"],
          },
          {
            path: "room",
            element: (
              <ErrorBoundaryHoc errorMessage="Something Went Wrong...">
                <RoomSchedular />
              </ErrorBoundaryHoc>
            ),
            children: [],
            header: "Manage Appointments",
            location: "/schedular/room",
            roles: ["MC Admin"],
          },
        ],
        header: "Manage Appointments",
        location: "/bookingappointment",
        roles: ["Recessepnoist"],
      },
      {
        id: INVOICE,
        path: "invoice/*",
        element: <InvoiceLayout />,
        children: [
          {
            path: "",
            element: <InvoiceLayout />,
            children: [],
            header: "Invoice",
            location: "/invoice/information",
            roles: ["Super Admin"],
          },
          {
            path: "information",
            element: (
              <ErrorBoundaryHoc errorMessage="Something Went Wrong...">
                <InvoiceInformation />
              </ErrorBoundaryHoc>
            ),
            children: [],
            header: "Invoice",
            location: "/invoice/information",
            roles: ["Super Admin"],
          },
          {
            path: "services",
            element: (
              <ErrorBoundaryHoc errorMessage="Something Went Wrong...">
                <InvoiceServices />
              </ErrorBoundaryHoc>
            ),
            children: [],
            header: "Invoice - Services",
            location: "/invoice/services",
            roles: ["Super Admin"],
          },
          {
            path: "payment",
            element: (
              <ErrorBoundaryHoc errorMessage="Something Went Wrong...">
                <InvoicePayment />
              </ErrorBoundaryHoc>
            ),
            children: [],
            header: "Invoice - Payment",
            location: "/invoice/payment",
            roles: ["Super Admin"],
          },
        ],
        header: "Invoice",
        location: "/invoice",
        roles: ["Super Admin"],
      },
      {
        id: RECEIPT,
        path: "receipt/*",
        element: (
          <ErrorBoundaryHoc errorMessage="Something Went Wrong...">
            <ReceiptLayout />
          </ErrorBoundaryHoc>
        ),
        children: [
          {
            path: "",
            element: <Receipt />,
            children: [],
            header: "Receipt",
            location: "/receipt",
            roles: ["Receptionist"],
          },
        ],
        header: "Receipt",
        location: "/receipt",
        roles: ["Receptionist"],
      },
      // doctor routes
      {
        id: DC_DASHBOARD,
        path: "doctor/*",
        element: <DoctorAppointmentDashboard />,
        children: [],
        header: "Doctor",
        location: "/doctor",
        roles: ["DOCTOR"],
      },
      {
        id: DC_DIAGNOSIS,
        path: "patientdiagnosis/*",
        element: <DoctorDiagnosisLayout />,
        children: [
          {
            path: "diagnosis",
            element: <DiagnosisForm />,
            children: [],
            header: "Diagnosis",
            location: "/patientdiagnosis/diagnosis",
            roles: ["DOCTOR"],
          },
          {
            path: "medication",
            element: <Medication />,
            children: [],
            header: "Medication",
            location: "/patientdiagnosis/medication",
            roles: ["DOCTOR"],
          },
          {
            path: "request",
            element: <Request />,
            children: [],
            header: "Request",
            location: "/patientdiagnosis/request",
            roles: ["DOCTOR"],
          },
          {
            path: "referral",
            element: <Referral />,
            children: [],
            header: "Referral",
            location: "/patientdiagnosis/referral",
            roles: ["DOCTOR"],
          },
          {
            path: "treatment",
            element: <TreatmentPlanList />,
            children: [],
            header: "Treatment",
            location: "/patientdiagnosis/treatment",
            roles: ["DOCTOR"],
          },
          {
            path: "invoices",
            element: <Invoice />,
            children: [],
            header: "Doctor",
            location: "/patientdiagnosis/invoices",
            roles: ["DOCTOR"],
          },
        ],
        header: "Doctor Diagnosis",
        location: "/diagnosis",
        roles: ["DOCTOR"],
      },
      {
        id: FORM_BUILDER,
        path: "formBuilder/*",
        element: (
          <ErrorBoundaryHoc errorMessage="Something Went Wrong...">
            <CreateNewFormsLayout />
          </ErrorBoundaryHoc>
        ),
        children: [
          {
            path: "",
            element: <CreateNewFormList />,
            children: [],
            header: "Create New Forms",
            location: "",
            roles: ["DOCTOR"],
          },
          {
            path: "createNewFormBuilder",
            element: <CreateNewFormBuilder />,
            children: [],
            header: "Create New Form Builder",
            location: "/createNewFormBuilder",
            roles: ["DOCTOR"],
          },
          {
            path: "*",
            element: <NotFound />,
            children: [],
            header: "Page Not Found",
            roles: ["DOCTOR"],
          },
        ],
        header: "Create New Forms",
        location: "/formBuilder",
        roles: ["DOCTOR"],
      },

      {
        id: SCHEDULAR,
        path: "schedular/*",
        element: <MainSchedular />,
        children: [
          {
            path: "",
            element: <BookingSchedularFunctional />,
            children: [],
            header: "Manage Appointments",
            location: "/schedular",
            roles: ["MC Admin"],
          },
          {
            path: "beds",
            element: <BedSchedular />,
            children: [],
            header: "Manage Appointments",
            location: "/schedular/beds",
            roles: ["MC Admin"],
          },
          {
            path: "nurse",
            element: <BookingSchedularFunctional />,
            children: [],
            header: "Patient Emr Dashboard",
            location: "/schedular/nurse",
            roles: ["MC Admin"],
          },
        ],
        header: "Manage Appointments",
        location: "/bookingappointment",
        roles: ["Recessepnoist"],
      },
      // lab routes
      {
        id: LAB_JOB,
        path: "job/*",
        element: <JobLayout />,
        children: [
          {
            path: "",
            element: <JobLayout />,
            children: [],
            header: "Job",
            location: "/job/createjobs",
            roles: ["Super Admin"],
          },
          {
            path: "createjobs",
            element: <CreateJobs />,
            children: [],
            header: "Job",
            location: "/job/createjobs",
            roles: ["LAB_SUPERVISOR"],
          },
          {
            path: "viewJobs",
            element: <ViewJobs />,
            children: [],
            header: "View Jobs",
            location: "/job/viewJobs",
            roles: ["Super Admin"],
          },
        ],
        header: "Job",
        location: "/job/createjobs",
        roles: ["LAB_SUPERVISOR"],
      },
      {
        id: LAB_REQUEST,
        path: "request/*",
        element: <LabRequestLayout />,
        children: [
          {
            path: "",
            element: <LabRequest />,
            children: [],
            header: "Lab Request",
            location: "/request",
            roles: ["LAB_SUPERVISOR"],
          },
        ],
        header: "Lab Request",
        location: "/request",
        roles: ["LAB_SUPERVISOR"],
      },
      {
        id: LAB_INVOICE,
        path: "invoice/*",
        element: <LabInvoiceLayout />,
        children: [
          {
            path: "",
            element: <LabInvoiceLayout />,
            children: [],
            header: "Lab Invoice",
            location: "/invoice/labinformation",
            roles: ["Super Admin"],
          },
          {
            path: "labinformation",
            element: <LabInformation />,
            children: [],
            header: "Lab Invoice",
            location: "/invoice/labinformation",
            roles: ["Super Admin"],
          },
          {
            path: "labservices",
            element: <LabServices />,
            children: [],
            header: "Lab Services",
            location: "/invoice/labservices",
            roles: ["Super Admin"],
          },
          {
            path: "labpayment",
            element: <LabPayment />,
            children: [],
            header: "Lab Payment",
            location: "/labinvoice/labpayment",
            roles: ["Super Admin"],
          },
        ],
        header: "Lab",
        location: "/invoice",
        roles: ["Super Admin"],
      },
      {
        id: LAB_CONFIGURATION,
        path: "configuration/*",
        element: <Configuration />,
        children: [],
        header: "Configuration",
        location: "/configuration",
        roles: ["LAB_SUPERVISOR"],
      },

      // Radiology Routes
      {
        id: RADIOLOGY_JOB,
        path: "radiology-job/*",
        element: <RadiologyJobLayout />,
        children: [
          {
            path: "",
            element: <RadiologyJobLayout />,
            children: [],
            header: "Job",
            location: "/radiology-job/createjobs",
            roles: ["Super Admin"],
          },
          {
            path: "createjobs",
            element: <RadiologyCreateJobs />,
            children: [],
            header: "Job",
            location: "/radiology-job/createjobs",
            roles: ["Super Admin"],
          },
          {
            path: "viewJobs",
            element: <RadiologyViewJobs />,
            children: [],
            header: "View Jobs",
            location: "/radiology-job/viewJobs",
            roles: ["Super Admin"],
          },
        ],
        header: "Job",
        location: "/job",
        roles: ["RADIOLOGY_SUPERVISOR"],
      },
      {
        id: RADIOLOGY_REQUEST,
        path: "radiology-request/*",
        element: <RadioLogyRequestLayout />,
        children: [
          {
            path: "",
            element: <RadiologyRequest />,
            children: [],
            header: "Radiology Request",
            location: "/radiology-request",
            roles: ["RADIOLOGY_SUPERVISOR"],
          },
        ],
        header: "Radiology Request",
        location: "/radiology-request",
        roles: ["RADIOLOGY_SUPERVISOR"],
      },
      {
        id: RADIOLOGY_INVOICE,
        path: "radiology-invoice/*",
        element: <RadiologyInvoiceLayout />,
        children: [
          {
            path: "",
            element: <RadiologyInvoiceLayout />,
            children: [],
            header: "Invoice",
            location: "/radiology-invoice/information",
            roles: ["RADIOLOGY_SUPERVISOR"],
          },
          {
            path: "information",
            element: <RadiologyInvoiceInformation />,
            children: [],
            header: "Invoice",
            location: "/radiology-invoice/information",
            roles: ["RADIOLOGY_SUPERVISOR"],
          },
          {
            path: "services",
            element: <RadiologyInvoiceService />,
            children: [],
            header: "Invoice - Services",
            location: "/radiology-invoice/services",
            roles: ["RADIOLOGY_SUPERVISOR"],
          },
          {
            path: "payment",
            element: <RadiologyInvoicePayment />,
            children: [],
            header: "Invoice - Payment",
            location: "/radiology-invoice/payment",
            roles: ["RADIOLOGY_SUPERVISOR"],
          },
        ],
        header: "Invoice",
        location: "/radiology-invoice",
        roles: ["RADIOLOGY_SUPERVISOR"],
      },
      {
        id: RADIOLOGY_CONFIGURAION,
        path: "radiology-configuration/*",
        element: <RadiologyConfiguration />,
        children: [],
        header: "Configuration",
        location: "/radiology-configuration",
        roles: ["RADIOLOGY_SUPERVISOR"],
      },

      // Inventory Routes
      {
        id: INVENTORY_REQUEST,
        path: "request/*",
        element: <RequestLayout />,
        children: [
          {
            path: "",
            element: <InventoryRequest />,
            children: [],
            header: "Request",
            location: "/request",
            roles: ["Super Admin"],
          },
        ],
        header: "request",
        location: "/request",
        roles: ["Super Admin"],
      },
      {
        id: BRANCH_STORE,
        path: "branchstore/*",
        element: <BranchStoreLayout />,
        children: [
          {
            path: "",
            element: <BranchStore />,
            children: [],
            header: "Branch Store",
            location: "/branchstore",
            roles: ["Super Admin"],
          },
        ],
        header: "Branch Store",
        location: "/branchstore",
        roles: ["Super Admin"],
      },
      {
        id: MAIN_STORE,
        path: "mainstore/*",
        element: <MainStoreLayout />,
        children: [
          {
            path: "",
            element: <MainStore />,
            children: [],
            header: "Main Store",
            location: "/mainstore",
            roles: ["Super Admin"],
          },
        ],
        header: "Mainstore",
        location: "/mainstore",
        roles: ["Super Admin"],
      },
      {
        id: PHARMACY_MAIN_STORE,
        path: "pharmacymainstore/*",
        element: <MainStoreLayout />,
        children: [
          {
            path: "",
            element: <MainStore />,
            children: [],
            header: "Pharmacy main Store",
            location: "/pharmacymainstore",
            roles: ["Super Admin"],
          },
        ],
        header: "Pharmacy",
        location: "/pharmacymainstore",
        roles: ["Super Admin"],
      },
      {
        id: PURCHASE_INVOICE,
        path: "purchaseinvoice/*",
        element: <PurchaseInvoiceLayout />,
        children: [
          {
            path: "",
            element: <PurchaseInvoice />,
            children: [],
            header: "Purchase Invoice",
            location: "/purchaseinvoice",
            roles: ["Super Admin"],
          },
        ],
        header: "Purchaseinvoice",
        location: "/purchaseinvoice",
        roles: ["Super Admin"],
      },

      // dentist routes

      {
        id: DNT_DASHBOARD,
        path: "dentist/*",
        element: <DentistAppointmentDashboard />,
        children: [],
        header: "Dentist",
        location: "/dentist",
        roles: ["DENTIST"],
      },
      {
        id: DNT_DIAGNOSIS,
        path: "patientdentaldiagnosis/*",
        element: <DentistDiagnosisLayout />,
        children: [
          {
            path: "diagnosis",
            element: <DentalDiagnosisForm />,
            children: [],
            header: "Diagnosis",
            location: "/patientdentaldiagnosis/diagnosis",
            roles: ["DENTIST"],
          },
          {
            path: "medication",
            element: <DentalMedication />,
            children: [],
            header: "Medication",
            location: "/patientdentaldiagnosis/medication",
            roles: ["DENTIST"],
          },
          {
            path: "request",
            element: <DentalRequest />,
            children: [],
            header: "Request",
            location: "/patientdentaldiagnosis/request",
            roles: ["DENTIST"],
          },
          {
            path: "referral",
            element: <DentalReferral />,
            children: [],
            header: "Referral",
            location: "/patientdentaldiagnosis/referral",
            roles: ["DENTIST"],
          },
          {
            path: "treatment",
            element: <DentalTreatmentPlanList />,
            children: [],
            header: "Treatment",
            location: "/patientdentaldiagnosis/treatment",
            roles: ["DENTIST"],
          },
        ],
        header: "Dentist Diagnosis",
        location: "/diagnosis",
        roles: ["DENTIST"],
      },

      // pharmacy route
      {
        id: PHARMACY_POS,
        path: "pharmacy/*",
        element: <PharmacyLayout />,
        children: [
          {
            path: "pharmacy-info",
            element: <PharmacyInfoForm />,
            children: [],
            header: "POS",
            location: "/pharmacy/pharmacy-info",
            roles: ["MC Admin"],
          },
          {
            path: "pharmacy-payment",
            element: <PharmacyPaymentNew />,
            children: [],
            header: "POS",
            location: "/pharmacy/pharmacy-info",
            roles: ["MC Admin"],
          },
          {
            path: "pharmacy-onhold",
            element: <PharmacyOnhold />,
            children: [],
            header: "POS",
            location: "/pharmacy/pharmacy-onhold",
            roles: ["MC Admin"],
          },
        ],
        header: "Pharmacy",
        location: "/pharmacy",
        roles: ["Pharmacy"],
      },
      {
        id: PHARMACY_STORE,
        path: "pharmacystore",
        element: <PharmacyStore />,
        children: [],
        header: "Pharmacy Store",
        location: "/pharmacystore",
      },
      {
        id: "public",
        path: "*",
        element: <NotFound />,
        children: [],
        header: "Page Not Found",
        roles: ["MC Admin"],
      },
      {
        id: "public",
        path: "notfound",
        element: <NotFound />,
        children: [],
        header: "Page Not Found",
        roles: ["MC Admin"],
      },
      {
        id: "public",
        path: "notpermitted",
        element: <NotFound />,
        children: [],
        header: "Page Not Found",
        roles: ["MC Admin"],
      },

      // IPD routes
      {
        id: IPD_ID,
        path: "ipd/*",
        element: <IPD />,
        children: [
          {
            path: "",
            element: <IpdDashboard />,
            children: [],
            header: "IPD",
            location: "/ipd",
            roles: ["DOCTOR"],
          },
          {
            path: "createipdmainlayout",
            element: <CreateIpdMainLayout />,
            children: [
              {
                path: "consultation",
                element: <Consultation />,
                children: [],
                header: "IPD",
                location: "ipd/createipdmainlayout/consultation",
                roles: ["DOCTOR"],
              },
            ],
            header: "IPD",
            location: "/ipd/createipdmainlayout",
            roles: ["DOCTOR"],
          },
        ],
        header: "IPD",
        location: "/ipd",
        roles: ["DOCTOR"],
      },

      // Receptionist IPD Routes
      {
        id: IPD_INVOICE,
        path: "ipd-invoice/*",
        element: <IpdInvoiceLayout />,
        children: [
          {
            path: "",
            element: <IpdInvoiceLayout />,
            children: [],
            header: "Ipd Invoice",
            location: "/ipd-invoice",
            roles: ["Super Admin"],
          },
          {
            path: "patientinfo",
            element: (
              <ErrorBoundaryHoc errorMessage="Something Went Wrong...">
                <PatientIPDInfo />
              </ErrorBoundaryHoc>
            ),
            children: [],
            header: "Ipd Invoice",
            location: "/ipd-invoice/patientinfo",
            roles: ["Super Admin"],
          },
          {
            path: "consumble",
            element: (
              <ErrorBoundaryHoc errorMessage="Something Went Wrong...">
                <PatientConsumble />
              </ErrorBoundaryHoc>
            ),
            children: [],
            header: "Patient Consumble",
            location: "/ipd-invoice/consumble",
            roles: ["Super Admin"],
          },
          {
            path: "charges",
            element: (
              <ErrorBoundaryHoc errorMessage="Something Went Wrong...">
                <PatientCharges />
              </ErrorBoundaryHoc>
            ),
            children: [],
            header: "Patient Charges",
            location: "/ipd-invoice/charges",
            roles: ["Super Admin"],
          },
          {
            path: "services",
            element: (
              <ErrorBoundaryHoc errorMessage="Something Went Wrong...">
                <PatientIPDServices />
              </ErrorBoundaryHoc>
            ),
            children: [],
            header: "Patient Services",
            location: "/ipd-invoice/services",
            roles: ["Super Admin"],
          },

          {
            path: "payment",
            element: (
              <ErrorBoundaryHoc errorMessage="Something Went Wrong...">
                <PatientPayment />
              </ErrorBoundaryHoc>
            ),
            children: [],
            header: "Patient Payment",
            location: "/ipd-invoice/payment",
            roles: ["Super Admin"],
          },
        ],
        header: "Ipd Invoice",
        location: "/ipd-invoice",
        roles: ["Super Admin"],
      },
      {
        id: IPD_CREATE_CASE,
        path: "ipd-case-form",
        element: <IpdCreateForm isRecep={true} />,
        children: [],
        header: "IPD",
        location: "/ipd-case-form",
        roles: ["RECEPTIONIST"],
      },
      // Call Center Admin Routes
      {
        id: CC_ADMIN_DASHBOARD,
        path: "call/*",
        element: <CallCenterDashboardLayout />,
        children: [
          {
            path: "",
            element: <Call />,
            children: [],
            header: "Call Center Admin",
            location: "/call",
            roles: ["Medical Center Admin"],
          },
        ],
        header: "Call Center Admin",
        location: "/call",
        roles: ["Super Admin"],
      },
      {
        id: CC_LEAD_POOL,
        path: "lead/*",
        element: <LeadLayout />,
        children: [
          {
            path: "leads",
            element: <Leads />,
            children: [],
            header: "Lead Pool",
            location: "/lead/leads",
            roles: ["Medical Center Admin"],
          },
          {
            path: "internal",
            element: <Internal />,
            children: [],
            header: "Lead Pool",
            location: "/lead/internal",
            roles: ["Medical Center Admin"],
          },
          {
            path: "external",
            element: <External />,
            children: [],
            header: "Lead Pool ",
            location: "lead/external",
            roles: ["Medical Center Admin"],
          },
        ],
        header: "Proact",
        location: "/lead",
        roles: ["Super Admin"],
      },
      {
        id: CC_AGENT,
        path: "agents/*",
        element: <AgentsLayout />,
        children: [
          {
            path: "",
            element: <Agents />,
            children: [],
            header: "Agents",
            location: "/agents",
            roles: ["Medical Center Admin"],
          },
        ],
        header: "Agents",
        location: "/agents",
        roles: ["Super Admin"],
      },
      {
        id: CC_CAMPAIGN,
        path: "campaigns/*",
        element: <CampaignsLayout />,
        children: [
          {
            path: "onGoingCampaigns",
            element: <OnGoingCampaigns />,
            children: [],
            header: "Campaigns",
            location: "campaigns/onGoingCampaigns",
            roles: ["Medical Center Admin"],
          },
          {
            path: "createCampaigns",
            element: <CreateCampaigns />,
            children: [],
            header: "Campaigns",
            location: "campaigns/createCampaigns",
            roles: ["Medical Center Admin"],
          },
        ],
        header: "Campaigns",
        location: "/campaigns",
        roles: ["Super Admin"],
      },

      //call center Agent
      {
        id: CC_AGENT_DASHBOARD,
        path: "agent/*",
        element: <AgentDashboardLayout />,
        children: [
          {
            path: "agentleads",
            element: <AgentLeads />,
            children: [],
            header: "Leads",
            location: "/agent/agentleads",
            roles: ["Medical Center Admin"],
          },

          {
            path: "follow-up",
            element: <FollowUp />,
            children: [],
            header: "Follow Up",
            location: "/agent/follow-up",
            roles: ["Medical Center Admin"],
          },
        ],
        header: "Call Center Agent",
        location: "/agent",
        roles: ["Super Admin"],
      },
      {
        id: CRM_ADMIN_KEY,
        path: "crmadmin/*",
        element: <CrmAdminLayout />,
        children: [
          {
            path: "new",
            element: <CrmNew />,
            children: [],
            header: "CRM Admin",
            location: "/crmadmin/new",
            roles: ["Medical Center Admin"],
          },
          {
            path: "sent",
            element: <CrmSent />,
            children: [],
            header: "CRM Admin",
            location: "/crmadmin/sent",
            roles: ["Medical Center Admin"],
          },
        ],
        header: "Crm Admin",
        location: "/crmadmin",
        roles: ["Super Admin"],
      },
      {
        id: REPORT_GENERATOR,
        path: "reportgenerator",
        element: (
          <ErrorBoundaryHoc errorMessage="Something Went Wrong...">
            <ReportBuilder />
          </ErrorBoundaryHoc>
        ),
        children: [],
        header: "Report Generator",
        location: "/reportgenerator",
      },
      {
        id: REPORT_BUILDER,
        path: "reportbuilder/*",
        element: <ReportBuilderLayout />,
        children: [
          {
            path: "",
            element: (
              <ErrorBoundaryHoc errorMessage="Something Went Wrong...">
                <ReportBuilderList />
              </ErrorBoundaryHoc>
            ),
            children: [],
            header: "Report Builder",
            location: "/reportbuilder",
            roles: ["MC Admin"],
          },
          {
            path: "createreport",
            element: (
              <ErrorBoundaryHoc errorMessage="Something Went Wrong...">
                <CreateReport />
              </ErrorBoundaryHoc>
            ),
            children: [],
            header: "Report Builder",
            location: "/reportbuilder/createreport",
            roles: ["MC Admin"],
          },
        ],
        header: "Report Builder",
        location: "/reportbuilder",
        roles: ["Medical Center Admin"],
      },
      // report mapping
      {
        id: REPORT_MAPPING,
        path: "report-mapping/*",
        element: (
          <ErrorBoundaryHoc errorMessage="Something Went Wrong...">
            <ReportMapping />
          </ErrorBoundaryHoc>
        ),
        children: [],
        header: "Report Mapping",
        location: "/report-mapping",
        roles: ["MC Admin"],
      },

      // HR dashboard

      {
        id: HR_DASHBOARD,
        path: "hr-dashboard/*",
        element: (
          <ErrorBoundaryHoc errorMessage="Something Went Wrong...">
            <HRDashboardLayout />
          </ErrorBoundaryHoc>
        ),
        children: [
          {
            path: "",
            element: <HrDashboard />,
            children: [],
            header: "Dashboard",
            location: "/hr-dashboard",
            roles: ["HR Admin"],
          },
          {
            path: "departments",
            element: <Departments />,
            children: [],
            header: "Departments",
            location: "/hr-dashboard/departments",
            roles: ["Super Admin"],
          },
        ],
        header: "Dashboard",
        location: "/hr-dashboard",
        roles: ["Super Admin"],
      },

      // HR Employee

      {
        id: EMP_MANAGEMENT,
        path: "employees/*",
        element: (
          <ErrorBoundaryHoc errorMessage="Something Went Wrong...">
            <EmployeesLayout />
          </ErrorBoundaryHoc>
        ),
        children: [
          {
            path: "",
            element: <Employees />,
            children: [],
            header: "Employees",
            location: "/employees",
            roles: ["Super Admin"],
          },
          {
            path: "addEmployee",
            element: <AddEmployee />,
            children: [],
            header: "Add New Employee",
            location: "/employees/addEmployee",
            roles: ["Super Admin"],
          },
        ],
        header: "Employees",
        location: "/employees",
        roles: ["Super Admin"],
      },

      // HR Documents
      {
        id: DOCUMENTS,
        path: "documents/*",
        element: (
          <ErrorBoundaryHoc errorMessage="Something Went Wrong...">
            <DocumentsLayout />
          </ErrorBoundaryHoc>
        ),
        children: [
          {
            path: "",
            element: <Documnets />,
            children: [],
            header: "Documents",
            location: "/documents",
            roles: ["Super Admin"],
          },
          {
            path: "addnew",
            element: <AddDocument />,
            children: [],
            header: "Add New Document",
            location: "/documents/addnew",
            roles: ["Super Admin"],
          },
        ],
        header: "Documents",
        location: "/documents",
        roles: ["Super Admin"],
      },

      // HR Payroll
      {
        id: PAYROLL,
        path: "payroll/*",
        element: (
          <ErrorBoundaryHoc errorMessage="Something Went Wrong...">
            <PayrollLayout />
          </ErrorBoundaryHoc>
        ),
        children: [
          {
            path: "",
            element: <Payroll />,
            children: [],
            header: "Payroll",
            location: "/payroll",
            roles: ["Super Admin"],
          },
          {
            path: "create",
            element: <Create />,
            children: [],
            header: "Create Salary Slip",
            location: "/payroll/create",
            roles: ["Super Admin"],
          },
          {
            path: "services",
            element: <EndofServices />,
            children: [],
            header: "End Of Services",
            location: "/payroll/services",
            roles: ["Super Admin"],
          },
          {
            path: "services/new",
            element: <New />,
            children: [],
            header: "Add New",
            location: "/payroll/services/new",
            roles: ["Super Admin"],
          },
        ],
        header: "Payroll",
        location: "/payroll",
        roles: ["Super Admin"],
      },

      // HR Attendance
      {
        id: ATTENDANCE,
        path: "attendance/*",
        element: (
          <ErrorBoundaryHoc errorMessage="Something Went Wrong...">
            <AttendaceLayout />
          </ErrorBoundaryHoc>
        ),
        children: [
          // {
          //   path: '',
          //   element: <Attendance />,
          //   children: [],
          //   header: 'Attendance',
          //   location: '/attendance',
          //   roles: ['Super Admin'],
          // },
          {
            path: "",
            element: <ManageAttendance />,
            children: [],
            header: "Attendance",
            location: "/attendance/manage-attendance",
            roles: ["Super Admin"],
          },
        ],
        header: "Attendance",
        location: "/attendance",
        roles: ["Super Admin"],
      },

      // HR Leave
      {
        id: LEAVE_MANAGEMENT,
        path: "leave-management/*",
        element: (
          <ErrorBoundaryHoc errorMessage="Something Went Wrong...">
            <LeaveManagementLayout />
          </ErrorBoundaryHoc>
        ),
        children: [
          {
            path: "",
            element: <LeaveManagement />,
            children: [],
            header: "Leave Management",
            location: "/leave-management",
            roles: ["Super Admin"],
          },
          {
            path: "leave-encashment",
            element: <LeaveEncashment />,
            children: [],
            header: "Leave Encashment",
            location: "/leave-management/leave-encashment",
            roles: ["Super Admin"],
          },
          {
            path: "leave-encashment/leave-formdata",
            element: <LeaveFormData />,
            children: [],
            header: "Leave Encashment",
            location: "/leave-management/leave-encashment/leave-formdata",
            roles: ["Super Admin"],
          },
          {
            path: "apply-leave",
            element: <ApplyLeave />,
            children: [],
            header: "Leave Management",
            location: "/leave-management/apply-leave",
            roles: ["Super Admin"],
          },
        ],
        header: "Leave Management",
        location: "/leaveManagement",
        roles: ["Super Admin"],
      },

      // Account Dashboard
      {
        id: ACCOUNT_DASHBOARD,
        path: "account-dashboard/*",
        element: (
          <ErrorBoundaryHoc errorMessage="Something Went Wrong...">
            <AccountDashboardLayout />
          </ErrorBoundaryHoc>
        ),
        children: [
          {
            path: "",
            element: <Dashboard />,
            children: [],
            header: "Dashboard",
            location: "/account-dashboard",
            roles: ["Super Admin"],
          },
        ],
        header: "Dashboard",
        location: "/dashboard",
        roles: ["Super Admin"],
      },

      // View chart of accounts
      {
        id: VIEW_CHART_OF_ACCOUNTS,
        path: "viewchartofaccounts/*",
        element: (
          <ErrorBoundaryHoc errorMessage="Something Went Wrong...">
            <ViewChartOfAccountsLayout />
          </ErrorBoundaryHoc>
        ),
        children: [
          {
            path: "",
            element: <ViewChartOfAccountsLayout />,
            children: [],
            header: "View chart of accounts",
            location: "/viewchartofaccounts",
            roles: ["Super Admin"],
          },
          {
            path: "level2list",
            element: <Level2List />,
            children: [],
            header: "View chart of accounts",
            location: "/viewchartofaccounts/level2list",
            roles: ["Super Admin"],
          },
          {
            path: "level3list",
            element: <Level3List />,
            children: [],
            header: "View chart of accounts",
            location: "/viewchartofaccounts/level3list",
            roles: ["Super Admin"],
          },
          {
            path: "level4list",
            element: <Level4List />,
            children: [],
            header: "View chart of accounts",
            location: "/viewchartofaccounts/level4list",
            roles: ["Super Admin"],
          },
          {
            path: "level5list",
            element: <Level5List />,
            children: [],
            header: "View chart of accounts",
            location: "/viewchartofaccounts/level5list",
            roles: ["Super Admin"],
          },
        ],
        header: "View Chart Of Accounts",
        location: "/viewchartofaccounts",
        roles: ["Super Admin"],
      },

      // vouchers
      {
        id: VOUCHERS,
        path: "vouchers/*",
        element: (
          <ErrorBoundaryHoc errorMessage="Something Went Wrong...">
            <VouchersLayout />
          </ErrorBoundaryHoc>
        ),
        children: [
          {
            path: "",
            element: <Vouchers />,
            children: [],
            header: "Vouchers",
            location: "/vouchers",
            roles: ["Super Admin"],
          },
        ],
        header: "Vouchers",
        location: "/vouchers",
        roles: ["Super Admin"],
      },

      // Account reports
      {
        id: REPORTS,
        path: "reports/*",
        element: (
          <ErrorBoundaryHoc errorMessage="Something Went Wrong...">
            <ReportsLayout />
          </ErrorBoundaryHoc>
        ),
        children: [
          {
            path: "ledger",
            element: <Ledger />,
            children: [],
            header: "Reports - Ledger",
            location: "/reports/ledger",
            roles: ["Super Admin"],
          },
          {
            path: "trialbalance",
            element: <TrialBalance />,
            children: [],
            header: "Reports - Trial Balance",
            location: "/reports/trialbalance",
            roles: ["Super Admin"],
          },
          {
            path: "pl",
            element: <ProfitLoss />,
            children: [],
            header: "Reports - P & L",
            location: "/reports/pl",
            roles: ["Super Admin"],
          },
          {
            path: "balancesheet",
            element: <BalanceSheet />,
            children: [],
            header: "Reports - Balance Sheet",
            location: "/reports/balancesheet",
            roles: ["Super Admin"],
          },
        ],
        header: "Reports",
        location: "/reports",
        roles: ["Super Admin"],
      },

      // Manage Unavailability
      {
        id: UNAVAILABILITY,
        path: "unavailability/*",
        element: (
          <ErrorBoundaryHoc errorMessage="Something Went Wrong...">
            <UnavailabilityLayout />
          </ErrorBoundaryHoc>
        ),
        children: [
          {
            path: "",
            element: <UnavailabilityLayout />,
            children: [],
            header: "Manage Unavailability",
            location: "/unavailability",
            roles: ["Super Admin"],
          },
          {
            path: "doctor",
            element: <DoctorUnavailablity />,
            children: [],
            header: "Manage Unavailability",
            location: "/unavailability/doctor",
            roles: ["Super Admin"],
          },
          {
            path: "rooms",
            element: <DoctorUnavailablity />,
            children: [],
            header: "Manage Unavailability",
            location: "/unavailability/room",
            roles: ["Super Admin"],
          },
        ],
        header: "Manage Unavailability",
        location: "/unavailability",
        roles: ["Super Admin"],
      },

      // UPAY Configuration
      // {
      //   id: UPAY_CONFIG,
      //   path: 'upay/*',
      //   element: (
      //     <ErrorBoundaryHoc errorMessage="Something Went Wrong...">
      //       <UpayConfiguration />
      //     </ErrorBoundaryHoc>
      //   ),
      //   children: [],
      //   header: 'UPAY Configuration',
      //   location: '/upay',
      //   roles: ['Super Admin'],
      // },

      {
        id: UPAY_CONFIG,
        path: "configuration/*",
        element: (
          <ErrorBoundaryHoc errorMessage="Something Went Wrong...">
            <Configurations />
          </ErrorBoundaryHoc>
        ),
        children: [
          {
            path: "upay",
            element: (
              <ErrorBoundaryHoc errorMessage="Something Went Wrong...">
                <UpayConfiguration />
              </ErrorBoundaryHoc>
            ),
            children: [],
            header: "Configuration",
            location: "configuration/upay",
            roles: ["Super Admin"],
          },
          {
            path: "whatsup",
            element: (
              <ErrorBoundaryHoc errorMessage="Something Went Wrong...">
                <Whatsup />
              </ErrorBoundaryHoc>
            ),
            children: [],
            header: "Configuration",
            location: "configuration/whatsup",
            roles: ["Super Admin"],
          },
          {
            path: "configuration-details",
            element: (
              <ErrorBoundaryHoc errorMessage="Something Went Wrong...">
                <ConfigurationDetails />
              </ErrorBoundaryHoc>
            ),
            children: [],
            header: "Configuration",
            location: "configuration/configuration-details",
            roles: ["Super Admin"],
          },
        ],
        header: "Configuration",
        location: "/configuration",
        roles: ["Super Admin"],
      },
      // Import utility
      {
        id: IMPORT_UTILITY,
        path: "import-utility/*",
        element: (
          <ErrorBoundaryHoc errorMessage="Something Went Wrong...">
            <ImportUtility />
          </ErrorBoundaryHoc>
        ),
        children: [],
        header: "Import Utility",
        location: "/import-utility",
        roles: ["Super Admin"],
      },

      // dynamic invoice template
      {
        id: DYNAMIC_TEMPLATE,
        path: "template/*",
        element: (
          <ErrorBoundaryHoc errorMessage="Something Went Wrong...">
            <DynamicTemplateLayout />
          </ErrorBoundaryHoc>
        ),
        children: [
          {
            path: "",
            element: <DynamicTemplate />,
            children: [],
            header: "Set template for invoice & receipt",
            location: "/template",
            roles: ["MC Admin"],
          },
          {
            path: "manage/*",
            element: <ManageDynamicTemplate />,
            children: [],
            header: "Set template for invoice & receipt",
            location: "/template/manage",
            roles: ["Super Admin"],
          },
        ],
        header: "Set template for invoice & receipt",
        location: "/template",
        roles: ["MC Admin"],
      },
    ],
    header: "Home",
    roles: ["MC Admin"],
  },
];

export const notFoundRoutes: MyRoutes[] = [
  ...commonRoutes,
  {
    path: "/*",
    element: (
      <AuthWrapper>
        <MainLayout />
      </AuthWrapper>
    ),
    children: [
      {
        path: "*",
        element: <NotFound />,
        children: [],
        header: "Page Not Found",
        roles: ["MC Admin"],
      },
      {
        path: "notfound",
        element: <NotFound />,
        children: [],
        header: "Page Not Found",
        roles: ["MC Admin"],
      },
      {
        path: "notpermitted",
        element: <NotFound />,
        children: [],
        header: "Page Not Found",
        roles: ["MC Admin"],
      },
    ],
    header: "Home",
    roles: ["MC Admin"],
  },
];

export const mainRouteData: MyRoutes[] = [...commonRoutes, ...authRoutes];
