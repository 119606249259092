import { useNavigate } from "react-router-dom";
import styles from "./tableData.module.scss";
import { useAppDispatch } from "../../hooks";
import { getPatientEmrById } from "../../redux/features/patient-emr/patient/patientAsyncAction";
import { requestGenerator } from "../../utils/payloadGenerator";
import {
  AudioIcon,
  ChatTypeIcon,
  DiagnosPlayIcon,
  InpersonIcon,
  VideoCallIcon,
  DiagnosCheckIcon,
  DiagnosPauseIcon,
} from "../../components/common/svg-components";
import moment from "moment";
import { appointmentTypes } from "../data";
import agentAptImg from "../../assets/images/agentAptimg.png";
import { isSameCurentAptDate } from "../../utils/utils";
import { t } from "i18next";
import MasterAppointmentStatusDropdown from "../../components/common/status-dropdown/master-appointment-status-dropdown/MasterAppoinmentStatusDropdown";

export const dentistAppointmentHeaderData: any = [
  {
    Header: " ",
    Cell: ({ row }: any) => {
      let isAgent = row?.original?.booked_by_agent;
      return isAgent ? (
        <img
          src={agentAptImg}
          alt="agent appointmetn"
          style={{ display: "inline-block", cursor: "pointer" }}
          title={row?.original?.booked_by_user_name}
        />
      ) : (
        ""
      );
    },
  },
  {
    Header: t("RcptTableHeader.DATE"),
    // accessor: "appointment_date",
    Cell: ({ row }: any) => {
      const originalDate = row?.original?.appointment_date;
      const formattedDate = moment(originalDate).format("DD MMM YYYY");
      return <>{formattedDate}</>;
    },
  },
  {
    Header: t("RcptTableHeader.FILE NO"),
    accessor: "patient_emr_number",

    Cell: ({ row }: any) => {
      const objectId = row?.original;
      const dispatch = useAppDispatch();
      const navigate = useNavigate();
      const handleEmrRecord = (item: any) => {
        let dataPayload = {
          id: item,
        };
        dispatch(getPatientEmrById(requestGenerator(dataPayload))).then((e) => {
          if (e.type === "patient/getPatientEmrById/fulfilled") {
            navigate("/patientemr");
          }
        });
      };
      return (
        <span
          onClick={() =>
            objectId?.patient_emr_number
              ? handleEmrRecord(row?.original?.patient_id)
              : {}
          }
          style={{ cursor: "pointer", color: "#0e26a3" }}
        >
          {objectId?.patient_emr_number ? objectId?.patient_emr_number : "-"}
        </span>
      );
    },
  },
  {
    Header: t("RcptTableHeader.TYPE"),
    accessor: "appointment_type",
    Cell: ({ row }: any) => {
      return (
        <>
          {row?.original?.appointment_type === "INPERSON" ? (
            <InpersonIcon />
          ) : row?.original?.appointment_type === "CHAT" ? (
            <ChatTypeIcon />
          ) : row?.original?.appointment_type === "AUDIO" ? (
            <AudioIcon />
          ) : (
            <VideoCallIcon />
          )}
        </>
      );
    },
  },

  {
    Header: t("RcptTableHeader.PATIENT"),
    accessor: "patient_name",
  },
  {
    Header: t("RcptTableHeader.MOBILE NO"),
    accessor: "patient_phone_number",
  },

  {
    Header: t("RcptTableHeader.TIME"),
    accessor: "time_in",
    Cell: ({ row }: any) => {
      let start_time = row?.original?.time_in;
      let duration = row?.original?.time_duration;
      const end_time = moment(start_time, "HH:mm")
        .add(Number(duration), "minutes")
        .format("HH:mm");
      return <>{`${start_time} to ${end_time}`}</>;
    },
  },
  {
    Header: "STATUS",
    Cell: ({ row }: any) => {
      return (
        <>
          <MasterAppointmentStatusDropdown
            appointmentStatus={row?.original?.status}
            isDisable={true}
          />
        </>
      );
    },
  },

  {
    Header: t("RcptTableHeader.INVOICE"),

    Cell: ({ row }: any) => {
      return (
        <>
          {row?.original?.invoice_status ? (
            <span
              className={
                row?.original?.invoice_status === "TO BE GENERATE"
                  ? styles.toBeGeneratedDoc
                  : styles[row?.original?.invoice_status]
              }
              style={{ cursor: "default" }}
              // onClick={() => handleInvoice(row?.original)}
            >
              {row?.original?.invoice_status}
            </span>
          ) : (
            "-"
          )}
        </>
      );
    },
  },
  {
    Header: t("DentistDashbord.DOCTOR DIAGNOSIS"),
    Cell: ({ row }: any) => {
      const patient_diagnosis_id = row?.original?.dental_diagnosis_id;
      const dispatch = useAppDispatch();
      const navigate = useNavigate();
      const handleEdit = (item: any) => {
        if (
          row?.original?.status === "CANCELLED" ||
          row?.original?.status === "WAITINGLIST" ||
          row?.original?.status === "NOSHOW" ||
          row?.original?.status === "BOOKED" ||
          row?.original?.status === "NOANSWER" ||
          !row?.original.patient_emr_number ||
          appointmentTypes.includes(row?.original?.appointment_type)
        ) {
          return null;
        } else {
          navigate("/patientdentaldiagnosis/diagnosis", {
            state: {
              user: item,
              dentistDiagnosis: { createdDiagnosisId: patient_diagnosis_id },
            },
          });
        }
      };
      return (
        <>
          {row?.original?.diagnosis_stage === "NS" ? (
            <DiagnosPlayIcon
              fillColor={
                row?.original?.status === "CANCELLED" ||
                row?.original?.status === "WAITINGLIST" ||
                row?.original?.status === "NOSHOW" ||
                row?.original?.status === "BOOKED" ||
                row?.original?.status === "NOANSWER" ||
                !row?.original.patient_emr_number ||
                appointmentTypes.includes(row?.original?.appointment_type)
                  ? "#797979"
                  : "#02BF90"
              }
              style={{
                cursor:
                  row?.original?.status === "CANCELLED" ||
                  row?.original?.status === "WAITINGLIST" ||
                  row?.original?.status === "NOSHOW" ||
                  row?.original?.status === "BOOKED" ||
                  row?.original?.status === "NOANSWER" ||
                  !row?.original.patient_emr_number ||
                  appointmentTypes.includes(row?.original?.appointment_type)
                    ? "initial"
                    : "pointer",
              }}
              handleClick={() => {
                if (isSameCurentAptDate(row?.original?.appointment_date)) {
                  handleEdit(row?.original);
                }
              }}
            />
          ) : row?.original?.diagnosis_stage === "S" ? (
            <DiagnosPauseIcon
              fillColor="#FFA009"
              handleClick={() => {
                if (isSameCurentAptDate(row?.original?.appointment_date)) {
                  handleEdit(row?.original);
                }
              }}
            />
          ) : row?.original?.diagnosis_stage === "E" ? (
            row?.original?.invoice_status === "TO BE GENERATE" &&
            isSameCurentAptDate(row?.original?.appointment_date) ? (
              <DiagnosPauseIcon
                fillColor="#FFA009"
                handleClick={() => {
                  handleEdit(row?.original);
                }}
              />
            ) : (
              <DiagnosCheckIcon fillColor="#5936F1" />
            )
          ) : (
            ""
          )}
        </>
      );
    },
  },
];
