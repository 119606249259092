import { useState, useEffect, useRef } from "react";
import MobileAppoinmentRequestModal from "../../../../pages/mobile-appointment-request/mobile-appointment-request-modal/MobileAppoinmentRequestModal";
import BookingConfirmationModal from "../../../common/modal/booking-confirmation-modal/BookingConfirmationModal";
import StatusConfirmationPopupV3 from "../../../common/modal/status-confirmation-popup/status-confirmation-popupV3/StatusConfirmationPopupV3";
import IpdBookingPopup from "../../../../pages/ipd/ipd-booking/IpdBooking";
import { requestGenerator } from "../../../../utils/payloadGenerator";
import { useAppDispatch, useAppSelector } from "../../../../hooks/index";
import {
  cancelAppointmentStatus,
  getAllMobileAppointment,
} from "../../../../redux/features/mobile-appointment-request/mobileAppointmentRequestAsyncActions";
import {
  getAllBookedBeds,
  getIpdBookDetails,
  updateIpdBooking,
} from "../../../../redux/features/ipd-booking/ipdBookingAsyncActions";
import {
  clearSlotData,
  addColorSchemeData,
  setFilterDoctorList,
  setCalendarStartEndTime,
  clearData,
  setSelectedBranch,
} from "../../../../redux/features/appointments/bookingAppointmentsSlice";
import {
  CANCEL_APPOINTMENT,
  UPDATE_IPD_BOOK_TYPE,
  GET_IPD_BOOK_TYPE,
} from "../../../../constants/asyncActionsType";
import { clearIpdBookingDropDownData } from "../../../../redux/features/ipd-booking/ipdBookingSlice";
import Popup from "../../popup/Popup";
import Button from "../../button/Button";
import InfoTooltip from "../../info-tooltip/InfoTooltip";
import ColorScheme from "../../color-scheme/ColorScheme";

import StatusSummaryPopup from "../../modal/view-appointment-popup/status-summary-popup/StatusSummaryPopup";
import {
  caladerDropdownData,
  caladerDropdownDataWithoutBed,
  colorSchemeData,
} from "../../../../constants/data";
import SearchModal from "../../../../pages/ipd/ipd-booking/SearchModal";
import PatientHistoryNotesModal from "../../../../components/common/modal/patient-history-notes-modal/PatientHistoryNotesModal";
import IpdBookPopup from "../../../../pages/ipd/ipd-booking/IpdBookPopup";
import DropdownV2 from "../../dropdown/dropdownv2/DropdownV2";
import { useNavigate, useLocation } from "react-router";
import OnlinePaymentModal from "../../modal/online-payment-modal/OnlinePaymentModal";
import { CustomModal } from "../../custom-modal/modal";
import PhysioAppointmentBooking from "../../../../pages/physio/page";
import styles from "./schedularHeader.module.scss";
import { IPD_BOOKING } from "../../../../config/modules";
import { hasHeaderMenuAccess, selectedCalView } from "../../../../utils/utils";
import { useTranslation } from "react-i18next";
import {
  getAllDoctorAppointments,
  getAllDoctorAppointmentsWithDate,
  getAllDoctors,
  getAllPhysioAppointments,
  getAllPhysioAppointmentsWithDate,
  getAllRoomsForCalnedar,
  getAllRoomsForCalnedarDrodown,
} from "../../../../redux/features/appointments/bookingAppointmentAsyncActions";
import Filter from "../../filter/Filter";
import ReloadButton from "../../reload-button/ReloadButton";
import PaginationV2 from "../../pagination/paginationv2/PaginationV2";
import { getAllDepartment } from "../../../../redux/features/department/departmentAsyncActions";

const SchedularHeader = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { getAllMobileAppointPayloadData } = useAppSelector(
    (state) => state.mobileAppointmentRequest
  );
  const { colorSchemeList, sidebarData } = useAppSelector(
    (state) => state.login
  );
  const { branchData } = useAppSelector((state) => state.ipdBooking);
  const {
    currentViewDate,
    resources,
    hasMorePage,
    totalCount,
    filterList,
    allRoomsList,
    allDoctorsList,
  } = useAppSelector((state) => state.appointments);
  console.log("allRoomsList", allRoomsList);
  console.log("fcurrentViewDateirst", currentViewDate);
  const { departmentData } = useAppSelector((state) => state.department);
  const loginDetails = useAppSelector((state) => state.login);
  const [calenderView, setCalenderView] = useState<any>("");

  const itemsPerPage = 5;
  const lastPage = Math.ceil(totalCount / itemsPerPage);

  // Define state variables
  const [showMobileAppointmentRequest, setShowMobileAppointmentRequest] =
    useState<boolean>(false);
  const [showBookingConfirmationModal, setShowBookingConfirmationModal] =
    useState<boolean>(false);
  const [showStatusConfirmationModal, setStatusConfirmationModal] =
    useState<boolean>(false);
  const [statusSummary, setStatusSummary] = useState<boolean>(false);
  const [togglePayloadData, setTogglePayloadData] = useState<any>({});
  const [showIpdBooking, setIpdBooking] = useState<boolean>(false);
  const [showSearchModal, setShowSearchModal] = useState<boolean>(false);
  const [patientData, setPateintData] = useState<any>({});
  const [showIpdDescriptionModal, setShowIpdDescriptionModal] =
    useState<boolean>(false);
  const [ipdDescriptionPopupData, setIpdDescriptionPopupData] = useState<any>(
    {}
  );
  const [showIpdNotesModal, setShowIpdNotesModal] = useState<boolean>(false);
  const [ipdNotesPopupData, setIpdNotesPopupData] = useState<any>({});
  const [showIpdBookModal, setShowIpdBookModal] = useState<boolean>(false);
  const [ipdBookModalPopupData, setIpdBookModalPopupData] = useState<any>({});
  const [ipdBookIngModalData, setIpdBookingModalData] = useState<any>({});
  const [selectedItem, setSelectedItem] = useState<any>(caladerDropdownData[0]);
  const [showOnlinePaymentModeModal, setShowOnlinePaymentModeModal] =
    useState<boolean>(false);
  const [onlinePaymentLinkData, setOnlinePaymentLinkData] = useState<any>({
    patient_default_branch_id: "",
    name: "Rahul Patel",
    phone: "965989898989800",
    email: "rahul@yopmail.com",
    invoice_no: "123456",
    amount: "80",
    // link: upayLinkObject?.link,
    // transaction_id: upayLinkObject?.transaction_id,
    note: "test note",
    transaction_id: "",
    link: "https://api.upayments.com/live/new-knet-payment?ref=m7OxY4Bqva65014e139319ea424297b81a1694584340164456273065014e145ea45&sess_id=1d2abf928d57c24ca791bc431482c01d&alive=TVZ2MEdtbG8yYA==",
    // link: null,
  });
  const [physioBookingModal, setPhysioBookingModal] = useState(false);

  const [searchString, setSearchString] = useState("");
  const [selectedBranchItem, setSelectedBranchItem] = useState({
    name: "Select Branch",
    _id: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedDoctorItem, setSelectedDoctorItem] = useState({
    name: "Select All",
    _id: "",
  });
  const [selectedDeptItem, setSelectedDeptItem] = useState({
    name: "Select All",
    _id: "",
  });
  const [reoladDate, setReloadDate] = useState(new Date());
  const [startIndex, setStartIndex] = useState(0);
  const [lastIndex, setlastIndex] = useState(5);

  // Room
  const [selectedItemRoom, setSelectedItemRoom] = useState<any>({
    name: "Select All",
    _id: "",
  });
  const [allRomms, setAllRomms] = useState<any>([]);
  const { doctorData } = useAppSelector((state) => state.appointments);
  const { doctorData: roomData } = useAppSelector(
    (state) => state.appointments
  );

  const handleMobileAppointmentRequestModal = () => {
    setShowMobileAppointmentRequest((prevState) => !prevState);
  };

  const handleBookingConfirmationModal = () => {
    setShowBookingConfirmationModal((prevState) => !prevState);
    dispatch(clearSlotData());
  };

  const handleStatusConfirmationModal = (statusData: any) => {
    setStatusConfirmationModal((prevState) => !prevState);
    setTogglePayloadData(statusData);
  };

  const handleStatusConfirmationModalClose = () => {
    setStatusConfirmationModal((prevState) => !prevState);
    setTogglePayloadData({});
  };

  const handleStatusConfirmationAPICall = (data: any) => {
    const payload = { ...togglePayloadData };
    payload.reason = data.reason;
    dispatch(cancelAppointmentStatus(requestGenerator(payload))).then((e) => {
      if (e.type === `${CANCEL_APPOINTMENT}/fulfilled`) {
        dispatch(
          getAllMobileAppointment(
            requestGenerator(getAllMobileAppointPayloadData)
          )
        );
        handleStatusConfirmationModalClose();
      }
    });
  };

  useEffect(() => {
    dispatch(addColorSchemeData(colorSchemeList));
  }, [dispatch, colorSchemeList]);

  // handle patient search modal
  const handlePatientSearchModalOpen = () => {
    setShowSearchModal((prevState) => !prevState);
  };

  const handlePatientSearchModalClose = () => {
    setShowSearchModal((prevState) => !prevState);
  };
  // funtion for handling IPD booking modal
  const handlIpdBookingModal = () => {
    setPateintData({});
    setIpdBookModalPopupData({});
    setIpdBookingModalData({});
    setIpdBooking((prevState) => !prevState);
    dispatch(clearIpdBookingDropDownData());
  };

  const handlAddIpdBookModal = () => {
    let payalodData = {};
    if (ipdBookIngModalData?.discharge_date) {
      payalodData = {
        branch_id: branchData?._id,
        bed_id: ipdBookModalPopupData?._id,
        ipd_id: ipdBookModalPopupData?.ipd_id,
        patient_id: ipdBookModalPopupData?.patient_id,
        admission_date: ipdBookIngModalData?.admitted_date,
        booking_date: ipdBookIngModalData?.booking_date,
        discharge_date: ipdBookIngModalData?.discharge_date,
        primary_doctor_id: ipdBookIngModalData?.primary_doctor?.value,
      };
    } else {
      payalodData = {
        branch_id: branchData?._id,
        bed_id: ipdBookModalPopupData?._id,
        ipd_id: ipdBookModalPopupData?.ipd_id,
        patient_id: ipdBookModalPopupData?.patient_id,
        admission_date: ipdBookIngModalData?.admitted_date,
        booking_date: ipdBookIngModalData?.booking_date,
        primary_doctor_id: ipdBookIngModalData?.primary_doctor?.value,
      };
    }
    dispatch(updateIpdBooking(requestGenerator(payalodData))).then((e) => {
      if (e.type === `${UPDATE_IPD_BOOK_TYPE}/fulfilled`) {
        setPateintData({});
        setIpdBookModalPopupData({});
        setIpdBookingModalData({});
        setIpdBooking((prevState) => !prevState);
        dispatch(clearIpdBookingDropDownData());
        if (path === "/schedular/beds") {
          dispatch(
            getAllBookedBeds(requestGenerator({ branch_id: branchData?._id }))
          );
        } else {
          navigate("/schedular/beds", {
            state: { notRenderSidebar: true },
          });
        }
      }
    });
  };
  // handle ipd booking notes modal
  const handleIpdBookingDescriptionModalOpen = (descriptionData: any) => {
    const ipdDescriptionData = {
      diag_note: descriptionData?.description_notes,
      diag_apt_date: new Date(),
    };
    setIpdDescriptionPopupData(ipdDescriptionData);
    setShowIpdDescriptionModal((prevState) => !prevState);
  };
  const handleIpdBookingDescriptionModalClose = () => {
    setIpdDescriptionPopupData({});
    setShowIpdDescriptionModal((prevState) => !prevState);
  };

  const handleIpdBookingNotesModalOpen = (descriptionData: any) => {
    const ipdNotesData = {
      diag_note: descriptionData?.notes,
      diag_apt_date: new Date(),
    };
    setIpdNotesPopupData(ipdNotesData);
    setShowIpdNotesModal((prevState) => !prevState);
  };
  const handleIpdBookingNotesModalClose = () => {
    setIpdNotesPopupData({});
    setShowIpdNotesModal((prevState) => !prevState);
  };
  // handle ipd book modal
  const handleIpdBookModalOpen = (bookModalData: any) => {
    const ipdBookModalData = {
      ...bookModalData,
    };
    const payload = {
      bed_id: bookModalData?._id,
      ipd_id: bookModalData?.ipd_id,
    };
    dispatch(getIpdBookDetails(requestGenerator(payload))).then((e) => {
      if (e.type === `${GET_IPD_BOOK_TYPE}/fulfilled`) {
        setIpdBookModalPopupData(ipdBookModalData);
        setShowIpdBookModal((prevState) => !prevState);
      }
    });
  };
  const handleIpdBookModalClose = () => {
    setIpdBookModalPopupData({});
    setIpdBookingModalData({});
    setShowIpdBookModal((prevState) => !prevState);
  };
  const handleIpdBookModal = (data: any) => {
    setIpdBookingModalData(data);
    setShowIpdBookModal((prevState) => !prevState);
  };

  const bedColorScheme = [
    {
      title: "Booked",
      label: "BOOKED",
      colorCode: "#0aa423",
      bgColor: "#a1fbb0",
    },
  ];
  const path = useLocation().pathname;
  const campaignData = useLocation().state;
  const invoiceOnlinePaymentModalClose = () => {
    setShowOnlinePaymentModeModal(!showOnlinePaymentModeModal);
    setOnlinePaymentLinkData({});
  };

  useEffect(() => {
    // if (path === '/schedular') {
    const item = selectedCalView(path);
    setSelectedItem(item);
    // }
  }, [path]);

  // Get All Doctors
  useEffect(() => {
    let data = {
      search: searchString,
      department: selectedDeptItem?._id,
      branch: selectedBranchItem?._id,
    };
    selectedBranchItem?._id && dispatch(getAllDoctors(requestGenerator(data)));
  }, []);

  // Get All Branches
  useEffect(() => {
    loginDetails?.branchData?.branches?.length > 0
      ? setSelectedBranchItem({
          name: loginDetails?.branchData?.branches[0].name,
          _id: loginDetails?.branchData?.branches[0]._id,
        })
      : setSelectedBranchItem({ name: "Select Branch", _id: "" });
      
    dispatch(
      setSelectedBranch({
        name: loginDetails?.branchData?.branches[0].name,
        _id: loginDetails?.branchData?.branches[0]._id,
      })
    );
  }, [loginDetails?.branchData?.branches]);

  useEffect(() => {
    const array = resources[0]?.dataSource?.filter(
      (item: any, index: number) => {
        return index >= startIndex && index < lastIndex;
      }
    );
    dispatch(
      setFilterDoctorList([
        {
          label: "Doctors",
          value: "_id",
          dataSource: array || [],
        },
      ])
    );
  }, [resources, startIndex, lastIndex]);

  // login for filter doctors
  useEffect(() => {
    if (path === "/schedular") {
      if (selectedDoctorItem?._id === "") {
        const array = resources[0]?.dataSource?.filter(
          (item: any, index: number) => {
            return index >= 0 && index < 5;
          }
        );
        dispatch(
          setFilterDoctorList([
            { label: "Doctors", value: "_id", dataSource: array || [] },
          ])
        );
        setStartIndex(0);
        setlastIndex(5);
      } else {
        let tempArr = [];
        tempArr =
          resources?.length > 0 &&
          resources[0]?.dataSource?.filter(
            (item: any) => item?.id === selectedDoctorItem?._id
          );
        dispatch(
          setFilterDoctorList([
            {
              label: "Doctors",
              value: "_id",
              dataSource: tempArr || [],
            },
          ])
        );
        dispatch(setCalendarStartEndTime(tempArr));
      }
    }
  }, [resources]);

  // login for filter Room
  useEffect(() => {
    if (path === "/schedular/room") {
      if (selectedItemRoom?._id === "") {
        dispatch(setFilterDoctorList(resources));
      } else {
        let tempArr = [];
        tempArr =
          resources?.length > 0 &&
          resources[0]?.dataSource?.filter(
            (item: any) => item?.id === selectedItemRoom?._id
          );
        dispatch(
          setFilterDoctorList([
            {
              label: "Rooms",
              value: "_id",
              dataSource: tempArr || [],
            },
          ])
        );
      }
    }
  }, [resources]);

  // reolad
  const handleReloadButton = () => {
    dispatch(
      getAllDoctorAppointments(
        requestGenerator({ branch_id: selectedBranchItem?._id })
      )
    );
    setReloadDate(new Date());
  };

  // go to previous page
  const handlePrevious = () => {
    if (startIndex > 0) {
      setStartIndex((pre) => pre - 5);
      setlastIndex((pre) => pre - 5);
    }
  };

  // go to next page
  const handleNext = () => {
    if (
      resources[0]?.dataSource?.length > lastIndex &&
      filterList[0]?.dataSource?.length > 1
    ) {
      setStartIndex((pre) => pre + 5);
      setlastIndex((pre) => pre + 5);
    }
  };

  const handleFilter = () => {
    if (selectedDeptItem?._id || selectedBranchItem?._id) {
      let data = {
        search: searchString,
        department: selectedDeptItem?._id,
        branch: selectedBranchItem?._id,
      };
      selectedBranchItem?._id &&
        dispatch(getAllDoctors(requestGenerator(data)));
    }
    // dispatch(
    //   getAllDoctorAppointments(
    //     requestGenerator({ branch_id: selectedBranchItem?._id })
    //   )
    // );
    setStartIndex(0);
    setlastIndex(5);
    setCurrentPage(1);

    if (
      calenderView?.navigate !== null &&
      hasHeaderMenuAccess(sidebarData, IPD_BOOKING)
    ) {
      navigate(calenderView?.navigate, {
        state: { notRenderSidebar: true },
      });
    }
  };

  const handleFilterRoom = () => {
    let data = {
      search: searchString,
      page: currentPage,
      pageSize: itemsPerPage,
      order_by: { name: 1 },
      is_active: true,
      type: "OPD",
    };
    dispatch(getAllRoomsForCalnedar(requestGenerator(data)));
    // selectedBranchItem?._id &&
    //   dispatch(
    //     getAllPhysioAppointments(
    //       requestGenerator({ branch_id: selectedBranchItem?._id })
    //     )
    //   );

    if (
      calenderView?.navigate !== null &&
      hasHeaderMenuAccess(sidebarData, IPD_BOOKING)
    ) {
      navigate(calenderView?.navigate, {
        state: { notRenderSidebar: true },
      });
    }
    setCurrentPage(1);
  };
  // go to next page Room
  const handleNextRoom = () => {
    if (filterList[0]?.dataSource?.length > 1) {
      setSearchString("");
      currentPage < lastPage
        ? setCurrentPage(currentPage + 1)
        : setCurrentPage(currentPage);
    }
  };

  // go to previous page Room
  const handlePreviousRoom = () => {
    if (currentPage > 1) {
      setSearchString("");
      currentPage !== 1 ? setCurrentPage(currentPage - 1) : setCurrentPage(1);
    }
  };

  // fetch Room
  useEffect(() => {
    if (path == "/schedular/room") {
      let data = {
        search: searchString,
        page: currentPage,
        pageSize: itemsPerPage,
        order_by: { name: 1 },
        is_active: true,
        type: "OPD",
      };
      dispatch(getAllRoomsForCalnedar(requestGenerator(data)));
    }
  }, [currentPage, itemsPerPage, searchString]);

  useEffect(() => {
    // if (selectedItem?.name == "Room") {
    let data = {
      search: searchString,
      page: currentPage,
      pageSize: itemsPerPage,
      order_by: { name: 1 },
      is_active: true,
      type: "OPD",
    };
    dispatch(getAllRoomsForCalnedarDrodown(requestGenerator(data))).then(
      (res: any) => {
        if (res?.type === "appoinments/getAllRoomsForCalnedarList/fulfilled") {
          setAllRomms(res.payload?.data);
        }
      }
    );
    // }
  }, []);

  useEffect(() => {
    if (path === "/schedular") {
      const interval = setInterval(() => {
        dispatch(
          getAllDoctorAppointments(
            requestGenerator({
              branch_id: selectedBranchItem?._id,
              date: new Date(currentViewDate),
            })
          )
        );
      }, 60000);
      return () => clearInterval(interval);
    } else {
      const interval = setInterval(() => {
        dispatch(
          getAllPhysioAppointments(
            requestGenerator({
              branch_id: selectedBranchItem?._id,
              date: new Date(currentViewDate),
            })
          )
        );
      }, 60000);
      return () => clearInterval(interval);
    }
    // return () => {};
  }, [selectedBranchItem?._id, currentViewDate, path]);

  useEffect(() => {
    if (currentViewDate) {
      if (path === "/schedular") {
        dispatch(
          getAllDoctorAppointmentsWithDate(
            requestGenerator({
              branch_id: selectedBranchItem?._id,
              date: new Date(currentViewDate),
            })
          )
        );
      } else {
        dispatch(
          getAllPhysioAppointmentsWithDate(
            requestGenerator({
              branch_id: selectedBranchItem?._id,
              date: new Date(currentViewDate),
            })
          )
        );
      }
    }
  }, [currentViewDate, path]);

  useEffect(() => {
    dispatch(
      getAllDepartment(
        requestGenerator({
          search: "",
          page: 1,
          pageSize: 500,
        })
      )
    );
  }, [dispatch]);
  return (
    <>
      {statusSummary && (
        <Popup
          Children={StatusSummaryPopup}
          handleClose={() => setStatusSummary(false)}
          popData={{ date: currentViewDate ? currentViewDate : "" }}
        />
      )}
      {showMobileAppointmentRequest && (
        <Popup
          Children={MobileAppoinmentRequestModal}
          handleClose={handleMobileAppointmentRequestModal}
          handleSubmit={handleBookingConfirmationModal}
          handleOpen={handleStatusConfirmationModal}
        />
      )}
      {showBookingConfirmationModal && (
        <Popup
          Children={BookingConfirmationModal}
          handleClose={handleBookingConfirmationModal}
        />
      )}
      {showStatusConfirmationModal && (
        <Popup
          Children={StatusConfirmationPopupV3}
          handleClose={handleStatusConfirmationModalClose}
          handleOpen={handleStatusConfirmationAPICall}
          message={t("Common.Are you sure") || "Are you sure ?"}
          heading={
            t("StatusConfirmation.Cancel appointment") || "Cancel appointment"
          }
        />
      )}
      {showIpdBooking && (
        <Popup
          Children={IpdBookingPopup}
          handleOpen={handlePatientSearchModalOpen}
          handleClose={handlIpdBookingModal}
          handleYes={handleIpdBookingDescriptionModalOpen}
          handleRowClick={handleIpdBookingNotesModalOpen}
          handleSubmit={handleIpdBookModalOpen}
          popData={patientData}
          handleSubmitData={ipdBookIngModalData}
          handleRowData={ipdBookModalPopupData}
          handleConfirm={handlAddIpdBookModal}
        />
      )}
      {showSearchModal && (
        <Popup
          Children={SearchModal}
          handleClose={handlePatientSearchModalClose}
          handleRowClick={(item: any) => {
            setPateintData(item);
            handlePatientSearchModalClose();
          }}
        />
      )}
      {showIpdDescriptionModal && (
        <Popup
          Children={PatientHistoryNotesModal}
          handleClose={handleIpdBookingDescriptionModalClose}
          popData={ipdDescriptionPopupData}
          heading={t("DoctorDiagnosis.Description") || "Description"}
        />
      )}
      {showIpdNotesModal && (
        <Popup
          Children={PatientHistoryNotesModal}
          handleClose={handleIpdBookingNotesModalClose}
          popData={ipdNotesPopupData}
          heading={t("Common.Notes") || "Notes"}
        />
      )}
      {showIpdBookModal && (
        <Popup
          Children={IpdBookPopup}
          popData={ipdBookModalPopupData}
          handleClose={handleIpdBookModalClose}
          handleYes={handleIpdBookModal}
        />
      )}
      {showOnlinePaymentModeModal && (
        <Popup
          Children={OnlinePaymentModal}
          popData={onlinePaymentLinkData}
          handleClose={() => invoiceOnlinePaymentModalClose()}
          setModelOpenClose={setShowOnlinePaymentModeModal}
          message="/invoice/information"
        />
      )}
      <CustomModal
        showModal={physioBookingModal}
        closeModal={() => setPhysioBookingModal(false)}
        title={t("RoomBooking.Room Booking") || "Room Booking"}
        // width="68dvw"
        // height="95dvh"
      >
        <PhysioAppointmentBooking
          closeModal={() => setPhysioBookingModal(false)}
        />
      </CustomModal>
      <div className={styles.headerContainer}>
        <div className={styles.mainHeaderContainer}>
          <div className={styles.buttonContainer}>
            <Button
              title={t("SchedularHeader.ShareLink") || "Share Payment Link"}
              customClass={styles.buttonOutline}
              type="button"
              disable={campaignData?.lead?._id ? true : false}
              handleClick={() => setShowOnlinePaymentModeModal(true)}
            />
            <Button
              title={t("RoomBooking.Room Booking") || "Room Booking"}
              customClass={styles.buttonOutline}
              disable={campaignData?.lead?._id ? true : false}
              handleClick={() => setPhysioBookingModal(true)}
            />
            {hasHeaderMenuAccess(sidebarData, IPD_BOOKING) && (
              <Button
                title={t("IPDBooking.IPD Booking") || "IPD Booking"}
                customClass={styles.buttonOutline}
                handleClick={handlIpdBookingModal}
                disable={campaignData?.lead?._id ? true : false}
              />
            )}

            <Button
              title={
                t("SchedularHeader.MoAptReq") || "Mobile Appointment Request"
              }
              customClass={styles.buttonOutline}
              disable={campaignData?.lead?._id ? true : false}
              handleClick={handleMobileAppointmentRequestModal}
            />
            <Button
              title={t("ViewAppointment.Status Summary") || "Status Summary"}
              customClass={styles.buttonOutline}
              disable={campaignData?.lead?._id ? true : false}
              handleClick={() => setStatusSummary(true)}
            />
          </div>
        </div>

        <div className={styles.filterContainer}>
          <div className={styles.reloadPaginationComponent}>
            <ReloadButton
              customClass={styles.mobileAppointmentReloadButton}
              handleClick={handleReloadButton}
              reoladDate={reoladDate}
            />

            {path == "/schedular" ? (
              <PaginationV2
                handleNext={() => {
                  handleNext();
                }}
                handlePrevious={() => {
                  handlePrevious();
                }}
                handleReset={() => setCurrentPage(1)}
                nextPage={
                  resources[0]?.dataSource?.length > lastIndex &&
                  filterList[0]?.dataSource?.length > 1
                }
                previousPage={startIndex > 0 ? false : true}
              />
            ) : (
              <PaginationV2
                handleNext={() => handleNextRoom()}
                handlePrevious={() => handlePreviousRoom()}
                handleReset={() => setCurrentPage(1)}
                nextPage={hasMorePage && filterList[0]?.dataSource?.length > 1}
                previousPage={currentPage > 1 ? false : true}
              />
            )}
          </div>

          <div className={styles.colorSchemeContainer}>
            <InfoTooltip
              title={t("SchedularHeader.Color Scheme") || "Color Scheme"}
              Children={ColorScheme}
              tooltipData={
                path === "/schedular/beds"
                  ? bedColorScheme
                  : colorSchemeList && colorSchemeList.length > 0
                  ? colorSchemeList
                  : colorSchemeData
              }
            />

            {/* {path !== '/schedular/beds' && (
            <InfoTooltip
              title=" Allow double bookings"
              Children={DoubleInfoBookingInfo}
              customClassChild={styles.doubleBookingMsg}
              tooltipData={{
                message: "No. of Appointments per slot",
                count: 3,
              }}
            />
          )} */}
          </div>
          <Filter title="Filter">
            <>
              <div className={styles.childrenItemContainer}>
                <DropdownV2
                  data={
                    hasHeaderMenuAccess(sidebarData, IPD_BOOKING)
                      ? caladerDropdownData
                      : caladerDropdownDataWithoutBed
                  }
                  type={t("SchedularHeader.Calendar View") || "Calendar View"}
                  keyName="name"
                  selectedItem={selectedItem}
                  setSelectedItem={setSelectedItem}
                  notShowAllOption={true}
                  handleClick={(item: any) => {
                    if (
                      item?.navigate !== null &&
                      hasHeaderMenuAccess(sidebarData, IPD_BOOKING)
                    ) {
                      setSelectedItem({ name: item?.name, _id: item?._id });
                      setCalenderView(item);
                    }
                  }}
                  isDisable={campaignData?.lead?._id ? true : false}
                  // customClass={styles.dropownContainer}
                  // customClassTitle={styles.custonClassTitle}
                  // customClassBox={styles.customClassBOx}
                  // customClassBody={styles.customClassBody}
                  customClass={styles.filterDropDownStyle}
                  customClassTitle={styles.dropDownLabelStyle}
                />

                {selectedItem?.name === "Doctor" ? (
                  <>
                    <DropdownV2
                      // data={doctorData}
                      data={allDoctorsList}
                      type={t("Receptionist Dashboard.Doctor") || "Doctor"}
                      keyName="doctor_name"
                      selectedItem={selectedDoctorItem}
                      setSelectedItem={setSelectedDoctorItem}
                      handleClick={(item: any) => {
                        setSelectedDoctorItem({
                          name: item?.doctor_name,
                          _id: item?._id,
                        });
                        // setSelectedDeptItem({ name: "Select All", _id: "" });
                        // setSelectedBranchItem({ name: "Select All", _id: "" })
                      }}
                      customClass={styles.filterDropDownStyle}
                      customClassTitle={styles.dropDownLabelStyle}
                    />
                    <DropdownV2
                      data={
                        departmentData && departmentData?.length > 0
                          ? departmentData?.filter(
                              (item: any) => item?.is_active
                            )
                          : []
                      }
                      type={t("IPDBooking.Department") || "Department"}
                      keyName="name"
                      selectedItem={selectedDeptItem}
                      setSelectedItem={setSelectedDeptItem}
                      handleClick={(item: any) => {
                        setSelectedDeptItem({
                          name: item?.name,
                          _id: item?._id,
                        });
                        // setSelectedItem({ name: "Select All", _id: "" });
                      }}
                      customClass={styles.filterDropDownStyle}
                      customClassTitle={styles.dropDownLabelStyle}
                    />
                    <DropdownV2
                      data={loginDetails?.branchData?.branches}
                      type={t("Header.Branch") || "Branch"}
                      keyName="name"
                      selectedItem={selectedBranchItem}
                      setSelectedItem={setSelectedBranchItem}
                      notShowAllOption={true}
                      handleClick={(item: any) => {
                        setSelectedBranchItem({
                          name: item?.name,
                          _id: item?._id,
                        });
                        // setSelectedItem({ name: "Select All", _id: "" });
                        dispatch(
                          setSelectedBranch({
                            name: item?.name,
                            _id: item?._id,
                          })
                        );
                      }}
                      customClass={styles.filterDropDownStyle}
                      customClassTitle={styles.dropDownLabelStyle}
                    />
                  </>
                ) : (
                  <>
                    <DropdownV2
                      // data={roomData}
                      // data={allRomms}
                      data={allRoomsList}
                      type={t("IPDBooking.Room") || "Room"}
                      keyName="room_name"
                      selectedItem={selectedItemRoom}
                      setSelectedItem={setSelectedItemRoom}
                      handleClick={(item: any) => {
                        setSelectedItemRoom({
                          name: item?.room_name,
                          _id: item?._id,
                        });
                      }}
                      customClass={styles.filterDropDownStyle}
                      customClassTitle={styles.dropDownLabelStyle}
                    />

                    <DropdownV2
                      data={loginDetails?.branchData?.branches}
                      type={t("Header.Branch") || "Branch"}
                      keyName="name"
                      selectedItem={selectedBranchItem}
                      setSelectedItem={setSelectedBranchItem}
                      notShowAllOption={true}
                      handleClick={(item: any) => {
                        setSelectedBranchItem({
                          name: item?.name,
                          _id: item?._id,
                        });
                        // setSelectedItem({ name: "Select All", _id: "" });
                        dispatch(
                          setSelectedBranch({
                            name: item?.name,
                            _id: item?._id,
                          })
                        );
                      }}
                      customClass={styles.filterDropDownStyle}
                      customClassTitle={styles.dropDownLabelStyle}
                    />
                  </>
                )}
              </div>

              <div className={styles.btnContainer}>
                <Button
                  title={"Apply"}
                  customClass={styles.applyButton}
                  handleClick={() => {
                    if (selectedItem?.name === "Doctor") {
                      handleFilter();
                    } else {
                      handleFilterRoom();
                    }
                  }}
                />
              </div>
            </>
          </Filter>
        </div>
      </div>
    </>
  );
};

export default SchedularHeader;
