// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".patientCard_container__CPHcr {\n  width: 50%;\n  border: 1px solid var(--grey6);\n  border-radius: 10px;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n}\n.patientCard_container__CPHcr .patientCard_patientCount__dvpAp {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 20px;\n  margin: 30px 0px 0px 0px;\n}\n.patientCard_container__CPHcr .patientCard_patientCount__dvpAp .patientCard_labelValue__5g5a\\+ {\n  display: flex;\n  flex-direction: column;\n}\n.patientCard_container__CPHcr .patientCard_graph__5vr54 {\n  display: inline-block;\n  width: 100%;\n  border-bottom-left-radius: 10px;\n  border-bottom-right-radius: 10px;\n}", "",{"version":3,"sources":["webpack://./src/components/common/modal/kpi-revenue/patient-card/patientCard.module.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,8BAAA;EAEA,mBAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;AAAF;AACE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,SAAA;EACA,wBAAA;AACJ;AAAI;EACE,aAAA;EACA,sBAAA;AAEN;AACE;EACE,qBAAA;EACA,WAAA;EACA,+BAAA;EACA,gCAAA;AACJ","sourcesContent":[".container {\n  width: 50%;\n  border: 1px solid var(--grey6);\n  //   max-height: 330px;\n  border-radius: 10px;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  .patientCount {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    gap: 20px;\n    margin: 30px 0px 0px 0px;\n    .labelValue {\n      display: flex;\n      flex-direction: column;\n    }\n  }\n  .graph {\n    display: inline-block;\n    width: 100%;\n    border-bottom-left-radius: 10px;\n    border-bottom-right-radius: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "patientCard_container__CPHcr",
	"patientCount": "patientCard_patientCount__dvpAp",
	"labelValue": "patientCard_labelValue__5g5a+",
	"graph": "patientCard_graph__5vr54"
};
export default ___CSS_LOADER_EXPORT___;
