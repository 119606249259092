import axios from "axios";
import { IAPIPayload } from "../../../interfaces/apiInterface";
import { CONFIGURATIONS_GET, CREATE_CONFIGURATIONS } from "../../../config/config";

export const configurationsDetails = (data: IAPIPayload) => {
  return axios.post(CREATE_CONFIGURATIONS, data);
};

export const getConfigurationsDetails = (data: IAPIPayload) => {
  return axios.post(CONFIGURATIONS_GET, data);
};
