import {
  GET__ROLE__USER,
  REPORT_POC_TYPE,
  UPDATE_REPORT_MAPPING_TYPE,
  UPDATE_ROLE_STATUS_TYPE,
  CREATE_REPORT_QUERY_TYPE,
  UPDATE_REPORT_QUERY_TYPE,
  GET_REPORT_BUILDER_CONFIG_TYPE,
  GET_REPORT_BUILDER_TYPE,
  GET_REPORTS_TYPE,
  GET_DYNAMIC_REPORT_BY_QUERY_TYPE,
  UPDATE_REPORT_STATUS_BY_ID_TYPE,
  PATIENT_LIST_REPORT,
  INVOICE_WISE_SALES,
  SERVICE_WISE_SALES_SUMMARY,
  COLLECTION_DAY_WISE_SUMMARY,
  DOCTOR_WISE_COLLECTION_SUMMARY,
  SERVICE_WISE_SALES,
  PATIENT_STATEMENT,
  PATIENT_SUMMARY_TYPE,
  REVENUE_TYPE,
  PATIENT_SUMMARY_FILTER,
  REVENUE_ANYLYTICS_FILTER,
  PATIENT_LEDGER_REPORT_TYPE,
  PATIENT_FUTURE_APPOINMENT,
  PHARMACY_MEDICINES_SALES_REPORT,
  DUE_AGING_REPORT_SALES_REPORT,
  ACCOUNT_LEDGER_REPORT,
  INVOICE_SALES_REPORT,
  INVENTORY_REPORT,
  CARD_SUMMARY,
  PATIENT_OUTSTANDING,
  PATIENT_SOURCE,
  DOCTER_REPORT,
  DEPT_REPORT,
} from "../../../constants/asyncActionsType";

import createAsyncThunkForSlice from "../../../utils/utils";
import {
  getRoleUser,
  reportMapping,
  reportPoc,
  updaterolestatus,
  createReportQuery,
  updateReportBuilderQuery,
  getReportBuilderConfig,
  getReportBuilder,
  getReportsType,
  getDynamicReportByQuery,
  updateReportStatus,
  getAllPatientListReport,
  invoiceWiseSales,
  serviceWiseSalesSummary,
  collectionDayWiseSummary,
  doctorWiseCollections,
  serviceWiseSales,
  patientStatements,
  patientSummary,
  revenue,
  patientSummaryFilter,
  RevenueAnylticsFilter,
  patientLedger,
  patientFuture,
  pharmcyMedicinesSalesReport,
  dueAgingReport,
  ledgerReport,
  salesReport,
  inventoryStoreReport,
  cardSummary,
  patientOutstanding,
  pateintSource,
  docterReportdata,
  deptReportdata,
} from "./roleCrud";

export const getUserRole = createAsyncThunkForSlice(
  GET__ROLE__USER,
  getRoleUser
);

export const updateRoleStatus = createAsyncThunkForSlice(
  UPDATE_ROLE_STATUS_TYPE,
  updaterolestatus
);

export const updateReportMapping = createAsyncThunkForSlice(
  UPDATE_REPORT_MAPPING_TYPE,
  reportMapping,
  { isToast: true }
);

export const reportPocAsync = createAsyncThunkForSlice(
  REPORT_POC_TYPE,
  reportPoc,
  { isToast: true }
);

export const addReportQuery = createAsyncThunkForSlice(
  CREATE_REPORT_QUERY_TYPE,
  createReportQuery,
  { isToast: true }
);

export const updateReportQuery = createAsyncThunkForSlice(
  UPDATE_REPORT_QUERY_TYPE,
  updateReportBuilderQuery,
  { isToast: true }
);

export const getReportBuilderConfigList = createAsyncThunkForSlice(
  GET_REPORT_BUILDER_CONFIG_TYPE,
  getReportBuilderConfig
);

export const getReportBuilderList = createAsyncThunkForSlice(
  GET_REPORT_BUILDER_TYPE,
  getReportBuilder
);

export const getReportsList = createAsyncThunkForSlice(
  GET_REPORTS_TYPE,
  getReportsType
);

export const getDynamicReportQueryData = createAsyncThunkForSlice(
  GET_DYNAMIC_REPORT_BY_QUERY_TYPE,
  getDynamicReportByQuery,
  { isToast: true }
);

export const updateReportStatusById = createAsyncThunkForSlice(
  UPDATE_REPORT_STATUS_BY_ID_TYPE,
  updateReportStatus,
  { isToast: true }
);

export const getAllPatientListsReport = createAsyncThunkForSlice(
  PATIENT_LIST_REPORT,
  getAllPatientListReport,
  { isToast: true }
);

export const invoiceWiseSale = createAsyncThunkForSlice(
  INVOICE_WISE_SALES,
  invoiceWiseSales,
  { isToast: true }
);

export const serviceWiseSaleSummary = createAsyncThunkForSlice(
  SERVICE_WISE_SALES_SUMMARY,
  serviceWiseSalesSummary,
  { isToast: true }
);

export const collectionsDayWiseSummary = createAsyncThunkForSlice(
  COLLECTION_DAY_WISE_SUMMARY,
  collectionDayWiseSummary,
  { isToast: true }
);

export const doctorWiseCollection = createAsyncThunkForSlice(
  DOCTOR_WISE_COLLECTION_SUMMARY,
  doctorWiseCollections,
  { isToast: true }
);

export const serviceWiseSale = createAsyncThunkForSlice(
  SERVICE_WISE_SALES,
  serviceWiseSales,
  { isToast: true }
);

export const patientStatement = createAsyncThunkForSlice(
  PATIENT_STATEMENT,
  patientStatements,
  { isToast: true }
);

// patient summary report
export const patientSummaryReport = createAsyncThunkForSlice(
  PATIENT_SUMMARY_TYPE,
  patientSummary
);

export const revenueDetail = createAsyncThunkForSlice(REVENUE_TYPE, revenue);

// patient summary filter

export const patientSummaryFilterUrl = createAsyncThunkForSlice(
  PATIENT_SUMMARY_FILTER,
  patientSummaryFilter
);

export const RevenueAnylticsFilterUrl = createAsyncThunkForSlice(
  REVENUE_ANYLYTICS_FILTER,
  RevenueAnylticsFilter
);

export const patientLedgerReport = createAsyncThunkForSlice(
  PATIENT_LEDGER_REPORT_TYPE,
  patientLedger,
  {
    isToast: true,
  }
);

// patient Future Appointment
export const patientFutureAppoinmentReport = createAsyncThunkForSlice(
  PATIENT_FUTURE_APPOINMENT,
  patientFuture,
  {
    isToast: true,
  }
);

// pharmacy medicines sales report
export const pharmcyMedicinesSalesSummaryReport = createAsyncThunkForSlice(
  PHARMACY_MEDICINES_SALES_REPORT,
  pharmcyMedicinesSalesReport,
  {
    isToast: true,
  }
);

// Due Aging Report
export const dueAgingSalesReport = createAsyncThunkForSlice(
  DUE_AGING_REPORT_SALES_REPORT,
  dueAgingReport,
  {
    isToast: true,
  }
);
// Account ledger report
export const accountLedgerReport = createAsyncThunkForSlice(
  ACCOUNT_LEDGER_REPORT,
  ledgerReport,
  {
    isToast: true,
  }
);

// card summary report 
export const cardSummaryReport = createAsyncThunkForSlice(
  CARD_SUMMARY,
  cardSummary,
  {
    isToast: true,
  }
);

// Patient outstatnding
export const patientOutstandingReport = createAsyncThunkForSlice(
  PATIENT_OUTSTANDING,
  patientOutstanding,
  {
    isToast: true,
  }
);

// patient source
export const patientSourceReport = createAsyncThunkForSlice(
  PATIENT_SOURCE,
  pateintSource,
  {
    isToast: true,
  }
);

// docter report 
export const docterReport = createAsyncThunkForSlice(
  DOCTER_REPORT,
  docterReportdata,
  {
    isToast: true,
  }
);

// dept report 
export const deptReport = createAsyncThunkForSlice(
  DEPT_REPORT,
  deptReportdata,
  {
    isToast: true,
  }
);


export const invoiceSalesReport = createAsyncThunkForSlice(
  INVOICE_SALES_REPORT,
  salesReport,
  {
    isToast: true,
  }
);

// Inventory Store Report

export const inventoryStoreItemReport = createAsyncThunkForSlice(
  INVENTORY_REPORT,
  inventoryStoreReport,
  {
    isToast: true,
  }
);
