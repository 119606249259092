// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_addNotesContainer__Mchb6 {\n  margin-block: 30px;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 20px;\n}\n.style_addNotesContainer__Mchb6 textarea {\n  width: 100%;\n  height: 120px;\n  border-radius: 6px;\n  border: 1px solid var(--grey2);\n  padding: 8px;\n}", "",{"version":3,"sources":["webpack://./src/pages/mainstore/submitpo-popup/addpo-notes/style.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,WAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,SAAA;AACJ;AAAI;EACE,WAAA;EACA,aAAA;EACA,kBAAA;EACA,8BAAA;EACA,YAAA;AAEN","sourcesContent":[".addNotesContainer {\n    margin-block: 30px;\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 20px;\n    textarea {\n      width: 100%;\n      height: 120px;\n      border-radius: 6px;\n      border: 1px solid var(--grey2);\n      padding: 8px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addNotesContainer": "style_addNotesContainer__Mchb6"
};
export default ___CSS_LOADER_EXPORT___;
