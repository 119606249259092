import { EditIcon, InfoIcon } from '../../components/common/svg-components'
import { useState } from 'react'
import { colors } from '../color'
import styles from './tableData.module.scss'
import { t } from 'i18next'

export const radiologyConfigurationHeaderData: any = [
  {
    Header: t('LabJob.TEST_ID'),
    accessor: 'test_no',
  },
  {
    Header: t('RequestDiagnosis.TESTNAME'),
    accessor: 'name',
  },
  {
    Header: t('CallCenterAdmin.CODE'),
    accessor: 'code',
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.code ? <>{props?.row?.original?.code}</> : '-'}
        </>
      )
    },
  },
  {
    Header: () => {
      const [tat, setTat] = useState(false)

      return (
        <>
          <div className={styles.tatStyle}>
            <p>{t('LabConfiguration.TAT')}</p>
            <span className={styles.iconContainer}>
              <InfoIcon
                fillColor={colors.grey2}
                mouseEnter={() => setTat(true)}
                mouseLeave={() => setTat(false)}
              />
            </span>
            {tat && (
              <p className={styles.tatText}>
                {' '}
                {t('LabConfiguration.Turn_Around_Time')}
              </p>
            )}
          </div>
        </>
      )
    },
    accessor: 'tat',
    Cell: ({ row }: any) => {
      const _id = row?.original?._id
      return (
        <>
          <input
            className={styles.inputField}
            value={row?.original?.turn_around_time}
            type="text"
            key={_id}
            disabled={true}
          />
        </>
      )
    },
  },
  {
    Header: () => {
      return (
        <div>
          <p>{t('LabConfiguration.INTERNAL')}/</p>
          <p>{t('LabConfiguration.EXTERNAL')}</p>
        </div>
      )
    },
    accessor: 'source',
  },
  {
    Header: t('BedSetup.COST_PRICE'),
    accessor: 'cost_price',
  },
  {
    Header: t('BedSetup.SELL_PRICE'),
    accessor: 'sell_price',
  },
  {
    Header: t('DocUploadHeader.ACTION'),
    Cell: (props: any) => {
      return (
        <EditIcon
          handleClick={() => props?.onClick(props?.row?.original?._id)}
        />
      )
    },
  },
]
