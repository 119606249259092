import { t } from 'i18next'

export const ViewInventoryHeaderData = [
  {
    Header: t('ViewInventory.ITEM NO'),
    accessor: (row: any) => {
      return row?.items.item_no
    },
  },
  {
    Header: t('ViewInventory.ITEM NAME'),
    accessor: (row: any) => {
      return row?.items.name ?? '-'
    },
  },
  {
    Header: t('ViewInventory.QTY'),
    accessor: (row: any) => {
      return row?.qty ?? '-'
    },
  },
]
