import {
  ACCOUNT_SUMMARY_WIDGETS,
  APPOINMENT_SUMMARY_WIDGETS,
  DEPARTMENT_WISE_REVENUE_WIDGETS,
  DOCTOR_FEEDBACK_SUMMARY_WIDGETS,
  DOCTOR_PATIENT_VISIT_WIDGETS,
  DOCTOR_WISE_REVENUE_WIDGETS,
  DOCTORS_OUTSTANDING_REVENUE_WIDGETS,
  GET_ALL_DOCTOR_APPOINTMENT_LIST,
  GET_ALL_TODO_REMINDER_LIST,
  PATIENT_FEEDBACK_WIDGETS,
  Upload_File,
} from "../../../constants/asyncActionsType";
import createAsyncThunkForSlice from "../../../utils/utils";
import {
  accountSummary,
  departmentWiseRevenue,
  doctorFeedbackSummary,
  doctorOutStandingRevenue,
  doctorPatientVisit,
  doctorWiseRevenue,
  getAllDoctorAppointments,
  getAllTodoReminder,
  patientAppoinmentSummary,
  patientFeedback,
  uploadFileEmr,
} from "./doctorDashboardCrud";

export const getAllDoctorAppointmentLists = createAsyncThunkForSlice(
  GET_ALL_DOCTOR_APPOINTMENT_LIST,
  getAllDoctorAppointments
);

export const getAllTodoReminderLists = createAsyncThunkForSlice(
  GET_ALL_TODO_REMINDER_LIST,
  getAllTodoReminder,
  {
    isToast: true,
  }
);

export const uploadFile = createAsyncThunkForSlice(Upload_File, uploadFileEmr, {
  isToast: true,
});

// doctor patinet feedback widgest
export const patientFeedbackWidgets = createAsyncThunkForSlice(
  PATIENT_FEEDBACK_WIDGETS,
  patientFeedback
);

// doctor patient-appointment-summary
export const patientAppoinmentSummarykWidgets = createAsyncThunkForSlice(
  APPOINMENT_SUMMARY_WIDGETS,
  patientAppoinmentSummary
);
// doctor revenue
export const doctorWiseRevenueWidgets = createAsyncThunkForSlice(
  DOCTOR_WISE_REVENUE_WIDGETS,
  doctorWiseRevenue
);
// department revenue
export const departmentWiseRevenueWidgets = createAsyncThunkForSlice(
  DEPARTMENT_WISE_REVENUE_WIDGETS,
  departmentWiseRevenue
);
// outstanding revenue
export const doctorOutstandingRevenueWidgets = createAsyncThunkForSlice(
  DOCTORS_OUTSTANDING_REVENUE_WIDGETS,
  doctorOutStandingRevenue
);
// doctor feedback summary
export const doctorFeedbackSummaryWidgets = createAsyncThunkForSlice(
  DOCTOR_FEEDBACK_SUMMARY_WIDGETS,
  doctorFeedbackSummary
);

// doctor patient  visit
export const doctorPatientVisitWidget = createAsyncThunkForSlice(
  DOCTOR_PATIENT_VISIT_WIDGETS,
  doctorPatientVisit
);

// account summary
export const accountSummaryWidget = createAsyncThunkForSlice(
  ACCOUNT_SUMMARY_WIDGETS,
  accountSummary
);

