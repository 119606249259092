import React from "react";
import styles from "./InitialLoadUI.module.scss"
import healthboxLogo from "../../../assets/images/HealthboxLogoText.png";

const InitialLoadUI = () => {
  return (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: `translate(${-50}%, ${-50}%)`,
        width: "200px",
      }}
    >
      <img src={healthboxLogo} alt="logo" className={styles.imgStyle} />
    </div>
  );
};

export default InitialLoadUI;
