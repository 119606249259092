import { useEffect, useState } from 'react'
import styles from './createHL7.module.scss'
import { SubmitHandler, useForm } from 'react-hook-form'
import { trimValue } from '../../utils/utils'
import {
  CONTACT_PERSON_NAME,
  HL7_BRANCH,
  HL7_EMAIL,
  HL7_NAME,
  HL7_PHONE,
  MESSAGE_LIST,
  MESSAGE_VARIENT,
} from '../../constants/constant'
import { hl7FormValidators } from '../../form-validators/hl7FormValidators'
import Button from '../../components/common/button/Button'
import Select from 'react-select'
import { reactSelectStyle } from '../../constants/data'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { createHl7, updateHl7 } from '../../redux/features/hl7/hl7AsyncActions'
import { requestGenerator } from '../../utils/payloadGenerator'
import Loader from '../../components/common/spinner/Loader'
import { useLocation, useNavigate } from 'react-router-dom'
import PhoneInput from 'react-phone-input-2'
import { t } from 'i18next'
import { getAllBranch } from '../../redux/features/branch/branchAsyncActions'

const CreateHL7 = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const location = useLocation()?.state?.id
  const { masterValueData } = useAppSelector((state) => state.login)
  const { isLoading } = useAppSelector((state) => state.hl7)
  const { branchData } = useAppSelector((state) => state.branch)
  const [branchOptions, setBranchOptions] = useState([])

  const values = location

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    trigger,
    getValues,
  } = useForm<any>({
    defaultValues: {
      name: location?.name,
      version: location?.version,
    },
    values,
  })

  useEffect(() => {
    if (location?._id) {
      setValue(MESSAGE_VARIENT, {
        label: location?.version,
        value: location?.version,
      })
    } else {
      setValue(MESSAGE_VARIENT, {
        label: 'HL7',
        value: 'HL7',
      })
    }
  }, [location, setValue])

  const onSubmit: SubmitHandler<any> = (data: any) => {
    let accessList = data?.message_list?.map((item: any) => {
      return {
        type: item?.value,
        ip_address: data[`${item?.value}_IP`],
        port: data[item?.value],
      }
    })
    let payloadData = {
      name: data?.name,
      version: data?.version?.value,
      access: accessList,
      contact_person_name: data?.contact_person_name,
      email: data?.email,
      phone: data?.phone,
      branch_id: data?.branch_id?.value,
    }

    if (location?._id) {
      let reqData = {
        ...payloadData,
        id: location?._id,
      }
      dispatch(updateHl7(requestGenerator(reqData))).then((e) => {
        if (e?.type === 'hl7/updateHl7/fulfilled') {
          navigate('/hl7')
        }
      })
    } else {
      dispatch(createHl7(requestGenerator(payloadData))).then((e) => {
        if (e?.type === 'hl7/createHl7/fulfilled') {
          navigate('/hl7')
        }
      })
    }
  }

  const handleClearState = () => {
    let categoryData = masterValueData?.filter(
      (it: any) => it?.category_name === 'HL7_MESSAGE_TYPE'
    )[0]?.values

    let result = categoryData.filter(
      (o1: any) => !watch(MESSAGE_LIST).some((o2: any) => o1.value === o2.value)
    )
    result?.map((item: any) => {
      setValue(item?.value, undefined)
    })
  }

  useEffect(() => {
    let message_list = location?.hl7messageaccesses?.map((item: any) => {
      setValue(`${item?.type}_IP`, item?.ip_address)
      setValue(`${item?.type}`, item?.port)
      return {
        value: item?.type,
        label: item?.type,
      }
    })
    setValue(MESSAGE_LIST, message_list)
  }, [location, setValue])

  //get all branch
  useEffect(() => {
    let data = {
      page: 1,
      pageSize: 1000,
    }
    dispatch(getAllBranch(requestGenerator(data)))
  }, [dispatch])

  //set branch data
  useEffect(() => {
    if (branchData && branchData) {
      const branches = branchData?.map((item: any) => {
        return {
          label: item?.name,
          value: item?._id,
        }
      })
      setBranchOptions(branches)
      if (location?.branch_id) {
        const branch = branchData.find(
          (item: any) => item?._id === location.branch_id
        )
        if (branch?._id) {
          setValue(HL7_BRANCH, {
            label: branch?.name,
            value: branch?._id,
          })
        } else {
          setValue(HL7_BRANCH, branches[0])
        }
      }
    }
  }, [branchData, setValue, location])

  return (
    <>
      {isLoading && <Loader />}
      <div className={styles.mainContainer}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.fieldContainer}>
            <label className={styles.labelText}>
              {t('hl7.Application_Name')} <span className="asterick">*</span>
            </label>
            <div className={styles.inputContainer}>
              <input
                type="text"
                className={styles.inputField}
                placeholder={
                  t('SupplierMaster.name_placeholder') || 'Enter Name'
                }
                {...register(HL7_NAME, hl7FormValidators[HL7_NAME])}
                onChange={(e) => trimValue(e)}
              />
              {errors[HL7_NAME] && (
                <p className="errorText">{errors[HL7_NAME].message as any}</p>
              )}
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <label className={styles.labelText}>
              {t('Header.Branch')} <span className="asterick">*</span>
            </label>
            <div className={styles.inputContainer}>
              <Select
                className={styles.select}
                placeholder={
                  t('OnlinePayment.Select branch') || 'Select branch'
                }
                closeMenuOnSelect={true}
                isSearchable={true}
                {...register(HL7_BRANCH, hl7FormValidators[HL7_BRANCH])}
                value={watch(HL7_BRANCH)}
                options={branchOptions}
                onChange={(e: any) => {
                  setValue(HL7_BRANCH, e)
                  trigger(HL7_BRANCH)
                }}
                maxMenuHeight={200}
                styles={reactSelectStyle}
              />
              {errors[HL7_BRANCH] && (
                <p className="errorText">{errors[HL7_BRANCH].message as any}</p>
              )}
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <label className={styles.labelText}>
              {t('SupplierMaster.Contact_Person')}{' '}
              <span className="asterick">*</span>
            </label>
            <div className={styles.inputContainer}>
              <input
                type="text"
                className={styles.inputField}
                placeholder={
                  t('hl7.contact_person_placeholder') ||
                  'Enter Contact Person Name'
                }
                {...register(
                  CONTACT_PERSON_NAME,
                  hl7FormValidators[CONTACT_PERSON_NAME]
                )}
                onChange={(e) => trimValue(e)}
              />
              {errors[CONTACT_PERSON_NAME] && (
                <p className="errorText">
                  {errors[CONTACT_PERSON_NAME].message as any}
                </p>
              )}
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <label className={styles.labelText}>
              {t('Auth.Email')} <span className="asterick">*</span>
            </label>
            <div className={styles.inputContainer}>
              <input
                type="text"
                className={styles.inputField}
                placeholder={t('hl7.email_placeholder') || 'Enter Email'}
                {...register(HL7_EMAIL, hl7FormValidators[HL7_EMAIL])}
                onChange={(e) => trimValue(e)}
              />
              {errors[HL7_EMAIL] && (
                <p className="errorText">{errors[HL7_EMAIL].message as any}</p>
              )}
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <label className={styles.labelText}>
              {t('ContactUs.Phone')} <span className="asterick">*</span>
            </label>
            <div className={styles.inputContainer}>
              <PhoneInput
                country={'kw'}
                {...register(HL7_PHONE, hl7FormValidators[HL7_PHONE])}
                value={getValues(HL7_PHONE)}
                onChange={(phone: any) => {
                  const formattedPhone = phone && `+${phone}`
                  setValue(HL7_PHONE, formattedPhone)
                  trigger(HL7_PHONE)
                }}
                inputClass={styles.inputFieldPhone}
              />
              {errors[HL7_PHONE] && (
                <p className="errorText">{errors[HL7_PHONE].message as any}</p>
              )}
            </div>
          </div>

          <div className={styles.fieldContainer}>
            <label className={styles.labelText}>
              {t('hl7.Message_Version')} <span className="asterick">*</span>
            </label>
            <div className={styles.inputContainer}>
              <Select
                className={styles.select}
                placeholder={t('hl7.Message_Version') || 'Message Version'}
                closeMenuOnSelect={true}
                isSearchable={true}
                {...register(
                  MESSAGE_VARIENT,
                  hl7FormValidators[MESSAGE_VARIENT]
                )}
                value={watch(MESSAGE_VARIENT)}
                options={['HL7']?.map((item: any) => ({
                  label: item,
                  value: item,
                }))}
                onChange={(e: any) => {
                  setValue(MESSAGE_VARIENT, e)
                  trigger(MESSAGE_VARIENT)
                }}
                maxMenuHeight={200}
                styles={reactSelectStyle}
              />
              {errors[MESSAGE_VARIENT] && (
                <p className="errorText">
                  {errors[MESSAGE_VARIENT].message as any}
                </p>
              )}
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <label className={styles.labelText}>
              {t('hl7.Message_List')} <span className="asterick">*</span>
            </label>
            <div className={styles.inputContainer}>
              <Select
                className={styles.select}
                placeholder={t('hl7.Message_List') || 'Message List'}
                closeMenuOnSelect={true}
                isSearchable={true}
                isMulti
                {...register(MESSAGE_LIST, hl7FormValidators[MESSAGE_LIST])}
                options={masterValueData
                  ?.filter(
                    (data: any) => data?.category_name === 'HL7_MESSAGE_TYPE'
                  )[0]
                  ?.values?.map((item: any) => ({
                    label: item?.value,
                    value: item?.value,
                  }))}
                value={watch(MESSAGE_LIST) || []}
                onChange={(e: any) => {
                  setValue(
                    MESSAGE_LIST,
                    e.map((item: any) => {
                      return item
                    })
                  )
                  handleClearState()
                  trigger(MESSAGE_LIST)
                }}
                maxMenuHeight={200}
                styles={reactSelectStyle}
              />
              {errors[MESSAGE_LIST] && (
                <p className="errorText">
                  {errors[MESSAGE_LIST].message as any}
                </p>
              )}
            </div>
          </div>
          {watch(MESSAGE_LIST)?.length > 0 &&
            watch(MESSAGE_LIST)?.map((item: any, index: number) => {
              return (
                <div className={styles.fieldContainer} key={index}>
                  <label className={styles.labelText}>
                    {item?.label} <span className="asterick">*</span>
                  </label>
                  <div className={styles.inputContainer}>
                    <input
                      type="text"
                      className={styles.inputField}
                      placeholder={t('hl7.ip_placeholder') || `Enter ip`}
                      {...register(`${item?.label}_IP`, {
                        required: `${t('hl7.ip_validation')}`,
                      })}
                      onChange={(e) => trimValue(e)}
                    />
                    {errors[item?.label] && (
                      <p className="errorText">
                        {errors[`${item?.label}_IP`]?.message as any}
                      </p>
                    )}
                  </div>
                  <div className={styles.inputContainer}>
                    <input
                      type="text"
                      className={styles.inputField}
                      placeholder={t('hl7.port_placeholder') || `Enter port`}
                      {...register(item?.label, {
                        required: `${t('hl7.port_validation')}`,
                      })}
                      onChange={(e) => trimValue(e)}
                    />
                    {errors[item?.label] && (
                      <p className="errorText">
                        {errors[item?.label]?.message as any}
                      </p>
                    )}
                  </div>
                </div>
              )
            })}
          <div className={styles.buttonContainer}>
            <Button
              title={t('Common.Back') || 'Back'}
              type="button"
              customClass={styles.backBtn}
              handleClick={() => navigate('/hl7')}
            />
            <Button title={t('Common.Submit') || 'Submit'} type="submit" />
          </div>
        </form>
      </div>
    </>
  )
}

export default CreateHL7
