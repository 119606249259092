import * as React from 'react'
import { Outlet } from 'react-router-dom'

interface IAppProps {}

const HL7Layout: React.FunctionComponent<IAppProps> = () => {
  return (
    <>
      <Outlet />
    </>
  )
}

export default HL7Layout
