// import LandingPage from "../../components/common/landing-page/LandingPage";
// import Login from "../../pages/login/Login";
// import ForgotPassword from "../../pages/login/forgot-password/ForgotPassword";
// import OtpVerfication from "../../pages/login/otp-verfication/OtpVerfication";
// import RecoveryPassword from "../../pages/login/recovery-password/RecoveryPassword";
// import SubmitQuestionnaireLayout from "../../pages/submit-questionnaire/submit-questionnaire-layout/SubmitQuestionnaireLayout";
// import SubmitQuestionnaireForm from "../../pages/submit-questionnaire/submit-questionnaire-form/SubmitQuestionnaireForm";
// import QuestionnaireSubmit from "../../pages/submit-questionnaire/questionnaire-submit/QuestionnaireSubmit";
// import SubmitOtp from "../../pages/submit-otp/SubmitOtp";
// import AuthWrapper from "../../components/app/routes/AuthWrapper";
// import MainLayout from "../../pages/main-layout/MainLayout";
// import MobileAppConfigLayout from "../../pages/mobileapp-configuraion/MobileAppConfigLayout";

// import MobileAppAppointment from "../../pages/mobileapp-configuraion/mobile-app-appointment/MobileAppAppointment";
// import MobileAppNews from "../../pages/mobileapp-configuraion/mobile-app-news/MobileAppNews";
// import ManageUsersLayout from "../../pages/manage-users/ManageUsersLayout";
// import ManageUsers from "../../pages/manage-users/manage-user-grid/ManageUsers";
// import CreateUsers from "../../pages/manage-users/create-user/CreateUsers";
// import CreateUserPrimary from "../../pages/manage-users/create-user/create-user-primary/CreateUserPrimary";
// import CreateUserSecondary from "../../pages/manage-users/create-user/create-user-secondary/CreateUserSecondary";
// import ManageUserGroupsLayout from "../../pages/manage-usergroups/ManageUserGroupsLayout";
// import UserGroupList from "../../pages/manage-usergroups/usergrouplist/UserGroupList";
// import ManageUserGroup from "../../pages/manage-usergroups/usergrouplist/manage-usergroup/ManageUsergroup";
// import PrimaryUserGroup from "../../pages/manage-usergroups/usergrouplist/manage-usergroup/primary/PrimaryUsergroup";
// import SecondaryUsergroup from "../../pages/manage-usergroups/usergrouplist/manage-usergroup/secondary/SecondaryUsergroup";
// import DashboardLayout from "../../pages/dashboard/DashboardLayout";
// import MedicalCenterBranch from "../../pages/dashboard/branch/MedicalCenterBranch";
// import BranchList from "../../pages/dashboard/branch/branch-list/BranchList";
// import ManageBranch from "../../pages/dashboard/branch/manage-branch/ManageBranch";
// import MedicalCenterDepartment from "../../pages/dashboard/department/MedicalCenterDepartment";
// import DepartmentList from "../../pages/dashboard/department/department-list/DepartmentList";
// import ManageDepartment from "../../pages/dashboard/department/manage-department/ManageDepartment";
// import NotFound from "../../components/common/not-found/NotFound";
// import PatientEmrLayout from "../../pages/patient-emr/PatientEmrLayout";
// import PatientEmrDashboard from "../../pages/patient-emr/patient-emr-dashboard/PatientEmrDashboard";
// import ShareQuestionEmail from "../../pages/patient-emr/share-question-email/ShareQuestionEmail";
// import MainSchedular from "../../pages/appointment/SchedularMainLayout";
// import BookingSchedularFunctional from "../../pages/appointment/BookingFunctional";
// import Receptionist from "../../pages/receptionist/Receptionist";
// import InvoiceLayout from "../../pages/invoice/InvoiceLayout";

// import InvoiceInformation from "../../pages/invoice/invoice-information/InvoiceInformation";
// import InvoiceServices from "../../pages/invoice/invoice-services/InvoiceServices";
// import InvoicePayment from "../../pages/invoice/invoice-payment/InvoicePayment";
// import ReceiptLayout from "../../pages/receipt/ReceiptLayout";
// import Receipt from "../../pages/receipt/receipt-landing-page/Receipt";
// import CreateNewFormsLayout from "../../pages/create-new-forms/CreateNewFormsLayout";
// import CreateNewFormList from "../../pages/create-new-forms/create-new-form-list/CreateNewFormList";
// import CreateNewFormBuilder from "../../pages/create-new-forms/create-new-form-builder/CreateNewFormBuilder";
// import Invoice from "../../pages/doctor-diagnosis/invoice/Invoice";
// import TreatmentPlanList from "../../pages/doctor-diagnosis/treatment/treatment-plan-list/TreatmentPlanList";
// import Referral from "../../pages/doctor-diagnosis/referal/Referral";
// import Request from "../../pages/doctor-diagnosis/request/Request";
// import Medication from "../../pages/doctor-diagnosis/medication/Medication";
// import DiagnosisForm from "../../pages/doctor-diagnosis/diagnosis/diagnosis-form/DiagnosisForm";
// import DoctorDiagnosisLayout from "../../pages/doctor-diagnosis/DoctorDiagnosisLayout";
// import DoctorAppointmentDashboard from "../../pages/doctor-dashboard/doctor-appointments/DoctorAppointmentDashboard";
// import LabRequestLayout from "../../pages/lab-request/LabRequestLayout";
// import LabRequest from "../../pages/lab-request/labRequest/LabRequest";
// import LabInvoiceLayout from "../../pages/lab-invoice/LabInvoiceLayout";
// import LabInformation from "../../pages/lab-invoice/lab-information/LabInformation";
// import LabServices from "../../pages/lab-invoice/lab-services/LabServices";
// import Configuration from "../../pages/configuration/Configuration";
// import JobLayout from "../../pages/job/JobLayout";
// import CreateJobs from "../../pages/job/create-jobs/CreateJobs";
// import ViewJobs from "../../pages/job/view-jobs/ViewJobs";
// import RadiologyJobLayout from "../../pages/radiology-jobs/JobLayout";
// import RadioLogyRequestLayout from "../../pages/radiology-request/RadioLogyRequestLayout";
// import RadiologyInvoiceLayout from "../../pages/radiology-invoice/RadiologyInvoiceLayout";
// import RadiologyConfiguration from "../../pages/radiology-configuration/RadiologyConfiguration";

// import RadiologyInvoicePayment from "../../pages/radiology-invoice/radiology-invoice-payment/RadiologyInvoicePayment";
// import RadiologyInvoiceService from "../../pages/radiology-invoice/radiology-invoice-service/RadiologyInvoiceService";
// import RadiologyInvoiceInformation from "../../pages/radiology-invoice/radiology-invoice-information/RadiologyInvoiceInformation";
// import RadiologyRequest from "../../pages/radiology-request/radioLogyRequest/RadioLogyRequest";
// import RadiologyCreateJobs from "../../pages/radiology-jobs/create-jobs/CreateJobs";
// import RadiologyViewJobs from "../../pages/radiology-jobs/view-jobs/ViewJobs";
// import ManageMasterTableNewtLayout from "../../pages/manageMasterTable-New/ManageMasterTableNewLayout";
// import InsuranceMaster from "../../pages/insurance-master/InsuranceMaster";
// import OnGoingClaims from "../../pages/insurance/ongoing-claims/onGoingClaims";
// import Services from "../../pages/PatientEmrServices/service-landing-page/Service";
// import ManageMasterNew from "../../pages/manageMasterTable-New/manageMasterNew/ManageMasterNew";
// import ManageAppoinmentStatus from "../../pages/manageMasterTable-New/manageAppoinmentStatus/ManageAppoinmentStatus";
// import ManageCategoryValues from "../../pages/manageMasterTable-New/MasterCategoryValues/ManageCategoryValues";
// import ManageAssignTag from "../../pages/manageMasterTable-New/manageAssignTag/ManageAssignTag";
// import LabPayment from "../../pages/lab-invoice/lab-payment/LabPayment";
// import RequestLayout from "../../pages/request/RequestLayout";
// import BranchStoreLayout from "../../pages/branchstore/BranchStoreLayout";
// import BranchStore from "../../pages/branchstore/branchstore/BranchStore";
// import MainStoreLayout from "../../pages/mainstore/MainStoreLayout";
// import MainStore from "../../pages/mainstore/mainstore/MainStore";
// import PurchaseInvoiceLayout from "../../pages/purchase-invoice/PurchaseInvoiceLayout";
// import PurchaseInvoice from "../../pages/purchase-invoice/purchase-invoice/PurchaseInvoice";
// import InventoryRequest from "../../pages/request/request/Request";
// import DentistAppointmentDashboard from "../../pages/dentist-dashboard/dentist-appointments/DentistAppointmentDashboard";
// import DentistDiagnosisLayout from "../../pages/dentist-diagnosis/DentistDiagnosisLayout";
// import IPD from "../../pages/ipd/Ipd";
// import IpdDashboard from "../../pages/ipd/ipd-dashboard/IpdDashboard";
// import CreateIpdMainLayout from "../../pages/ipd/create-ipd-main-layout/CreateIpdMainLayout";
// import IpdInvoiceLayout from "../../pages/ipd-invoice/IpdInvoiceLayout";
// import PatientIPDInfo from "../../pages/ipd-invoice/patient-ipd-info/PatientIPDInfo";
// import PatientConsumble from "../../pages/ipd-invoice/patient-consumable/PatientConsumble";
// import PatientCharges from "../../pages/ipd-invoice/patient-charges/PatientCharges";
// import PatientIPDServices from "../../pages/ipd-invoice/patient-services/PatientIPDServices";

// import Consultation from "../../pages/ipd/create-ipd-main-layout/ipd-timeline/consultation/Consultation";
// import DentalDiagnosisForm from "../../pages/dentist-diagnosis/diagnosis/diagnosis-form/DiagnosisForm";
// import DentalMedication from "../../pages/dentist-diagnosis/medication/Medication";
// import DentalRequest from "../../pages/dentist-diagnosis/request/Request";
// import DentalReferral from "../../pages/dentist-diagnosis/referal/Referral";
// import DentalTreatmentPlanList from "../../pages/dentist-diagnosis/treatment/treatment-plan-list/TreatmentPlanList";
// import UnitTypeMainLayout from "../../pages/unit-type-master/UnitTypeMainLayout";
// import MedicalCenterWard from "../../pages/dashboard/ward/MedicalCenterWard";
// import WardList from "../../pages/dashboard/ward/ward-list/WardList";
// import ManageWard from "../../pages/dashboard/ward/manage-ward/ManageWard";
// import MedicalCenterRoom from "../../pages/dashboard/room/MedicalCenterRoom";
// import ManageRoom from "../../pages/dashboard/room/manage-room/ManageRoom";
// import RoomList from "../../pages/dashboard/room/room-list/RoomList";
// import MedicalCenterBed from "../../pages/dashboard/bed/MedicalCenterBed";
// import ManageBed from "../../pages/dashboard/bed/manage-bed/ManageBed";
// import BedList from "../../pages/dashboard/bed/bed-list/BedList";
// import ManageInventoryUnitType from "../../pages/manageMasterTable-New/manageInventoryUnitType/ManageInventoryUnitType";
// import BedSchedular from "../../pages/appointment/bed/BedSchedular";
// import PatientPayment from "../../pages/ipd-invoice/patient-payment/PatientPayment";
// import InventoryMasterTableLayout from "../../pages/admin-inventory-master/InventoryMasterTableLayout";
// import InventoryMasterTable from "../../pages/admin-inventory-master/InventoryMasterTable";
// import InventoryItemTableLayout from "../../pages/inventory-item-master/InventoryItemMasterTableLayout";
// import InventoryItemTable from "../../pages/inventory-item-master/InventoryItemMasterTable";
// import AddNewSuplier from "../../pages/admin-inventory-master/add-new-supplier/AddNewSuplier";
// import AddNewItem from "../../pages/inventory-item-master/add-new-item/AddNewItem";
// import IpdCreateForm from "../../pages/ipd/create-ipd-main-layout/ipd-create-form/IpdCreateForm";
// import ReportBuilder from "../../pages/report-builder/ReportBuilder";

// import CallCenterDashboardLayout from "../../pages/call-center-admin/admin/CallCenterLayout";
// import Call from "../../pages/call-center-admin/admin/admin/CallCenterDashboard";
// import LeadLayout from "../../pages/call-center-admin/lead/LeadLayout";
// import Internal from "../../pages/call-center-admin/lead/internal/Internal";
// import External from "../../pages/call-center-admin/lead/external/External";
// import AgentsLayout from "../../pages/call-center-admin/agents/AgentsLayout";

// import CampaignsLayout from "../../pages/call-center-admin/campaign/CampaignsLayout";
// import CreateCampaigns from "../../pages/call-center-admin/campaign/create-campaigns/CreateCampaigns";
// import OnGoingCampaigns from "../../pages/call-center-admin/campaign/on-going-campaigns/OnGoingCampaigns";
// import Agents from "../../pages/call-center-admin/agents/agents/Agents";
// import Leads from "../../pages/call-center-admin/lead/total-leads/Leads";
// import AgentDashboardLayout from "../../pages/agent/AgentDashboardLayout";

import { MyRoutes } from "../../interfaces/interfaces";
// import AgentLeads from "../../pages/agent/agent-info-table/agent-leads/page";
// import FollowUp from "../../pages/agent/agent-info-table/follow-up/page";
import {
  ACCOUNT_DASHBOARD,
  ATTENDANCE,
  BRANCH_STORE,
  BRANCH_STORE_DASHBOARD,
  CC_ADMIN_DASHBOARD,
  CC_AGENT,
  CC_AGENT_DASHBOARD,
  CC_CAMPAIGN,
  CC_LEAD_POOL,
  CRM_ADMIN_KEY,
  DC_DASHBOARD,
  DC_DIAGNOSIS,
  DNT_DASHBOARD,
  DNT_DIAGNOSIS,
  DOCUMENTS,
  DYNAMIC_TEMPLATE,
  EMP_MANAGEMENT,
  FORM_BUILDER,
  HR_DASHBOARD,
  IMPORT_UTILITY,
  INS_MASTERS,
  INVENTORY_ITEM_MASTERS,
  INVENTORY_MASTER_TABLE,
  INVENTORY_REQUEST,
  INVENTORY_UNIT_TYPE_MAP,
  INVOICE,
  IPD_CREATE_CASE,
  IPD_ID,
  IPD_INVOICE,
  LAB_CONFIGURATION,
  LAB_DASHBOARD,
  LAB_INVOICE,
  LAB_JOB,
  LAB_REQUEST,
  LEAVE_MANAGEMENT,
  MAIN_STORE,
  MAIN_STORE_DASHBOARD,
  MANAGE_MASTER_TABLE,
  MANAGE_STAFF,
  MANAGE_USER_ROLE,
  MASTER_IMPORT,
  MC_ADMIN_DASHBOARD,
  MC_DASHBOARD,
  MOB_APP_CONFIGURATION,
  ONGING_CLAIMS,
  PATIENT_EMR,
  PAYROLL,
  PHARMACY_MAIN_STORE,
  PHARMACY_POS,
  PHARMACY_STORE,
  PURCHASE_INVOICE,
  RADIOLOGY_CONFIGURAION,
  RADIOLOGY_DASHBOARD,
  RADIOLOGY_INVOICE,
  RADIOLOGY_JOB,
  RADIOLOGY_REQUEST,
  RECEIPT,
  RECEP_DASHBOARD,
  REPORTS,
  REPORT_BUILDER,
  REPORT_GENERATOR,
  REPORT_MAPPING,
  SCHEDULAR,
  SER_MASTERS,
  UNAVAILABILITY,
  UPAY_CONFIG,
  VIEW_APPOINTMENT,
  VIEW_CHART_OF_ACCOUNTS,
  VOUCHERS,
} from "../../config/modules";

// import CrmAdminLayout from "../../pages/crm-admin/page";
// import CrmNew from "../../pages/crm-admin/crm-new-tab/page";
// import CrmSent from "../../pages/crm-admin/crm-sent-tab/page";
// import PharmacyLayout from "../../pages/pharmacy/PharmacyLayout";
// import PharmacyInfoForm from "../../pages/pharmacy/pharmacy-info/PharmacyInfoForm";
// import PharmacyStore from "../../pages/pharmacy/pharmacy-store/PharmacyStore";
// import PharmacyPaymentNew from "../../pages/pharmacy/pharmacy-payment-new/PharmacyPaymentNew";
// import PharmacyOnhold from "../../pages/pharmacy/pharmacy-onhold/PharmacyOnhold";
// import ManageSpecialitiesNew from "../../pages/dashboard/department/manage-specialities/manageSpecialityNew";
// import lazy.ErrorBoundaryHoc from "../../components/hoc/error-boundary/lazy.ErrorBoundaryHoc";
// import DynamicFormLayout from "../../pages/dynamic-form-layout/DynamicFormLayout";
// import DynamicFormSubmit from "../../pages/dynamic-form-layout/dynamic-form-submit/DynamicFormSubmit";
// import ReportMapping from "../../pages/report-mapping/ReportMapping";
// import ReportBuilderLayout from "../../pages/reports-poc/ReportBuilderLayout";
// import ReportBuilderList from "../../pages/reports-poc/ReportBuilderList";
// import CreateReport from "../../pages/reports-poc/CreateReport";
// import PrivacyPolicy from "../../pages/login/privacy-policy/PrivacyPolicy";
// import HRDashboardLayout from "../../pages/hr-dashboard/HRDashboardLayout";
// import HrDashboard from "../../pages/hr-dashboard/dashboard/HrDashboard";
// import Departments from "../../pages/hr-dashboard/departments/Departments";
// import EmployeesLayout from "../../pages/employees/EmployeesLayout";
// import Employees from "../../pages/employees/employees/Employees";
// import AddEmployee from "../../pages/employees/add-employee/AddEmployee";
// import DocumentsLayout from "../../pages/documents/DocumnetsLayout";
// import Documents from "../../pages/documents/documnets/Documents";
// import AddDocument from "../../pages/documents/add-new/AddDocument";
// import PayrollLayout from "../../pages/payroll/PayrollLayout";
// import Payroll from "../../pages/payroll/payroll/Payroll";
// import Create from "../../pages/payroll/creare-salary-slip/Create-salary";
// import EndofServices from "../../pages/payroll/end-of-services/End-of-services";
// import New from "../../pages/payroll/add-new/Add-new";
// import AttendaceLayout from "../../pages/attendance/AttendanceLayout";
// import ManageAttendance from "../../pages/attendance/manage-attendance/ManageAttendance";
// import LeaveManagementLayout from "../../pages/leave-management/LeaveManagementLayout";
// import LeaveManagement from "../../pages/leave-management/leave-management/LeaveManagement";
// import LeaveEncashment from "../../pages/leave-management/leave-encashment/LeaveEncashment";
// import LeaveFormData from "../../pages/leave-management/leave-encashment/leave-form-data/LeaveFormData";
// import ApplyLeave from "../../pages/leave-management/apply-leave/ApplyLeave";
// import AccountDashboardLayout from "../../pages/account-dashboard/dashboard/DashboardLayout";
// import Dashboard from "../../pages/account-dashboard/dashboard/dashboard/Dashboard";
// import ViewChartOfAccountsLayout from "../../pages/view-chart-of-accounts/ViewChartOfAccountsLayout";
// import Level2List from "../../pages/view-chart-of-accounts/level2/Level2List";
// import Level3List from "../../pages/view-chart-of-accounts/level3/Level3List";
// import Level4List from "../../pages/view-chart-of-accounts/level4/Level4List";
// import Level5List from "../../pages/view-chart-of-accounts/level5/Level5List";
// import VouchersLayout from "../../pages/vouchers/VouchersLayout";
// import Vouchers from "../../pages/vouchers/vouchers/Vouchers";
// import ReportsLayout from "../../pages/reports/ReportsLayout";
// import Ledger from "../../pages/reports/ledger/Ledger";
// import TrialBalance from "../../pages/reports/trial-balance/TrialBalance";
// import ProfitLoss from "../../pages/reports/profit-loss/ProfitLoss";
// import BalanceSheet from "../../pages/reports/balance-sheet/BalanceSheet";
// import HL7Layout from "../../pages/hl7/HL7Layout";

// import HL7List from "../../pages/hl7/HL7List";
// import CreateHL7 from "../../pages/hl7/CreateHL7";
// import ContactUS from "../../pages/login/contact-us/ContactUs";
// import ManagePaymentModes from "../../pages/manageMasterTable-New/managePaymentmodes/ManagePaymentModes";
// import RoomSchedular from "../../pages/appointment/room/RoomSchedular";
// import UnavailabilityLayout from "../../pages/unavailability/UnavailabilityLayout";
// import DoctorUnavailablity from "../../pages/unavailability/doctor/DoctorUnavailablity";
// import UpayConfiguration from "../../pages/upay/UpayConfiguration";
// import ImportUtility from "../../pages/import-utility/ImportUtility";
// import DynamicTemplateLayout from "../../pages/dynamic_template/DynamicTemplateLayout";
// import DynamicTemplate from "../../pages/dynamic_template/dynamic_template_grid/DynamicTemplateGrid";
// import ManageDynamicTemplate from "../../pages/dynamic_template/manage-dynamic-template/ManageDynamicTemplate";
// import StaffPrivacyPolicy from "../../pages/login/privacy-policy/StaffPrivacyPolicy";
// import Configurations from "../../pages/configurations/Configurations";
// import Whatsup from "../../pages/configurations/whatsup/Whatsup";
import CurrencyDetails from "../../pages/configurations/configurationsDetails/ConfigurationDetails";
// import ConfigurationDetails from "../../pages/configurations/configurationsDetails/ConfigurationDetails";
import {
  // LazyAuthWrapper,
  // LazyForgotPassword,
  // LazyLandingPage,
  // LazyLogin,
  // LazyMainLayout,
  LazyMobileAppConfigLayout,
  LazyOtpVerfication,
  LazyQuestionnaireSubmit,
  LazyRecoveryPassword,
  LazySubmitOtp,
  LazySubmitQuestionnaireForm,
  LazySubmitQuestionnaireLayout,
} from "./lazyLoadFiles";

import * as lazy from "./lazyLoadFiles";
import ViewAppointmentPopup from "../../components/common/modal/view-appointment-popup/ViewAppointmentPopup";
import ViewAppointmentpage from "../../pages/viewAppointment/viewAppointment";
import { Children } from "react";
import { elements } from "chart.js";
import ManageWidgets from "../../components/common/ManageWidgets/ManageWidgets";

export const commonRoutes: any[] = [
  {
    path: "/",
    element: (
      <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
        <lazy.LazyLandingPage />
      </lazy.ErrorBoundaryHoc>
    ),
    children: [
      {
        path: "",
        element: <lazy.Login />,
        children: [],
        header: "",
        location: "/",
      },
      {
        path: "forgotpassword",
        element: <lazy.ForgotPassword />,
        children: [],
        header: "",
        location: "/forgotpassword",
      },
      {
        path: "otpverfication",
        element: <LazyOtpVerfication />,
        children: [],
        header: "",
        location: "/otpverfication",
      },
      {
        path: "resetpassword",
        element: <LazyRecoveryPassword />,
        children: [],
        header: "",
        location: "/resetpassword",
      },
      {
        path: "contactus",
        element: <lazy.ContactUS />,
        children: [],
        header: "",
        location: "/contactus",
      },
    ],
    header: "",
    location: "/",
  },
  {
    path: "submitquestionnairelayout",
    element: (
      <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
        <LazySubmitQuestionnaireLayout />
      </lazy.ErrorBoundaryHoc>
    ),
    children: [
      {
        path: "",
        element: <LazySubmitQuestionnaireForm />,
        children: [],
        header: "",
        location: "/submitquestionnairelayout",
      },
      {
        path: "questionnairesubmit",
        element: <LazyQuestionnaireSubmit />,
        children: [],
        header: "",
        location: "/submitquestionnairelayout/questionnairesubmit",
      },
    ],
    header: "",
    location: "/submitquestionnairelayout",
  },
  {
    path: "dynamicformsubmitLayout",
    element: <lazy.DynamicFormLayout />,
    children: [
      {
        path: "",
        element: <lazy.DynamicFormSubmit />,
        children: [],
        header: "",
        location: "/dynamicformsubmitLayout",
      },
    ],
    header: "",
    location: "/dynamicformsubmitLayout",
  },
  {
    path: "submitotp",
    element: (
      <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
        <LazySubmitOtp />
      </lazy.ErrorBoundaryHoc>
    ),
    children: [],
    header: "",
    location: "/submitotp",
  },
  {
    path: "privacy-policy",
    element: <lazy.PrivacyPolicy />,
    children: [],
    header: "",
    location: "/privacy-policy",
  },
  {
    path: "privacy-policy-promed-staff",
    element: <lazy.StaffPrivacyPolicy />,
    children: [],
    header: "",
    location: "/privacy-policy-promed-staff",
  },
];

export const authRoutes: any[] = [
  {
    path: "/*",
    element: (
      <lazy.AuthWrapper>
        <lazy.MainLayout />
      </lazy.AuthWrapper>
    ),
    children: [
      // mc admin routes
      {
        id: MOB_APP_CONFIGURATION,
        path: "mobileappconfiguration/*",
        element: <LazyMobileAppConfigLayout />,
        children: [
          {
            path: "appointment",
            element: (
              <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
                <lazy.MobileAppAppointment />
              </lazy.ErrorBoundaryHoc>
            ),
            children: [],
            header: "Mobile App Configurations",
            location: "/mobileappconfiguration/appointment",
            roles: ["MC Admin"],
          },
          {
            path: "news",
            element: (
              <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
                <lazy.MobileAppNews />
              </lazy.ErrorBoundaryHoc>
            ),
            children: [],
            header: "Mobile App Configurations",
            location: "/mobileappconfiguration/news",
            roles: ["MC Admin"],
          },
        ],
        header: "Mobile App Configurations",
        location: "/mobileappconfiguration",
        roles: ["MC Admin"],
      },
      {
        id: MC_ADMIN_DASHBOARD,
        path: "admin-dashbord/*",
        element: (
          <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
            <lazy.MCAdminDashbord />
          </lazy.ErrorBoundaryHoc>
        ),
        children: [
          {
            path: "",
            element: <lazy.MCAdminDashbord />,
            children: [],
            header: "Admin dashboard",
            location: "/admin-dashbord",
            roles: ["MC Admin"],
          },
        ],
        header: "Medical Center AdmindashBoard",
        location: "/admin-dashbord",
        roles: ["MC Admin"],
      },
      {
        id: MANAGE_STAFF,
        path: "manageusers/*",
        element: (
          <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
            <lazy.ManageUsersLayout />
          </lazy.ErrorBoundaryHoc>
        ),
        children: [
          {
            path: "",
            element: <lazy.ManageUsers />,
            children: [],
            header: "Manage Staff",
            location: "/manageusers",
            roles: ["MC Admin"],
          },
          {
            path: "createusers/*",
            element: <lazy.CreateUsers />,
            children: [
              {
                path: "primary",
                element: <lazy.CreateUserPrimary />,
                children: [],
                header: "Manage Staff",
                location: "/manageusers/createusers/primary",
                roles: ["Super Admin"],
              },
              {
                path: "secondary",
                element: <lazy.CreateUserSecondary />,
                children: [],
                header: "Manage Staff",
                location: "/manageusers/createusers/secondary",
                roles: ["Super Admin"],
              },
            ],
            header: "Add Medical Center Users",
            location: "/manageusers/createusers",
            roles: ["Super Admin"],
          },
        ],
        header: "Manage Staff",
        location: "/manageusers",
        roles: ["MC Admin"],
      },
      {
        id: MANAGE_MASTER_TABLE,
        path: "mastertablemanagenew/*",
        element: (
          <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
            <lazy.ManageMasterTableNewtLayout />
          </lazy.ErrorBoundaryHoc>
        ),
        children: [
          {
            path: "",
            element: <lazy.ManageMasterTableNewtLayout />,
            children: [],
            header: "Manage Master Tables - Manage Category",
            location: "/mastertablemanagenew",
            roles: ["MC Admin"],
          },
          {
            path: "managemasternew",
            element: <lazy.ManageMasterNew />,
            children: [],
            header: "Manage Master Tables - Manage Category",
            location: "/mastertablemanagenew/managemasternew",
            roles: ["MC Admin"],
          },
          {
            path: "manageappoinmentstatus",
            element: <lazy.ManageAppoinmentStatus />,
            children: [],
            header: "Manage Master Tables - Appoinment Status",
            location: "/mastertablemanagenew/manageappoinmentstatus",
            roles: ["MC Admin"],
          },
          {
            path: "managecategoryvalues",
            element: <lazy.ManageCategoryValues />,
            children: [],
            header: "Manage Master Tables - Manage Category Value",
            location: "/mastertablemanagenew/managecategoryvalues",
            roles: ["MC Admin"],
          },
          {
            path: "manageappoinmentstatus",
            element: <lazy.ManageAppoinmentStatus />,
            children: [],
            header: "Manage Master Tables - Appoinment Status",
            location: "/mastertablemanagenew/manageappoinmentstatus",
            roles: ["MC Admin"],
          },

          {
            path: "manageassigntag",
            element: <lazy.ManageAssignTag />,
            children: [],
            header: "Manage Master Tables - Manage Assign Tag",
            location: "/mastertablemanagenew/manageassigntag",
            roles: ["MC Admin"],
          },
          {
            path: "manageinventorytype",
            element: <lazy.ManageInventoryUnitType />,
            children: [],
            header: "Manage Master Tables - Manage Inventory Type",
            location: "/mastertablemanagenew/manageinventorytype",
            roles: ["MC Admin"],
          },
          {
            path: "managepaymentmode",
            element: <lazy.ManagePaymentModes />,
            children: [],
            header: "Manage Master Tables - Manage Payment Modes",
            location: "/mastertablemanagenew/managepaymentmode",
            roles: ["MC Admin"],
          },
        ],
        header: "Manage Master Tables",
        location: "/mastertablemanage",
        roles: ["MC Admin"],
      },
      {
        id: MANAGE_USER_ROLE,
        path: "usergroups/*",
        element: <lazy.ManageUserGroupsLayout />,
        children: [
          {
            path: "",
            element: (
              <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
                <lazy.UserGroupList />
              </lazy.ErrorBoundaryHoc>
            ),
            children: [],
            header: "Manage User Roles",
            location: "/usergroups",
            roles: ["MC Admin"],
          },
          {
            path: "manageusergroups/*",
            element: (
              <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
                <lazy.ManageUserGroup />
              </lazy.ErrorBoundaryHoc>
            ),
            children: [
              {
                path: "primary",
                element: <lazy.PrimaryUserGroup />,
                children: [],
                header: "Manage User Roles",
                location: "/manageusergroups/usergroups",
                roles: ["MC Admin"],
              },
              {
                path: "secondary",
                element: <lazy.SecondaryUsergroup />,
                children: [],
                header: "Manage User Roles",
                location: "/manageusergroups/usergroups/secondary",
                roles: ["MC Admin"],
              },
            ],
            header: "Manage User Roles",
            location: "/usergroups/manageusergroups",
            roles: ["MC Admin"],
          },
        ],
        header: "Manage User Roles",
        location: "/usergroup",
        roles: ["MC Admin"],
      },
      {
        id: MC_DASHBOARD,
        path: "medicalcenter/*",
        element: <lazy.DashboardLayout />,
        children: [
          {
            path: "branch",
            element: (
              <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
                <lazy.MedicalCenterBranch />
              </lazy.ErrorBoundaryHoc>
            ),
            children: [
              {
                path: "",
                element: <lazy.BranchList />,
                children: [],
                header: "Manage Medical Center Setup",
                location: "/medicalcenter/branch",
                roles: ["Medical Center Admin"],
              },
              {
                path: "managebranch",
                element: <lazy.ManageBranch />,
                children: [],
                header: "Manage Medical Center Setup",
                location: "/medicalcenter/branch/managebranch",
                roles: ["Medical Center Admin"],
              },
            ],
            header: "Manage Medical Center Setup",
            location: "/medicalcenter/branch",
            roles: ["Medical Center Admin"],
          },
          {
            path: "department",
            element: (
              <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
                <lazy.MedicalCenterDepartment />
              </lazy.ErrorBoundaryHoc>
            ),
            children: [
              {
                path: "",
                element: <lazy.DepartmentList />,
                children: [],
                header: "Manage Medical Center Setup",
                location: "/medicalcenter/department",
                roles: ["Medical Center Admin"],
              },
              {
                path: "managedepartment",
                element: <lazy.ManageDepartment />,
                children: [],
                header: "Manage Medical Center Setup",
                location: "/medicalcenter/department/managedepartment",
                roles: ["Medical Center Admin"],
              },
              {
                path: "managespecialities",
                // element: <ManageSpecialities />,
                element: <lazy.ManageSpecialitiesNew />,
                children: [],
                header: "Manage Medical Center Setup",
                location: "/medicalcenter/department/managespecialities",
                roles: ["Medical Center Admin"],
              },
            ],
            header: "Manage Medical Center Setup",
            location: "/medicalcenter/department",
            roles: ["Medical Center Admin"],
          },
          {
            path: "ward",
            element: (
              <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
                <lazy.MedicalCenterWard />
              </lazy.ErrorBoundaryHoc>
            ),
            children: [
              {
                path: "",
                element: <lazy.WardList />,
                children: [],
                header: "Manage Medical Center Setup",
                location: "/medicalcenter/ward",
                roles: ["Medical Center Admin"],
              },
              {
                path: "manageward",
                element: <lazy.ManageWard />,
                children: [],
                header: "Manage Medical Center Setup",
                location: "/medicalcenter/ward/manageward",
                roles: ["Medical Center Admin"],
              },
            ],
            header: "Manage Medical Center Setup",
            location: "/medicalcenter/ward",
            roles: ["Medical Center Admin"],
          },
          {
            path: "room",
            element: (
              <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
                <lazy.MedicalCenterRoom />
              </lazy.ErrorBoundaryHoc>
            ),
            children: [
              {
                path: "",
                element: <lazy.RoomList />,
                children: [],
                header: "Manage Medical Center Setup",
                location: "/medicalcenter/room",
                roles: ["Medical Center Admin"],
              },
              {
                path: "manageroom",
                element: <lazy.ManageRoom />,
                children: [],
                header: "Manage Medical Center Setup",
                location: "/medicalcenter/room/manageroom",
                roles: ["Medical Center Admin"],
              },
            ],
            header: "Manage Medical Center Setup",
            location: "/medicalcenter/room",
            roles: ["Medical Center Admin"],
          },
          {
            path: "bed",
            element: (
              <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
                <lazy.MedicalCenterBed />
              </lazy.ErrorBoundaryHoc>
            ),
            children: [
              {
                path: "",
                element: <lazy.BedList />,
                children: [],
                header: "Manage Medical Center Setup",
                location: "/medicalcenter/bed",
                roles: ["Medical Center Admin"],
              },
              {
                path: "managebed",
                element: <lazy.ManageBed />,
                children: [],
                header: "Manage Medical Center Setup",
                location: "/medicalcenter/bed/managebed",
                roles: ["Medical Center Admin"],
              },
            ],
            header: "Manage Medical Center Setup",
            location: "/medicalcenter/bed",
            roles: ["Medical Center Admin"],
          },
        ],
        header: "Medical Center - Branch Setup",
        location: "/medicalcenter/branch",
        roles: ["Super Admin"],
      },
      {
        id: INS_MASTERS,
        path: "insurancemaster",
        element: (
          <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
            <lazy.InsuranceMaster />
          </lazy.ErrorBoundaryHoc>
        ),
        children: [],
        header: "Insurance Masters",
        location: "/insurancemaster",
        roles: ["Medical Center Admin"],
      },
      {
        id: ONGING_CLAIMS,
        path: "ongoing-claims",
        element: (
          <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
            <lazy.OnGoingClaims />
          </lazy.ErrorBoundaryHoc>
        ),
        children: [],
        header: "Insurance Claims",
        location: "/ongoing-claims",
        roles: ["Medical Center Admin"],
      },
      {
        id: SER_MASTERS,
        path: "services",
        element: (
          <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
            <lazy.Services />
          </lazy.ErrorBoundaryHoc>
        ),
        children: [],
        header: "Services Masters",
        location: "/services",
        roles: ["Medical Center Admin"],
      },
      {
        id: INVENTORY_MASTER_TABLE,
        path: "inventorymastertable/*",
        element: (
          <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
            <lazy.InventoryMasterTableLayout />
          </lazy.ErrorBoundaryHoc>
        ),
        children: [
          {
            path: "",
            element: <lazy.InventoryMasterTable />,
            children: [],
            header: "Supplier Master",
            location: "/inventorymastertable",
            roles: ["Medical Center Admin"],
          },
          {
            path: "addnewsuplier",
            element: <lazy.AddNewSuplier />,
            children: [],
            header: "Add New Supplier",
            location: "/addnewsuplier",
            roles: ["Medical Center Admin"],
          },
        ],
        header: "Supplier Master ",
        location: "/inventorymastertable",
        roles: ["Medical Center Admin"],
      },
      {
        id: INVENTORY_ITEM_MASTERS,
        path: "inventoryitemtable/*",
        element: (
          <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
            <lazy.InventoryItemTableLayout />
          </lazy.ErrorBoundaryHoc>
        ),
        children: [
          {
            path: "",
            element: <lazy.InventoryItemTable />,
            children: [],
            header: "Inventory Item Tables",
            location: "/inventoryitemtable",
            roles: ["Medical Center Admin"],
          },
          {
            path: "addnewitem",
            element: <lazy.AddNewItem />,
            children: [],
            header: "Add New Item",
            location: "/addnewitem",
            roles: ["Medical Center Admin"],
          },
        ],
        header: "Inventory Item Tables",
        location: "/inventoryitemtable",
        roles: ["Medical Center Admin"],
      },

      {
        id: MASTER_IMPORT,
        path: "master-import/*",
        element: (
          <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
            <lazy.MasterImport />
          </lazy.ErrorBoundaryHoc>
        ),
        children: [],
        header: "Master Import",
        location: "/master-import",
        roles: ["Super Admin"],
      },
      // Unit Type Master
      {
        id: INVENTORY_UNIT_TYPE_MAP,
        path: "unitTypeMap/*",
        element: (
          <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
            <lazy.UnitTypeMainLayout />
          </lazy.ErrorBoundaryHoc>
        ),
        children: [],
        header: "Unit Type Master",
        location: "/unitTypeMap",
        roles: ["Medical Center Admin"],
      },

      // receptionist routes

      {
        id: VIEW_APPOINTMENT,
        path: "viewappointment",
        element: <ViewAppointmentpage />,
        headers: "viewappointment",
        children: [],
        location: "/viewappointment",
        roles: ["View Appointment"],
      },
      {
        id: RECEP_DASHBOARD,
        path: "receptionist/*",
        element: (
          <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
            <lazy.Receptionist />
          </lazy.ErrorBoundaryHoc>
        ),
        children: [],
        header: "Receptionist",
        location: "/receptionist",
        roles: ["Receptionist"],
      },
      {
        id: PATIENT_EMR,
        path: "patientemr/*",
        element: <lazy.PatientEmrLayout />,
        children: [
          {
            path: "",
            element: (
              <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
                <lazy.PatientEmrDashboard />
              </lazy.ErrorBoundaryHoc>
            ),
            children: [],
            header: "Patient EMR Dashboard",
            location: "/patientemr",
            roles: ["MC Admin"],
          },
          {
            path: "sharequestionnaireemail",
            element: (
              <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
                <lazy.ShareQuestionEmail />
              </lazy.ErrorBoundaryHoc>
            ),
            children: [],
            header: "Patient Emr Dashboard",
            location: "patientemr/sharequestionnaireemail",
            roles: ["MC Admin"],
          },
        ],
        header: "Patient Emr Dashboard",
        location: "/patientemr",
        roles: ["MC Admin"],
      },

      // for viewAppointment
      // {
      //   // id: DC_DASHBOARD,
      //   path: "appointment/*",
      //   element: <lazy.ViewAppointment />,
      //   children: [],
      //   header: "Appointment ",
      //   location: "/viewAppointment",
      //   roles: ["Appointment"],
      // },

      {
        id: SCHEDULAR,
        path: "schedular/*",
        element: (
          <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
            <lazy.MainSchedular />
          </lazy.ErrorBoundaryHoc>
        ),
        children: [
          {
            path: "",
            element: (
              <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
                <lazy.BookingSchedularFunctional />
              </lazy.ErrorBoundaryHoc>
            ),
            children: [],
            header: "Manage Appointments",
            location: "/schedular",
            roles: ["MC Admin"],
          },
          {
            path: "beds",
            element: (
              <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
                <lazy.BedSchedular />
              </lazy.ErrorBoundaryHoc>
            ),
            children: [],
            header: "Manage Appointments",
            location: "/schedular/beds",
            roles: ["MC Admin"],
          },
          {
            path: "nurse",
            element: (
              <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
                <lazy.BookingSchedularFunctional />
              </lazy.ErrorBoundaryHoc>
            ),
            children: [],
            header: "Patient Emr Dashboard",
            location: "/schedular/nurse",
            roles: ["MC Admin"],
          },
          {
            path: "room",
            element: (
              <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
                <lazy.RoomSchedular />
              </lazy.ErrorBoundaryHoc>
            ),
            children: [],
            header: "Manage Appointments",
            location: "/schedular/room",
            roles: ["MC Admin"],
          },
        ],
        header: "Manage Appointments",
        location: "/bookingappointment",
        roles: ["Recessepnoist"],
      },
      {
        id: INVOICE,
        path: "invoice/*",
        element: <lazy.InvoiceLayout />,
        children: [
          {
            path: "",
            element: <lazy.InvoiceLayout />,
            children: [],
            header: "Invoice",
            location: "/invoice/information",
            roles: ["Super Admin"],
          },
          {
            path: "information",
            element: (
              <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
                <lazy.InvoiceInformation />
              </lazy.ErrorBoundaryHoc>
            ),
            children: [],
            header: "Invoice",
            location: "/invoice/information",
            roles: ["Super Admin"],
          },
          {
            path: "services",
            element: (
              <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
                <lazy.InvoiceServices />
              </lazy.ErrorBoundaryHoc>
            ),
            children: [],
            header: "Invoice - Services",
            location: "/invoice/services",
            roles: ["Super Admin"],
          },
          {
            path: "payment",
            element: (
              <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
                <lazy.InvoicePayment />
              </lazy.ErrorBoundaryHoc>
            ),
            children: [],
            header: "Invoice - Payment",
            location: "/invoice/payment",
            roles: ["Super Admin"],
          },
        ],
        header: "Invoice",
        location: "/invoice",
        roles: ["Super Admin"],
      },
      {
        id: RECEIPT,
        path: "receipt/*",
        element: (
          <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
            <lazy.ReceiptLayout />
          </lazy.ErrorBoundaryHoc>
        ),
        children: [
          {
            path: "",
            element: <lazy.Receipt />,
            children: [],
            header: "Receipt",
            location: "/receipt",
            roles: ["Receptionist"],
          },
        ],
        header: "Receipt",
        location: "/receipt",
        roles: ["Receptionist"],
      },
      // doctor routes
      {
        id: DC_DASHBOARD,
        path: "doctor/*",
        element: <lazy.DoctorAppointmentDashboard />,
        children: [],
        header: "Doctor",
        location: "/doctor",
        roles: ["DOCTOR"],
      },
      {
        id: DC_DIAGNOSIS,
        path: "patientdiagnosis/*",
        element: <lazy.DoctorDiagnosisLayout />,
        children: [
          {
            path: "diagnosis",
            element: <lazy.DiagnosisForm />,
            children: [],
            header: "Diagnosis",
            location: "/patientdiagnosis/diagnosis",
            roles: ["DOCTOR"],
          },
          {
            path: "medication",
            element: <lazy.Medication />,
            children: [],
            header: "Medication",
            location: "/patientdiagnosis/medication",
            roles: ["DOCTOR"],
          },
          {
            path: "request",
            element: <lazy.Request />,
            children: [],
            header: "Request",
            location: "/patientdiagnosis/request",
            roles: ["DOCTOR"],
          },
          {
            path: "referral",
            element: <lazy.Referral />,
            children: [],
            header: "Referral",
            location: "/patientdiagnosis/referral",
            roles: ["DOCTOR"],
          },
          {
            path: "treatment",
            element: <lazy.TreatmentPlanList />,
            children: [],
            header: "Treatment",
            location: "/patientdiagnosis/treatment",
            roles: ["DOCTOR"],
          },
          {
            path: "invoices",
            element: <lazy.Invoice />,
            children: [],
            header: "Doctor",
            location: "/patientdiagnosis/invoices",
            roles: ["DOCTOR"],
          },
        ],
        header: "Doctor Diagnosis",
        location: "/diagnosis",
        roles: ["DOCTOR"],
      },
      {
        id: FORM_BUILDER,
        path: "formBuilder/*",
        element: (
          <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
            <lazy.CreateNewFormsLayout />
          </lazy.ErrorBoundaryHoc>
        ),
        children: [
          {
            path: "",
            element: <lazy.CreateNewFormList />,
            children: [],
            header: "Create New Forms",
            location: "",
            roles: ["DOCTOR"],
          },
          {
            path: "createNewFormBuilder",
            element: <lazy.CreateNewFormBuilder />,
            children: [],
            header: "Create New Form Builder",
            location: "/createNewFormBuilder",
            roles: ["DOCTOR"],
          },
          {
            path: "*",
            element: <lazy.NotFound />,
            children: [],
            header: "Page Not Found",
            roles: ["DOCTOR"],
          },
        ],
        header: "Create New Forms",
        location: "/formBuilder",
        roles: ["DOCTOR"],
      },

      {
        id: SCHEDULAR,
        path: "schedular/*",
        element: <lazy.MainSchedular />,
        children: [
          {
            path: "",
            element: <lazy.BookingSchedularFunctional />,
            children: [],
            header: "Manage Appointments",
            location: "/schedular",
            roles: ["MC Admin"],
          },
          {
            path: "beds",
            element: <lazy.BedSchedular />,
            children: [],
            header: "Manage Appointments",
            location: "/schedular/beds",
            roles: ["MC Admin"],
          },
          {
            path: "nurse",
            element: <lazy.BookingSchedularFunctional />,
            children: [],
            header: "Patient Emr Dashboard",
            location: "/schedular/nurse",
            roles: ["MC Admin"],
          },
        ],
        header: "Manage Appointments",
        location: "/bookingappointment",
        roles: ["Recessepnoist"],
      },
      // lab routes
      {
        id: LAB_DASHBOARD,
        path: "dashboard",
        element: <lazy.LabDashboard />,
        children: [],
        header: "Lab Dashboard",
        location: "/dashboard",
        roles: ["Super Admin"],
      },
      {
        id: LAB_JOB,
        path: "job/*",
        element: <lazy.JobLayout />,
        children: [
          // {
          //   path: "",
          //   element: <lazy.JobLayout />,
          //   children: [],
          //   header: "Job",
          //   location: "/job/createjobs",
          //   roles: ["Super Admin"],
          // },
          // {
          //   path: "createjobs",
          //   element: <lazy.CreateJobs />,
          //   children: [],
          //   header: "Job",
          //   location: "/job/createjobs",
          //   roles: ["LAB_SUPERVISOR"],
          // },
          {
            path: "viewJobs",
            element: <lazy.ViewJobs />,
            children: [],
            header: "View Jobs",
            location: "/job/viewJobs",
            roles: ["Super Admin"],
          },
        ],
        header: "Job",
        location: "/job/viewJobs",
        roles: ["LAB_SUPERVISOR"],
      },
      {
        id: LAB_REQUEST,
        path: "request/*",
        element: <lazy.LabRequestLayout />,
        children: [
          {
            path: "",
            element: <lazy.LabRequest />,
            children: [],
            header: "Lab Request",
            location: "/request",
            roles: ["LAB_SUPERVISOR"],
          },
        ],
        header: "Lab Request",
        location: "/request",
        roles: ["LAB_SUPERVISOR"],
      },
      {
        id: LAB_INVOICE,
        path: "invoice/*",
        element: <lazy.LabInvoiceLayout />,
        children: [
          {
            path: "",
            element: <lazy.LabInvoiceLayout />,
            children: [],
            header: "Lab Invoice",
            location: "/invoice/labinformation",
            roles: ["Super Admin"],
          },
          {
            path: "labinformation",
            element: <lazy.LabInformation />,
            children: [],
            header: "Lab Invoice",
            location: "/invoice/labinformation",
            roles: ["Super Admin"],
          },
          {
            path: "labservices",
            element: <lazy.LabServices />,
            children: [],
            header: "Lab Services",
            location: "/invoice/labservices",
            roles: ["Super Admin"],
          },
          {
            path: "labpayment",
            element: <lazy.LabPayment />,
            children: [],
            header: "Lab Payment",
            location: "/labinvoice/labpayment",
            roles: ["Super Admin"],
          },
        ],
        header: "Lab",
        location: "/invoice",
        roles: ["Super Admin"],
      },
      {
        id: LAB_CONFIGURATION,
        path: "configuration/*",
        element: <lazy.Configuration />,
        children: [],
        header: "Configuration",
        location: "/configuration",
        roles: ["LAB_SUPERVISOR"],
      },

      // Radiology Routes
      {
        id: RADIOLOGY_DASHBOARD,
        path: "dashboard",
        element: <lazy.RadiologyDashboard />,
        children: [],
        header: "Radiology Dashboard",
        location: "/dashboard",
        roles: ["RADIOLOGY_SUPERVISOR"],
      },
      {
        id: RADIOLOGY_JOB,
        path: "radiology-job/*",
        element: <lazy.RadiologyJobLayout />,
        children: [
          // {
          //   path: "",
          //   element: <lazy.RadiologyJobLayout />,
          //   children: [],
          //   header: "Job",
          //   location: "/radiology-job/createjobs",
          //   roles: ["Super Admin"],
          // },
          // {
          //   path: "createjobs",
          //   element: <lazy.RadiologyCreateJobs />,
          //   children: [],
          //   header: "Job",
          //   location: "/radiology-job/createjobs",
          //   roles: ["Super Admin"],
          // },
          {
            path: "viewJobs",
            element: <lazy.RadiologyViewJobs />,
            children: [],
            header: "View Jobs",
            location: "/radiology-job/viewJobs",
            roles: ["Super Admin"],
          },
        ],
        header: "Job",
        location: "/radiology-job/viewJobs",
        roles: ["RADIOLOGY_SUPERVISOR"],
      },
      {
        id: RADIOLOGY_REQUEST,
        path: "radiology-request/*",
        element: <lazy.RadioLogyRequestLayout />,
        children: [
          {
            path: "",
            element: <lazy.RadiologyRequest />,
            children: [],
            header: "Radiology Request",
            location: "/radiology-request",
            roles: ["RADIOLOGY_SUPERVISOR"],
          },
        ],
        header: "Radiology Request",
        location: "/radiology-request",
        roles: ["RADIOLOGY_SUPERVISOR"],
      },
      {
        id: RADIOLOGY_INVOICE,
        path: "radiology-invoice/*",
        element: <lazy.RadiologyInvoiceLayout />,
        children: [
          {
            path: "",
            element: <lazy.RadiologyInvoiceLayout />,
            children: [],
            header: "Invoice",
            location: "/radiology-invoice/information",
            roles: ["RADIOLOGY_SUPERVISOR"],
          },
          {
            path: "information",
            element: <lazy.RadiologyInvoiceInformation />,
            children: [],
            header: "Invoice",
            location: "/radiology-invoice/information",
            roles: ["RADIOLOGY_SUPERVISOR"],
          },
          {
            path: "services",
            element: <lazy.RadiologyInvoiceService />,
            children: [],
            header: "Invoice - Services",
            location: "/radiology-invoice/services",
            roles: ["RADIOLOGY_SUPERVISOR"],
          },
          {
            path: "payment",
            element: <lazy.RadiologyInvoicePayment />,
            children: [],
            header: "Invoice - Payment",
            location: "/radiology-invoice/payment",
            roles: ["RADIOLOGY_SUPERVISOR"],
          },
        ],
        header: "Invoice",
        location: "/radiology-invoice",
        roles: ["RADIOLOGY_SUPERVISOR"],
      },
      {
        id: RADIOLOGY_CONFIGURAION,
        path: "radiology-configuration/*",
        element: <lazy.RadiologyConfiguration />,
        children: [],
        header: "Configuration",
        location: "/radiology-configuration",
        roles: ["RADIOLOGY_SUPERVISOR"],
      },

      // Inventory Routes
      {
        id: INVENTORY_REQUEST,
        path: "request/*",
        element: <lazy.RequestLayout />,
        children: [
          {
            path: "",
            element: <lazy.InventoryRequest />,
            children: [],
            header: "Request",
            location: "/request",
            roles: ["Super Admin"],
          },
        ],
        header: "request",
        location: "/request",
        roles: ["Super Admin"],
      },
      {
        id: BRANCH_STORE_DASHBOARD,
        path: "dashboard",
        element: <lazy.BranchStoreDashbopard />,
        children: [],
        header: "BranchStoreDashboard",
        location: "/dashboard",
        roles: ["Super Admin"],
      },
      {
        id: BRANCH_STORE,
        path: "branchstore/*",
        element: <lazy.BranchStoreLayout />,
        children: [
          {
            path: "",
            element: <lazy.BranchStore />,
            children: [],
            header: "Branch Store",
            location: "/branchstore",
            roles: ["Super Admin"],
          },
        ],
        header: "Branch Store",
        location: "/branchstore",
        roles: ["Super Admin"],
      },
      {
        id: MAIN_STORE_DASHBOARD,
        path: "dashboard",
        element: <lazy.MainStoreDashboard />,
        children: [],
        header: "MainStoreDashboard",
        location: "/dashboard",
        roles: ["Super Admin"],
      },
      {
        id: MAIN_STORE,
        path: "mainstore/*",
        element: <lazy.MainStoreLayout />,
        children: [
          {
            path: "",
            element: <lazy.MainStore />,
            children: [],
            header: "Main Store",
            location: "/mainstore",
            roles: ["Super Admin"],
          },
        ],
        header: "Mainstore",
        location: "/mainstore",
        roles: ["Super Admin"],
      },
      {
        id: PHARMACY_MAIN_STORE,
        path: "pharmacymainstore/*",
        element: <lazy.MainStoreLayout />,
        children: [
          {
            path: "",
            element: <lazy.MainStore />,
            children: [],
            header: "Pharmacy main Store",
            location: "/pharmacymainstore",
            roles: ["Super Admin"],
          },
        ],
        header: "Pharmacy",
        location: "/pharmacymainstore",
        roles: ["Super Admin"],
      },
      {
        id: PURCHASE_INVOICE,
        path: "purchaseinvoice/*",
        element: <lazy.PurchaseInvoiceLayout />,
        children: [
          {
            path: "",
            element: <lazy.PurchaseInvoice />,
            children: [],
            header: "Purchase Invoice",
            location: "/purchaseinvoice",
            roles: ["Super Admin"],
          },
        ],
        header: "Purchaseinvoice",
        location: "/purchaseinvoice",
        roles: ["Super Admin"],
      },

      // dentist routes

      {
        id: DNT_DASHBOARD,
        path: "dentist/*",
        element: <lazy.DentistAppointmentDashboard />,
        children: [],
        header: "Dentist",
        location: "/dentist",
        roles: ["DENTIST"],
      },
      {
        id: DNT_DIAGNOSIS,
        path: "patientdentaldiagnosis/*",
        element: <lazy.DentistDiagnosisLayout />,
        children: [
          {
            path: "diagnosis",
            element: <lazy.DentalDiagnosisForm />,
            children: [],
            header: "Diagnosis",
            location: "/patientdentaldiagnosis/diagnosis",
            roles: ["DENTIST"],
          },
          {
            path: "medication",
            element: <lazy.DentalMedication />,
            children: [],
            header: "Medication",
            location: "/patientdentaldiagnosis/medication",
            roles: ["DENTIST"],
          },
          {
            path: "request",
            element: <lazy.DentalRequest />,
            children: [],
            header: "Request",
            location: "/patientdentaldiagnosis/request",
            roles: ["DENTIST"],
          },
          {
            path: "referral",
            element: <lazy.DentalReferral />,
            children: [],
            header: "Referral",
            location: "/patientdentaldiagnosis/referral",
            roles: ["DENTIST"],
          },
          {
            path: "treatment",
            element: <lazy.DentalTreatmentPlanList />,
            children: [],
            header: "Treatment",
            location: "/patientdentaldiagnosis/treatment",
            roles: ["DENTIST"],
          },
        ],
        header: "Dentist Diagnosis",
        location: "/diagnosis",
        roles: ["DENTIST"],
      },

      // pharmacy route
      {
        id: PHARMACY_STORE,
        path: "dashboard",
        element: <lazy.PharmacyDashboard />,
        children: [],
        header: "Pharmacy Dashboard",
        location: "/dashboard",
      },
      {
        id: PHARMACY_POS,
        path: "pharmacy/*",
        element: <lazy.PharmacyLayout />,
        children: [
          {
            path: "pharmacy-info",
            element: <lazy.PharmacyInfoForm />,
            children: [],
            header: "POS",
            location: "/pharmacy/pharmacy-info",
            roles: ["MC Admin"],
          },
          {
            path: "pharmacy-payment",
            element: <lazy.PharmacyPaymentNew />,
            children: [],
            header: "POS",
            location: "/pharmacy/pharmacy-info",
            roles: ["MC Admin"],
          },
          {
            path: "pharmacy-onhold",
            element: <lazy.PharmacyOnhold />,
            children: [],
            header: "POS",
            location: "/pharmacy/pharmacy-onhold",
            roles: ["MC Admin"],
          },
        ],
        header: "Pharmacy",
        location: "/pharmacy",
        roles: ["Pharmacy"],
      },
      {
        id: PHARMACY_STORE,
        path: "pharmacystore",
        element: <lazy.PharmacyStore />,
        children: [],
        header: "Pharmacy Store",
        location: "/pharmacystore",
      },
      {
        id: "public",
        path: "*",
        element: <lazy.NotFound />,
        children: [],
        header: "Page Not Found",
        roles: ["MC Admin"],
      },
      {
        id: "public",
        path: "notfound",
        element: <lazy.NotFound />,
        children: [],
        header: "Page Not Found",
        roles: ["MC Admin"],
      },
      {
        id: "public",
        path: "notpermitted",
        element: <lazy.NotFound />,
        children: [],
        header: "Page Not Found",
        roles: ["MC Admin"],
      },

      // IPD routes
      {
        id: IPD_ID,
        path: "ipd/*",
        element: <lazy.IPD />,
        children: [
          {
            path: "",
            element: <lazy.IpdDashboard />,
            children: [],
            header: "IPD",
            location: "/ipd",
            roles: ["DOCTOR"],
          },
          {
            path: "createipdmainlayout",
            element: <lazy.CreateIpdMainLayout />,
            children: [
              {
                path: "consultation",
                element: <lazy.Consultation />,
                children: [],
                header: "IPD",
                location: "ipd/createipdmainlayout/consultation",
                roles: ["DOCTOR"],
              },
            ],
            header: "IPD",
            location: "/ipd/createipdmainlayout",
            roles: ["DOCTOR"],
          },
        ],
        header: "IPD",
        location: "/ipd",
        roles: ["DOCTOR"],
      },

      // Receptionist IPD Routes
      {
        id: IPD_INVOICE,
        path: "ipd-invoice/*",
        element: <lazy.IpdInvoiceLayout />,
        children: [
          {
            path: "",
            element: <lazy.IpdInvoiceLayout />,
            children: [],
            header: "Ipd Invoice",
            location: "/ipd-invoice",
            roles: ["Super Admin"],
          },
          {
            path: "patientinfo",
            element: (
              <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
                <lazy.PatientIPDInfo />
              </lazy.ErrorBoundaryHoc>
            ),
            children: [],
            header: "Ipd Invoice",
            location: "/ipd-invoice/patientinfo",
            roles: ["Super Admin"],
          },
          {
            path: "consumble",
            element: (
              <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
                <lazy.PatientConsumble />
              </lazy.ErrorBoundaryHoc>
            ),
            children: [],
            header: "Patient Consumble",
            location: "/ipd-invoice/consumble",
            roles: ["Super Admin"],
          },
          {
            path: "charges",
            element: (
              <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
                <lazy.PatientCharges />
              </lazy.ErrorBoundaryHoc>
            ),
            children: [],
            header: "Patient Charges",
            location: "/ipd-invoice/charges",
            roles: ["Super Admin"],
          },
          {
            path: "services",
            element: (
              <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
                <lazy.PatientIPDServices />
              </lazy.ErrorBoundaryHoc>
            ),
            children: [],
            header: "Patient Services",
            location: "/ipd-invoice/services",
            roles: ["Super Admin"],
          },

          {
            path: "payment",
            element: (
              <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
                <lazy.PatientPayment />
              </lazy.ErrorBoundaryHoc>
            ),
            children: [],
            header: "Patient Payment",
            location: "/ipd-invoice/payment",
            roles: ["Super Admin"],
          },
        ],
        header: "Ipd Invoice",
        location: "/ipd-invoice",
        roles: ["Super Admin"],
      },
      {
        id: IPD_CREATE_CASE,
        path: "ipd-case-form",
        element: <lazy.IpdCreateForm isRecep={true} />,
        children: [],
        header: "IPD",
        location: "/ipd-case-form",
        roles: ["RECEPTIONIST"],
      },
      // Call Center Admin Routes
      {
        id: CC_ADMIN_DASHBOARD,
        path: "call/*",
        element: <lazy.CallCenterDashboardLayout />,
        children: [
          {
            path: "",
            element: <lazy.Call />,
            children: [],
            header: "Call Center Admin",
            location: "/call",
            roles: ["Medical Center Admin"],
          },
        ],
        header: "Call Center Admin",
        location: "/call",
        roles: ["Super Admin"],
      },
      {
        id: CC_LEAD_POOL,
        path: "lead/*",
        element: <lazy.LeadLayout />,
        children: [
          {
            path: "leads",
            element: <lazy.Leads />,
            children: [],
            header: "Lead Pool",
            location: "/lead/leads",
            roles: ["Medical Center Admin"],
          },
          {
            path: "internal",
            element: <lazy.Internal />,
            children: [],
            header: "Lead Pool",
            location: "/lead/internal",
            roles: ["Medical Center Admin"],
          },
          {
            path: "external",
            element: <lazy.External />,
            children: [],
            header: "Lead Pool ",
            location: "lead/external",
            roles: ["Medical Center Admin"],
          },
        ],
        header: "Proact",
        location: "/lead",
        roles: ["Super Admin"],
      },
      {
        id: CC_AGENT,
        path: "agents/*",
        element: <lazy.AgentsLayout />,
        children: [
          {
            path: "",
            element: <lazy.Agents />,
            children: [],
            header: "Agents",
            location: "/agents",
            roles: ["Medical Center Admin"],
          },
        ],
        header: "Agents",
        location: "/agents",
        roles: ["Super Admin"],
      },
      {
        id: CC_CAMPAIGN,
        path: "campaigns/*",
        element: <lazy.CampaignsLayout />,
        children: [
          {
            path: "onGoingCampaigns",
            element: <lazy.OnGoingCampaigns />,
            children: [],
            header: "Campaigns",
            location: "campaigns/onGoingCampaigns",
            roles: ["Medical Center Admin"],
          },
          {
            path: "createCampaigns",
            element: <lazy.CreateCampaigns />,
            children: [],
            header: "Campaigns",
            location: "campaigns/createCampaigns",
            roles: ["Medical Center Admin"],
          },
        ],
        header: "Campaigns",
        location: "/campaigns",
        roles: ["Super Admin"],
      },

      //call center Agent
      {
        id: CC_AGENT_DASHBOARD,
        path: "agent/*",
        element: <lazy.AgentDashboardLayout />,
        children: [
          {
            path: "agentleads",
            element: <lazy.AgentLeads />,
            children: [],
            header: "Leads",
            location: "/agent/agentleads",
            roles: ["Medical Center Admin"],
          },

          {
            path: "follow-up",
            element: <lazy.FollowUp />,
            children: [],
            header: "Follow Up",
            location: "/agent/follow-up",
            roles: ["Medical Center Admin"],
          },
        ],
        header: "Call Center Agent",
        location: "/agent",
        roles: ["Super Admin"],
      },
      {
        id: CRM_ADMIN_KEY,
        path: "crmadmin/*",
        element: <lazy.CrmAdminLayout />,
        children: [
          {
            path: "new",
            element: <lazy.CrmNew />,
            children: [],
            header: "CRM Admin",
            location: "/crmadmin/new",
            roles: ["Medical Center Admin"],
          },
          {
            path: "sent",
            element: <lazy.CrmSent />,
            children: [],
            header: "CRM Admin",
            location: "/crmadmin/sent",
            roles: ["Medical Center Admin"],
          },
        ],
        header: "Crm Admin",
        location: "/crmadmin",
        roles: ["Super Admin"],
      },
      {
        id: REPORT_GENERATOR,
        path: "reportgenerator",
        element: (
          <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
            <lazy.ReportBuilder />
          </lazy.ErrorBoundaryHoc>
        ),
        children: [],
        header: "Report Generator",
        location: "/reportgenerator",
      },
      {
        id: REPORT_BUILDER,
        path: "reportbuilder/*",
        element: <lazy.ReportBuilderLayout />,
        children: [
          {
            path: "",
            element: (
              <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
                <lazy.ReportBuilderList />
              </lazy.ErrorBoundaryHoc>
            ),
            children: [],
            header: "Report Builder",
            location: "/reportbuilder",
            roles: ["MC Admin"],
          },
          {
            path: "createreport",
            element: (
              <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
                <lazy.CreateReport />
              </lazy.ErrorBoundaryHoc>
            ),
            children: [],
            header: "Report Builder",
            location: "/reportbuilder/createreport",
            roles: ["MC Admin"],
          },
        ],
        header: "Report Builder",
        location: "/reportbuilder",
        roles: ["Medical Center Admin"],
      },
      // report mapping
      {
        id: REPORT_MAPPING,
        path: "report-mapping/*",
        element: (
          <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
            <lazy.ReportMapping />
          </lazy.ErrorBoundaryHoc>
        ),
        children: [],
        header: "Report Mapping",
        location: "/report-mapping",
        roles: ["MC Admin"],
      },
      // HL7
      {
        id: REPORT_BUILDER,
        path: "hl7/*",
        element: (
          <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
            <lazy.HL7Layout />
          </lazy.ErrorBoundaryHoc>
        ),
        children: [
          {
            path: "",
            element: (
              <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
                <lazy.HL7List />
              </lazy.ErrorBoundaryHoc>
            ),
            children: [],
            header: "HL7",
            location: "/hl7",
            roles: ["MC Admin"],
          },
          {
            path: "createhl7",
            element: (
              <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
                <lazy.CreateHL7 />
              </lazy.ErrorBoundaryHoc>
            ),
            children: [],
            header: "HL7",
            location: "/hl7/createhl7",
            roles: ["MC Admin"],
          },
        ],
        header: "HL7",
        location: "/hl7",
        roles: ["MC Admin"],
      },
      // HR dashboard

      {
        id: HR_DASHBOARD,
        path: "hr-dashboard/*",
        element: (
          <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
            <lazy.HRDashboardLayout />
          </lazy.ErrorBoundaryHoc>
        ),
        children: [
          {
            path: "",
            element: <lazy.HrDashboard />,
            children: [],
            header: "Dashboard",
            location: "/hr-dashboard",
            roles: ["HR Admin"],
          },
          {
            path: "departments",
            element: <lazy.Departments />,
            children: [],
            header: "Departments",
            location: "/hr-dashboard/departments",
            roles: ["Super Admin"],
          },
        ],
        header: "Dashboard",
        location: "/hr-dashboard",
        roles: ["Super Admin"],
      },

      // HR Employee

      {
        id: EMP_MANAGEMENT,
        path: "employees/*",
        element: (
          <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
            <lazy.EmployeesLayout />
          </lazy.ErrorBoundaryHoc>
        ),
        children: [
          {
            path: "",
            element: <lazy.Employees />,
            children: [],
            header: "Employees",
            location: "/employees",
            roles: ["Super Admin"],
          },
          {
            path: "addEmployee",
            element: <lazy.AddEmployee />,
            children: [],
            header: "Add New Employee",
            location: "/employees/addEmployee",
            roles: ["Super Admin"],
          },
        ],
        header: "Employees",
        location: "/employees",
        roles: ["Super Admin"],
      },

      // HR Documents
      {
        id: DOCUMENTS,
        path: "documents/*",
        element: (
          <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
            <lazy.DocumentsLayout />
          </lazy.ErrorBoundaryHoc>
        ),
        children: [
          {
            path: "",
            element: <lazy.Documents />,
            children: [],
            header: "Documents",
            location: "/documents",
            roles: ["Super Admin"],
          },
          {
            path: "addnew",
            element: <lazy.AddDocument />,
            children: [],
            header: "Add New Document",
            location: "/documents/addnew",
            roles: ["Super Admin"],
          },
        ],
        header: "Documents",
        location: "/documents",
        roles: ["Super Admin"],
      },

      // HR Payroll
      {
        id: PAYROLL,
        path: "payroll/*",
        element: (
          <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
            <lazy.PayrollLayout />
          </lazy.ErrorBoundaryHoc>
        ),
        children: [
          {
            path: "",
            element: <lazy.Payroll />,
            children: [],
            header: "Payroll",
            location: "/payroll",
            roles: ["Super Admin"],
          },
          {
            path: "create",
            element: <lazy.Create />,
            children: [],
            header: "Create Salary Slip",
            location: "/payroll/create",
            roles: ["Super Admin"],
          },
          {
            path: "services",
            element: <lazy.EndofServices />,
            children: [],
            header: "End Of Services",
            location: "/payroll/services",
            roles: ["Super Admin"],
          },
          {
            path: "services/new",
            element: <lazy.New />,
            children: [],
            header: "Add New",
            location: "/payroll/services/new",
            roles: ["Super Admin"],
          },
        ],
        header: "Payroll",
        location: "/payroll",
        roles: ["Super Admin"],
      },

      // HR Attendance
      {
        id: ATTENDANCE,
        path: "attendance/*",
        element: (
          <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
            <lazy.AttendaceLayout />
          </lazy.ErrorBoundaryHoc>
        ),
        children: [
          // {
          //   path: '',
          //   element: <Attendance />,
          //   children: [],
          //   header: 'Attendance',
          //   location: '/attendance',
          //   roles: ['Super Admin'],
          // },
          {
            path: "",
            element: <lazy.ManageAttendance />,
            children: [],
            header: "Attendance",
            location: "/attendance/manage-attendance",
            roles: ["Super Admin"],
          },
        ],
        header: "Attendance",
        location: "/attendance",
        roles: ["Super Admin"],
      },

      // HR Leave
      {
        id: LEAVE_MANAGEMENT,
        path: "leave-management/*",
        element: (
          <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
            <lazy.LeaveManagementLayout />
          </lazy.ErrorBoundaryHoc>
        ),
        children: [
          {
            path: "",
            element: <lazy.LeaveManagement />,
            children: [],
            header: "Leave Management",
            location: "/leave-management",
            roles: ["Super Admin"],
          },
          {
            path: "leave-encashment",
            element: <lazy.LeaveEncashment />,
            children: [],
            header: "Leave Encashment",
            location: "/leave-management/leave-encashment",
            roles: ["Super Admin"],
          },
          {
            path: "leave-encashment/leave-formdata",
            element: <lazy.LeaveFormData />,
            children: [],
            header: "Leave Encashment",
            location: "/leave-management/leave-encashment/leave-formdata",
            roles: ["Super Admin"],
          },
          {
            path: "apply-leave",
            element: <lazy.ApplyLeave />,
            children: [],
            header: "Leave Management",
            location: "/leave-management/apply-leave",
            roles: ["Super Admin"],
          },
        ],
        header: "Leave Management",
        location: "/leaveManagement",
        roles: ["Super Admin"],
      },

      // Account Dashboard
      {
        id: ACCOUNT_DASHBOARD,
        path: "account-dashboard/*",
        element: (
          <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
            <lazy.AccountDashboardLayout />
          </lazy.ErrorBoundaryHoc>
        ),
        children: [
          {
            path: "",
            element: <lazy.Dashboard />,
            children: [],
            header: "Dashboard",
            location: "/account-dashboard",
            roles: ["Super Admin"],
          },
        ],
        header: "Dashboard",
        location: "/dashboard",
        roles: ["Super Admin"],
      },

      // View chart of accounts
      {
        id: VIEW_CHART_OF_ACCOUNTS,
        path: "viewchartofaccounts/*",
        element: (
          <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
            <lazy.ViewChartOfAccountsLayout />
          </lazy.ErrorBoundaryHoc>
        ),
        children: [
          {
            path: "",
            element: <lazy.ViewChartOfAccountsLayout />,
            children: [],
            header: "View chart of accounts",
            location: "/viewchartofaccounts",
            roles: ["Super Admin"],
          },
          {
            path: "level2list",
            element: <lazy.Level2List />,
            children: [],
            header: "View chart of accounts",
            location: "/viewchartofaccounts/level2list",
            roles: ["Super Admin"],
          },
          {
            path: "level3list",
            element: <lazy.Level3List />,
            children: [],
            header: "View chart of accounts",
            location: "/viewchartofaccounts/level3list",
            roles: ["Super Admin"],
          },
          {
            path: "level4list",
            element: <lazy.Level4List />,
            children: [],
            header: "View chart of accounts",
            location: "/viewchartofaccounts/level4list",
            roles: ["Super Admin"],
          },
          {
            path: "level5list",
            element: <lazy.Level5List />,
            children: [],
            header: "View chart of accounts",
            location: "/viewchartofaccounts/level5list",
            roles: ["Super Admin"],
          },
        ],
        header: "View Chart Of Accounts",
        location: "/viewchartofaccounts",
        roles: ["Super Admin"],
      },

      // vouchers
      {
        id: VOUCHERS,
        path: "vouchers/*",
        element: (
          <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
            <lazy.VouchersLayout />
          </lazy.ErrorBoundaryHoc>
        ),
        children: [
          {
            path: "",
            element: <lazy.Vouchers />,
            children: [],
            header: "Vouchers",
            location: "/vouchers",
            roles: ["Super Admin"],
          },
        ],
        header: "Vouchers",
        location: "/vouchers",
        roles: ["Super Admin"],
      },

      // Account reports
      {
        id: REPORTS,
        path: "reports/*",
        element: (
          <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
            <lazy.ReportsLayout />
          </lazy.ErrorBoundaryHoc>
        ),
        children: [
          {
            path: "ledger",
            element: <lazy.Ledger />,
            children: [],
            header: "Reports - Ledger",
            location: "/reports/ledger",
            roles: ["Super Admin"],
          },
          {
            path: "trialbalance",
            element: <lazy.TrialBalance />,
            children: [],
            header: "Reports - Trial Balance",
            location: "/reports/trialbalance",
            roles: ["Super Admin"],
          },
          {
            path: "pl",
            element: <lazy.ProfitLoss />,
            children: [],
            header: "Reports - P & L",
            location: "/reports/pl",
            roles: ["Super Admin"],
          },
          {
            path: "balancesheet",
            element: <lazy.BalanceSheet />,
            children: [],
            header: "Reports - Balance Sheet",
            location: "/reports/balancesheet",
            roles: ["Super Admin"],
          },
        ],
        header: "Reports",
        location: "/reports",
        roles: ["Super Admin"],
      },

      // Manage Unavailability
      {
        id: UNAVAILABILITY,
        path: "unavailability/*",
        element: (
          <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
            <lazy.UnavailabilityLayout />
          </lazy.ErrorBoundaryHoc>
        ),
        children: [
          {
            path: "",
            element: <lazy.UnavailabilityLayout />,
            children: [],
            header: "Manage Unavailability",
            location: "/unavailability",
            roles: ["Super Admin"],
          },
          {
            path: "doctor",
            element: <lazy.DoctorUnavailablity />,
            children: [],
            header: "Manage Unavailability",
            location: "/unavailability/doctor",
            roles: ["Super Admin"],
          },
          {
            path: "rooms",
            element: <lazy.DoctorUnavailablity />,
            children: [],
            header: "Manage Unavailability",
            location: "/unavailability/room",
            roles: ["Super Admin"],
          },
        ],
        header: "Manage Unavailability",
        location: "/unavailability",
        roles: ["Super Admin"],
      },

      // UPAY Configuration
      // {
      //   id: UPAY_CONFIG,
      //   path: 'upay/*',
      //   element: (
      //     <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
      //       <UpayConfiguration />
      //     </lazy.ErrorBoundaryHoc>
      //   ),
      //   children: [],
      //   header: 'UPAY Configuration',
      //   location: '/upay',
      //   roles: ['Super Admin'],
      // },

      {
        id: UPAY_CONFIG,
        path: "configuration/*",
        element: (
          <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
            <lazy.Configurations />
          </lazy.ErrorBoundaryHoc>
        ),
        children: [
          {
            path: "upay",
            element: (
              <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
                <lazy.UpayConfiguration />
              </lazy.ErrorBoundaryHoc>
            ),
            children: [],
            header: "Configuration",
            location: "configuration/upay",
            roles: ["Super Admin"],
          },
          {
            path: "whatsup",
            element: (
              <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
                <lazy.Whatsup />
              </lazy.ErrorBoundaryHoc>
            ),
            children: [],
            header: "Configuration",
            location: "configuration/whatsup",
            roles: ["Super Admin"],
          },
          {
            path: "wapi",
            element: (
              <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
                <lazy.Wapi />
              </lazy.ErrorBoundaryHoc>
            ),
            children: [],
            header: "Wapi-template",
            location: "configuration/wapi",
            roles: ["Super Admin"],
          },
          {
            path: "configuration-details",
            element: (
              <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
                <lazy.ConfigurationDetails />
              </lazy.ErrorBoundaryHoc>
            ),
            children: [],
            header: "Configuration",
            location: "configuration/configuration-details",
            roles: ["Super Admin"],
          },
          {
            path: "tax",
            element: (
              <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
                <lazy.TaxConfiguration />
              </lazy.ErrorBoundaryHoc>
            ),
            children: [],
            header: "Tax",
            location: "configuration/tax",
            roles: ["Super Admin"],
          },
          {
            path: "compliance",
            element: (
              <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
                <lazy.ComplianceDetails />
              </lazy.ErrorBoundaryHoc>
            ),
            children: [],
            header: "Compliance",
            location: "configuration/compliance",
            roles: ["Super Admin"],
          },
        ],
        header: "Configuration",
        location: "/configuration",
        roles: ["Super Admin"],
      },
      // Import utility
      {
        id: IMPORT_UTILITY,
        path: "import-utility/*",
        element: (
          <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
            <lazy.ImportUtility />
          </lazy.ErrorBoundaryHoc>
        ),
        children: [],
        header: "Import Utility",
        location: "/import-utility",
        roles: ["Super Admin"],
      },
     
     
      // dynamic invoice template
      {
        id: DYNAMIC_TEMPLATE,
        path: "template/*",
        element: (
          <lazy.ErrorBoundaryHoc errorMessage="Something Went Wrong...">
            <lazy.DynamicTemplateLayout />
          </lazy.ErrorBoundaryHoc>
        ),
        children: [
          {
            path: "",
            element: <lazy.DynamicTemplate />,
            children: [],
            header: "Set template for invoice & receipt",
            location: "/template",
            roles: ["MC Admin"],
          },
          {
            path: "manage/*",
            element: <lazy.ManageDynamicTemplate />,
            children: [],
            header: "Set template for invoice & receipt",
            location: "/template/manage",
            roles: ["Super Admin"],
          },
        ],
        header: "Set template for invoice & receipt",
        location: "/template",
        roles: ["MC Admin"],
      },
    ],
    header: "Home",
    roles: ["MC Admin"],
  },
];

export const notFoundRoutes: MyRoutes[] = [
  ...commonRoutes,
  {
    path: "/*",
    element: (
      <lazy.AuthWrapper>
        <lazy.MainLayout />
      </lazy.AuthWrapper>
    ),
    children: [
      {
        path: "*",
        element: <lazy.NotFound />,
        children: [],
        header: "Page Not Found",
        roles: ["MC Admin"],
      },
      {
        path: "notfound",
        element: <lazy.NotFound />,
        children: [],
        header: "Page Not Found",
        roles: ["MC Admin"],
      },
      {
        path: "notpermitted",
        element: <lazy.NotFound />,
        children: [],
        header: "Page Not Found",
        roles: ["MC Admin"],
      },
    ],
    header: "Home",
    roles: ["MC Admin"],
  },
];

export const mainRouteData: MyRoutes[] = [...commonRoutes, ...authRoutes];
