import { CREATE_COMPLIANCE_CONFIGURATION, GET_ALL_COMPLIANCE_CONFIGURATION, UPDATE_COMPLIANCE_CONFIGURATION } from "../../../constants/asyncActionsType";
import createAsyncThunkForSlice from "../../../utils/utils";
import { createCompliance, getAllCompliance, updateCompliance } from "./complianceCrud";

export const createComplianceConfiguration = createAsyncThunkForSlice(
  CREATE_COMPLIANCE_CONFIGURATION,
  createCompliance,
  {
    isToast: true,
  }
);



export const getAllComplianceConfiguration = createAsyncThunkForSlice(
  GET_ALL_COMPLIANCE_CONFIGURATION,
  getAllCompliance,
  {
    isToast: true,
  }
);

export const updateComplianceConfiguration = createAsyncThunkForSlice(
  UPDATE_COMPLIANCE_CONFIGURATION,
  updateCompliance,
  {
    isToast: true,
  }
);
