import { useState, FC, useEffect } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import styles from './login.module.scss'
import { loginValidators } from '../../form-validators/loginValidators'
import { EmailIcon, PasswordIcon } from '../../components/common/svg-components'
import Button from '../../components/common/button/Button'
import { ILoginFormInputs, passwordType } from '../../interfaces/interfaces'
import EyeIcons from '../../components/common/eye-icon/EyeIcon'
import { EMAIL, PASSWORD } from '../../constants/constant'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  filterSideBarData,
  getSearchQueryinObject,
  trimValue,
  uuid,
} from '../../utils/utils'
import { requestGenerator } from '../../utils/payloadGenerator'
import {
  fetchFirebaseToken,
  setActiveRole,
  setEncryptionKey,
} from '../../redux/features/login/loginSlice'
import {
  getAllMasterValueData,
  getSideBarData,
  userLogin,
} from '../../redux/features/login/loginAsynActions'
import { getPatientBranchList } from '../../redux/features/patient-emr/patient/patientAsyncAction'
import {
  GET_ALL_BRANCH_LIST,
  GET_SIDEBAR_TYPE,
  USER_LOGIN_TYPE,
} from '../../constants/asyncActionsType'
import Loader from '../../components/common/spinner/Loader'
import { mainSidebarData } from '../../constants/routesPermission/mainSidebarData'
import healthboxtext from '../../assets/images/HealthboxLogoText.png'
import { useTranslation } from 'react-i18next'

const Login: FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const state = useLocation()
  const queryString = getSearchQueryinObject(state.search)

  const [passwordType, setPasswordType] = useState<passwordType>('password')
  const { loading } = useAppSelector((state) => state.login)

  const {
    register,
    handleSubmit,
    // setError,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm<ILoginFormInputs>()

  const onSubmit: SubmitHandler<ILoginFormInputs> = (data) => {
    const staticKey: string = uuid()

    dispatch(setEncryptionKey(staticKey))
    dispatch(userLogin(requestGenerator(data))).then((e) => {
      if (e.type === `${USER_LOGIN_TYPE}/fulfilled`) {
        dispatch(fetchFirebaseToken())
        dispatch(getPatientBranchList(requestGenerator({}))).then(
          (profileEvent) => {
            if (profileEvent.type === `${GET_ALL_BRANCH_LIST}/fulfilled`) {
              if (profileEvent.payload?.rolesArray) {
                const activeRole = {
                  label: profileEvent.payload?.rolesArray[0]?.name,
                  value: profileEvent.payload?.rolesArray[0]?._id,
                }
                dispatch(setActiveRole(activeRole))
                dispatch(
                  getSideBarData(
                    requestGenerator({ role_id: activeRole?.value })
                  )
                ).then((sidebarEvent) => {
                  if (sidebarEvent.type === `${GET_SIDEBAR_TYPE}/fulfilled`) {
                    const moduleIdArr = sidebarEvent.payload?.map(
                      (item: any) => item?.moduleId
                    )
                    const data = filterSideBarData(moduleIdArr, mainSidebarData)
                    navigate(
                      data[0]?.navigateAfterLogin
                        ? data[0]?.navigateAfterLogin
                        : data[0]?.navigate
                        ? data[0]?.navigate
                        : '/'
                    )
                  }
                })
              }
              dispatch(
                getAllMasterValueData(
                  requestGenerator({
                    category_name: [
                      'REFERRAL_DOCTOR',
                      'APPOINTMENT_TAG',
                      'DIAGNOSIS_STATUS',
                      'DIAGNOSIS_MAIN_COMPLAINT',
                      'TREATMENT_SERVICE',
                      'DOCUMENT_CATEGORY',
                      'APPOINTMENT_STATUS',
                      'INVENTORY_UNIT_TYPE',
                      'DENTAL_DIAGNOSIS_MAIN_COMPLAINT',
                      'CURRENCY',
                      'NATIONALITY',
                      'SOURCE_AD_CAMPAIGN',
                      'SUB-COMPANY',
                      'SHIFT-TYPE',
                      'WEEKDAYS',
                      'ALLOWANCE',
                      'DEDUCTIONS',
                      'DOCUMENT-TYPE',
                      'LEAVE-TYPE',
                      'PAYMENT_MODE',
                    ],
                    search: '',
                  })
                )
              )
            }
          }
        )
      }
    })
  }

  useEffect(() => {
    if (queryString?.hasOwnProperty?.('u')) {
      // reset(queryString)
      setValue(EMAIL, queryString.u)
      setValue(PASSWORD, 'Abc@123')
      handleSubmit(onSubmit)()
    }
  }, [])

  return (
    <>
      {loading && <Loader />}
      {/* <p className={styles.formTitle}>Sign in</p> */}
      <div className={styles.mainContainer}>
        <div className={styles.logoContainer}>
          <img
            src={healthboxtext}
            alt="error to display"
            className={styles.logoImg}
          />
        </div>
        <p className={styles.formTitle}>{t('Auth.Sign in')}</p>
        <p className={styles.formSubTitle}>{t('Auth.WelcomText')}</p>

        <div className={styles.formContainer}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.loginForm}>
              {/* <span className={styles.iconLabelStyle}>
                <EmailIcon customClass={styles.iconCustomClass} /> */}
              <label htmlFor={EMAIL} className={styles.labelStyle}>
                {t('Auth.Email')}{' '}
              </label>
              {/* </span> */}
              <div className={styles.inputFieldContainer}>
                <input
                  type="text"
                  placeholder={t('Auth.Enter email') || 'Enter email'}
                  className={styles.inputFieldStyle}
                  {...register(EMAIL, loginValidators[EMAIL])}
                  // onChange={(e) => trimValue(e)}
                />
                <p className={styles.errorText}>
                  {errors.email && (
                    <span className="error">{errors.email.message}</span>
                  )}
                </p>
              </div>
            </div>
            <div className={styles.loginForm}>
              {/* <span className={styles.iconLabelStyle}>
                <PasswordIcon customClass={styles.iconCustomClass} /> */}
              <label htmlFor={PASSWORD} className={styles.labelStyle}>
                {t('Auth.Password')}
              </label>
              {/* </span> */}
              <div className={styles.inputFieldContainer}>
                <span style={{ position: 'relative' }}>
                  <input
                    type={passwordType}
                    placeholder={t('Auth.Enter password') || 'Enter password'}
                    className={styles.inputFieldStyle}
                    {...register(PASSWORD, loginValidators[PASSWORD])}
                    // onChange={(e) => trimValue(e)}
                  />
                  <EyeIcons
                    passwordType={passwordType}
                    setPasswordType={setPasswordType}
                    customClass={styles.eyeIcon}
                    handleClick={() => setPasswordType('password')}
                  />
                </span>
                <p className={styles.errorText}>
                  {errors.password && (
                    <span className="error">{errors.password.message}</span>
                  )}
                </p>
              </div>
            </div>
            <div className={styles.forgotPasswordContainer}>
              <span
                className={styles.forgotPasswordStyle}
                onClick={() =>
                  navigate('/forgotpassword', {
                    state: { renderForgotPassword: true },
                  })
                }
              >
                {t('Auth.PasswordForgot')}
              </span>

              <span className={styles.forgotPasswordStyle}>
                {t('Auth.NeedSupport')}
              </span>
            </div>
            <Button
              title={t('Auth.Sign in') || 'Sign in'}
              type="submit"
              customClass={styles.loginButtonStyle}
              disable={loading}
            />{' '}
          </form>
        </div>
      </div>
    </>
  )
}

export default Login
