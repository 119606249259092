import React, { useState } from "react";
import { FC } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  HamburgerIconOpen,
  HamburgerIconClose,
} from "../../../components/common/svg-components";
import healthBoxLogo from "../../../assets/images/healthBoxLogo.png";
import healthBoxMainLogo from "../../../assets/images/healthBoxMainLogo.png";
import usePermissions from "../../../hooks/usePermissions";
import styles from "./sidebar.module.scss";
import { useTranslation } from "react-i18next";

interface ISidebarProps {
  sidebarData: any[];
}

const SideBar: FC<ISidebarProps> = ({ sidebarData }) => {
  const [id, setId] = useState(-1);
  const [showSidebar, setshowSidebar] = useState(false);
  const state = useLocation().state;
  const navigate = useNavigate();
  const { sidebar } = usePermissions();
  const handleNavigateToHome = () => {
    // const { navigateTo } = navigateAfterLogin(userData?.role)
    navigate(sidebar[0]?.navigate ? sidebar[0]?.navigate : "/notfound");
  };
  const location = useLocation().pathname;
  let matchPath = location.split("/");
  const { t } = useTranslation();
  return (
    <>
      {!state?.notRenderSidebar && (
        <div className={styles.sidebarContainer}>
          <div className={styles.imgToggleContainer}>
            {
              <div className={styles.logoHamburgerContainer}>
                <img
                  src={healthBoxLogo}
                  alt="healthbox_logo"
                  className={styles.logoStyle}
                  onClick={() => handleNavigateToHome()}
                />
                <HamburgerIconOpen
                  customClass={styles.toggleStyle}
                  handleClick={() => setshowSidebar(!showSidebar)}
                />
              </div>
            }
          </div>
          <div className={styles.sidebarData}>
            {sidebarData.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <NavLink
                    to={item.navigate}
                    className={({ isActive }) =>
                      isActive || id === item?.id
                        ? item?.navigate
                          ? styles.active
                          : matchPath[1] === item?.activeLocation
                          ? styles.active
                          : styles.sidebarContent
                        : styles.sidebarContent
                    }
                    onMouseEnter={() => setId(item?.id)}
                    onMouseLeave={() => setId(-1)}
                    children={({ isActive }) => {
                      return (
                        <>
                          <div className={styles.iconTooltip}>
                            <item.icon />
                            <p className={styles.iconTooltipText}>
                              {t(`Sidebar.${item.name}`).startsWith("Sidebar")
                                ? item.name
                                : t(`Sidebar.${item.name}`)}
                            </p>
                          </div>
                        </>
                      );
                    }}
                  ></NavLink>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      )}
      {!state?.notRenderSidebar && showSidebar && (
        <div className={styles.sidebarOverlayContainer}>
          <div className={styles.sidebarAnimatedContainer}>
            <div className={styles.imgToggleContainer}>
              {
                <div className={styles.logoHamburgerContainer}>
                  <img
                    src={healthBoxMainLogo}
                    alt="healthbox_logo"
                    className={styles.logoStyle}
                    onClick={() => handleNavigateToHome()}
                  />
                  <HamburgerIconClose
                    customClass={styles.toggleStyle}
                    handleClick={() => setshowSidebar(!showSidebar)}
                  />
                </div>
              }
            </div>
            <div className={styles.sidebarData}>
              <hr className={styles.sidebarDivider} />
              {sidebarData?.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <NavLink
                      to={item.navigate}
                      className={({ isActive }) =>
                        isActive || id === item?.id
                          ? item?.navigate
                            ? styles.active
                            : matchPath[1] === item?.activeLocation
                            ? styles.active
                            : styles.sidebarContent
                          : styles.sidebarContent
                      }
                      onMouseEnter={() => setId(item?.id)}
                      onMouseLeave={() => setId(-1)}
                      children={({ isActive }) => {
                        return (
                          <>
                            <div className={styles.iconTooltip}>
                              <div className={styles.iconStyle}>
                                <item.icon />
                              </div>
                              <p className={styles.iconTooltipText}>
                                {t(`Sidebar.${item.name}`).startsWith("Sidebar")
                                  ? item.name
                                  : t(`Sidebar.${item.name}`)}
                              </p>
                            </div>
                          </>
                        );
                      }}
                      onClick={()=>setshowSidebar(!sidebar)}
                    ></NavLink>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
          <div
            className={styles.sidebarOverlay}
            onClick={() => setshowSidebar(!showSidebar)}
          />
        </div>
      )}
    </>
  );
};

export default SideBar;
