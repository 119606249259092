// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dashboard_dashbordContainer__5V6gB {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}", "",{"version":3,"sources":["webpack://./src/pages/account-dashboard/dashboard/dashboard/dashboard.module.scss"],"names":[],"mappings":"AA6CA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;AA5CF","sourcesContent":["@import '../../../../styles/mixin.scss';\n\n// .requestContainer {\n//   @include pageWrapper(30px 25px);\n//   // @include font(var(--black1), var(--font-80), var(--font-extrabold));\n//   margin: 0;\n//   margin-bottom: 40px;\n//   width: 97%;\n//   @include flexCenter;\n//   flex-direction: column;\n//   .requestTextComingSoon {\n//     background: linear-gradient(\n//       91.42deg,\n//       #5936f1 0%,\n//       #2adbdf 99.54%,\n//       #4bb3e7 99.98%,\n//       #47dae3 99.99%\n//     );\n//     font-size: var(--font-80);\n//     font-weight: 800;\n//     background-clip: text;\n//     color: transparent;\n//     @include responsive(568px) {\n//       font-size: var(--font-50);\n//     }\n//   }\n\n//   .requestText {\n//     @include font(var(--black1), var(--font-22), var(--font-medium));\n//     margin: 12px 0px 36px 0px;\n//     @include responsive(568px) {\n//       font-size: var(--font-16);\n//     }\n//   }\n\n//   .requestVectorStyle {\n//     height: 300px;\n//     @include responsive(568px) {\n//       width: 100%;\n//       height: 200px;\n//     }\n//   }\n// }\n\n\n.dashbordContainer{\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dashbordContainer": "dashboard_dashbordContainer__5V6gB"
};
export default ___CSS_LOADER_EXPORT___;
