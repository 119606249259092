import { useEffect, useState } from 'react'
import Button from '../../components/common/button/Button'
import styles from './hl7List.module.scss'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { requestGenerator } from '../../utils/payloadGenerator'
import { getAllHl7 } from '../../redux/features/hl7/hl7AsyncActions'
import TableV2 from '../../components/common/table/tableV2/TableV2'
import { hl7HeaderData } from './hl7TableData'
import Pagination from '../../components/common/pagination/Pagination'
import Loader from '../../components/common/spinner/Loader'
import { trimValue } from '../../utils/utils'
import { SearchButton } from '../../components/common/svg-components'
import Popup from '../../components/common/popup/Popup'
import ViewMessage from './ViewMessage'
import { t } from 'i18next'

const HL7List = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { isLoading, hl7Data } = useAppSelector((state) => state.hl7)
  const [dataPerPage, setDataPerPage] = useState<number>(10)
  const [totalPage, setTotalPage] = useState<number>(0)
  const [pageIndex, setPageIndex] = useState<number>(1)
  const [totalData, setTotalData] = useState(0)
  const [search, setSearch] = useState('')
  const [viewMessage, setViewMessage] = useState(false)
  const [messageList, setMessageList] = useState([])

  // function for creating Page Index Array
  const pageIndexArray = () => {
    let pageIndexOptions = []
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i)
    }
    return pageIndexOptions
  }
  const pageIndexOptions = pageIndexArray()

  useEffect(() => {
    let data = {
      name: search,
      page: pageIndex,
      pageSize: dataPerPage,
    }
    dispatch(getAllHl7(requestGenerator(data))).then((e) => {
      setTotalPage(e.payload.lastPage)
      setTotalData(e.payload.total)
    })
  }, [pageIndex, dataPerPage])

  const handleSearch = () => {
    setPageIndex(1)
    if (search?.length > 0) {
      let data = {
        name: search,
        page: 1,
        pageSize: dataPerPage,
      }
      dispatch(getAllHl7(requestGenerator(data))).then((result) => {
        setTotalPage(result.payload.lastPage)
        setTotalData(result.payload.total)
      })
    }
  }

  const handleClick = (data: any) => {
    setMessageList(data)
    setViewMessage(true)
  }

  return (
    <>
      {isLoading && <Loader />}
      {viewMessage && (
        <Popup
          Children={ViewMessage}
          popData={messageList}
          handleClose={() => setViewMessage(false)}
        />
      )}
      <div className={styles.ListContainer}>
        <div className={styles.BtnContainer}>
          <div className={styles.inputFieldContainer}>
            <input
              type="text"
              className={styles.inputSearchContainer}
              placeholder={t('Common.Search by name') || 'Search by name'}
              onKeyDown={(e: any) => {
                if (e?.key === 'Enter') {
                  handleSearch()
                }
              }}
              onChange={(e) => {
                trimValue(e)
                setSearch(e.target.value)
                if (search !== '' && e.target.value.length === 0) {
                  setPageIndex(1)
                  setDataPerPage(10)
                  let requestData = {
                    name: '',
                    page: 1,
                    pageSize: 10,
                  }
                  dispatch(getAllHl7(requestGenerator(requestData))).then(
                    (result) => {
                      setTotalPage(result.payload.lastPage)
                      setTotalData(result.payload.total)
                    }
                  )
                }
              }}
              value={search}
            />
            <SearchButton
              handleClick={() => {
                handleSearch()
              }}
              customClass={styles.inputSearchButton}
            />
          </div>
          <Button
            title={t('hl7.Create_HL7') || 'Create HL7'}
            handleClick={() => {
              navigate('/hl7/createhl7')
            }}
          />
        </div>
        <div className={styles.tableContainer}>
          <TableV2
            tableHeaderData={hl7HeaderData}
            tableRowData={hl7Data}
            active={false}
            handleClick={handleClick}
          />
        </div>
        {totalData > 10 && (
          <Pagination
            setDataPerPage={setDataPerPage}
            pageIndexOptions={pageIndexOptions}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
          />
        )}
      </div>
    </>
  )
}

export default HL7List
