// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_quickAcessContainer__o5K2V {\n  background-color: var(--white1);\n  box-shadow: 0px 10px 117px 14px rgba(0, 0, 0, 0.05);\n  border-radius: 10px;\n  padding: 25px;\n  margin: 0 20px 50px 0;\n}\n\n.style_heading__ogHU2 {\n  font-size: 20px;\n  font-weight: 700;\n  margin-bottom: 40px;\n}\n\n.style_inlineItemsContainer__AR44Z {\n  display: flex;\n  justify-content: space-between;\n  gap: 20px;\n  margin-top: 20px;\n}\n.style_inlineItemsContainer__AR44Z li {\n  cursor: pointer;\n}", "",{"version":3,"sources":["webpack://./src/pages/pharmacy/pharmacy-store/quick-access/style.module.scss"],"names":[],"mappings":"AAAA;EACE,+BAAA;EACA,mDAAA;EACA,mBAAA;EACA,aAAA;EACA,qBAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;EACA,SAAA;EACA,gBAAA;AACF;AACE;EACE,eAAA;AACJ","sourcesContent":[".quickAcessContainer {\n  background-color: var(--white1);\n  box-shadow: 0px 10px 117px 14px rgba(0, 0, 0, 0.05);\n  border-radius: 10px;\n  padding: 25px;\n  margin: 0 20px 50px 0;\n}\n\n.heading {\n  font-size: 20px;\n  font-weight: 700;\n  margin-bottom: 40px;\n}\n\n.inlineItemsContainer {\n  display: flex;\n  justify-content: space-between;\n  gap: 20px;\n  margin-top: 20px;\n\n  li {\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"quickAcessContainer": "style_quickAcessContainer__o5K2V",
	"heading": "style_heading__ogHU2",
	"inlineItemsContainer": "style_inlineItemsContainer__AR44Z"
};
export default ___CSS_LOADER_EXPORT___;
