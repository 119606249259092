// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".leadlayout_mainContainer__zhA5T {\n  display: flex;\n}\n.leadlayout_mainContainer__zhA5T .leadlayout_tabContainer__LdTsK {\n  width: 95%;\n}\n.leadlayout_mainContainer__zhA5T .leadlayout_floatingBarContainer__v8MDx {\n  position: sticky;\n  top: 30px;\n}\n\n.leadlayout_floatbar__XPJoX {\n  position: sticky;\n}", "",{"version":3,"sources":["webpack://./src/pages/call-center-admin/lead/leadlayout.module.scss"],"names":[],"mappings":"AACA;EACE,aAAA;AAAF;AACE;EACE,UAAA;AACJ;AACE;EACE,gBAAA;EACA,SAAA;AACJ;;AAEE;EACE,gBAAA;AACJ","sourcesContent":["@import \"../../../styles/mixin.scss\";\n.mainContainer {\n  display: flex;\n  .tabContainer {\n    width: 95%;\n  }\n  .floatingBarContainer {\n    position: sticky;\n    top: 30px;\n  }\n}\n  .floatbar{\n    position:sticky;    \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContainer": "leadlayout_mainContainer__zhA5T",
	"tabContainer": "leadlayout_tabContainer__LdTsK",
	"floatingBarContainer": "leadlayout_floatingBarContainer__v8MDx",
	"floatbar": "leadlayout_floatbar__XPJoX"
};
export default ___CSS_LOADER_EXPORT___;
