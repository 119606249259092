import { FC, useEffect, useState } from 'react'
import Button from '../../../components/common/button/Button'
import styles from './manageCategoryValues.module.scss'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { countryList, masterValuesHeader } from '../../../constants/data'
import { requestGenerator } from '../../../utils/payloadGenerator'
import {
  addAllMasterValue,
  getAllMasterValue,
  updatMasterValue,
  updateStatusValue,
} from '../../../redux/features/master-value/MasterValueAsyncActions'
import { useLocation } from 'react-router-dom'
import {
  MASTER_VALUE_LABEL,
  MASTER_VALUE,
  COUNTRY_CODE,
  ACCOUNT_NO,
  HOLIDAY_DATE,
  COMPLAINT_CODE,
} from '../../../constants/constant'
import { IMaterValues } from '../../../interfaces/interfaces'
import { SubmitHandler, useForm } from 'react-hook-form'
import { trimValue } from '../../../utils/utils'
import Popup from '../../../components/common/popup/Popup'
import ManageAssignTagModal from '../../../components/common/modal/manage-assigntag-modal/ManageAssignTagModal'
import TableV2 from '../../../components/common/table/tableV2/TableV2'
import Loader from '../../../components/common/spinner/Loader'
import { clearState } from '../../../redux/features/master-value/MasterValueSlice'
import Pagination from '../../../components/common/pagination/Pagination'
import { masterValuesValidators } from '../../../form-validators/masterValuesValidators'
import Select from 'react-select'
import { t } from 'i18next'
import SearchDropDown from '../../../components/common/search-dropdown/SearchDropDown'
import { getIcd10Code } from '../../../redux/features/hl7/hl7AsyncActions'

interface IManageAssignTag {}

const ManageCategoryValues: FC<IManageAssignTag> = () => {
  // use state
  const [toggleData, setToggleData] = useState({})
  const [confirm, setConfirm] = useState<boolean>(false)
  const [dataPerPage, setDataPerPage] = useState<number>(10)
  const [totalPage, setTotalPage] = useState<number>(0)
  const [pageIndex, setPageIndex] = useState<number>(1)
  const [searchValue, setSearchValue] = useState('')
  // icd10Search
  const [icdSearchString, setIcdSearchString] = useState('')

  // use location
  const location = useLocation()

  // use dispatch
  const dispatch = useAppDispatch()

  // use selector
  const { isLoading, getAllMasterValueData, getAllMasterValueDataReducer } =
    useAppSelector((state) => state.masterValue)
  const { icd10Data } = useAppSelector((state) => state.hl7)

  // use effect
  useEffect(() => {
    const alldata = {
      category_id: location?.state?.id?._id,
      page: pageIndex,
      pageSize: dataPerPage,
      search: searchValue,
    }
    dispatch(getAllMasterValue(requestGenerator(alldata))).then((result) =>
      setTotalPage(result.payload.lastPage)
    )
    handleReset(false)
  }, [dispatch, dataPerPage, pageIndex])

  // handle reset
  const handleReset = (resetLabel?: boolean) => {
    setValue(MASTER_VALUE, '')
    resetLabel && setValue(MASTER_VALUE_LABEL, '')
    dispatch(clearState())
  }

  // use form
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<IMaterValues>({})

  const onSubmit: SubmitHandler<IMaterValues> = (data) => {
    if (getAllMasterValueDataReducer._id) {
      const masterInit = {
        id: getAllMasterValueDataReducer?._id,
        data: {
          category_id: getAllMasterValueDataReducer?.category_id?._id,
          value: data.name,
          metadata:
            location?.state?.id?.category_name === 'NATIONALITY'
              ? {
                  country_code: data?.country_code?.value,
                }
              : location?.state?.id?.category_name === 'PARAMETER'
              ? {
                  [ACCOUNT_NO]: data[ACCOUNT_NO],
                }
              : location?.state?.id?.category_name === 'HOLIDAYS'
              ? {
                  [HOLIDAY_DATE]: data[HOLIDAY_DATE],
                }
              : location?.state?.id?.category_name ===
                'DIAGNOSIS_MAIN_COMPLAINT'
              ? {
                  code: data[COMPLAINT_CODE] || undefined,
                }
              : undefined,
        },
      }
      dispatch(updatMasterValue(requestGenerator(masterInit))).then((e) => {
        if (e.type === 'masterValueSlice/updatMasterValue/fulfilled') {
          const alldata = {
            category_id: location?.state?.id?._id,
            page: pageIndex,
            pageSize: dataPerPage,
            search: searchValue,
          }
          dispatch(getAllMasterValue(requestGenerator(alldata))).then(
            (result) => setTotalPage(result.payload.lastPage)
          )
          handleReset(false)
        }
      })
    } else {
      const masterInit = {
        category_id: location.state.id._id,
        value: data.name,
        metadata:
          location?.state?.id?.category_name === 'NATIONALITY'
            ? {
                country_code: data?.country_code?.value,
              }
            : location?.state?.id?.category_name === 'PARAMETER'
            ? {
                [ACCOUNT_NO]: data[ACCOUNT_NO],
              }
            : location?.state?.id?.category_name === 'HOLIDAYS'
            ? {
                [HOLIDAY_DATE]: data[HOLIDAY_DATE],
              }
            : location?.state?.id?.category_name === 'DIAGNOSIS_MAIN_COMPLAINT'
            ? {
                code: data[COMPLAINT_CODE] || undefined,
              }
            : undefined,
      }
      dispatch(addAllMasterValue(requestGenerator(masterInit))).then((e) => {
        if (e.type === 'masterValueSlice/addAllMasterValue/fulfilled') {
          const alldata = {
            category_id: location?.state?.id?._id,
            page: pageIndex,
            pageSize: dataPerPage,
            search: searchValue,
          }
          dispatch(getAllMasterValue(requestGenerator(alldata))).then(
            (result) => setTotalPage(result.payload.lastPage)
          )
          handleReset(false)
        } else {
        }
      })
    }
  }

  // convert file object to data_uri
  useEffect(() => {
    if (location?.state?.id?.category_name) {
      setValue(MASTER_VALUE_LABEL, location?.state?.id?.category_name)
    }
  }, [location?.state?.id?.category_name])
  // toggle modal
  const handleDataClick = (item: any) => {
    const toggleData = {
      id: item?._id,
      data: {
        is_active: !item.is_active,
      },
    }
    dispatch(updateStatusValue(requestGenerator(toggleData))).then((e) => {
      if (e.type === 'masterValueSlice/updateStatusValue/fulfilled') {
        const alldata = {
          category_id: location?.state?.id?._id,
          page: pageIndex,
          pageSize: dataPerPage,
          search: searchValue,
        }
        dispatch(getAllMasterValue(requestGenerator(alldata))).then((result) =>
          setTotalPage(result.payload.lastPage)
        )
        handleReset(false)
      } else {
      }
    })
  }

  // update value when click on edit button
  useEffect(() => {
    setValue(MASTER_VALUE, getAllMasterValueDataReducer?.value)
    setValue(COUNTRY_CODE, {
      label: getAllMasterValueDataReducer?.metadata?.country_code,
      value: getAllMasterValueDataReducer?.metadata?.country_code,
    })
    setValue(ACCOUNT_NO, getAllMasterValueDataReducer?.metadata?.[ACCOUNT_NO])
    setValue(
      HOLIDAY_DATE,
      getAllMasterValueDataReducer?.metadata?.[HOLIDAY_DATE]
    )
    setValue(
      COMPLAINT_CODE,
      getAllMasterValueDataReducer?.metadata?.[COMPLAINT_CODE]
    )
  }, [getAllMasterValueDataReducer, setValue])

  // function for creating Page Index Array
  const pageIndexArray = () => {
    let pageIndexOptions = []
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i)
    }
    return pageIndexOptions
  }
  const pageIndexOptions = pageIndexArray()

  const getTableHeaderData = (): any => {
    const categoryName = location?.state?.id?.category_name
    const excludeHeaders: any = {
      NATIONALITY: ['ACCOUNT_NO', 'DATE', 'CODE'],
      PARAMETER: ['COUNTRY_CODE', 'DATE', 'CODE'],
      HOLIDAYS: ['COUNTRY_CODE', 'ACCOUNT_NO', 'CODE'],
      DIAGNOSIS_MAIN_COMPLAINT: ['COUNTRY_CODE', 'ACCOUNT_NO', 'DATE'],
    }

    const defaultExclude = ['COUNTRY_CODE', 'ACCOUNT_NO', 'DATE', 'CODE']
    const headersToExclude: any = excludeHeaders[categoryName] || defaultExclude

    return masterValuesHeader?.filter(
      (item: any) =>
        !headersToExclude.some(
          (header: any) =>
            item?.Header === t(`ManageCategory.${header}`) ||
            item?.Header === t(`RcptTableHeader.${header}`) ||
            item?.Header === t(`CallCenterAdmin.${header}`)
        )
    )
  }

  // icd10Data

  useEffect(() => {
    dispatch(
      getIcd10Code(
        requestGenerator({
          page: 1,
          pageSize: 10,
          search: icdSearchString,
        })
      )
    )
  }, [dispatch, icdSearchString])

  // clear state while we can move on another page
  useEffect(() => {
    return () => {
      dispatch(clearState())
    }
  }, [dispatch])

  return (
    <>
      {isLoading && <Loader />}
      {confirm && (
        <Popup
          popData={toggleData}
          Children={ManageAssignTagModal}
          handleClose={() => setConfirm(false)}
        />
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.mainContainer}>
          <div className={styles.appointmentStatusInputMain}>
            <div className={styles.appointmanetInput}>
              <label
                htmlFor=""
                className={styles.appointmentLable}
                style={{ marginTop: '10px' }}
              >
                {t('ManageCategory.Category')}
                <span className="asterick">*</span>
              </label>
              <div className={styles.fieldErrorContainer}>
                <input
                  type="text"
                  className={styles.inputSearchContainer}
                  {...register(
                    MASTER_VALUE_LABEL,
                    masterValuesValidators[MASTER_VALUE_LABEL]
                  )}
                  placeholder="Enter Category"
                  disabled
                  onChange={(e) => trimValue(e)}
                />
                <div className={styles.errorContainer}>
                  <span className={styles.extraSpan}></span>
                  {errors[MASTER_VALUE_LABEL] && (
                    <p className="dashboardFormError">
                      {errors[MASTER_VALUE_LABEL].message as any}
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className={styles.appointmanetInput}>
              <label
                htmlFor=""
                className={styles.appointmentLable}
                style={{ marginLeft: '20px', marginTop: '10px' }}
              >
                {t('ManageCategory.Value')}
                <span className="asterick">*</span>
              </label>
              <div className={styles.fieldErrorContainer}>
                <input
                  type="text"
                  className={styles.inputSearchContainer}
                  {...register(
                    MASTER_VALUE,
                    masterValuesValidators[MASTER_VALUE]
                  )}
                  placeholder={
                    t('ManageCategory.Value_placeholder') || 'Enter Value'
                  }
                  onChange={(e) => {
                    trimValue(e)
                    if (
                      location?.state?.id?.category_name ===
                        'DIAGNOSIS_MAIN_COMPLAINT' &&
                      watch(COMPLAINT_CODE)
                    ) {
                      setValue(COMPLAINT_CODE, '')
                    }
                  }}
                />
                <div className={styles.errorContainer}>
                  <span className={styles.extraSpan}></span>
                  {errors[MASTER_VALUE] && (
                    <p className="dashboardFormError">
                      {errors[MASTER_VALUE].message as any}
                    </p>
                  )}
                </div>
              </div>
            </div>
            {location?.state?.id?.category_name === 'PARAMETER' && (
              <div className={styles.appointmanetInput}>
                <label
                  htmlFor=""
                  className={styles.appointmentLable}
                  style={{ marginLeft: '20px', marginTop: '10px' }}
                >
                  {t('ManageCategory.Account_No')}
                  <span className="asterick">*</span>
                </label>
                <div className={styles.fieldErrorContainer}>
                  <input
                    type="text"
                    className={styles.inputSearchContainer}
                    {...register(
                      ACCOUNT_NO,
                      masterValuesValidators[ACCOUNT_NO]
                    )}
                    placeholder={
                      t('ManageCategory.Value_placeholder') || 'Enter Value'
                    }
                    onChange={(e) => trimValue(e)}
                  />
                  <div className={styles.errorContainer}>
                    <span className={styles.extraSpan}></span>
                    {errors[ACCOUNT_NO] && (
                      <p className="dashboardFormError">
                        {errors[ACCOUNT_NO].message as any}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            )}
            {location?.state?.id?.category_name === 'HOLIDAYS' && (
              <div className={styles.appointmanetInput}>
                <label
                  htmlFor=""
                  className={styles.appointmentLable}
                  style={{ marginLeft: '20px', marginTop: '10px' }}
                >
                  {t('RecentHistory.Date')}
                  <span className="asterick">*</span>
                </label>
                <div className={styles.fieldErrorContainer}>
                  <input
                    type="date"
                    className={styles.inputSearchContainer}
                    {...register(
                      HOLIDAY_DATE,
                      masterValuesValidators[HOLIDAY_DATE]
                    )}
                    placeholder={
                      t('ManageCategory.Value_placeholder') || 'Enter Value'
                    }
                    onChange={(e) => trimValue(e)}
                  />
                  <div className={styles.errorContainer}>
                    <span className={styles.extraSpan}></span>
                    {errors[HOLIDAY_DATE] && (
                      <p className="dashboardFormError">
                        {errors[HOLIDAY_DATE].message as any}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            )}

            {location?.state?.id?.category_name === 'NATIONALITY' && (
              <div className={styles.appointmanetInput}>
                <label
                  htmlFor=""
                  className={styles.appointmentLable}
                  style={{ marginLeft: '20px', marginTop: '10px' }}
                >
                  {t('ManageCategory.Country_Code')}
                  <span className="asterick">*</span>
                </label>
                <div className={styles.fieldErrorContainer}>
                  <Select
                    // className={styles.selectInputField}
                    // isDisabled={props.row.original?.type === "job_item"}
                    closeMenuOnSelect={true}
                    // components={{ DropdownIndicator }}
                    isSearchable={true}
                    value={watch(COUNTRY_CODE) || {}}
                    // defaultValue={
                    //   {
                    //     label: countryList[0],
                    //     value: countryList[0],
                    //   }
                    // }
                    {...register(
                      COUNTRY_CODE,
                      masterValuesValidators[COUNTRY_CODE]
                    )}
                    options={countryList?.map((item: any) => {
                      return {
                        label: item,
                        value: item,
                      }
                    })}
                    onChange={(e: any) => setValue(COUNTRY_CODE, e)}
                    maxMenuHeight={120}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        width: '220px',
                        minHeight: '42px',
                        cursor: 'pointer',
                        borderRadius: '6px',
                        marginLeft: '12px',
                      }),
                    }}
                  />
                  <div className={styles.errorContainer}>
                    <span className={styles.extraSpan}></span>
                    {errors[COUNTRY_CODE] && (
                      <p className="dashboardFormError">
                        {errors[COUNTRY_CODE].message as any}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            )}

            {location?.state?.id?.category_name ===
              'DIAGNOSIS_MAIN_COMPLAINT' && (
              <>
                <div className={styles.appointmanetInput}>
                  <label
                    htmlFor=""
                    className={styles.appointmentLable}
                    style={{ marginLeft: '20px', marginTop: '10px' }}
                  >
                    {t('ViewChartOfAccount.Code')}
                  </label>
                  <div className={styles.fieldErrorContainer}>
                    <input
                      type="text"
                      className={styles.inputSearchContainer}
                      {...register(COMPLAINT_CODE)}
                      disabled
                      onChange={(e) => trimValue(e)}
                    />
                  </div>
                </div>
                <SearchDropDown
                  searchString={icdSearchString}
                  setSearchString={setIcdSearchString}
                  dropdownDataToSee={icd10Data}
                  dropDownKeyName="name"
                  placeholder={t('hl7.Searhicd') || 'Search ICD10'}
                  customClass={styles.searchDropdown}
                  customClassdata={styles.dropdownList}
                  customClassItem={styles.dropdownItem}
                  inputCustomClass={styles.dropdownItem}
                  // setState={() => setSelectedIcd({})}
                  handleClick={(
                    item: any,
                    setVal: any,
                    setShowDropdown: any
                  ) => {
                    setShowDropdown(false)
                    setValue(MASTER_VALUE, item?.name)
                    setValue(COMPLAINT_CODE, item?.code)
                    setVal('')
                  }}
                />
              </>
            )}
          </div>
          <div className={styles.buttonMainColor}>
            <Button
              title={
                getAllMasterValueDataReducer?._id
                  ? t('ManageCategory.Update') || 'Update'
                  : t('ActionBar.Add') || 'Add'
              }
              type="submit"
              customClass={styles.addBUtton}
            />
          </div>
          <div className={styles.tableContainer}>
            <div className={styles.tableContainer}>
              {getAllMasterValueData?.length > 0 ? (
                <TableV2
                  tableHeaderData={getTableHeaderData()}
                  tableRowData={getAllMasterValueData}
                  handleClick={handleDataClick}
                />
              ) : (
                ''
              )}
            </div>
          </div>
          {getAllMasterValueData && getAllMasterValueData.length !== 0 ? (
            <Pagination
              setDataPerPage={setDataPerPage}
              pageIndexOptions={pageIndexOptions}
              pageIndex={pageIndex}
              setPageIndex={setPageIndex}
            />
          ) : (
            ''
          )}
        </div>
      </form>
    </>
  )
}

export default ManageCategoryValues
