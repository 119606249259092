import { FC, useState } from "react";
import { colors } from "../../../../constants/color";
import Divider from "../../divider/Divider";
import { LogoutIcon } from "../../svg-components";
import styles from "./logoutModal.module.scss";
import { useAppDispatch, useAppSelector } from "../../../../hooks/index";
import { useNavigate } from "react-router";
import { requestGenerator } from "../../../../utils/payloadGenerator";
import { userLogout } from "../../../../redux/features/login/loginAsynActions";
import { clearPatientBranch } from "../../../../redux/features/patient-emr/patient/patientSlice";
import { socket } from "../../../../socket";
import SelectImage from "../../../../assets/images/Default Image.png";
import { USER_LOGOUT_TYPE } from "../../../../constants/asyncActionsType";
import { useTranslation } from "react-i18next";
import useLanguage from "../../../../hooks/useLanguage";

interface ILogoutModal {
  logoutRef: any;
}

const LogoutModal: FC<ILogoutModal> = ({ logoutRef }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { language } = useLanguage();
  const { userData, branchData } = useAppSelector((state) => state.login);
  console.log("branchData", branchData);
  const [socketJoined, setSocketJoined] = useState(false);
  // const { setSideBar, setRouteData } = usePermissions()
  const handleLogout = () => {
    // setRouteData([])
    // setSideBar([])
    dispatch({ type: "RESET_STATE" });
    navigate("/");
    dispatch(clearPatientBranch());
    if (socket && socket.connected) {
      // socket.on("userOffline");
      socket.disconnect();
      setSocketJoined(false);

      // Disconnect the socket if it's connected
    } else {
      console.log("Socket disconnec issue");
    }
    dispatch(userLogout(requestGenerator({}))).then((e) => {
      if (e.type === `${USER_LOGOUT_TYPE}/fulfilled`) {
        // i18n.changeLanguage('en')
        localStorage.setItem("i18nextLng", language);
        // window.location.reload()
      }
    });
  };

  const userDataArray = userData?.role ? userData?.role?.split("_") : [];
  const userDataReplace = userDataArray?.join(" ");
  const userDataFormate =
    userDataReplace?.charAt(0)?.toUpperCase() +
    userDataReplace?.slice(1)?.toLowerCase();

  return (
    <>
      <div className={styles.mainContainer} ref={logoutRef}>
        <div className={styles.profileContainer}>
          <div className={styles.profileImageContainer}>
            <img
              className={styles.profileImage}
              src={
                branchData?.profile_pic ? branchData?.profile_pic : SelectImage
              }
              alt=""
            />
          </div>
          <div className={styles.profileInfo}>
            <p className={styles.userNameStyle}>{userData?.name}</p>
            <p className={styles.userRoleStyle}>
              {/* {userData?.role && roleData[userData.role]?.name} */}
              {/* {userData?.role ? userData?.role.replace("_", " ") : '-'} */}
              {/* Your words matter, and our paraphrasing  */}
              {userDataFormate ? userDataFormate : ""}
            </p>
            <div className={styles.branchContainer}>
              <p className={styles.mcName}>{userData?.mc_name}</p>
            </div>
          </div>
        </div>
        <Divider customClass={styles.dividerStyle} />

        <div>
          <span className={styles.logoutContainer}>
            <LogoutIcon
              fillColor={colors.black1}
              handleClick={() => handleLogout()}
            />
            <p className={styles.logoutTextStyle} onClick={handleLogout}>
              {t("Common.Sign Out")}
            </p>
          </span>
        </div>
      </div>
    </>
  );
};

export default LogoutModal;
