import { t } from 'i18next'

export const labTestNameModalHeaderData: any = [
  {
    Header: t('LabRequest.TEST_NO'),
    accessor: 'test_no',
    Cell: (props: any) => {
      const sixDigitString = props?.row?.original?.test_no
        .toString()
        .padStart(6, '0')
      return <>{sixDigitString ? <span>{sixDigitString}</span> : '-'}</>
    },
    disableSortBy: true,
  },
  {
    Header: t('CallCenterAdmin.CODE'),
    accessor: 'code',
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.code ? <>{props?.row?.original?.code}</> : '-'}
        </>
      )
    },
    disableSortBy: true,
  },
  {
    Header: t('ViewReports.TEST NAME'),
    accessor: 'test_name',
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.test_name ? (
            <span>{props?.row?.original?.test_name?.toUpperCase()}</span>
          ) : (
            '-'
          )}
        </>
      )
    },
    disableSortBy: true,
  },
]
