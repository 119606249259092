import {
  CREATE_HL7,
  GET_ALL_HL7,
  GET_ICD10_TYPE,
  GET_LAB_IONIC_TYPE,
  GET_RADIO_IONIC_TYPE,
  UPDATE_HL7,
  UPDATE_HL7_STATUS,
} from '../../../constants/asyncActionsType'
import createAsyncThunkForSlice from '../../../utils/utils'
import {
  createHL7,
  getAllHL7,
  geticd10,
  getLabIonic,
  getRadiologyIonic,
  udateHL7,
  updateStatusHL7,
} from './hl7Crud'

export const createHl7 = createAsyncThunkForSlice(CREATE_HL7, createHL7, {
  isToast: true,
})

export const updateHl7 = createAsyncThunkForSlice(UPDATE_HL7, udateHL7, {
  isToast: true,
})

export const getAllHl7 = createAsyncThunkForSlice(GET_ALL_HL7, getAllHL7)

export const updateStatusHl7 = createAsyncThunkForSlice(
  UPDATE_HL7_STATUS,
  updateStatusHL7,
  {
    isToast: true,
  }
)

//icd10
export const getIcd10Code = createAsyncThunkForSlice(GET_ICD10_TYPE, geticd10)

//ionic code
export const getLabIonicData = createAsyncThunkForSlice(
  GET_LAB_IONIC_TYPE,
  getLabIonic
)
export const getRadioIonicData = createAsyncThunkForSlice(
  GET_RADIO_IONIC_TYPE,
  getRadiologyIonic
)
