import {
  ADD_ORDER_TYPE,
  ADJUSTMENT_FORM_KEY,
  DELETE_PHARMACY_ON_HOLD_DATA,
  EDIT_ORDER_TYPE,
  GET_ALL_PHARMACY_ON_HOLD_DATA,
  GET_OUTPATIENT_DETAIL_TYPE,
  GET_PHARMACY_ORDER_TYPE,
  PHARMACY_SUMMARY_WIDGET,
} from '../../../constants/asyncActionsType'
import createAsyncThunkForSlice from '../../../utils/utils'
import {
  addOrder,
  adjustmentFormApi,
  deletePharmacyOnholdsData,
  editOrder,
  getAllPharmacyOnholdsData,
  getOutPatientDetail,
  pharmacySummary,
} from './pharmacyCrud'

export const getAllPharmacyOnholdData = createAsyncThunkForSlice(
  GET_ALL_PHARMACY_ON_HOLD_DATA,
  getAllPharmacyOnholdsData
)

export const deletePharmacyOnholdData = createAsyncThunkForSlice(
  DELETE_PHARMACY_ON_HOLD_DATA,
  deletePharmacyOnholdsData,
  {
    isToast: true,
  }
)

export const adjustmentFormUpdateApi = createAsyncThunkForSlice(
  ADJUSTMENT_FORM_KEY,
  adjustmentFormApi,
  {
    isToast: true,
  }
)

export const addPharmacyOrder = createAsyncThunkForSlice(
  ADD_ORDER_TYPE,
  addOrder,
  {
    isToast: true,
  }
)
export const editPharmacyOrder = createAsyncThunkForSlice(
  EDIT_ORDER_TYPE,
  editOrder,
  {
    isToast: true,
  }
)

export const getPharmacyOutPatientDetail = createAsyncThunkForSlice(
  GET_OUTPATIENT_DETAIL_TYPE,
  getOutPatientDetail
)

// Pharmacy Case Summary Widget
export const PharmacyCaseSummaryWidget = createAsyncThunkForSlice(
  PHARMACY_SUMMARY_WIDGET,
  pharmacySummary
)


