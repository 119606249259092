import React, { useState } from "react";
import {
  ChatsIcon,
  CloseIcon,
  FloatCloseIcon,
  FlotingIcon,
  TodoIcon,
} from "../common/svg-components";
import styles from "./floting.module.scss";
import Chat from "../chatbot/Chatv1";
import { CustomModal } from "../common/custom-modal/modal";
import DoctorTodo from "../../pages/doctor-dashboard/doctor-todo/DoctorTodo";
import { useTranslation } from "react-i18next";
import { motion, AnimatePresence } from "framer-motion";
import Button from "../common/button/Button";

const Floting = () => {
  const [flotOpen, setFlotOpen] = useState(false);
  const [showIcon, setShowIcon] = useState(true);
  const [showChat, setShowChat] = useState(false);
  const [showTodo, setShowTodo] = useState(false);

  const { t } = useTranslation();

  const handleChat = () => {
    setShowIcon(false);
    setShowChat(true);
  };

  const handleClose = () => {
    setFlotOpen(!flotOpen);
    if (showChat) {
      setShowChat(false);
      setShowIcon(true);
    }
    if (showTodo) {
      setShowIcon(true);
      setShowTodo(false);
    }
  };

  const handleTodo = () => {
    setShowIcon(false);
    setShowTodo(true);
  };
  return (
    <div className={styles.mainContainer}>
      {flotOpen ? (
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 1, y: 5, rotateX: 10 }}
            animate={{ opacity: 1, y: 0, rotateX: 0 }}
            exit={{ opacity: 1, y: 0, rotateX: 0 }}
            transition={{
              duration: 1,
              type: "damping",
              stiffness: 20,
              damping: 40,
            }}
          >
            <div
              className={
                showIcon ? styles.containetList : styles.closeIconContainer
              }
            >
              {showIcon && (
                <>
                  <div className={styles.todoContainer}>
                    <p className={styles.todoTooltip}>Todo</p>
                    <TodoIcon handleClick={() => handleTodo()} />
                  </div>
                </>
              )}
              {showIcon && (
                <div className={styles.chatContainer}>
                  <p className={styles.chatTooltip}>Chat</p>
                  <ChatsIcon handleClick={() => handleChat()} />
                </div>
              )}
              <FloatCloseIcon handleClick={() => handleClose()} />
            </div>
          </motion.div>
        </AnimatePresence>
      ) : (
        <FlotingIcon handleClick={() => setFlotOpen(!flotOpen)} />
      )}

      {showChat && <Chat />}

      {showTodo && (
        <CustomModal
          showModal={showTodo}
          closeModal={() => {
            setShowTodo(false);
            setShowIcon(true);
            setFlotOpen(!flotOpen);
          }}
          title={t("Receptionist Dashboard.To-Do List") || "To-Do List"}
          width="500px"
        >
          <DoctorTodo />
        </CustomModal>
      )}
    </div>
  );
};

export default Floting;
