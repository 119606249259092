import {
  GET_ALL_MASTER_IMPORT,
  UPDATE_ALL_MASTER_IMPORT,
} from '../../../constants/asyncActionsType'
import createAsyncThunkForSlice from '../../../utils/utils'
import {
  updateAllMasterImport,
  getAllMasterImport,
} from './masterImportCrud'

// GET ALL MASTER IMPORT
export const getAllMasterImportAction = createAsyncThunkForSlice(
  GET_ALL_MASTER_IMPORT,
  getAllMasterImport,
  {
    isToast: true,
  }
)

// UPDATE ALL MASTER IMPORT
export const updateAllMasterImportAction = createAsyncThunkForSlice(
  UPDATE_ALL_MASTER_IMPORT,
  updateAllMasterImport,
  {
    isToast: true,
  }
)


