import axios from "axios";
import { IAPIPayload } from "../../../interfaces/apiInterface";
import {
  CHECK_WHATSUP_CONFIG,
  GET_WHATSUP_DETAILS,
  UPDATE_WHATSUP_CONFIG,
} from "../../../config/config";

export const checkWhatsUpConfigurations = (data: IAPIPayload) => {
  return axios.post(CHECK_WHATSUP_CONFIG, data);
};

export const updateWhatsUpConfigurations = (data: IAPIPayload) => {
  return axios.post(UPDATE_WHATSUP_CONFIG, data);
};

export const getWhatsUpConfigurations = (data: IAPIPayload) => {
  return axios.post(GET_WHATSUP_DETAILS, data);
};
