import axios from "axios";
import {
  CREATE_TAX,
  GET_ALL_TAX,
  GET_TAX,
  UPDATE_TAX,
} from "../../../config/config";
import { IAPIPayload } from "../../../interfaces/apiInterface";


export const createTax = (data: IAPIPayload) => {
  return axios.post(CREATE_TAX, data);
};

export const getAllTax = (data: IAPIPayload) => {
  return axios.post(GET_ALL_TAX,data);
};

export const getTax = (id: any) => {
  return axios.get(`${GET_TAX}`);
};

export const updateTax = (data: IAPIPayload) => {
  return axios.put(`${UPDATE_TAX}`, data);
};
