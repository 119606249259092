import axios from "axios";
import {
  CREATE_COMPLIANCE,
  GET_ALL_COMPLIANCE,
  UPDATE_COMPLIANCE,
} from "../../../config/config";
import { IAPIPayload } from "../../../interfaces/apiInterface";


export const createCompliance = (data: IAPIPayload) => {
  return axios.post(CREATE_COMPLIANCE, data);
};

export const getAllCompliance = (id: any) => {
  return axios.post(`${GET_ALL_COMPLIANCE}`);
};

export const updateCompliance = (data: IAPIPayload) => {
  return axios.post(`${UPDATE_COMPLIANCE}`, data);
};
