import { FC, useState, useEffect, useCallback, useRef } from "react";
import styles from "./pharmacyInfoForm.module.scss";
import Button from "../../../components/common/button/Button";
import { useForm, SubmitHandler } from "react-hook-form";
import {
  IaddPharmacyCustomerInformation,
  IaddPharmacyCustomerSecondInformation,
} from "../../../interfaces/interfaces";
import {
  DropDownArrowIcon,
  DropDownIcon,
  NotificationNewIcon,
  SearchIcon,
} from "../../../components/common/svg-components";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import Divider from "../../../components/common/divider/Divider";
import { Label } from "../../../components/common/label";
import { colors } from "../../../constants/color";
import PhoneInput from "react-phone-input-2";
import Popup from "../../../components/common/popup/Popup";
import InfoTooltip from "../../../components/common/info-tooltip/InfoTooltip";
import DoubleInfoBookingInfo from "../../../components/common/color-scheme/double-booking-info/DoubleBookingInfo";
import DropdownV2 from "../../../components/common/dropdown/dropdownv2/DropdownV2";
import {
  reactSelectStyle,
  referalTypeData,
  warning,
} from "../../../constants/data";
import MedicineCompositionPopup from "../../../components/common/modal/medicine-composition-popup/MedicineCompositionPopup";
import TableV2 from "../../../components/common/table/tableV2/TableV2";
import { medicinesTableHeaderData } from "../../../constants/table-data/pharmacycustomerinformationData";
import Select from "react-select";

import {
  PHARMACY_PATIENT_NAME,
  PHARMACY_FILE_NO,
  PHARMACY_MOBILE,
  PHARMACY_AGE,
  PHARMACY_NATIONAL_ID_NO,
  PHARMACY_DATE,
  PHARMACY_REFERRAL,
  PHARMACY_GENDER,
  PHARMACY_ITEM_CODE,
  PHARMACY_PRODUCTS,
  PHARMACY_SELL_PRICE,
  PHARMACY_QTY,
  PHARMACY_DISCOUNT,
  PHARMACY_EXPIRY_DATE,
  PATIENT_TYPE,
  PHARMACY_FIXED_FILE_NO,
  DISCOUNT,
  PHARMACY_AVAILABLE_QTY,
  PHARMACY_BRANCH,
} from "../../../constants/constant";
import {
  allowedNumberOfDigitsAfterDecimal,
  disableArrowKey,
  trimValue,
} from "../../../utils/utils";
import { addPharmacyCustomerInformation } from "../../../form-validators/addPharmacyCustomerInformationValidators";
import { addPharmacyCustomerSecondInformation } from "../../../form-validators/addPharmacyCustomerInformationSecondValidators";
import { useLocation, useNavigate } from "react-router-dom";
import SearchModal from "../../../components/common/modal/search-modal/SearchModal";
import moment from "moment";
import { getAllInventoryItem } from "../../../redux/features/inventory-request/inventoryRequestAsyncActions";
import { requestGenerator } from "../../../utils/payloadGenerator";
import { debounce, floor } from "lodash";
import DropdownIndicator from "../../../components/common/dropdown-indicator copy/DropdownIndicator";
import { setMessage } from "../../../redux/features/toast/toastSlice";
import {
  addItemOrderData,
  clearOrderData,
  setItemOrderData,
} from "../../../redux/features/pharmacy/pharmacySlice";
import {
  addPharmacyOrder,
  editPharmacyOrder,
  getPharmacyOutPatientDetail,
} from "../../../redux/features/pharmacy/pharmacyAsyncActions";
import {
  ADD_ORDER_TYPE,
  EDIT_ORDER_TYPE,
  GET_OUTPATIENT_DETAIL_TYPE,
  GET_PATIENT_EMR_BY_ID,
  GET_PATIENT_MEDICINE,
} from "../../../constants/asyncActionsType";
import Loader from "../../../components/common/spinner/Loader";
import { clearPatientData } from "../../../redux/features/patient-emr/patient/patientSlice";
import { CustomModal } from "../../../components/common/custom-modal/modal";
import ViewInventory from "../../mainstore/view-inventory/page";
import PharmacyMedication from "../../../components/common/modal/pharmacy-medication/PharmacyMedication";
import MedicationViewPopup from "../../../components/common/modal/medication-view-popup/MedicationViewPopup";
import { getAllNotificationList } from "../../../redux/features/app-notifications/appNotificationAsyncActions";
import PharmacyNotificationModal from "../../../components/common/modal/notification-modal/pharmact-notification/PharmactNotification";
import { getPatientEmrById } from "../../../redux/features/patient-emr/patient/patientAsyncAction";
import { getPatientMedicine } from "../../../redux/features/diagnosis/diagnosisAsyncActions";
import { t } from "i18next";
import { getAllBranch } from "../../../redux/features/branch/branchAsyncActions";
import SettledInvoiceModal from "../../../components/common/modal/settled-invoice-modal/SettledInvoiceModal";

interface PharmacyInfoForm {}

const PharmacyInfoForm: FC<PharmacyInfoForm> = () => {
  const navigate = useNavigate();
  const { patientDataObjectById, emrLoader } = useAppSelector(
    (state) => state.patient
  );
  const { branchData } = useAppSelector((state) => state.login);
  const { branchData: allBranches } = useAppSelector((state) => state.branch);
  const { itemOrderData, isFormLoading } = useAppSelector(
    (state) => state.pharmacy
  );
  const { inventoryItemData } = useAppSelector(
    (state) => state.inventoryRequest
  );
  const [pharmacyType, setPharmacyType] = useState("COMPLETED");
  const {
    handleSubmit: handleSubmitfirstForm,
    register: register1,
    getValues: getValues1,
    watch: watch1,
    reset: reset1,
    setValue: setValue1,
    trigger,
    formState: { errors: errors1 },
  } = useForm<IaddPharmacyCustomerInformation>({
    defaultValues: {
      [PATIENT_TYPE]: "regular",
      [PHARMACY_DATE]: moment(new Date()).format("YYYY-MM-DD"),
    },
  });

  const {
    handleSubmit: handleSubmitSecondForm,
    register: register2,
    getValues: getValues2,
    watch: watch2,
    reset: reset2,
    setValue: setValue2,
    formState: { errors: errors2 },
  } = useForm<IaddPharmacyCustomerSecondInformation>({
    defaultValues: {
      [PHARMACY_AVAILABLE_QTY]: "0",
    },
  });
  const dispatch = useAppDispatch();

  const selectedProduct = watch2(PHARMACY_PRODUCTS);
  const pharmacyQty = watch2(PHARMACY_QTY);
  const { userData } = useAppSelector((state) => state.login);
  const { notificationListData } = useAppSelector(
    (state) => state.notifications
  );
  const [selectedItem, setSelectedItem] = useState(referalTypeData[0]);
  const [infoForm, setInforForm] = useState(true);
  const [medicineComposition, setMedicineComposition] =
    useState<boolean>(false);
  const [showSearchModal, setShowSearchModal] = useState<boolean>(false);
  const [inventoryModal, setInventoryModal] = useState<boolean>(false);
  const [medicationModal, setMedicationModal] = useState<boolean>(false);
  const [medicationViewData, setMedicationViewData] = useState(false);
  const [medicationData, setMedicationData] = useState<any>([]);
  const [notificationModalOpen, setNotificationModalOpen] =
    useState<boolean>(false);
  const [selectedNotification, setSelectedNotification] = useState<any>({});
  const [selectedPrescription, setSelectedPrescription] = useState<any>({});
  const [showSettleInvoiceModal, setShowSettleInvoiceModal] =
  useState<boolean>(false);
  const notificationRef = useRef<any>();

  const location = useLocation();
  const handlefirstFormSubmit: SubmitHandler<
    IaddPharmacyCustomerInformation
  > = (data) => {
    const order = location?.state?.order;
    const calculation = (array: any[]) => {
      const total_price = array?.reduce(
        (acc, curr) => acc + curr?.price * curr?.qty,
        0
      );
      const total_discount = array?.reduce(
        (acc, curr) => acc + curr?.discountedAmount,
        0
      );
      const total_amount = array?.reduce(
        (acc, curr) => acc + curr?.total_amount,
        0
      );
      return { total_price, total_amount, total_discount };
    };
    if (itemOrderData && itemOrderData?.length > 0) {
      const { total_price, total_amount, total_discount } =
        calculation(itemOrderData);
      const payload = {
        ...data,
        age: data[PHARMACY_AGE] ? Number(data[PHARMACY_AGE]) : 0,
        // branch_id: null,
        branch_id: data?.branch?.value,
        pharmacy_id: branchData?.pharmacy_store[0]?._id,
        status: pharmacyType,
        referal_type: selectedItem?.name,
        finacial_remark: 1,
        total_price: total_price,
        total_discount: total_discount,
        total_amount: total_amount,
        items: itemOrderData,
        patient_id: patientDataObjectById?._id
          ? patientDataObjectById?._id
          : order?.patient_id
          ? order?.patient_id
          : undefined,
        diagnosis_id: selectedNotification?.metadata?.diagnosis_id
          ? selectedNotification?.metadata?.diagnosis_id
          : undefined,
      };
      if (order?._id) {
        const editPayload = {
          _id: order?._id,
          ...payload,
        };
        dispatch(editPharmacyOrder(requestGenerator(editPayload))).then((e) => {
          if (e.type === `${EDIT_ORDER_TYPE}/fulfilled`) {
            dispatch(clearOrderData());
            dispatch(clearPatientData());
            reset1();
            reset2();
            if (pharmacyType === "COMPLETED") {
              navigate("/pharmacy/pharmacy-payment", {
                state: { order: e?.payload },
              });
            } else {
              navigate("/pharmacy/pharmacy-onhold", {
                state: { order: e?.payload },
              });
            }
          }
        });
      } else {
        dispatch(addPharmacyOrder(requestGenerator(payload))).then((e) => {
          if (e.type === `${ADD_ORDER_TYPE}/fulfilled`) {
            dispatch(clearOrderData());
            dispatch(clearPatientData());
            reset1();
            reset2();
            if (pharmacyType === "COMPLETED") {
              navigate("/pharmacy/pharmacy-payment", {
                state: { order: e?.payload },
              });
            } else {
              navigate("/pharmacy/pharmacy-onhold", {
                state: { order: e?.payload },
              });
            }
          }
        });
      }
    } else {
      dispatch(
        setMessage({ message: t("POS.Item_Validation"), type: warning })
      );
    }
  };

  const handleSecondFormSubmit: SubmitHandler<
    IaddPharmacyCustomerSecondInformation
  > = (data) => {
    const amount =
      Number(data[PHARMACY_QTY]) * Number(data[PHARMACY_SELL_PRICE]);
    const discountedAmount = (amount * Number(data[DISCOUNT])) / 100;
    const totalAmount = amount - discountedAmount;
    const obj = {
      item_id: data[PHARMACY_ITEM_CODE]?._id,
      name: data[PHARMACY_ITEM_CODE]?.name,
      qty: Number(data[PHARMACY_QTY]),
      price: Number(data[PHARMACY_SELL_PRICE]),
      discount: Number(data[PHARMACY_DISCOUNT]),
      discountedAmount: discountedAmount,
      expiry_date: data[PHARMACY_EXPIRY_DATE],
      total_amount: Number(allowedNumberOfDigitsAfterDecimal(totalAmount, 3)),
      item_code: data[PHARMACY_ITEM_CODE]?.value,
    };
    if (itemOrderData?.some((item: any) => item?.item_id === obj?.item_id)) {
      dispatch(setMessage({ message: t("POS.Item_Exist"), type: warning }));
    } else {
      dispatch(addItemOrderData(obj));
      reset2();
      setValue2(PHARMACY_PRODUCTS, null);
      setValue2(PHARMACY_ITEM_CODE, null);
    }
  };
  const formData = watch1();
  // search modal close
  const handleSearchModalClose = () => {
    setShowSearchModal(!showSearchModal);
  };

  const checkDisable = () => {
    let disable: boolean = false;
    if (formData[PATIENT_TYPE] === "regular") {
      disable = true;
    }
    return disable;
  };

  const checkRequired = () => {
    let required: boolean = true;
    if (formData[PATIENT_TYPE] === "out-patient") {
      required = false;
    }
    return required;
  };

  useEffect(() => {
    if (patientDataObjectById?._id) {
      let patientDob = patientDataObjectById?.dob;
      // const formatData = patientAge ? utcToDate(patientAge, true) : ''
      const yearsSinceDob = moment().diff(patientDob, "years", true);
      const patientBranch = allBranches?.find((item: any) => {
        return item?._id === patientDataObjectById?.patient_default_branch_id;
      });
      const patientInfo: any = {
        [PATIENT_TYPE]: "regular",
        [PHARMACY_PATIENT_NAME]: patientDataObjectById?.name,
        [PHARMACY_FILE_NO]: patientDataObjectById?.emr_no,
        [PHARMACY_AGE]: floor(Number(yearsSinceDob)),
        [PHARMACY_NATIONAL_ID_NO]: patientDataObjectById?.national_id,
        [PHARMACY_GENDER]: patientDataObjectById?.gender,
        [PHARMACY_MOBILE]: patientDataObjectById?.phone,
        [PHARMACY_DATE]: moment(new Date()).format("YYYY-MM-DD"),
        [PHARMACY_BRANCH]: patientBranch?._id
          ? {
              label: patientBranch?.name,
              value: patientBranch?._id,
            }
          : null,
      };
      reset1(patientInfo);
    }
  }, [patientDataObjectById]);

  useEffect(() => {
    let patientInfo: any = {
      [PATIENT_TYPE]: "regular",
      [PHARMACY_PATIENT_NAME]: "",
      [PHARMACY_FILE_NO]: "",
      [PHARMACY_FIXED_FILE_NO]: "",
      [PHARMACY_AGE]: "",
      [PHARMACY_NATIONAL_ID_NO]: "",
      [PHARMACY_GENDER]: "",
      [PHARMACY_MOBILE]: "",
      [PHARMACY_DATE]: moment(new Date()).format("YYYY-MM-DD"),
      [PHARMACY_BRANCH]: "",
    };
    if (formData[PATIENT_TYPE] === "regular") {
      reset1(patientInfo);
    } else {
      dispatch(clearPatientData());

      setSelectedNotification({});

      if (!location.state?.order?._id) {
        dispatch(setItemOrderData([]));
      }

      if (!location.state?.order?._id) {
        dispatch(getPharmacyOutPatientDetail(requestGenerator({}))).then(
          (e) => {
            if (e.type === `${GET_OUTPATIENT_DETAIL_TYPE}/fulfilled`) {
              if (e.payload?.fixed_file_no) {
                patientInfo = {
                  ...patientInfo,
                  [PATIENT_TYPE]: "out-patient",
                  [PHARMACY_FIXED_FILE_NO]: String(e.payload?.fixed_file_no),
                  [PHARMACY_PATIENT_NAME]: e.payload?.name,
                };
                reset1(patientInfo);
              } else {
                patientInfo = {
                  ...patientInfo,
                  [PATIENT_TYPE]: "out-patient",
                  [PHARMACY_FIXED_FILE_NO]: "9999999",
                  [PHARMACY_PATIENT_NAME]: "Cash Customer",
                };
                reset1(patientInfo);
              }
            }
          }
        );
      }
    }
  }, [formData[PATIENT_TYPE]]);

  const createRequestPayload = (data?: any, event?: any) => {
    let requestData: any = {
      page: 1,
      pageSize: 10,
      type: "MEDICINES",
    };
    if (data && event) {
      requestData[data] = event;
    }
    return requestData;
  };
  useEffect(() => {
    dispatch(getAllInventoryItem(requestGenerator(createRequestPayload())));
  }, []);
  const handleItemNameApi = (event: any) => {
    dispatch(
      getAllInventoryItem(requestGenerator(createRequestPayload("name", event)))
    );
  };

  const handleItemNameChange = useCallback(
    debounce((text) => handleItemNameApi(text), 1000),
    []
  );
  useEffect(() => {
    if (location.state?.order) {
      const order = location?.state?.order;
      const patientBranch = allBranches?.find((item: any) => {
        return item?._id === order?.branch_id;
      });
      let patientInfo: any = {
        [PATIENT_TYPE]: order?.patient_id ? "regular" : "out-patient",
        [PHARMACY_PATIENT_NAME]: order?.name,
        [PHARMACY_FILE_NO]: order?.emr_no,
        [PHARMACY_FIXED_FILE_NO]: order?.fixed_file_no,
        [PHARMACY_AGE]: order?.age,
        [PHARMACY_NATIONAL_ID_NO]: order?.national_id,
        [PHARMACY_GENDER]: order?.gender,
        [PHARMACY_MOBILE]: order?.phone,
        [PHARMACY_DATE]: moment(order?.date).format("YYYY-MM-DD"),
        [PHARMACY_REFERRAL]: order?.referal_name,
        [PHARMACY_BRANCH]: {
          label: patientBranch?.name,
          value: patientBranch?._id,
        },
      };
      const referalType: any = referalTypeData?.find(
        (item: any) => item?.name === order?.referal_type
      );
      setSelectedItem(referalType);
      setPharmacyType(order?.status);
      const orderArray = order?.phrm_orders_items?.map((item: any) => {
        const discountedAmount = item?.price * item?.qty - item?.total_amount;
        return {
          item_id: item?.item_id,
          name: item?.name,
          qty: item?.qty,
          price: item?.price,
          discount: item?.discount,
          discountedAmount: discountedAmount,
          expiry_date: "-",
          total_amount: item?.total_amount,
          item_code: "-",
        };
      });
      dispatch(setItemOrderData(orderArray));
      reset1(patientInfo);
    }
  }, [location]);
  useEffect(() => {
    return () => {
      dispatch(clearOrderData());
      dispatch(clearPatientData());
    };
  }, []);

  const handleOpenNotificationModal = () => {
    setNotificationModalOpen(true);
  };
  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (
        notificationModalOpen &&
        notificationRef.current &&
        !notificationRef.current.contains(e.target)
      ) {
        setNotificationModalOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [notificationModalOpen]);

  useEffect(() => {
    dispatch(getAllNotificationList(requestGenerator({})));
  }, []);

  useEffect(() => {
    if (selectedNotification?.metadata?.patient_id) {
      dispatch(
        getPatientEmrById(
          requestGenerator({ id: selectedNotification?.metadata?.patient_id })
        )
      ).then((e) => {
        if (e.type === `${GET_PATIENT_EMR_BY_ID}/fullfilled`) {
        }
      });
    }
    if (selectedNotification?.metadata?.diagnosis_id) {
      dispatch(
        getPatientMedicine(
          requestGenerator({
            diagnosis_id: selectedNotification?.metadata?.diagnosis_id,
          })
        )
      ).then((e) => {
        if (e.type === `${GET_PATIENT_MEDICINE}/fulfilled`) {
          const orderArray =
            e.payload?.medicine_prescription &&
            e.payload?.medicine_prescription?.length > 0
              ? e.payload?.medicine_prescription?.map((item: any) => {
                  return {
                    item_id: item?.item_id,
                    name: item?.medicine_name,
                    qty: item?.qty,
                    price: item?.sell_price ? item?.sell_price : 0,
                    discount: 0,
                    discountedAmount: 0,
                    expiry_date: "-",
                    total_amount: item?.qty * item?.sell_price,
                    item_code: item?.item_code,
                  };
                })
              : [];
          dispatch(setItemOrderData(orderArray));
        }
      });
    }
  }, [selectedNotification]);

  useEffect(() => {
    if (selectedPrescription && selectedPrescription?.medication?.length > 0) {
      const orderArray =
        selectedPrescription?.medication &&
        selectedPrescription?.medication?.length > 0
          ? selectedPrescription?.medication?.map((item: any) => {
              return {
                item_id: item?.item_id,
                name: item?.medicine_name,
                qty: item?.qty,
                price: item?.sell_price ? item?.sell_price : 0,
                discount: 0,
                discountedAmount: 0,
                expiry_date: "-",
                total_amount: item?.qty * item?.sell_price,
                item_code: item?.item_code,
              };
            })
          : [];
      dispatch(setItemOrderData(orderArray));
    }
  }, [selectedPrescription]);

  const validateDiscountValue = (value: any) => {
    if (value < 0) {
      return t("InsurancePlan_Form.positive_value_validate");
    } else if (value > 100) {
      return t("InsurancePlan_Form.value_less_than_100");
    }
    return undefined;
  };

  const handleKeyDown = (e: any, length: number) => {
    if (e.target.value.length >= length && e.key !== "Backspace") {
      e.preventDefault();
    }
  };

  useEffect(() => {
    let data = {
      search: "",
      page: 1,
      pageSize: 1000,
    };
    dispatch(getAllBranch(requestGenerator(data)));
  }, []);

  return (
    <>
      {notificationModalOpen && (
        <PharmacyNotificationModal
          setNotificationModalOpen={setNotificationModalOpen}
          notiRef={notificationRef}
          customClassIcon={styles.recetangelICon}
          customClass={styles.mainCustomClass}
          setData={setSelectedNotification}
          patientType={formData[PATIENT_TYPE]}
        />
      )}
      {isFormLoading || emrLoader ? <Loader /> : ""}
      {medicineComposition && (
        <Popup
          Children={MedicineCompositionPopup}
          handleClose={() => setMedicineComposition(false)}
        />
      )}
      {showSearchModal && (
        <Popup
          Children={SearchModal}
          handleClose={() => handleSearchModalClose()}
          setModelOpenClose={setShowSearchModal}
          invoiceFlag={false}
          isDefault={true}
        />
      )}

      <CustomModal
        title={t("POS.Item_Inventory") || "Item Inventory"}
        showModal={inventoryModal}
        closeModal={() => setInventoryModal(false)}
        width="80dvw"
        height="85dvh"
      >
        <ViewInventory isPharmacy={true} />
      </CustomModal>

      <CustomModal
        title={t("Common.Medication") || "Medication"}
        showModal={medicationModal}
        closeModal={() => setMedicationModal(false)}
        width="80dvw"
        height="85dvh"
      >
        <PharmacyMedication
          handleView={(date: any, item: any) => {
            setMedicationViewData(true);
            setMedicationData(
              item?.map((item: any) => {
                return { ...item, date: date };
              })
            );
          }}
          handleRow={(id: any, item: any, row: any) => {
            if (row?.id == 0) {
              setSelectedPrescription(item);
              setMedicationModal(false);
            } else {
              // dispatch(
              //   setMessage({
              //     message: 'Please select latest prescription',
              //     type: warning,
              //   })
              // )
            }
          }}
        />
      </CustomModal>
      {medicationViewData && (
        <Popup
          Children={MedicationViewPopup}
          handleClose={() => setMedicationViewData(false)}
          popData={medicationData}
        />
      )}

      {showSettleInvoiceModal && (
        <Popup
          Children={SettledInvoiceModal}
          handleClose={() => setShowSettleInvoiceModal(false)}
          setModelOpenClose={setShowSettleInvoiceModal}
          message={"PHARMACY"}
          popData={""}
        />
      )}

      <div className={styles.pharmacyInfoFormMainContainer}>
        <div className={styles.pharnacyFormHeader}>
          <div className={styles.customerInfoTitle}>
            {t("POS.Customer_Information")}
          </div>
          <div className={styles.buttonUserInfoContainer}>
            {/* <div className={styles.documentContainer}>
              <span className={styles.docNoText}>Document No.</span>
              <span className={styles.docNo}>1254689</span>
            </div> */}
            <div className={styles.salesPersonContainer}>
              <span className={styles.salsePersonText}>
                {t("POS.Sales_Person")}
              </span>
              <span className={styles.salePersonName}>{userData?.name}</span>
            </div>
            <Button
             title={t("SettledInvoice.All Invoices") || "All Invoices"}
              customClass={styles.receiptButton}
              handleClick={()=>setShowSettleInvoiceModal(!showSettleInvoiceModal)}
            />
            <Button
              title={
                t("POS.Internal_Prescriptions") || "Internal Prescriptions"
              }
              customClass={styles.prescriptionButton}
              handleClick={() => setMedicationModal(true)}
              disable={patientDataObjectById?._id !== undefined ? false : true}
            />

            <div className={styles.notiifcationConatiner}>
              {notificationListData?.notificationCount > 0 ? (
                <div
                  className={styles.circle}
                  onClick={handleOpenNotificationModal}
                >
                  <p className={styles.countTextStyle}>
                    {notificationListData?.notificationCount > 99
                      ? notificationListData?.notificationCount + "+"
                      : notificationListData?.notificationCount}
                  </p>
                </div>
              ) : (
                ""
              )}
              <NotificationNewIcon
                customClass={styles.notiifcationIconStyle}
                handleClick={handleOpenNotificationModal}
                iconRef={notificationRef}
              />
            </div>
          </div>
        </div>
        <div className={styles.pharmacyInfoFormWrapper}>
          <form onSubmit={handleSubmitfirstForm(handlefirstFormSubmit)}>
            {infoForm && (
              <>
                <Divider customClass={styles.divider} />
                <div className={styles.formFieldRow}>
                  <div className={styles.formFieldContainer}>
                    <div className={styles.inputFieldContainer}>
                      <label
                        htmlFor={PATIENT_TYPE}
                        className={styles.radioLabelText}
                      >
                        {t("ViewAppointment.Patient")}
                      </label>
                      <div className={styles.inlineItems}>
                        <div>
                          <label
                            htmlFor={"regular"}
                            className={styles.radioLabel}
                          >
                            <input
                              className={styles.radioInput}
                              type="radio"
                              id="regular"
                              value="regular"
                              {...register1(PATIENT_TYPE)}
                              disabled={location?.state?.order?._id}
                            />
                            <span className={styles.customRadio} />
                            {t("InvoiceInfo.Regular")}
                          </label>
                        </div>
                        <div>
                          <label
                            htmlFor={"out-patient"}
                            className={styles.radioLabel}
                          >
                            <input
                              className={styles.radioInput}
                              type="radio"
                              id="out-patient"
                              value="out-patient"
                              {...register1(PATIENT_TYPE)}
                              disabled={location?.state?.order?._id}
                            />
                            <span className={styles.customRadio} />
                            {t("InvoiceInfo.Out Patient")}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.formFieldRow}>
                  <div className={styles.formFieldContainer}>
                    <div className={styles.inputFieldContainer}>
                      <Label
                        htmlFor={PHARMACY_PATIENT_NAME}
                        labelText={t("ShareQuestionnaire.Patient Name")}
                        requiredField={true}
                        customClass={styles.customLabel}
                      />
                      <div className={styles.inlineItems}>
                        <input
                          type="text"
                          placeholder={
                            t("ShareQuestionnaire.Patient Name") ||
                            "Patient Name"
                          }
                          className={styles.inputField}
                          {...register1(
                            PHARMACY_PATIENT_NAME,
                            addPharmacyCustomerInformation[
                              PHARMACY_PATIENT_NAME
                            ]
                          )}
                          onChange={(e) => trimValue(e)}
                          disabled={checkDisable()}
                        />
                        <SearchIcon
                          fillColor={colors.grey2}
                          customClass={styles.searchIconStyle}
                          handleClick={() => {
                            if (formData[PATIENT_TYPE] === "regular") {
                              setShowSearchModal(true);
                            }
                          }}
                        />
                      </div>
                    </div>
                    {errors1[PHARMACY_PATIENT_NAME] && (
                      <div className={styles.errorContainer}>
                        <span className={styles.extraSpan}></span>
                        <p className="dashboardFormError">
                          {errors1[PHARMACY_PATIENT_NAME].message}
                        </p>
                      </div>
                    )}
                  </div>
                  {formData[PATIENT_TYPE] === "out-patient" ? (
                    <div className={styles.formFieldContainer}>
                      <div className={styles.inputFieldContainer}>
                        <Label
                          htmlFor={PHARMACY_FIXED_FILE_NO}
                          labelText={t("LabJob.Fixed_File_No")}
                          requiredField={checkRequired()}
                          customClass={styles.customLabel}
                        />
                        <div className={styles.inlineItems}>
                          <input
                            type="text"
                            placeholder={
                              t("LabJob.Fixed_File_No") || "Fixed File No"
                            }
                            {...register1(PHARMACY_FIXED_FILE_NO)}
                            className={styles.inputField}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className={styles.formFieldContainer}>
                      <div className={styles.inputFieldContainer}>
                        <Label
                          htmlFor={PHARMACY_FILE_NO}
                          labelText={t("PatientEMR.File No.")}
                          requiredField={checkRequired()}
                          customClass={styles.customLabel}
                        />
                        <div className={styles.inlineItems}>
                          <input
                            type="text"
                            placeholder={t("PatientEMR.File No.") || "File No."}
                            className={styles.inputField}
                            {...register1(
                              PHARMACY_FILE_NO,
                              checkRequired()
                                ? addPharmacyCustomerInformation[
                                    PHARMACY_FILE_NO
                                  ]
                                : undefined
                            )}
                            onChange={(e) => {
                              trimValue(e);
                            }}
                            disabled={checkDisable()}
                          />
                        </div>
                      </div>
                      {errors1[PHARMACY_FILE_NO] && (
                        <div className={styles.errorContainer}>
                          <span className={styles.extraSpan}></span>
                          <p className="dashboardFormError">
                            {errors1[PHARMACY_FILE_NO].message}
                          </p>
                        </div>
                      )}
                    </div>
                  )}

                  <div className={styles.formFieldContainer}>
                    <div className={styles.inputFieldContainer}>
                      <Label
                        htmlFor={PHARMACY_MOBILE}
                        labelText={t("PatientEMR.Mobile")}
                        requiredField={checkRequired()}
                        customClass={styles.customLabel}
                      />

                      <PhoneInput
                        country={branchData?.country?.country_code?.toLowerCase()}
                        placeholder="Enter Mobile No."
                        {...register1(
                          PHARMACY_MOBILE,
                          checkRequired()
                            ? addPharmacyCustomerInformation[PHARMACY_MOBILE]
                            : undefined
                        )}
                        value={getValues1(PHARMACY_MOBILE)}
                        onChange={(phone) => {
                          //  trimValue(e)
                          const formattedPhone: any = phone && `+${phone}`;
                          setValue1(PHARMACY_MOBILE, formattedPhone);
                          trigger(PHARMACY_MOBILE);
                        }}
                        inputClass={styles.phoneNumberInput}
                        containerStyle={{ flexBasis: "70%" }}
                        disabled={checkDisable()}
                      />
                    </div>
                    {errors1[PHARMACY_MOBILE] && (
                      <div className={styles.errorContainer}>
                        <span className={styles.extraSpan}></span>
                        <p className="dashboardFormError">
                          {errors1[PHARMACY_MOBILE].message as any}
                        </p>
                      </div>
                    )}
                  </div>
                  <div className={styles.formFieldContainer}>
                    <div className={styles.inputFieldContainer}>
                      <Label
                        htmlFor={PHARMACY_AGE}
                        labelText={t("POS.Age(Y)")}
                        requiredField={checkRequired()}
                        customClass={styles.customLabel}
                      />
                      <div className={styles.inlineItems}>
                        <input
                          type="number"
                          placeholder={t("PatientEMR.Age") || "Age"}
                          className={styles.inputField}
                          {...register1(
                            PHARMACY_AGE,
                            checkRequired()
                              ? addPharmacyCustomerInformation[PHARMACY_AGE]
                              : undefined
                          )}
                          onChange={(e) => trimValue(e)}
                          onKeyDown={(e: any) => disableArrowKey(e)}
                          onWheel={(e: any) => {
                            e.target.blur();
                          }}
                          disabled={checkDisable()}
                        />
                      </div>
                    </div>
                    {errors1[PHARMACY_AGE] && (
                      <div className={styles.errorContainer}>
                        <span className={styles.extraSpan}></span>
                        <p className="dashboardFormError">
                          {errors1[PHARMACY_AGE].message as any}
                        </p>
                      </div>
                    )}
                  </div>
                  <div className={styles.formFieldContainer}>
                    <div className={styles.inputFieldContainer}>
                      <Label
                        htmlFor={PHARMACY_NATIONAL_ID_NO}
                        labelText={t("POS.National_ID_No")}
                        requiredField={checkRequired()}
                        customClass={styles.customLabel}
                      />
                      <div className={styles.inlineItems}>
                        <input
                          type="text"
                          placeholder={
                            t("POS.National_ID_No") || "National ID No."
                          }
                          className={styles.inputField}
                          {...register1(
                            PHARMACY_NATIONAL_ID_NO,
                            checkRequired()
                              ? addPharmacyCustomerInformation[
                                  PHARMACY_NATIONAL_ID_NO
                                ]
                              : undefined
                          )}
                          disabled={checkDisable()}
                        />

                        <InfoTooltip
                          tooltipData={{
                            message: t("POS.nationalid_info"),
                          }}
                          Children={DoubleInfoBookingInfo}
                          title=""
                        />
                      </div>
                    </div>
                    {errors1[PHARMACY_NATIONAL_ID_NO] && (
                      <div className={styles.errorContainer}>
                        <span className={styles.extraSpan}></span>
                        <p className="dashboardFormError">
                          {errors1[PHARMACY_NATIONAL_ID_NO].message}
                        </p>
                      </div>
                    )}
                  </div>
                  <div className={styles.formFieldContainer}>
                    <div className={styles.inputFieldContainer}>
                      <Label
                        htmlFor={PHARMACY_DATE}
                        labelText={t("RecentHistory.Date")}
                        requiredField={checkRequired()}
                        customClass={styles.customLabel}
                      />
                      <div className={styles.inlineItems}>
                        <input
                          type="date"
                          placeholder={t("RecentHistory.Date") || "Date"}
                          max="9999-12-31"
                          // min={new Date().toISOString().split('T')[0]}
                          className={styles.inputField}
                          {...register1(
                            PHARMACY_DATE,
                            checkRequired()
                              ? addPharmacyCustomerInformation[PHARMACY_DATE]
                              : undefined
                          )}
                          onChange={(e) => trimValue(e)}
                        />
                      </div>
                    </div>
                    {errors1[PHARMACY_DATE] && (
                      <div className={styles.errorContainer}>
                        <span className={styles.extraSpan}></span>
                        <p className="dashboardFormError">
                          {errors1[PHARMACY_DATE].message as any}
                        </p>
                      </div>
                    )}
                  </div>

                  <div className={styles.formFieldContainer}>
                    <div className={styles.inputFieldContainer}>
                      <Label
                        htmlFor={PHARMACY_REFERRAL}
                        labelText={t("POS.Referral")}
                        customClass={styles.customLabel}
                      />
                      <div className={styles.inlineItems}>
                        <DropdownV2
                          data={referalTypeData}
                          keyName="name"
                          selectedItem={selectedItem}
                          setSelectedItem={"setSelectedItem"}
                          handleClick={(item: any) => {
                            setSelectedItem({
                              name: item?.name,
                              _id: item?._id,
                            });
                          }}
                          notShowAllOption={true}
                          customClassBox={styles.dropdownBox}
                          customClassBody={styles.dropdownBody}
                        />
                        <input
                          type="text"
                          className={styles.referalInputField}
                          {...register1(PHARMACY_REFERRAL)}
                          onChange={(e) => trimValue(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.formFieldContainer}>
                    <div className={styles.inputFieldContainer}>
                      <label
                        htmlFor={PHARMACY_GENDER}
                        className={styles.radioLabelText}
                      >
                        {t("PatientEMR.Gender")}
                        {checkRequired() && <span className="asterick">*</span>}
                      </label>
                      <div className={styles.inlineItems}>
                        <div>
                          <label htmlFor="male" className={styles.radioLabel}>
                            <input
                              className={styles.radioInput}
                              type="radio"
                              id="male"
                              value="MALE"
                              {...register1(
                                PHARMACY_GENDER,
                                checkRequired()
                                  ? addPharmacyCustomerInformation[
                                      PHARMACY_GENDER
                                    ]
                                  : undefined
                              )}
                            />
                            <span className={styles.customRadio} />
                            {t("PatientEMR.Male")}
                          </label>
                        </div>
                        <div>
                          <label htmlFor="female" className={styles.radioLabel}>
                            <input
                              className={styles.radioInput}
                              type="radio"
                              id="female"
                              value="FEMALE"
                              {...register1(
                                PHARMACY_GENDER,
                                checkRequired()
                                  ? addPharmacyCustomerInformation[
                                      PHARMACY_GENDER
                                    ]
                                  : undefined
                              )}
                            />
                            <span className={styles.customRadio} />
                            {t("PatientEMR.Female")}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className={styles.errorContainer}>
                      <span className={styles.extraSpan}></span>
                      {errors1[PHARMACY_GENDER] && (
                        <p className="dashboardFormError">
                          {errors1[PHARMACY_GENDER].message}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className={styles.formFieldContainer}>
                    <div className={styles.inputFieldContainer}>
                      <Label
                        htmlFor={PHARMACY_BRANCH}
                        // labelText={t('POS.National_ID_No')}
                        labelText="Branch"
                        requiredField={checkRequired()}
                        customClass={styles.customLabel}
                      />
                      <div className={styles.inlineItems}>
                        <Select
                          className={styles.selectField}
                          // isClearable
                          // placeholder={t('Pharmacy.Select_Item_Code')}
                          placeholder="Branch"
                          closeMenuOnSelect={true}
                          components={{ DropdownIndicator }}
                          value={watch1(PHARMACY_BRANCH)}
                          options={allBranches
                            ?.filter((item: any) => item?._id)
                            .map((item: any, i: number) => {
                              return {
                                label: item?.name,
                                value: item?._id,
                              };
                            })}
                          isSearchable={true}
                          {...register1(
                            PHARMACY_BRANCH,
                            addPharmacyCustomerInformation[PHARMACY_BRANCH]
                          )}
                          onChange={(e: any) => {
                            setValue1(PHARMACY_BRANCH, e);
                          }}
                          maxMenuHeight={200}
                          styles={reactSelectStyle}
                        />
                      </div>
                    </div>
                    {errors1[PHARMACY_BRANCH] && (
                      <div className={styles.errorContainer}>
                        <span className={styles.extraSpan}></span>
                        <p className="dashboardFormError">
                          {errors1[PHARMACY_BRANCH].message as any}
                        </p>
                      </div>
                    )}
                  </div>
                </div>

                <div className={styles.formFieldRow}>
                  <div className={styles.finanicialRemarkInsuaranceContainer}>
                    <div className={styles.finacialRemark}>
                      <span className={styles.financialRemarkText}>
                        {t("PatientEMR.Financial Remark")} :
                      </span>
                      <span className={styles.viewLink}>
                        {t("Common.View")}
                      </span>
                    </div>
                    <div className={styles.insuarance}>
                      <span className={styles.insurancePlanText}>
                        {t("Insurance.Insurance Plan")} :
                      </span>
                      <div className={styles.buttonContainer}>
                        <Button
                          type="button"
                          title={t("Common.View") || "View"}
                          customClass={styles.button}
                        />
                        <Button
                          type="button"
                          title={t("ActionBar.Add") || "Add"}
                          customClass={styles.button}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className={styles.moreLessDivider}>
              <Divider customClass={styles.divider1} />
              {infoForm ? (
                <div className={styles.iconTextDivider}>
                  <p className={styles.textDivider}>{t("POS.Less")}</p>
                  <DropDownArrowIcon
                    fillColor={colors.grey2}
                    height={20}
                    width={20}
                    handleClick={() => setInforForm(false)}
                  />
                </div>
              ) : (
                <div className={styles.iconTextDivider}>
                  <p className={styles.textDivider}>{t("POS.More")}</p>
                  <DropDownIcon
                    fillColor={colors.grey2}
                    height={20}
                    width={20}
                    handleClick={() => setInforForm(true)}
                  />
                </div>
              )}
              <Divider customClass={styles.divider2} />
            </div>
            <div className={styles.pharnacyFormHeader}>
              <div className={styles.customerInfoTitle}>
                {t("POS.Medicines")}
              </div>
              <div className={styles.buttonUserInfoContainer}>
                <Button
                  type="button"
                  title={
                    t("Medication.MedicinesComposition") ||
                    "Medicines Composition"
                  }
                  customClass={styles.medicinButton}
                  handleClick={() => setMedicineComposition(true)}
                />
                <Button
                  type="button"
                  title={t("POS.Item_Inventory") || "Item Inventory"}
                  customClass={styles.inventoryButton}
                  handleClick={() => setInventoryModal(true)}
                />
              </div>
            </div>

            <form onSubmit={handleSubmitSecondForm(handleSecondFormSubmit)}>
              <div className={styles.medicinFormContainer}>
                <Divider customClass={styles.divider} />
                <div className={styles.formFieldRow}>
                  <div className={styles.formFieldContainer}>
                    <div className={styles.inputFieldContainer}>
                      <Label
                        htmlFor={PHARMACY_ITEM_CODE}
                        labelText={t("InventoryItemTable.Item_Code")}
                        requiredField={true}
                        customClass={styles.customLabel}
                      />
                      <div className={styles.inlineItems}>
                        <Select
                          className={styles.selectField}
                          // isClearable
                          placeholder={t("Pharmacy.Select_Item_Code")}
                          closeMenuOnSelect={true}
                          onInputChange={handleItemNameChange}
                          components={{ DropdownIndicator }}
                          value={watch2(PHARMACY_ITEM_CODE)}
                          options={inventoryItemData?.map((items: any) => ({
                            ...items,
                            label: items.item_code,
                            value: items.item_code,
                          }))}
                          // isSearchable={true}
                          {...register2(
                            PHARMACY_ITEM_CODE,
                            addPharmacyCustomerSecondInformation[
                              PHARMACY_ITEM_CODE
                            ]
                          )}
                          onChange={(e: any) => {
                            setValue2(PHARMACY_ITEM_CODE, e);
                            setValue2(PHARMACY_PRODUCTS, {
                              ...e,
                              label: e?.name,
                              value: e?.name,
                            });
                            setValue2(PHARMACY_SELL_PRICE, e?.sell_price);
                            setValue2(
                              PHARMACY_AVAILABLE_QTY,
                              e?.available_qty || "0"
                            );
                            setValue2(PHARMACY_QTY, "");
                          }}
                          maxMenuHeight={200}
                          styles={reactSelectStyle}
                        />
                      </div>
                    </div>
                    {errors2[PHARMACY_ITEM_CODE] && (
                      <div className={styles.errorContainer}>
                        <span className={styles.extraSpan} />
                        <p className="dashboardFormError">
                          {errors2[PHARMACY_ITEM_CODE].message as any}
                        </p>
                      </div>
                    )}
                  </div>
                  <div className={styles.formFieldContainer}>
                    <div className={styles.inputFieldContainer}>
                      <Label
                        htmlFor={PHARMACY_PRODUCTS}
                        labelText={t("POS.Products")}
                        requiredField={true}
                        customClass={styles.customLabel}
                      />
                      <div className={styles.inlineItems}>
                        <Select
                          className={styles.selectField}
                          placeholder={t("POS.Search_products")}
                          closeMenuOnSelect={true}
                          onInputChange={handleItemNameChange}
                          components={{ DropdownIndicator }}
                          value={watch2(PHARMACY_PRODUCTS)}
                          options={inventoryItemData?.map((items: any) => ({
                            ...items,
                            label: items.name,
                            value: items.name,
                          }))}
                          // isSearchable={true}
                          {...register2(
                            PHARMACY_PRODUCTS,
                            addPharmacyCustomerSecondInformation[
                              PHARMACY_PRODUCTS
                            ]
                          )}
                          onChange={(e: any) => {
                            setValue2(PHARMACY_PRODUCTS, e);
                            setValue2(PHARMACY_ITEM_CODE, {
                              ...e,
                              label: e?.item_code,
                              value: e?.item_code,
                            });
                            setValue2(PHARMACY_SELL_PRICE, e?.sell_price);
                            setValue2(
                              PHARMACY_AVAILABLE_QTY,
                              e?.available_qty || "0"
                            );
                            setValue2(PHARMACY_QTY, "");
                          }}
                          maxMenuHeight={200}
                          styles={reactSelectStyle}
                        />
                      </div>
                    </div>
                    {errors2[PHARMACY_PRODUCTS] && (
                      <div className={styles.errorContainer}>
                        <span className={styles.extraSpan}></span>
                        <p className="dashboardFormError">
                          {errors2[PHARMACY_PRODUCTS].message as any}
                        </p>
                      </div>
                    )}
                  </div>
                  <div className={styles.formFieldContainer}>
                    <div className={styles.inputFieldContainer}>
                      <Label
                        htmlFor={PHARMACY_SELL_PRICE}
                        labelText={t("BedSetup.sell_price")}
                        requiredField={true}
                        customClass={styles.customLabel}
                      />
                      <div className={styles.inlineItems}>
                        <input
                          type="number"
                          placeholder={
                            t("ServiceMaster.sell_price_placeholder") ||
                            "Enter Sell Price"
                          }
                          className={styles.inputField}
                          {...register2(
                            PHARMACY_SELL_PRICE,
                            addPharmacyCustomerSecondInformation[
                              PHARMACY_SELL_PRICE
                            ]
                          )}
                          onChange={(e) => trimValue(e)}
                          onKeyDown={(e: any) => disableArrowKey(e)}
                          onWheel={(e: any) => {
                            e.target.blur();
                          }}
                        />
                      </div>
                    </div>
                    {errors2[PHARMACY_SELL_PRICE] && (
                      <div className={styles.errorContainer}>
                        <span className={styles.extraSpan}></span>
                        <p className="dashboardFormError">
                          {errors2[PHARMACY_SELL_PRICE].message as any}
                        </p>
                      </div>
                    )}
                  </div>
                  <div className={styles.formFieldContainer}>
                    <div className={styles.inputFieldContainer}>
                      <Label
                        htmlFor={PHARMACY_AVAILABLE_QTY}
                        labelText={t("POS.Available_Qty")}
                        requiredField={true}
                        customClass={styles.customLabel}
                      />
                      <div className={styles.inlineItems}>
                        <input
                          type="number"
                          placeholder={
                            t("POS.Available_Qty_Placeholder") ||
                            "Enter Available Qty"
                          }
                          className={styles.inputField}
                          {...register2(PHARMACY_AVAILABLE_QTY)}
                          onChange={(e) => trimValue(e)}
                          onKeyDown={(e: any) => disableArrowKey(e)}
                          onWheel={(e: any) => {
                            e.target.blur();
                          }}
                          disabled
                        />
                      </div>
                    </div>
                    {/* {errors2[PHARMACY_QTY] && (
                      <div className={styles.errorContainer}>
                        <span className={styles.extraSpan}></span>
                        <p className="dashboardFormError">
                          {errors2[PHARMACY_QTY].message as any}
                        </p>
                      </div>
                    )} */}
                  </div>
                  <div className={styles.formFieldContainer}>
                    <div className={styles.inputFieldContainer}>
                      <Label
                        htmlFor={PHARMACY_QTY}
                        labelText={t("ItemUnitMaster.Qty")}
                        requiredField={true}
                        customClass={styles.customLabel}
                      />
                      <div className={styles.inlineItems}>
                        <input
                          type="number"
                          placeholder={
                            t("ItemUnitMaster.Qty_Placeholder") || "Enter Qty"
                          }
                          className={styles.inputField}
                          {...register2(
                            PHARMACY_QTY,
                            addPharmacyCustomerSecondInformation[PHARMACY_QTY]
                          )}
                          onChange={(e) => {
                            trimValue(e);
                            setValue2(PHARMACY_QTY, e.target.value);
                          }}
                          onKeyDown={(e: any) => disableArrowKey(e)}
                          onWheel={(e: any) => {
                            e.target.blur();
                          }}
                        />
                      </div>
                    </div>
                    {errors2[PHARMACY_QTY] && (
                      <div className={styles.errorContainer}>
                        <span className={styles.extraSpan}></span>
                        <p className="dashboardFormError">
                          {errors2[PHARMACY_QTY].message as any}
                        </p>
                      </div>
                    )}
                  </div>
                  <div className={styles.formFieldContainer}>
                    <div className={styles.inputFieldContainer}>
                      <Label
                        htmlFor={PHARMACY_DISCOUNT}
                        labelText={`${t("InsurancePlan_Form.Discount")}%`}
                        requiredField={false}
                        customClass={styles.customLabel}
                      />
                      <div className={styles.inlineItems}>
                        <input
                          type="number"
                          placeholder={
                            t("InsurancePlan_Form.Discount_Placeholder") ||
                            "Enter Discount"
                          }
                          className={styles.inputField}
                          {...register2(PHARMACY_DISCOUNT, {
                            validate: validateDiscountValue,
                          } as any)}
                          onChange={(e) => trimValue(e)}
                          onKeyDown={(e: any) => {
                            disableArrowKey(e);
                            handleKeyDown(e, 3);
                          }}
                          onWheel={(e: any) => {
                            e.target.blur();
                          }}
                        />
                      </div>
                    </div>
                    {errors2[PHARMACY_DISCOUNT] && (
                      <p className="errorText">
                        {errors2[PHARMACY_DISCOUNT].message as any}
                      </p>
                    )}
                  </div>
                  <div className={styles.formFieldContainer}>
                    <div className={styles.inputFieldContainer}>
                      <Label
                        htmlFor={PHARMACY_EXPIRY_DATE}
                        labelText={t("Insurance.Expiry Date")}
                        customClass={styles.customLabel}
                      />
                      <div className={styles.inlineItems}>
                        <input
                          type="date"
                          className={styles.inputField}
                          {...register2(PHARMACY_EXPIRY_DATE)}
                          disabled
                          onChange={(e) => trimValue(e)}
                        />
                      </div>
                    </div>
                    {errors2[PHARMACY_EXPIRY_DATE] && (
                      <div className={styles.errorContainer}>
                        <span className={styles.extraSpan}></span>
                        <p className="dashboardFormError">
                          {errors2[PHARMACY_EXPIRY_DATE].message as any}
                        </p>
                      </div>
                    )}
                  </div>
                  {/* <div className={styles.formFieldContainer}>
                    <div className={styles.inputFieldContainer}>
                      <Label
                        htmlFor={PHARMACY_BRANCH}
                        // labelText={t('POS.National_ID_No')}
                        labelText="Branch"
                        requiredField={checkRequired()}
                        customClass={styles.customLabel}
                      />
                      <div className={styles.inlineItems}>
                        <Select
                          className={styles.selectField}
                          // isClearable
                          // placeholder={t('Pharmacy.Select_Item_Code')}
                          placeholder="Branch"
                          closeMenuOnSelect={true}
                          components={{ DropdownIndicator }}
                          value={watch2(PHARMACY_BRANCH)}
                          options={branchData?.branches
                            ?.filter((item: any) => item?._id)
                            .map((item: any, i: number) => {
                              return {
                                label: item?.name,
                                value: item?._id,
                              };
                            })}
                          // isSearchable={true}
                          {...register2(
                            PHARMACY_BRANCH
                            // addPharmacyCustomerInformation[PHARMACY_BRANCH]
                          )}
                          onChange={(e: any) => {
                            setValue2(PHARMACY_BRANCH, e);
                          }}
                          maxMenuHeight={200}
                          styles={reactSelectStyle}
                        />
                      </div>
                    </div>
                    {errors2[PHARMACY_BRANCH] && (
                      <div className={styles.errorContainer}>
                        <span className={styles.extraSpan}></span>
                        <p className="dashboardFormError">
                          {errors2[PHARMACY_BRANCH].message as any}
                        </p>
                      </div>
                    )}
                  </div> */}
                </div>
                <div className={styles.addItemButton}>
                  <Button
                    type="submit"
                    title={t("POS.Add_Item") || "Add Item"}
                    handleClick={handleSubmitSecondForm(handleSecondFormSubmit)}
                    // disable={
                    //   Number(pharmacyQty) > selectedProduct?.available_qty
                    // }
                  />
                </div>
              </div>
            </form>

            <div className={styles.tablestyle}>
              <TableV2
                tableHeaderData={medicinesTableHeaderData}
                tableRowData={itemOrderData}
                active={false}
              />
            </div>

            <div className={styles.formsubmitbuttons}>
              <Button
                type="submit"
                title={t("IPDInvoice.Next") || "Next"}
                customClass={styles.nextButton}
                handleClick={() => setPharmacyType("COMPLETED")}
                // handleClick={handleSubmitfirstForm(handlefirstFormSubmit)}
              />
              <Button
                title={t("POS.On-hold") || "On-hold"}
                type="submit"
                customClass={styles.inventoryButton}
                handleClick={() => setPharmacyType("ONHOLD")}
                // handleClick={handleSubmitfirstForm(handlefirstFormSubmit)}
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default PharmacyInfoForm;
