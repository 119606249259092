import { useState, useEffect } from 'react'
import styles from './tableData.module.scss'
import { useAppDispatch, useAppSelector } from '../../hooks'
import {
  setTestAddNewText,
  updatedNewServiceArray,
} from '../../redux/features/invoice-module/invoiceSlice'
import {
  allowedNumberOfDigitsAfterDecimal,
  disableArrowKey,
  disableScroll,
  isServiceEditable,
} from '../../utils/utils'
import { DeleteIcon } from '../../components/common/svg-components'
import { t } from 'i18next'

// invoice service data
export const invoiceServiceHeaderData: any = [
  {
    Header: t('ServiceTable.PLAN NAME'),
    Cell: ({ row }: any) => {
      const plan_name = row?.original?.plan_name

      return (
        <>
          <span>{plan_name ? plan_name : '-'}</span>
          {/* <span>{'-'}</span> */}
        </>
      )
    },
  },

  {
    Header: t('ServiceTable.SERVICE NAME'),
    accessor: 'name',
  },
  {
    Header: t('ServiceTable.Quantity (E)'),
    Cell: ({ row }: any) => {
      const dispatch = useAppDispatch()
      const { unitPrice, discountPercantage } = row?.original
      const { patientInvoiceData, patientDiagnosisServiceData } =
        useAppSelector((state) => state.invoice)
      const [error, setError] = useState('')

      const handleQuantityChange = (event: any) => {
        const quantity = event.target.value

        const updatedData = patientDiagnosisServiceData.map(
          (item: any, index: number) => {
            if (index === Number(row?.id)) {
              return {
                ...item,
                discount: discountPercantage
                  ? (quantity * unitPrice * discountPercantage) / 100
                  : 0,
                quantity: parseInt(quantity),
              }
            }

            return item
          }
        )

        dispatch(updatedNewServiceArray(updatedData))
      }
      useEffect(() => {
        disableScroll()
      }, [])
      return (
        <>
          <input
            className={styles.inputFieldServiceTable}
            value={row?.original?.quantity}
            type="number"
            key={row.original._id}
            onChange={handleQuantityChange}
            onKeyDown={(e: any) => disableArrowKey(e)}
            onWheel={(e: any) => {
              e.target.blur()
            }}
            disabled={isServiceEditable(patientInvoiceData) ? false : true}
          />
          <p className="dashboardFormError">{error}</p>
        </>
      )
    },
  },
  {
    Header: t('ServiceTable.UNIT PRICE (E)'),
    Cell: ({ row }: any) => {
      // const _id = row?.original?._id;
      const price = row?.original?.unitPrice
      const discountPercantage = row?.original?.discountPercantage
      const quantity = row?.original?.quantity
      // const price = allowedNumberOfDigitsAfterDecimal(
      //   row?.original?.unitPrice,
      //   3
      // )

      const dispatch = useAppDispatch()
      const { patientDiagnosisServiceData, patientInvoiceData } =
        useAppSelector((state) => state.invoice)
      const [error, setError] = useState('')

      const validateInput = (inputValue: any) => {
        const pattern = /^\d{1,6}(?:\.\d{1,3})?$/
        return pattern.test(inputValue)
      }
      const handleUnitPriceChange = (
        event: React.ChangeEvent<HTMLInputElement>
      ) => {
        const unitPrice = event.target.value
        const isValid = validateInput(unitPrice)
        if (isValid === false) {
          setError(t('ServiceTable.ValidAMT') || 'Please enter valid amount')
        }
        if (isValid === true) {
          setError('')
        }
        const updatedData = patientDiagnosisServiceData.map(
          (item: any, index: number) => {
            if (index === Number(row?.id)) {
              return {
                ...item,
                discount: discountPercantage
                  ? (quantity * Number(unitPrice) * discountPercantage) / 100
                  : 0,
                unitPrice: parseFloat(unitPrice),
              }
            }
            return item
          }
        )

        dispatch(updatedNewServiceArray(updatedData))
      }
      useEffect(() => {
        disableScroll()
      }, [])
      return (
        <>
          <input
            className={styles.inputFieldServiceTable}
            value={price}
            type="number"
            key={row.original._id}
            onChange={handleUnitPriceChange}
            onKeyDown={(e: any) => disableArrowKey(e)}
            onWheel={(e: any) => {
              e.target.blur()
            }}
            disabled={isServiceEditable(patientInvoiceData) ? false : true}
          />
          <p className="dashboardFormError">{error}</p>
        </>
      )
    },
  },

  {
    Header: t('InvoiceService.AMOUNT'),
    Cell: ({ row }: any) => {
      const quantity = row?.original?.quantity
      const unitPrice = row?.original?.unitPrice
      const amountCalculation = quantity * unitPrice
      const amount = amountCalculation
      // const amount = allowedNumberOfDigitsAfterDecimal(amountCalculation, 3)
      const [error, setError] = useState('')

      const validateInput = (inputValue: any) => {
        const pattern = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/
        return pattern.test(inputValue)
      }
      const handleAmount: any = (amount: any) => {
        const isValid = validateInput(amount)
        if (isValid === false) {
          setError(t('ServiceTable.ValidAMT') || 'Please enter valid amount')
        }
        if (isValid === true) {
          setError('')
        }
      }
      useEffect(() => {
        handleAmount(amount)
      }, [amount])
      return (
        <>
          {isNaN(amount) ? '-' : <span>{amount?.toLocaleString()}</span>}
          <p className="dashboardFormError">{error}</p>
        </>
      )
    },
  },
  {
    Header: t('ServiceTable.APPLY DISCOUNT (E)'),
    Cell: ({ row }: any) => {
      const quantity = row?.original?.quantity
      const unitPrice = row?.original?.unitPrice
      const amountCalculation = quantity * unitPrice
      const dispatch = useAppDispatch()
      const { patientDiagnosisServiceData, patientInvoiceData } =
        useAppSelector((state) => state.invoice)
      const [error, setError] = useState('')

      const validateInput = (inputValue: any) => {
        const pattern = /^\d{1,4}(?:\.\d{1,3})?$/
        return pattern.test(inputValue)
      }

      const handleDiscountChange = (event: any) => {
        const discount = event.target.value
        const isValid = validateInput(discount)
        if (isValid === false) {
          setError(t('ServiceTable.ValidDIS') || 'Please enter valid discount')
        }
        if (isValid === true) {
          setError('')
        }
        const updatedData = patientDiagnosisServiceData.map(
          (item: any, index: number) => {
            if (index === Number(row?.id)) {
              return {
                ...item,
                discountPercantage: parseFloat(discount),
                discount: (amountCalculation * parseFloat(discount)) / 100,
              }
            }
            return item
          }
        )

        dispatch(updatedNewServiceArray(updatedData))
      }
      useEffect(() => {
        disableScroll()
      }, [])
      return (
        <>
          <input
            className={styles.inputFieldServiceTable}
            value={row?.original?.discountPercantage}
            // value={allowedNumberOfDigitsAfterDecimal(
            //   row?.original?.discount,
            //   3
            // )}
            type="number"
            key={row.original._id}
            onChange={handleDiscountChange}
            onKeyDown={(e: any) => disableArrowKey(e)}
            onWheel={(e: any) => {
              e.target.blur()
            }}
            disabled={isServiceEditable(patientInvoiceData) ? false : true}
          />
          <p className="dashboardFormError">{error}</p>
        </>
      )
    },
  },
  {
    Header: t('ServiceTable.DISCOUNT_AMT'),
    Cell: ({ row }: any) => {
      const dispatch = useAppDispatch()
      const { patientDiagnosisServiceData, patientInvoiceData } =
        useAppSelector((state) => state.invoice)
      const [error, setError] = useState('')

      const validateInput = (inputValue: any) => {
        const pattern = /^\d{1,4}(?:\.\d{1,3})?$/
        return pattern.test(inputValue)
      }

      const handleDiscountChange = (event: any) => {
        const discount = event.target.value
        const isValid = validateInput(discount)
        if (isValid === false) {
          setError(t('ServiceTable.ValidDIS') || 'Please enter valid discount')
        }
        if (isValid === true) {
          setError('')
        }
        const updatedData = patientDiagnosisServiceData.map(
          (item: any, index: number) => {
            if (index === Number(row?.id)) {
              return {
                ...item,
                discount: parseFloat(discount),
              }
            }
            return item
          }
        )

        dispatch(updatedNewServiceArray(updatedData))
      }
      useEffect(() => {
        disableScroll()
      }, [])
      return (
        <>
          <input
            className={styles.inputFieldServiceTable}
            value={row?.original?.discount}
            type="number"
            key={row.original._id}
            onChange={handleDiscountChange}
            onKeyDown={(e: any) => disableArrowKey(e)}
            onWheel={(e: any) => {
              e.target.blur()
            }}
            disabled={isServiceEditable(patientInvoiceData) ? false : true}
          />
          <p className="dashboardFormError">{error}</p>
        </>
      )
    },
  },
  {
    Header: t('ServiceTable.NET. AMOUNT'),
    Cell: ({ row }: any) => {
      const quantity = row?.original?.quantity
      const unitPrice = row?.original?.unitPrice
      const discount = row?.original?.discount || 0
      const amount = quantity * unitPrice
      const netAmountCalculation = amount - discount
      const netAmount = allowedNumberOfDigitsAfterDecimal(
        netAmountCalculation,
        3
      )
      const [error, setError] = useState('')
      const validateInput = (inputValue: any) => {
        const pattern = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/
        return pattern.test(inputValue)
      }
      const handleNetAmount: any = (amount: any) => {
        const isValid = validateInput(amount)
        if (isValid === false) {
          setError(
            t('ServiceTable.POSITIVENUM') || 'Net amount must be positive'
          )
        }
        if (isValid === true) {
          setError('')
        }
      }
      useEffect(() => {
        handleNetAmount(netAmount)
      }, [netAmount])
      return (
        <>
          {isNaN(netAmount) ? '-' : <span>{netAmount?.toLocaleString()}</span>}
          <p className="dashboardFormError">{error}</p>
        </>
      )
    },
  },
  {
    Header: t('ServiceTable.RETURN'),
    // accessor: "is_return",
    Cell: (props: any) => {
      const typeOfInvoice = typeof props?.row?.original?.is_return
      const { patientInvoiceData } = useAppSelector((state) => state.invoice)
      return (
        <>
          {patientInvoiceData?.status === 'DRAFT' ||
          (patientInvoiceData?.refund_amount == 0 &&
            patientInvoiceData?.is_return === false &&
            patientInvoiceData?.is_refund === false) ? (
            '-'
          ) : typeOfInvoice === 'boolean' ? (
            <span>
              {props?.row?.original?.is_return === true
                ? t('ServiceTable.True')
                : t('ServiceTable.False')}
            </span>
          ) : (
            ''
          )}
        </>
      )
    },
  },
  {
    Header: t('ServiceTable.BILLABLE'),
    accessor: 'isBillable',
  },
  {
    Header: t('PatientHistoryTableHeader.NOTES'),
    accessor: 'notes',
    Cell: (props: any) => {
      return (
        <>
          <span
            className={styles.addNotePopupLink}
            onClick={() => props.onClick(props?.row)}
          >
            {t('InvoiceService.Add')}
          </span>
        </>
      )
    },
  },
  {
    Header: t('DocUploadHeader.ACTION'),
    accessor: 'action',
    Cell: ({ row }: any) => {
      const dispatch = useAppDispatch()
      const { patientDiagnosisServiceData } = useAppSelector(
        (state) => state.invoice
      )
      return (
        <>
          <DeleteIcon
            fillColor="#CDD4D8"
            handleClick={() => {
              const updatedData = patientDiagnosisServiceData.filter(
                (item: any, index: number) => {
                  if (index !== row.index) {
                    return true
                  } else {
                    return false
                  }
                }
              )
              dispatch(setTestAddNewText(row.original._id))
              dispatch(updatedNewServiceArray(updatedData))
            }}
          />
        </>
      )
    },
  },
]
