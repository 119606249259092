import {
  CREATE_INVENTORY_ITEM_TABLE,
  GET_ALL_INVENTORY_ITEM,
  INVENTORY_ITEMS_REQUEST_WIDGETS,
  INVENTORY_ITEMS_WIDGETS,
  UPDATE_INVENTORY_ITEM_TABLE,
  UPDATE_STATUS_INVENTORY_ITEM_TABLE,
} from "../../../constants/asyncActionsType";
import createAsyncThunkForSlice from "../../../utils/utils";
import {
  createInventoryItemTable,
  getAllInventoryItemTable,
  inventoryItemList,
  inventoryItemRequest,
  updateInventoryItemTable,
  updateStatusInventoryItemTable,
} from "./inventoryItemCrud";

export const getInventoryItemList = createAsyncThunkForSlice(
  GET_ALL_INVENTORY_ITEM,
  getAllInventoryItemTable
);

export const createInventoryItem = createAsyncThunkForSlice(
  CREATE_INVENTORY_ITEM_TABLE,
  createInventoryItemTable,
  {
    isToast: true,
  }
);

export const updateInventoryItem = createAsyncThunkForSlice(
  UPDATE_INVENTORY_ITEM_TABLE,
  updateInventoryItemTable,
  {
    isToast: true,
  }
);
export const updateStatusInventoryItem = createAsyncThunkForSlice(
  UPDATE_STATUS_INVENTORY_ITEM_TABLE,
  updateStatusInventoryItemTable,
  {
    isToast: true,
  }
);
// inventory item widgets
export const inventoryItemListWidgets = createAsyncThunkForSlice(
  INVENTORY_ITEMS_WIDGETS,
  inventoryItemList
);

// inventory item  request widgets
export const inventoryItemRequestsWidgets = createAsyncThunkForSlice(
  INVENTORY_ITEMS_REQUEST_WIDGETS,
  inventoryItemRequest
);
