import { FC, useEffect } from "react";
import styles from "./SettledEmailPopup.module.scss";
import Button from "../../button/Button";
import { CloseIcon } from "../../svg-components";
import { colors } from "../../../../constants/color";
import Divider from "../../divider/Divider";
import {
  SETTLED_INVOICE_PATIENT_EMAIL,
  VIEW_ID,
} from "../../../../constants/constant";
import { SubmitHandler, useForm } from "react-hook-form";
import { ISettledInvoicePatientEmail } from "../../../../interfaces/interfaces";
import { settledInvoiceEmailValidator } from "../../../../form-validators/settledInvoiceEmailValidators";
import { findDefaultTemplate, trimValue } from "../../../../utils/utils";
import Loader from "../../spinner/Loader";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { useTranslation } from "react-i18next";

import Select from "react-select";
import { reactSelectStyle } from "../../../../constants/data";

interface ISettledEmailModal {
  handleClose?: any;
  popData?: string | any;
  handleYes: any;
}

const SettledEmailPopup: FC<ISettledEmailModal> = ({
  handleClose,
  popData,
  handleYes,
}) => {
  const dispatch = useAppDispatch();

  // FORM
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    trigger,
    watch,
    formState: { errors },
  } = useForm<ISettledInvoicePatientEmail>({});

  const onSubmit: SubmitHandler<ISettledInvoicePatientEmail> = (data: any) => {
    handleYes({
      _id: popData?._id,
      isPdf: !popData?.showEmail,
      ...data,
      view_id:
        data[VIEW_ID]?.value === "default" ? undefined : data[VIEW_ID]?.value,
    });
  };

  const { isLoading } = useAppSelector((state) => state.labInvoice);
  const { t } = useTranslation();

  useEffect(() => {
    reset(popData);
    setValue(VIEW_ID, findDefaultTemplate(popData?.options));
  }, [popData, reset, dispatch, setValue]);

  return (
    <>
      {isLoading ? <Loader /> : ""}
      <div
        className={styles.mainContainer}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <CloseIcon
          customClass={styles.closeIconStyle}
          fillColor={colors.green1}
          handleClick={() => {
            handleClose && handleClose();
          }}
        />
        <h1 className={styles.heading}>
          {popData?.showEmail
            ? t("Auth.Email")
            : t("DynamicTemplate.SelectTemplate")}
        </h1>
        <Divider customClass={styles.dividerStyle} />

        <div className={styles.container}>
          <form
            noValidate
            className={styles.formContainer}
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className={styles.labelFieldContainer}>
              <label
                htmlFor={SETTLED_INVOICE_PATIENT_EMAIL}
                className={styles.labelText}
              >
                {t("DynamicTemplate.SelectTemplate")}
                <span className="asterick">*</span>
              </label>
              <div className={styles.fieldErrorContainer}>
                <Select
                  className={styles.selectInputField}
                  isSearchable={true}
                  options={popData?.options || []}
                  value={watch(VIEW_ID)}
                  placeholder={
                    t("DynamicTemplate.SelectTemplate") || "Select Template"
                  }
                  {...register(VIEW_ID, settledInvoiceEmailValidator[VIEW_ID])}
                  onChange={(e: any) => {
                    setValue(VIEW_ID, e);
                    trigger(VIEW_ID);
                  }}
                  maxMenuHeight={190}
                  styles={reactSelectStyle}
                />

                <div className={styles.errorContainer}>
                  <span className={styles.extraSpan}></span>
                  {errors[VIEW_ID] && (
                    <p className="dashboardFormError">
                      {errors[VIEW_ID].message as any}
                    </p>
                  )}
                </div>
              </div>
            </div>
            {popData?.showEmail && (
              <div className={styles.labelFieldContainer}>
                <label
                  htmlFor={SETTLED_INVOICE_PATIENT_EMAIL}
                  className={styles.labelText}
                >
                  {t("Auth.Email")}
                  <span className="asterick">*</span>
                </label>
                <div className={styles.fieldErrorContainer}>
                  <input
                    type="email"
                    placeholder={t("Auth.Enter email") || "Enter email"}
                    className={styles.inputField}
                    {...register(
                      SETTLED_INVOICE_PATIENT_EMAIL,
                      settledInvoiceEmailValidator[
                        SETTLED_INVOICE_PATIENT_EMAIL
                      ]
                    )}
                    onChange={(e) => trimValue(e)}
                  />

                  <div className={styles.errorContainer}>
                    <span className={styles.extraSpan}></span>
                    {errors[SETTLED_INVOICE_PATIENT_EMAIL] && (
                      <p className="dashboardFormError">
                        {errors[SETTLED_INVOICE_PATIENT_EMAIL].message as any}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            )}

            <Button
              title={t("Common.Submit") || "Submit"}
              customClass={styles.buttonStyle}
            />
          </form>
        </div>
      </div>
    </>
  );
};

export default SettledEmailPopup;
