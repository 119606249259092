import { FC, useState, useEffect } from 'react'
import styles from './addgrnpopup.module.scss'
import { CloseIcon } from '../../../components/common/svg-components'
import { colors } from '../../../constants/color'
import Divider from '../../../components/common/divider/Divider'
import Button from '../../../components/common/button/Button'
import TableV2 from '../../../components/common/table/tableV2/TableV2'
import { addGrnHeaderData } from '../../../constants/table-data/addGrnTableData'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { requestGenerator } from '../../../utils/payloadGenerator'
import SearchDropDown from '../../../components/common/search-dropdown/SearchDropDown'
import {
  getAllInventoryAllPo,
  grnAdd,
} from '../../../redux/features/inventory-request/inventoryRequestAsyncActions'
import { getAllSupplierDetails } from '../../../redux/features/purchase-invoice/purchaseAsyncActions'
import Loader from '../../../components/common/spinner/Loader'
import {
  clearInventoryPurchaseOrderData,
  emptyAllPoData,
} from '../../../redux/features/inventory-request/inventoryRequestSlice'
import AttachfilesV2 from '../../../components/common/attach-files/AttachfilesV2'
import { fileType } from '../../../interfaces/interfaces'
import { Input } from '../../../components/common/input/input'
import { CustomModal } from '../../../components/common/custom-modal/modal'
import { useLocation } from 'react-router-dom'
import { trimValue } from '../../../utils/utils'
import { useTranslation } from 'react-i18next'

interface INewGrnPopup {
  handleClose?: any
  handleChildClick?: any
  handleDepartment?: any
  popData: any
  // supplier_id?: any;
  name?: any
}

const AddGrnPopup: FC<INewGrnPopup> = ({
  handleClose,
  handleChildClick,
  handleDepartment,
  popData,
  // supplier_id,
}) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [searchString, setSearchString] = useState('')
  const [supplierId, setSupplierId] = useState<any>({
    supplier_id: '',
    date: '',
    doc_no: '',
    selectedSupplierName: '',
  })
  const [selectedSupplierName, setSelectedSupplierName] = useState('')
  const [nameSelected, setNameSelected] = useState(false)
  const [supplierDocNo, setSupplierDocNo] = useState('')
  const [receivedDate, setReceivedDate] = useState('')
  const [showDocNoError, setShowDocNoError] = useState(false)
  const [showDateError, setShowDateError] = useState(false)
  const [attachments, setAttachments] = useState<fileType[]>([])
  const [grnIdGenerated, setGrnIdGenerated] = useState('')
  const [grnGeneratePopup, setGrnGeneratePopup] = useState(false)
  const { suppliersInfo } = useAppSelector((state) => state.purchaseInvoice)
  const { branchData } = useAppSelector((state) => state.login)
  const location = useLocation()

  const { isLoading, selectedPurchaseOrderList } = useAppSelector(
    (state) => state.inventoryRequest
  )

  const filterDataWithPoNo = selectedPurchaseOrderList?.filter((item: any) =>
    item?.hasOwnProperty('po_no')
  )
  const filterDataWithOutPoNo = selectedPurchaseOrderList?.filter(
    (item: any) => !item?.hasOwnProperty('po_no')
  )
  const handlePendingPOClick = (item: any) => {
    if (supplierId.supplier_id && selectedSupplierName) {
      handleDepartment(supplierId.supplier_id, selectedSupplierName)
    } else {
      console.error('Supplier ID is missing or invalid.')
    }
  }
  useEffect(() => {
    if (searchString === '') {
      setNameSelected(false)
    }
  }, [searchString])

  useEffect(() => {
    let requestData = {
      status: ['INITIATED', 'PARTIAL'],
      supplier_id: popData?.supplier_id,
      isPOItem: true,
    }
    dispatch(getAllInventoryAllPo(requestGenerator(requestData)))
  }, [dispatch])

  useEffect(() => {
    let data = {
      name: searchString,
    }
    dispatch(getAllSupplierDetails(requestGenerator(data)))
  }, [dispatch, searchString])

  const handleAdd = () => {
    let payloadData = {
      supplier_name: selectedSupplierName,
      supplier_id: popData?.supplier_id,
      receive_date: receivedDate,
      store_id:
        location?.pathname === '/mainstore'
          ? branchData?.main_store?.[0]?._id
          : branchData?.pharmacy_store?.[0]?._id,
      supplier_doc_no: supplierDocNo,
      file: attachments,
      received_items: filterDataWithPoNo?.map((item: any) => {
        return {
          po_id: item?.po_id,
          po_item_id: item?.po_item_id,
          item_id: item?.item_id,
          name: item?.name,
          qty: item?.delievered_qty,
          price: item?.requested_price,
          total_amount: item?.requested_price * item?.delievered_qty,
          base_unit_type_id: item?.base_unit_type?._id,
          req_unit_type_id: item?.req_unit_type?._id,
        }
      }),
      additional_items: filterDataWithOutPoNo?.map((items: any) => {
        return {
          item_id: items?.item_id,
          name: items?.name,
          qty: items?.delievered_qty,
        }
      }),
    }
    dispatch(grnAdd(requestGenerator(payloadData))).then((e) => {
      if (e.type === 'inventory/grnAdd/fulfilled') {
        // handleClose();
        setGrnIdGenerated(e?.payload?.[0]?.doc_id)
        setGrnGeneratePopup(true)
        dispatch(emptyAllPoData())
        setShowDocNoError(false)
        setShowDateError(false)
      }
    })

    if (!supplierDocNo) {
      setShowDocNoError(true)
    }

    if (!receivedDate) {
      setShowDateError(true)
    }
  }
  useEffect(() => {
    dispatch(clearInventoryPurchaseOrderData())
  }, [dispatch])
  return (
    <>
      <CustomModal
        showModal={grnGeneratePopup}
        closeModal={() => setGrnGeneratePopup(false)}
        title={t('MainStore.GRN ID') || 'GRN ID'}
        zIndex="4"
        overlayzIndex="3"
        width="40%"
        height="220PX"
      >
        <div className={styles.content}>
          {t('MainStore.GRN ID Created')} : {grnIdGenerated}
        </div>
      </CustomModal>
      {isLoading && <Loader />}
      <div
        className={styles.newGrnPopupMainContainer}
        onClick={(e) => e.stopPropagation()}
      >
        <CloseIcon
          customClass={styles.closeIconStyle}
          fillColor={colors.green1}
          handleClick={() => handleClose()}
        />
        <div className={styles.paymentContainer}>
          <p className={styles.title}> {t('MainStore.Add GRN')} </p>
          <Divider customClass={styles.dividerStyle} />
        </div>
        <div className={styles.searchContainer}>
          <div className={styles.searchDropDownContainer}>
            <span className={styles.text}>{t('MainStore.Supplier Name')}</span>
            <SearchDropDown
              searchString={searchString}
              setSearchString={setSearchString}
              dropdownDataToSee={suppliersInfo}
              dropDownKeyName="name"
              customClass={styles.search}
              handleClick={(item: any, setVal: any, setShowDropdown: any) => {
                setVal(item?.name)
                setSupplierId({ ...supplierId, supplier_id: item?._id })
                setShowDropdown(false)
                setNameSelected(true)
                setSelectedSupplierName(item?.name)
              }}
              placeholder={
                t('MainStore.SearchSupplier') || 'Search by supplier name'
              }
            />
          </div>
          <div style={{ flexBasis: '33%' }}>
            <Input
              labelText={t('MainStore.GRN No') || 'GRN No'}
              placeholder="XXXXXX"
              disabled={true}
              inlineClass={styles.disabledField}
            />
          </div>
          <div style={{ flexBasis: '33%' }}>
            <Input
              labelText={t('ViewAppointment.Date') || 'Date'}
              requiredField={true}
              showErrors={showDateError}
              errorMessage="Date is required."
              type="date"
              value={receivedDate}
              onChange={(e) => setReceivedDate(e.target.value)}
              customClass={styles.datePicker}
            />
          </div>
        </div>

        <div className={styles.searchContainer2}>
          <div>
            <Input
              labelText={t('MainStore.SUPNo') || "Supplier's Grn No"}
              requiredField={true}
              showErrors={showDocNoError}
              errorMessage={`${`Supplier's Grn No is required.`}`}
              type="text"
              value={supplierDocNo}
              onChange={(e) => {
                trimValue(e)
                setSupplierDocNo(e.target.value)
              }}
              placeholder={t('MainStore.AddGrnPlac') || 'Add grn no'}
            />
          </div>
          <div className={styles.attachmentContainer}>
            <label className={styles.labelText}>
              {t('Common.Attachments')}
            </label>
            <div className={styles.fieldErrorContainer}>
              <AttachfilesV2
                isMultiSelect={true}
                fileKey="name"
                attachments={attachments}
                setAttachments={setAttachments}
                customClassFileName={styles.fileNameStyle}
                customAttachFileName={styles.attachedFile}
              />
            </div>
          </div>
        </div>
        <div className={styles.btnContainer}>
          <Button
            title={t('MainStore.Pending PO') || 'Pending PO'}
            handleClick={handlePendingPOClick}
            disable={!nameSelected}
          />
          <Button
            title={t('MainStore.Additional Items') || 'Additional Items'}
            handleClick={handleChildClick}
            disable={!filterDataWithPoNo?.length}
          />
        </div>
        <div className={styles.table}>
          <TableV2
            tableHeaderData={addGrnHeaderData}
            tableRowData={selectedPurchaseOrderList}
            active={false}
          />
        </div>
        <div className={styles.btn}>
          <Button
            title={t('Common.Submit') || 'Submit'}
            handleClick={handleAdd}
            disable={
              !supplierDocNo?.length ||
              !receivedDate?.length ||
              !selectedPurchaseOrderList?.length
            }
          />
        </div>
      </div>
    </>
  )
}

export default AddGrnPopup
