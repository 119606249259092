import { useEffect, useState } from "react";
import PreBuildReportBuilder from "./PreBuildReportBuilder";
import DynamicReportBuilder from "./DynamicReportBuilder";
import { Label } from "../../components/common/label";
import Select from "react-select";
import { DropdownIndicator } from "../../components/common/dropdown-indicator/DropdownIndicator";
import { reportType, typeOfReport } from "./data";
import styles from "./ReportBuilder.module.scss";
import { t } from "i18next";
import { ReportType } from "../../constants/data";
import { useAppSelector } from "../../hooks";

const ReportBuilder = () => {
  const [selectedReportType, setSelectedReportType] = useState<any>(
    typeOfReport[1]
  );
  const [baseType, setBaseType] = useState<any>("");
  const [selectedBaseType, setSelectedBaseType] = useState<any>("");
  const [reportTypeList, setreportTypeList] = useState<any>([]);
  const [reportTypeData, setReportTypeData] = useState<any[]>([]);
  const { branchData } = useAppSelector((state) => state.login);

  console.log(
    "branchData?.role_id?.reports_access",
    branchData?.role_id?.reports_access
  );
  useEffect(() => {
    let tempArr: any[] = [];
    branchData?.role_id?.reports_access &&
      branchData?.role_id?.reports_access?.length > 0 &&
      branchData?.role_id?.reports_access?.forEach((item: any) => {
        const type: any = reportType?.find(
          (repType: any) => repType?.value === item
        );
        if (type) {
          tempArr.push(type);
        }
      });
    setReportTypeData(tempArr);
    const list: any = [];

    for (let i = 0; i < tempArr?.length; i++) {
      if (!list.includes(tempArr[i]?.type)) {
        list.push(tempArr[i]?.type);
      }
    }
    setBaseType(list);
  }, []);

  useEffect(() => {
    const obj = reportTypeData?.filter((item) => {
      return item?.type === selectedBaseType?.value;
    });
    setreportTypeList(obj);
  }, [selectedBaseType]);

  return (
    <>
      <div className={styles.reportBuilderContainer}>
        <div className={styles.reportTypeContainer}>
          <div className={styles.inputFieldContainer}>
            <Label
              labelText={t("MobileAppConfiguration.Type")}
              requiredField={true}
              flexBasis="10%"
            />
            <div className={styles.inlineItems}>
              <Select
                className={styles.selectInputField}
                placeholder={`Select Type`}
                closeMenuOnSelect={true}
                value={selectedReportType}
                onChange={(e: any) => {
                  setSelectedReportType(e);
                }}
                components={{ DropdownIndicator }}
                options={typeOfReport}
                isSearchable={false}
                maxMenuHeight={200}
              />
            </div>
          </div>
        </div>

        {selectedReportType?.value === "PRE_BUILD_REPORT" && (
          <div className={styles.baseReportTypeContainer}>
            <Label
              labelText={"Base Type"}
              requiredField={true}
              flexBasis="10%"
            />
            <div className={styles.inlineItems}>
              <Select
                className={styles.selectInputField}
                placeholder={"Select Base Type"}
                closeMenuOnSelect={true}
                onChange={(e: any) => {
                  setSelectedBaseType(e);
                }}
                components={{ DropdownIndicator }}
                options={baseType?.map((item: any) => {
                  return {
                    label: item,
                    value: item,
                  };
                })}
                isSearchable={false}
                maxMenuHeight={200}
              />
            </div>
          </div>
        )}

        <div className={styles.MainTypeContainer}>
          {selectedReportType?.value === "PRE_BUILD_REPORT" && (
            <PreBuildReportBuilder reportTypes={reportTypeList} />
          )}
        </div>

        <div className={styles.MainTypeContainer}>
          {selectedReportType?.value === "DYNAMIC_REPORT" && (
            <DynamicReportBuilder />
          )}
        </div>
      </div>
    </>
  );
};

export default ReportBuilder;
