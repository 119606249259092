import { useTranslation } from 'react-i18next'
import { Label } from '../../components/common/label'
import styles from './upayConfig.module.scss'
import Button from '../../components/common/button/Button'
import { IUPAYConfiguration } from '../../interfaces/interfaces'
import { useForm } from 'react-hook-form'
import {
  ACCESS_KEY,
  IBAN,
  MERCHANT_ID,
  UPAY_PASSWORD,
  USERNAME,
} from './upayConstant'
import { upayValidators } from '../../form-validators/upayValidators'
import { useAppDispatch, useAppSelector } from '../../hooks'
import Loader from '../../components/common/spinner/Loader'
import { useEffect } from 'react'
import {
  getUpayConfig,
  updateUpayConfig,
} from '../../redux/features/manage-user/ManageUserAsynActions'
import { requestGenerator } from '../../utils/payloadGenerator'
import { GET_UPAY_CONFIG_TYPE } from '../../constants/asyncActionsType'
import { trimValue } from '../../utils/utils'
const UpayConfiguration = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { isLoading } = useAppSelector((state) => state.manageUser)
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IUPAYConfiguration>({})

  const onSubmit = (data: IUPAYConfiguration) => {
    dispatch(updateUpayConfig(requestGenerator({ upay: data })))
  }
  useEffect(() => {
    dispatch(getUpayConfig(requestGenerator({}))).then((e) => {
      if (e.type === `${GET_UPAY_CONFIG_TYPE}/fulfilled`) {
        reset(e.payload)
      }
    })
  }, [dispatch, reset])
  return (
    <>
      {isLoading ? <Loader /> : ''}
      <form className={styles.mainContainer} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.formFields}>
          <div className={styles.inputFieldContainer}>
            <Label
              labelText={t('upay.MerchanidLabel')}
              requiredField={true}
              flexBasis="45%"
            />
            <div className={styles.inputField}>
              <input
                className={styles.inputTextField}
                type="text"
                placeholder={t('upay.MerchantIdPlac') || 'Enter Merchant Id'}
                {...register(MERCHANT_ID, upayValidators[MERCHANT_ID])}
                onChange={(e: any) => {
                  trimValue(e)
                }}
              />
              {errors[MERCHANT_ID] && (
                <p className={styles.formError}>
                  {errors[MERCHANT_ID].message as any}
                </p>
              )}
            </div>
          </div>
          <div className={styles.inputFieldContainer}>
            <Label
              labelText={t('InvoiceService.Username')}
              requiredField={true}
              flexBasis="45%"
            />
            <div className={styles.inputField}>
              <input
                className={styles.inputTextField}
                type="text"
                placeholder={t('upay.UserNamePlac') || 'Enter Username'}
                {...register(USERNAME, upayValidators[USERNAME])}
                onChange={(e: any) => {
                  trimValue(e)
                }}
              />
              {errors[USERNAME] && (
                <p className={styles.formError}>
                  {errors[USERNAME].message as any}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className={styles.formFields}>
          <div className={styles.inputFieldContainer}>
            <Label
              labelText={t('Auth.Password')}
              requiredField={true}
              flexBasis="45%"
            />
            <div className={styles.inputField}>
              <input
                className={styles.inputTextField}
                type="text"
                placeholder={t('Auth.Enter password') || 'Enter password'}
                {...register(UPAY_PASSWORD, upayValidators[UPAY_PASSWORD])}
                onChange={(e: any) => {
                  trimValue(e)
                }}
              />
              {errors[UPAY_PASSWORD] && (
                <p className={styles.formError}>
                  {errors[UPAY_PASSWORD].message as any}
                </p>
              )}
            </div>
          </div>
          <div className={styles.inputFieldContainer}>
            <Label
              labelText={t('upay.AccessKeyLabel')}
              requiredField={true}
              flexBasis="45%"
            />
            <div className={styles.inputField}>
              <input
                className={styles.inputTextField}
                type="text"
                placeholder={t('upay.AccessKeyPlac') || 'Enter Access Key'}
                {...register(ACCESS_KEY, upayValidators[ACCESS_KEY])}
                onChange={(e: any) => {
                  trimValue(e)
                }}
              />
              {errors[ACCESS_KEY] && (
                <p className={styles.formError}>
                  {errors[ACCESS_KEY].message as any}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className={styles.formFields}>
          <div className={styles.inputFieldContainer}>
            <Label
              labelText={t('upay.IBANLabel')}
              requiredField={true}
              flexBasis="45%"
            />
            <div className={styles.inputField}>
              <input
                className={styles.inputTextField}
                type="text"
                placeholder={t('upay.IBANPlac') || 'Enter IBAN'}
                {...register(IBAN, upayValidators[IBAN])}
                onChange={(e: any) => {
                  trimValue(e)
                }}
              />
              {errors[IBAN] && (
                <p className={styles.formError}>
                  {errors[IBAN].message as any}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className={styles.btnContainer}>
          <Button type="submit" title={t('Common.Submit') || 'Submit'} />
        </div>
      </form>
    </>
  )
}

export default UpayConfiguration
