import { FC, useState } from "react";
import styles from "./LabPayment.module.scss";

import Button from "../../../components/common/button/Button";
import {
  ExportIcon,
  PrintIcon,
} from "../../../components/common/svg-components";
import TableV2 from "../../../components/common/table/tableV2/TableV2";
import { invoicePaymentHeaderData } from "../../../constants/table-data/invoicePaymentTableData";
import ScribeDialog from "../../doctor-diagnosis/diagnosis/scribe-dialog/ScribeDialog";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import PaymentLinkModal from "../../../components/common/modal/payment-link-modal/payment-link/PaymentLinkModal";
import AddPaymentModeModal from "../../../components/common/modal/receipt-add-payment-mode-modal/AddPaymentModeModal";
import Popup from "../../../components/common/popup/Popup";
import {
  clearInvoicePatientData,
  savePaymentMode,
  setAmountData,
} from "../../../redux/features/invoice-module/invoiceSlice";
import { requestGenerator } from "../../../utils/payloadGenerator";
import OnlinePaymentModal from "../../../components/common/modal/online-payment-modal/OnlinePaymentModal";
import { generateUpayLink } from "../../../redux/features/invoice-module/invoiceAsynActions";
import Loader from "../../../components/common/spinner/Loader";
import {
  clearLabInvoicePatientData,
  setLabInvoiceLoading,
} from "../../../redux/features/lab-invoice/labInvoiceSlice";
import {
  allowedNumberOfDigitsAfterDecimal,
  handleCalculateTotalAmountForPaymentMode,
  requestToDownloadPdf,
  setInvoiceReceiptTemplateOptions,
} from "../../../utils/utils";
import { useNavigate } from "react-router-dom";
import InvoiceConformationModal from "../../../components/common/modal/invoice-conformation-modal/InvoiceConformationModal";
import moment from "moment";
import { SETTLED_INVOICE_VIEW } from "../../../config/config";
import { t } from "i18next";
import SettledEmailPopup from "../../../components/common/modal/settled-email-popup/SettledEmailPopup";
import { getAllTemplates } from "../../../redux/features/dynamic-template/dynamicTemplateAsyncActions";
import { GET_TEMPLATE_TYPE } from "../../../constants/asyncActionsType";
import { clearTemplateData } from "../../../redux/features/dynamic-template/dynamicTemplateSlice";
import useCurrency from "../../../hooks/useCurrency";

interface IInvoicePayment {}

const InvoicePayment: FC<IInvoicePayment> = () => {
  const [showScribeDialog, setShowScribeDialog] = useState<boolean>(false);
  const [amountModal, setamountModal] = useState<boolean>(false);
  const [showPaymentMode, setshowPaymentMode] = useState(false);
  const [showOnlinePaymentModeModal, setShowOnlinePaymentModeModal] =
    useState<boolean>(false);
  const [onlinePaymentLinkData, setOnlinePaymentLinkData] = useState<any>({});
  const [paymentLinkData, setpaymentLinkData] = useState<any>({});
  const [invoiceConformationModal, setinvoiceConformationModal] =
    useState<boolean>(false);
  const [showSettleEmailPopup, setshowSettleEmailPopup] = useState(false);
  const [templateOptions, setTemplateOptions] = useState<any[]>([]);

  const { isLoading, generatedInvoiceObject, paymentModeData } = useAppSelector(
    (state) => state.invoice
  );

  const { labInformationData, invoiceLoader } = useAppSelector(
    (state) => state.labInvoice
  );

  const { patientPaymentInfo } = useAppSelector((state) => state.labInvoice);
  const { masterPaymentModeData } = useAppSelector((state) => state.login);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { formatCurrency } = useCurrency();
  const handlePaymentProcess = () => {
    setamountModal(!amountModal);
    // if (paymentModeData?.length > 0) {
    //   const findUpayPayment = paymentModeData?.find(
    //     (item: any) => item?._id === 8
    //   )
    //   setpaymentLinkData(findUpayPayment)
    // } else {
    //   setpaymentLinkData({})
    // }
  };

  const handlePaymentMode = () => {
    setshowPaymentMode(!showPaymentMode);
  };

  const addPaymentMode = (item: any) => {
    setshowPaymentMode((prevState) => !prevState);
    let requestPayload: any = {
      payment_mode: item?.payment_mode_name,
      payment_label: item?.payment_mode_label,
      amount: item?.amount ?? 0,
      _id: item?.payment_mode_id,
    };
    dispatch(savePaymentMode(requestPayload));
  };

  // paymentLinkPopup modal close

  const handlePaymentLinkModalClose = () => {
    setamountModal(!amountModal);
    setpaymentLinkData({});
  };

  const addUpayData = (item: any) => {
    setpaymentLinkData(item);
    dispatch(setAmountData(item));
    setamountModal(!amountModal);
  };

  const handleGenerateLink = () => {
    const modifyPaymentModeData = paymentModeData?.map((item: any) => {
      const { _id, ...rest } = item;
      return rest;
    });
    const requestPayload = {
      invoice_no: patientPaymentInfo?.invoice_no,
      partial_payment_array: modifyPaymentModeData,
    };

    dispatch(generateUpayLink(requestGenerator(requestPayload))).then(
      (e: any) => {
        if (e.type === "invoice/getGenerateUpayLink/fulfilled") {
          if (e?.payload?.link) {
            const onlinePaymentLinkData = {
              patient_default_branch_id:
                labInformationData?.patient_default_branch_id,
              name: labInformationData?.name,
              phone: labInformationData?.phone,
              email: labInformationData?.email,
              invoice_no: patientPaymentInfo?.invoice_no,
              transaction_id: e?.payload?.transaction_id,
              amount: paymentLinkData?.amount || 0,
              link: e?.payload?.link,
            };
            // dispatch(clearLabInvoicePatientData())
            setShowOnlinePaymentModeModal(!showOnlinePaymentModeModal);
            setOnlinePaymentLinkData(onlinePaymentLinkData);
          } else {
            setinvoiceConformationModal(true);

            // dispatch(clearLabInvoicePatientData())
            // dispatch(updatedNewPaymentAmountArray([]))
            // navigate('/invoice/labinformation')
          }
        }
      }
    );

    // static
    // if (onlinePaymentLinkData?.link !== null) {
    //   setShowOnlinePaymentModeModal(!showOnlinePaymentModeModal);
    //   setOnlinePaymentLinkData(onlinePaymentLinkData);
    // }
  };

  const invoiceOnlinePaymentModalClose = () => {
    setShowOnlinePaymentModeModal(!showOnlinePaymentModeModal);
    setOnlinePaymentLinkData({});
  };

  const handlePadStart = (invoiceNo: any) => {
    let padstartInvoiceNumber = invoiceNo;
    let invoice_number = String(padstartInvoiceNumber).padStart(6, "0");
    return invoice_number;
  };

  const handleExit = () => {
    dispatch(clearLabInvoicePatientData());
    dispatch(clearInvoicePatientData());
    navigate("/invoice/labinformation");
  };

  const setPdfLoader = (loader: boolean) => {
    dispatch(setLabInvoiceLoading(loader));
  };

  const sendEmail = (data: any) => {
    const requestedData = {
      _id: data?._id,
      email: data?.email,
      is_email: false,
      offset: moment()?.utcOffset(),
      view_id: data?.view_id,
    };
    requestToDownloadPdf(
      SETTLED_INVOICE_VIEW,
      requestGenerator(requestedData),
      "s",
      setPdfLoader,
      "print"
    );
    handleExit();
    dispatch(clearTemplateData());
  };

  return (
    <>
      {isLoading || invoiceLoader ? <Loader /> : ""}

      {amountModal && (
        <Popup
          Children={PaymentLinkModal}
          popData={paymentLinkData}
          handleClose={handlePaymentLinkModalClose}
          setModelOpenClose={addUpayData}
        />
      )}

      {showPaymentMode && (
        <Popup
          Children={AddPaymentModeModal}
          popData={masterPaymentModeData}
          handleClose={handlePaymentMode}
          handleYes={addPaymentMode}
        />
      )}

      {/* Scribe Dialog Dependencies */}
      {showScribeDialog === true && (
        <ScribeDialog
          handleClose={() => setShowScribeDialog(false)}
          setScribeDropdownShow={false}
          customClassContainer={styles.customClassContainer}
          customClassCanvasContainer={styles.customClassCanvasContainer}
        />
      )}

      {showOnlinePaymentModeModal && (
        <Popup
          Children={OnlinePaymentModal}
          popData={onlinePaymentLinkData}
          handleClose={() => invoiceOnlinePaymentModalClose()}
          setModelOpenClose={setShowOnlinePaymentModeModal}
          message="/invoice/labinformation"
        />
      )}
      {invoiceConformationModal && (
        <Popup
          Children={InvoiceConformationModal}
          // popData={searchModalData}
          handleClose={() =>
            setinvoiceConformationModal(!invoiceConformationModal)
          }
          handleNo={handleExit}
          setModelOpenClose={() => {
            dispatch(
              getAllTemplates(
                requestGenerator({ type: "invoice", page: 1, pageSize: 1000 })
              )
            ).then((e) => {
              if (e.type === `${GET_TEMPLATE_TYPE}/fulfilled`) {
                setTemplateOptions(
                  setInvoiceReceiptTemplateOptions(e.payload?.data)
                );
                setshowSettleEmailPopup(true);
              } else {
                const requestedData = {
                  _id: patientPaymentInfo?._id,
                  email: labInformationData?.email,
                  is_email: false,
                  offset: moment()?.utcOffset(),
                };
                requestToDownloadPdf(
                  SETTLED_INVOICE_VIEW,
                  requestGenerator(requestedData),
                  "s",
                  setPdfLoader,
                  "print"
                );
                handleExit();
              }
            });
          }}
          heading={t("InvoicePayment.PrintInvoice") || "Print Invoice"}
          message={
            t("InvoicePayment.DoYouPrint") || "Do you want to print invoice?"
          }
        />
      )}

      {showSettleEmailPopup && (
        <Popup
          Children={SettledEmailPopup}
          handleClose={() => setshowSettleEmailPopup(!showSettleEmailPopup)}
          setModelOpenClose={() =>
            setshowSettleEmailPopup(!showSettleEmailPopup)
          }
          popData={{
            _id: patientPaymentInfo?._id,
            email: labInformationData?.email,
            showEmail: false,
            options: templateOptions,
          }}
          handleYes={(data: any) => sendEmail(data)}
        />
      )}

      <form className={styles.mainContainer}>
        <div className={styles.mainServiceContainer}>
          {/*invoiceInfoContainer*/}
          <div className={styles.invoiceInfoContainer}>
            <div className={styles.invoiceNoContainer}>
              <p className={styles.invoiceTextStyle}>
                {t("InvoicePayment.Invoice No")} :
              </p>
              <p className={styles.invoiceStyle}>
                {/* {patientPaymentInfo?.invoice_no?.padStart(6,"0")} */}
                {
                  //patientPaymentInfo?.invoice_no ? handlePadStart(patientPaymentInfo?.invoice_no) : ''
                  patientPaymentInfo?.invoice_no
                    ? patientPaymentInfo?.invoice_no
                    : ""
                }
              </p>
            </div>

            {patientPaymentInfo?.fixed_file_number ? (
              <div className={styles.fileNoContainer}>
                <p className={styles.invoiceFileTextStyle}>
                  {t("LabJob.Fixed_File_No")} :
                </p>
                <p className={styles.invoiceFileStyle}>
                  {handlePadStart(patientPaymentInfo?.fixed_file_number)}
                </p>
              </div>
            ) : (
              ""
            )}
          </div>

          {/* payment container */}
          <div className={styles.paymentMainContainer}>
            <div className={styles.paymentContainer}>
              {/* {patientPaymentInfo?.insurance_claim_amount && (
                <>
                  <div className={styles.amountStyle}>
                    Amount to be Claimed:{" "}
                    {patientPaymentInfo?.insurance_claim_amount
                      ? patientPaymentInfo?.insurance_claim_amount
                      : 0}
                  </div>
                </>
              )} */}

              <div className={styles.outstandingStyle}>
                {t("InvoicePayment.Outstanding Amount")}{" "}
                {/* {patientPaymentInfo?.invoice_no &&
                  patientPaymentInfo?.outstanding_amount
                  ? allowedNumberOfDigitsAfterDecimal(
                    patientPaymentInfo?.outstanding_amount,
                    3
                  )
                  : 0} */}
                {formatCurrency(patientPaymentInfo?.outstanding_amount) || 0}
              </div>
              {/* <div className={styles.advanceDueStyle}>
                Advance Due:{' '}
                {patientPaymentInfo?.paid_amount
                  ? patientPaymentInfo?.paid_amount
                  : 0}
              </div> */}
              {patientPaymentInfo?.isOutPatient === false ? (
                <div className={styles.advanceDueStyle}>
                  {t("Common.Advance Due")}{" "}
                  {/* {patientPaymentInfo?.invoice_no &&
                  labInformationData?.advance_amount
                    ? allowedNumberOfDigitsAfterDecimal(
                        labInformationData?.advance_amount,
                        3
                      )
                    : 0} */}
                  {formatCurrency(labInformationData?.advance_amount) || 0}
                </div>
              ) : (
                ""
              )}

              {/* <div className={styles.copayDetailContainer}>
                <div className={styles.copayPercentageContainer}>
                  <label className={styles.labelText}>Co-pay %</label>
                  <input
                    type="text"
                    className={styles.inputFieldDisable}
                    value={patientPaymentInfo?.co_pay_percentage ?? ""}
                    disabled={true}
                  />
                </div>
                <div className={styles.copayAmountContainer}>
                  <label className={styles.labelText}>Co-pay Amount</label>
                  <input
                    type="text"
                    className={styles.inputFieldDisable}
                    value={patientPaymentInfo?.co_pay_amount ?? ""}
                    disabled={true}
                  />
                </div>
              </div> */}

              <div className={styles.buttonContainer}>
                <Button
                  type="button"
                  title={
                    t("InvoicePayment.Add Payment Mode") || "Add Payment Mode"
                  }
                  customClass={styles.addPaymentStyle}
                  handleClick={handlePaymentMode}
                  disable={
                    Number(
                      handleCalculateTotalAmountForPaymentMode(paymentModeData)
                        .amount
                    ) >= Number(generatedInvoiceObject?.outstanding_amount)
                      ? true
                      : false
                  }
                />
                <div>
                  <Button
                    type="button"
                    title={
                      t("InvoicePayment.ESign") || `Customer's E-Signature`
                    }
                    customClass={styles.eSignButtonStyle}
                    handleClick={() => setShowScribeDialog(true)}
                    disable={true}
                  />
                  {/* <ShareIcon
                    fillColor="#0E26A3"
                    customClass={styles.shareIcon}
                  /> */}
                </div>
                <PrintIcon customClass={styles.printIcon} />
                <ExportIcon customClass={styles.exportIcon} />
              </div>

              {/* <div className={styles.scribeContainer}>
                <p className={styles.eSignText}>Customer's E-Signature</p>
                <div className={styles.scribeAttachments}>
                  {!scribeNotesArr ||
                  scribeNotesArr === undefined ||
                  scribeNotesArr === "" ||
                  scribeNotesArr === null ||
                  scribeNotesArr?.length === 0
                    ? defaultNoAttachmentArr.map((_element: any) => {
                        return (
                          <>
                            <div
                              className={styles.scribeSingleAttachmentWrapper}
                            >
                              <div
                                className={styles.scribeSingleAttachment}
                                key={_element.id}
                              >
                                <img src={_element.imageUrl} />
                              </div>
                            </div>
                          </>
                        );
                      })
                    : scribeNotesArr.map((_element: any) => {
                        return (
                          <>
                            <div
                              className={styles.scribeSingleAttachmentWrapper}
                              key={_element.id}
                            >
                              <div
                                className={styles.scribeSingleAttachment}
                                onClick={() =>
                                  handleScribeImagesAndNotesPreviewDialogOpen(
                                    _element
                                  )
                                }
                              >
                                <img src={_element.imageUrl} />
                              </div>
                              <div
                                className={styles.closeIconContainer}
                                onClick={() =>
                                  dispatch(removeScribeNotesData(_element))
                                }
                              >
                                <RemoveTagCloseIcon fillColor="#02BF90" />
                              </div>
                            </div>
                          </>
                        );
                      })}
                </div>
              </div> */}
            </div>

            <div className={styles.paymentDetailContainer}>
              <p
                className={styles.linkGenreateContainer}
                onClick={handlePaymentProcess}
              >
                {t("InvoicePayment.ClickURL")}
              </p>
              <div className={styles.tableContainer}>
                <TableV2
                  tableHeaderData={invoicePaymentHeaderData}
                  tableRowData={paymentModeData}
                  active={false}
                />
              </div>

              <div className={styles.submitPaymentContainer}>
                <Button
                  type="button"
                  title={t("Common.Submit") || "Submit"}
                  customClass={styles.settle8Button}
                  handleClick={handleGenerateLink}
                  disable={
                    Number(
                      handleCalculateTotalAmountForPaymentMode(paymentModeData)
                        .amount
                    ) > Number(generatedInvoiceObject?.outstanding_amount)
                      ? true
                      : paymentModeData?.length === 0
                      ? true
                      : paymentModeData?.some((item: any) => !item?.amount)
                      ? true
                      : false
                  }
                />

                <Button
                  title={t("ActionBar.Exit") || "Exit"}
                  type="button"
                  customClass={styles.backButton}
                  // handleClick={() => navigate("/invoice/labservices")}
                  handleClick={handleExit}
                />
              </div>
            </div>
          </div>
        </div>

        {/* action bar */}
        {/* <div className={styles.mainContainerFormActionSidebar}>
          <div className={styles.sidebarData}>
            {invoiceFormActionData?.map((item: any, index: any) => {
              return (
                <React.Fragment key={index}>
                  <div className={styles.iconStyleContainer} key={index}>
                    <item.icon
                      customClass={styles.iconStyle}
                      fillColor="#CDD4D8"
                      mouseEnter={() => setFormActionValue(index)}
                      mouseLeave={() => setFormActionValue(-1)}
                    />
                    <p className={styles.tooltiptext}>{item.name}</p>
                  </div>
                </React.Fragment>
              )
            })}
          </div>
        </div> */}
      </form>
    </>
  );
};

export default InvoicePayment;
