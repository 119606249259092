// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".agentdashboardlayout_mainContainer__QkT6g {\n  display: flex;\n}\n.agentdashboardlayout_mainContainer__QkT6g .agentdashboardlayout_tabContainer__Ek7fk {\n  width: 95%;\n}\n.agentdashboardlayout_mainContainer__QkT6g .agentdashboardlayout_floatingBarContainer__8o6Xw {\n  position: sticky;\n  top: 30px;\n}\n\n.agentdashboardlayout_floatbar__QMgun {\n  position: sticky;\n}", "",{"version":3,"sources":["webpack://./src/pages/agent/agentdashboardlayout.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;AADF;AAEE;EACE,UAAA;AAAJ;AAEE;EACE,gBAAA;EACA,SAAA;AAAJ;;AAGE;EACE,gBAAA;AAAJ","sourcesContent":["@import \"../../styles/mixin.scss\";\n\n.mainContainer {\n  display: flex;\n  .tabContainer {\n    width: 95%;\n  }\n  .floatingBarContainer {\n    position: sticky;\n    top: 30px;\n  }\n}\n  .floatbar{\n    position:sticky;    \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContainer": "agentdashboardlayout_mainContainer__QkT6g",
	"tabContainer": "agentdashboardlayout_tabContainer__Ek7fk",
	"floatingBarContainer": "agentdashboardlayout_floatingBarContainer__8o6Xw",
	"floatbar": "agentdashboardlayout_floatbar__QMgun"
};
export default ___CSS_LOADER_EXPORT___;
