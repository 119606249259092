import React, { FC, ReactNode, useState } from "react";
import styles from "./bookingAppoinment.module.scss";
import BookingConfirmationModalV2 from "../modal/booking-confirmation-modal/bookingconfirmationV2/BookingConfirmationModalV2";
import PhysioAppointmentBooking from "../../../pages/physio/page";
import { Label } from "../label";
import { CustomRadio } from "../custom-radio";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../../hooks";

interface IBookingAppoinmentProps {
  handleTitleChange?: any;
  closeModal?: any;
  handleClose?: any;
  handleOpen?: any;
  popData?: any;
  setModelOpenClose?: any;
  branchId?: any;
  customClassPopup?: any;
}
const BookingAppoinment: FC<IBookingAppoinmentProps> = ({
  handleTitleChange,
  closeModal,
  handleClose,
  handleOpen,
  popData,
  setModelOpenClose,
  branchId,
  customClassPopup,
}) => {
  const path = useLocation().pathname;
  const [selectOption, setselectOption] = useState(
    path == "/schedular" ? "Doctor" : "Room"
  );
  const { t } = useTranslation();
  return (
    <>
      <div className={styles.bookingAppoinmentContainer}>
        <div className={styles.inputFields}>
          <div className={styles.radioFieldGenderContainer}>
            <CustomRadio
              label={t("Header Title.Doctor") || "Doctor"}
              name="gender"
              value="Female"
              customLabel={styles.customRadioLabel}
              checked={selectOption === "Doctor"}
              onClick={() => {
                setselectOption("Doctor");
                handleTitleChange("Doctor");
              }}
              disabled={popData?.isUpdated}
            />
            <CustomRadio
              label={t("IPDBooking.Room") || "Room"}
              name="gender"
              value="Male"
              customLabel={styles.customRadioLabel}
              checked={selectOption === "Room"}
              onClick={() => {
                setselectOption("Room");
                handleTitleChange("Room");
              }}
              disabled={popData?.isUpdated}
            />
          </div>
        </div>
        {selectOption === "Doctor" ? (
          <BookingConfirmationModalV2
            handleClose={() => {
              if (path == "/schedular") {
                handleClose();
              } else {
                closeModal();
              }
            }}
            handleOpen={handleOpen}
            popData={popData}
            setModelOpenClose={setModelOpenClose}
            branchId={branchId}
          />
        ) : (
          <PhysioAppointmentBooking
            closeModal={() => {
              if (path == "/schedular") {
                handleClose();
              } else {
                closeModal();
              }
            }}
            handleOpen={handleOpen}
            popData={popData}
            customClass={styles.roomBookimgModelStyles}
          />
        )}
      </div>
    </>
  );
};

export default BookingAppoinment;
