import moment from 'moment'
import ToggleSwitchV2 from '../../components/common/toggle-switch/ToggleSwitchV2'
import { useAppDispatch } from '../../hooks'
import {
  getAllHl7,
  updateStatusHl7,
} from '../../redux/features/hl7/hl7AsyncActions'
import { requestGenerator } from '../../utils/payloadGenerator'
import { EditIcon } from '../../components/common/svg-components'
import { colors } from '../../constants/color'
import { useNavigate } from 'react-router-dom'
import styles from './hl7List.module.scss'
import { t } from 'i18next'

export const hl7HeaderData: any = [
  {
    Header: t('AllAttachmentHeader.NAME'),
    accessor: 'name',
  },
  {
    Header: t('hl7.CONTACT_PERSON_NAME'),
    accessor: 'contact_person_name',
    Cell: ({ row }: any) => {
      return <>{row?.original?.contact_person_name ?? '-'}</>
    },
  },
  {
    Header: t('ManageStaff.EMAIL'),
    accessor: 'email',
    Cell: ({ row }: any) => {
      return <>{row?.original?.email ?? '-'}</>
    },
  },
  {
    Header: t('ReportBuilder.CREATED_AT'),
    Cell: ({ row }: any) => {
      const convertDate = moment(row?.original?.createdAt).format('DD MMM YYYY')
      return <>{row?.original?.createdAt ? <span>{convertDate}</span> : '-'}</>
    },
  },
  {
    Header: t('hl7.MESSAGE_TYPE'),
    Cell: (props: any) => {
      return (
        <span
          className={styles.view}
          onClick={() =>
            props.onClick(props?.row?.original?.hl7messageaccesses)
          }
        >
          {t('Common.View')}
        </span>
      )
    },
  },
  {
    Header: t('RequestDiagnosis.ACTIONS'),
    Cell: (props: any) => {
      const navigate = useNavigate()

      return (
        <EditIcon
          fillColor={colors.grey4}
          handleClick={() => {
            navigate('/hl7/createhl7', {
              state: { id: props?.row?.original },
            })
          }}
        />
      )
    },
  },
  {
    Header: t('RcptTableHeader.STATUS'),
    Cell: (props: any) => {
      const dispatch = useAppDispatch()

      const handleToggle = () => {
        const payload = {
          id: props?.row?.original?._id,
          is_active: !props?.row?.original?.is_active,
          name: props?.row?.original?.name,
        }
        dispatch(updateStatusHl7(requestGenerator(payload))).then((res) => {
          if (res.type === 'hl7/updateStatus/fulfilled') {
            dispatch(getAllHl7(requestGenerator({})))
          }
        })
      }
      return (
        <>
          <ToggleSwitchV2
            isToggled={props?.row?.original?.is_active}
            handleToggle={() => handleToggle()}
          />
        </>
      )
    },
  },
]

export const messageListData: any = [
  {
    Header: t('RcptTableHeader.TYPE'),
    accessor: 'type',
  },
  {
    Header: t('hl7.IP'),
    accessor: 'ip_address',
  },
  {
    Header: t('hl7.PORT'),
    accessor: 'port',
  },
]
