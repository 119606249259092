import { FC, useState, useCallback, useEffect, useRef } from 'react'
import styles from './searchdropdown.module.scss'
import { SearchIcon } from '../svg-components'
import { debounce } from 'lodash'

interface ISearchDropDown {
  dropdown?: any
  customClass?: any
  inputCustomClass?: string
  placeholder?: string
  searchString?: any
  setSearchString?: any
  dropdownDataToSee?: any
  handleClick?: any
  keyName?: any
  dropDownKeyName?: any
  customClassdata?: any
  customClassItem?: string
  setState?: any
  customClassdropdownItem?: string
  isDisabled?: boolean
}

const SearchDropDown: FC<ISearchDropDown> = ({
  customClass,
  inputCustomClass,
  placeholder,
  setSearchString,
  dropdownDataToSee,
  handleClick,
  keyName,
  dropDownKeyName,
  customClassdata,
  customClassItem,
  setState,
  customClassdropdownItem,
  isDisabled,
}) => {
  const [val, setVal] = useState('')
  const [showDropdown, setShowDropdown] = useState(false)
  const ref = useRef<any>()
  const deb = useCallback(
    debounce((text) => setSearchString(text), 1000),
    []
  )
  const handleSearch = (text: any) => {
    setVal(text)
    setShowDropdown(true)
    deb(text)
    if (text === '') {
      if (setState) {
        setState()
      }
      setShowDropdown(false)
    }
  }

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (showDropdown && ref.current && !ref.current.contains(e.target)) {
        setShowDropdown(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [showDropdown])

  return (
    <div
      className={[
        styles.inputData,
        isDisabled ? styles.disableInput : '',
        customClass,
      ].join(' ')}
    >
      <input
        type="text"
        className={[styles.inputFilled, inputCustomClass].join(' ')}
        placeholder={placeholder}
        onChange={(e) => handleSearch(e.target.value.trimLeft())}
        value={val}
        name={keyName ? keyName : ''}
        onFocus={() => setShowDropdown(true)}
        disabled={isDisabled}
      />
      <SearchIcon
        fillColor="#969BA0"
        handleClick={() => !isDisabled && setShowDropdown(true)}
        customClass={isDisabled ? styles.cursorDefault : styles.cursorPointer}
      />
      <div
        className={
          showDropdown && dropdownDataToSee
            ? [styles.seeDataInDropDownMain, customClassdata].join(' ')
            : styles.hideDataInDropDownMain
        }
        ref={ref}
      >
        {showDropdown && dropdownDataToSee?.length
          ? dropdownDataToSee?.map((item: any, index: number) => {
              return (
                <p
                  key={index}
                  // className={styles.dropdownItemText}
                  className={[
                    styles.dropdownItemText,
                    customClassdropdownItem,
                  ].join(' ')}
                  onClick={() => handleClick(item, setVal, setShowDropdown)}
                >
                  <span
                    className={[styles.dropdownItemName, customClassItem].join(
                      ' '
                    )}
                  >
                    {item[dropDownKeyName]}
                  </span>
                </p>
              )
            })
          : 'No records found'}
      </div>
    </div>
  )
}

export default SearchDropDown
