import { useNavigate } from "react-router-dom";
import { EditIcon, PrintIcon } from "../../components/common/svg-components";
import ToggleSwitchV2 from "../../components/common/toggle-switch/ToggleSwitchV2";
import { useAppDispatch } from "../../hooks";
import { colors } from "../color";
import styles from "./tableData.module.scss";
import { updateStatusManageUserById } from "../../redux/features/manage-user/ManageUserAsynActions";
import { requestGenerator } from "../../utils/payloadGenerator";
import {
  allowedNumberOfDigitsAfterDecimal,
  utcToDate,
} from "../../utils/utils";
import moment from "moment";
import { getDoctorById } from "../../redux/features/receptionist/receptionistAsyncActions";
import { t } from "i18next";

export const userListTableHeaderData: any = [
  {
    Header: t("AllAttachmentHeader.NAME"),
    accessor: "name",
  },
  {
    Header: t("ManageStaff.BRANCH"),
    aaccessor: "branches",
    Cell: ({ row }: any) => {
      const branchRow: any = row?.original?.branches;
      return branchRow && branchRow.length > 0
        ? branchRow.map((item: any, index: number) => {
          return (
            <div key={`branch-${index}`} className={styles.columnFlex}>
              <span>
                {index + 1}. {item?.name}
              </span>
            </div>
          );
        })
        : "-";
    },
  },
  {
    Header: t("DepartmentSetup.DEPT"),
    aaccessor: "departments",
    Cell: ({ row }: any) => {
      const departments: any = row?.original?.departments;
      return departments && departments.length > 0
        ? departments.map((item: any, index: number) => {
          return (
            <div key={`dept-${index}`} className={styles.columnFlex}>
              <span>
                {index + 1}. {item?.name}
              </span>
            </div>
          );
        })
        : "-";
    },
  },
  {
    Header: t("DepartmentSetup.SPECIALTIES"),
    aaccessor: "specialities",
    Cell: ({ row }: any) => {
      const specialities: any = row?.original?.specialities;
      return specialities && specialities.length > 0
        ? specialities.map((item: any, index: number) => {
          return (
            <div key={`spec-${index}`} className={styles.columnFlex}>
              <span>
                {index + 1}. {item?.name}
              </span>
            </div>
          );
        })
        : "-";
    },
  },

  {
    Header: t("ManageStaff.EMAIL"),
    accessor: "email",
  },
  {
    Header: t("ManageStaff.PRIMARY_ROLE"),
    accessor: (row: any) => {
      return row?.role_id?.name ?? "-";
    },
  },
  {
    Header: t("ManageStaff.SECONDARY_ROLE"),
    aaccessor: "secondary_role",
    Cell: ({ row }: any) => {
      const sec_role: any = row?.original?.secondary_roles;
      return sec_role && sec_role.length > 0
        ? sec_role.map((item: any, index: number) => {
          return (
            <div key={`spec-${index}`} className={styles.columnFlex}>
              <span>
                {index + 1}. {item?.name}
              </span>
            </div>
          );
        })
        : "-";
    },
  },
  {
    Header: t("ManageStaff.PHONE_NO"),
    accessor: "phone",
  },
  {
    Header: t("PatientHistoryTableHeader.NOTES"),
    // accessor: 'notes',
    Cell: (props: any) => {
      const notesObject = {
        noteDetail: props?.row?.original?.notes,
        lastUpdateDate: utcToDate(props?.row?.original?.updatedAt),
      };

      return (
        <>
          {notesObject?.noteDetail ? (
            <span
              className={styles.view}
              onClick={() => props.onClick(notesObject)}
            >
              View
            </span>
          ) : (
            "-"
          )}
        </>
      );
    },
  },
  {
    Header: t("RcptTableHeader.STATUS"),
    Cell: ({ row }: any) => {
      const is_active = row?.original?.is_active;
      const dispatch = useAppDispatch();

      const handleToggle = (item: any) => {
        const payload = {
          id: item._id,
          data: { is_active: !item.is_active },
        };
        dispatch(updateStatusManageUserById(requestGenerator(payload)));
      };
      return (
        <>
          {row?.original?.role_id?.name === "MC_ADMIN" ? (
            "-"
          ) : (
            <ToggleSwitchV2
              isToggled={is_active}
              handleToggle={() => handleToggle(row?.original)}
            />
          )}
        </>
      );
    },
  },
  {
    Header: t("DocUploadHeader.ACTION"),
    Cell: ({ row }: any) => {
      const navigate = useNavigate();
      const handleEdit = (item: any) => {
        // dispatch(getEditUserData(row.original));
        navigate("/manageusers/createusers/primary", {
          state: { user: item },
        });
      };
      return (
        <EditIcon
          fillColor={colors.grey4}
          handleClick={() => handleEdit(row?.original)}
        />
      );
    },
  },
];

// patient EMR: searchModalHeaderData
export const searchModalHeaderData: any = [
  {
    Header: "FILE NO.",
    accessor: "emr_no", //file_no
    Cell: ({ row, onPopClose, onRowClick, invoiceFlag }: any) => {
      const objectId = row?.original;
      return (
        <span
          onClick={() => {
            onRowClick(row?.original);
          }}
          style={{ cursor: "pointer", color: "#0e26a3" }}
        >
          {objectId?.emr_no}
        </span>
      );
    },
  },
  {
    Header: "PATIENT NAME",
    accessor: "patient_name",
  },

  {
    Header: "NATIONAL ID",
    accessor: "national_id",
  },
  {
    Header: "MOBILE",
    accessor: "phone",
  },
];

// invoice: doctorModalHeaderData
export const doctorModalHeaderData: any = [
  {
    Header: t("RcptTableHeader.DOCTOR NAME"),
    Cell: ({ row, onPopClose, onRowClick, invoiceFlag }: any) => {
      const objectId = row?.original;
      const dispatch = useAppDispatch();
      onRowClick = (item: any) => {
        let dataPayload = {
          id: item,
        };
        dispatch(getDoctorById(requestGenerator(dataPayload))).then((e) => {
          if (e.type === "receptionist/getAllDoctorListById/fulfilled") {
            onPopClose(false);
          }
        });
      };
      return (
        <span
          onClick={() => {
            onRowClick(row?.original?._id);
          }}
          style={{ cursor: "pointer", color: "#0e26a3" }}
        >
          {objectId?.doctor_name}
        </span>
      );
    },
  },

  {
    Header: t("RcptTableHeader.MOBILE NO"),
    accessor: "doctor_phone",
  },
];

// invoice: addInsuranceHeaderData
export const addInsuranceHeaderData: any = [
  {
    Header: t("InsuranceHeader.ID"),
    accessor: "plan_id",
  },
  {
    Header: t("InsuranceHeader.INSURANCE PLAN"),
    accessor: "insurance_plan",
  },
  {
    Header: t("InsuranceHeader.EXPIRY DATE"),
    Cell: ({ row }: any) => {
      const originalDate = row?.original?.expiry_date;
      const formattedDate = moment(originalDate).format("DD MMM YYYY");
      return <>{formattedDate}</>;
    },
  },
  {
    Header: t("InsuranceHeader.POLICY NO"),
    accessor: "policy_no",
  },
  {
    Header: t("InsuranceHeader.DETAILS"),
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.details?.length ? (
            <span
              className={styles.viewPopupLink}
              onClick={() => {
                props?.onOpen(props?.row?.original);
              }}
            >
              {t("Common.View")}
            </span>
          ) : (
            "-"
          )}
        </>
      );
    },
  },
  {
    Header: t("InsuranceHeader.COPAY %"),
    accessor: "coPay",
  },
  {
    Header: t("PatientHistoryTableHeader.NOTES"),
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.notes ? (
            <span
              className={styles.viewPopupLink}
              onClick={() => {
                props?.onRowClick(props?.row?.original);
              }}
            >
              {t("Common.View")}
            </span>
          ) : (
            "-"
          )}
        </>
      );
    },
  },
];

// invoice: settledInvoiceHeaderData
export const settledInvoiceHeaderData: any = [
  {
    Header: t("PatientHistoryFormHeader.PATIENT NAME"),
    accessor: "patient_name",
    Cell: ({ row }: any) => {
      return (
        <>{row?.original?.patient_name ? row?.original?.patient_name : "-"}</>
      );
    },
  },
  {
    Header: t("SettledInvoice.INVOICE NO"),
    accessor: "invoice_no",
    Cell: ({ row }: any) => {
      //return row?.original?.invoice_no ? String(row?.original?.invoice_no).padStart(6, "0") : "-";
      return row?.original?.invoice_no ? row?.original?.invoice_no : "-";
    },
  },

  {
    Header: t("SettledInvoice.INVOICE DATE"),
    accessor: "createdAt", // Add an accessor for sorting
    sortType: (rowA: any, rowB: any, columnId: string, desc: boolean) => {
      // Custom sort function
      const dateA = rowA.original.generate_date || rowA.original.createdAt;
      const dateB = rowB.original.generate_date || rowB.original.createdAt;

      if (!dateA && !dateB) return 0; // Both dates are missing
      if (!dateA) return desc ? -1 : 1; // A is missing, B exists
      if (!dateB) return desc ? 1 : -1; // B is missing, A exists

      return desc
        ? new Date(dateB).getTime() - new Date(dateA).getTime()
        : new Date(dateA).getTime() - new Date(dateB).getTime();
    },
    Cell: ({ row }: any) => {
      const generatedDate = row?.original?.generate_date;
      const originalDate = row?.original?.createdAt;
      // const formattedDate = moment(originalDate).utc().format("DD-MM-YYYY");
      const formattedDate = generatedDate
        ? moment(generatedDate).format("DD MMM YYYY")
        : originalDate
          ? moment(originalDate).format("DD MMM YYYY")
          : "-";
      return <>{formattedDate}</>;
    },
  },
  {
    Header: t("SettledInvoice.INVOICE AMOUNT"),
    // accessor: 'paid_amount',
    Cell: ({ row }: any) => {
      const objectTotalAmount = row?.original?.insurance_claim_amount
        ? Number(
          allowedNumberOfDigitsAfterDecimal(
            row?.original?.insurance_claim_amount,
            3
          )
        ) +
        Number(
          allowedNumberOfDigitsAfterDecimal(row?.original?.total_amount, 3)
        )
        : allowedNumberOfDigitsAfterDecimal(row?.original?.total_amount, 3);
      return (
        <>
          <span style={{ cursor: "pointer", color: "#0e26a3" }}>
            {objectTotalAmount ? objectTotalAmount : "0.000"}
          </span>
        </>
      );
    },
  },
  {
    Header: t("SettledInvoice.AMOUNT RECEIVED"),
    // accessor: "paid_amount",
    Cell: ({ row }: any) => {
      const objectReceivedAmount = allowedNumberOfDigitsAfterDecimal(
        row?.original?.paid_amount,
        3
      );
      return (
        <>
          <span style={{ cursor: "pointer", color: "#0e26a3" }}>
            {objectReceivedAmount ? objectReceivedAmount : "0.000"}
          </span>
        </>
      );
    },
  },
  {
    Header: t("SettledInvoice.INSURANCE INVOICE"),
    // accessor: "paid_amount",
    Cell: ({ row }: any) => {
      return (
        <>
          <span>
            {row?.original?.patient_insurance_id
              ? t("Common.Yes")
              : t("Common.No")}
          </span>
        </>
      );
    },
  },
  {
    Header: t("Voucher.Reference_No"),
    Cell: ({ row }: any) => {
      return (
        <>
          <span>{row?.original?.reference_no === "" ? "-" : row?.original?.reference_no}</span>
        </>
      );
    },
  },
  {
    Header: t("SettledInvoice.EMAIL"),
    Cell: ({ row, onRowClick }: any) => {
      return (
        <>
          <span
            onClick={() => {
              onRowClick({ ...row?.original, showEmail: true });
            }}
            style={{ cursor: "pointer", color: "#0e26a3" }}
          >
            {t("Common.View")}
          </span>
        </>
      );
    },
  },
  {
    Header: t("SettledInvoice.PRINT"),
    Cell: ({ row, onRowClick }: any) => {
      return (
        <>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              onRowClick({ ...row?.original, showEmail: false });
            }}
          >
            <PrintIcon />
          </span>
        </>
      );
    },
  },
];

export const settledInvoiceHeaderDataV1: any = [
  {
    Header: "INVOICE NO.",
    accessor: "invoice_no",
  },

  {
    Header: "INVOICE DATE",
    Cell: ({ row }: any) => {
      const originalDate = row?.original?.generate_date;
      const formattedDate = moment(originalDate).format("DD MMM YYYY");
      return <>{formattedDate}</>;
    },
  },
  {
    Header: "INVOICE AMOUNT",
    accessor: "paid_amount",
  },
  {
    Header: "AMOUNT RECEIVED",
    Cell: ({ row, onPopClose, onRowClick }: any) => {
      const objectId = row?.original;
      return (
        <>
          <span
            // onClick={() => {
            //   onRowClick(row?.original);
            // }}
            style={{ cursor: "pointer", color: "#0e26a3" }}
          >
            {objectId?.paid_amount}
          </span>
        </>
      );
    },
  },
  {
    Header: "SETTLED INVOICES",
    Cell: ({ row, onPopClose, onRowClick }: any) => {
      return (
        <>
          <span
            // onClick={() => {
            //   onRowClick(row?.original);
            // }}
            style={{ cursor: "pointer", color: "#0e26a3" }}
          >
            View
          </span>
        </>
      );
    },
  },
];
