// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bookingAppoinment_bookingAppoinmentContainer__ywnuW {\n  background: var(--white1);\n}\n.bookingAppoinment_bookingAppoinmentContainer__ywnuW .bookingAppoinment_radioFieldGenderContainer__9lCxf {\n  display: flex;\n  align-items: baseline;\n  gap: 10px;\n  justify-content: center;\n  padding: 10px 0px;\n  margin-top: 15px;\n}\n.bookingAppoinment_bookingAppoinmentContainer__ywnuW .bookingAppoinment_radioFieldGenderContainer__9lCxf .bookingAppoinment_selectTimeInputField__LCWE2 {\n  height: 42px;\n  width: 150px;\n}\n\n.bookingAppoinment_roomBookimgModelStyles__Cfj1y {\n  margin-top: 10px !important;\n  background: var(--white1);\n  width: 900px;\n  margin: auto;\n}\n\n.bookingAppoinment_customRadioLabel__ODcuB {\n  color: var(--black2);\n  font-size: var(--font-14);\n  font-weight: var(--font-bold);\n}", "",{"version":3,"sources":["webpack://./src/components/common/booking-appoinment-popup/bookingAppoinment.module.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;AACJ;AAAI;EACI,aAAA;EACA,qBAAA;EACA,SAAA;EACA,uBAAA;EACA,iBAAA;EACA,gBAAA;AAER;AADQ;EACE,YAAA;EACA,YAAA;AAGV;;AAEA;EACE,2BAAA;EACA,yBAAA;EACA,YAAA;EACA,YAAA;AACF;;AAEA;EACE,oBAAA;EACA,yBAAA;EACA,6BAAA;AACF","sourcesContent":[".bookingAppoinmentContainer{\n    background: var(--white1);\n    .radioFieldGenderContainer {\n        display: flex;\n        align-items: baseline;\n        gap: 10px;\n        justify-content: center;\n        padding: 10px 0px;\n        margin-top: 15px;\n        .selectTimeInputField {\n          height: 42px;\n          width: 150px;\n        }\n      }\n}\n\n.roomBookimgModelStyles{\n  margin-top: 10px !important;\n  background: var(--white1);\n  width: 900px;\n  margin: auto;\n}\n\n.customRadioLabel{\n  color: var(--black2);\n  font-size: var(--font-14);\n  font-weight: var(--font-bold);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bookingAppoinmentContainer": "bookingAppoinment_bookingAppoinmentContainer__ywnuW",
	"radioFieldGenderContainer": "bookingAppoinment_radioFieldGenderContainer__9lCxf",
	"selectTimeInputField": "bookingAppoinment_selectTimeInputField__LCWE2",
	"roomBookimgModelStyles": "bookingAppoinment_roomBookimgModelStyles__Cfj1y",
	"customRadioLabel": "bookingAppoinment_customRadioLabel__ODcuB"
};
export default ___CSS_LOADER_EXPORT___;
