import { combineReducers } from "@reduxjs/toolkit";
import toastReducer, {
  initialState as toastIntialState,
} from "../features/toast/toastSlice";
import loginReducer from "../features/login/loginSlice";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import branchReducer, {
  initialState as branchIntialState,
} from "../features/branch/branchSlice";
import departmentReducer, {
  initialState as departmentIntialState,
} from "../features/department/departmentSlice";
import specialityReducer, {
  initialState as specialityIntialState,
} from "../features/specialities/specialitiesSlice";
import mobileAppConfigReducer, {
  initialState as mobileAppConfigIntialState,
} from "../features/mobile_app_configuration/mobileAppConfigurationSlice";
import manageUserReducer, {
  initialState as manageUserIntialState,
} from "../features/manage-user/ManageUserSlice";
import masterTableCategoryReducer, {
  initialState as masterTableCategoryIntialState,
} from "../features/master-table-category/MasterTableCategorySlice";
import manageUsergroupReducer, {
  initialState as manageUsergroupIntialState,
} from "../features/manage-usergroup/mangeUsergroupSlice";
import patinetActivityLogReducer, {
  initialState as patinetActivityIntialState,
} from "../features/patient_activity_log/patientActivityLogSlice";
import tagReducer, {
  initialState as tagIntialState,
} from "../features/patient-emr/tag/tagSlice";
import patientReducer, {
  initialState as patientIntialState,
} from "../features/patient-emr/patient/patientSlice";
import mobileAppointmentRequestReducer, {
  initialState as mobileAppointmentRequestIntialState,
} from "../features/mobile-appointment-request/mobileAppointmentRequestSlice";
import receptionistReducer, {
  initialState as receptionistIntialState,
} from "../features/receptionist/receptionistSlice";
import appointmentReducer, {
  initialState as appointmentIntialState,
} from "../features/appointment/appointmentSlice";
import bookingAppointmentRequestReducer, {
  initialState as bookingAppointmentIntialState,
} from "../features/appointments/bookingAppointmentsSlice";
import chatReducer, {
  initialState as chatIntialState,
} from "../features/chat/chatSlice";
import roleUserReducer, {
  initialState as roleUserIntialState,
} from "../features/role/roleSlice";
import treatmentPlansReducer, {
  initialState as treatmentIntialState,
} from "../features/treatmentPlans/treatmentPlansSlice";
import doctorDashboardReducer, {
  initialState as doctorDashboardIntialState,
} from "../features/doctor-dashboard/doctorDashboardSlice";
import diagnosisReducer, {
  initialState as diagnosisIntialState,
} from "../features/diagnosis/diagnosisSlice";
import createNewFormReducer, {
  initialState as createNewFormIntialState,
} from "../features/create-new-form/createNewFormSlice";
import referralReducer, {
  initialState as referralIntialState,
} from "../features/referral/referralSlice";
import notificationReducer, {
  initialState as notificationIntialState,
} from "../features/app-notifications/appNotificationSlice";
import patientHistoryReducer, {
  initialState as patientHistoryIntialState,
} from "../features/patient-history/patientHistorySlice";
import doctorDiagnosisReducer, {
  initialState as doctorDiagnosisIntialState,
} from "../features/doctor-diagnosis/doctorDiagnosisSlice";
import insuranceReducer, {
  initialState as insuranceIntialState,
} from "../features/insurance/insuranceSlice";
import receiptReducer, {
  initialState as receiptIntialState,
} from "../features/receipt/receiptSlice";
import requestReducer, {
  initialState as requestIntialState,
} from "../features/request/requestSlice";
import invoiceReducer, {
  initialState as invoiceIntialState,
} from "../features/invoice-module/invoiceSlice";
import labRequestReducer, {
  initialState as labRequestIntialState,
} from "../features/lab-request/labRequestSlice";
import radiologyRequestReducer, {
  initialState as radiologyRequestIntialState,
} from "../features/radiology-request/radiologyRequestSlice";
import createLabReducer, {
  initialState as createLabIntialState,
} from "../features/jobs/jobsSlice";
import ongoingClaimsReducer, {
  initialState as ongoingClaimsIntialState,
} from "../features/ongoing-claims/onGoingClaimsSlice";
import labInvoiceReducer, {
  initialState as labInvoiceIntialState,
} from "../features/lab-invoice/labInvoiceSlice";
import labReducer, {
  initialState as labIntialState,
} from "../features/lab/labSlice";
import radiologyJobs, {
  initialState as radiologyJobsIntialState,
} from "../features/radiology-jobs/jobsSlice";
import radiologyReducer, {
  initialState as radiologyIntialState,
} from "../features/radiology/radiologySlice";
import inventoryRequestReducer, {
  initialState as inventoryRequestIntialState,
} from "../features/inventory-request/inventoryRequestSlice";
import inventoryPurchaseInvoice, {
  initialState as inventoryPurchaseInvoiceIntialState,
} from "../features/purchase-invoice/purchaseInvoiceSlice";
import branchStoreReducer, {
  initialState as branchStoreInitialState,
} from "../features/branch-store/branchStoreSlice";
import masterValueReducer, {
  initialState as masterValueInitialState,
} from "../features/master-value/MasterValueSlice";
import serviceReducer, {
  initialState as servicesInitialState,
} from "../features/patientservices/servicesSlice";
import rolesPermissionsReducer, {
  initialState as permissionsInitialState,
} from "../features/user-roles-permissions/rolesPermissionsSlice";
import dentistDashboardReducer, {
  initialState as dentistDashboardIntialState,
} from "../features/dentist-dashboard/dentistDashboardSlice";
import dentistDiagnosisReducer, {
  initialState as dentistDiagnosisIntialState,
} from "../features/dentist-diagnosis/dentistDiagnosisSlice";
import ipdBookingReducer, {
  initialState as ipdBookingIntialState,
} from "../features/ipd-booking/ipdBookingSlice";
import ipdDashboardReducer, {
  initialState as ipdDashboardIntialState,
} from "../features/ipd-dashboard/ipdDashBoardSlice";
import IpdReducer, {
  initialState as ipdIntialState,
} from "../features/ipd/ipdSlice";
import inventoryMasterReducer, {
  initialState as inventoryMasterInitialState,
} from "../features/inventory-master/inventoryMasterSlice";

import inventoryItemReducer, {
  initialState as inventoryItemInitialState,
} from "../features/inventory-item/inventoryItemSlice";
import unitTypeReducer, {
  initialState as unitTypeIntialState,
} from "../features/unit-type/unitTypeSlice";
import wardTypeReducer, {
  initialState as wardInitialState,
} from "../features/ward/wardSlice";
import roomTypeReducer, {
  initialState as roomInitialState,
} from "../features/room/roomSlice";
import bedTypeReducer, {
  initialState as bedInitialState,
} from "../features/bed/bedSlice";
import ipdInvoiceReducer, {
  initialState as ipdInvoiceIntialState,
} from "../features/ipd-invoice/ipdInvoiceSlice";
import internalReducer, {
  initialState as internalIntialstate,
} from "../../redux/features/internal/internalSlice";
import agentReducer, {
  initialState as agentInitialState,
} from "../../redux/features/agents/agentSlice";
import CampaignReducer, {
  initialState as CampaignInitialState,
} from "../features/call-center-campaign/campaignSlice";
import CallCentereReducer, {
  initialState as callCentereInitialState,
} from "../features/lead-pool/callCenterSlice";

import callCeneterAdminDashbordReducer, {
  initialState as callCenterAdminDashbordInitialState,
} from "../features/call-center-admin-dashbord/callCenterDashbordSlice";

import crmAdminReducer, {
  initialState as crmAdminState,
} from "../features/crm-admin-store/crmSlice";

import pharmacyReducer, {
  initialState as pharmacyInitialState,
} from "../features/pharmacy/pharmacySlice";

import physioReducer, {
  initialState as physioInitialState,
} from "../features/physio/physioSlice";

import hrDashboardReducer, {
  initialState as hrDashboarInitialState,
} from "../features/hr-dashboard/hrDashboardSlice";

import employeeReducer, {
  initialState as empIntialState,
} from "../features/employee/employeeSlice";

import documentReducer, {
  initialState as documentInitialState,
} from "../features/document/documentSlice";

import payrollReducer, {
  initialState as payrollInitialState,
} from "../features/payroll/payrollSlice";

import attendanceReducer, {
  initialState as attendanceInitialState,
} from "../features/attendance/attendanceSlice";

import leaveReducer, {
  initialState as leaveInitialState,
} from "../features/leave/leaveSlice";

import accountsReducer, {
  initialState as accountsInitialState,
} from "../features/view-chat-of-accounts/accountsSlice";

import vouchersReducer, {
  initialState as vouchersInitialState,
} from "../features/vouchers/vouchersSlice";

import ledgerReducer, {
  initialState as ledgerIntialState,
} from "../features/ledger/ledgerSlice";

import trialBalanceReducer, {
  initialState as trialBalanceIntialState,
} from "../features/trial-balance/trialBalanceSlice";

import profitLossReducer, {
  initialState as profitLossInialState,
} from "../features/profit-loss/profitLossSlice";

import balanceSheetReducer, {
  initialState as balanceSheetInitialState,
} from "../features/balance-sheet/balanceSheetSlice";

import hl7Reducer, {
  initialState as hl7InitialState,
} from "../features/hl7/hl7Slice";
import importUtilityReducer, {
  initialState as importUtilityInitialState,
} from "../features/import-utility/importUtilitySlice";

import templateReducer, {
  initialState as templateInitialState,
} from "../features/dynamic-template/dynamicTemplateSlice";

import whatsupReducer, {
  initialState as whatsupInitialState,
} from "../features/whatsup-configurations/whatsupSlice";
import configuratinsDetailsReducer, {
  initialState as configuratinsDetailsInitialState,
} from "../features/configuration-details/configuratinsDetailsSlice";

import complianceConfigrationReducer, {
  initialState as complianceInitialState,
} from "../features/compliance/complianceSlice";

import taxReducer ,{initialState as taxInitialState} from "../features/tax/taxSlice"
import masterImportReducer, {
  initialState as masterImportInitialState,
} from "../features/master-import/masterImportSlice";
// import taxDetailReducer,{ initialState as configuratinsDetailsInitialState,} from "../features/tax/taxSlice"

const persistConfig = {
  storage,
  key: "proact-user",
  whitelist: [
    "userData",
    "encryptionKey",
    "isLoggedin",
    "branchData",
    "firebaseToken",
    "masterValueData",
    "colorSchemeList",
    "activeRole",
    "sidebarData",
    "masterPaymentModeData",
  ],
};

const peristedLoginReducer = persistReducer(persistConfig, loginReducer);

const rootReducer: any = (state: any, action: any) => {
  if (action.type === "RESET_STATE") {
    // Clear the persisted state
    state = {
      toast: toastIntialState,
      // login: loginIntialState,
      branch: branchIntialState,
      department: departmentIntialState,
      speciality: specialityIntialState,
      mobileAppConfig: mobileAppConfigIntialState,
      manageUser: manageUserIntialState,
      usergroup: manageUsergroupIntialState,
      patientActivityLog: patinetActivityIntialState,
      tag: tagIntialState,
      patient: patientIntialState,
      receptionist: receptionistIntialState,
      masterTableCategory: masterTableCategoryIntialState,
      appointment: appointmentIntialState,
      mobileAppointmentRequest: mobileAppointmentRequestIntialState,
      appointments: bookingAppointmentIntialState,
      roleUser: roleUserIntialState,
      chat: chatIntialState,
      treatmentPlans: treatmentIntialState,
      createNewForm: createNewFormIntialState,
      doctor: doctorDashboardIntialState,
      diagnosis: diagnosisIntialState,
      referral: referralIntialState,
      notifications: notificationIntialState,
      patientHistory: patientHistoryIntialState,
      doctorDiagnosis: doctorDiagnosisIntialState,
      labsJob: createLabIntialState,
      insurance: insuranceIntialState,
      labInvoice: labInvoiceIntialState,
      receipt: receiptIntialState,
      invoice: invoiceIntialState,
      request: requestIntialState,
      ongoingClaims: ongoingClaimsIntialState,
      labRequests: labRequestIntialState,
      radiolgyRequests: radiologyRequestIntialState,
      lab: labIntialState,
      radiologyJobs: radiologyJobsIntialState,
      radiology: radiologyIntialState,
      inventoryRequest: inventoryRequestIntialState,
      purchaseInvoice: inventoryPurchaseInvoiceIntialState,
      branchStore: branchStoreInitialState,
      masterValue: masterValueInitialState,
      services: servicesInitialState,
      inventoryMaster: inventoryMasterInitialState,
      inventoryItem: inventoryItemInitialState,
      permission: permissionsInitialState,
      dentist: dentistDashboardIntialState,
      dentistDiagnosis: dentistDiagnosisIntialState,
      ipdBooking: ipdBookingIntialState,
      ipdDashboard: ipdDashboardIntialState,
      ipd: ipdIntialState,
      unitTypeMaster: unitTypeIntialState,
      ward: wardInitialState,
      room: roomInitialState,
      bed: bedInitialState,
      ipdInvoice: ipdInvoiceIntialState,
      internal: internalIntialstate,
      agent: agentInitialState,
      campaign: CampaignInitialState,
      callCenter: callCentereInitialState,
      callCenterDashbord: callCenterAdminDashbordInitialState,
      crmAdmin: crmAdminState,
      pharmacy: pharmacyInitialState,
      physio: physioInitialState,
      hrdashboard: hrDashboarInitialState,
      employee: empIntialState,
      document: documentInitialState,
      payroll: payrollInitialState,
      attendance: attendanceInitialState,
      leave: leaveInitialState,
      accounts: accountsInitialState,
      vouchers: vouchersInitialState,
      ledger: ledgerIntialState,
      trialBalance: trialBalanceIntialState,
      profitLoss: profitLossInialState,
      balanceSheet: balanceSheetInitialState,
      hl7: hl7InitialState,
      import: importUtilityInitialState,
      template: templateInitialState,
      whatsup: whatsupInitialState,
      configuratinsDetails: configuratinsDetailsInitialState,
        taxs:taxInitialState,
        compliance:complianceInitialState,
        masterImport: masterImportInitialState,
    };
  }

  return combineReducers({
    toast: toastReducer,
    login: peristedLoginReducer,
    branch: branchReducer,
    department: departmentReducer,
    speciality: specialityReducer,
    mobileAppConfig: mobileAppConfigReducer,
    manageUser: manageUserReducer,
    usergroup: manageUsergroupReducer,
    patientActivityLog: patinetActivityLogReducer,
    tag: tagReducer,
    patient: patientReducer,
    receptionist: receptionistReducer,
    masterTableCategory: masterTableCategoryReducer,
    appointment: appointmentReducer,
    mobileAppointmentRequest: mobileAppointmentRequestReducer,
    appointments: bookingAppointmentRequestReducer,
    roleUser: roleUserReducer,
    chat: chatReducer,
    treatmentPlans: treatmentPlansReducer,
    createNewForm: createNewFormReducer,
    doctor: doctorDashboardReducer,
    diagnosis: diagnosisReducer,
    referral: referralReducer,
    notifications: notificationReducer,
    patientHistory: patientHistoryReducer,
    doctorDiagnosis: doctorDiagnosisReducer,
    labsJob: createLabReducer,
    insurance: insuranceReducer,
    labInvoice: labInvoiceReducer,
    receipt: receiptReducer,
    invoice: invoiceReducer,
    request: requestReducer,
    ongoingClaims: ongoingClaimsReducer,
    labRequests: labRequestReducer,
    radiolgyRequests: radiologyRequestReducer,
    lab: labReducer,
    radiologyJobs: radiologyJobs,
    radiology: radiologyReducer,
    inventoryRequest: inventoryRequestReducer,
    purchaseInvoice: inventoryPurchaseInvoice,
    branchStore: branchStoreReducer,
    masterValue: masterValueReducer,
    services: serviceReducer,
    permission: rolesPermissionsReducer,
    dentist: dentistDashboardReducer,
    dentistDiagnosis: dentistDiagnosisReducer,
    ipd: IpdReducer,
    unitTypeMaster: unitTypeReducer,
    ipdBooking: ipdBookingReducer,
    ipdDashboard: ipdDashboardReducer,
    ward: wardTypeReducer,
    room: roomTypeReducer,
    bed: bedTypeReducer,
    ipdInvoice: ipdInvoiceReducer,
    inventoryMaster: inventoryMasterReducer,
    inventoryItem: inventoryItemReducer,
    internal: internalReducer,
    agent: agentReducer,
    campaign: CampaignReducer,
    callCenter: CallCentereReducer,
    callCenterAdminDashbord: callCeneterAdminDashbordReducer,
    crmAdmin: crmAdminReducer,
    pharmacy: pharmacyReducer,
    physio: physioReducer,
    hrdashboard: hrDashboardReducer,
    employee: employeeReducer,
    document: documentReducer,
    payroll: payrollReducer,
    attendance: attendanceReducer,
    leave: leaveReducer,
    accounts: accountsReducer,
    vouchers: vouchersReducer,
    ledger: ledgerReducer,
    trialBalance: trialBalanceReducer,
    profitLoss: profitLossReducer,
    balanceSheet: balanceSheetReducer,
    hl7: hl7Reducer,
    import: importUtilityReducer,
    template: templateReducer,
    whatsup: whatsupReducer,
    configuratinsDetails: configuratinsDetailsReducer,
    taxs:taxReducer,
    compliance:complianceConfigrationReducer,
    masterImport: masterImportReducer,
  })(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
