import {
  SHIFT1_END_TIME,
  SHIFT1_START_TIME,
  SHIFT2_END_TIME,
  SHIFT2_START_TIME,
} from '../constants/constant'
import { ICreateSecondaryFormInputs } from '../interfaces/interfaces'
import { t } from 'i18next'

type ValidateResult = string | undefined
type Validate<TFieldValue, TFormValues> = (
  value: TFieldValue,
  formValues: TFormValues
) => ValidateResult | Promise<ValidateResult>

type ValidatorShift1<TFieldValue> = {
  required: string
  validate?: Validate<TFieldValue, ICreateSecondaryFormInputs>
}
type ValidatorShift2<TFieldValue> = {
  // required: string
  validate?: Validate<TFieldValue, ICreateSecondaryFormInputs>
}

export interface ICreateSecondaryFormInputsValidators {
  [SHIFT1_START_TIME]: ValidatorShift1<string>
  [SHIFT1_END_TIME]: ValidatorShift1<string>
  [SHIFT2_START_TIME]: ValidatorShift2<string>
  [SHIFT2_END_TIME]: ValidatorShift2<string>
  [SHIFT2_END_TIME]: ValidatorShift2<string>
}

export const createSecondaryValidators: any = {
  [SHIFT1_START_TIME]: {
    required: t('ManageStaff.Start_Time_validation'),
  },
  [SHIFT1_END_TIME]: {
    required: t('ManageStaff.End_Time_validation'),
    // validate: (value: string, values: ICreateSecondaryFormInputs) => {
    //   const shift1StartTime = values[SHIFT1_START_TIME]
    //   const shift2StartTime = values[SHIFT2_START_TIME]
    //   // const shift2EndTime = values[SHIFT2_END_TIME]

    //   if (shift1StartTime) {
    //     if (value <= shift1StartTime) {
    //       return t('ManageStaff.shift1_greater_than_shift2')
    //     } else if (shift2StartTime && value > shift2StartTime) {
    //       return t('ManageStaff.shift1_less_than_shift2')
    //     }
    //   }
    //   return undefined
    // },
  },
  [SHIFT2_START_TIME]: {
    // required: 'Please enter shift 2 start time',
    // validate: (value: string, values: ICreateSecondaryFormInputs) => {
    //   const shift1EndTime = values[SHIFT1_END_TIME]
    //   const shift2EndTime = values[SHIFT2_END_TIME]

    //   if (shift1EndTime && shift2EndTime) {
    //     if (value <= shift1EndTime) {
    //       return t('ManageStaff.Start_shift2_validation')
    //     } else if (value === shift1EndTime) {
    //       return t('ManageStaff.shift_not_same_start_time')
    //     }
    //   }
    //   return undefined
    // },
  },
  [SHIFT2_END_TIME]: {
    // required: 'Please enter shift 2 end time',
    // validate: (value: string, values: ICreateSecondaryFormInputs) => {
    //   const shift1EndTime = values[SHIFT1_END_TIME]
    //   const shift2StartTime = values[SHIFT2_START_TIME]

    //   if (shift1EndTime && shift2StartTime) {
    //     if (value <= shift2StartTime) {
    //       return t('ManageStaff.end_shift2_validation')
    //     } else if (value === shift1EndTime) {
    //       return t('ManageStaff.shift_not_same_time')
    //     }
    //   }
    //   return undefined
    // },
  },
}
