import axios from 'axios'
import { IAPIPayload } from '../../../interfaces/apiInterface'
import {
  CREATE_HL7,
  GET_ALL_HL7,
  GET_ICD10_URL,
  GET_LAB_IONIC_CODE,
  GET_RADIO_IONIC_CODE,
  UPDATE_HL7,
  UPDATE_HL7_STATUS,
} from '../../../config/config'

export const createHL7 = (data: IAPIPayload) => {
  return axios.post(CREATE_HL7, data)
}

export const udateHL7 = (data: IAPIPayload) => {
  return axios.post(UPDATE_HL7, data)
}

export const getAllHL7 = (data: IAPIPayload) => {
  return axios.post(GET_ALL_HL7, data)
}

export const updateStatusHL7 = (data: IAPIPayload) => {
  return axios.post(UPDATE_HL7_STATUS, data)
}

export const geticd10 = (data: IAPIPayload) => {
  return axios.post(GET_ICD10_URL, data)
}

export const getLabIonic = (data: IAPIPayload) => {
  return axios.post(GET_LAB_IONIC_CODE, data)
}

export const getRadiologyIonic = (data: IAPIPayload) => {
  return axios.post(GET_RADIO_IONIC_CODE, data)
}
