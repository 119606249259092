import { FC, useEffect, useState } from "react";
import {
  CloseIcon,
  ExportIcon,
  PrintIcon,
  SearchButton,
} from "../../svg-components";
import styles from "./viewAppointmentPopup.module.scss";
import { colors } from "../../../../constants/color";
import Divider from "../../divider/Divider";
import TableV2 from "../../table/tableV2/TableV2";
import { viewAppointmentPopupData } from "../../../../constants/table-data/viewAppointmentPopupData";
import Button from "../../button/Button";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { requestGenerator } from "../../../../utils/payloadGenerator";
import Loader from "../../spinner/Loader";
import Pagination from "../../pagination/Pagination";
import { trimValue } from "../../../../utils/utils";
import Popup from "../../popup/Popup";
import StatusSummaryPopup from "./status-summary-popup/StatusSummaryPopup";
import AppointmentLogPopup from "./appointment-log-popup/AppointmentLogPopup";
import ActionLogPopup from "./action-log-popup/ActionLogPopup";
import { getAllPatientAppointment } from "../../../../redux/features/appointment/appointmentAsyncActions";
import { useTranslation } from "react-i18next";

interface IViewAppointment {
  handleStatusSummary?: any;
  handleAppointmentLog?: any;
  handleActionLog?: any;
  handleClose?: any;
  setModelOpenClose?: any;
}

const ViewAppointmentPopup: FC<IViewAppointment> = ({
  handleStatusSummary,
  handleAppointmentLog,
  handleActionLog,
  handleClose,
  setModelOpenClose,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { isLoading, appointmentData } = useAppSelector(
    (state) => state.appointment
  );
  const [dataPerPage, setDataPerPage] = useState<number>(10);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [searchDoctor, setSearchDoctor] = useState("");
  const [searchPatient, setSearchPatient] = useState("");
  const [searchDate, setSearchDate] = useState("");
  const [searchFile, setSearchFile] = useState("");
  const [appointmentLog, setAppointmentLog] = useState<boolean>(false);
  const [actionLog, setActionLog] = useState<boolean>(false);
  const [statusSummary, setStatusSummary] = useState<boolean>(false);
  const [id, setId] = useState();
  const [active, setActive] = useState<boolean>(false);
  const [value, setValue] = useState();
  const [searchPhone, setSearchPhone] = useState("");

  const { branchData } = useAppSelector((state) => state.login);
  let branche = branchData?.branches;
  let branch_id = branche && branche.length > 0 && branche[0]?._id;
  const [branch, setBranch] = useState(branch_id);

  const pageIndexArray = () => {
    let pageIndexOptions = [];
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i);
    }
    return pageIndexOptions;
  };

  const pageIndexOptions = pageIndexArray();

  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionChange = (event: any) => {
    setSelectedOption(event.target.value);

    if (event.target.value === "today") {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const day = String(today.getDate()).padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;
      setSearchDate(formattedDate);
    }
  };

  const dateFormat = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  let checkDate = dateFormat();

  function getMonthRange(dateString = "") {
    const date = dateString ? new Date(dateString) : new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0") as any;

    const startOfMonth = `${year}-${month}-01`;
    const endOfMonth = `${year}-${month}-${new Date(year, month, 0).getDate()}`;

    return `${startOfMonth} - ${endOfMonth}`;
  }

  const getWeekRange = (dateString = "") => {
    let date;
    if (dateString) {
      date = new Date(dateString);
    } else {
      date = new Date();
    }
    const dayOfWeek = date.getDay();

    // Calculate the difference between the selected date and the closest Sunday
    const diff = date.getDate() - dayOfWeek;

    // Set the start of the week as Sunday
    const startOfWeek = new Date(date.setDate(diff));
    // Set the end of the week as Saturday
    const endOfWeek = new Date(date.setDate(date.getDate() + 6));

    // Format the start and end dates
    const startDateString = startOfWeek.toISOString().slice(0, 10);
    const endDateString = endOfWeek.toISOString().slice(0, 10);

    return `${startDateString} - ${endDateString}`;
  };

  const getDateRange = () => {
    const today = new Date();
    const nextWeek = new Date();
    nextWeek.setDate(today.getDate() + 7);

    switch (selectedOption) {
      case "weekly":
        let we = getWeekRange(searchDate);
        return we;
      case "monthly":
        let mm = getMonthRange(searchDate);
        return mm;
      default:
        return "";
    }
  };

  const dateStrings = getDateRange().split(" - ");
  const newStartDate = dateStrings[0];
  const newEndDate = dateStrings[1];

  useEffect(() => {
    let reqData = {
      fileNo: searchFile,
      patient: searchPatient,
      doctor: searchDoctor,
      date: searchDate,
      branch_id: branch,
      phone: searchPhone,
      page: pageIndex,
      pageSize: dataPerPage,
      range: {
        fromDate: newStartDate,
        toDate: newEndDate,
      },
    };

    dispatch(getAllPatientAppointment(requestGenerator(reqData))).then(
      (result) => setTotalPage(result.payload.lastPage)
    );
  }, [pageIndex, dataPerPage]);

  const handleSearch = () => {
    setPageIndex(1);
    let reqData = {
      fileNo: searchFile,
      patient: searchPatient,
      doctor: searchDoctor,
      date: searchDate,
      branch_id: branch,
      phone: searchPhone,
      page: pageIndex,
      pageSize: dataPerPage,
      range: {
        fromDate: newStartDate,
        toDate: newEndDate,
      },
    };

    dispatch(getAllPatientAppointment(requestGenerator(reqData))).then(
      (result) => setTotalPage(result.payload.lastPage)
    );
  };

  const handleViewAll = () => {
    setBranch(branch_id);
    setId(undefined);
    let reqData = {
      fileNo: "",
      patient: "",
      doctor: "",
      date: "",
      branch_id: branch_id,
      page: pageIndex,
      pageSize: dataPerPage,
      range: {
        fromDate: "",
        toDate: "",
      },
    };
    dispatch(getAllPatientAppointment(requestGenerator(reqData))).then(
      (result) => setTotalPage(result.payload.lastPage)
    );
    setSearchDoctor("");
    setSearchFile("");
    setSearchPatient("");
    setSearchDate("");
    setSelectedOption("Select");
  };

  const handlePrint = () => {
    window.print();
  };

  const handleRow = (id: any) => {
    setId(id);
    setValue(id);
    if (value === id) {
      setActive(true);
    } else {
      setActive(false);
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <div
        className={styles.popupContainer}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className={styles.iconContainer}>
          {/* DO NOT REMOVE THIS DIV BLOCK AS WE MAY BE NEED IN FUTURE */}

          {/* <div style={{ position: "relative" }}>
            <InfoIcon
              fillColor={colors.grey1}
              customClass={styles.closeIconStyle}
              mouseEnter={(e: any) => {
                setShowInfo(true);
                e.stopPropagation();
              }}
              mouseLeave={(e: any) => {
                setShowInfo(false);
                e.stopPropagation();
              }}
            />
            {showInfo && (
              <p className={styles.infoText}>
                It will enable the KPI for respective CRM Agent
              </p>
            )}
          </div> */}
          <CloseIcon
            customClass={styles.closeIconStyle}
            fillColor={colors.green1}
            handleClick={() => handleClose()}
          />
        </div>
        <div className={styles.viewAppointment}>
          <p className={styles.title}>
            {t("ViewAppointment.View Appointment")}
          </p>
          <Divider customClass={styles.dividerStyle} />
          <div className={styles.mainContent}>
            <div className={styles.searchContainer}>
              <label className={styles.labelText}>
                {t("PatientEMR.File No.")}
              </label>
              <input
                type="text"
                className={styles.inputField}
                placeholder={
                  t("ViewAppointment.FileSearch") || "Search by file no."
                }
                value={searchFile}
                onKeyDown={(e: any) => {
                  if (e?.key === "Enter") {
                    handleSearch();
                  }
                }}
                onChange={(e) => {
                  trimValue(e);
                  setSearchFile(e.target.value);
                  if (searchFile !== "" && e.target.value === "") {
                    let reqData = {
                      fileNo: "",
                      patient: searchPatient,
                      doctor: searchDoctor,
                      date: searchDate,
                      branch_id: branch,
                      phone: searchPhone,
                      page: pageIndex,
                      pageSize: dataPerPage,
                      range: {
                        fromDate: newStartDate,
                        toDate: newEndDate,
                      },
                    };

                    dispatch(
                      getAllPatientAppointment(requestGenerator(reqData))
                    ).then((result) => setTotalPage(result.payload.lastPage));
                  }
                }}
              />

              <label className={styles.labelText}>
                {t("Receptionist Dashboard.Doctor")}
              </label>
              <input
                type="text"
                className={styles.inputField}
                placeholder={
                  t("ViewAppointment.DoctorSearch") || "Search by doctor"
                }
                value={searchDoctor}
                onKeyDown={(e: any) => {
                  if (e?.key === "Enter") {
                    handleSearch();
                  }
                }}
                onChange={(e) => {
                  trimValue(e);
                  setSearchDoctor(e.target.value);
                  if (searchDoctor !== "" && e.target.value === "") {
                    let reqData = {
                      fileNo: searchFile,
                      patient: searchPatient,
                      doctor: "",
                      date: searchDate,
                      branch_id: branch,
                      phone: searchPhone,
                      page: pageIndex,
                      pageSize: dataPerPage,
                      range: {
                        fromDate: newStartDate,
                        toDate: newEndDate,
                      },
                    };

                    dispatch(
                      getAllPatientAppointment(requestGenerator(reqData))
                    ).then((result) => setTotalPage(result.payload.lastPage));
                  }
                }}
              />

              <label className={styles.labelText}>
                {t("ViewAppointment.Patient")}
              </label>
              <input
                type="text"
                className={styles.inputField}
                placeholder={
                  t("ViewAppointment.PatientSearch") || "Search by patient"
                }
                value={searchPatient}
                onKeyDown={(e: any) => {
                  if (e?.key === "Enter") {
                    handleSearch();
                  }
                }}
                onChange={(e) => {
                  trimValue(e);
                  setSearchPatient(e.target.value);
                  if (searchPatient !== "" && e.target.value === "") {
                    let reqData = {
                      fileNo: searchFile,
                      patient: "",
                      doctor: searchDoctor,
                      date: searchDate,
                      branch_id: branch,
                      phone: searchPhone,
                      page: pageIndex,
                      pageSize: dataPerPage,
                      range: {
                        fromDate: newStartDate,
                        toDate: newEndDate,
                      },
                    };

                    dispatch(
                      getAllPatientAppointment(requestGenerator(reqData))
                    ).then((result) => setTotalPage(result.payload.lastPage));
                  }
                }}
              />
            </div>
            <div className={styles.filterContainer}>
              <label className={styles.labelText}>
                {t("ViewAppointment.Date")}
              </label>
              <input
                type="date"
                className={styles.DateinputField}
                value={searchDate}
                onChange={(e) => {
                  trimValue(e);
                  setSearchDate(e.target.value);
                  if (e.target.value === "") {
                    let reqData = {
                      fileNo: searchFile,
                      patient: searchPatient,
                      doctor: searchDoctor,
                      date: searchDate,
                      branch_id: branch,
                      phone: searchPhone,
                      page: pageIndex,
                      pageSize: dataPerPage,
                      range: {
                        fromDate: newStartDate,
                        toDate: newEndDate,
                      },
                    };
                    setSelectedOption("Select");
                    dispatch(
                      getAllPatientAppointment(requestGenerator(reqData))
                    ).then((result) => setTotalPage(result.payload.lastPage));
                  }
                  if (e.target.value !== checkDate) {
                    setSelectedOption("Select");
                  }
                }}
              />
              <label className={styles.labelText}>
                {t("ViewAppointment.Period")}
              </label>
              <select
                className={styles.daysSelectContainer}
                onChange={handleOptionChange}
                value={selectedOption}
              >
                <option value="Select">Select </option>
                <option value="today">Today</option>
                <option value="weekly">Weekly</option>
                <option value="monthly">Monthly</option>
              </select>

              <label className={styles.labelText}>{t("Header.Branch")}</label>
              <select
                className={styles.branchsSelectContainer}
                value={branch}
                onChange={(e) => {
                  setBranch(e.target.value);
                }}
              >
                {branche &&
                  branche.length > 0 &&
                  branche.map((item: any, i: number) => {
                    return (
                      <option value={item?._id} key={i}>
                        {item?.name}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className={styles.searchContainer}>
              <label className={styles.labelText}>
                {t("ContactUs.Phone")}
              </label>
              <input
                type="number"
                className={styles.inputField}
                placeholder={
                  t("ViewAppointment.PhoneSearch") || "Search by phone"
                }
                value={searchPhone}
                onKeyDown={(e: any) => {
                  if (e?.key === "Enter") {
                    handleSearch();
                  }
                }}
                onChange={(e) => {
                  trimValue(e);
                  setSearchPhone(e.target.value);
                  if (searchPhone !== "" && e.target.value === "") {
                    let reqData = {
                      fileNo: searchFile,
                      patient: "",
                      doctor: searchDoctor,
                      date: searchDate,
                      branch_id: branch,
                      phone: searchPhone,
                      page: pageIndex,
                      pageSize: dataPerPage,
                      range: {
                        fromDate: newStartDate,
                        toDate: newEndDate,
                      },
                    };

                    dispatch(
                      getAllPatientAppointment(requestGenerator(reqData))
                    ).then((result) => setTotalPage(result.payload.lastPage));
                  }
                }}
              />
              <SearchButton handleClick={() => handleSearch()} />
            </div>
            <div className={styles.btnContainer}>
              <Button
                title={t("Common.View All") || "View All"}
                customClass={styles.viewBtn}
                handleClick={handleViewAll}
              />
              <Button
                title={
                  t("ViewAppointment.Appointment Log") || "Appointment Log"
                }
                customClass={styles.btnStyle}
                handleClick={() => {
                  setAppointmentLog(true);
                }}
                type="button"
                disable={id === undefined ? true : false}
              />
              <Button
                title={t("ViewAppointment.Action Log") || "Action Log"}
                customClass={styles.btnStyle}
                handleClick={() => setActionLog(true)}
                disable={id === undefined ? true : false}
              />
              <Button
                title={t("ViewAppointment.Status Summary") || "Status Summary"}
                customClass={styles.btnStyle}
                handleClick={() => setStatusSummary(true)}
              />
              <PrintIcon
                customClass={styles.printIconStyle}
                handleClick={handlePrint}
              />
              <ExportIcon />
            </div>
            <div className={styles.tableContainer}>
              {appointmentData?.length > 0 ? (
                <TableV2
                  tableHeaderData={viewAppointmentPopupData}
                  tableRowData={appointmentData}
                  handleRow={handleRow}
                  setModelOpenClose={setModelOpenClose}
                  setActive={setValue}
                  active={value}
                  hadleData={() => handleSearch()}
                />
              ) : (
                <p className={styles.noRecord}>
                  {t("Common.No records found")}
                </p>
              )}
            </div>

            <Pagination
              setDataPerPage={setDataPerPage}
              pageIndexOptions={pageIndexOptions}
              pageIndex={pageIndex}
              setPageIndex={setPageIndex}
            />
          </div>
        </div>
        {appointmentLog && (
          <Popup
            Children={AppointmentLogPopup}
            handleClose={() => {
              setAppointmentLog(false);
            }}
            popData={id}
          />
        )}
        {actionLog && (
          <Popup
            Children={ActionLogPopup}
            handleClose={() => {
              setActionLog(false);
            }}
            popData={id}
          />
        )}
        {statusSummary && (
          <Popup
            Children={StatusSummaryPopup}
            handleClose={() => setStatusSummary(false)}
            popData={{
              doctor: searchDoctor,
              date: searchDate,
              branch_id: branch,
              range: {
                fromDate: newStartDate,
                toDate: newEndDate,
              },
            }}
            // customClassPopup={styles.popupStyles}
          />
        )}
      </div>
    </>
  );
};

export default ViewAppointmentPopup;
