import {
  createContext,
  Component,
  isValidElement,
  createElement,
  useContext,
  useState,
  useMemo,
  forwardRef,
  ReactElement,
} from "react";

interface ErrorBoundaryContextValue {
  didCatch: boolean;
  error: Error | null;
  resetErrorBoundary: () => void;
}

const ErrorBoundaryContext = createContext<ErrorBoundaryContextValue | null>(
  null
);

const initialState: ErrorBoundaryState = {
  didCatch: false,
  error: null,
};

interface ErrorBoundaryProps {
  children: React.ReactNode;
  fallbackRender?: (props: {
    error: Error | null;
    resetErrorBoundary: () => void;
  }) => React.ReactNode;
  FallbackComponent?: React.ComponentType<{
    error: Error | null;
    resetErrorBoundary: () => void;
  }>;
  fallback?: React.ReactNode;
  onError?: (error: Error, info: { componentStack: string }) => void;
  onReset?: (errorBoundaryProps: { args: any[]; reason: string }) => void;
  resetKeys?: any;
}

interface ErrorBoundaryState {
  didCatch: boolean;
  error: Error | null;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.resetErrorBoundary = this.resetErrorBoundary.bind(this);
    this.state = initialState;
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return {
      didCatch: true,
      error,
    };
  }

  resetErrorBoundary(...args: any[]): void {
    const { error } = this.state;
    if (error !== null) {
      const { onReset } = this.props;
      onReset?.({
        args,
        reason: "imperative-api",
      });
      this.setState(initialState);
    }
  }

  componentDidCatch(error: Error, info: React.ErrorInfo): void {
    const { onError } = this.props
    //@ts-ignore
    onError?.(error, { componentStack: info.componentStack })
  }

  componentDidUpdate(
    prevProps: ErrorBoundaryProps,
    prevState: ErrorBoundaryState
  ): void {
    const { didCatch } = this.state;
    const { resetKeys } = this.props;

    if (
      didCatch &&
      prevState.error !== null &&
      hasArrayChanged(prevProps.resetKeys, resetKeys)
    ) {
      const { onReset } = this.props;
      onReset?.({
        //@ts-ignore
        next: resetKeys,
        prev: prevProps.resetKeys,
        reason: "keys",
      });
      this.setState(initialState);
    }
  }

  render(): ReactElement {
    const { children, fallbackRender, FallbackComponent, fallback } =
      this.props;
    const { didCatch, error } = this.state;
    let childToRender = children;

    if (didCatch) {
      const props = {
        error,
        resetErrorBoundary: this.resetErrorBoundary,
      };

      if (isValidElement(fallback)) {
        childToRender = fallback;
      } else if (typeof fallbackRender === "function") {
        childToRender = fallbackRender(props);
      } else if (FallbackComponent) {
        childToRender = createElement(FallbackComponent, props);
      } else {
        console.error(
          "react-error-boundary requires either a fallback, fallbackRender, or FallbackComponent prop"
        );
        throw error;
      }
    }

    return createElement(
      ErrorBoundaryContext.Provider,
      {
        value: {
          didCatch,
          error,
          resetErrorBoundary: this.resetErrorBoundary,
        },
      },
      childToRender
    );
  }
}

function hasArrayChanged(a: any[] = [], b: any[] = []): boolean {
  return (
    a.length !== b.length || a.some((item, index) => !Object.is(item, b[index]))
  );
}

function assertErrorBoundaryContext(
  value: ErrorBoundaryContextValue | null
): boolean {
  if (
    value == null ||
    typeof value.didCatch !== "boolean" ||
    typeof value.resetErrorBoundary !== "function"
  ) {
    throw new Error("ErrorBoundaryContext not found");
  }
  return true;
}

function useErrorBoundary(): ErrorBoundaryContextValue {
  const context = useContext(ErrorBoundaryContext);
  assertErrorBoundaryContext(context);

  const [state, setState] = useState({
    error: null,
    hasError: false,
  });

  const memoized = useMemo(
    () => ({
      resetErrorBoundary: () => {
        context?.resetErrorBoundary();
        setState({
          error: null,
          hasError: false,
        });
      },
      showBoundary: (error: any) =>
        setState({
          error,
          hasError: true,
        }),
    }),
    [context?.resetErrorBoundary]
  );

  if (state.hasError) {
    throw state.error;
  }

  //@ts-ignore
  return memoized;
}

function withErrorBoundary<P>(
  component: any,
  errorBoundaryProps: ErrorBoundaryProps
) {
  const Wrapped = forwardRef((props: P, ref) =>
    createElement(
      ErrorBoundary,
      errorBoundaryProps,
      createElement(component, {
        ...props,
        ref,
      })
    )
  );

  // Format for display in DevTools
  const name = component.displayName || component.name || "Unknown";
  Wrapped.displayName = "withErrorBoundary(".concat(name, ")");
  return Wrapped;
}

export {
  ErrorBoundary,
  ErrorBoundaryContext,
  useErrorBoundary,
  withErrorBoundary,
};
