import React, { useEffect } from "react";
import Tabs from "../../components/core/tab/Tabs";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import styles from "./configurations.module.scss";
import { configuratons } from "../../constants/data";
import { useAppDispatch } from "../../hooks";

const Configurations = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/configuration/upay");
  }, []);
  return (
    <>
      <Tabs tabData={configuratons} />
      <Outlet />
    </>
  );
};

export default Configurations;
