import {
  GET_ALL_PATIENT_IPD_BOOKING_TYPE,
  GET_IPD_BOOK_TYPE,
  GET_IPD_BOOK_DOCTORS_TYPE,
  GET_IPD_AVAILABLE_BEDS_TYPE,
  GET_IPD_ALL_BEDS_TYPE,
  GET_IPD_BOOK_DROPDOWN_TYPE,
  UPDATE_IPD_BOOK_TYPE,
  GET_ALL_BOOKED_BED_URL_TYPE,
  GET_IPD_ALL_BEDS_SCHEDULAR_TYPE,
  IPD_CASE_SUMMARY_WIDGET,
} from "../../../constants/asyncActionsType";
import createAsyncThunkForSlice from "../../../utils/utils";
import {
  getAllIpdBookingPatient,
  getIpdBook,
  getIpdBookDoctors,
  getIpdAvailableBeds,
  getIpdAllBeds,
  getIpdBookDropdownData,
  updateIpdBookData,
  bookedBed,
  ipdCaseSummary,
} from "./ipdBookingCrud";

export const getIpdBookingPatientList = createAsyncThunkForSlice(
  GET_ALL_PATIENT_IPD_BOOKING_TYPE,
  getAllIpdBookingPatient
);

export const getIpdBookDetails = createAsyncThunkForSlice(
  GET_IPD_BOOK_TYPE,
  getIpdBook
);

export const getIpdBookDoctorsList = createAsyncThunkForSlice(
  GET_IPD_BOOK_DOCTORS_TYPE,
  getIpdBookDoctors
);

export const getIpdAvailableBedsList = createAsyncThunkForSlice(
  GET_IPD_AVAILABLE_BEDS_TYPE,
  getIpdAvailableBeds
);

export const getIpdAllBedsList = createAsyncThunkForSlice(
  GET_IPD_ALL_BEDS_TYPE,
  getIpdAllBeds
);
export const getIpdAllBedsListForSchedular = createAsyncThunkForSlice(
  GET_IPD_ALL_BEDS_SCHEDULAR_TYPE,
  getIpdAllBeds
);

export const getIpdBookDropdownList = createAsyncThunkForSlice(
  GET_IPD_BOOK_DROPDOWN_TYPE,
  getIpdBookDropdownData
);

export const updateIpdBooking = createAsyncThunkForSlice(
  UPDATE_IPD_BOOK_TYPE,
  updateIpdBookData,
  { isToast: true }
);

export const getAllBookedBeds = createAsyncThunkForSlice(
  GET_ALL_BOOKED_BED_URL_TYPE,
  bookedBed
);
// ipd case summary widgets
export const ipdCaseSummaryWidgets = createAsyncThunkForSlice(
  IPD_CASE_SUMMARY_WIDGET,
  ipdCaseSummary
);
