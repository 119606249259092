// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".searchDesign_mainContainer__fb8Pt {\n  width: 285px;\n  height: 50px;\n  display: flex;\n  flex-direction: row;\n  border-radius: 10px;\n  background: rgba(255, 255, 255, 0.7);\n  box-shadow: 0px 10px 117px 14px rgba(0, 0, 0, 0.05);\n}\n.searchDesign_mainContainer__fb8Pt .searchDesign_searchIcon__FtCQp {\n  width: 12%;\n}\n.searchDesign_mainContainer__fb8Pt .searchDesign_searchIcon__FtCQp .searchDesign_searchIconStyle__\\+zacj {\n  position: absolute;\n  left: 12px;\n  top: 16px;\n}\n.searchDesign_mainContainer__fb8Pt .searchDesign_searchContainer__hwHly {\n  margin-left: 8px;\n  display: flex;\n}\n.searchDesign_mainContainer__fb8Pt .searchDesign_searchContainer__hwHly .searchDesign_searchText__y\\+53X {\n  display: flex;\n  align-self: center;\n  color: var(--grey11);\n}", "",{"version":3,"sources":["webpack://./src/components/common/search-design/searchDesign.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,oCAAA;EACA,mDAAA;AACF;AACE;EACE,UAAA;AACJ;AAAI;EACE,kBAAA;EACA,UAAA;EACA,SAAA;AAEN;AACE;EACE,gBAAA;EACA,aAAA;AACJ;AAAI;EACE,aAAA;EACA,kBAAA;EACA,oBAAA;AAEN","sourcesContent":[".mainContainer {\n  width: 285px;\n  height: 50px;\n  display: flex;\n  flex-direction: row;\n  border-radius: 10px;\n  background: rgba(255, 255, 255, 0.7);\n  box-shadow: 0px 10px 117px 14px rgba(0, 0, 0, 0.05);\n  // cursor: pointer;\n  .searchIcon {\n    width: 12%;\n    .searchIconStyle {\n      position: absolute;\n      left: 12px;\n      top: 16px;\n    }\n  }\n  .searchContainer {\n    margin-left: 8px;\n    display: flex;\n    .searchText {\n      display: flex;\n      align-self: center;\n      color: var(--grey11);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContainer": "searchDesign_mainContainer__fb8Pt",
	"searchIcon": "searchDesign_searchIcon__FtCQp",
	"searchIconStyle": "searchDesign_searchIconStyle__+zacj",
	"searchContainer": "searchDesign_searchContainer__hwHly",
	"searchText": "searchDesign_searchText__y+53X"
};
export default ___CSS_LOADER_EXPORT___;
