import { FC, useState, useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import Button from '../../../components/common/button/Button'
import EyeIcons from '../../../components/common/eye-icon/EyeIcon'
import Loader from '../../../components/common/spinner/Loader'
import { CONFIRM_NEW_PASSWORD, NEW_PASSWORD } from '../../../constants/constant'
import { recoveryPasswordValidators } from '../../../form-validators/recoveryPasswordValidators'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import {
  IRecoveryPasswordInputs,
  passwordType,
} from '../../../interfaces/interfaces'
import { userResetPassword } from '../../../redux/features/login/loginAsynActions'
import { requestGenerator } from '../../../utils/payloadGenerator'
import styles from './recoveryPassword.module.scss'
import { useNavigate, useLocation } from 'react-router-dom'
import { RESET_PASSWORD_TYPE } from '../../../constants/asyncActionsType'
import healthboxtext from '../../../assets/images/HealthboxLogoText.png'
import { trimValue } from '../../../utils/utils'
import { useTranslation } from 'react-i18next'

const RecoveryPassword: FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { loading, resetPWDToken } = useAppSelector((state) => state.login)
  const state = useLocation().state
  const [passwordType, setPasswordType] = useState<passwordType>('password')
  const [confirmPasswordType, setConfirmPasswordType] =
    useState<passwordType>('password')

  const {
    register,
    handleSubmit,
    // setError,
    formState: { errors },
  } = useForm<IRecoveryPasswordInputs>()
  const token = useLocation().search

  const onSubmit: SubmitHandler<IRecoveryPasswordInputs> = (data) => {
    const payload = {
      ...data,
      token: resetPWDToken ? resetPWDToken : token ? token?.split('=')[1] : '',
    }
    dispatch(userResetPassword(requestGenerator(payload))).then((e) => {
      if (e.type === `${RESET_PASSWORD_TYPE}/fulfilled`) {
        navigate('/')
      }
    })
  }
  useEffect(() => {
    if (!state?.renderResetPassword && !token) {
      navigate('/')
    }
  }, [navigate, state?.renderResetPassword, token])

  return (
    <>
      {loading && <Loader />}
      {/* <p className={styles.formTitle}>Set Password</p> */}
      <div className={styles.mainContainer}>
        <div className={styles.logoContainer}>
          <img
            src={healthboxtext}
            alt="error to display"
            className={styles.logoImg}
          />
        </div>
        <p className={styles.formTitle}>{t('Auth.Reset Password')}</p>
        <p className={styles.formSubTitle}>{t('Auth.EnterNewPasswordText')}</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.loginForm}>
            {/* <span className={styles.iconLabelStyle}> */}
            {/* <PasswordIcon customClass={styles.iconCustomClass} /> */}
            <label htmlFor={NEW_PASSWORD} className={styles.labelStyle}>
              {t('Auth.Password')}
            </label>
            {/* </span> */}
            <div>
              <span style={{ position: 'relative' }}>
                <input
                  type={passwordType}
                  placeholder={
                    t('Auth.EnterNewPassword') || 'Enter new password'
                  }
                  className={styles.inputFieldStyle}
                  {...register(
                    NEW_PASSWORD,
                    recoveryPasswordValidators[NEW_PASSWORD]
                  )}
                  onChange={(e) => trimValue(e)}
                />
                <EyeIcons
                  passwordType={passwordType}
                  setPasswordType={setPasswordType}
                  customClass={styles.eyeIcon}
                  handleClick={() => setPasswordType('password')}
                />
              </span>
              {errors[NEW_PASSWORD] && (
                <p className="errorText">
                  <span className="error">{errors[NEW_PASSWORD].message}</span>
                </p>
              )}
            </div>
          </div>
          <div className={styles.loginForm}>
            {/* <span className={styles.iconLabelStyle}>
              <PasswordIcon customClass={styles.iconCustomClass} /> */}
            <label htmlFor={CONFIRM_NEW_PASSWORD} className={styles.labelStyle}>
              {t('Auth.Confirm Password')}
            </label>
            {/* </span> */}
            <div>
              <span style={{ position: 'relative' }}>
                <input
                  type={confirmPasswordType}
                  placeholder={
                    t('Auth.EnterConfirmPassword') || 'Enter confirm password'
                  }
                  className={styles.inputFieldStyle}
                  {...register(
                    CONFIRM_NEW_PASSWORD,
                    recoveryPasswordValidators[CONFIRM_NEW_PASSWORD]
                  )}
                  onChange={(e) => trimValue(e)}
                />
                <EyeIcons
                  passwordType={confirmPasswordType}
                  setPasswordType={setConfirmPasswordType}
                  customClass={styles.eyeIcon}
                  handleClick={() => setConfirmPasswordType('password')}
                />
              </span>
              {errors[CONFIRM_NEW_PASSWORD] && (
                <p className="errorText">
                  <span className="error">
                    {errors[CONFIRM_NEW_PASSWORD].message}
                  </span>
                </p>
              )}
            </div>
          </div>
          <div className={styles.btnContainer}>
            <Button
              title={t('Auth.Reset Password') || 'Reset Password'}
              type="submit"
              disable={loading}
              customClass={styles.loginButtonStyle}
            />
          </div>
        </form>
      </div>
    </>
  )
}

export default RecoveryPassword
