import { FC, useEffect, useState } from "react";
import RootRoutes from "./routes/RootRoutes";
import { useAppDispatch, useAppSelector } from "../../hooks/index";
import Toast from "../common/toast/Toast";
import { initializeApp } from "firebase/app";
import { getMessaging, onMessage } from "firebase/messaging";
import { getAllNotificationList } from "../../redux/features/app-notifications/appNotificationAsyncActions";
import { requestGenerator } from "../../utils/payloadGenerator";
import { Suspense } from "react";
import InitialLoadUI from "../common/initial-site-load-loader/initialFallBack";
import Chat from "../chatbot/Chatv1";
import Floting from "../floting/Floting";

const FIREBASE_CONFIG = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};
initializeApp(FIREBASE_CONFIG);

const App: FC = () => {
  const { message, type } = useAppSelector((state) => state.toast);
  const { userData, isLoggedin } = useAppSelector((state) => state.login);
  const [showChat, setShowChat] = useState(false);

  const uploadChat = () => {
    setTimeout(() => {
      setShowChat(true);
    }, 3000);
  };
  const dispatch = useAppDispatch();
  /* IT WILL GLOBALLY REMOVE CONSOLE LOGS, If doing development below line */
  // console.log = () => {}

  const listen = () => {
    const messaging = getMessaging();
    onMessage(messaging, (payload: any) => {
      dispatch(getAllNotificationList(requestGenerator({})));
      const notificationTitle = payload.notification.title;
      const notificationOptions = {
        body: payload.notification.body,
        //icon: payload.notification.icon,
      };

      if (!("Notification" in window)) {
        console.log("This browser does not support system notifications.");
      } else if (Notification.permission === "granted") {
        // If it's okay let's create a notification
        var notification = new Notification(
          notificationTitle,
          notificationOptions
        );
        notification.onclick = function (event) {
          event.preventDefault();
          window.open("https://google.com", "_blank");
          notification.close();
        };
      }
    });
  };

  listen();
  const ChatPermission = ["RECEPTIONIST", "DOCTOR"];

  useEffect(() => {
    if (isLoggedin && ChatPermission.includes(userData?.role)) {
      const timer = setTimeout(() => {
        setShowChat(true);
      }, 2000);

      return () => clearTimeout(timer);
    } else {
      setShowChat(false);
    }
  }, [isLoggedin, userData?.role]);

  return (
    <>
      {message && <Toast type={type} message={message} />}
      <Suspense fallback={<InitialLoadUI />}>
        <RootRoutes />
        {/* {showChat && <Chat />} */}
        {showChat && <Floting />}
      </Suspense>
    </>
  );
};

export default App;
