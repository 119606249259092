import styles from './styles.module.scss'
import { useState, useEffect } from 'react'
import dummyImage from '../../../../assets/images/Default Image.png'
import { Input } from '../../../../components/common/input/input'
import Button from '../../../../components/common/button/Button'
import { CustomRadio } from '../../../../components/common/custom-radio'
import { CustomModal } from '../../../../components/common/custom-modal/modal'
import DischargeFormModal from '../../ipd-discharge-form-modal/dischargeForm'
import IpdRequest from '../../ipd-request-modal/ipdRequest'
import { useForm } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import { useLocation, useNavigate } from 'react-router-dom'
import { requestGenerator } from '../../../../utils/payloadGenerator'
import Loader from '../../../../components/common/spinner/Loader'
import Select from 'react-select'
import { DropdownIndicator } from '../../../../components/common/dropdown-indicator/DropdownIndicator'
import { getPatientEmrById } from '../../../../redux/features/patient-emr/patient/patientAsyncAction'
import {
  applyInsurancetoCase,
  getCaseByIdIpdBooking,
  getIpdCaseById,
  ipdCreateCase,
  updateCaseIpd,
} from '../../../../redux/features/ipd/ipdAsyncActions'
import { TextArea } from '../../../../components/common/text-area'
import { getAllDoctors } from '../../../../redux/features/appointments/bookingAppointmentAsyncActions'
import moment from 'moment'
import {
  LeftArrowIcon,
  ShareIcon,
} from '../../../../components/common/svg-components'
import SearchDropDown from '../../../../components/common/search-dropdown/SearchDropDown'
import { getIpdAllBedsList } from '../../../../redux/features/ipd-booking/ipdBookingAsyncActions'
import { Link } from 'react-router-dom'
import AddInsuranceModalV2 from '../../../../components/common/modal/add-insurance-modal/AddInsuranceModalV2'
import Popup from '../../../../components/common/popup/Popup'
import DescriptionDataModal from '../../../../components/common/modal/description-data-Modal/DescriptionDataModal'
import { IPD_INS_ADD_TYPE } from '../../../../constants/asyncActionsType'
import { calculateAge, trimValue, utcToDate } from '../../../../utils/utils'
import { clearIpdStoreData } from '../../../../redux/features/ipd/ipdSlice'
import { useTranslation } from 'react-i18next'
import useCurrency from '../../../../hooks/useCurrency'

const IpdCreateForm = ({ isRecep }: any) => {
  const [dischargeForm, setDischargeForm] = useState(false)
  const [ipdRequest, setIpdRequest] = useState(false)
  const [ipdInsurance, setIpdInsurance] = useState(false)
  const [searchString, setSearchString] = useState('')
  const [selectedItem, setSelectedItem] = useState({
    name: '',
    _id: '',
  })
  const [showNotesModal, setShowNotesModal] = useState<boolean>(false)
  const [notesPopupData, setNotesPopupData] = useState<any>({})
  const [showDescriptionModal, setShowDescriptionModal] =
    useState<boolean>(false)
  const [descriptionPopupData, setDescriptionPopupData] = useState<any>({})
  const navigate = useNavigate()
  // to get data from emr while create case
  const { patientDataObjectById, isLoading } = useAppSelector(
    (state) => state.patient
  )

  //to get data from attend case ipd
  const { storeIpdCaseById, isIpdCaseLoading, ipdLoading } = useAppSelector(
    (state) => state.ipd
  )

  const { ipdBedsData } = useAppSelector((state) => state.ipdBooking)
  const { doctorData } = useAppSelector((state) => state.appointments)
  const { branchData } = useAppSelector((state) => state.login)

  const getPatiendId = useLocation().state
  const checkIpdIdExist = getPatiendId?.hasOwnProperty?.('ipd_id') ?? false
  const checkIpdFromBooking =
    getPatiendId?.hasOwnProperty?.('is_booking') ?? false
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { formatCurrency } = useCurrency()

  const [patientAge, setPatientAge] = useState('')
  const [createAttendedAge, setCreateAttendedAge] = useState('')

  useEffect(() => {
    if (!checkIpdIdExist) {
      let dataPayload = {
        id: getPatiendId?.patient_id,
      }

      dispatch(getPatientEmrById(requestGenerator(dataPayload)))
    }
  }, [checkIpdIdExist, dispatch, getPatiendId])

  useEffect(() => {
    if (checkIpdFromBooking) {
      let dataPayload = {
        ipd_case_id: getPatiendId?.ipd_id,
      }

      dispatch(getCaseByIdIpdBooking(requestGenerator(dataPayload)))
    }
  }, [checkIpdFromBooking, dispatch, getPatiendId?.ipd_id])

  useEffect(() => {
    const resultData = {
      search: '',
      page: 0,
      pageSize: 100,
    }
    dispatch(getAllDoctors(requestGenerator(resultData)))
  }, [dispatch])

  let date: any = new Date().getFullYear()
  const createAge = date - Number(patientDataObjectById?.dob?.split('-')?.[0])
  const createAttendAge = date - Number(storeIpdCaseById?.dob?.split('-')?.[0])

  useEffect(() => {
    let patientAge = patientDataObjectById?.dob
    const formatData = patientAge ? utcToDate(patientAge, true) : ''
    let getSelectedDate = calculateAge(formatData)
    setPatientAge(getSelectedDate)
  }, [dispatch, patientDataObjectById?.dob])

  useEffect(() => {
    let patientAge = storeIpdCaseById?.dob
    const formatData = patientAge ? utcToDate(patientAge, true) : ''
    let getSelectedDate = calculateAge(formatData)
    setCreateAttendedAge(getSelectedDate)
  }, [dispatch, storeIpdCaseById?.dob])

  const admittedDate = moment(storeIpdCaseById?.admission_date).format(
    'DD-MMM-YYYY'
  )
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm()

  useEffect(() => {
    if (!checkIpdFromBooking && checkIpdIdExist) {
      let data = {
        ipd_id: getPatiendId?.ipd_id,
      }
      dispatch(getIpdCaseById(requestGenerator(data)))
    }
  }, [checkIpdFromBooking, checkIpdIdExist, dispatch, getPatiendId?.ipd_id])

  useEffect(() => {
    if (Object.keys(patientDataObjectById)?.length > 0) {
      setValue('fileNo', patientDataObjectById?.emr_no)
      setValue('patientName', patientDataObjectById?.name)
      setValue('age', patientAge)
      setValue('gender', patientDataObjectById?.gender)
      setValue('contactNo', patientDataObjectById?.phone)
      setValue('primaryDoctor', '')
      setValue('branchType', '')
    }
  }, [patientAge, patientDataObjectById, setValue])

  useEffect(() => {
    if (Object.keys(storeIpdCaseById)?.length > 0) {
      setValue('fileNo', storeIpdCaseById?.emr_no)
      setValue('patientName', storeIpdCaseById?.patient_name)
      setValue('age', createAttendedAge)
      setValue('gender', storeIpdCaseById?.gender)
      setValue('contactNo', storeIpdCaseById?.phone)
      setValue('caseNo', storeIpdCaseById?.case_no)
      setValue('caseDescription', storeIpdCaseById?.case_description)
      setValue('ward', storeIpdCaseById?.ward_name)
      setValue('bed', storeIpdCaseById?.bed_name)
      setValue('medicalNotes', storeIpdCaseById?.medical_notes)
      setValue('branchType', {
        label: storeIpdCaseById?.branch_name,
        value: storeIpdCaseById?.branch_id,
      })
      setValue('primaryDoctor', {
        label: storeIpdCaseById?.primary_doctor_name,
        value: storeIpdCaseById?.primary_doctor_id,
      })
      setValue('admittedDate', admittedDate)
    }
  }, [admittedDate, createAttendedAge, setValue, storeIpdCaseById])

  useEffect(() => {
    let requestData = {
      page: 1,
      pageSize: 10,
    }
    dispatch(getIpdAllBedsList(requestGenerator(requestData)))
  }, [dispatch])

  useEffect(() => {
    reset()
  }, [reset])

  const watchGenderValue = watch('gender')
  const onSubmit = (items: any) => {
    if (!checkIpdIdExist) {
      let data = {
        patient_id: patientDataObjectById?._id,
        primary_doctor_id: items?.primaryDoctor?.value,
        medical_notes: items?.medicalNotes,
        case_description: items?.caseDescription,
        branch_id: items?.branchType?.value,
        ward_id: items?.ward,
        bed_id: items?.bed,
        temporary_bed_id: selectedItem?._id?.length
          ? selectedItem?._id
          : undefined,
        admitted_date: items?.admittedDate,
        room_id: items?.ward,
        gender: items?.gender,
      }
      dispatch(ipdCreateCase(requestGenerator(data))).then((result) => {
        if (result.type === 'ipd/ipdCreateCase/fulfilled') {
          reset()
          setValue('primaryDoctor', '')
          setValue('branchType', '')
          const nav = isRecep ? '/ipd-invoice/patientinfo' : '/doctor'
          !checkIpdIdExist && navigate(nav)
        }
      })
    } else {
      let updatedData = {
        ipd_id: getPatiendId?.ipd_id,
        medical_notes: items?.medicalNotes,
        case_description: items?.caseDescription,
      }
      dispatch(updateCaseIpd(requestGenerator(updatedData)))
    }
  }
  const updateDate: any = new Date(storeIpdCaseById?.last_updated_at)

  //insurance notes
  const notesModalClose = () => {
    setNotesPopupData({})
    setShowNotesModal((prevState) => !prevState)
  }
  const descriptionModalClose = () => {
    setDescriptionPopupData({})
    setShowDescriptionModal((prevState) => !prevState)
  }
  const handleNotesModalOpen = (item: any) => {
    const payload = {
      description: item?.notes,
    }
    setShowNotesModal(!showNotesModal)
    setNotesPopupData(payload)
  }
  const handleDescriptionModalOpen = (item: any) => {
    const payload = {
      description: item?.details,
    }
    setShowDescriptionModal(!showDescriptionModal)
    setDescriptionPopupData(payload)
  }

  const handleSelectInsurance = (insurance: any) => {
    const payload = {
      _id: storeIpdCaseById?.ipd_id,
      insurance_plan_id: insurance?.insurance_plan_id,
    }
    dispatch(applyInsurancetoCase(requestGenerator(payload))).then((e) => {
      if (e.type === `${IPD_INS_ADD_TYPE}/fulfilled`) {
        let data = {
          ipd_id: getPatiendId?.ipd_id,
        }
        dispatch(getIpdCaseById(requestGenerator(data)))
      }
    })
  }

  useEffect(() => {
    return () => {
      dispatch(clearIpdStoreData())
    }
  }, [])

  return (
    <>
      {isRecep && (
        <Link
          to={'/ipd-invoice'}
          style={{
            marginBottom: '20px',
            color: '#0E26A3',
            display: 'block',
            fontSize: '18px',
          }}
        >
          <LeftArrowIcon
            fillColor="#0E26A3"
            height={14}
            customClass={styles.iconStyle}
          />
          {t('Common.Back')}
        </Link>
      )}
      {isLoading && <Loader />}
      {isIpdCaseLoading && <Loader />}
      {ipdLoading && <Loader />}
      <CustomModal
        showModal={dischargeForm}
        closeModal={() => setDischargeForm(false)}
        width="66%"
        height="580px"
        title={t('DischargeForm.Discharge Form') || 'Discharge Form'}
      >
        <DischargeFormModal />
      </CustomModal>
      <CustomModal
        showModal={ipdRequest}
        closeModal={() => setIpdRequest(false)}
        width="90%"
        height="580px"
        title={t('Header Title.Request') || 'Request'}
      >
        <IpdRequest closeModal={() => setIpdRequest(false)} />
      </CustomModal>
      <CustomModal
        showModal={ipdInsurance}
        closeModal={() => setIpdInsurance(false)}
        width="90%"
        height="580px"
        title={t('CreateIPDFrom.Add Insurance') || 'Add Insurance'}
      >
        <AddInsuranceModalV2
          popData={storeIpdCaseById}
          handleNotesPreview={handleNotesModalOpen}
          handleOpen={handleDescriptionModalOpen}
          handleClose={() => setIpdInsurance(false)}
          handleSaveInsurance={(item: any) => handleSelectInsurance(item)}
        />
      </CustomModal>
      {showNotesModal && (
        <Popup
          Children={DescriptionDataModal}
          handleClose={notesModalClose}
          popData={notesPopupData}
          heading={t('RequestDiagnosis.Notes') || 'Notes'}
        />
      )}
      {showDescriptionModal && (
        <Popup
          Children={DescriptionDataModal}
          handleClose={descriptionModalClose}
          popData={descriptionPopupData}
          heading={t('MobileAppConfiguration.Description') || 'Description'}
        />
      )}
      <section className={styles.ipdContainer}>
        <form
          className={styles.formContainer}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className={styles.formFields}>
            <div style={{ flexBasis: '50%' }}>
              <Input
                // labelText="File No."
                labelText={t('PatientEMR.File No.') || 'File No.'}
                {...register('fileNo')}
                disabled
                inlineClass={styles.disabledField}
              />
            </div>
            <div
              className={styles.inputFieldContainer}
              style={{ alignItems: 'flex-start' }}
            >
              <label
                htmlFor="Primary Doctor"
                className={styles.formLabel}
                style={{ marginTop: '7px' }}
              >
                {/* Primary Doctor */}
                {t('CreateIPDFrom.Primary Doctor') || 'Primary Doctor'}
                <span className="asterick">*</span>
              </label>
              <div className={styles.fieldAndErrorTxtContainer}>
                <Select
                  className={styles.selectInputField}
                  isDisabled={checkIpdIdExist}
                  // placeholder="Select doctor"
                  placeholder={
                    t('CreateIPDFrom.Select doctor') || 'Select doctor'
                  }
                  closeMenuOnSelect={true}
                  components={{ DropdownIndicator }}
                  value={watch('primaryDoctor')}
                  options={doctorData?.map((s: any) => {
                    return {
                      label: s?.doctor_name,
                      value: s?._id,
                    }
                  })}
                  {...register('primaryDoctor', { required: true })}
                  isSearchable={false}
                  onChange={(e: any) => {
                    setValue('primaryDoctor', e)
                  }}
                  maxMenuHeight={200}
                />
                {errors?.primaryDoctor?.type === 'required' && (
                  <p className={styles.formError}>
                    {t('RoomBooking.SelectDoctorValidation')}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className={styles.formFields}>
            <div style={{ flexBasis: '50%' }}>
              <Input
                // labelText="Case No."
                labelText={t('CreateIPDFrom.Case No.') || 'Case No.'}
                {...register('caseNo')}
                disabled
                inlineClass={styles.disabledField}
              />
            </div>
            <div style={{ flexBasis: '50%' }}>
              <Input
                // labelText="Patient Name"
                labelText={
                  t('ShareQuestionnaire.Patient Name') || 'Patient Name'
                }
                {...register('patientName')}
                disabled
                inlineClass={styles.disabledField}
              />
            </div>
          </div>
          <div className={styles.formFields}>
            <div style={{ flexBasis: '50%' }}>
              <Input
                // labelText="Contact No."
                labelText={t('CreateIPDFrom.Contact No.') || 'Contact No.'}
                {...register('contactNo')}
                disabled
                inlineClass={styles.disabledField}
              />
            </div>
            <div style={{ flexBasis: '50%' }}>
              <Input
                // labelText="Age"
                labelText={t('PatientEMR.Age') || 'Age'}
                {...register('age')}
                disabled
                inlineClass={styles.disabledField}
              />
            </div>
          </div>
          <div className={styles.formFields}>
            <div style={{ flexBasis: '50%' }}>
              <Input
                // labelText="Date Admitted"
                labelText={t('CreateIPDFrom.Date Admitted') || 'Date Admitted'}
                {...register('admittedDate')}
                disabled
                inlineClass={styles.disabledField}
              />
            </div>
            <div className={styles.radioFieldGenderContainer}>
              <span className={styles.radioFieldName}>
                {t('PatientEMR.Gender')}
              </span>
              <CustomRadio
                // label="Male"
                label={t('PatientEMR.Male') || 'Male'}
                customLabel={styles.customRadioLabel}
                {...register('gender')}
                checked={watchGenderValue === 'MALE'}
                disabled
              />
              <CustomRadio
                // label="Female"
                label={t('PatientEMR.Female') || 'Female'}
                customLabel={styles.customRadioLabel}
                {...register('gender')}
                checked={watchGenderValue === 'FEMALE'}
                disabled
              />
            </div>
          </div>
          <div className={styles.formFields}>
            <div style={{ flexBasis: '50%' }}>
              <Input
                // labelText="Ward"
                labelText={t('IPDBooking.Ward') || 'Ward'}
                {...register('ward')}
                disabled={checkIpdIdExist}
                inlineClass={checkIpdIdExist && styles.disabledField}
                placeholder="Please enter ward"
                onChange={(e) => trimValue(e)}
              />
            </div>
            <div style={{ flexBasis: '50%' }}>
              <Input
                // labelText="Bed"
                labelText={t('CreateIPDFrom.Bed') || 'Bed'}
                {...register('bed')}
                disabled={checkIpdIdExist}
                inlineClass={checkIpdIdExist && styles.disabledField}
                placeholder="Please enter bed"
                onChange={(e) => trimValue(e)}
              />
            </div>
          </div>
          <div className={styles.formFields}>
            <div className={styles.inputFieldContainer}>
              <label className={styles.formLabel}>
                {t('CreateIPDFrom.Temporary Bed') || 'Temporary Bed'}
              </label>
              <div className={styles.fieldAndErrorTxtContainer}>
                <SearchDropDown
                  searchString={searchString}
                  setSearchString={setSearchString}
                  dropdownDataToSee={ipdBedsData}
                  dropDownKeyName="bed_name"
                  placeholder={t('CreateIPDFrom.Search Bed') || 'Search Bed'}
                  customClass={styles.search}
                  customClassdata={styles.search}
                  setState={() =>
                    setSelectedItem({ name: 'Select All', _id: '' })
                  }
                  handleClick={(
                    item: any,
                    setVal: any,
                    setShowDropdown: any
                  ) => {
                    setVal(item?.bed_name)
                    setShowDropdown(false)
                    setSelectedItem({ name: item?.bed_name, _id: item?._id })
                  }}
                />
              </div>
            </div>
            <div
              className={styles.inputFieldContainer}
              style={{ alignItems: 'flex-start' }}
            >
              <label
                htmlFor="branchType"
                className={styles.formLabel}
                style={{ marginTop: '7px' }}
              >
                {/* Branch */}
                {t('Header.Branch') || 'Branch'}
                <span className="asterick">*</span>
              </label>
              <div className={styles.fieldAndErrorTxtContainer}>
                <Select
                  className={styles.selectInputField}
                  isDisabled={checkIpdIdExist}
                  // placeholder="Select Branch"
                  placeholder={
                    t('PatientEMRPlaceHolder.Select branch') || 'Select Branch'
                  }
                  closeMenuOnSelect={true}
                  value={watch('branchType')}
                  components={{ DropdownIndicator }}
                  options={branchData?.branches?.map((s: any) => {
                    return {
                      label: s?.name,
                      value: s?._id,
                    }
                  })}
                  {...register('branchType', { required: true })}
                  isSearchable={false}
                  onChange={(e: any) => {
                    setValue('branchType', e)
                  }}
                  maxMenuHeight={200}
                />
                {errors?.branchType?.type === 'required' && (
                  <p className={styles.formError}>
                    {t('OnlinePaymentValidator.SelectBranch')}
                  </p>
                )}
              </div>
            </div>
          </div>
          <TextArea
            rows={4}
            cols={45}
            // label="Case Description"
            label={t('CreateIPDFrom.Case Description') || 'Case Description'}
            requiredField
            {...register('caseDescription', { required: true })}
            showErrors={errors?.caseDescription?.type === 'required'}
            errorMessage={
              t('CreateIPDFrom.Enter Case Description') ||
              'Please enter case description'
            }
            placeholder={
              t('CreateIPDFrom.Enter Case Description') ||
              'Please enter case description'
            }
            onChange={(e) => trimValue(e)}
          />
          <TextArea
            rows={4}
            cols={45}
            // label="Medical Notes"
            label={t('CreateIPDFrom.Medical Notes') || 'Medical Notes'}
            requiredField
            {...register('medicalNotes', { required: true })}
            showErrors={errors?.medicalNotes?.type === 'required'}
            errorMessage={
              t('CreateIPDFrom.Enter Medical Notes') ||
              'Please enter medical notes'
            }
            // placeholder="Please enter medical notes"
            placeholder={
              t('CreateIPDFrom.Enter Medical Notes') ||
              'Please enter medical notes'
            }
            onChange={(e) => trimValue(e)}
          />
          {storeIpdCaseById?.insurance_plan_detail?.insurance_plan && (
            <div className={styles.formFields}>
              <div style={{ flexBasis: '50%' }}>
                <Input
                  labelText={t('Treatment.InsurancePlan') || 'Insurance Plan'}
                  disabled={true}
                  inlineClass={checkIpdIdExist && styles.disabledField}
                  value={
                    storeIpdCaseById?.insurance_plan_detail?.insurance_plan
                  }
                />
              </div>
            </div>
          )}

          <div className={styles.formBtnContainer}>
            <Button
              title={t('CreateIPDFrom.Add Insurance') || 'Add Insurance'}
              type="button"
              handleClick={() => setIpdInsurance(true)}
              disable={
                storeIpdCaseById?.insurance_plan_detail?._id
                  ? true
                  : !checkIpdIdExist
                  ? true
                  : false
              }
            />
            <Button
              title={t('Header Title.Request') || 'Request'}
              type="button"
              handleClick={() => setIpdRequest(true)}
              disable={checkIpdFromBooking || !checkIpdIdExist}
            />
            <Button
              title={
                <div>
                  <span style={{ marginRight: '7px' }}>
                    {t('CreateIPDFrom.Consent Form') || 'Consent Form'}
                  </span>
                  <ShareIcon fillColor="#ffffff" />
                </div>
              }
              type="button"
              disable={checkIpdFromBooking || !checkIpdIdExist}
              customClass={styles.btnStyle}
            />
            <Button
              title={
                checkIpdIdExist
                  ? t('CreateIPDFrom.Update Case') || 'Update Case'
                  : t('CreateIPDFrom.Submit Case') || 'Submit Case'
              }
              type="submit"
            />
            <Button
              title={t('CreateIPDFrom.Discharge') || 'Discharge'}
              handleClick={() => setDischargeForm(true)}
              type="button"
              disable={checkIpdFromBooking || !checkIpdIdExist}
            />
            {/* <ShareIcon fillColor="#0E26A3" /> */}
          </div>
        </form>
        <aside className={styles.userDataContainer}>
          <figure className={styles.userImage}>
            <img
              src={
                (patientDataObjectById?.patient_pic?.length
                  ? patientDataObjectById?.patient_pic
                  : storeIpdCaseById?.profile_pic) ?? dummyImage
              }
              alt="userimage"
            />
          </figure>
          <div className={styles.btnContainer}>
            <button>{t('CreateIPDFrom.IPD log')}</button>
            <button>{t('CreateIPDFrom.Vitals')}</button>
          </div>
          {checkIpdIdExist && (
            <p className={styles.userLastUpdate}>
              {t('CreateIPDFrom.Last update')}:
              {moment(updateDate).format('DD-MM-YYYY')}
            </p>
          )}
          <div className={styles.paymentContainer}>
            <p>
              {t('Common.Due')}
              {formatCurrency(patientDataObjectById?.outstanding_amount) ??
                formatCurrency(storeIpdCaseById?.outstanding_amount) ??
                0}
            </p>
            <p>
              {t('Common.Advance Due')}
              {formatCurrency(patientDataObjectById?.advance_amount) ??
                formatCurrency(storeIpdCaseById?.advance_amount) ??
                0}
            </p>
          </div>
        </aside>
      </section>
    </>
  )
}

export default IpdCreateForm
