import { createSlice } from "@reduxjs/toolkit";
import { IConfiguratinsDetails } from "../../../interfaces/apiInterface";
import { createConfigurationsDetails, getUserConfigurationsDetails } from "./configuratinsDetailsAsyncActions";

export const initialState: IConfiguratinsDetails = {
  isLoading: false,
};

export const configuratinsDetailsSlice = createSlice({
  name: "configuratinsDetailSlice",
  initialState,
  reducers: {},
  extraReducers(builder) {
    // create
    builder.addCase(createConfigurationsDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createConfigurationsDetails.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(createConfigurationsDetails.rejected, (state, error) => {
      state.isLoading = false;
    });

    // get
    builder.addCase(getUserConfigurationsDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUserConfigurationsDetails.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(getUserConfigurationsDetails.rejected, (state, error) => {
      state.isLoading = false;
    });
  },
});

export const {} = configuratinsDetailsSlice.actions;
export default configuratinsDetailsSlice.reducer;
