import React, { FC } from "react";
import styles from "./referral.module.scss";
import { SearchIcon } from "../../svg-components";
import { useAppSelector } from "../../../../hooks";
import { trimValue } from "../../../../utils/utils";
import { useTranslation } from "react-i18next";
import Button from "../../button/Button";

interface IReferral {
  handleDoctorPopup?: any;
  modleClose?: any;
}

const Referral: FC<IReferral> = ({ handleDoctorPopup, modleClose }) => {
  const { t } = useTranslation();

  const { invoiceObjectById } = useAppSelector((state) => state.invoice);
  const referredDoctor = useAppSelector(
    (state) => state.invoice?.invoiceObjectById?.refer_info?.doctor_name
  );
  const { doctorDataById } = useAppSelector((state) => state.receptionist);

  return (
    <div>
      {/* referral info container */}
      <div className={styles.referralContainer}>
        <div className={styles.referralLayout}>
          <div className={styles.labelFieldContainer}>
            <div className={styles.fieldErrorContainer}>
              <label htmlFor="internal" className={styles.radioLabel}>
                <input
                  className={styles.radioInput}
                  type="radio"
                  id="internal"
                  value="INTERNAL"
                  checked
                  //   {...register(INVOICE_REFERRAL_TYPE)}
                  // disabled={patientType === 'REGULAR' ? true : false}
                />
                <span className={styles.customRadio} />
                {t("InvoiceInfo.Internal")}
              </label>

              <label htmlFor="external" className={styles.radioLabel}>
                <input
                  className={styles.radioInput}
                  type="radio"
                  id="external"
                  value="EXTERNAL"
                  //   {...register(INVOICE_REFERRAL_TYPE)}
                  // disabled={patientType === "REGULAR" ? true : false}
                  disabled={true}
                />
                <span className={styles.customRadio} />
                {t("InvoiceInfo.External")}
              </label>
            </div>
          </div>

          <div className={styles.fieldContainer}>
            <label className={styles.labelText}>
              {t("InvoiceInfo.Doctor / Medical Center")}
              {/* <span className="asterick">*</span> */}
            </label>
            <div
              className={styles.fieldErrorContainer}
              onClick={() => {
                // if (patientType === 'OUT PATIENT') {
                handleDoctorPopup();
                // }
              }}
            >
              <input
                type="text"
                placeholder={
                  t("InvoiceInfo.Search doctor / medical center") ||
                  "Search doctor / medical center"
                }
                className={styles.inputField}
                value={
                  invoiceObjectById?.patient_id
                    ? referredDoctor !== undefined
                      ? referredDoctor
                      : doctorDataById?._id
                      ? doctorDataById?.doctor_name
                      : ""
                    : doctorDataById?._id
                    ? doctorDataById?.doctor_name
                    : ""
                }
                // {...register(INVOICE_DOCTOR_NAME)}
                onChange={(e) => trimValue(e)}
                disabled={true}
              />
              <SearchIcon
                fillColor="#797979"
                customClass={styles.searchIconStyle}
                // handleClick={handleDoctorPopup}
                // handleClick={() => {
                //   if (patientType === "OUT PATIENT") {
                //     handleDoctorPopup();
                //   }
                // }}
              />
            </div>
          </div>
        </div>
        <div className={styles.btnContainer}>
          <Button
            title={t("Common.Submit") || "Submit"}
            type="button"
            disable={
              doctorDataById?.doctor_name?.length || referredDoctor?.length
                ? false
                : true
            }
            handleClick={modleClose}
          />
        </div>
      </div>
    </div>
  );
};

export default Referral;
