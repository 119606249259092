import axios from "axios";
import { IAPIPayload } from "../../../interfaces/apiInterface";
import {
  ADD_ORDER_URL,
  ADJUSTMENT_FORM_UPDATE_API,
  DELETE_PHARMACY_ON_HOLD_DATA,
  EDIT_ORDER_URL,
  GET_OUTPATIENT_DETAIL_URL,
  GET_PHARMACY_ORDER_URL,
  PHARMACY_SUMMARY,
} from "../../../config/config";

export const getAllPharmacyOnholdsData = (data: IAPIPayload) => {
  return axios.post(GET_PHARMACY_ORDER_URL, data);
};

export const deletePharmacyOnholdsData = (data: IAPIPayload) => {
  return axios.post(DELETE_PHARMACY_ON_HOLD_DATA, data);
};

export const adjustmentFormApi = (data: IAPIPayload) => {
  return axios.post(ADJUSTMENT_FORM_UPDATE_API, data);
};

export const addOrder = (data: IAPIPayload) => {
  return axios.post(ADD_ORDER_URL, data);
};

export const editOrder = (data: IAPIPayload) => {
  return axios.post(EDIT_ORDER_URL, data);
};

export const getOutPatientDetail = (data: IAPIPayload) => {
  return axios.post(GET_OUTPATIENT_DETAIL_URL, data);
};
// Pharmacy Case Summary Widget
export const pharmacySummary = (data: IAPIPayload) => {
  return axios.post(PHARMACY_SUMMARY, data);
};
