import moment from "moment";
import { useEffect, useState } from "react";
import styles from "./labInvoiceTabledata.module.scss";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  allowedNumberOfDigitsAfterDecimal,
  disableArrowKey,
  disableScroll,
} from "../../utils/utils";
import { updatedNewPaymentAmountArray } from "../../redux/features/invoice-module/invoiceSlice";
import { DeleteIcon, PrintIcon } from "../../components/common/svg-components";
import { colors } from "../color";
import { t } from "i18next";

export const addInsuranceHeaderData: any = [
  {
    Header: "ID",
    accessor: "plan_id",
    Cell: ({ row, onPopClose, onRowClick }: any) => {
      const objectId = row?.original;
      return (
        <>
          <span style={{ cursor: "pointer", color: "#0e26a3" }}>
            {objectId?.plan_id}
          </span>
        </>
      );
    },
  },
  {
    Header: "INSURANCE PLAN",
    accessor: "insurance_plan",
  },
  {
    Header: "EXPIRY DATE",
    accessor: "expiry_date",
    Cell: ({ row }: any) => {
      const originalDate = row?.original?.expiry_date;
      const formattedDate = moment(originalDate).format("DD MMM YYYY");
      return <>{formattedDate}</>;
    },
  },
  {
    Header: "POLICY NO",
    accessor: "policy_no",
  },
  {
    Header: "DETAILS",
    accessor: "details",
    Cell: ({ row, onPopClose, onRowClick }: any) => {
      const objectId = row?.original;
      return (
        <>
          <span
            onClick={() => {
              onRowClick(row?.original);
            }}
            style={{ cursor: "pointer", color: "#0e26a3" }}
          >
            {t("Common.View")}
          </span>
        </>
      );
    },
  },
  {
    Header: "COPAY %",
    accessor: "coPay",
  },
  {
    Header: t("PatientHistoryTableHeader.NOTES"),
    accessor: "notes",
  },
];

// patient EMR: searchModalHeaderData
export const searchModalHeaderData: any = [
  {
    Header: t("RcptTableHeader.FILE NO"),
    accessor: "emr_no", //file_no
    Cell: ({ row, onPopClose, onRowClick, invoiceFlag }: any) => {
      const objectId = row?.original;
      const emrIsActive = row?.original?.is_active;
      return (
        <p
          className={
            emrIsActive === false
              ? styles.emrFileNoInactive
              : styles.emrFileNoActive
          }
          onClick={() => {
            onRowClick(row?.original);
          }}
        >
          {objectId?.emr_no}
        </p>
      );
    },
  },
  {
    Header: t("PatientHistoryFormHeader.PATIENT NAME"),
    accessor: "patient_name",
    Cell: ({ row }: any) => {
      const objectId = row?.original;
      const emrIsActive = row?.original?.is_active;
      return (
        <p className={emrIsActive === false ? styles.inactive : styles.active}>
          {objectId?.patient_name}
        </p>
      );
    },
  },

  {
    Header: t("PatientHistoryFormHeader.NATIONAL ID"),
    accessor: "national_id",
    Cell: ({ row }: any) => {
      const objectId = row?.original;
      const emrIsActive = row?.original?.is_active;
      return (
        <p className={emrIsActive === false ? styles.inactive : styles.active}>
          {objectId?.national_id}
        </p>
      );
    },
  },
  {
    Header: t("RcptTableHeader.MOBILE NO"),
    accessor: "phone",
    Cell: ({ row }: any) => {
      const objectId = row?.original;
      const emrIsActive = row?.original?.is_active;
      return (
        <p className={emrIsActive === false ? styles.inactive : styles.active}>
          {objectId?.phone}
        </p>
      );
    },
  },
];

// invoice: doctorModalHeaderData
export const doctorModalHeaderData: any = [
  {
    Header: t("RcptTableHeader.DOCTOR NAME"),
    Cell: ({ row, onPopClose, onRowClick, invoiceFlag }: any) => {
      const objectId = row?.original;

      return (
        <span
          onClick={() => {
            onRowClick(row?.original);
          }}
          style={{ cursor: "pointer", color: "#0e26a3" }}
        >
          {objectId?.doctor_name}
        </span>
      );
    },
  },

  {
    Header: t("RcptTableHeader.MOBILE NO"),
    accessor: "doctor_phone",
  },
];

//LabInvoice : PaymentTable
export const invoicePaymentHeaderData: any = [
  {
    Header: "PAYMENT MODE",
    accessor: "payment_mode",
  },

  {
    Header: "AMOUNT (E)",
    Cell: ({ row }: any) => {
      const dispatch = useAppDispatch();
      const { paymentModeData } = useAppSelector((state) => state.invoice);
      const [error, setError] = useState("");

      const validateInput = (inputValue: any) => {
        const pattern = /^\d{1,6}(?:\.\d{1,3})?$/;
        return pattern.test(inputValue);
      };

      const handleAmountChange = (event: any) => {
        const amount = event.target.value;
        const isValid = validateInput(amount);

        if (isValid === false) {
          setError("Please enter valid amount");
        }
        if (isValid === true) {
          setError("");
        }

        const updatedData = paymentModeData.map((item: any) => {
          if (item?._id === row?.original?._id) {
            return {
              ...item,
              // amount: parseInt(amount),
              amount: parseFloat(amount), 
            };
          }
          return item;
        });

        dispatch(updatedNewPaymentAmountArray(updatedData));
      };

      useEffect(() => {
        disableScroll();
      }, []);

      return (
        <>
          <input
            className={styles.inputFieldServiceTable}
            value={row?.original?.amount}
            type="number"
            key={row.original._id}
            onChange={handleAmountChange}
            onKeyDown={(e: any) => disableArrowKey(e)}
            onWheel={(e: any) => {
              e.target.blur();
            }}
             step="0.01"
          />
          <p className="dashboardFormError">{error}</p>
        </>
      );
    },
  },

  {
    Header: "APPROVAL NO.",
    accessor: "approval_no",
  },

  {
    Header: "ACTION",
    Cell: ({ row }: any) => {
      const dispatch = useAppDispatch();
      const { paymentModeData } = useAppSelector((state) => state.invoice);

      const handleRemovePaymentMode = (paymentMode: any) => {
        let tempArr: any = [];
        tempArr =
          paymentModeData && paymentModeData.length > 0
            ? paymentModeData?.filter((item: any) => {
                return item?._id !== paymentMode?._id;
              })
            : [];
        dispatch(updatedNewPaymentAmountArray(tempArr));
      };
      return (
        <>
          <DeleteIcon
            fillColor={colors.grey4}
            handleClick={() => handleRemovePaymentMode(row?.original)}
          />
        </>
      );
    },
  },
];

// Services Tests
export const LabTestsHeaderData: any = [
  {
    Header: t("LabJob.TEST_ID"),
    accessor: "test_no",
  },

  {
    Header: t("ViewReports.TEST NAME"),
    accessor: "test_name",
  },

  {
    Header: t("ViewInventory.QTY"),
    accessor: "quantity",
  },

  {
    Header: t("InsurancePlan_Form.PRICE"),
    accessor: "price",
  },
];

export const settledInvoiceHeaderDataV1: any = [
  {
    Header: t("PatientHistoryFormHeader.EMRNO"),
    accessor: "emr_no",
    Cell: ({ row }: any) => {
      return <>{row?.original?.emr_no ? row?.original?.emr_no : "-"}</>;
    },
  },
  {
    Header: t("PatientHistoryFormHeader.PATIENT NAME"),
    Cell: ({ row, onPopClose, onRowClick }: any) => {
      return (
        <>{row?.original?.patient_name ? row?.original?.patient_name : "-"}</>
      );
    },
  },
  {
    Header: t("PatientHistoryFormHeader.PHONE"),
    accessor: "patient_phone",
    Cell: ({ row }: any) => {
      return (
        <>{row?.original?.patient_phone ? row?.original?.patient_phone : "-"}</>
      );
    },
  },
  {
    Header: t("SettledInvoice.INVOICE NO"),
    accessor: "invoice_no",
    Cell: ({ row }: any) => {
      return (
        <>
          {
            //row?.original?.invoice_no ? String(row?.original?.invoice_no).padStart(6, '0') : '-'
            row?.original?.invoice_no ? row?.original?.invoice_no : "-"
          }
        </>
      );
    },
  },

  {
    Header: t("SettledInvoice.INVOICE DATE"),
    accessor: "createdAt", // Add an accessor for sorting
    sortType: (rowA: any, rowB: any, columnId: string, desc: boolean) => {
      // Custom sort function
      const dateA = rowA.original.createdAt;
      const dateB = rowB.original.createdAt;

      if (!dateA && !dateB) return 0; // Both dates are missing
      if (!dateA) return desc ? -1 : 1; // A is missing, B exists
      if (!dateB) return desc ? 1 : -1; // B is missing, A exists

      return desc
        ? new Date(dateB).getTime() - new Date(dateA).getTime()
        : new Date(dateA).getTime() - new Date(dateB).getTime();
    },
    Cell: ({ row }: any) => {
      const originalDate = row?.original?.createdAt;
      const formattedDate = originalDate
        ? moment(originalDate).format("DD MMM YYYY")
        : "-";
      return <>{formattedDate}</>;
    },
  },
  {
    Header: t("SettledInvoice.INVOICE AMOUNT"),
    Cell: ({ row }: any) => {
      const objectPaidAmount = allowedNumberOfDigitsAfterDecimal(
        row?.original?.total_amount,
        3
      );
      return (
        <>
          <span style={{ cursor: "pointer", color: "#0e26a3" }}>
            {objectPaidAmount ? objectPaidAmount : "0.000"}
          </span>
        </>
      );
    },
  },
  {
    Header: t("SettledInvoice.AMOUNT RECEIVED"),
    Cell: ({ row }: any) => {
      const objectReceivedAmount = allowedNumberOfDigitsAfterDecimal(
        row?.original?.paid_amount,
        3
      );
      return (
        <>
          <span style={{ cursor: "pointer", color: "#0e26a3" }}>
            {objectReceivedAmount ? objectReceivedAmount : "0.000"}
          </span>
        </>
      );
    },
  },
  {
    Header: t("SettledInvoice.INSURANCE INVOICE"),
    // accessor: "paid_amount",
    Cell: ({ row }: any) => {
      return (
        <>
          <span>
            {row?.original?.patient_insurance_id
              ? t("Common.Yes")
              : t("Common.No")}
          </span>
        </>
      );
    },
  },
  {
    Header: t("Voucher.Reference_No"),
    Cell: ({ row }: any) => {
      return (
        <>
          <span>
            {row?.original?.reference_no === ""
              ? "-"
              : row?.original?.reference_no}
          </span>
        </>
      );
    },
  },
  {
    Header: t("SettledInvoice.EMAIL"),
    Cell: ({ row, onRowClick }: any) => {
      return (
        <>
          <span
            onClick={() => {
              onRowClick({ ...row?.original, showEmail: true });
            }}
            style={{ cursor: "pointer", color: "#0e26a3" }}
          >
            {t("Common.View")}
          </span>
        </>
      );
    },
  },
  {
    Header: t("SettledInvoice.PRINT"),
    Cell: ({ row, onRowClick }: any) => {
      return (
        <>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              onRowClick({ ...row?.original, showEmail: false });
            }}
          >
            <PrintIcon />
          </span>
        </>
      );
    },
  },
];
