import { FC, useEffect, useRef, useState } from 'react'
import styles from './landingPage.module.scss'
import loginBackgroundImage from '../../../assets/images/loginImageHealthbox.png'
import { Outlet } from 'react-router'
import { useTranslation } from 'react-i18next'
import { TranslationIcon } from '../svg-components'
import TranslationMenu from '../translation-menu/TranslationMenu'
import { colors } from '../../../constants/color'

interface ILandingPage {}
const LandingPage: FC<ILandingPage> = () => {
  const [translationMenu, settranslationMenu] = useState<boolean>(false)
  const translationRef = useRef<any>()
  const { t } = useTranslation()
  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (
        translationMenu &&
        translationRef.current &&
        !translationRef.current.contains(e.target)
      ) {
        settranslationMenu(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [translationMenu])
  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.formStyleContainer}>
          <div className={styles.formStyle}>
            <Outlet />
          </div>
        </div>
        <div className={styles.landingPageImageContainer}>
          <img
            src={loginBackgroundImage}
            className={styles.landingPageImageStyle}
            alt="error to display"
          />
        </div>
        <div className={styles.translactionMenuContainer}>
          <TranslationIcon
            fillColor={colors.grey2}
            handleClick={() => settranslationMenu(!translationMenu)}
          />
          {translationMenu && (
            <TranslationMenu
              translationRef={translationRef}
              handleClose={() => settranslationMenu(false)}
              customClass={styles.translationMenu}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default LandingPage
