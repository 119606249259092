import React, { FC, ReactNode, useEffect, useRef, useState } from "react";
import styles from "./filter.module.scss";
import { FilterIcon, InfoIcon } from "../svg-components";
import { colors } from "../../../constants/color";

interface IFilterProps {
  title: string;
  children: ReactNode;
  customClassChild?: any;
  customClass?: any;
}

const Filter: FC<IFilterProps> = ({
  children,
  customClassChild,
  title,
  customClass,
}) => {
  const [show, setShow] = useState<boolean>(false);
  const ref = useRef<any>();
  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (show && ref.current && !ref.current.contains(e.target)) {
        setShow(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [show]);

  return (
    <div className={[styles.filterContainer, customClass].join(" ")} ref={ref}>
      <span>
        <FilterIcon
          fillColor={colors.grey2}
          handleClick={() => setShow(!show)}
        />
      </span>
      {show && (
        <div className={[styles.filterChildren, customClassChild].join(" ")}>
          <span className={styles.infoTitle}>{title}</span>
          {children}
        </div>
      )}
    </div>
  );
};

export default Filter;
