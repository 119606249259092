// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".popup_popup__MOsyV {\n  position: absolute;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  min-height: 100%;\n  top: 0;\n  left: 0;\n  background: rgba(0, 0, 0, 0.8);\n  overflow-y: auto;\n  margin: auto;\n  z-index: 2;\n}\n.popup_popup__MOsyV::-webkit-scrollbar {\n  display: block !important;\n  width: 7px !important;\n  height: 7px !important;\n}\n.popup_popup__MOsyV::-webkit-scrollbar-track {\n  background: transparent !important;\n}\n.popup_popup__MOsyV::-webkit-scrollbar-thumb {\n  background-color: var(--grey7) !important;\n  border-radius: 10px !important;\n  border: 1px solid var(--grey7) !important;\n}", "",{"version":3,"sources":["webpack://./src/components/common/popup/popup.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,gBAAA;EACA,MAAA;EACA,OAAA;EACA,8BAAA;EACA,gBAAA;EACA,YAAA;EACA,UAAA;AACF;AAAE;EACE,yBAAA;EACA,qBAAA;EACA,sBAAA;AAEJ;AAAE;EACE,kCAAA;AAEJ;AACE;EACE,yCAAA;EACA,8BAAA;EACA,yCAAA;AACJ","sourcesContent":[".popup {\n  position: absolute;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  min-height: 100%;\n  top: 0;\n  left: 0;\n  background: rgba(0, 0, 0, 0.8);\n  overflow-y: auto;\n  margin: auto;\n  z-index: 2;\n  &::-webkit-scrollbar {\n    display: block !important;\n    width: 7px !important;\n    height: 7px !important;\n  }\n  &::-webkit-scrollbar-track {\n    background: transparent !important;\n  }\n\n  &::-webkit-scrollbar-thumb {\n    background-color: var(--grey7) !important;\n    border-radius: 10px !important;\n    border: 1px solid var(--grey7) !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popup": "popup_popup__MOsyV"
};
export default ___CSS_LOADER_EXPORT___;
