import { FC } from 'react'
import styles from './viewMessage.module.scss'
import TableV2 from '../../components/common/table/tableV2/TableV2'
import { CloseIcon } from '../../components/common/svg-components'
import { colors } from '../../constants/color'
import Divider from '../../components/common/divider/Divider'
import { messageListData } from './hl7TableData'
import { t } from 'i18next'

interface IViewSpecialities {
  popData?: any
  handleClose?: any
}

const ViewMessage: FC<IViewSpecialities> = ({ popData, handleClose }) => {
  return (
    <>
      <div
        className={styles.popupContainer}
        onClick={(e) => e.stopPropagation()}
      >
        <CloseIcon
          customClass={styles.closeIconStyle}
          fillColor={colors.green1}
          handleClick={handleClose}
        />
        <div className={styles.viewContent}>
          <p className={styles.title}>{t('hl7.Message_Type')}</p>
          <Divider customClass={styles.dividerStyle} />
          <div className={styles.tableContainer}>
            <TableV2 tableHeaderData={messageListData} tableRowData={popData} />
          </div>
        </div>
      </div>
    </>
  )
}

export default ViewMessage
