// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".questionnaire_successContainer__n9c1h {\n  text-align: center;\n  margin: 150px 0;\n}\n.questionnaire_successContainer__n9c1h .questionnaire_title__v2A\\+P {\n  font-weight: var(--font-semibold);\n  font-size: var(--font-24);\n  color: var(--black2);\n  margin-top: 20px;\n}", "",{"version":3,"sources":["webpack://./src/pages/submit-questionnaire/questionnaire-submit/questionnaire.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,eAAA;AACF;AAAE;EACE,iCAAA;EACA,yBAAA;EACA,oBAAA;EACA,gBAAA;AAEJ","sourcesContent":[".successContainer {\n  text-align: center;\n  margin: 150px 0;\n  .title {\n    font-weight: var(--font-semibold);\n    font-size: var(--font-24);\n    color: var(--black2);\n    margin-top: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"successContainer": "questionnaire_successContainer__n9c1h",
	"title": "questionnaire_title__v2A+P"
};
export default ___CSS_LOADER_EXPORT___;
