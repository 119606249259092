import {
  KEY,
  MESSAGE,
  MOBILE_NO,
  REGISTER_MOBILE_NO,
} from "../constants/constant";
import { t } from "i18next";

export interface IConfigurationsValidators {
  [KEY]: {
    required: string;
  };
  [REGISTER_MOBILE_NO]: {
    required: string;
  };
  [MESSAGE]: {
    required: any;
  };
  [MOBILE_NO]: {
    required: string;
  };
}

export const whatsupValidators: IConfigurationsValidators = {
  [KEY]: {
    required: t("WhatsApp.EnterKey"),
  },
  [REGISTER_MOBILE_NO]: {
    required: t("WhatsApp.EnterRegisterMobileNo"),
  },
  [MESSAGE]: {
    required: t("WhatsApp.EnterMessage"),
  },
  [MOBILE_NO]: {
    required: t("WhatsApp.EnterMobileNo"),
  },
};
