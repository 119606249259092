import React, { FC, useEffect, useState } from 'react'
import styles from './manageSpecialities.module.scss'
import { useLocation, useNavigate } from 'react-router-dom'
import Button from '../../../../components/common/button/Button'
import Select from 'react-select'
import {
  manageSpecialitiesTableHeaderData,
  reactSelectStyle,
} from '../../../../constants/data'
import Table from '../../../../components/common/table/Table'
import { SubmitHandler, useForm } from 'react-hook-form'
import {
  DEPT,
  SPECIALITY,
  SPECIALITY_IMG,
} from '../../../../constants/constant'
import { specialityValidators } from '../../../../form-validators/specialityValidators'
import { requestGenerator } from '../../../../utils/payloadGenerator'
import { ADD_SPECIALITIES } from '../../../../config/config'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import Loader from '../../../../components/common/spinner/Loader'
import Popup from '../../../../components/common/popup/Popup'
import {
  addSpeciality,
  editSpeciality,
  getAllSpeciality,
  getSpecialityById,
} from '../../../../redux/features/specialities/specialitiesAsyncActions'
import StatusConfirmationPopup from '../../../../components/common/modal/status-confirmation-popup/StatusConfirmationPopup'
import { clearSpecialityInfo } from '../../../../redux/features/specialities/specialitiesSlice'
import makeAnimated from 'react-select/animated'
import Pagination from '../../../../components/common/pagination/Pagination'
import AttachFiles from '../../../../components/common/attach-files/single-file/AttachSingleFile'
import { dataURI } from '../../../../utils/utils'
import {
  getAllDepartment,
  getAllDepartmentDropdownData,
} from '../../../../redux/features/department/departmentAsyncActions'
import { trimValue } from '../../../../utils/utils'
import { t } from 'i18next'

interface IManageSpecialities {
  name?: string
  img_url?: string
  department_id?: any
  isEditing?: boolean
  setIsEditing?: React.Dispatch<React.SetStateAction<boolean>>
  editAppointmentData?: any
}

interface FileList {
  name: string
  data_uri: any
}

const ManageSpecialitiesNew: FC<IManageSpecialities> = ({}) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [toggle, setToggle] = useState<boolean>(false)
  const [toggleValue, setToggleValue] = useState()
  const [toggleData, setToggleData] = useState({})
  const [confirm, setConfirm] = useState<boolean>(false)
  const { isLoading, specialityData, specialityInfo } = useAppSelector(
    (state) => state.speciality
  )
  const [setId, setSetId] = useState('')
  const [dataPerPage, setDataPerPage] = useState<number>(10)
  const [totalPage, setTotalPage] = useState<number>(0)
  const [pageIndex, setPageIndex] = useState<number>(1)
  const [selectedValue, setSelectedValue] = useState(
    specialityInfo?.department_id?.name
  )
  const { departmentData, departmentDropdownData } = useAppSelector(
    (state) => state.department
  )

  let values = specialityInfo
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    trigger,
    watch,
    formState: { errors },
  } = useForm<IManageSpecialities>({
    defaultValues: {
      name: specialityInfo?.name,
    },
    values,
  })

  const formData = watch()

  const selectIconData = watch(SPECIALITY_IMG)
  const fileName = selectIconData?.[0]

  const [iconFiles, setIconFiles] = useState({ name: '', data_uri: '' })

  let deptData = departmentDropdownData?.map((item: any) => {
    return {
      name: item?.name,
      dept_id: item?._id,
    }
  })

  useEffect(() => {
    let reqData = {
      is_active: 'true',
      page: 1,
      pageSize: 1000,
    }
    dispatch(getAllDepartmentDropdownData(requestGenerator(reqData)))
  }, [dispatch])

  const pageIndexArray = () => {
    let pageIndexOptions = []
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i)
    }
    return pageIndexOptions
  }

  const pageIndexOptions = pageIndexArray()

  useEffect(() => {
    const fileList: FileList = { name: '', data_uri: '' }
    const getDataURI = async (fileName: File | any) => {
      try {
        const result = await dataURI(fileName)
        fileList.data_uri = result
        fileList.name = fileName.name
        setIconFiles(fileList)
      } catch (error) {
        console.log({ error })
      }
    }
    if (fileName) {
      getDataURI(fileName)
    }
  }, [fileName, setValue])

  useEffect(() => {
    if (specialityInfo?.department_id?._id !== undefined) {
      setValue(DEPT, {
        value: specialityInfo?.department_id?._id,
        label: specialityInfo?.department_id?.name,
      })
    } else {
      setValue(DEPT, null)
    }
  }, [specialityInfo?.department_id?._id])

  const handleEdit = (item: any) => {
    let data = {
      id: item?._id,
    }
    setSelectedValue(specialityInfo?.department_id?._id)
    dispatch(getSpecialityById(requestGenerator(data))).then((e) => {
      if (e.type === 'specialities/getSpecialitiesById/fulfilled') {
        setIconFiles({ name: '', data_uri: e?.payload?.specialty_img })
      }
    })
    setSetId(item?._id)
  }

  const onSubmit: SubmitHandler<IManageSpecialities> = (data: any) => {
    let file = {
      name: iconFiles?.name,
      data_uri: iconFiles?.data_uri,
    }

    data.file = file
    let reqPayload = {
      search: '',
      page: 0,
      pageSize: 10,
    }

    const { img_url, ...newObject } = data

    data = { ...data, [DEPT]: formData[DEPT]?.value || '' }

    if (specialityInfo.department_id?._id !== undefined) {
      let id = setId
      dispatch(editSpeciality(requestGenerator({ id, data }))).then((e) => {
        if (e.type === 'specialities/editSpecialities/fulfilled') {
          dispatch(getAllSpeciality(requestGenerator(reqPayload)))
          dispatch(clearSpecialityInfo())
          reset()
          setValue(SPECIALITY, '')
          setIconFiles({ name: '', data_uri: '' })
          setValue(DEPT, null)
          setValue(SPECIALITY_IMG, '')
        }
      })
    } else {
      dispatch(addSpeciality(requestGenerator(data))).then((e) => {
        if (e.type === 'specialities/addSpecialities/fulfilled') {
          dispatch(getAllSpeciality(requestGenerator(reqPayload)))
          dispatch(clearSpecialityInfo())
          reset()
          setValue(SPECIALITY, '')
          setIconFiles({ name: '', data_uri: '' })
          setValue(DEPT, null)
          setValue(SPECIALITY_IMG, '')
        }
      })
    }
  }

  let newDefault = {}

  useEffect(() => {
    let data = {
      search: '',
      page: pageIndex,
      pageSize: dataPerPage,
    }
    let reqData = {
      is_active: 'true',
    }
    dispatch(getAllSpeciality(requestGenerator(data))).then((result) =>
      setTotalPage(result.payload.lastPage)
    )
    dispatch(getAllDepartment(requestGenerator(reqData))).then((result) => {
      newDefault = {
        value: specialityInfo.department_id?.name,
        label: specialityInfo.department_id?._id,
      }

      setTotalPage(result.payload.lastPage)
    })
  }, [dispatch, pageIndex, dataPerPage])

  const handleActiveSpecialities = (item: any) => {
    setConfirm(!confirm)
    setToggleData(item)
    setToggle(item.is_active)
    setToggleValue(item?._id)
  }

  return (
    <>
      {isLoading && <Loader />}
      {confirm && (
        <Popup
          popData={toggleData}
          Children={StatusConfirmationPopup}
          handleClose={() => setConfirm(false)}
        />
      )}
      <div className={styles.manageSpecialitiesContainer}>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.labelField}>
            <label className={styles.labelText}>
              {t('DepartmentSetup.Dept')} <span className="asterick">*</span>
            </label>
            <div className={styles.fieldErrorContainer}>
              <Select
                className={styles.selectInputField}
                placeholder={t('DepartmentSetup.Dept placeholder')}
                closeMenuOnSelect={true}
                isSearchable={true}
                {...register(DEPT, specialityValidators[DEPT])}
                value={watch(DEPT)}
                options={deptData?.map((item: any) => ({
                  label: item?.name,
                  value: item?.dept_id,
                }))}
                onChange={(e: any) => {
                  setValue(DEPT, e)
                  trigger(DEPT)
                }}
                maxMenuHeight={200}
                styles={reactSelectStyle}
              />
              {errors[DEPT] && (
                <p className="dashboardFormError">
                  {errors[DEPT].message as any}
                </p>
              )}
            </div>
          </div>
          <div className={styles.labelField}>
            <label className={styles.labelText}>
              {t('DepartmentSetup.Specialty Name')}{' '}
              <span className="asterick">*</span>
            </label>
            <div className={styles.fieldErrorContainer}>
              <input
                type="text"
                placeholder={
                  t('DepartmentSetup.Specialty Name placeholder') ||
                  'Enter Specialty'
                }
                className={styles.inputField}
                {...register(SPECIALITY, specialityValidators[SPECIALITY])}
                onChange={(e) => trimValue(e)}
              />
              {errors[SPECIALITY] && (
                <p className="errorText">{errors[SPECIALITY].message}</p>
              )}
            </div>
          </div>
          <div className={styles.labelField}>
            <label className={styles.labelText} htmlFor={SPECIALITY_IMG}>
              {t('DepartmentSetup.Specialty Photo')}{' '}
              <span className="asterick">*</span>
            </label>
            <div className={styles.fieldErrorContainer}>
              <AttachFiles
                fileKey={SPECIALITY_IMG}
                id={SPECIALITY_IMG}
                register={register}
                fileList={iconFiles}
                validation={
                  iconFiles?.data_uri?.length > 0
                    ? {}
                    : specialityValidators[SPECIALITY_IMG]
                }
                isName={false}
              />
              {errors[SPECIALITY_IMG] && (
                <p className="errorText">{errors[SPECIALITY_IMG].message}</p>
              )}
            </div>
          </div>
          <div className={styles.btnContainer}>
            <Button title={t('Common.Submit') || 'Submit'} type="submit" />
            <Button
              title={t('BranchSetup.Reset') || 'Reset'}
              type="button"
              customClass={styles.backBtn}
              handleClick={() => {
                dispatch(clearSpecialityInfo())
                reset()
                setValue(SPECIALITY, '')
                setIconFiles({ name: '', data_uri: '' })
                setValue(DEPT, null)
              }}
            />
            <Button
              title={t('Common.Back') || 'Back'}
              type="button"
              customClass={styles.backBtn}
              handleClick={() => {
                navigate('/medicalcenter/department')
                reset()
                setIconFiles({ name: '', data_uri: '' })
              }}
            />
          </div>
          <div>
            {isLoading && <Loader />}
            <Table
              tableHeaderData={manageSpecialitiesTableHeaderData}
              tableRowData={specialityData}
              handleAction={handleEdit}
              handleActiveMC={handleActiveSpecialities}
              showSpeciality={false}
            />
          </div>
          {specialityData?.length > 0 && (
            <Pagination
              setDataPerPage={setDataPerPage}
              pageIndexOptions={pageIndexOptions}
              pageIndex={pageIndex}
              setPageIndex={setPageIndex}
            />
          )}
        </form>
      </div>
    </>
  )
}

export default ManageSpecialitiesNew
