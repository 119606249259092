export const MC_ADMIN_DASHBOARD = "678b818f88867ea2b3c45298";
export const MC_DASHBOARD = "64ec823efbe2e6b90cbe0119";
export const MANAGE_USER_ROLE = "64dde263bc74631d4f42cb1d";
export const MANAGE_STAFF = "64dde270bc74631d4f42cb20";
export const MANAGE_MASTER_TABLE = "64ec8294fbe2e6b90cbe011f";
export const MOB_APP_CONFIGURATION = "64ec82affbe2e6b90cbe0121";
export const INS_MASTERS = "64ec82c3fbe2e6b90cbe0123";
export const SER_MASTERS = "64ec82f7fbe2e6b90cbe0127";
export const ONGING_CLAIMS = "64ec82ddfbe2e6b90cbe0125";
export const INVENTORY_MASTER_TABLE = "65092ff2d6c0459b02e12aca";
export const REPORT_GENERATOR = "651bbe4f427c449bbb6f58cb";
export const REPORT_BUILDER = "65c4984ada4ab5a131a87367";
export const INVENTORY_UNIT_TYPE_MAP = "64fea6ad2188af776a01978c";
export const INVENTORY_ITEM_MASTERS = "650ab220fcec2f5ae2369bb7";
export const DC_DASHBOARD = "64d6044495887b2e44e7dc70";
export const DC_DIAGNOSIS = "64d6044d95887b2e44e7dc72";
export const LAB_DASHBOARD = "67a479fd5ecea2f1eb4d1b15";
export const LAB_JOB = "64ec83c8fbe2e6b90cbe012f";
export const LAB_REQUEST = "64ec83e0fbe2e6b90cbe0131";
export const LAB_INVOICE = "64ec83fcfbe2e6b90cbe0133";
export const LAB_CONFIGURATION = "64ec842bfbe2e6b90cbe0135";
export const RADIOLOGY_DASHBOARD = "67a47a985ecea2f1eb4d1b16";
export const RADIOLOGY_JOB = "64ec844bfbe2e6b90cbe0137";
export const RADIOLOGY_REQUEST = "64edb9fe537f7a84f0c11fec";
export const RADIOLOGY_INVOICE = "64ec848afbe2e6b90cbe0139";
export const RADIOLOGY_CONFIGURAION = "64ec84dafbe2e6b90cbe013b";
export const DNT_DASHBOARD = "64f72ac08380cdd3a764cb37";
export const DNT_DIAGNOSIS = "64f72c068380cdd3a764cb6e";
export const INVENTORY_REQUEST = "64f72e418380cdd3a764cb74";
export const BRANCH_STORE_DASHBOARD = "67a47b6a5ecea2f1eb4d1b18";
export const BRANCH_STORE = "64f72f0b8380cdd3a764cb78";
export const MAIN_STORE_DASHBOARD = "67a47b6a5ecea2f1eb4d1b18";
export const MAIN_STORE = "64f72fa28380cdd3a764cb7c";
export const PURCHASE_INVOICE = "64f730008380cdd3a764cb80";
export const RECEP_DASHBOARD = "64d603683d33b619b0974b0b";
export const PATIENT_EMR = "64d603d595887b2e44e7dc6a";
export const RECEIPT = "64d6041c95887b2e44e7dc6e";
export const INVOICE = "64d603fa95887b2e44e7dc6c";
export const FORM_BUILDER = "64d6045d95887b2e44e7dc74";
export const IPD_ID = "64f842e35a9a1c887568f879";
export const IPD_CREATE_CASE = "6516a1827c7aeaedf4626b1b";
export const IPD_INVOICE = "64f84b605a9a1c887568f8ef";
export const CC_ADMIN_DASHBOARD = "652d25d7784ef3e21019dcb8";
export const CC_LEAD_POOL = "652d271c784ef3e21019dcbd";
export const CC_AGENT = "652d2791784ef3e21019dcc1";
export const CC_CAMPAIGN = "652d2833784ef3e21019dcc5";
export const CC_AGENT_DASHBOARD = "652d28d4784ef3e21019dcc9";
export const SCHEDULAR = "64f1c2d6f9cd64565fef48bc";
export const CRM_ADMIN_KEY = "655c6b5c7f383026c7bfaf9c";
export const PHARMACY_DASHBOARD = "67a47afe5ecea2f1eb4d1b17";
export const PHARMACY_POS = "65604f7fdde06d5c6a124ec0";
export const PHARMACY_STORE = "65604febdde06d5c6a124ec4";
export const GET_QR_CODE = "64f5814d3c10607a9b8266a3";
export const REPORT = "64f57f913c10607a9b826699";
export const PATIENT_ACTIVITY_LOG = "64f57fe53c10607a9b82669e";
export const TAGGED_PATIENT_MODULE = "64f57dd43c10607a9b82668a";
export const DYNAMIC_FORM_LIST = "64f57e313c10607a9b82668f";
export const KPI_REVENUE = "64f57f0b3c10607a9b826694";
export const PHARMACY_MAIN_STORE = "657831060335fe235667ea5d";
export const REPORT_MAPPING = "658a7a42b0f0b01b73408340";
export const IPD_BOOKING = "65969acbc1ae2dbe8f282025";
export const HR_DASHBOARD = "65f01d2ae7c0fac38b19e894";
export const EMP_MANAGEMENT = "65f01e04e7c0fac38b19e8fd";
export const DOCUMENTS = "65f01e36e7c0fac38b19e903";
export const PAYROLL = "65f02247e7c0fac38b19e9ad";
export const ATTENDANCE = "65f0226be7c0fac38b19e9b1";
export const LEAVE_MANAGEMENT = "65f0228ce7c0fac38b19e9b3";
export const ACCOUNT_DASHBOARD = "65f0239fe7c0fac38b19e9b5";
export const VIEW_CHART_OF_ACCOUNTS = "65f023cbe7c0fac38b19e9b7";
export const VOUCHERS = "65f0240fe7c0fac38b19e9b9";
export const REPORTS = "65f0242ae7c0fac38b19e9bb";
export const UNAVAILABILITY = "667e99b298cf5b22400b4c2f";
export const UPAY_CONFIG = "6684f65d45cc04df70104e31";
export const IMPORT_UTILITY = "668cc31c77fd189e47b1e8ff";
export const DYNAMIC_TEMPLATE = "668f87a436b36d1c9c0690e9";
export const VIEW_APPOINTMENT = "6757eb354e86902a68a1e19e";
export const MASTER_IMPORT = "67a491a85ecea2f1eb4d1b2f";
