import { createSlice } from '@reduxjs/toolkit'
import { IHL7 } from '../../../interfaces/apiInterface'
import {
  createHl7,
  getAllHl7,
  getIcd10Code,
  getLabIonicData,
  getRadioIonicData,
  updateHl7,
  updateStatusHl7,
} from './hl7AsyncActions'

export const initialState: IHL7 = {
  isLoading: false,
  hl7Data: [],
  error: null,
  icd10Data: [],
  labIonicData: [],
  radiologyIonicData: [],
}

export const hl7Slice = createSlice({
  name: 'hl7',
  initialState,
  reducers: {
    clearHL7Data: (state) => {
      state.error = null
      state.isLoading = false
      state.hl7Data = []
      state.icd10Data = []
      state.labIonicData = []
      state.radiologyIonicData = []
    },
  },
  extraReducers(builder) {
    // GET ALL HL7 DATA

    builder.addCase(getAllHl7.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getAllHl7.fulfilled, (state, action) => {
      state.isLoading = false
      state.hl7Data = action.payload?.data
    })
    builder.addCase(getAllHl7.rejected, (state, error) => {
      state.isLoading = false
      state.error = error
    })

    // CREATE HL7

    builder.addCase(createHl7.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(createHl7.fulfilled, (state) => {
      state.isLoading = false
    })
    builder.addCase(createHl7.rejected, (state, error) => {
      state.isLoading = false
      state.error = error
    })

    // UPDATE HL7

    builder.addCase(updateHl7.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(updateHl7.fulfilled, (state) => {
      state.isLoading = false
    })
    builder.addCase(updateHl7.rejected, (state, error) => {
      state.isLoading = false
      state.error = error
    })

    // UPDATE HL7 STATUS

    builder.addCase(updateStatusHl7.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(updateStatusHl7.fulfilled, (state) => {
      state.isLoading = false
    })
    builder.addCase(updateStatusHl7.rejected, (state, error) => {
      state.isLoading = false
      state.error = error
    })

    // get icd10 code data
    builder.addCase(getIcd10Code.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getIcd10Code.fulfilled, (state, action) => {
      state.isLoading = false
      state.icd10Data = action.payload?.data || []
    })
    builder.addCase(getIcd10Code.rejected, (state, error) => {
      state.isLoading = false
    })

    // get lab ionic code data
    builder.addCase(getLabIonicData.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getLabIonicData.fulfilled, (state, action) => {
      state.isLoading = false
      state.labIonicData = action.payload?.data || []
    })
    builder.addCase(getLabIonicData.rejected, (state, error) => {
      state.isLoading = false
    })

    // get radiology ionic code data
    builder.addCase(getRadioIonicData.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getRadioIonicData.fulfilled, (state, action) => {
      state.isLoading = false
      state.radiologyIonicData = action.payload?.data || []
    })
    builder.addCase(getRadioIonicData.rejected, (state, error) => {
      state.isLoading = false
    })
  },
})

export const { clearHL7Data } = hl7Slice.actions

export default hl7Slice.reducer
