import { FC, useState } from "react";
import styles from "./radiologyInvoicePayment.module.scss";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useNavigate } from "react-router-dom";
import {
  clearInvoicePatientData,
  savePaymentMode,
  setAmountData,
} from "../../../redux/features/invoice-module/invoiceSlice";
import ScribeDialog from "../../doctor-diagnosis/diagnosis/scribe-dialog/ScribeDialog";
import Button from "../../../components/common/button/Button";
import {
  ExportIcon,
  PrintIcon,
  ShareIcon,
} from "../../../components/common/svg-components";
import TableV2 from "../../../components/common/table/tableV2/TableV2";
import { invoicePaymentHeaderData } from "../../../constants/table-data/invoicePaymentTableData";
import {
  clearRadiologyInvoiceData,
  setRadiologyInvoiceLoading,
} from "../../../redux/features/radiology/radiologySlice";
import Popup from "../../../components/common/popup/Popup";
import PaymentLinkModal from "../../../components/common/modal/payment-link-modal/payment-link/PaymentLinkModal";
import AddPaymentModeModal from "../../../components/common/modal/receipt-add-payment-mode-modal/AddPaymentModeModal";
import { generateUpayLink } from "../../../redux/features/invoice-module/invoiceAsynActions";
import { requestGenerator } from "../../../utils/payloadGenerator";
import OnlinePaymentModal from "../../../components/common/modal/online-payment-modal/OnlinePaymentModal";
import {
  handleCalculateTotalAmountForPaymentMode,
  handlePadStart,
  requestToDownloadPdf,
  setInvoiceReceiptTemplateOptions,
} from "../../../utils/utils";
import Loader from "../../../components/common/spinner/Loader";
import InvoiceConformationModal from "../../../components/common/modal/invoice-conformation-modal/InvoiceConformationModal";
import moment from "moment";
import { SETTLED_INVOICE_VIEW } from "../../../config/config";
import { t } from "i18next";
import SettledEmailPopup from "../../../components/common/modal/settled-email-popup/SettledEmailPopup";
import { getAllTemplates } from "../../../redux/features/dynamic-template/dynamicTemplateAsyncActions";
import { GET_TEMPLATE_TYPE } from "../../../constants/asyncActionsType";
import { clearTemplateData } from "../../../redux/features/dynamic-template/dynamicTemplateSlice";
import useCurrency from "../../../hooks/useCurrency";

interface IRadiologyInvoicePayment {}

const RadiologyInvoicePayment: FC<IRadiologyInvoicePayment> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { formatCurrency } = useCurrency();
  const { generatedInvoiceObject, paymentModeData, invoiceLoading } =
    useAppSelector((state) => state.invoice);
  const { radiologyPatientInvoiceData, isLoading } = useAppSelector(
    (state) => state.radiology
  );
  const { masterPaymentModeData } = useAppSelector((state) => state.login);
  const [showScribeDialog, setShowScribeDialog] = useState<boolean>(false);
  const [showPaymentLinkModal, setShowPaymentLinkModal] =
    useState<boolean>(false);
  const [paymentLinkData, setPaymentLinkData] = useState<any>({});
  const [showPaymentModeModal, setShowPaymentModeModal] =
    useState<boolean>(false);
  const [showOnlinePaymentModeModal, setShowOnlinePaymentModeModal] =
    useState<boolean>(false);
  const [invoiceConformationModal, setinvoiceConformationModal] =
    useState<boolean>(false);
  const [onlinePaymentLinkData, setOnlinePaymentLinkData] = useState<any>({});
  const [showSettleEmailPopup, setshowSettleEmailPopup] = useState(false);
  const [templateOptions, setTemplateOptions] = useState<any[]>([]);

  const handlePaymentProcess = () => {
    setShowPaymentLinkModal(!showPaymentLinkModal);
  };
  //paymentLinkPopup modal close
  const handlePaymentLinkModalClose = () => {
    setShowPaymentLinkModal(!showPaymentLinkModal);
    setPaymentLinkData({});
  };
  const invoicePaymentModeModalOpen = () => {
    setShowPaymentModeModal((prevState) => !prevState);
  };

  const invoicePaymentModeModalClose = () => {
    setShowPaymentModeModal((prevState) => !prevState);
  };

  const addPaymentMode = (item: any) => {
    setShowPaymentModeModal((prevState) => !prevState);
    let requestPayload: any = {
      payment_mode: item?.payment_mode_name,
      payment_label: item?.payment_mode_label,
      amount: item?.amount ?? 0,
      _id: item?.payment_mode_id,
    };
    dispatch(savePaymentMode(requestPayload));
  };
  const addUpayData = (item: any) => {
    setPaymentLinkData(item);
    dispatch(setAmountData(item));
    setShowPaymentLinkModal(!showPaymentLinkModal);
  };

  const handleGenerateLink = () => {
    const modifyPaymentModeData = paymentModeData?.map((item: any) => {
      const { _id, ...rest } = item;
      return rest;
    });
    const requestPayload = {
      invoice_no: generatedInvoiceObject?.invoice_no,
      partial_payment_array: modifyPaymentModeData,
    };

    dispatch(generateUpayLink(requestGenerator(requestPayload))).then((e) => {
      if (e.type === "invoice/getGenerateUpayLink/fulfilled") {
        if (e?.payload?.link) {
          const onlinePaymentLinkData = {
            patient_default_branch_id:
              radiologyPatientInvoiceData?.patient_default_branch_id,
            name: radiologyPatientInvoiceData?.name,
            phone: radiologyPatientInvoiceData?.phone,
            email: radiologyPatientInvoiceData?.email,
            invoice_no: generatedInvoiceObject?.invoice_no,
            // amount: upayLinkObject?.amount,
            // link: upayLinkObject?.link,
            // transaction_id: upayLinkObject?.transaction_id,
            transaction_id: e?.payload?.transaction_id,
            amount: paymentLinkData?.amount || 0,
            link: e?.payload?.link,
            // link: null,
          };
          setShowOnlinePaymentModeModal(!showOnlinePaymentModeModal);
          setOnlinePaymentLinkData(onlinePaymentLinkData);
        } else {
          setinvoiceConformationModal(true);
          // navigate('/radiology-invoice/information')
          // dispatch(clearRadiologyInvoiceData())
          // dispatch(clearInvoicePatientData())
        }
      }
    });
  };
  const invoiceOnlinePaymentModalClose = () => {
    setShowOnlinePaymentModeModal(!showOnlinePaymentModeModal);
    setOnlinePaymentLinkData({});
  };

  const handleExit = () => {
    dispatch(clearRadiologyInvoiceData());
    dispatch(clearInvoicePatientData());
    navigate("/radiology-invoice/information");
  };

  const setPdfLoader = (loader: boolean) => {
    dispatch(setRadiologyInvoiceLoading(loader));
  };
  const sendEmail = (data: any) => {
    const requestedData = {
      _id: data?._id,
      email: data?.email,
      is_email: false,
      offset: moment()?.utcOffset(),
      view_id: data?.view_id,
    };
    requestToDownloadPdf(
      SETTLED_INVOICE_VIEW,
      requestGenerator(requestedData),
      "s",
      setPdfLoader,
      "print"
    );
    handleExit();
    dispatch(clearTemplateData());
  };
  return (
    <>
      {isLoading || invoiceLoading ? <Loader /> : ""}
      {showPaymentLinkModal && (
        <Popup
          Children={PaymentLinkModal}
          popData={paymentLinkData}
          handleClose={() => handlePaymentLinkModalClose()}
          setModelOpenClose={addUpayData}
        />
      )}
      {invoiceConformationModal && (
        <Popup
          Children={InvoiceConformationModal}
          // popData={searchModalData}
          handleClose={() =>
            setinvoiceConformationModal(!invoiceConformationModal)
          }
          handleNo={handleExit}
          setModelOpenClose={() => {
            dispatch(
              getAllTemplates(
                requestGenerator({ type: "invoice", page: 1, pageSize: 1000 })
              )
            ).then((e) => {
              if (e.type === `${GET_TEMPLATE_TYPE}/fulfilled`) {
                setTemplateOptions(
                  setInvoiceReceiptTemplateOptions(e.payload?.data)
                );
                setshowSettleEmailPopup(true);
              } else {
                const requestedData = {
                  _id: generatedInvoiceObject?._id,
                  email: radiologyPatientInvoiceData?.email,
                  is_email: false,
                  offset: moment()?.utcOffset(),
                };
                requestToDownloadPdf(
                  SETTLED_INVOICE_VIEW,
                  requestGenerator(requestedData),
                  "s",
                  setPdfLoader,
                  "print"
                );
                handleExit();
              }
            });
          }}
          heading={t("InvoicePayment.PrintInvoice") || "Print Invoice"}
          message={
            t("InvoicePayment.DoYouPrint") || "Do you want to print invoice?"
          }
        />
      )}
      {showSettleEmailPopup && (
        <Popup
          Children={SettledEmailPopup}
          handleClose={() => setshowSettleEmailPopup(!showSettleEmailPopup)}
          setModelOpenClose={() =>
            setshowSettleEmailPopup(!showSettleEmailPopup)
          }
          popData={{
            _id: generatedInvoiceObject?._id,
            email: radiologyPatientInvoiceData?.email,
            showEmail: false,
            options: templateOptions,
          }}
          handleYes={(data: any) => sendEmail(data)}
        />
      )}
      {showScribeDialog === true && (
        <ScribeDialog
          handleClose={() => setShowScribeDialog(false)}
          setScribeDropdownShow={false}
          customClassContainer={styles.customClassContainer}
          customClassCanvasContainer={styles.customClassCanvasContainer}
        />
      )}
      {showPaymentModeModal && (
        <Popup
          Children={AddPaymentModeModal}
          popData={masterPaymentModeData}
          handleClose={invoicePaymentModeModalClose}
          handleYes={addPaymentMode}
        />
      )}
      {showOnlinePaymentModeModal && (
        <Popup
          Children={OnlinePaymentModal}
          popData={onlinePaymentLinkData}
          handleClose={() => invoiceOnlinePaymentModalClose()}
          setModelOpenClose={setShowOnlinePaymentModeModal}
          message="/radiology-invoice/information"
        />
      )}
      <form className={styles.mainContainer}>
        <div className={styles.mainServiceContainer}>
          {/*invoiceInfoContainer*/}
          <div className={styles.invoiceInfoContainer}>
            <div className={styles.userNameContainer}>
              <p className={styles.userTextStyle}>
                {t("InvoicePayment.Invoice No")} :
              </p>
              <p className={styles.userNameStyle}>
                {
                  generatedInvoiceObject?.invoice_no
                    ? generatedInvoiceObject?.invoice_no
                    : "-"
                  //generatedInvoiceObject?.invoice_no ? handlePadStart(generatedInvoiceObject?.invoice_no) : '-'
                }
              </p>
            </div>

            {generatedInvoiceObject?.fixed_file_number ? (
              <div className={styles.invoiceDateContainer}>
                <p className={styles.invoiceDateTextStyle}>
                  {" "}
                  {t("LabJob.Fixed_File_No")} :
                </p>
                <p className={styles.invoiceDateStyle}>
                  {generatedInvoiceObject?.fixed_file_number}
                </p>
              </div>
            ) : (
              ""
            )}
          </div>
          {/* payment container */}
          <div className={styles.paymentMainContainer}>
            <div className={styles.paymentContainer}>
              <div className={styles.outstandingStyle}>
                {t("InvoicePayment.Outstanding Amount")}
                {/* {generatedInvoiceObject?.invoice_no &&
                  generatedInvoiceObject?.outstanding_amount
                  ? formatCurrency(generatedInvoiceObject?.outstanding_amount)
                  : 0} */}
                {formatCurrency(generatedInvoiceObject?.outstanding_amount) ||
                  0}
              </div>

              {generatedInvoiceObject?.isOutPatient === false ? (
                <div className={styles.advanceDueStyle}>
                  {t("Common.Advance Due")}
                  {/* {generatedInvoiceObject?.invoice_no &&
                    radiologyPatientInvoiceData?.advance_amount
                    ? formatCurrency(
                      radiologyPatientInvoiceData?.advance_amount
                    )
                    : 0} */}
                  {formatCurrency(
                    radiologyPatientInvoiceData?.advance_amount
                  ) || 0}
                </div>
              ) : (
                ""
              )}
              {/* generatedInvoiceObject?.patient_insurance_id !== null */}
              {generatedInvoiceObject?.isOutPatient === true ||
              (generatedInvoiceObject?.isOutPatient === false &&
                generatedInvoiceObject?.patient_insurance_id === null) ? (
                ""
              ) : (
                <>
                  {/* <div className={styles.copayDetailContainer}>
                    <div className={styles.copayPercentageContainer}>
                      <label className={styles.labelText}>Co-pay %</label>
                      <input
                        type="text"
                        className={styles.inputField}
                        value={generatedInvoiceObject?.co_pay_percentage ?? ''}
                      />
                    </div>
                    <div className={styles.copayAmountContainer}>
                      <label className={styles.labelText}>Co-pay Amount</label>
                      <input
                        type="text"
                        className={styles.inputField}
                        value={generatedInvoiceObject?.co_pay_amount ?? ''}
                      />
                    </div>
                  </div> */}
                </>
              )}

              <div className={styles.buttonContainer}>
                <Button
                  type="button"
                  title={
                    t("InvoicePayment.Add Payment Mode") || "Add Payment Mode"
                  }
                  customClass={styles.addPaymentStyle}
                  handleClick={invoicePaymentModeModalOpen}
                  disable={
                    handleCalculateTotalAmountForPaymentMode(paymentModeData)
                      .amount >= generatedInvoiceObject?.outstanding_amount
                      ? true
                      : false
                  }
                />
                <div style={{ position: "relative" }}>
                  <Button
                    type="button"
                    title={
                      t("InvoicePayment.ESign") || `Customer's E-Signature`
                    }
                    customClass={styles.eSignButtonStyle}
                    handleClick={() => setShowScribeDialog(true)}
                    disable={true}
                  />
                  <ShareIcon
                    fillColor="#0E26A3"
                    customClass={styles.shareIcon}
                  />
                </div>
                <PrintIcon customClass={styles.printIcon} />
                <ExportIcon customClass={styles.exportIcon} />
              </div>

              {/* <div className={styles.scribeContainer}>
                <p className={styles.eSignText}>Customer's E-Signature</p>
              </div> */}
            </div>
            <div className={styles.paymentDetailContainer}>
              <p
                className={styles.linkGenreateContainer}
                onClick={handlePaymentProcess}
              >
                {t("InvoicePayment.ClickURL")}
              </p>
              <div className={styles.tableContainer}>
                <TableV2
                  tableHeaderData={invoicePaymentHeaderData}
                  tableRowData={
                    paymentModeData && paymentModeData?.length > 0
                      ? paymentModeData
                      : []
                  }
                  active={false}
                />

                <div className={styles.submitPaymentContainer}>
                  <Button
                    title={t("Common.Submit") || "Submit"}
                    type="button"
                    customClass={styles.buttonStyle}
                    handleClick={handleGenerateLink}
                    disable={
                      handleCalculateTotalAmountForPaymentMode(paymentModeData)
                        .amount > generatedInvoiceObject?.outstanding_amount
                        ? true
                        : paymentModeData?.length === 0
                        ? true
                        : paymentModeData?.some((item: any) => !item?.amount)
                        ? true
                        : false
                    }
                  />

                  <Button
                    title={t("ActionBar.Exit") || "Exit"}
                    type="button"
                    customClass={styles.backButton}
                    // handleClick={() => navigate('/invoice/services')}
                    handleClick={handleExit}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* action bar */}
        {/* <div className={styles.mainContainerFormActionSidebar}>
          <div className={styles.sidebarData}>
            {invoiceFormActionData?.map((item: any, index: any) => {
              return (
                <React.Fragment key={index}>
                  <div className={styles.iconStyleContainer} key={index}>
                    <item.icon
                      customClass={styles.iconStyle}
                      fillColor="#CDD4D8"
                      mouseEnter={() => setFormActionValue(index)}
                      mouseLeave={() => setFormActionValue(-1)}
                    />
                    <p className={styles.tooltiptext}>{item.name}</p>
                  </div>
                </React.Fragment>
              )
            })}
          </div>
        </div> */}
      </form>
    </>
  );
};

export default RadiologyInvoicePayment;
