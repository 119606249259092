import {
  CREATE_TAX_CONFIGURATION,
  UPDATE_TAX_CONFIGURATION,
  GET_TAX_CONFIGURATION,
  GET_ALL_TAX_CONFIGURATION,
} from "../../../constants/asyncActionsType";
import createAsyncThunkForSlice from "../../../utils/utils";
import { createTax, getAllTax, getTax, updateTax } from "./taxCrud";

export const createTaxConfiguration = createAsyncThunkForSlice(
  CREATE_TAX_CONFIGURATION,
  createTax,
  {
    isToast: true,
  }
);

export const getAllTaxConfiguration = createAsyncThunkForSlice(
  GET_ALL_TAX_CONFIGURATION,
  getAllTax
);

export const getTaxConfiguration = createAsyncThunkForSlice(
  GET_TAX_CONFIGURATION,
  getTax
);

export const updateTaxConfiguration = createAsyncThunkForSlice(
  UPDATE_TAX_CONFIGURATION,
  updateTax,
  {
    isToast: true,
  }
);
