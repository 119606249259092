import { CREATE_CONFIGURATION_DETAILS, GET_CONFIGURATION_DETAILS } from "../../../constants/asyncActionsType";
import createAsyncThunkForSlice from "../../../utils/utils";
import { configurationsDetails, getConfigurationsDetails } from "./configuratinsDetailsCrud";

export const createConfigurationsDetails = createAsyncThunkForSlice(
  CREATE_CONFIGURATION_DETAILS,
  configurationsDetails,
  {
    isToast: true,
  }
);

export const getUserConfigurationsDetails = createAsyncThunkForSlice(
  GET_CONFIGURATION_DETAILS,
  getConfigurationsDetails,
);
