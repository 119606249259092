// Booking Confirmation Constants

export const PATIENT_NAME = 'patient_name'
export const FILE_NO = 'patient_emr'
export const DOCTOR_NAME = 'doctor_name'
export const BOOKING_DATE = 'appointment_date'
export const START_TIME = 'start_time'
export const END_TIME = 'end_time'
export const MOBILE_NO = 'phone'
export const RECURRING_DOCTOR_NAME = 'recurring_doctor_name'
export const SESSION = 'session'
export const RECURRING_START_DATE = 'recurring_start_date'
export const TYPE = 'type'
export const SESSION_TIME = 'session_time'
export const RECURRING_START_TIME = 'recurring_start_time'
export const RECURRING_END_TIME = 'recurring_end_time'
export const INTERVAL = 'interval'
export const NOTES = 'problem_description'
export const DAY = 'day'
export const AVAILABLE_SLOT = 'appointment_time'
export const PATINET_MOBILE_NO = 'patient_phone'
export const APPOINTMENT_ID = 'apt_id'
export const STATUS_NAME = 'appointment_status'

// new constant for select time filed (15min interval)

export const START_TIME_HR = 'start_time_hr'
export const START_TIME_MIN = 'start_time_min'
export const END_TIME_HR = 'end_time_hr'
export const END_TIME_MIN = 'end_time_min'
// recurring time field constant
export const RECURRING_START_TIME_HR = 'recurring_start_time_hr'
export const RECURRING_START_TIME_MIN = 'recurring_start_time_min'
export const RECURRING_END_TIME_HR = 'recurring_end_time_hr'
export const RECURRING_END_TIME_MIN = 'recurring_end_time_min'
export const DOCTOR = "doctor"
