import React, {FC} from "react";
import styles from './viewAppointmentNotesDetailsModal.module.scss';
import { CloseIcon } from "../../components/common/svg-components";
import { colors } from "../../constants/color";
import Divider from "../../components/common/divider/Divider";
import { useTranslation } from "react-i18next";

interface IViewAppointmentNote {
    handleClose?: any
    open: boolean
    noteDetails: string
  }
  
  const ViewAppointmentNotesDetailDialog: FC<IViewAppointmentNote> = ({
    handleClose,
    open,
    noteDetails,
  }) => {
    const { t } = useTranslation()
    return (
      <>
        {/* Create New Form Dialog */}
        {open && (
          <div className="dialog">
            <div
              className={styles.mainContainer}
              onClick={(e) => e.stopPropagation()}
            >
              <div className={styles.iconContainer}>
                <CloseIcon
                  customClass={styles.closeIconStyle}
                  fillColor={colors.green1}
                  handleClick={handleClose}
                />
              </div>
  
              <header className={styles.headerContainer}>
                <span className={styles.title}>{t('Common.Notes')}</span>
                <Divider customClass={styles.dividerStyle} />
              </header>
  
              <section className={styles.sectionContainer}>{noteDetails}</section>
            </div>
          </div>
        )}
        {/* Create New Form Dialog */}
      </>
    )
  }
  export default ViewAppointmentNotesDetailDialog