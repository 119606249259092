import { FC, useState, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import styles from "../bookingConfirmationModal.module.scss";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import { IBookingConfirmationForm } from "../../../../../interfaces/bookingConfirmationModalInterfaces";
import {
  AVAILABLE_SLOT,
  BOOKING_DATE,
  DAY,
  DOCTOR_NAME,
  END_TIME,
  END_TIME_MIN,
  FILE_NO,
  INTERVAL,
  MOBILE_NO,
  NOTES,
  PATIENT_NAME,
  RECURRING_DOCTOR_NAME,
  RECURRING_END_TIME,
  RECURRING_END_TIME_HR,
  RECURRING_END_TIME_MIN,
  RECURRING_START_DATE,
  RECURRING_START_TIME,
  RECURRING_START_TIME_HR,
  RECURRING_START_TIME_MIN,
  SESSION,
  SESSION_TIME,
  START_TIME,
  START_TIME_HR,
  START_TIME_MIN,
  STATUS_NAME,
  TYPE,
} from "../../../../../constants/bookingConfirmationConstatnt";
import {
  appointmentDuration,
  calculateTotalMinutes,
  createSlots,
  disableArrowKey,
  filterAppointmentStatusList,
  isIqualSameDate,
  sortArray,
  trimValue,
  validatePhoneWithCode,
} from "../../../../../utils/utils";
import {
  BookingDeleteIcon,
  CheckIcon,
  CloseIcon,
  CrossIcon,
  PrintIcon,
  SearchIcon,
  UncheckIcon,
} from "../../../svg-components";
import { colors } from "../../../../../constants/color";
import { bookingConfirmationValidators } from "../../../../../form-validators/bookingConfirmationValidators";
import Button from "../../../button/Button";
import PhoneInput from "react-phone-input-2";
import Popup from "../../../popup/Popup";
import SearchModal from "../../search-modal/SearchModal";
import { requestGenerator } from "../../../../../utils/payloadGenerator";
import {
  bookingConfirmation,
  getAllDoctorAppointments,
  getAllPhysioAppointments,
  getAvailableSlots,
} from "../../../../../redux/features/appointments/bookingAppointmentAsyncActions";
import moment from "moment";
import makeAnimated from "react-select/animated";
import Select from "react-select";
import {
  clearSlotData,
  setAvialbleSlotsPayload,
  setSelectedSlots,
} from "../../../../../redux/features/appointments/bookingAppointmentsSlice";
import { BOOKING_CONFIRMATION_TYPE } from "../../../../../constants/asyncActionsType";
import { setMessage } from "../../../../../redux/features/toast/toastSlice";
import {
  daysList,
  sessionTimeData,
  warning,
  reactSelectStyle,
  hourDropdown,
  minDropdown,
  defaultBooking,
} from "../../../../../constants/data";
import { IInterval } from "../../../../../interfaces/interfaces";
import { useLocation, useNavigate } from "react-router-dom";
import { getPatientEmrById } from "../../../../../redux/features/patient-emr/patient/patientAsyncAction";
import { END_TIME_HR } from "../../../../../constants/bookingConfirmationConstatnt";
import { useTranslation } from "react-i18next";
import { getAllInternalDocotorReferral } from "../../../../../redux/features/referral/referralAsyncActions";
import { DropdownIndicator } from "../../../../../components/common/dropdown-indicator/DropdownIndicator";

interface IBookingConfirmationModalProps {
  heading?: string;
  message?: string;
  handleClose?: (e?: React.MouseEvent<HTMLElement>) => void;
  popData?: any;
  handleOpen?: any;
  setModelOpenClose?: any;
  branchId?: any;
}

const BookingConfirmationModalV2: FC<IBookingConfirmationModalProps> = ({
  heading,
  message,
  handleClose,
  popData,
  handleOpen,
  setModelOpenClose,
  branchId,
}) => {
  // Define state variables
  const { t } = useTranslation();
  const [recurringIcon, setRecurringIcon] = useState<boolean>(false);
  const [showSearchModal, setShowSearchModal] = useState<boolean>(false);
  const [searchModalData, setSearchModalData] = useState({});
  const [patientData, setPateintData] = useState<any>({});
  const {
    doctorData,
    selectedSlots,
    recurringSelectedSlots,
    availbleSlots,
    selectedBranch,
    currentViewDate,
  } = useAppSelector((state) => state.appointments);
  const { colorSchemeList } = useAppSelector((state) => state.login);
  const [totalAppointmentDuration, setTotalAppointmentDuration] = useState(0);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const [defaultSlots, setDefaultSlots] = useState([]);
  const [numberOfSession, setNumberOfSeesion] = useState<any>("");
  const [phoneState, setPhoneState] = useState<any>({});
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const path = useLocation().pathname;
  // booking from agent
  const location = useLocation();
  const { referDoctorData } = useAppSelector((state) => state.referral);
  const { branchData } = useAppSelector((state) => state.ipdBooking);
  const { branchData: profile } = useAppSelector((state) => state.login);
  const { roomBookingDetailById } = useAppSelector((state) => state.physio);
  // React Hook form for the form handling
  const {
    register,
    setError,
    clearErrors,
    handleSubmit,
    watch,
    getValues,
    trigger,
    setValue,
    formState: { errors },
  } = useForm<IBookingConfirmationForm>({});

  const watchDoctor = watch("doctor");
  // const selectedSlots = watch(AVAILABLE_SLOT)
  const selectedDate = watch(BOOKING_DATE);
  const selectedRecurringDate = watch(RECURRING_START_DATE);
  const startTime = watch(START_TIME);
  const endTime = watch(END_TIME);
  const startTimeHr = watch(START_TIME_HR);
  const startTimeMin = watch(START_TIME_MIN);
  const endTimeHr = watch(END_TIME_HR);
  const endTimeMin = watch(END_TIME_MIN);
  const recurringStartTime = watch(RECURRING_START_TIME);
  const recurringEndTime = watch(RECURRING_END_TIME);
  const recurringStartTimeHr = watch(RECURRING_START_TIME_HR);
  const recurringStartTimeMin = watch(RECURRING_START_TIME_MIN);
  const recurringEndTimeHr = watch(RECURRING_END_TIME_HR);
  const recurringEndTimeMin = watch(RECURRING_END_TIME_MIN);
  const sessionTime = watch(SESSION_TIME);
  const daysArray = watch(DAY);
  const interval = watch(INTERVAL);
  const handleRecurring = () => {
    if (patientData?._id) {
      setRecurringIcon((prevState) => !prevState);
    } else {
      dispatch(
        setMessage({
          message: "Please select patient witn emr from search",
          type: warning,
        })
      );
    }
  };

  const watchInterval: any = watch(INTERVAL);
  // Envoke on when we submit the form
  const onSubmit: SubmitHandler<IBookingConfirmationForm> = (data: any) => {
    const date = new Date(selectedDate);

    date.setHours(parseInt(selectedSlots[0]?.split(":")[0]) || 0);
    date.setMinutes(parseInt(selectedSlots[0]?.split(":")[1]) || 0);
    // date.setSeconds(parseInt(data[AVAILABLE_SLOT][0]?.value.split(':')[1]) || 0)
    // ** uncomment beloe two lines to add offset ** //
    // const offset = date.getTimezoneOffset()
    // date.setTime(date.getTime() + offset)

    const recurringSelectedSlotsWithTimestamp = recurringSelectedSlots?.map(
      (slotItem: any) => {
        const date = new Date(slotItem?.date);
        date.setHours(parseInt(slotItem?.time?.split(":")[0]) || 0);
        date.setMinutes(parseInt(slotItem?.time?.split(":")[1]) || 0);
        return { ...slotItem, appointment_timestamp: date };
      }
    );
    const mintues = calculateTotalMinutes(startTime, endTime);

    const payload = recurringIcon
      ? {
          // ...data,
          doctor_id: popData?._id ?? watchDoctor?.value,
          patient_id: patientData?._id,
          branch_id: branchId || "",
          appointment_status: "SCHEDULED",
          appointment_type: "INPERSON",
          appointment_id: popData?.appt_id || "",
          appointment_duration: sessionTime?.value,
          // status_name: statusName?.value,
          // appointment_date: date,
          // [AVAILABLE_SLOT]: selectedSlots,
          [SESSION]: Number(numberOfSession),
          recurring_details: recurringSelectedSlotsWithTimestamp,
          is_update: popData?.appt_id ? true : false,
          is_recurring: true,
          [NOTES]: data[NOTES],
        }
      : {
          ...data,
          doctor_id: popData?._id ?? watchDoctor?.value,
          patient_id: patientData?._id ? patientData?._id : undefined,
          branch_id: branchId || "",
          // appointment_status: popData?.appt_id ? "RESCHEDULED" : "SCHEDULED",
          [STATUS_NAME]: data[STATUS_NAME]?.value,

          appointment_duration: totalAppointmentDuration,
          appointment_type: "INPERSON",
          appointment_id: popData?.appt_id || "",
          appointment_date: date,
          [AVAILABLE_SLOT]: selectedSlots,
          is_update: popData?.appt_id ? true : false,
          is_booked_by_agent: location?.state?.lead?._id ? true : false,
          campaign_id: popData?.campaign_id ? popData?.campaign_id : undefined,
        };

    const selectedDateMiliseconds = new Date(selectedDate).getTime();
    const apptDateMilliSec = new Date(popData?.appointment_date).getTime();
    // const valid = validatePhoneWithCode(phoneState?.value, phoneState?.dialCode)
    if (recurringIcon) {
      if (recurringSelectedSlots && recurringSelectedSlots?.length > 0) {
        dispatch(bookingConfirmation(requestGenerator(payload))).then((e) => {
          if (e.type === `${BOOKING_CONFIRMATION_TYPE}/fulfilled`) {
            // dispatch(
            //   getAllDoctorAppointments(
            //     requestGenerator({ branch_id: branchId })
            //   )
            // );
            if (path === "/schedular") {
              dispatch(
                getAllDoctorAppointments(
                  requestGenerator({
                    branch_id: branchId ?? selectedBranch?._id,
                    date: new Date(currentViewDate),
                  })
                )
              );
            } else {
              dispatch(
                getAllPhysioAppointments(
                  requestGenerator({
                    branch_id: branchId ?? selectedBranch?._id,
                    date: new Date(currentViewDate),
                  })
                )
              );
            }
            handleClose && handleClose();
          }
        });
      } else {
        dispatch(
          setMessage({
            message: "Please select recurring time slots",
            type: warning,
          })
        );
      }
    } else if (
      !recurringIcon &&
      selectedSlots?.length === 0 &&
      data[STATUS_NAME]?.value === "SCHEDULED"
    ) {
      dispatch(
        setMessage({
          message: "No slots available in given time range",
          type: warning,
        })
      );
    } else if (totalAppointmentDuration > 120 || mintues > 120) {
      dispatch(
        setMessage({
          message: "Maximum appointment time is 2 hours",
          type: warning,
        })
      );
    } else if (popData?.appt_id) {
      if (
        apptDateMilliSec === selectedDateMiliseconds &&
        popData?.problem_description === data[NOTES] &&
        isIqualSameDate({ aptData: popData, formData: data }) &&
        data[STATUS_NAME]?.value === "RESCHEDULED"
      ) {
        dispatch(
          setMessage({
            message: "Please change appointment time or date",
            type: warning,
          })
        );
        return;
      } else {
        dispatch(bookingConfirmation(requestGenerator(payload))).then((e) => {
          if (e.type === `${BOOKING_CONFIRMATION_TYPE}/fulfilled`) {
            // dispatch(
            //   getAllDoctorAppointments(
            //     requestGenerator({ branch_id: branchId })
            //   )
            // );
            if (path === "/schedular") {
              dispatch(
                getAllDoctorAppointments(
                  requestGenerator({
                    branch_id: branchId ?? selectedBranch?._id,
                    date: new Date(currentViewDate),
                  })
                )
              );
            } else {
              dispatch(
                getAllPhysioAppointments(
                  requestGenerator({
                    branch_id: branchId ?? selectedBranch?._id,
                    date: new Date(currentViewDate),
                  })
                )
              );
            }
            handleClose && handleClose();
            if (location?.state?.lead?._id) {
              navigate("/agent/agentleads");
            }
          }
        });
      }
    } else {
      dispatch(bookingConfirmation(requestGenerator(payload))).then((e) => {
        if (e.type === `${BOOKING_CONFIRMATION_TYPE}/fulfilled`) {
          // dispatch(
          //   getAllDoctorAppointments(requestGenerator({ branch_id: branchId }))
          // );
          if (path === "/schedular") {
            dispatch(
              getAllDoctorAppointments(
                requestGenerator({
                  branch_id: branchId ?? selectedBranch?._id,
                  date: new Date(currentViewDate),
                })
              )
            );
          } else {
            dispatch(
              getAllPhysioAppointments(
                requestGenerator({
                  branch_id: branchId ?? selectedBranch?._id,
                  date: new Date(currentViewDate),
                })
              )
            );
          }
          handleClose && handleClose();
          if (location?.state?.lead?._id) {
            navigate("/agent/agentleads");
          }
        }
      });
    }
  };

  // search modal open -close
  const handleSearchModalClose = () => {
    setShowSearchModal(!showSearchModal);
  };

  // set doctor name field
  useEffect(() => {
    if (popData?._id) {
      const doctor = doctorData?.find(
        (item: any) => item?._id === popData?._id
      );
      setValue(DOCTOR_NAME, doctor?.doctor_name);
    }
  }, [doctorData]);

  const animatedComponent = makeAnimated();
  useEffect(() => {
    setTotalAppointmentDuration(appointmentDuration(selectedSlots, 15));
    // const startTime = selectedSlots[0]
    // const endTime = moment(selectedSlots[selectedSlots.length - 1], 'HH:mm')
    //   .add(15, 'minutes')
    //   .format('HH:mm')
    // // setValue(START_TIME, startTime)
    // // setValue(END_TIME, endTime)
  }, [selectedSlots]);

  useEffect(() => {
    return () => {
      dispatch(clearSlotData());
    };
  }, []);

  // set form values
  useEffect(() => {
    if (popData || roomBookingDetailById?._id) {
      const startDate = new Date(
        popData?.dateStart ?? roomBookingDetailById?.dateStart
      );
      const startHour =
        startDate.getHours() < 10
          ? `0${startDate.getHours()}`
          : startDate.getHours();
      const startMin =
        startDate.getMinutes() < 10
          ? `0${startDate.getMinutes()}`
          : startDate.getMinutes() || "00";
      const endDate = new Date(
        popData?.dateEnd ?? roomBookingDetailById?.dateEnd
      );
      const endHour =
        endDate.getHours() < 10 ? `0${endDate.getHours()}` : endDate.getHours();
      const endMin =
        endDate.getMinutes() < 10
          ? `0${endDate.getMinutes()}`
          : endDate.getMinutes() || "00";
      const editSlots = createSlots(startDate, endDate);
      setDefaultSlots(editSlots);
      // setValue(AVAILABLE_SLOT, editSlots || [])
      if (popData?.patient_id) {
        dispatch(setSelectedSlots(editSlots));
        dispatch(
          getPatientEmrById(
            requestGenerator({
              id: popData?.patient_id,
            })
          )
        );
      } else if (popData?.appt_id) {
        dispatch(setSelectedSlots(editSlots));
      }

      setValue(PATIENT_NAME, popData?.patient_name || "");
      setValue(FILE_NO, popData?.file_no || "");
      setValue(MOBILE_NO, popData?.patient_phone || "");

      setValue(
        BOOKING_DATE,
        popData?.appointment_date ||
          moment(startDate).format("YYYY-MM-DD") ||
          ""
      );

      setValue(START_TIME, `${startHour}:${startMin}` || "");
      setValue(END_TIME, `${endHour}:${endMin}` || "");
      // new state for 15min time interval
      setValue(START_TIME_HR, { label: `${startHour}`, value: `${startHour}` });
      setValue(START_TIME_MIN, { label: `${startMin}`, value: `${startMin}` });
      setValue(END_TIME_HR, {
        label: `${endHour}`,
        value: `${endHour}`,
      });
      setValue(END_TIME_MIN, { label: `${endMin}`, value: `${endMin}` });
      setPateintData({ _id: popData?.patient_id });
      setValue(NOTES, popData?.problem_description || "");
      if (popData?.appt_status) {
        setValue(STATUS_NAME, {
          label: popData?.appt_status,
          value: popData?.appt_status,
        });
      } else {
        setValue(STATUS_NAME, { label: "SCHEDULED", value: "SCHEDULED" });
      }
    }
  }, [popData, dispatch, setValue]);

  const handleClearPatientDetail = () => {
    setRecurringIcon(false);
    setPateintData({});
    setValue(PATIENT_NAME, "");
    setValue(FILE_NO, "");
    setValue(MOBILE_NO, "");
  };

  // view  time slots
  const handleViewSlots = (
    isRecurring: boolean,
    selectionDate: any,
    handler: any,
    timeStart: any,
    timeEnd: any,
    dateKey: any,
    sessionTime?: any,
    session?: any,
    interval?: any,
    days?: any[]
  ) => {
    const currentTime = moment().format("HH:mm");
    const date = selectionDate && moment(selectionDate);
    const dayIndex = date && date.weekday();
    const payload = isRecurring
      ? {
          is_recurring: true,
          doctor_id: popData?._id ?? watchDoctor?.value,
          current_time: currentTime,
          recurring_details: {
            session: Number(session),
            duration: sessionTime ?? "",
            interval: Number(interval),
            start_date: selectedRecurringDate,
            day: days || [],
            convenient_time: {
              start_time: recurringStartTime,
              end_time: recurringEndTime,
            },
          },
        }
      : {
          doctor_id: popData?._id ?? watchDoctor?.value,
          appointment_date: selectionDate,
          appointment_day: dayIndex,
          current_time: currentTime,
          appointment_id: popData?.appt_id ? popData?.appt_id : undefined,
          convenient_time: {
            start_time: timeStart,
            end_time: timeEnd,
          },
        };
    const currentDateFormated = moment(new Date()).format("YYYY-MM-DD");
    const currentDateMiliseconds = new Date(currentDateFormated).getTime();
    const selectedDateMiliseconds = new Date(selectionDate).getTime();
    // if (selectedDateMiliseconds >= currentDateMiliseconds) {
    dispatch(setAvialbleSlotsPayload(payload));
    clearErrors(dateKey);
    handler();
    // }

    // else {
    //   setError(dateKey, {
    //     type: "custom",
    //     message:
    //       t("BookingConfirmationValidators.PASTDATE") ||
    //       "Past date is not allowed!!",
    //   });
    // }
  };

  // handling auto select slots //

  useEffect(() => {
    const currentDateFormated = moment(new Date()).format("YYYY-MM-DD");
    const currentDateMiliseconds = new Date(currentDateFormated).getTime();
    const selectedDateMiliseconds = new Date(selectedDate).getTime();
    const date = selectedDate && moment(selectedDate);
    const dayIndex = date && date.weekday();
    const currentTime = moment().format("HH:mm");

    const payload = {
      doctor_id: popData?._id ?? watchDoctor?.value,
      appointment_date: selectedDate,
      appointment_day: dayIndex,
      current_time: currentTime,
      appointment_id: popData?.appt_id ? popData?.appt_id : undefined,

      convenient_time: {
        start_time: startTime,
        end_time: endTime,
      },
    };

    // if (selectedDateMiliseconds >= currentDateMiliseconds) {
    dispatch(setAvialbleSlotsPayload(payload));
    const {
      doctor_id,
      appointment_date,
      appointment_day,
      current_time,
      convenient_time,
    } = payload;
    const { start_time, end_time } = convenient_time;
    if (
      doctor_id &&
      appointment_date &&
      appointment_day?.toString() &&
      current_time &&
      convenient_time &&
      start_time &&
      end_time
    ) {
      dispatch(getAvailableSlots(requestGenerator(payload)));
      clearErrors(BOOKING_DATE);
    }
    clearErrors(BOOKING_DATE);
    // handler()
    // }
    // else {
    //   setError(BOOKING_DATE, {
    //     type: "custom",
    //     message:
    //       t("BookingConfirmationValidators.PASTDATE") ||
    //       "Past date is not allowed!!",
    //   });
    // }
  }, [popData?._id, selectedDate, startTime, endTime, watchDoctor?.value]);
  const handleNavigate = () => {
    navigate("/patientemr/sharequestionnaireemail");
  };

  const handleEmrRecord = (item: any) => {
    let dataPayload = {
      id: item?._id,
    };
    dispatch(getPatientEmrById(requestGenerator(dataPayload))).then((e) => {
      if (e.type === "patient/getPatientEmrById/fulfilled") {
        // setModelOpenClose(false);
        setShowSearchModal(false);
      }
    });
  };
  useEffect(() => {
    if (availbleSlots && availbleSlots?.length > 0) {
      const selctedSlots = availbleSlots
        ?.filter((item: any) => item?.selected)
        .map((item: any) => item?.value);
      dispatch(setSelectedSlots(selctedSlots));
    } else if (!popData?.appt_id) {
      dispatch(setSelectedSlots([]));
    }
  }, [availbleSlots]);

  useEffect(() => {
    if (totalAppointmentDuration > 120) {
      dispatch(
        setMessage({
          message: "Maximum appointment time is 2 hours",
          type: warning,
        })
      );
    }
  }, [totalAppointmentDuration]);

  useEffect(() => {
    dispatch(
      getAllInternalDocotorReferral(
        requestGenerator({ page: 1, pageSize: 100, branch: branchData?._id })
      )
    );
  }, [branchData?._id, dispatch]);

  return (
    <>
      {showSearchModal && (
        <Popup
          Children={SearchModal}
          popData={searchModalData}
          handleClose={() => setShowSearchModal(false)}
          setModelOpenClose={setShowSearchModal}
          handleRowClick={(item: any) => {
            setPateintData(item);
            setValue(PATIENT_NAME, item?.patient_name);
            setValue(FILE_NO, item?.emr_no);
            setValue(MOBILE_NO, item?.phone);
            setShowSearchModal(false);
            handleEmrRecord(item);
          }}
          isDefault={true}
        />
      )}

      <div
        className={styles.bookingConfirmationModalContainer}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {/* <CloseIcon
          customClass={styles.closeIconStyle}
          fillColor={colors.green1}
          handleClick={() => {
            handleClose && handleClose();
          }}
        />
        <div className={styles.iconConatainer}>
          <PrintIcon />
          <BookingDeleteIcon />
        </div>
        <h1 className={styles.bookingConfirmationModalHeading}>
          {t("BookingConfirmation.Booking Confirmation")}
        </h1>
        <hr className={styles.bookingConfirmationModalDivider} /> */}
        <form
          className={styles.bookingConfirmForm}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className={styles.filedsContainer}>
            <div className={styles.formFieldRow}>
              <div className={styles.formFieldContainer}>
                <div className={styles.inputFieldContainer}>
                  <label htmlFor={PATIENT_NAME} className={styles.formLabel}>
                    {t("ShareQuestionnaire.Patient Name")}
                    <span className="asterick">*</span>
                  </label>
                  <span
                    // onClick={() =>
                    //   popData?.patient_id ? {} : handleSearchModalClose()
                    // }
                    style={{ position: "relative" }}
                  >
                    <input
                      type="text"
                      className={styles.inputField}
                      {...register(
                        PATIENT_NAME,
                        bookingConfirmationValidators[PATIENT_NAME]
                      )}
                      placeholder={
                        t("ShareQuestionnaire.Patient Name") || "Patient Name"
                      }
                      disabled={
                        popData?.patient_id || patientData?._id ? true : false
                      }
                      autoComplete="off"
                    />
                    <span
                      className={styles.searchButton}
                      onClick={() =>
                        popData?.patient_id
                          ? {}
                          : patientData?._id
                          ? handleClearPatientDetail()
                          : handleSearchModalClose()
                      }
                    >
                      {popData?.patient_id ? (
                        <SearchIcon fillColor={colors.white1} />
                      ) : patientData?._id ? (
                        <CrossIcon fillColor={colors.white1} />
                      ) : (
                        <SearchIcon fillColor={colors.white1} />
                      )}
                    </span>
                  </span>
                </div>
                {errors[PATIENT_NAME] && (
                  <div className={styles.errorContainer}>
                    <div className={styles.errorExtraDiv}></div>
                    <p className={styles.formError}>
                      {errors[PATIENT_NAME].message}
                    </p>
                  </div>
                )}
              </div>
              <div className={styles.formFieldContainer}>
                <div className={styles.inputFieldContainer}>
                  <label htmlFor={FILE_NO} className={styles.formLabel}>
                    {t("PatientEMR.File No.")}
                  </label>
                  <input
                    type="text"
                    className={styles.inputField}
                    {...register(FILE_NO)}
                    placeholder={t("PatientEMR.File No.") || "File No."}
                    disabled
                  />
                </div>

                {/* {errors[FILE_NO] && (
            <p className={styles.formError}>{errors[FILE_NO].message}</p>
          )} */}
              </div>
            </div>

            <div className={styles.formFieldRow}>
              {popData?._id ? (
                <div className={styles.formFieldContainer}>
                  <div className={styles.inputFieldContainer}>
                    <label htmlFor={DOCTOR_NAME} className={styles.formLabel}>
                      {t("Receptionist Dashboard.Doctor")}
                      <span className="asterick">*</span>
                    </label>
                    <input
                      type="text"
                      className={styles.inputField}
                      {...register(DOCTOR_NAME)}
                      placeholder={
                        t("BookingConfirmation.EnterDoctor") ||
                        "Enter Doctor Name"
                      }
                      disabled
                    />
                  </div>
                </div>
              ) : (
                <div className={styles.formFieldContainer}>
                  <div className={styles.inputFieldContainer}>
                    <label htmlFor={DOCTOR_NAME} className={styles.formLabel}>
                      {t("Receptionist Dashboard.Doctor") || "Doctor"}
                      <span className="asterick">*</span>
                    </label>
                    <Select
                      className={styles.selectInputField}
                      placeholder={
                        t("IPDBooking.Select Doctor") || "Select Doctor"
                      }
                      value={watchDoctor}
                      {...register("doctor", { required: true })}
                      onChange={(e: any) => {
                        setValue("doctor", e);
                        // dispatch(setSelectedSlotStatus([]));
                      }}
                      isSearchable={true}
                      components={{ DropdownIndicator }}
                      options={referDoctorData?.map((items: any) => ({
                        label: items?.doctor_name,
                        value: items?._id,
                      }))}
                      maxMenuHeight={200}
                      styles={reactSelectStyle}
                    />
                  </div>
                </div>
              )}

              <div className={styles.formFieldContainer}>
                <div className={styles.inputFieldContainer}>
                  <label htmlFor={BOOKING_DATE} className={styles.formLabel}>
                    {t("ViewAppointment.Date")}
                    <span className="asterick">*</span>
                  </label>
                  <input
                    type="date"
                    className={styles.inputField}
                    max="9999-12-31"
                    // min={new Date().toISOString().split("T")[0]}
                    {...register(
                      BOOKING_DATE,
                      bookingConfirmationValidators[BOOKING_DATE]
                    )}
                  />
                </div>
                {errors[BOOKING_DATE] && (
                  <div className={styles.errorContainer}>
                    <div className={styles.errorExtraDiv}></div>
                    <p className={styles.formError}>
                      {errors[BOOKING_DATE].message}
                    </p>
                  </div>
                )}
              </div>
            </div>

            <div className={styles.formFieldRow}>
              <div className={styles.formFieldContainer}>
                <div className={styles.inputFieldContainer}>
                  <label className={styles.formLabel}>
                    {t("BookingConfirmation.Time")}
                    <span className="asterick">*</span>
                  </label>
                  <input
                    type="time"
                    className={styles.inputTimeField}
                    {...register(START_TIME)}
                    style={{ display: "none" }}
                    // disabled
                  />
                  <Select
                    className={styles.selectTimeInputField}
                    {...register(START_TIME_HR)}
                    isSearchable={true}
                    isClearable={true}
                    options={hourDropdown}
                    maxMenuHeight={200}
                    value={watch(START_TIME_HR) || []}
                    components={animatedComponent}
                    placeholder={t("BookingConfirmation.Hour") || "Hour"}
                    onChange={(e: any) => {
                      setValue(START_TIME_HR, e);
                      trigger(START_TIME_HR);
                      if (startTimeMin) {
                        setValue(
                          START_TIME,
                          `${e.value}:${startTimeMin.value}`
                        );
                      }
                    }}
                    styles={reactSelectStyle}
                  />
                  <Select
                    className={styles.selectTimeInputField}
                    {...register(START_TIME_MIN)}
                    isSearchable={true}
                    isClearable={true}
                    options={minDropdown?.filter((item) => item.label !== "59")}
                    maxMenuHeight={200}
                    value={watch(START_TIME_MIN) || []}
                    components={animatedComponent}
                    placeholder={t("BookingConfirmation.Min") || "Min"}
                    onChange={(e: any) => {
                      setValue(START_TIME_MIN, e);
                      trigger(START_TIME_MIN);
                      if (startTimeHr) {
                        setValue(START_TIME, `${startTimeHr.value}:${e.value}`);
                      }
                    }}
                    styles={reactSelectStyle}
                  />
                  <p>{t("BookingConfirmation.to")}</p>
                  <input
                    type="time"
                    className={styles.inputTimeField}
                    {...register(END_TIME)}
                    style={{ display: "none" }}
                    // min="12:00"
                    // max="18:00" // disabled
                  />
                  <Select
                    className={styles.selectTimeInputField}
                    {...register(END_TIME_HR)}
                    isSearchable={true}
                    isClearable={true}
                    options={hourDropdown}
                    maxMenuHeight={200}
                    value={watch(END_TIME_HR) || []}
                    components={animatedComponent}
                    placeholder={t("BookingConfirmation.Hour") || "Hour"}
                    onChange={(e: any) => {
                      setValue(END_TIME_HR, e);
                      trigger(END_TIME_HR);
                      if (endTimeMin) {
                        setValue(END_TIME, `${e.value}:${endTimeMin.value}`);
                      }
                    }}
                    styles={reactSelectStyle}
                  />
                  <Select
                    className={styles.selectTimeInputField}
                    {...register(END_TIME_MIN)}
                    isSearchable={true}
                    isClearable={true}
                    options={minDropdown?.filter((item) => item.label !== "59")}
                    maxMenuHeight={200}
                    value={watch(END_TIME_MIN) || []}
                    components={animatedComponent}
                    placeholder={t("BookingConfirmation.Min") || "Min"}
                    onChange={(e: any) => {
                      setValue(END_TIME_MIN, e);
                      trigger(END_TIME_MIN);
                      if (endTimeHr) {
                        setValue(END_TIME, `${endTimeHr.value}:${e.value}`);
                      }
                    }}
                    styles={reactSelectStyle}
                  />
                </div>
              </div>
              <div className={styles.formFieldContainer}>
                <div className={styles.inputFieldContainer}>
                  {/* <label className={styles.formLabel}></label> */}
                  <Button
                    title={t("ViewSlots.View Slots") || "View Slots"}
                    type="button"
                    disable={
                      selectedDate && startTime && endTime ? false : true
                    }
                    handleClick={() =>
                      handleViewSlots(
                        false,
                        selectedDate,
                        handleOpen,
                        startTime,
                        endTime,
                        BOOKING_DATE,
                        ""
                      )
                    }
                    customClass={styles.customButtonClass}
                  />
                </div>
              </div>
            </div>

            <div className={styles.formFieldRow}>
              <div
                className={[
                  styles.formFieldContainer,
                  // styles.singleFieldContainer,
                ].join(" ")}
              >
                <div className={styles.inputFieldContainer}>
                  <label htmlFor={MOBILE_NO} className={styles.formLabel}>
                    {t("PatientEMR.Mobile No.")}
                    <span className="asterick">*</span>
                  </label>

                  <PhoneInput
                    country={profile?.country?.country_code?.toLowerCase()}
                    {...register(
                      MOBILE_NO,
                      bookingConfirmationValidators[MOBILE_NO]
                    )}
                    value={getValues(MOBILE_NO)}
                    placeholder={
                      t("BookingConfirmation.Enter Phone No.") ||
                      "Enter Phone No."
                    }
                    onChange={(phone: any, country: any, e: any) => {
                      const formattedPhone = phone && `+${phone}`;
                      const valid = validatePhoneWithCode(
                        phone,
                        country?.dialCode
                      );
                      setPhoneState({
                        value: phone,
                        format: country?.format,
                        dialCode: country?.dialCode,
                      });
                      if (valid) {
                        setValue(MOBILE_NO, formattedPhone);
                        trigger(MOBILE_NO);
                        clearErrors(MOBILE_NO);
                      } else {
                        setError(MOBILE_NO, {
                          type: "custom",
                          message:
                            t("BookingConfirmationValidators.MOBILE_NO") ||
                            "Please Enter Valid Mobile no.",
                        });
                      }
                    }}
                    inputClass={styles.phoneNumberInput}
                    // disableCountryCode={true}
                    // disabled
                    enableSearch={true}
                    enableLongNumbers={true}
                  />
                </div>
                {errors[MOBILE_NO] && (
                  <div className={styles.errorContainer}>
                    <div className={styles.errorExtraDiv}></div>
                    <p className={styles.formError}>
                      {errors[MOBILE_NO].message}
                    </p>
                  </div>
                )}
              </div>
              <div className={styles.formFieldContainer}>
                <div className={styles.inputFieldContainer}>
                  <label htmlFor={STATUS_NAME} className={styles.formLabel}>
                    {t("MobileAppointmentReq.Status")}
                    <span className="asterick">*</span>
                  </label>
                  <Select
                    className={styles.selectInputField}
                    isSearchable={true}
                    options={filterAppointmentStatusList(
                      colorSchemeList,
                      popData?.appt_status || defaultBooking
                    ).map((item) => {
                      return {
                        label: item?.label,
                        value: item?.label,
                      };
                    })}
                    value={watch(STATUS_NAME)}
                    placeholder={
                      t("BookingConfirmation.Select Status Name") ||
                      "Select Status Name"
                    }
                    {...register(
                      STATUS_NAME,
                      bookingConfirmationValidators[STATUS_NAME]
                    )}
                    onChange={(e: any) => {
                      setValue(STATUS_NAME, e);
                      trigger(STATUS_NAME);
                    }}
                    // isDisabled={!popData?.appt_id ? true : false}
                    styles={reactSelectStyle}
                  />
                </div>
                {errors[STATUS_NAME] && (
                  <div className={styles.errorContainer}>
                    <div className={styles.errorExtraDiv}></div>
                    <p className={styles.formError}>
                      {errors[STATUS_NAME].message as any}
                    </p>
                  </div>
                )}
                {/* {errors[FILE_NO] && (
            <p className={styles.formError}>{errors[FILE_NO].message}</p>
          )} */}
              </div>
            </div>
            {popData?.patient_id ? (
              ""
            ) : patientData?._id ? (
              <>
                <div className={styles.recurringContainer}>
                  <div className={styles.extraForCheckBox}></div>
                  {recurringIcon ? (
                    <CheckIcon
                      fillColor={colors.green1}
                      handleClick={handleRecurring}
                    />
                  ) : (
                    <UncheckIcon
                      fillColor={colors.grey2}
                      handleClick={handleRecurring}
                    />
                  )}
                  <p className={styles.recurringText}>
                    {t("BookingConfirmation.Recurring")}
                  </p>
                </div>
                {recurringIcon && (
                  <>
                    <div className={styles.formFieldRow}>
                      <div className={styles.formFieldContainer}>
                        <div className={styles.inputFieldContainer}>
                          <label
                            htmlFor={RECURRING_DOCTOR_NAME}
                            className={styles.formLabel}
                          >
                            {t("Receptionist Dashboard.Doctor")}
                            <span className="asterick">*</span>
                          </label>
                          <input
                            type="text"
                            className={styles.inputField}
                            {...register(DOCTOR_NAME)}
                            placeholder={
                              t("BookingConfirmation.EnterDoctor") ||
                              "Enter Doctor Name"
                            }
                            disabled
                          />
                        </div>
                      </div>
                      <div className={styles.formFieldContainer}>
                        <div className={styles.inputFieldContainer}>
                          <label htmlFor={SESSION} className={styles.formLabel}>
                            {t("BookingConfirmation.Session")}
                            <span className="asterick">*</span>
                          </label>
                          <input
                            type="number"
                            className={styles.inputField}
                            {...register(
                              SESSION,
                              bookingConfirmationValidators[SESSION]
                            )}
                            onChange={(e: any) => {
                              trimValue(e);
                              setNumberOfSeesion(e.target.value);
                            }}
                            placeholder={
                              t("BookingConfirmation.Enter Session") ||
                              "Enter Session"
                            }
                            onKeyDown={(e: any) => disableArrowKey(e)}
                            onWheel={(e: any) => {
                              e.target.blur();
                            }}
                          />
                        </div>

                        {errors[SESSION] && (
                          <div className={styles.errorContainer}>
                            <div className={styles.errorExtraDiv}></div>
                            <p className={styles.formError}>
                              {errors[SESSION].message}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={styles.formFieldRow}>
                      <div className={styles.formFieldContainer}>
                        <div className={styles.inputFieldContainer}>
                          <label htmlFor={TYPE} className={styles.formLabel}>
                            {t("BookingConfirmation.Session Time")}
                            <span className="asterick">*</span>
                          </label>

                          <Select
                            className={styles.selectInputField}
                            isSearchable={true}
                            // isClearable={true}
                            options={sessionTimeData}
                            value={watch(SESSION_TIME)}
                            components={animatedComponent}
                            placeholder={
                              t("BookingConfirmation.Select Session Time") ||
                              "Select Session Time"
                            }
                            {...register(
                              SESSION_TIME,
                              bookingConfirmationValidators[SESSION_TIME]
                            )}
                            onChange={(e: any) => {
                              setValue(SESSION_TIME, e);
                              trigger(SESSION_TIME);
                            }}
                          />
                        </div>

                        {errors[SESSION_TIME] && (
                          <div className={styles.errorContainer}>
                            <div className={styles.errorExtraDiv}></div>
                            <p className={styles.formError}>
                              {errors[SESSION_TIME].message as any}
                            </p>
                          </div>
                        )}
                      </div>
                      <div className={styles.formFieldContainer}>
                        <div className={styles.inputFieldContainer}>
                          <label
                            htmlFor={RECURRING_START_DATE}
                            className={styles.formLabel}
                          >
                            {t("BookingConfirmation.Start Date")}
                            <span className="asterick">*</span>
                          </label>
                          <input
                            type="date"
                            className={styles.inputField}
                            max="9999-12-31"
                            min={new Date().toISOString().split("T")[0]}
                            {...register(
                              RECURRING_START_DATE,
                              bookingConfirmationValidators[
                                RECURRING_START_DATE
                              ]
                            )}
                          />
                        </div>
                        {errors[RECURRING_START_DATE] && (
                          <div className={styles.errorContainer}>
                            <div className={styles.errorExtraDiv}></div>
                            <p className={styles.formError}>
                              {errors[RECURRING_START_DATE].message}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className={styles.formFieldRow}>
                      <div className={styles.formFieldContainer}>
                        <div className={styles.inputFieldContainer}>
                          <label
                            htmlFor={INTERVAL}
                            className={styles.formLabel}
                          >
                            {t("BookingConfirmation.Interval")}
                            <span className="asterick">*</span>
                          </label>
                          <input
                            type="number"
                            placeholder={
                              t("BookingConfirmation.Enter Interval") ||
                              "Enter Interval"
                            }
                            className={styles.inputField}
                            {...register(
                              INTERVAL,
                              bookingConfirmationValidators[INTERVAL]
                            )}
                            onChange={(e: any) => {
                              setValue(INTERVAL, e.target.value);
                              trigger(INTERVAL);
                              setValue(DAY, !(watchInterval >= 7) && "");
                            }}
                            onKeyDown={(e: any) => disableArrowKey(e)}
                            onWheel={(e: any) => {
                              e.target.blur();
                            }}
                          />
                          {/* <Select
                          className={styles.selectInputField}
                          isSearchable={true}
                          // isClearable={true}
                          options={intervalData}
                          maxMenuHeight={190}
                          value={watch(INTERVAL)}
                          // defaultValue={intervalData[0]}
                          components={animatedComponent}
                          placeholder="Select Interval"
                          {...register(
                            INTERVAL,
                            bookingConfirmationValidators[INTERVAL]
                          )}
                          onChange={(e: any) => {
                            setValue(INTERVAL, e)
                            trigger(INTERVAL)
                          }}
                        /> */}
                        </div>
                        {errors[INTERVAL] && (
                          <div className={styles.errorContainer}>
                            <div className={styles.errorExtraDiv}></div>
                            <p className={styles.formError}>
                              {errors[INTERVAL].message as any}
                            </p>
                          </div>
                        )}
                      </div>

                      <div className={styles.formFieldContainer}>
                        <div className={styles.inputFieldContainer}>
                          <label htmlFor={DAY} className={styles.formLabel}>
                            {t("BookingConfirmation.Day")}
                            {/* <span className="asterick">*</span> */}
                          </label>
                          <Select
                            className={styles.selectInputField}
                            isSearchable={true}
                            isDisabled={!(watchInterval >= 7) ? true : false}
                            // isClearable={true}
                            isMulti
                            options={daysList}
                            maxMenuHeight={190}
                            value={watch(DAY)}
                            components={animatedComponent}
                            closeMenuOnSelect={false}
                            placeholder={
                              t("BookingConfirmation.Select Day") ||
                              "Select Day"
                            }
                            {...register(DAY)}
                            onChange={(e: any) => {
                              const sortedOptions = sortArray(e);
                              setValue(DAY, sortedOptions);
                              trigger(DAY);
                            }}
                          />
                        </div>
                        {/* 
                      {errors[DAY] && (
                        <div className={styles.errorContainer}>
                          <div className={styles.errorExtraDiv}></div>
                          <p className={styles.formError}>
                            {errors[DAY].message as any}
                          </p>
                        </div>
                      )} */}
                      </div>
                    </div>
                    <div className={styles.formFieldRow}>
                      <div className={styles.formFieldContainer}>
                        <div className={styles.inputFieldContainer}>
                          <label className={styles.formLabel}>
                            {t("BookingConfirmation.Time")}
                            <span className="asterick">*</span>
                          </label>
                          <input
                            type="time"
                            className={styles.inputTimeField}
                            style={{ display: "none" }}
                            {...register(RECURRING_START_TIME)}
                            // disabled
                          />
                          <Select
                            className={styles.selectTimeInputField}
                            {...register(RECURRING_START_TIME_HR)}
                            isSearchable={true}
                            isClearable={true}
                            options={hourDropdown}
                            maxMenuHeight={200}
                            value={watch(RECURRING_START_TIME_HR) || []}
                            components={animatedComponent}
                            placeholder={
                              t("BookingConfirmation.Hour") || "Hour"
                            }
                            onChange={(e: any) => {
                              setValue(RECURRING_START_TIME_HR, e);
                              trigger(RECURRING_START_TIME_HR);
                              if (recurringStartTimeMin) {
                                setValue(
                                  RECURRING_START_TIME,
                                  `${e.value}:${recurringStartTimeMin.value}`
                                );
                              }
                            }}
                            styles={reactSelectStyle}
                          />
                          <Select
                            className={styles.selectTimeInputField}
                            {...register(RECURRING_START_TIME_MIN)}
                            isSearchable={true}
                            isClearable={true}
                            options={minDropdown?.filter(
                              (item) => item.label !== "59"
                            )}
                            maxMenuHeight={200}
                            value={watch(RECURRING_START_TIME_MIN) || []}
                            components={animatedComponent}
                            placeholder={t("BookingConfirmation.Min") || "Min"}
                            onChange={(e: any) => {
                              setValue(RECURRING_START_TIME_MIN, e);
                              trigger(RECURRING_START_TIME_MIN);
                              if (recurringStartTimeHr) {
                                setValue(
                                  RECURRING_START_TIME,
                                  `${recurringStartTimeHr.value}:${e.value}`
                                );
                              }
                            }}
                            styles={reactSelectStyle}
                          />
                          <p>{t("BookingConfirmation.to")}</p>
                          <input
                            type="time"
                            className={styles.inputTimeField}
                            style={{ display: "none" }}
                            {...register(RECURRING_END_TIME)}
                            // disabled
                          />
                          <Select
                            className={styles.selectTimeInputField}
                            {...register(RECURRING_END_TIME_HR)}
                            isSearchable={true}
                            isClearable={true}
                            options={hourDropdown}
                            maxMenuHeight={200}
                            value={watch(RECURRING_END_TIME_HR) || []}
                            components={animatedComponent}
                            placeholder={
                              t("BookingConfirmation.Hour") || "Hour"
                            }
                            onChange={(e: any) => {
                              setValue(RECURRING_END_TIME_HR, e);
                              trigger(RECURRING_END_TIME_HR);
                              if (recurringEndTimeMin) {
                                setValue(
                                  RECURRING_END_TIME,
                                  `${e.value}:${recurringEndTimeMin.value}`
                                );
                              }
                            }}
                            styles={reactSelectStyle}
                          />
                          <Select
                            className={styles.selectTimeInputField}
                            {...register(RECURRING_END_TIME_MIN)}
                            isSearchable={true}
                            isClearable={true}
                            options={minDropdown?.filter(
                              (item) => item.label !== "59"
                            )}
                            maxMenuHeight={200}
                            value={watch(RECURRING_END_TIME_MIN) || []}
                            components={animatedComponent}
                            placeholder={t("BookingConfirmation.Min") || "Min"}
                            onChange={(e: any) => {
                              setValue(RECURRING_END_TIME_MIN, e);
                              trigger(RECURRING_END_TIME_MIN);
                              if (recurringEndTimeHr) {
                                setValue(
                                  RECURRING_END_TIME,
                                  `${recurringEndTimeHr.value}:${e.value}`
                                );
                              }
                            }}
                            styles={reactSelectStyle}
                          />
                        </div>
                      </div>
                      <div className={styles.formFieldContainer}>
                        <div className={styles.inputFieldContainer}>
                          <Button
                            title={t("ViewSlots.View Slots") || "View Slots"}
                            type="button"
                            disable={
                              numberOfSession &&
                              sessionTime &&
                              // daysArray?.length > 0 &&
                              interval &&
                              selectedRecurringDate &&
                              recurringStartTime &&
                              recurringEndTime
                                ? false
                                : true
                            }
                            handleClick={() => {
                              let days: String[] = [];
                              days =
                                daysArray?.length &&
                                daysArray?.map((item: IInterval) => {
                                  return item?.value ?? "";
                                });
                              if (
                                numberOfSession <= 0 ||
                                numberOfSession > 99
                              ) {
                                setError(SESSION, {
                                  type: "custom",
                                  message:
                                    t(
                                      "BookingConfirmationValidators.SESSIONNO"
                                    ) ||
                                    "Please enter session no between 1 to 99",
                                });
                              } else {
                                clearErrors(SESSION);
                                handleViewSlots(
                                  true,
                                  selectedRecurringDate,
                                  setModelOpenClose,
                                  recurringStartTime,
                                  recurringEndTime,
                                  RECURRING_START_DATE,
                                  sessionTime?.value,
                                  // statusName?.value,
                                  numberOfSession,
                                  interval,
                                  days
                                );
                              }
                            }}
                            customClass={styles.customButtonClass}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            ) : (
              ""
            )}

            <div className={styles.formFieldRow}>
              <div className={styles.formFieldContainer}>
                <div
                  className={[
                    styles.inputFieldContainer,
                    styles.textAreaFieldContainer,
                  ].join(" ")}
                >
                  <label htmlFor={NOTES} className={styles.formLabel}>
                    {t("Common.Notes")}
                    {/* <span className="asterick">*</span> */}
                  </label>
                  <div className={styles.textAreaContainer}>
                    <textarea
                      className={styles.textArea}
                      {...register(
                        NOTES /* bookingConfirmationValidators[NOTES]*/
                      )}
                      onChange={(e) => {
                        trimValue(e);
                      }}
                      placeholder={
                        t("BookingConfirmation.EnterNotes") || "Enter Notes"
                      }
                    />
                    {/* {errors[NOTES] && (
                    <p className={styles.formError}>{errors[NOTES].message}</p>
                  )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.formButtonContainer}>
            {/* <div className={styles.extraDiv}></div> */}
            <Button
              title={t("Common.Save") || "Save"}
              type="submit"
              disable={disableSubmit}
              customClass={styles.submitButton}
            />
            <Button
              title={t("BookingConfirmation.Add Nurse") || "Add Nurse"}
              type="button"
              // handleClick={() => handleReset()}
              customClass={styles.extraButton}
              disable={true}
            />
            <Button
              title={
                t("BookingConfirmation.Share Payment Link") ||
                "Share Payment Link"
              }
              type="button"
              // handleClick={() => handleReset()}
              customClass={styles.extraButton}
              disable={true}
            />
            <Button
              title={
                t("BookingConfirmation.Share Payment Link") ||
                "Enable smart notification"
              }
              type="button"
              // handleClick={() => handleReset()}
              customClass={styles.extraButton}
              disable={true}
            />
            <Button
              title={
                t("Medical History.Share Questionnaire") ||
                "Share Questionnaire"
              }
              type="button"
              disable={popData?.disable ? true : false}
              handleClick={() => handleNavigate()}
              customClass={styles.extraButton}
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default BookingConfirmationModalV2;
