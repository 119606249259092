import {
  ADDRESS_ONE,
  ADDRESS_TWO,
  BRANCH_INITIALS,
  BRANCH_NAME,
  DEFAULT_SEQUENCE_NO,
  CITY,
  COUNTRY,
  STATE,
  ZIPCODE,
  INVOICE_SEQUENCE,
  RECEIPT_SEQUENCE,
} from "../constants/constant";
import { t } from "i18next";

export interface IBranchValidators {
  [BRANCH_NAME]: {
    required: string;
  };
  [BRANCH_INITIALS]: {
    required: string;
  };
  [DEFAULT_SEQUENCE_NO]: {
    required: string;
  };
  [ADDRESS_ONE]: {
    required: string;
  };
  [ADDRESS_TWO]: {
    required: string;
  };
  [CITY]: {
    required: string;
  };
  [COUNTRY]: {
    required: string;
  };
  [STATE]: {
    required: string;
  };
  [ZIPCODE]: {
    required: string;
  };
  [INVOICE_SEQUENCE]: {
    required: number;
  };
  [RECEIPT_SEQUENCE]: {
    required: number;
  };
}

export const branchValidators = {
  [BRANCH_NAME]: {
    required: t("BranchSetup.Name Validations"),
  },
  [BRANCH_INITIALS]: {
    required: t("BranchSetup.Initials Validations"),
  },
  [DEFAULT_SEQUENCE_NO]: {
    required: t("BranchSetup.Sequence No Validations"),
    pattern: {
      value: /^\d{1,3}$/,
      message: t("BranchSetup.Branch Seq. Pattern"),
    },
  },

  [ADDRESS_ONE]: {
    required: "Please enter address line 1",
  },
  [ADDRESS_TWO]: {
    required: "Please enter address line 2",
  },
  [CITY]: {
    required: "Please enter city",
    pattern: {
      value: /^[a-zA-Z !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+$/i,
      message: "Please enter valid city name",
    },
  },
  [COUNTRY]: {
    required: "Please enter country",
    pattern: {
      value: /^[a-zA-Z !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+$/i,
      message: "Please enter valid country name",
    },
  },
  [STATE]: {
    required: "Please enter state",
    pattern: {
      value: /^[a-zA-Z !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+$/i,
      message: "Please enter valid state name",
    },
  },
  [ZIPCODE]: {
    required: "Please enter zipcode",
    pattern: {
      value: /^\d{4,10}$/,
      message: "Please enter valid 4 to 10 digit zipcode",
    },
  },

  [INVOICE_SEQUENCE]: {
    required: "Please enter invoice sequence number",
    pattern: {
      value: /^[0-9]+$/,
      message: "Please enter valid invoice sequence number",
    },
  },
  [RECEIPT_SEQUENCE]: {
    required: "Please enter receipt sequence number",
    pattern: {
      value: /^[0-9]+$/,
      message: "Please enter valid receipt sequence number",
    },
  },
};
