import { DeleteIcon } from '../../components/common/svg-components'
import moment from 'moment'
import { colors } from '../../constants/color'
import styles from '../../pages/receipt/receipt-landing-page/receipt.module.scss'
import { t } from 'i18next'

export const receiptOutstandingHeaderData: any = [
  {
    Header: t('SettledInvoice.INVOICE NO'),
    accessor: 'invoice_no',
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.invoice_no ? (
            <span>
              {
                //props?.row?.original?.invoice_no?.toString().padStart(6, '0')
                props?.row?.original?.invoice_no
              }
            </span>
          ) : (
            '-'
          )}
        </>
      )
    },
    disableSortBy: true,
  },
  {
    Header: t('SettledInvoice.INVOICE DATE'),
    accessor: 'createdAt',
    disableSortBy: true,
    Cell: (props: any) => {
      const invoice_sdate_formated = moment(
        props?.row?.original?.createdAt
      ).format('DD-MM-YYYY')
      return (
        <>
          {props?.row?.original?.createdAt ? (
            <span>{invoice_sdate_formated}</span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },

  {
    Header: t('SettledInvoice.INVOICE AMOUNT'),
    accessor: 'invoice_amount',
    disableSortBy: true,
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.total_amount ? (
            <span>{props?.row?.original?.total_amount?.toFixed(3)}</span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: t('SettledInvoice.AMOUNT RECEIVED'),
    accessor: 'paid_amount',
    disableSortBy: true,
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.paid_amount ? (
            <span>{props?.row?.original?.paid_amount?.toFixed(3)}</span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: t('Outstanding.OUTSTANDING AMOUNT'),
    accessor: 'outstanding_amount',
    disableSortBy: true,
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.outstanding_amount ? (
            <span>{props?.row?.original?.outstanding_amount?.toFixed(3)}</span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: t('DocUploadHeader.ACTION'),
    disableSortBy: true,
    Cell: (props: any) => {
      return (
        <DeleteIcon
          fillColor={colors.grey4}
          customClass={styles.iconStyle}
          handleClick={() => {
            props?.onClick(props?.row?.original)
          }}
        />
      )
    },
  },
]
