import { FC, useCallback, useEffect, useState } from "react";
import {
  CloseIcon,
  ExportIcon,
  PrintIcon,
  SearchButton,
} from "../../components/common/svg-components";
import { colors } from "../../constants/color";
import Divider from "../../components/common/divider/Divider";
import TableV2 from "../../components/common/table/tableV2/TableV2";
import { viewAppointmentPopupData } from "../../constants/table-data/viewAppointmentPopupData";
import Button from "../../components/common/button/Button";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { requestGenerator } from "../../utils/payloadGenerator";
import Loader from "../../components/common/spinner/Loader";
import Pagination from "../../components/common/pagination/Pagination";
import { trimValue } from "../../utils/utils";
import Popup from "../../components/common/popup/Popup";
import StatusSummaryPopup from "../../components/common/modal/view-appointment-popup/status-summary-popup/StatusSummaryPopup";
import AppointmentLogPopup from "../../components/common/modal/view-appointment-popup/appointment-log-popup/AppointmentLogPopup";
import ActionLogPopup from "../../components/common/modal/view-appointment-popup/action-log-popup/ActionLogPopup";
import {
  getAllExcelSheetData,
  getAllPatientAppointment,
} from "../../redux/features/appointment/appointmentAsyncActions";
import { useTranslation } from "react-i18next";
import styles from "./viewAppointment.module.scss";
import Filter from "../../components/common/filter/Filter";
import SideBar from "../main-layout/sidebar/SideBar";
import FutureReportPopup from "../../components/common/modal/view-appointment-popup/future-report-popup/futureReportPopup";
import { utils, writeFile, WorkBook, WorkSheet } from "xlsx";
import moment from "moment";
import ViewAppointmentNotesDetailDialog from "../ViewAppointmentNotesDetailModal/viewAppointmentNotesDetailsModal";
// import Select from "react-select/dist/declarations/src/Select";
import Select from "react-select";
import { getAllDoctors } from "../../redux/features/appointments/bookingAppointmentAsyncActions";
import { debounce } from "lodash";

interface IViewAppointment {
  handleStatusSummary?: any;
  handleAppointmentLog?: any;
  handleActionLog?: any;
  handleClose?: any;
  setModelOpenClose?: any;
}

const ViewAppointmentPopup: FC<IViewAppointment> = ({
  handleStatusSummary,
  handleAppointmentLog,
  handleActionLog,
  handleClose,
  setModelOpenClose,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { isLoading, appointmentData } = useAppSelector(
    (state) => state.appointment
  );
  const { allDoctorsList } = useAppSelector((state) => state.appointments);
  console.log(allDoctorsList, "allDoctorsList");

  const [dataPerPage, setDataPerPage] = useState<number>(10);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [searchDoctor, setSearchDoctor] = useState("");
  const [searchPatient, setSearchPatient] = useState("");


      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const day = String(today.getDate()).padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;

  const [searchDate, setSearchDate] = useState(formattedDate);
  const [searchFile, setSearchFile] = useState("");
  const [appointmentLog, setAppointmentLog] = useState<boolean>(false);
  const [actionLog, setActionLog] = useState<boolean>(false);
  const [statusSummary, setStatusSummary] = useState<boolean>(false);
  const [futureReport, setFutureReport] = useState<boolean>(false);
  const [showNoteDetails, setShowNoteDetails] = useState<string>("");
  const [search,setSearch] = useState("")
  const [showNotesDetailDialog, setShowNotesDetailDialog] =
    useState<boolean>(false);

  const [id, setId] = useState();
  const [active, setActive] = useState<boolean>(false);
  const [value, setValue] = useState();
  const [searchPhone, setSearchPhone] = useState("");
  const [status, setStatus] = useState("");

  const [selectedDoctor, setSelectedDoctor] = useState<string>("");
  const [doctorsList, setDoctorsList] = useState<any[]>([]);
  const [searchedDocter,setSearchedDocter] = useState("")

  const { branchData } = useAppSelector((state) => state.login);
  let branche = branchData?.branches;
  let branch_id = branche && branche.length > 0 && branche[0]?._id;
  const [branch, setBranch] = useState(branch_id);

  const pageIndexArray = () => {
    let pageIndexOptions = [];
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i);
    }
    return pageIndexOptions;
  };

  const pageIndexOptions = pageIndexArray();

  const [selectedOption, setSelectedOption] = useState("monthly");

  const handleOptionChange = (event: any) => {
    setSelectedOption(event.target.value);

    if (event.target.value === "today") {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const day = String(today.getDate()).padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;
      setSearchDate(formattedDate);
    }
  };

  // Handle the change of status
  const handleStatusChange = (event: any) => {
    setStatus(event.target.value);
    // console.log("status",event.target,value);
  };

  const dateFormat = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  let checkDate = dateFormat();

  function getMonthRange(dateString = "") {
    const date = dateString ? new Date(dateString) : new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0") as any;

    const startOfMonth = `${year}-${month}-01`;
    const endOfMonth = `${year}-${month}-${new Date(year, month, 0).getDate()}`;

    return `${startOfMonth} - ${endOfMonth}`;
  }

  const getWeekRange = (dateString = "") => {
    let date;
    if (dateString) {
      date = new Date(dateString);
    } else {
      date = new Date();
    }
    const dayOfWeek = date.getDay();

    // Calculate the difference between the selected date and the closest Sunday
    const diff = date.getDate() - dayOfWeek;

    // Set the start of the week as Sunday
    const startOfWeek = new Date(date.setDate(diff));
    // Set the end of the week as Saturday
    const endOfWeek = new Date(date.setDate(date.getDate() + 6));

    // Format the start and end dates
    const startDateString = startOfWeek.toISOString().slice(0, 10);
    const endDateString = endOfWeek.toISOString().slice(0, 10);

    return `${startDateString} - ${endDateString}`;
  };

  const getDateRange = () => {
    const today = new Date();
    const nextWeek = new Date();
    nextWeek.setDate(today.getDate() + 7);

    switch (selectedOption) {
      case "weekly":
        let we = getWeekRange(searchDate);
        return we;
      case "monthly":
        let mm = getMonthRange(searchDate);
        return mm;
      default:
        return "";
    }
  };

  const dateStrings = getDateRange().split(" - ");
  const newStartDate = dateStrings[0];
  const newEndDate = dateStrings[1];

  useEffect(() => {    
    let reqData = {
      fileNo: searchFile,
      patient: searchPatient,
      doctor: selectedDoctor,
      date: searchDate,
      branch_id: branch,
      phone: searchPhone,
      page: pageIndex,
      pageSize: dataPerPage,
      status: status,
      range: {
        fromDate: newStartDate,
        toDate: newEndDate,
      },
    };

    dispatch(getAllPatientAppointment(requestGenerator(reqData))).then(
      (result) => setTotalPage(result.payload.lastPage)
    );
  }, [pageIndex, dataPerPage, status]);

  const handleSearch = () => {
    setPageIndex(1);
    let reqData = {
      fileNo: searchFile,
      patient: searchPatient,
      doctor: selectedDoctor,
      date: searchDate,
      branch_id: branch,
      phone: searchPhone,
      page: pageIndex,
      pageSize: dataPerPage,
      status: status,
      range: {
        fromDate: newStartDate,
        toDate: newEndDate,
      },
    };

    dispatch(getAllPatientAppointment(requestGenerator(reqData))).then(
      (result) => setTotalPage(result.payload.lastPage)
    );
  };

  const handleDoctorSearch = useCallback(
    debounce(async () => {
      try {
        const data = { is_active: true , page: 1, pageSize: 1000 };
        const response = await dispatch(getAllDoctors(requestGenerator(data)));
        setDoctorsList(response?.payload?.data || []);
      } catch (error) {
        console.error('Error fetching doctors:', error);
      }
    }, 500), 
    [search] 
  );

  const handleViewAll = () => {
    setBranch(branch_id);
    setId(undefined);
    let reqData = {
      fileNo: "",
      patient: "",
      doctor: "",
      date: "",
      branch_id: branch_id,
      page: pageIndex,
      pageSize: dataPerPage,
      range: {
        fromDate: "",
        toDate: "",
      },
    };
    dispatch(getAllPatientAppointment(requestGenerator(reqData))).then(
      (result) => setTotalPage(result.payload.lastPage)
    );
    setSearchDoctor("");
    setSearchFile("");
    setSearchPatient("");
    setSearchDate("");
    setSelectedOption("");
  };

  const handlePrint = () => {
    console.log("clicked");
    window.print();
  };

  const handleDownloadExcel = async () => {
    let reqData = {
      fileNo: searchFile,
      patient: searchPatient,
      doctor: selectedDoctor,
      date: searchDate,
      branch_id: branch,
      phone: searchPhone,
      page: "",
      pageSize: "",
      status: status,
      range: {
        fromDate: "",
        toDate: "",
      },
    };

    dispatch(getAllExcelSheetData(requestGenerator(reqData))).then(
      (data: any) => {
        console.log("got the data", data);

        // console.log(appointmentData);
        const excelData = data?.payload?.data.map((items: any) => {
          return {
            FileNo: items?.pnt_emr_no ? items?.pnt_emr_no :  "-",
            AppointmentDate: items?.appointment_date
              ? moment.utc(items.date).format("DD-MM-YYYY")
              : "",
            PatientName: items?.pnt_user_name ? items?.pnt_user_name : "-",
            PhoneNumber: items?.pnt_user_phone,
            DocterName: items?.dct_user_name,
            DocterPhoneNumber: items?.dct_user_phone,
            Room: items?.room ? items?.room.slice(0, 20) : "-",
            Status: items?.status,
            TimeIn: items?.time_in,
            Notes: items?.problem_description
              ? items?.problem_description.slice(0, 20)
              : "-",
            // TimeDuration: items?.time_duration,
          };
        });

        const worksheet: WorkSheet = utils.json_to_sheet(excelData);

        worksheet["!cols"] = [
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 30 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
        ];

        const workbook: WorkBook = utils.book_new();
        utils.book_append_sheet(workbook, worksheet, "View Appointment");

        //Download the Excel Sheet
        const excelBuffer = writeFile(
          workbook,
          "View Appointment Report.xlsx",
          {
            type: "buffer",
          }
        );
        const blob = new Blob([excelBuffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const link = document.createElement("a");
        link.download = "View Appointment Report.xlsx";
        link.click();
      }
    );
  };

  const handleRow = (id: any) => {
    setId(id);
    setValue(id);
    if (value === id) {
      setActive(true);
    } else {
      setActive(false);
    }
  };

  const handleNotesViewDialogOpen = (_element: any) => {
    console.log("clicked", _element);
    setShowNoteDetails(_element);
    setShowNotesDetailDialog(true);
  };

  const handleNotesViewDialogClose = (_element: any) => {
    setShowNotesDetailDialog(false);
  };


  useEffect(() => {
    const fetchDoctors = async () => {
      try {
        const data = { is_active: true, pageSize: 10 };
        const response = await dispatch(getAllDoctors(requestGenerator(data)));
        setDoctorsList(response?.payload?.data || []);
      } catch (error) {
        console.error('Error fetching doctors:', error);
      }
    };

    fetchDoctors();
  }, [dispatch]);

  const options = doctorsList?.map((doctor) => ({
    value: doctor._id,
    label: doctor.doctor_name,
  }));

  const handleDoctorChange = (selectedOption: any) => {
    setSelectedDoctor(selectedOption?.label); // Update selected doctor
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className={styles.mainContainer}>
        <div className={styles.headerContainer}>
          {/* <div className={styles.filterRoleContainer}> */}

          <div className={styles.popupContainer}>
            <div className={styles.viewAppointment}>
              <span className={styles.headerTitle}>
                {t("ViewAppointment.View Appointment")}
              </span>
              <div className={styles.filterContainer}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className={styles.btnContainer}>
                    <Button
                      title={t("Common.View All") || "View All"}
                      customClass={styles.viewBtn}
                      handleClick={handleViewAll}
                    />
                    <Button
                      title={
                        t("ViewAppointment.Appointment Log") ||
                        "Appointment Log"
                      }
                      customClass={styles.btnStyle}
                      handleClick={() => {
                        setAppointmentLog(true);
                      }}
                      type="button"
                      disable={id === undefined ? true : false}
                    />
                    <Button
                      title={t("ViewAppointment.Action Log") || "Action Log"}
                      customClass={styles.btnStyle}
                      handleClick={() => setActionLog(true)}
                      disable={id === undefined ? true : false}
                    />
                    <Button
                      title={
                        t("ViewAppointment.Status Summary") || "Status Summary"
                      }
                      customClass={styles.btnStyle}
                      handleClick={() => setStatusSummary(true)}
                    />
                    <Button
                      title={
                        t("ViewAppointment.Future Report") || "Future Report"
                      }
                      customClass={styles.btnStyle}
                      handleClick={() => setFutureReport(true)}
                    />

                    {/* <label className={styles.labelText}> */}
                    {/* {t("ViewAppointment.Period")} */}
                    {/* </label> */}

                    {/* <div className="statusDisplay">
                      <p>Selected Status: {status}</p>
                    </div> */}

                    <PrintIcon customClass={styles.printIconStyle} />

                    <button
                      onClick={handleDownloadExcel}
                      className={styles.printbutton}
                      title="Print"
                      // style={{
                      //   padding: "10px",
                      //   backgroundColor: "#FFFFFF",
                      //   border: "none",
                      //   borderRadius: "5px",
                      //   cursor: "pointer",
                      // }}
                    >
                      <ExportIcon />
                    </button>
                  </div>

                  <div className={styles.filterMainContainer}>
                    <Filter title="Filter">
                      <>
                        <div className={styles.childrenItemContainer}>
                          <div className={styles.searchContainer}>
                            {/* <label className={styles.labelText}> */}
                            {t("PatientEMR.File No.")}
                            {/* </label> */}
                            <input
                              type="text"
                              className={styles.inputField}
                              placeholder={
                                t("ViewAppointment.FileSearch") ||
                                "Search by file no."
                              }
                              value={searchFile}
                              onKeyDown={(e: any) => {
                                if (e?.key === "Enter") {
                                  handleDoctorSearch();
                                }
                              }}
                              onChange={(e) => {
                                trimValue(e);
                                setSearchFile(e.target.value);
                                if (
                                  searchFile !== "" &&
                                  e.target.value === ""
                                ) {
                                  let reqData = {
                                    fileNo: "",
                                    patient: searchPatient,
                                    doctor: searchDoctor,
                                    date: searchDate,
                                    branch_id: branch,
                                    phone: searchPhone,
                                    page: pageIndex,
                                    pageSize: dataPerPage,
                                    range: {
                                      fromDate: newStartDate,
                                      toDate: newEndDate,
                                    },
                                  };

                                  dispatch(
                                    getAllPatientAppointment(
                                      requestGenerator(reqData)
                                    )
                                  ).then((result) =>
                                    setTotalPage(result.payload.lastPage)
                                  );
                                }
                              }}
                            />
                            {/* <label className={styles.labelText}> */}
                            {t("Receptionist Dashboard.Doctor")}
                            {/* </label> */}
                            <Select
                              options={options}
                              value={options?.find(
                                (option) => option.value === selectedDoctor
                              )}
                              onKeyDown={(e:any)=>{
                                console.log(e.target.value,'the e')
                                setSearchedDocter(e.target.value)
                                handleDoctorSearch()
                              }}
                              onChange={handleDoctorChange}
                              placeholder="Select a doctor"
                            />
                            {/* <label className={styles.labelText}> */}
                            {t("ViewAppointment.Patient")}
                            {/* </label> */}
                            <input
                              type="text"
                              className={styles.inputField}
                              placeholder={
                                t("ViewAppointment.PatientSearch") ||
                                "Search by patient"
                              }
                              value={searchPatient}
                              onKeyDown={(e: any) => {
                                if (e?.key === "Enter") {
                                  handleSearch();
                                }
                              }}
                              onChange={(e) => {
                                trimValue(e);
                                setSearchPatient(e.target.value);
                                if (
                                  searchPatient !== "" &&
                                  e.target.value === ""
                                ) {
                                  let reqData = {
                                    fileNo: searchFile,
                                    patient: "",
                                    doctor: searchDoctor,
                                    date: searchDate,
                                    branch_id: branch,
                                    phone: searchPhone,
                                    page: pageIndex,
                                    pageSize: dataPerPage,
                                    range: {
                                      fromDate: newStartDate,
                                      toDate: newEndDate,
                                    },
                                  };

                                  dispatch(
                                    getAllPatientAppointment(
                                      requestGenerator(reqData)
                                    )
                                  ).then((result) =>
                                    setTotalPage(result.payload.lastPage)
                                  );
                                }
                              }}
                            />
                            {/* for selecting status  */}
                            Select Status
                            <select
                              className={styles.daysSelectContainer}
                              onChange={handleStatusChange}
                              value={status}
                            >
                              <option value="">Select Status </option>
                              <option value="SCHEDULED">SCHEDULED</option>
                              <option value="NOSHOW">NOSHOW</option>
                              <option value="RESCHEDULED">RESCHEDULED</option>
                              <option value="ARRIVED">ARRIVED</option>
                              <option value="CANCELLED">CANCELLED</option>
                              <option value="NOANSWER">NOANSWER</option>
                              <option value="NOTAVAILABEL">NOTAVAILABEL</option>
                              <option value="NOTREACHED">NOTREACHED</option>
                              <option value="OLDAPPOIINTMENTS">
                                OLDAPPOIINTMENTS
                              </option>
                            </select>
                            {/* </div> */}
                            {/* <div className={styles.searchContainer}> */}
                            {/* <label className={styles.labelText}> */}
                            {t("ViewAppointment.Date")}
                            {/* </label> */}
                            <input
                              type="date"
                              className={styles.DateinputField}
                              value={searchDate}
                              onChange={(e) => {
                                trimValue(e);
                                setSearchDate(e.target.value);
                                if (e.target.value === "") {
                                  let reqData = {
                                    fileNo: searchFile,
                                    patient: searchPatient,
                                    doctor: searchDoctor,
                                    date: searchDate,
                                    branch_id: branch,
                                    phone: searchPhone,
                                    page: pageIndex,
                                    pageSize: dataPerPage,
                                    range: {
                                      fromDate: newStartDate,
                                      toDate: newEndDate,
                                    },
                                  };
                                  setSelectedOption("Select");
                                  dispatch(
                                    getAllPatientAppointment(
                                      requestGenerator(reqData)
                                    )
                                  ).then((result) =>
                                    setTotalPage(result.payload.lastPage)
                                  );
                                }
                                if (e.target.value !== checkDate) {
                                  setSelectedOption("Select");
                                }
                              }}
                            />
                            {/* <label className={styles.labelText}> */}
                            {t("ViewAppointment.Period")}
                            {/* </label> */}
                            <select
                              className={styles.daysSelectContainer}
                              onChange={handleOptionChange}
                              value={selectedOption}
                            >
                              <option value="today">Today</option>
                              <option value="weekly">Weekly</option>
                              <option value="monthly">Monthly</option>
                            </select>
                            {/* <label className={styles.labelText}> */}
                            {t("Header.Branch")}
                            {/* </label> */}
                            <select
                              className={styles.branchsSelectContainer}
                              value={branch}
                              onChange={(e) => {
                                setBranch(e.target.value);
                              }}
                            >
                              {branche &&
                                branche.length > 0 &&
                                branche.map((item: any, i: number) => {
                                  return (
                                    <option value={item?._id} key={i}>
                                      {item?.name}
                                    </option>
                                  );
                                })}
                            </select>
                            {/* </div> */}
                            {/* <div className={styles.searchContainer}> */}
                            {/* <label className={styles.labelText}> */}
                            {t("ContactUs.Phone")}
                            {/* </label> */}
                            <input
                              type="number"
                              className={styles.inputField}
                              placeholder={
                                t("ViewAppointment.PhoneSearch") ||
                                "Search by phone"
                              }
                              value={searchPhone}
                              onKeyDown={(e: any) => {
                                if (e?.key === "Enter") {
                                  handleSearch();
                                }
                              }}
                              onChange={(e) => {
                                trimValue(e);
                                setSearchPhone(e.target.value);
                                if (
                                  searchPhone !== "" &&
                                  e.target.value === ""
                                ) {
                                  let reqData = {
                                    fileNo: searchFile,
                                    patient: "",
                                    doctor: searchDoctor,
                                    date: searchDate,
                                    branch_id: branch,
                                    phone: searchPhone,
                                    page: pageIndex,
                                    pageSize: dataPerPage,
                                    range: {
                                      fromDate: newStartDate,
                                      toDate: newEndDate,
                                    },
                                  };

                                  dispatch(
                                    getAllPatientAppointment(
                                      requestGenerator(reqData)
                                    )
                                  ).then((result) =>
                                    setTotalPage(result.payload.lastPage)
                                  );
                                }
                              }}
                            />
                            <Button
                              title={"Apply"}
                              customClass={styles.applyButton}
                              handleClick={() => handleSearch()}
                            />
                            {/* <SearchButton handleClick={() => handleSearch()} /> */}
                          </div>
                        </div>
                      </>
                    </Filter>
                  </div>
                </div>

                <div className={styles.tableContainer}>
                  {appointmentData?.length > 0 ? (
                    <TableV2
                      tableHeaderData={viewAppointmentPopupData}
                      tableRowData={appointmentData}
                      handleNotes={handleNotesViewDialogOpen}
                      handleRow={handleRow}
                      setModelOpenClose={setModelOpenClose}
                      setActive={setValue}
                      active={value}
                      hadleData={() => handleSearch()}
                      isViewChartHoverRow={true}
                    />
                  ) : (
                    <p className={styles.noRecord}>
                      {t("Common.No records found")}
                    </p>
                  )}
                </div>
                <Pagination
                  setDataPerPage={setDataPerPage}
                  pageIndexOptions={pageIndexOptions}
                  pageIndex={pageIndex}
                  setPageIndex={setPageIndex}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {appointmentLog && (
        <Popup
          Children={AppointmentLogPopup}
          handleClose={() => {
            setAppointmentLog(false);
          }}
          popData={id}
        />
      )}
      {actionLog && (
        <Popup
          Children={ActionLogPopup}
          handleClose={() => {
            setActionLog(false);
          }}
          popData={id}
        />
      )}
      {statusSummary && (
        <Popup
          Children={StatusSummaryPopup}
          handleClose={() => setStatusSummary(false)}
          popData={{
            doctor: searchDoctor,
            date: searchDate,
            branch_id: branch,
            range: {
              fromDate: newStartDate,
              toDate: newEndDate,
            },
          }}
          // customClassPopup={styles.popupStyles}
        />
      )}
      {
        <ViewAppointmentNotesDetailDialog
          open={showNotesDetailDialog}
          handleClose={handleNotesViewDialogClose}
          noteDetails={showNoteDetails}
        />
      }
      {futureReport && (
        <Popup
          Children={FutureReportPopup}
          handleClose={() => setFutureReport(false)}
          // popData={{
          //   doctor: searchDoctor,
          //   date: searchDate,
          //   branch_id: branch,
          //   range: {
          //     fromDate: newStartDate,
          //     toDate: newEndDate,
          //   },
          // }}
          // customClassPopup={styles.popupStyles}
        />
      )}
      {/* </div> */}
    </>
  );
};

export default ViewAppointmentPopup;
