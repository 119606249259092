// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".createIpdMainLayout_iconStyle__Vxcdd {\n  margin-right: 8px;\n}", "",{"version":3,"sources":["webpack://./src/pages/ipd/create-ipd-main-layout/createIpdMainLayout.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF","sourcesContent":[".iconStyle {\n  margin-right: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconStyle": "createIpdMainLayout_iconStyle__Vxcdd"
};
export default ___CSS_LOADER_EXPORT___;
