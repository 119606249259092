import React, { useEffect } from "react";
import styles from "./ConfigurationDetails.module.scss";
import { useTranslation } from "react-i18next";
import { daysList } from "../../../constants/data";
import Button from "../../../components/common/button/Button";
import { useForm } from "react-hook-form";
import {
  createConfigurationsDetails,
  getUserConfigurationsDetails,
} from "../../../redux/features/configuration-details/configuratinsDetailsAsyncActions";
import { requestGenerator } from "../../../utils/payloadGenerator";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import Loader from "../../../components/common/spinner/Loader";

const ConfigurationDetails = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector((state) => state.configuratinsDetails);
  // FORM
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm({});

  const weekDayValue = watch("weekDays");
  const onSubmit = (data: any) => {
    console.log("data", data);
    const payload = {
      query: {
        currency: data?.currency,
        weekday: Number(data?.weekDays),
      },
    };
    dispatch(createConfigurationsDetails(requestGenerator(payload)));
  };

  useEffect(() => {
    dispatch(getUserConfigurationsDetails(requestGenerator({}))).then(
      (res: any) => {
        if (res?.type === "configurations/getConfigurationsDetails/fulfilled") {
          setValue("currency", res?.payload[0]?.currency);
          const weekDay = daysList?.find((item) => {
            return item?.value === res?.payload[0]?.weekday;
          });
          setValue("weekDays", weekDay?.value);
        }
      }
    );
  }, []);
  return (
    <div className={styles.mainContainer}>
      {isLoading ? <Loader /> : ""}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.fieldsContainer}>
          <div className={styles.labelFieldContainer}>
            <label className={styles.labelText}>
              {/* {t("ShareQuestionnaire.Patient Name")} */}
              Currency
              <span className="asterick">*</span>
            </label>
            <div className={styles.fieldErrorContainer}>
              <input
                type="text"
                // placeholder={
                //   t("PatientEMRPlaceHolder.EnterName") || "Enter patient name"
                // }
                placeholder={"Enter Currency"}
                className={[styles.searchInputField]?.join(" ")}
                {...register("currency", {
                  required: "currency is required",
                  maxLength: {
                    value: 3,
                    message: "Currency only be 3 characters",
                  },
                })}
              />
              <div className={styles.errorContainer}>
                <span className={styles.extraSpan}></span>
                {errors["currency"] && (
                  <p className="dashboardFormError">
                    {errors["currency"].message as any}
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className={styles.labelFieldContainer}>
            <label className={styles.labelText}>
              {/* {t("IPDBooking.Primary Doctor")} */}
              Week Days
              <span className="asterick">*</span>
            </label>
            <div className={styles.fieldErrorContainer}>
              <select
                className={styles.selectInputField}
                placeholder={"Select  Week Days"}
                value={weekDayValue || []}
                {...register("weekDays", {
                  required: "please select Week Dayss",
                })}
              >
                <option value="">
                  Select Week Days
                </option>

                {daysList?.map((item: any, i: number) => {
                  return (
                    <React.Fragment key={i}>
                      <option value={item?.value}>{item?.label}</option>
                    </React.Fragment>
                  );
                })}
              </select>
              <div className={styles.errorContainer}>
                <span className={styles.extraSpan}></span>
                {errors["weekDays"] && (
                  <p className="dashboardFormError">
                    {errors["weekDays"].message as any}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className={styles.proeedButtonContainer}>
          <Button title="Submit" type="submit" />
        </div>
      </form>
    </div>
  );
};

export default ConfigurationDetails;
