import { createSlice } from "@reduxjs/toolkit";
import { IDoctorDashboard } from "../../../interfaces/apiInterface";
import {
  accountSummaryWidget,
  departmentWiseRevenueWidgets,
  doctorFeedbackSummaryWidgets,
  doctorOutstandingRevenueWidgets,
  doctorPatientVisitWidget,
  doctorWiseRevenueWidgets,
  getAllDoctorAppointmentLists,
  getAllTodoReminderLists,
  patientAppoinmentSummarykWidgets,
  patientFeedbackWidgets,
  uploadFile,
} from "./doctorDashboardAsyncActions";

export const initialState: IDoctorDashboard = {
  isLoading: false,
  doctorAppointmentList: [],
  doctorListDataObject: {},
  uploadfiledata: [],
  error: null,
  doctorFeedbackData: [],
  appoinmentSummaryData: null,
  doctorWiseRevenueData: [],
  departmentsRevenueData: [],
  doctorsoutStandingRevenueData: [],
  doctorFeedbackSummaryData: [],
  doctorPatientVisitData: [],
  doctorFeedbackWidgetLoader: false,
  doctorRevenueWidgetLoader: false,
  departmentWidgetLoader: false,
  doctorOutstandingWidgetLoader: false,
  patientVisitWidgetLoader: false,
  accountSummaryWidgetData: [],
  accountSummaryWidgeLoader: false,
  patientFeedbackWidgetLoader:false
};

export const doctorDashboardSlice = createSlice({
  name: "doctorDashboard",
  initialState,
  reducers: {
    // clearPatientData: (state) => {
    //   state.patientDataObjectById = {};
    // },
    clearUploadFileData: (state) => {
      state.uploadfiledata = [];
    },
  },

  extraReducers: (builder) => {
    // doctor dashboard all appointment
    builder.addCase(getAllDoctorAppointmentLists.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllDoctorAppointmentLists.fulfilled, (state, action) => {
      state.isLoading = false;
      state.doctorAppointmentList = action.payload?.data;
      state.doctorListDataObject = action.payload;
    });
    builder.addCase(getAllDoctorAppointmentLists.rejected, (state, error) => {
      state.isLoading = false;
      state.error = error;
    });

    // todo reminder list
    builder.addCase(getAllTodoReminderLists.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllTodoReminderLists.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(getAllTodoReminderLists.rejected, (state, error) => {
      state.isLoading = false;
      state.error = error;
    });

    // upload file
    builder.addCase(uploadFile.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(uploadFile.fulfilled, (state, action) => {
      state.isLoading = false;
      state.uploadfiledata = action.payload;
    });
    builder.addCase(uploadFile.rejected, (state, error) => {
      state.isLoading = false;
      state.error = error;
    });

    // doctor patinet feedback widgest
    builder.addCase(patientFeedbackWidgets.pending, (state) => {
      state.patientFeedbackWidgetLoader = true;
    });
    builder.addCase(patientFeedbackWidgets.fulfilled, (state, action) => {
      state.patientFeedbackWidgetLoader = false;
      state.doctorFeedbackData = action.payload?.data?.map((item: any) => {
        return {
          name: item?.reviewed_by?.name,
          rating: item?.rating,
          designation: "Patient",
        };
      });
    });
    builder.addCase(patientFeedbackWidgets.rejected, (state, error) => {
      state.patientFeedbackWidgetLoader = false;
      // state.error = error;
    });

    builder.addCase(patientAppoinmentSummarykWidgets.pending, (state) => {
      // state.isLoading = true;
    });
    builder.addCase(
      patientAppoinmentSummarykWidgets.fulfilled,
      (state, action) => {
        // state.isLoading = false;
        state.appoinmentSummaryData = action.payload;
      }
    );
    builder.addCase(
      patientAppoinmentSummarykWidgets.rejected,
      (state, error) => {
        // state.isLoading = false;
        // state.error = error;
      }
    );

    // Doctor Revenue

    builder.addCase(doctorWiseRevenueWidgets.pending, (state) => {
      state.doctorRevenueWidgetLoader = true;
    });
    builder.addCase(doctorWiseRevenueWidgets.fulfilled, (state, action) => {
      state.doctorRevenueWidgetLoader = false;
      state.doctorWiseRevenueData = action.payload?.data;
    });
    builder.addCase(doctorWiseRevenueWidgets.rejected, (state, error) => {
      state.doctorRevenueWidgetLoader = false;
      // state.error = error;
    });

    // departments revenue
    builder.addCase(departmentWiseRevenueWidgets.pending, (state) => {
      state.departmentWidgetLoader = true;
    });
    builder.addCase(departmentWiseRevenueWidgets.fulfilled, (state, action) => {
      state.departmentsRevenueData = action.payload;
      state.departmentWidgetLoader = false;
    });
    builder.addCase(departmentWiseRevenueWidgets.rejected, (state, error) => {
      state.departmentWidgetLoader = false;
    });

    // outstanding revenue
    builder.addCase(doctorOutstandingRevenueWidgets.pending, (state) => {
      state.doctorOutstandingWidgetLoader = true;
    });
    builder.addCase(
      doctorOutstandingRevenueWidgets.fulfilled,
      (state, action) => {
        state.doctorsoutStandingRevenueData = action.payload?.data;
        state.doctorOutstandingWidgetLoader = false;
      }
    );
    builder.addCase(
      doctorOutstandingRevenueWidgets.rejected,
      (state, error) => {
        state.doctorOutstandingWidgetLoader = false;
      }
    );

    // doctor feedback summary
    builder.addCase(doctorFeedbackSummaryWidgets.pending, (state) => {
      state.doctorFeedbackWidgetLoader = true;
    });
    builder.addCase(doctorFeedbackSummaryWidgets.fulfilled, (state, action) => {
      state.doctorFeedbackSummaryData = action.payload?.data?.map(
        (item: any) => {
          return {
            name: item?.doctor_name,
            rating: item?.avg_rating,
            designation: item?.specialities.toString(),
            rating_count: item?.rating_count,
          };
        }
      );
      state.doctorFeedbackWidgetLoader = false;
    });
    builder.addCase(doctorFeedbackSummaryWidgets.rejected, (state, error) => {
      state.doctorFeedbackWidgetLoader = false;
    });

    // doctor patient  visit
    builder.addCase(doctorPatientVisitWidget.pending, (state) => {
      state.patientVisitWidgetLoader = true;
    });
    builder.addCase(doctorPatientVisitWidget.fulfilled, (state, action) => {
      state.doctorPatientVisitData = action.payload;
      state.patientVisitWidgetLoader = false;
    });
    builder.addCase(doctorPatientVisitWidget.rejected, (state, error) => {
      state.patientVisitWidgetLoader = false;
    });

    // account summary
    builder.addCase(accountSummaryWidget.pending, (state) => {
      state.accountSummaryWidgeLoader = true;
    });
    builder.addCase(accountSummaryWidget.fulfilled, (state, action) => {
      state.accountSummaryWidgetData = action.payload;
      state.accountSummaryWidgeLoader = false;
    });
    builder.addCase(accountSummaryWidget.rejected, (state, error) => {
      state.accountSummaryWidgeLoader = false;
    });
  },
});

export default doctorDashboardSlice.reducer;
export const { clearUploadFileData } = doctorDashboardSlice.actions;
