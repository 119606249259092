import React, { FC, useState } from "react";
import styles from "./dashbord.module.scss";
import DropdownV2 from "../../../components/common/dropdown/dropdownv2/DropdownV2";
import { useAppSelector } from "../../../hooks";
import { useTranslation } from "react-i18next";
import { trimValue } from "../../../utils/utils";

interface IDashbordFilterProps {
  handleStatus: any;
  handleBranch: any;
  handleDoctor: any;
  statusValue: any;
  branchValue: any;
  doctorValue: any;
  dateValue: any;
  handleDate: any;
  handleSearch: any;
  searchValue: string;
}

const DashbordFilter: FC<IDashbordFilterProps> = ({
  handleStatus,
  handleBranch,
  handleDoctor,
  statusValue,
  branchValue,
  doctorValue,
  dateValue,
  handleDate,
  handleSearch,
  searchValue,
}) => {
  const { branchData, colorSchemeList } = useAppSelector(
    (state) => state.login
  );
  const { todayAppointmentDoctorData } = useAppSelector(
    (state) => state.appointment
  );
  let branche = branchData?.branches;

  let myArray =
    todayAppointmentDoctorData &&
    todayAppointmentDoctorData?.data?.length > 0 &&
    todayAppointmentDoctorData;
  console.log("todayAppointmentDoctorData", todayAppointmentDoctorData);

  let vNew =
    todayAppointmentDoctorData &&
    todayAppointmentDoctorData?.length > 0 &&
    [...myArray].sort((a, b) => (a.dcts > b.dcts ? 1 : -1));

  const { t } = useTranslation();
  return (
    <div className={styles.childrenItemContainer}>
      <div className={styles.filterContainer}>
        <span className={styles.customLabel}>Search</span>
        <input
          type="text"
          className={styles.inputSearchContainer}
          placeholder={t("LabJob.Search") || "Search Patient Name"}
          onChange={(e) => handleSearch(e.target.value)}
          value={searchValue}
        />
      </div>
      <div className={styles.filterContainer}>
        <span className={styles.customLabel}>Date</span>
        <input
          type="date"
          className={styles.selectContainer}
          value={dateValue}
          onChange={(e) => {
            trimValue(e);
            handleDate(e.target.value);
          }}
        />
      </div>
      <div className={styles.filterContainer}>
        <DropdownV2
          data={colorSchemeList}
          //   type={t("Receptionist Dashboard.Doctor") || "Doctor"}
          type="Status"
          selectedItem={statusValue}
          handleClick={(item: any) => {
            handleStatus({
              name: item?.title,
              value: item?.label,
            });
          }}
          keyName="title"
          customClass={styles.filterDropDownStyle}
          customClassTitle={styles.dropDownLabelStyle}
          setSelectedItem={(item: any) => handleStatus(item)}
        />
      </div>
      <div className={styles.filterContainer}>
        <DropdownV2
          data={branche}
          //   type={t("Receptionist Dashboard.Doctor") || "Doctor"}
          type="Branch"
          keyName="name"
          selectedItem={branchValue}
          handleClick={(item: any) => {
            handleBranch(item);
          }}
          customClass={styles.filterDropDownStyle}
          customClassTitle={styles.dropDownLabelStyle}
          setSelectedItem={(item: any) => handleBranch(item)}
          notShowAllOption={true}
        />
      </div>
      <div className={styles.filterContainer}>
        <DropdownV2
          data={vNew}
          //   type={t("Receptionist Dashboard.Doctor") || "Doctor"}
          type="Doctors"
          keyName="doctor_name"
          selectedItem={doctorValue}
          handleClick={(item: any) => {
            handleDoctor({
              name: item?.dcts,
              _id: item?._id,
            });
          }}
          customClass={styles.filterDropDownStyle}
          customClassTitle={styles.dropDownLabelStyle}
          setSelectedItem={(item: any) => handleDoctor(item)}
        />
      </div>
    </div>
  );
};

export default DashbordFilter;
