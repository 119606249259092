import axios from "axios";

import {
  ACCOUNT_SUMMARY,
  DEPARTMENTS_REVENUE,
  DOCTOR_FEEDBACK_SUMMARY,
  DOCTOR_OUTSTANDING_REVENUE,
  DOCTOR_PATIENT_APPOINMENT_SUMMARY,
  DOCTOR_PATIENT_FEEDBACK,
  DOCTOR_PATIENT_VISIT,
  DOCTORS_REVENUE,
  GET_ALL_NOTIFICATION,
  GET_DOCTOR_APPOINTMENT_LIST,
  GET_UPLOAD_FILE,
} from "../../../config/config";
import { IAPIPayload } from "../../../interfaces/apiInterface";

export const getAllDoctorAppointments = (data: IAPIPayload) => {
  return axios.post(GET_DOCTOR_APPOINTMENT_LIST, data);
};
export const getAllTodoReminder = (data: IAPIPayload) => {
  return axios.post(GET_ALL_NOTIFICATION, data);
};
export const uploadFileEmr = (data: IAPIPayload) => {
  return axios.post(GET_UPLOAD_FILE, data);
};
// doctor patinet feedback widgest
export const patientFeedback = (data: IAPIPayload) => {
  return axios.post(DOCTOR_PATIENT_FEEDBACK, data);
};

// doctor patient-appointment-summary
export const patientAppoinmentSummary = (data: IAPIPayload) => {
  return axios.post(DOCTOR_PATIENT_APPOINMENT_SUMMARY, data);
};

// doctor revenue
export const doctorWiseRevenue = (data: IAPIPayload) => {
  return axios.post(DOCTORS_REVENUE, data);
};

// department revenue
export const departmentWiseRevenue = (data: IAPIPayload) => {
  return axios.post(DEPARTMENTS_REVENUE, data);
};

// outstanding revenue
export const doctorOutStandingRevenue = (data: IAPIPayload) => {
  return axios.post(DOCTOR_OUTSTANDING_REVENUE, data);
};

// doctor feedback summary
export const doctorFeedbackSummary = (data: IAPIPayload) => {
  return axios.post(DOCTOR_FEEDBACK_SUMMARY, data);
};

// doctor patient  visit
export const doctorPatientVisit = (data: IAPIPayload) => {
  return axios.post(DOCTOR_PATIENT_VISIT, data);
};

// account summary
export const accountSummary = (data: IAPIPayload) => {
  return axios.post(ACCOUNT_SUMMARY, data);
};


