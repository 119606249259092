// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".searchDesign_mainContainer__mKZcj {\n  width: 285px;\n  height: 50px;\n  display: flex;\n  flex-direction: row;\n  border-radius: 10px;\n  background: rgba(255, 255, 255, 0.7);\n  box-shadow: 0px 10px 117px 14px rgba(0, 0, 0, 0.05);\n}\n.searchDesign_mainContainer__mKZcj .searchDesign_searchIcon__N-nAX {\n  width: 12%;\n}\n.searchDesign_mainContainer__mKZcj .searchDesign_searchIcon__N-nAX .searchDesign_searchIconStyle__Epa3i {\n  margin: 17px 0 0 14px;\n}\n.searchDesign_mainContainer__mKZcj .searchDesign_searchContainer__Kyl9F {\n  margin-left: 8px;\n  display: flex;\n}\n.searchDesign_mainContainer__mKZcj .searchDesign_searchContainer__Kyl9F .searchDesign_searchText__5mo3E {\n  display: flex;\n  align-self: center;\n  color: var(--grey14);\n}", "",{"version":3,"sources":["webpack://./src/pages/documents/search-design/searchDesign.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,oCAAA;EACA,mDAAA;AACF;AAAE;EACE,UAAA;AAEJ;AADI;EAIE,qBAAA;AAAN;AAGE;EACE,gBAAA;EACA,aAAA;AADJ;AAEI;EACE,aAAA;EACA,kBAAA;EACA,oBAAA;AAAN","sourcesContent":[".mainContainer {\n  width: 285px;\n  height: 50px;\n  display: flex;\n  flex-direction: row;\n  border-radius: 10px;\n  background: rgba(255, 255, 255, 0.7);\n  box-shadow: 0px 10px 117px 14px rgba(0, 0, 0, 0.05);\n  .searchIcon {\n    width: 12%;\n    .searchIconStyle {\n      // position: absolute;\n      // left: 12px;\n      // top: 16px;\n      margin: 17px 0 0 14px;\n    }\n  }\n  .searchContainer {\n    margin-left: 8px;\n    display: flex;\n    .searchText {\n      display: flex;\n      align-self: center;\n      color: var(--grey14);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContainer": "searchDesign_mainContainer__mKZcj",
	"searchIcon": "searchDesign_searchIcon__N-nAX",
	"searchIconStyle": "searchDesign_searchIconStyle__Epa3i",
	"searchContainer": "searchDesign_searchContainer__Kyl9F",
	"searchText": "searchDesign_searchText__5mo3E"
};
export default ___CSS_LOADER_EXPORT___;
