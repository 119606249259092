import {
  CREATE_PERMISSIONS_TYPE,
  CREATE_USER_ROLE_TYPE,
  GET_ALL_PERMISSIONS_TYPE,
  GET_ALL_USER_ROLES_TYPE,
  GET_PERMISSION_FOR_WIDGETS,
  GET_PERMISSIONS_BY_ID_TYPE,
  UPDATE_PERMISSION_FOR_WIDGETS,
  UPDATE_PERMISSIONS_TYPE,
} from "../../../constants/asyncActionsType";
import createAsyncThunkForSlice from "../../../utils/utils";
import {
  createRole,
  createpermissions,
  getPermissions,
  getWidgetsPermission,
  getallroles,
  getpermissionbyid,
  updateWidgetsPermission,
  updatepermissions,
} from "./rolesPermissionsCrud";

export const getAllEmptyPermissions = createAsyncThunkForSlice(
  GET_ALL_PERMISSIONS_TYPE,
  getPermissions
);

export const createUserRole = createAsyncThunkForSlice(
  CREATE_USER_ROLE_TYPE,
  createRole,
  {
    isToast: true,
  }
);

export const getAllRoles = createAsyncThunkForSlice(
  GET_ALL_USER_ROLES_TYPE,
  getallroles
);

export const createPermissions = createAsyncThunkForSlice(
  CREATE_PERMISSIONS_TYPE,
  createpermissions,
  {
    isToast: true,
  }
);

export const updatePermissions = createAsyncThunkForSlice(
  UPDATE_PERMISSIONS_TYPE,
  updatepermissions,
  {
    isToast: true,
  }
);

export const getAllPermissionsById = createAsyncThunkForSlice(
  GET_PERMISSIONS_BY_ID_TYPE,
  getpermissionbyid
);

// widgets permision
export const getWidgetsPermissionSystemUser = createAsyncThunkForSlice(
  GET_PERMISSION_FOR_WIDGETS,
  getWidgetsPermission
);
export const updateWidgetsPermissionSystemUser = createAsyncThunkForSlice(
  UPDATE_PERMISSION_FOR_WIDGETS,
  updateWidgetsPermission,
  {
    isToast: true,
  }
);
