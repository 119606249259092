import jsPDF from 'jspdf'
import moment from 'moment'
import { useAppDispatch, useAppSelector } from '../../hooks'
import image from '../../assets/images/healthBoxLogo.png'
import 'jspdf-autotable'
import styles from '../../pages/branchstore/issue-item-popup/issueditempopup.module.scss'
import { getBranchStoreIssueListById } from '../../redux/features/branch-store/branchStoreAsyncActions'
import { requestGenerator } from '../../utils/payloadGenerator'
import { useLocation } from 'react-router-dom'
import { t } from 'i18next'

export const issueItemPopupHeaderData: any = [
  {
    Header: t('RcptTableHeader.DATE'),
    accessor: (row: any) => {
      return moment(row?.date)?.format('DD MMM YYYY')
    },
    Cell: (props: any) => {
      const convertDate = moment(props?.row?.original?.date).format(
        'DD MMM YYYY'
      )
      return (
        <>{props?.row?.original?.date ? <span>{convertDate}</span> : '-'}</>
      )
    },
  },
  {
    Header: t('INVTableHeader.DOCID'),
    Cell: (props: any) => {
      const { branchData } = useAppSelector((state) => state.login)
      const location = useLocation()
      const dispatch = useAppDispatch()
      const generatePdf = () => {
        let data = {
          store_id:
            location?.pathname === '/mainstore' ||
            location?.pathname === '/pharmacymainstore'
              ? location?.pathname === '/mainstore'
                ? branchData?.main_store?.[0]?._id
                : branchData?.pharmacy_store?.[0]?._id
              : props.onRowClick,
          doc_id: props.row.original.doc_id,
        }
        dispatch(getBranchStoreIssueListById(requestGenerator(data))).then(
          (res: any) => {
            if (
              res.type === 'branchStore/getBranchStoreIssueListById/fulfilled'
            ) {
              const totalQty = res.payload?.reduce(
                (acc: any, num: any) => acc + num.qty,
                0
              )
              const totalPrice = res.payload?.reduce(
                (acc: any, num: any) => acc + num.price,
                0
              )
              const doc: any = new jsPDF('p', 'pt', 'a4')

              const width = doc.internal.pageSize.getWidth()
              let tableData: any = []
              res.payload.forEach((items: any, index: number) => {
                tableData.push([
                  items.item_no,
                  items.name,
                  items.qty,
                  items.price,
                ])
              })
              let offsetY = 4.797777777777778
              let lineHeight = 6.49111111111111

              const imgData = image
              doc.addImage(imgData, 'JPEG', width / 2, 20, 40, 40, {
                align: 'center',
              })
              doc
                .text('Goods Issue Report', width / 2, 80, { align: 'center' })
                .setFontSize(10)
                .setFont(undefined, 'bold')
              doc
                .text(`Doc. No.: ${props.row.original.doc_id}`, 60, 120)
                .setFontSize(8)
                .setFont(undefined, 'bold')
              doc
                .text(`Request From:${res.payload[0]?.request_source}`, 60, 140)
                .setFontSize(8)
                .setFont(undefined, 'bold')
              // doc
              //   .text(`Ref No: QAR849`, 400, 120)
              //   .setFontSize(8)
              //   .setFont(undefined, "bold");
              doc
                .text(
                  `Date: ${moment(res?.payload[0]?.date).format(
                    'DD-MMM-YYYY'
                  )}`,
                  400,
                  140
                )
                .setFontSize(8)
                .setFont(undefined, 'bold')

              doc.autoTable({
                startY: 155,
                styles: {
                  halign: 'center',
                  textColor: '#000',
                  fillColor: '#fefefe',
                },
                tableLineColor: '#000',
                tableLineWidth: 0.1,

                headStyles: { lineWidth: 0.1, lineColor: '#000' },
                bodyStyles: {
                  fontSize: '8',
                  lineWidth: 0.1,
                  lineColor: '#000',
                },
                footStyles: {
                  fontSize: '8',
                  halign: 'left',
                  lineWidth: 0.1,
                  lineColor: '#000',
                },

                head: [['Code', 'Name', 'Quantity', 'Price']],
                body: tableData,
                foot: [
                  ['', '', `Total Qty: ${totalQty}`, `Total: ${totalPrice}`],
                ],
              })
              const dynamicHeight = doc?.autoTable?.previous?.finalY

              doc
                .text(
                  'Signature:',
                  60,
                  dynamicHeight + lineHeight * 5 + offsetY
                )
                .setFontSize(8)
                .setFont(undefined, 'bold')

              doc
                .text('Date:', 200, dynamicHeight + lineHeight * 5 + offsetY)
                .setFontSize(8)
                .setFont(undefined, 'bold')

              doc.save('issue.pdf')
              window.open(doc.output('bloburl'), '_blank')
            }
          }
        )
      }
      return (
        <>
          {props?.row?.original?.doc_id ? (
            <span className={styles.viewPdfLink} onClick={() => generatePdf()}>
              {props?.row?.original?.doc_id}
            </span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: t('ViewInventory.ITEM NAME'),
    accessor: 'name',
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.name ? (
            <span>{props?.row?.original?.name}</span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: t('INVTableHeader.SOURCE'),
    accessor: 'request_source_type',
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.request_source_type ? (
            <span>{props?.row?.original?.request_source_type}</span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: t('INVTableHeader.SUB_SOURCE'),
    accessor: 'request_source',
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.request_source ? (
            <span>{props?.row?.original?.request_source}</span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: t('INVTableHeader.REQ_TYPE'),
    accessor: 'req_unit_type',
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.req_unit_type ? (
            <span>{props?.row?.original?.req_unit_type}</span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: t('INVTableHeader.REQ_QTY'),
    accessor: 'requested_qty',
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.requested_qty ? (
            <span>{props?.row?.original?.requested_qty}</span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: t('INVTableHeader.QTY_ISSUED'),
    accessor: 'qty',
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.qty ? (
            <span>{props?.row?.original?.qty}</span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  // {
  //   Header: "ACTION",
  //   accessor: "Action",
  //   disableSortBy: true,
  //   Cell: (props: any) => {
  //     return (
  //       <>
  //         <CrossIcon
  //           width={25}
  //           height={25}
  //           fillColor={colors.white1}
  //           fillColor1={colors.grey4}
  //         />
  //       </>
  //     );
  //   },
  // },
]
