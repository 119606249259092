import { FC, useEffect, useState } from "react";

import "./Chat.css"; // For styling
import StaffChat from "../../pages/whatsapp/staff-chat/StaffChat";
import StaffchatSidebarChat from "../../pages/whatsapp/staff-chat/staffchat-sidebar-chat/StaffchatSidebarChat";
import { useAppSelector } from "../../hooks";
import { socket, connectToSocket } from "../../socket";
import ChatList from "../../pages/whatsapp/staff-chat/staffchat-sidebar-chat/ChatSidebar";
import ChatWindow from "../../pages/whatsapp/staff-chat/staffchat-sidebar-chat/ChatWindow";
import { ChatCloseIcon, ChatIcon } from "../common/svg-components";
import { motion, AnimatePresence } from "framer-motion";

interface IWhatsapp {
  messageThread?: any;

  item?: any;
  setItem?: any;
  connectionId?: any;
  staffChatRef?: React.RefObject<any>;
}

const Chat: FC<IWhatsapp> = () => {
  const [isChatOpen, setIsChatOpen] = useState(false);

  const [data, setData] = useState<any>("");
  const [socketJoined, setSocketJoined] = useState(false);
  const { isLoggedin } = useAppSelector((state) => state.login);
  const [showChat, setShowChat] = useState<any>(false);
  const [notification, setNotification] = useState(0);
  const [chatMessages, setChatMessages] = useState<any>();

  useEffect(() => {
    async function connectSocket() {
      if (
        isLoggedin === true &&
        (!socket || !socket.connected) &&
        socketJoined === false
      ) {
        setSocketJoined(true);
        await connectToSocket();
        // await emitMessageCount()
      }
    }
    connectSocket();
  }, [socketJoined]);

  const getMessageThreds = async () => {
    socket?.on("msgThreadList", (msgThread: any) => {
      if (msgThread) {
        let data = msgThread.data;

        data ? setChatMessages(data) : setChatMessages([]);
      }
    });
    await socket.emit(
      "getMessageThread",
      {
        page: 1,
        pageSize: 100,
      },
      (messageThread: any) => {
        if (messageThread) {
          let data = messageThread.msgThreadList?.data;
          data ? setChatMessages(data) : setChatMessages([]);
        }
      }
    );
  };

  useEffect(() => {
    setNotification(0);
    if (chatMessages?.length) {
      const totalNotificationCount = chatMessages
        .filter((e: any) => e.messageCount > 0) // Filter objects where messageCount > 0
        .map((e: any) => e.messageCount) // Extract messageCount values
        .reduce((sum: number, count: number) => sum + count, 0); // Sum the values

      console.log(totalNotificationCount, "notificationCounts");
      setNotification(totalNotificationCount);
    }
  }, [chatMessages]);

  useEffect(() => {
    if (socket) {
      getMessageThreds();
    }
  }, [socket]);

  useEffect(() => {
    if (socket) {
      socket.off("notification");

      socket.on("notification", (res: any) => {
        setNotification((prev: number) => prev + 1);
      });

      return () => {
        socket.off("notification");
      };
    }
  }, [socket]);

  useEffect(() => {
    if (data) {
      console.log(data, "the data");
      const messageCounter = parseInt(data.messageCount, 10);
      if (!isNaN(messageCounter)) {
        const updatedCount = notification - messageCounter;
        setNotification(updatedCount);
      } else {
        console.warn("Invalid messageCount:", data.messageCount);
      }
      setShowChat(true);
    }
  }, [data]);

  const handleBack = () => {
    setData("");
    setShowChat(false);
  };

  const toggleChatWindow = () => {
    setIsChatOpen((prev) => !prev);
  };

  return (
    <div>
      {/* Chat Icon */}
      <div className="chat-icon" onClick={toggleChatWindow}>
        {!isChatOpen && notification > 0 && (
          <span className="notification-badge">{notification}</span>
        )}

        {/* {isChatOpen ? <ChatCloseIcon /> : <ChatIcon />} */}
      </div>

      {/* Chat Window */}
      <AnimatePresence>
        {/* {isChatOpen && ( */}
        <motion.div
          className="chat-window"
          initial={{ opacity: 0, y: 200, rotateX: 90 }}
          animate={{ opacity: 1, y: 0, rotateX: 0 }}
          exit={{ opacity: 0, y: 200, rotateX: 90 }}
          transition={{
            duration: 1.5,
            type: "spring",
            stiffness: 200,
            damping: 30,
          }}
        >
          <div className="chat-header">
            {!showChat && <p className="Chatheading mt-2 ml-2">Staff Chat</p>}
          </div>
          <div className="chat-body">
            {showChat ? (
              <>
                <ChatWindow
                  item={data}
                  setItem={setData}
                  connectionId={socketJoined}
                  handleBack={handleBack}
                />
              </>
            ) : (
              <ChatList
                item={data}
                setItem={setData}
                connectionId={socketJoined}
              />
            )}
          </div>
          <div></div>
        </motion.div>
        {/* )} */}
      </AnimatePresence>
    </div>
  );
};

export default Chat;
