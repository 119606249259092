// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".staffchat_wp__Rz4PS {\n  display: grid;\n}\n\n.staffchat_whatsappMainContainer__czGL4 {\n  display: flex;\n  margin-top: 10px;\n  max-width: 1000px;\n  min-width: 100%;\n}\n.staffchat_whatsappMainContainer__czGL4 .staffchat_chatContainer__33-Cl {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n}", "",{"version":3,"sources":["webpack://./src/pages/whatsapp/staff-chat/staffchat.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AAIA;EACE,aAAA;EACA,gBAAA;EACA,iBAAA;EACF,eAAA;AADA;AASE;EACE,aAAA;EACA,sBAAA;EAEA,WAAA;AARJ","sourcesContent":[".wp{\n  display: grid;\n  // place-items: center;\n\n}\n\n.whatsappMainContainer {\n  display: flex;\n  margin-top: 10px;\n  max-width: 1000px;\nmin-width: 100%\n  // height: 90vh;\n  // width: 90vw;\n  // margin-right: 10px;\n  // width: 98%\n  // width: 1232px\n  ;\n\n  .chatContainer{\n    display: flex;\n    flex-direction: column;\n    // width: 74%;\n    width: 100%;\n    // min-width: 69%;\n\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wp": "staffchat_wp__Rz4PS",
	"whatsappMainContainer": "staffchat_whatsappMainContainer__czGL4",
	"chatContainer": "staffchat_chatContainer__33-Cl"
};
export default ___CSS_LOADER_EXPORT___;
