import { FC, useEffect, useState } from "react";
import { colors } from "../../../../../constants/color";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import { getAppointmentSummary } from "../../../../../redux/features/appointment/appointmentAsyncActions";
import { requestGenerator } from "../../../../../utils/payloadGenerator";
import Divider from "../../../divider/Divider";
import { CloseIcon } from "../../../svg-components";
import styles from "./futureReportPopup.module.scss";
import Loader from "../../../spinner/Loader";
import { useTranslation } from "react-i18next";
import { Input } from "../../../input/input";
import Select from "react-select";
import { Label } from "../../../label";
import { useForm } from "react-hook-form";
import { DropdownIndicator } from "../../../dropdown-indicator/DropdownIndicator";
import { getAllPatientList } from "../../../../../redux/features/patient-emr/patient/patientAsyncAction";
import { ErrorMessage } from "../../../error-message-component";
import Button from "../../../button/Button";
import { patientFutureAppoinmentReport } from "../../../../../redux/features/role/roleAsynActions";
import { patientFutureAppoinmentReportPdf } from "../../../../../utils/utils";
import { PATIENT_FUTURE_APPOINMENT } from "../../../../../constants/asyncActionsType";
import SearchModal from "../../search-modal/SearchModal";
import Popup from "../../../popup/Popup";

interface IFutureReportPopup {
  popData?: any;
}

const FutureReportPopup: FC<IFutureReportPopup> = ({ popData }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { isLoading, appointmentSummary } = useAppSelector(
    (state) => state.appointment
  );

  const { nationalityData, patientListData } = useAppSelector(
    (state) => state.patient
  );
  const [patientId, setPatientId] = useState();
  const [patientModal, setPatientModal] = useState(false);
  const [patientName, setPatientName] = useState<any>("");
  const [isPatientSelected,setPatientSelected] = useState(false)
  const [showError,setShowError] = useState(false)

  useEffect(() => {
    const requestData = {
      search: "",
      page: 1,
      pageSize: 50,
      is_active: true,
    };
    dispatch(getAllPatientList(requestGenerator(requestData)));
  }, []);


  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
    clearErrors,
  } = useForm();

  const onSubmit = (item: any) => {

    if(!isPatientSelected) {
      return
    }
    let data = {
      startDate: item?.dateFrom?.length ? item?.dateFrom : undefined,
      endDate: item?.dateTo?.length ? item?.dateTo : undefined,
      patient_id: item?.patientId,
    };
    dispatch(patientFutureAppoinmentReport(requestGenerator(data)))
      .then((e) => {
        if (e?.type === `${PATIENT_FUTURE_APPOINMENT}/fulfilled`) {
          patientFutureAppoinmentReportPdf(e?.payload);
          reset()
          setShowError(false)
          setPatientSelected(false)
          setPatientName("")

        }
      })
      .catch((e) => {
        console.log(e, "the error");
      });
  };

  useEffect(() => {
    dispatch(getAppointmentSummary(requestGenerator(popData ? popData : {})));
  }, []);


  return (
    <>
      {isLoading && <Loader />}
      <div className={styles.popupContainer}>
        <CloseIcon
          customClass={styles.closeIconStyle}
          fillColor={colors.green1}
        />
        <div
          className={styles.viewAppointment}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <p className={styles.title}>
            {t("ViewAppointment.Future Appointment Report")}
          </p>
          <Divider customClass={styles.dividerStyle} />

          <form
            className={styles.reportContainer}
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className={styles.Content}>
              <div style={{ flexBasis: "30%", marginBlock: "20px" }}>
                <Input
                  type="date"
                  labelText={t("ReportGenerator.Date_From") || "Date From"}
                  {...register("dateFrom", { required: true })}
                  showErrors={errors?.dateFrom?.type === "required"}
                  errorMessage={t("ReportGenerator.Date_From_Validation")}
                  customClass={styles.datePicker}
                />
              </div>

              <div
                style={{
                  flexBasis: "30%",
                  marginBlock: "20px",
                  alignItems: "center",
                }}
              >
                <Input
                  type="date"
                  labelText={t("ReportGenerator.Date_To") || "Date To"}
                  {...register("dateTo", { required: true })}
                  showErrors={errors?.dateTo?.type === "required"}
                  errorMessage={t("ReportGenerator.Date_To_Validation")}
                  customClass={styles.datePicker}
                />
              </div>

              <div
                style={{ flexBasis: "30%", gap: "10px", marginLeft: "20px" }}
              >
                <div
                  style={{
                    flexBasis: "190%",
                    display: "flex",
                    margin: "20px 20px 0 20px",
                    alignItems: "center",
                  }}
                >
                  {/* <Label
                    labelText={t("ViewAppointment.Patient")}
                    requiredField={true}
                    // flexBasis="10%"
                    customClass={styles.label}
                  />
                  <Select
                    className={styles.SelectPatient}
                    placeholder={"Select Patient"}
                    closeMenuOnSelect={true}
                    components={{ DropdownIndicator }}
                    {...register("patientId", { required: true })}
                    value={watch("patientId") || []}
                    options={patientListData?.map((item: any) => {
                      return {
                        label: `${item?.patient_name} (${item?.emr_no})`,
                        value: item?._id,
                      };
                    })}
                    isSearchable={true}
                    onChange={(e) => {
                      setValue("patientId", e);
                      setPatientId(e.value);
                    }}
                    // onInputChange={(newValue) => {
                    //   deb(newValue);
                    // }}
                    maxMenuHeight={150}
                  /> */}

                  <Button
                  type="button"
                    title={
                      t("ViewAppointment.SelectPatient") || "Select Patient"
                    }
                    handleClick={()=>{
                      setPatientModal(true)
                    }}
                  />{patientName ? patientName : ""}
                </div>
                {showError && !isPatientSelected  &&(
                  <ErrorMessage
                    message={t("ReceiptPayment.SelectPatient")}
                    customClassSpan={styles.showError}
                  />
                )}
              </div>
            </div>

            {patientModal && (
              <Popup
                Children={SearchModal}
                handleRowClick={(e: any) => {
      
                  setValue("patientId", e?._id);
                  setPatientName(e?.patient_name);
                  setPatientModal(false);
                  setPatientSelected(true)
                }}
                handleClose={() =>{
                  setPatientSelected(false)
                  setValue("patientId","");
                  setPatientName("");
                  setPatientModal(false)}}
              />
            )}

            <div className={styles.submitBtn}>
              <Button
              handleClick={() => setShowError(true)}
                title={
                  t("ReportGenerator.Generate_Report") || "Generate Report"
                }
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default FutureReportPopup;
