import axios from "axios";
import { IAPIPayload } from "../../../interfaces/apiInterface";
import {
  GET_MASTER_IMPORT,
  UPDATE_MASTER_IMPORT,
} from "../../../config/config";

// GET ALL MASTER IMPORT
export const getAllMasterImport = (data: IAPIPayload) => {
  return axios.post(GET_MASTER_IMPORT, data);
};

// UPDATE ALL MASTER IMPORT
export const updateAllMasterImport = (data: IAPIPayload) => {
  return axios.post(UPDATE_MASTER_IMPORT, data);
};


