import { createSlice } from "@reduxjs/toolkit";
import { ITaxConfiguration } from "../../../interfaces/apiInterface";
import {
  createTaxConfiguration,
  getAllTaxConfiguration,
  getTaxConfiguration,
  updateTaxConfiguration,
} from "./taxAsyncActions";

export const initialState: ITaxConfiguration = {
  isLoading: false,
  list: [],
  error: null,
  taxInfo: [],
};

export const taxSlice = createSlice({
  name: "tax", 
  initialState,

  reducers: {
    storeTaxInfo(state, action) {
      state.taxInfo = action.payload
    },
  },
  extraReducers(builder) {
    // GET ALL TAX

    builder.addCase(getAllTaxConfiguration.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllTaxConfiguration.fulfilled, (state, action) => {
      state.isLoading = false;
      state.list = action.payload?.data;
    });
    builder.addCase(getAllTaxConfiguration.rejected, (state, error) => {
      state.isLoading = false;
      state.error = error;
    });


    builder.addCase(getTaxConfiguration.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getTaxConfiguration.fulfilled, (state, action) => {
      state.isLoading = false;
      state.taxInfo = action.payload?.data;
    });
    builder.addCase(getTaxConfiguration.rejected, (state, error) => {
      state.isLoading = false;
      state.error = error;
    });

    // ADD TAX

    builder.addCase(createTaxConfiguration.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createTaxConfiguration.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(createTaxConfiguration.rejected, (state, error) => {
      state.isLoading = false;
      state.error = error;
    });

  
    // EDIT TAX
    builder.addCase(updateTaxConfiguration.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateTaxConfiguration.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(updateTaxConfiguration.rejected, (state, error) => {
      state.isLoading = false;
      state.error = error;
    });

  

   
  },
});

export const {  storeTaxInfo } = taxSlice.actions;

export default taxSlice.reducer;
