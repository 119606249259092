import { createSlice } from "@reduxjs/toolkit";
import {
  createComplianceConfiguration,
  getAllComplianceConfiguration,
  updateComplianceConfiguration,
} from "./complianceAsyncActions";
import { IComplianceConfiguration } from "../../../interfaces/apiInterface";

export const initialState: IComplianceConfiguration = {
  isLoading: false,
  list: [],
  error: null,
  complianceInfo: [],
};

export const complianceSlice = createSlice({
  name: "compliance",
  initialState,

  reducers: {
    storeComplianceInfo(state, action) {
      state.complianceInfo = action.payload;
    },
  },
  extraReducers(builder) {
    // GET ALL COMPLIANCE

    builder.addCase(getAllComplianceConfiguration.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getAllComplianceConfiguration.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.list = action.payload?.data;
      }
    );
    builder.addCase(getAllComplianceConfiguration.rejected, (state, error) => {
      state.isLoading = false;
      state.error = error;
    });

    // ADD COMPLIANCE

    builder.addCase(createComplianceConfiguration.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      createComplianceConfiguration.fulfilled,
      (state, action) => {
        state.isLoading = false;
      }
    );
    builder.addCase(createComplianceConfiguration.rejected, (state, error) => {
      state.isLoading = false;
      state.error = error;
    });

    // EDIT Compliance
    builder.addCase(updateComplianceConfiguration.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      updateComplianceConfiguration.fulfilled,
      (state, action) => {
        state.isLoading = false;
      }
    );
    builder.addCase(updateComplianceConfiguration.rejected, (state, error) => {
      state.isLoading = false;
      state.error = error;
    });
  },
});

export const { storeComplianceInfo } = complianceSlice.actions;

export default complianceSlice.reducer;
