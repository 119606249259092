import { FC, useEffect } from 'react'
import { EmailIcon } from '../../../components/common/svg-components'
import styles from './forgotPassword.module.scss'
import { FORGOT_PASSWORD_EMAIL } from '../../../constants/constant'
import Button from '../../../components/common/button/Button'
import { SubmitHandler, useForm } from 'react-hook-form'

import 'react-phone-input-2/lib/style.css'
import { IForgotPasswordForm } from '../../../interfaces/interfaces'
import { forgotPasswordValidators } from '../../../form-validators/forgotPasswordValidators'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { requestGenerator } from '../../../utils/payloadGenerator'
import { userForgotpassword } from '../../../redux/features/login/loginAsynActions'
import { useNavigate, useLocation } from 'react-router-dom'
import { USER_FORGOT_PASSWORD_TYPE } from '../../../constants/asyncActionsType'
import Loader from '../../../components/common/spinner/Loader'
import healthboxtext from '../../../assets/images/HealthboxLogoText.png'
import { trimValue } from '../../../utils/utils'
import { useTranslation } from 'react-i18next'

const ForgotPassword: FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { loading } = useAppSelector((state) => state.login)
  const render = useLocation().state
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IForgotPasswordForm>()

  const onSubmit: SubmitHandler<IForgotPasswordForm> = (data) => {
    dispatch(userForgotpassword(requestGenerator(data))).then((e) => {
      if (e.type === `${USER_FORGOT_PASSWORD_TYPE}/fulfilled`) {
        navigate('/otpverfication', {
          state: { email: data?.email, renderOtp: true },
        })
      }
    })
  }
  useEffect(() => {
    if (!render?.renderForgotPassword) {
      navigate('/')
    }
  }, [navigate, render?.renderForgotPassword])
  return (
    <>
      {loading && <Loader />}
      <div className={styles.mainContainer}>
        <div className={styles.logoContainer}>
          <img
            src={healthboxtext}
            alt="error to display"
            className={styles.logoImg}
          />
        </div>
        <p className={styles.formTitle}>{t('Auth.Forgot password')}</p>
        <p className={styles.formSubTitle}>{t('Auth.EnterEmailInfor')}</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.formContainer}>
            <div className={styles.forgotPasswordForm}>
              {/* <span className={styles.iconLabelStyle}>
                <EmailIcon customClass={styles.iconCustomClass} /> */}
              <label
                htmlFor={FORGOT_PASSWORD_EMAIL}
                className={styles.labelStyle}
              >
                {t('Auth.Email')}
              </label>
              {/* </span> */}

              <div className={styles.inputFieldContainer}>
                <input
                  type="text"
                  placeholder="example@email.com"
                  className={styles.inputFieldStyle}
                  {...register(
                    FORGOT_PASSWORD_EMAIL,
                    forgotPasswordValidators[FORGOT_PASSWORD_EMAIL]
                  )}
                  onChange={(e) => trimValue(e)}
                />
              </div>
              <p className="errorText">
                {errors[FORGOT_PASSWORD_EMAIL] && (
                  <span className="error">
                    {errors[FORGOT_PASSWORD_EMAIL].message}
                  </span>
                )}
              </p>
            </div>
            <div className={styles.btnContainer}>
              <Button
                title={t('Auth.Send OTP') || 'Send OTP'}
                type="submit"
                disable={loading}
                customClass={styles.sendOtpButtonStyle}
              />
              <Button
                title={t('Auth.Go Back') || 'Go Back'}
                type="button"
                disable={loading}
                customClass={styles.goBackButton}
                handleClick={() => navigate('/')}
              />
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default ForgotPassword
