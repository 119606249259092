export const colors = {
  // grey1: '#BBBBBB',
  grey1: '#2DCEE0',
  grey2: '#797979',
  grey3: '#353751',
  grey4: '#CDD4D8',
  grey5: '#474973',
  grey6: '#F6F6F6',
  // blue1: '#3D96E7',
  blue1: '#5937F1',
  blue2: '#41416E',
  blue3: '#273fad',
  blue4: '#002C5A;',
  blue5: ' #097AF2',
  black: '#000',
  black1: '#181818',
  white1: '#fff',
  green: '#00ff00',
  green1: '#02BF90',
  red1: '#B11313',
  red2: '#ff0000',
  red3: '#E41616',
  purple: '#5936F1',
  yellow: '#FFA009',
  prussian_blue: "#002C5A",
  malachite: '#0BD26E',
};
