import axios from "axios";
import { IAPIPayload } from "../../../interfaces/apiInterface";
import {
  INVENTORY_ITEM_CREATE,
  INVENTORY_ITEM_GET_ALL,
  INVENTORY_ITEM_LIST,
  INVENTORY_ITEM_REQUEST,
  INVENTORY_ITEM_UPDATE,
  INVENTORY_ITEM_UPDATE_STATUS,
} from "../../../config/config";

export const getAllInventoryItemTable = (data: IAPIPayload) => {
  return axios.post(INVENTORY_ITEM_GET_ALL, data);
};

export const createInventoryItemTable = (data: IAPIPayload) => {
  return axios.post(INVENTORY_ITEM_CREATE, data);
};

export const updateInventoryItemTable = (data: IAPIPayload) => {
  return axios.post(INVENTORY_ITEM_UPDATE, data);
};

export const updateStatusInventoryItemTable = (data: IAPIPayload) => {
  return axios.post(INVENTORY_ITEM_UPDATE_STATUS, data);
};
// inventory item widgets
export const inventoryItemList = (data: IAPIPayload) => {
  return axios.post(INVENTORY_ITEM_LIST, data);
};

// inventory item  request widgets
export const inventoryItemRequest = (data: IAPIPayload) => {
  return axios.post(INVENTORY_ITEM_REQUEST, data);
};
