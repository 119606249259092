import React, { useEffect, useState } from "react";
import styles from "./whatsup.module.scss";
import Button from "../../../components/common/button/Button";
import { useForm } from "react-hook-form";
import {
  KEY,
  MESSAGE,
  MOBILE_NO,
  REGISTER_MOBILE_NO,
} from "../../../constants/constant";
import { whatsupValidators } from "../../../form-validators/configurationsValidators";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  checkWhatsupConfigurationsDetails,
  getWhatsupDetails,
  updateWhatsUpConfigurationsDetails,
} from "../../../redux/features/whatsup-configurations/whatsupAsyncActions";
import { requestGenerator } from "../../../utils/payloadGenerator";
import PhoneInput from "react-phone-input-2";
import Loader from "../../../components/common/spinner/Loader";
import { useTranslation } from "react-i18next";

const Whatsup = () => {
  const [saveBtn, setSaveBtn] = useState(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { isLoading } = useAppSelector((state) => state.whatsup);
  const { branchData } = useAppSelector((state) => state.login);
  // FORM
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm({});

  const keyValue = watch(KEY);
  const registerMobileNoValue = watch(REGISTER_MOBILE_NO);
  const mobileNoValue = watch(MOBILE_NO);
  const message = watch(MESSAGE);

  const onSubmit = (data: any) => {
    const payload = {
      key: data?.key,
      phone: data?.registerMobileNo,
      to_phone: data?.mobileNo,
      message: data?.message,
    };
    dispatch(checkWhatsupConfigurationsDetails(requestGenerator(payload))).then(
      (res: any) => {
        console.log("datacheck", res);
        if (res?.type === "whatsup/checkWhatsupDetails/fulfilled") {
          if (!saveBtn) {
            setSaveBtn(!saveBtn);
          }
        }
      }
    );
  };

  useEffect(() => {
    dispatch(getWhatsupDetails(requestGenerator({}))).then((res: any) => {
      setValue(KEY, res?.payload?.key);
      setValue(REGISTER_MOBILE_NO, res?.payload?.phone);
      setValue(
        MESSAGE,
        res?.payload?.message.body ??
          "patient_name, your appointment with Dr. doctor_name on appointment_date appointment_time is confirmed. Question? call phone_no. via medical_centre"
      );
    });
  }, []);

  const handleSave = () => {
    const payload = {
      key: keyValue,
      phone: registerMobileNoValue,
      type: "APPOINTMENT-REMINDER",
      message: message,
    };
    dispatch(updateWhatsUpConfigurationsDetails(requestGenerator(payload)));
  };
  return (
    <form className={styles.mainContainer} onSubmit={handleSubmit(onSubmit)}>
      {isLoading ? <Loader /> : ""}
      <div className={styles.filedContainer}>
        <div className={styles.labelFieldContainer}>
          <label className={styles.labelText}>
            {t("WhatsApp.key")}
            <span className="asterick">*</span>
          </label>
          <div className={styles.fieldErrorContainer}>
            <input
              type="text"
              placeholder={t("WhatsApp.KeyFiled") || "Enter Key"}
              className={[styles.searchInputField]?.join(" ")}
              {...register(KEY, whatsupValidators[KEY])}
            />
            <div className={styles.errorContainer}>
              <span className={styles.extraSpan}></span>
              {errors[KEY] && (
                <p className="dashboardFormError">
                  {errors[KEY].message as any}
                </p>
              )}
            </div>
          </div>
        </div>

        <div className={styles.labelFieldContainer}>
          <label className={styles.labelText}>
            {t("WhatsApp.RegisteredMobileNo")}
            <span className="asterick">*</span>
          </label>
          <div className={styles.fieldErrorContainer}>
            <PhoneInput
              country={branchData?.country?.country_code?.toLowerCase()}
              {...register(
                REGISTER_MOBILE_NO,
                whatsupValidators[REGISTER_MOBILE_NO]
              )}
              value={getValues(REGISTER_MOBILE_NO) ?? ""}
              onChange={(phone: any) => {
                const formattedPhone = phone && `+${phone}`;
                setValue(REGISTER_MOBILE_NO, formattedPhone);
                trigger(REGISTER_MOBILE_NO);
              }}
              inputClass={[styles.phoneNumberInput]?.join(" ")}
              inputStyle={{
                outline: "none",
                boxShadow: "none",
              }}
            />
            <div className={styles.errorContainer}>
              <span className={styles.extraSpan}></span>
              {errors[REGISTER_MOBILE_NO] && (
                <p className="dashboardFormError">
                  {errors[REGISTER_MOBILE_NO].message as any}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.textAreaContainer}>
        <label className={styles.labelText}>
          {t("WhatsApp.Message")}
          <span className="asterick">*</span>
        </label>
        <div className={styles.fieldErrorContainer}>
          <textarea
            className={styles.textArea}
            placeholder={t("WhatsApp.MessageFiled") || "Enter Message"}
            {...register(MESSAGE, whatsupValidators[MESSAGE])}
            maxLength={300}
            onChange={(e) => {
              e.target.value = e?.target?.value
                .replace(/\s+/g, " ")
                .trimStart();
              if (e?.target?.value?.length) {
                clearErrors(MESSAGE);
              }
            }}
          />
          {errors[MESSAGE] && (
            <p className="dashboardFormError">
              {errors[MESSAGE].message as any}
            </p>
          )}
        </div>
      </div>

      <div className={styles.textAreaContainer}>
        <label className={styles.labelText}>Note</label>
        <p className={styles.noteText}>
          {" "}
          {/* medical_centre, phone_no, date_time, and patient_name are required to
          fetch patient appointment details and send the whatsapp notification
          message. */}
          {t("WhatsApp.MessageNote")}
        </p>
      </div>

      <div className={styles.MobileFiledContainer}>
        <div className={styles.labelFieldContainer}>
          <label className={styles.labelText}>
            {/* Mobile No */}
            {t("PatientEMR.Mobile No.")}
            <span className="asterick">*</span>
          </label>
          <div className={styles.fieldErrorContainer}>
            <PhoneInput
              country={branchData?.country?.country_code?.toLowerCase()}
              {...register(MOBILE_NO, whatsupValidators[MOBILE_NO])}
              value={getValues(MOBILE_NO) ?? ""}
              onChange={(phone: any) => {
                const formattedPhone = phone && `+${phone}`;
                setValue(MOBILE_NO, formattedPhone);
                trigger(MOBILE_NO);
              }}
              inputClass={[styles.phoneNumberInput]?.join(" ")}
              inputStyle={{
                outline: "none",
                boxShadow: "none",
              }}
              containerClass={[styles.mobileNumberContainer]?.join(" ")}
            />
            {errors[MOBILE_NO] && (
              <p className="dashboardFormError">
                {errors[MOBILE_NO].message as any}
              </p>
            )}
          </div>
        </div>
        <Button
          title={t("LabJob.Test") || "Test"}
          type="submit"
          customClass={styles.testButtonStyle}
        />
      </div>

      <Button
        title={t("Common.Save") || "Save"}
        type="button"
        customClass={styles.saveButtonStyle}
        handleClick={handleSave}
        disable={!saveBtn}
      />
    </form>
  );
};

export default Whatsup;
