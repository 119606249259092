import {
  AgentsIcon,
  AppConfigIcon,
  CampaignsIcon,
  ConfigurationIcon,
  DashboardIcon,
  DiagnosisIcon,
  FormBuilderIcon,
  IPDIcon,
  InsuranceMasterIcon,
  InventoryMasterIcon,
  InvoiceIcon,
  LeadsIcon,
  MainstoreIcon,
  ManageUsersIcon,
  MasterTableManageIcon,
  PatientEMRIcon,
  PurchaseIon,
  ReceiptMenu,
  RequestInventoryIcon,
  ServicesMastersIcons,
  SubstoreIcon,
  UserGroupIcon,
  IPDInvoiceIcon,
  unitTypeMastersIcons,
  ReportBuilderIcon,
  inventoryItemMastersIcons,
  InsuranceClaimsIcon,
  PharmacyStoreIcon,
  RadiologyJobIcon,
  RadiologyRequestIcon,
  RadiologyInvoiceIcon,
  LabJobIcon,
  LaboratoryRequestIcon,
  LabInvoiceIcon,
  PharmacyMainstoreIcon,
  ReportMappingIcon,
  ReportGeneratorIcon,
  FolderIcon,
  ViewChartOfAccountsIcon,
  UserTickIcon,
  CamerIcon,
  VouchersIcon,
  ReportsIcons,
  UnavailablityIcon,
  UPAYConfigIcon,
  ImportUtilityIcon,
  InvoiceTemplateIcon,
  JobIcon,
  RequestIcon,
} from "../../components/common/svg-components";
import {
  ACCOUNT_DASHBOARD,
  ATTENDANCE,
  BRANCH_STORE,
  CC_ADMIN_DASHBOARD,
  CC_AGENT,
  CC_AGENT_DASHBOARD,
  CC_CAMPAIGN,
  CC_LEAD_POOL,
  CRM_ADMIN_KEY,
  DC_DASHBOARD,
  DC_DIAGNOSIS,
  DNT_DASHBOARD,
  DNT_DIAGNOSIS,
  DOCUMENTS,
  DYNAMIC_TEMPLATE,
  EMP_MANAGEMENT,
  FORM_BUILDER,
  HR_DASHBOARD,
  IMPORT_UTILITY,
  INS_MASTERS,
  INVENTORY_ITEM_MASTERS,
  INVENTORY_MASTER_TABLE,
  INVENTORY_REQUEST,
  INVENTORY_UNIT_TYPE_MAP,
  INVOICE,
  IPD_CREATE_CASE,
  IPD_ID,
  IPD_INVOICE,
  LAB_CONFIGURATION,
  LAB_INVOICE,
  LAB_JOB,
  LAB_REQUEST,
  LEAVE_MANAGEMENT,
  MAIN_STORE,
  MANAGE_MASTER_TABLE,
  MANAGE_STAFF,
  MANAGE_USER_ROLE,
  MC_DASHBOARD,
  MOB_APP_CONFIGURATION,
  ONGING_CLAIMS,
  PATIENT_EMR,
  PAYROLL,
  PHARMACY_MAIN_STORE,
  PHARMACY_POS,
  PHARMACY_STORE,
  PURCHASE_INVOICE,
  RADIOLOGY_CONFIGURAION,
  RADIOLOGY_INVOICE,
  RADIOLOGY_JOB,
  RADIOLOGY_REQUEST,
  RECEIPT,
  RECEP_DASHBOARD,
  REPORTS,
  REPORT_BUILDER,
  REPORT_GENERATOR,
  REPORT_MAPPING,
  SER_MASTERS,
  UNAVAILABILITY,
  UPAY_CONFIG,
  VIEW_CHART_OF_ACCOUNTS,
  VOUCHERS,
} from "../../config/modules";
import { ISidebar } from "../../interfaces/interfaces";

export const mainSidebarData: ISidebar[] = [
  {
    id: MC_DASHBOARD,
    name: "Medical Center",
    icon: DashboardIcon,
    navigate: "/medicalcenter",
    navigateAfterLogin: "/medicalcenter/branch",
  },
  {
    id: MANAGE_USER_ROLE,
    name: "User Roles",
    icon: UserGroupIcon,
    navigate: "/usergroups",
  },
  {
    id: MANAGE_STAFF,
    name: "Staff",
    icon: ManageUsersIcon,
    navigate: "/manageusers",
  },
  {
    id: MANAGE_MASTER_TABLE,
    name: "Master Tables",
    icon: MasterTableManageIcon,
    navigate: "/mastertablemanagenew",
  },
  {
    id: MOB_APP_CONFIGURATION,
    name: "App Configurations",
    icon: AppConfigIcon,
    navigate: "/mobileappconfiguration",
  },

  {
    id: INS_MASTERS,
    name: "Insurance Masters",
    icon: InsuranceMasterIcon,
    navigate: "/insurancemaster",
  },
  {
    id: SER_MASTERS,
    name: "Services Masters",
    icon: ServicesMastersIcons,
    navigate: "/services",
  },

  {
    id: INVENTORY_MASTER_TABLE,
    name: "Supplier Master",
    icon: InventoryMasterIcon,
    navigate: "/inventorymastertable",
  },

  {
    id: INVENTORY_UNIT_TYPE_MAP,
    name: "Unit Type",
    icon: unitTypeMastersIcons,
    navigate: "/unitTypeMap",
  },
  {
    id: INVENTORY_ITEM_MASTERS,
    name: "Inventory Masters",
    icon: inventoryItemMastersIcons,
    navigate: "/inventoryitemtable",
  },
  // doctor sidebar data
  {
    id: DC_DASHBOARD,
    name: "Dashboard",
    icon: DashboardIcon,
    navigate: "/doctor",
  },
  {
    id: DC_DIAGNOSIS,
    name: "Diagnosis",
    icon: DiagnosisIcon,
    navigate: null,
    activeLocation: "patientdiagnosis",
    navigateAfterLogin: "/patientdiagnosis",
  },

  // // lab siderbar
  {
    id: LAB_JOB,
    name: "Lab Job",
    icon: JobIcon,
    navigate: "/job",
    navigateAfterLogin: "/job/createjobs",
  },
  {
    id: LAB_REQUEST,
    name: "Lab Request",
    icon: RequestIcon,
    navigate: "/request",
  },
  {
    id: LAB_INVOICE,
    name: "Lab Invoice",
    icon: InvoiceIcon,
    navigate: "/invoice",
  },
  {
    id: LAB_CONFIGURATION,
    name: "Lab Configuration",
    icon: ConfigurationIcon,
    navigate: "/configuration",
  },

  // // radiology
  {
    id: RADIOLOGY_JOB,
    name: "Radiology Job",
    icon: JobIcon,
    navigate: "/radiology-job",
    navigateAfterLogin: "/radiology-job/createjobs",
  },
  {
    id: RADIOLOGY_REQUEST,
    name: "Radiology Request",
    icon: RequestIcon,
    navigate: "/radiology-request",
  },
  {
    id: RADIOLOGY_INVOICE,
    name: "Radiology Invoice",
    icon: InvoiceIcon,
    navigate: "/radiology-invoice",
  },
  {
    id: RADIOLOGY_CONFIGURAION,
    name: "Radiology Configuration",
    icon: ConfigurationIcon,
    navigate: "/radiology-configuration",
  },

  // dentist
  {
    id: DNT_DASHBOARD,
    name: "Dashboard",
    icon: DashboardIcon,
    navigate: "/dentist",
    navigateAfterLogin: "/dentist",
  },
  {
    id: DNT_DIAGNOSIS,
    name: "Dental Diagnosis",
    icon: DiagnosisIcon,
    navigate: null,
    navigateAfterLogin: "/patientdentaldiagnosis",
    activeLocation: "patientdentaldiagnosis",
  },

  // pharmacy
  {
    id: PHARMACY_POS,
    name: "POS",
    icon: DashboardIcon,
    navigate: "/pharmacy",
    navigateAfterLogin: "/pharmacy/pharmacy-info",
  },

  {
    id: PHARMACY_STORE,
    name: "Pharmacy Store",
    icon: PharmacyStoreIcon,
    navigate: "/pharmacystore",
  },

  // inventory sidebar

  {
    id: BRANCH_STORE,
    name: "Branch Store",
    icon: SubstoreIcon,
    navigate: "/branchstore",
  },
  {
    id: MAIN_STORE,
    name: "Main Store",
    icon: MainstoreIcon,
    navigate: "/mainstore",
  },
  {
    id: PHARMACY_MAIN_STORE,
    name: "Pharmacy main Store",
    icon: MainstoreIcon,
    navigate: "/pharmacymainstore",
  },
  {
    id: PURCHASE_INVOICE,
    name: "Purchase Invoice",
    icon: PurchaseIon,
    navigate: "/purchaseinvoice",
  },

  // Receptionist Sidebar
  {
    id: RECEP_DASHBOARD,
    name: "Dashboard",
    icon: DashboardIcon,
    navigate: "/receptionist",
  },
  {
    id: PATIENT_EMR,
    name: "Patient EMR",
    icon: PatientEMRIcon,
    navigate: "/patientemr",
    activeLocation: "patientemr",
  },
  {
    id: INVOICE,
    name: "Invoice",
    icon: InvoiceIcon,
    navigate: "/invoice",
  },
  {
    id: RECEIPT,
    name: "Receipt",
    icon: ReceiptMenu,
    navigate: "/receipt",
  },

  {
    id: FORM_BUILDER,
    name: "Form Builder",
    icon: FormBuilderIcon,
    navigate: "/formBuilder",
    activeLocation: "formBuilder",
  },

  //  IPD
  {
    id: IPD_ID,
    name: "IPD",
    icon: IPDIcon,
    // activeLocation: 'ipd',
    navigate: "/ipd",
  },
  {
    id: IPD_CREATE_CASE,
    name: "IPD Create Case",
    icon: IPDIcon,
    activeLocation: "ipd-case-form",
    navigate: null,
  },

  {
    id: IPD_INVOICE,
    name: "IPD Invoice",
    icon: IPDInvoiceIcon,
    navigate: "/ipd-invoice",
  },

  // inventory request

  {
    id: INVENTORY_REQUEST,
    name: "Inventory Request",
    icon: RequestInventoryIcon,
    navigate: "/request",
  },
  // Call Center Admin
  {
    id: CC_ADMIN_DASHBOARD,
    name: "Dashboard",
    icon: DashboardIcon,
    navigate: "/call",
    navigateAfterLogin: "/call",
  },
  {
    id: CC_LEAD_POOL,
    name: "Lead Pool",
    icon: LeadsIcon,
    navigate: "/lead",
  },
  {
    id: CC_AGENT,
    name: "Agents",
    icon: AgentsIcon,
    navigate: "/agents",
  },
  {
    id: CC_CAMPAIGN,
    name: "Campaigns",
    icon: CampaignsIcon,
    navigate: "/campaigns",
  },
  {
    id: CC_AGENT_DASHBOARD,
    name: "Dashboard",
    icon: DashboardIcon,
    navigate: "/agent",
  },
  {
    id: CRM_ADMIN_KEY,
    name: "CRM Admin",
    icon: DashboardIcon,
    navigate: "/crmadmin",
  },
  {
    id: ONGING_CLAIMS,
    name: "Ongoing Claims",
    icon: InsuranceClaimsIcon,
    navigate: "/ongoing-claims",
    navigateAfterLogin: "/ongoing-claims",
    activeLocation: "ongoing-claims",
  },
  {
    id: REPORT_MAPPING,
    name: "Report Mapping",
    icon: ReportMappingIcon,
    navigate: "/report-mapping",
  },
  {
    id: REPORT_GENERATOR,
    name: "Report Generator",
    icon: ReportGeneratorIcon,
    navigate: "/reportgenerator",
  },
  {
    id: REPORT_BUILDER,
    name: "Report Builder",
    icon: ReportBuilderIcon,
    navigate: "/reportbuilder",
  },
  {
    id: HR_DASHBOARD,
    name: "Dashboard",
    icon: DashboardIcon,
    navigate: "/hr-dashboard",
  },
  {
    id: EMP_MANAGEMENT,
    name: "Employees",
    icon: UserGroupIcon,
    navigate: "/employees",
  },
  {
    id: DOCUMENTS,
    name: "Documents",
    icon: FolderIcon,
    navigate: "/documents",
  },
  {
    id: PAYROLL,
    name: "Payroll",
    icon: ViewChartOfAccountsIcon,
    navigate: "/payroll",
  },
  {
    id: ATTENDANCE,
    name: "Attendance",
    icon: UserTickIcon,
    navigate: "/attendance",
  },
  {
    id: LEAVE_MANAGEMENT,
    name: "Leave Management",
    icon: CamerIcon,
    navigate: "/leave-management",
  },
  {
    id: ACCOUNT_DASHBOARD,
    name: "Dashboard",
    icon: DashboardIcon,
    navigate: "/account-dashboard",
  },
  {
    id: VIEW_CHART_OF_ACCOUNTS,
    name: "View Chart Of Accounts",
    icon: ViewChartOfAccountsIcon,
    navigate: "/viewchartofaccounts",
  },
  {
    id: VOUCHERS,
    name: "Vouchers",
    icon: VouchersIcon,
    navigate: "/vouchers",
  },
  {
    id: REPORTS,
    name: "Reports",
    icon: ReportsIcons,
    navigate: "/reports",
  },
  {
    id: REPORT_BUILDER,
    name: "HL7",
    icon: ReportBuilderIcon,
    navigate: "/hl7",
  },
  {
    id: UNAVAILABILITY,
    name: "Unavailability",
    icon: UnavailablityIcon,
    navigate: "/unavailability",
  },
  {
    id: UPAY_CONFIG,
    name: "Configuration",
    icon: UPAYConfigIcon,
    navigate: "/configuration",
  },
  {
    id: IMPORT_UTILITY,
    name: "Import Utility",
    icon: ImportUtilityIcon,
    navigate: "/import-utility",
  },
  {
    id: DYNAMIC_TEMPLATE,
    name: "Set template for invoice & receipt",
    icon: InvoiceTemplateIcon,
    navigate: "/template",
  },
];
