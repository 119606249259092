import { useState, useEffect } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import {
  balanceSheetAssetsTableHeaderData,
  balanceSheetLiabilityTableHeaderData,
} from "./balanceSheetTableData";
import { Label } from "../../../components/common/label";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getAllBalanceSheet } from "../../../redux/features/balance-sheet/balanceSheetAsyncActions";
import { requestGenerator } from "../../../utils/payloadGenerator";
import Popup from "../../../components/common/popup/Popup";
import Loader from "../../../components/common/spinner/Loader";
import BalanceSheetAssetsModal from "./balanceSheet-assets-modal/BalanceSheetAssetsModal";
import BalanceSheetLiabilityModal from "./balanceSheet-liability-modal/BalanceSheetLiabilityModal";
import { customDropdownStyles } from "../../../utils/utils";
import styles from "./Balancesheet.module.scss";
import TableV2 from "../../../components/common/table/tableV2/TableV2";
import { t } from "i18next";
import useCurrency from "../../../hooks/useCurrency";

const BalanceSheet = () => {
  const [expenseBalancePopup, setShowExpenseBalance] = useState<boolean>(false);
  const [incomeBalancePopup, setShowIncomeBalance] = useState<boolean>(false);
  const [assetsData, setAssetsData] = useState<any>([]);
  const [liabilityData, setLiabilityData] = useState([]);
  const [viewBalancePopData, setViewBalancePopData] = useState({});
  const currentYear = new Date().getFullYear();
  const [yearsFilter, setYearsFilter] = useState<any>({
    value: currentYear,
    label: currentYear,
  });

  const [totalCreditAmount, setTotalCreditAmount] = useState(0);
  const [totalDebitAmount, setTotalDebitAmount] = useState(0);
  const [dataPerPage, setDataPerPage] = useState<number>(10);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [totalData, setTotalData] = useState(0);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [addProfitAndLossAmtData, setProfitAndLossAmtData] = useState(0);

  const animatedComponent = makeAnimated();
  const dispatch = useAppDispatch();
  const { formatCurrency } = useCurrency();

  const { isLoading } = useAppSelector((state) => state.balanceSheet);

  /* Initial API call for Balance Sheet */
  useEffect(() => {
    const expenseRequestData = {
      is_active: "true",
      type: ["Expense", "Income", "Assets"],
      search: "",
      year: yearsFilter?.hasOwnProperty("value")
        ? yearsFilter?.value
        : undefined,
      page: pageIndex,
      pageSize: dataPerPage,
    };

    const incomeRequestData = {
      is_active: "true",
      type: ["Liabilities", "Capital"],
      search: "",
      year: yearsFilter?.hasOwnProperty("value")
        ? yearsFilter?.value
        : undefined,
      page: pageIndex,
      pageSize: dataPerPage,
    };
    dispatch(getAllBalanceSheet(requestGenerator(expenseRequestData))).then(
      (result) => {
        setTotalPage(result.payload.lastPage);
        setTotalData(result.payload.totalData);
        let assetsData = result.payload?.data.filter(
          (p: any) => p.account_no !== 0
        );

        setAssetsData(assetsData);

        let profitLossData = result.payload?.data.filter(
          (p: any) => p.account_no === 0
        );

        let addProfitAndLossAmtData = profitLossData.length
          ? profitLossData[0]?.close?.credit - profitLossData[0]?.close?.debit
          : 0;
        console.log("addProfitAndLossAmtData...", addProfitAndLossAmtData);
        setProfitAndLossAmtData(addProfitAndLossAmtData);

        // Assets Debit Amount
        // let countDebitAmount = 0

        // const calculatedTotalDebitAmount = assetsData?.forEach((item: any) => {
        //   item?.type?.forEach((item: any) => {
        //     countDebitAmount =
        //       countDebitAmount + (item?.debit_amount - item?.credit_amount)
        //   })
        // })
        // const totalAmount =
        //   addProfitAndLossAmtData < 0
        //     ? countDebitAmount + addProfitAndLossAmtData
        //     : countDebitAmount

        const totalAssets: any = assetsData?.find(
          (item: any) => item.account_name === "Grand Total"
        );
        const totalAmount =
          addProfitAndLossAmtData < 0
            ? totalAssets?.close?.debit + addProfitAndLossAmtData
            : totalAssets?.close?.debit;
        setTotalDebitAmount(totalAmount);
      }
    );
    dispatch(getAllBalanceSheet(requestGenerator(incomeRequestData))).then(
      (result) => {
        setTotalPage(result.payload.lastPage);
        setTotalData(result.payload.totalData);
        setLiabilityData(result.payload?.data);
      }
    );
  }, [dataPerPage, dispatch, pageIndex, yearsFilter]);
  // Dynamic year
  const startYear = currentYear - 5;
  let yearOptions = [];

  for (let i = startYear; i <= currentYear; i++) {
    yearOptions?.push({ value: i, label: i });
  }

  const handleViewBalance = () => {
    setShowExpenseBalance((prevState) => !prevState);
  };
  const handleViewBalancePopup = (viewObject: {}) => {
    setShowExpenseBalance((prevState) => !prevState);
    setViewBalancePopData(viewObject);
  };
  const handleIncomeBalance = () => {
    setShowIncomeBalance((prevState) => !prevState);
  };
  const handleIncomeBalancePopup = (viewObject: {}) => {
    setShowIncomeBalance((prevState) => !prevState);
    setViewBalancePopData(viewObject);
  };
  // Liability Credit Amount
  useEffect(() => {
    // let countCreditAmount = 0
    // const calculatedTotalCreditAmount = liabilityData?.forEach((item: any) => {
    //   item?.type?.forEach((item: any) => {
    //     countCreditAmount =
    //       countCreditAmount + (item?.credit_amount - item?.debit_amount)
    //   })
    // })
    // let finalTotal =
    //   addProfitAndLossAmtData > 0
    //     ? countCreditAmount + addProfitAndLossAmtData
    //     : countCreditAmount
    const grandTotal: any = liabilityData?.find(
      (item: any) => item.account_name === "Grand Total"
    );
    let finalTotal = grandTotal?.close?.credit;
    setTotalCreditAmount(finalTotal);
  }, [liabilityData]);

  return (
    <>
      {isLoading && <Loader />}
      {expenseBalancePopup && (
        <Popup
          Children={BalanceSheetLiabilityModal}
          popData={viewBalancePopData}
          handleClose={() => handleViewBalance()}
        />
      )}
      {incomeBalancePopup && (
        <Popup
          Children={BalanceSheetAssetsModal}
          popData={viewBalancePopData}
          handleClose={() => handleIncomeBalance()}
        />
      )}
      <div className={styles.balanceSheetContainer}>
        {/* Filters */}
        <form className={styles.balanceSheetFilter}>
          {/* Years */}

          <div className={styles.inputFieldContainer}>
            <Label
              htmlFor={"YEAR"}
              labelText={t("KPI.Year")}
              customClass={styles.customLabel}
            />
            <Select
              className={styles.selectInputField}
              placeholder={
                <span className="selectPlaceholder">
                  {t("KPI.Select Year")}
                </span>
              }
              isClearable={true}
              isSearchable={false}
              options={yearOptions}
              value={yearsFilter || ""}
              components={animatedComponent}
              closeMenuOnSelect={true}
              maxMenuHeight={120}
              styles={customDropdownStyles}
              onChange={(e: any) => {
                setYearsFilter(e);
              }}
            />
          </div>
        </form>
        <div className={styles.balanceSheetTableContainer}>
          {/* Expense Table */}
          <div className={styles.tableContainer}>
            <div className={styles.tableData}>
              <TableV2
                tableHeaderData={balanceSheetAssetsTableHeaderData ?? []}
                tableRowData={assetsData ?? []}
                active={false}
                handleClick={handleIncomeBalancePopup}
                showTableFoot={true}
                customClasssForViewSlotTrHead={
                  styles.customClasssForViewSlotTrHead
                }
              >
                {addProfitAndLossAmtData <= 0 && assetsData?.length > 0 && (
                  <tr className={styles.tableFooterRow}>
                    <td className={styles.totalText}></td>
                    <td className={styles.totalText}>{t("Reports.Loss")}</td>
                    <td className={styles.totalAmountText}>
                      {addProfitAndLossAmtData}
                    </td>
                    {/* <td></td> */}
                  </tr>
                )}
              </TableV2>
            </div>
            {/* <div className={styles.pagination}>
              {!!(assetsData > 9) && (
                <Pagination
                  setDataPerPage={setDataPerPage}
                  pageIndexOptions={pageIndexOptions}
                  pageIndex={pageIndex}
                  setPageIndex={setPageIndex}
                />
              )}
            </div> */}
          </div>
          {/* Profit Table */}
          <div className={styles.tableContainer}>
            <div className={styles.tableData}>
              <TableV2
                tableHeaderData={balanceSheetLiabilityTableHeaderData ?? []}
                tableRowData={liabilityData ?? []}
                active={false}
                handleClick={handleViewBalancePopup}
                showTableFoot={true}
                customClasssForViewSlotTrHead={
                  styles.customClasssForViewSlotTrHead
                }
              >
                {/* {addProfitAndLossAmtData > 0 && liabilityData?.length > 0 && (
                  <tr className={styles.tableFooterRow}>
                    <td className={styles.totalText}></td>
                    <td className={styles.totalText}>Profit</td>
                    <td className={styles.totalAmountText}>
                      {addProfitAndLossAmtData}
                    </td>
                    <td></td>
                  </tr>
                )} */}
              </TableV2>
            </div>

            {/* <div className={styles.pagination}>
              {!!(assetsData?.length > 10) && (
                <Pagination
                  setDataPerPage={setDataPerPage}
                  pageIndexOptions={pageIndexOptions}
                  pageIndex={pageIndex}
                  setPageIndex={setPageIndex}
                />
              )}
            </div> */}
          </div>
        </div>
        <div className={styles.tableLabelContainer}>
          {/*  Assets */}
          <span className={styles.creditText}>
            {t("Reports.Total_Assets")}: {formatCurrency(totalDebitAmount) || 0}
          </span>
          {/*  Lieabilities */}
          <span className={styles.liabilitiesAndCapitalText}>
            {t("Reports.Total_Liabilities_Capital")}:{" "}
            {formatCurrency(totalCreditAmount) || 0}
          </span>

          <span className={styles.debitText}>
            {addProfitAndLossAmtData >= 0
              ? `${t("Reports.Total_Profit")}: ${
                  formatCurrency(addProfitAndLossAmtData) || 0
                }`
              : ` ${t("Reports.Total_Loss")}: ${
                  formatCurrency(addProfitAndLossAmtData * -1) || 0
                }`}
          </span>
        </div>
      </div>
    </>
  );
};

export default BalanceSheet;
