import React, { FC, useEffect, useState } from "react";
import styles from "./VoucherEntries.module.scss";
import Select from "react-select";
import {
  ACCOUNT,
  COST_CENTER,
  COST_CENTER_VALUE,
  CREDIT_AMOUNT,
  DEBIT_AMOUNT,
  ENTRY_DATE,
  ENTRY_REMARKS,
} from "../voucherConstant";
import { t } from "i18next";
import Button from "../../../components/common/button/Button";
import { CloseIcon, PlusIcon } from "../../../components/common/svg-components";
import { SubmitHandler, useForm } from "react-hook-form";
import { IAddVoucherEntries } from "../voucherInterface";
import { vouchersValidators } from "../voucherValidator";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import makeAnimated from "react-select/animated";
import { disableArrowKey, reactDropdownStyles } from "../../../utils/utils";
import { colors } from "../../../constants/color";
import { costCenterOptions, failure } from "../../../constants/data";
import moment from "moment";
import {
  clearCostCenterData,
  removeCostCenterData,
  setCostCenterData,
  setEditCostCenterData,
} from "../../../redux/features/vouchers/vouchersSlice";
import { requestGenerator } from "../../../utils/payloadGenerator";
import {
  getAllBranch,
  getAllDepartment,
} from "../../../redux/features/vouchers/vouchersAsynActions";
import { getAllDoctorList } from "../../../redux/features/receptionist/receptionistAsyncActions";
import { setMessage } from "../../../redux/features/toast/toastSlice";

interface IVoucherLogModal {
  handleClose: any;
  setMergeVouchersData?: any;
  mergeVouchersData?: any;
  entriesData: any;
}

const VoucherEntries: FC<IVoucherLogModal> = ({
  handleClose,
  setMergeVouchersData,
  mergeVouchersData,
  entriesData,
}) => {
  const [costCenterOption, setcostCenterOption] = useState<any>([]);
  const [costCenterValue, setCostCenterValue] = useState<any>([]);
  const [isEditing, setIsEditing] = useState(false);
  // const [mergeVouchersData, setMergeVouchersData] = useState<any>([]);
  const [dataById, setDataById] = useState<any>({});
  const [isVoucher, setIsVoucher] = useState<Boolean>(false);

  const {
    loading,
    getVoucherName,
    getAccountNum,
    getReferenceDoc,
    addVoucherData,
    voucherListData,
    costCenterList,
    getVoucherData,
  } = useAppSelector((state) => state.vouchers);
  const dispatch = useAppDispatch();
  const animatedComponent = makeAnimated();
  const getAccNum = getAccountNum?.data;

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    setValue: setValue2,
    watch: watch2,
    trigger: trigger2,
    setError: setError2,
    reset: reset2,
    // control,
    formState: { errors: errors2 },
    clearErrors: clearErrors2,
  } = useForm<IAddVoucherEntries>({});

  const onSubmitData: SubmitHandler<IAddVoucherEntries> = (data) => {
    console.log('data', data)
    let UID = Math.floor(Math.random() * Date.now());

    if (costCenterList && costCenterList?.length > 0) {
      if (isEditing) {
        const updatedDataIndex = mergeVouchersData?.findIndex(
          (s: any) => s?.entry_id === dataById?.entry_id
        );
        const init = {
          ...dataById,
          entry_id: dataById?.entry_id,
          // entry_date: data?.entry_date,
          entry_date: moment(new Date()).format("YYYY-MM-DD"),
          accounts_data: data?.accounts_data?.value,
          debit_amount: data?.debit_amount || "0",
          credit_amount: data?.credit_amount || "0",
          cost_center: costCenterList?.map((items: any) => ({
            label: items.costCenter?.value,
            values: items?.costCenterValue?.map((s: any) => ({
              label_values: s.label,
            })),
          })),
          entry_remarks: data?.entry_remarks,
        };
        let updateArray: any = [...mergeVouchersData];
        updateArray[updatedDataIndex] = init;
        setMergeVouchersData(updateArray);
        setcostCenterOption(costCenterOptions);
        handleResetEntry();
        setIsEditing(false);
        handleClose();
      } else {
        setIsVoucher(true);
        const init = {
          entry_id: `${UID}`,
          // entry_date: data?.entry_date,
          entry_date: moment(new Date()).format("YYYY-MM-DD"),
          accounts_data: data?.accounts_data?.value,
          debit_amount: data?.debit_amount || "0",
          credit_amount: data?.credit_amount || "0",
          cost_center: costCenterList?.map((items: any) => ({
            label: items.costCenter?.value,
            values: items?.costCenterValue?.map((s: any) => ({
              label_values: s.label,
            })),
          })),
          entry_remarks: data?.entry_remarks,
        };
        setMergeVouchersData([...mergeVouchersData, init]);
        // dispatch(setAddVoucherData(requestGenerator(init)))
        setcostCenterOption(costCenterOptions);
        handleResetEntry();
        handleClose();
      }
    } else {
      dispatch(
        setMessage({
          message: "Please add atlease one cost center",
          type: failure,
        })
      );
    }
  };

  const watchCreditAmount = watch2(CREDIT_AMOUNT);
  const watchDebitAmount = watch2(DEBIT_AMOUNT);
  const costCenter = watch2(COST_CENTER);
  const watchCostCenter = watch2(COST_CENTER);
  const watchCostCenterValue = watch2(COST_CENTER_VALUE);

  //  Cost Center List Fields add
  useEffect(() => {
    if (costCenterList?.length > 0) {
      const costCenterField = costCenterOptions?.map((item: any) => {
        const costCenterObject = costCenterList?.find((_item: any) => {
          return _item?.costCenter?.value === item?.value;
        });

        if (typeof costCenterObject == "object") {
          return {
            ...item,
            isdisabled: true,
          };
        } else {
          return {
            ...item,
            isdisabled: false,
          };
        }
      });
      setcostCenterOption(costCenterField);
    }
  }, [costCenterList]);

  //Add Cost Center
  const handleCostCenterAdd = () => {
    setValue2(COST_CENTER, "");
    setValue2(COST_CENTER_VALUE, "");

    dispatch(
      setCostCenterData({
        costCenter: watchCostCenter,
        costCenterValue: watchCostCenterValue,
      })
    );
  };
  // Reset Entry Fields
  const handleResetEntry = () => {
    const removeDisabledCostCenter = costCenterOptions?.map((_item: any) => {
      return {
        ..._item,
        isdisabled: false,
      };
    });
    setcostCenterOption(removeDisabledCostCenter);
    reset2();
    setValue2(ACCOUNT, "");
    setValue2(COST_CENTER, "");
    setValue2(COST_CENTER_VALUE, "");
    setValue2(CREDIT_AMOUNT, "");
    setValue2(ENTRY_DATE, moment(new Date()).format("YYYY-MM-DD"));
    setValue2(DEBIT_AMOUNT, "");
    setValue2(ENTRY_REMARKS, "");
    dispatch(clearCostCenterData());
  };
  // Remove Cost Center
  const handleCostCenterRemove = (item: any) => {
    const removeFromList = costCenterList?.filter((test: any) => {
      return test?.costCenter?.value !== item?.costCenter?.value;
    });
    dispatch(removeCostCenterData(removeFromList));
    const removeDisabledCostCenter = costCenterOptions?.map((_item: any) => {
      if (_item?.value === item?.costCenter?.value) {
        return {
          ..._item,
          isdisabled: false,
        };
      } else {
        return {
          ..._item,
        };
      }
    });
    setcostCenterOption(removeDisabledCostCenter);
  };

  useEffect(() => {
      setcostCenterOption(costCenterOptions);
      setValue2(COST_CENTER, "");
      setValue2(DEBIT_AMOUNT, "");
      setValue2(CREDIT_AMOUNT, "");
  }, []);

  //  API calls for call center
  useEffect(() => {
    let payloadData = {
      search: "",
      page: 1,
      pageSize: 1000,
    };
    if (costCenter?.label === "Branch") {
      dispatch(getAllBranch(requestGenerator(payloadData))).then((e) => {
        setCostCenterValue(e?.payload?.data);
        setValue2(COST_CENTER_VALUE, "");
      });
    } else if (costCenter?.label === "Doctor") {
      dispatch(getAllDoctorList(requestGenerator(payloadData))).then((e) => {
        setCostCenterValue(e?.payload?.data);
        setValue2(COST_CENTER_VALUE, "");
      });
    } else {
      dispatch(getAllDepartment(requestGenerator(payloadData))).then((e) => {
        setCostCenterValue(e?.payload?.data);
        setValue2(COST_CENTER_VALUE, "");
      });
    }
  }, [costCenter]);

  useEffect(() => {
    if (entriesData) {
      setIsEditing(true);
      const filterData = mergeVouchersData?.find(
        (s: any) => s.entry_id === entriesData.entry_id
      );
      setDataById(filterData);
      setValue2(
        ENTRY_DATE,
        moment(filterData?.entry_date)?.format("YYYY-MM-DD")
      );

      setValue2(ENTRY_REMARKS, filterData?.entry_remarks);
      setValue2(
        CREDIT_AMOUNT,
        filterData?.credit_amount > 0 ? filterData?.credit_amount : ""
      );
      setValue2(
        DEBIT_AMOUNT,
        filterData?.debit_amount > 0 ? filterData?.debit_amount : ""
      );
      setValue2(ACCOUNT, {
        label: `${filterData?.accounts_data?.code_id} - ${filterData?.accounts_data?.name}`,
        value: filterData?.accounts_data,
      });
      // Set Cost center fields
      const newCostCenterArray = entriesData?.cost_center?.map((item: any) => {
        return {
          costCenter: {
            label: item?.label,
            value: item?.label,
          },
          costCenterValue: item?.values?.map((_item: any) => {
            return {
              label: _item?.label_values,
              value: _item?.label_values,
              _id: _item?._id,
            };
          }),
        };
      });
      console.log("newCostCenterArray", newCostCenterArray);
      console.log("costCenterOptions", costCenterOptions);
      setcostCenterOption(costCenterOptions);
      dispatch(setEditCostCenterData(newCostCenterArray));
    }
  }, [entriesData]);

  return (
    <>
      <form
        className={styles.vouchersForm}
        onSubmit={handleSubmit2(onSubmitData)}
      >
        <div className={styles.formFieldRow}>
          {/* Account */}
          <div className={styles.formFieldContainer}>
            <div className={styles.inputFieldContainer}>
              <label htmlFor={ACCOUNT} className={styles.formLabel}>
                {t("Voucher.Account")}
                <span className="asterick">*</span>
              </label>
              <Select
                className={styles.selectInputField}
                placeholder={
                  <span className="selectPlaceholder">
                    {t("Voucher.Account_placeholder")}
                  </span>
                }
                {...register2(ACCOUNT, vouchersValidators[ACCOUNT])}
                isSearchable={true}
                isClearable={true}
                options={getAccNum?.map((item: any) => ({
                  label: `${item.code} - ${item.name}`,
                  value: {
                    code_id: `${item.code}`,
                    name: item.name,
                    level_one: item.level_one,
                    level_two: item.level_two,
                    creditOrDebit: item.creditOrDebit,
                  },
                }))}
                maxMenuHeight={200}
                value={watch2(ACCOUNT) || []}
                components={animatedComponent}
                closeMenuOnSelect={true}
                styles={reactDropdownStyles}
                onChange={(e: any) => {
                  setValue2(ACCOUNT, e);
                  trigger2(ACCOUNT);
                }}
              />
            </div>
            {errors2[ACCOUNT] && (
              <div className={styles.errorContainer}>
                <span className={styles.extraSpan}></span>
                <p className="dashboardFormError">
                  {errors2[ACCOUNT].message as any}
                </p>
              </div>
            )}
          </div>
          {/* Debit Amount */}
          <div className={styles.formFieldContainer}>
            <div className={styles.inputFieldContainer}>
              <label htmlFor={DEBIT_AMOUNT} className={styles.formLabel}>
                {t("Voucher.Debit_Amount")}
                <span className="asterick">*</span>
              </label>
              <input
                type="number"
                placeholder={
                  t("Voucher.Debit_Amount_placeholder") || "Enter Debit Amount"
                }
                className={styles.inputField}
                {...register2(DEBIT_AMOUNT, vouchersValidators[DEBIT_AMOUNT])}
                disabled={watchCreditAmount !== "" ? true : false}
                onKeyDown={(e: any) => disableArrowKey(e)}
                onWheel={(e: any) => {
                  e.target.blur();
                }}
              />
            </div>
            {errors2[DEBIT_AMOUNT] && (
              <div className={styles.errorContainer}>
                <span className={styles.extraSpan}></span>
                <p className="dashboardFormError">
                  {errors2[DEBIT_AMOUNT].message as any}
                </p>
              </div>
            )}
          </div>
          {/* Credit Amount */}
          <div className={styles.formFieldContainer}>
            <div className={styles.inputFieldContainer}>
              <label htmlFor={CREDIT_AMOUNT} className={styles.formLabel}>
                {t("Voucher.Credit_Amount")}
                <span className="asterick">*</span>
              </label>
              <input
                type="number"
                placeholder={
                  t("Voucher.Credit_Amount_placeholder") ||
                  "Enter Credit Amount"
                }
                className={styles.inputField}
                {...register2(CREDIT_AMOUNT, vouchersValidators[CREDIT_AMOUNT])}
                onKeyDown={(e: any) => disableArrowKey(e)}
                onWheel={(e: any) => {
                  e.target.blur();
                }}
                disabled={watchDebitAmount !== "" ? true : false}
              />
            </div>
            {errors2[CREDIT_AMOUNT] && (
              <div className={styles.errorContainer}>
                <span className={styles.extraSpan}></span>
                <p className="dashboardFormError">
                  {errors2[CREDIT_AMOUNT].message as any}
                </p>
              </div>
            )}
          </div>
          {/* Entry Date */}
          {/* <div className={styles.formFieldContainer}>
            <div className={styles.inputFieldContainer}>
              <label htmlFor={ENTRY_DATE} className={styles.formLabel}>
                {t("Voucher.Entry_Date")}
                <span className="asterick">*</span>
              </label>
              <input
                type="date"
                max="9999-12-31"
                className={styles.inputField}
                {...register2(ENTRY_DATE, vouchersValidators[ENTRY_DATE])}
                // defaultValue={moment(new Date()).format('YYYY-MM-DD')}
              />
            </div>
            <div className={styles.errorContainer}>
              <span className={styles.extraSpan}></span>
              {errors2[ENTRY_DATE] && (
                <p className="dashboardFormError">
                  {errors2[ENTRY_DATE].message as any}
                </p>
              )}
            </div>
          </div> */}
          {/*Entry Remarks */}
          <div className={styles.formFieldContainer}>
            <div className={styles.inputFieldContainer}>
              <label htmlFor={ENTRY_REMARKS} className={styles.formLabel}>
                {t("Voucher.Entry_Remarks")}
              </label>
              <textarea
                placeholder={
                  t("Voucher.Entry_Remarks_placeholder") ||
                  "Enter Entry Remarks"
                }
                className={styles.textArea}
                {...register2(ENTRY_REMARKS)}
              />
            </div>
            <div className={styles.errorContainer}>
              <span className={styles.extraSpan}></span>
              {errors2[ENTRY_REMARKS] && (
                <p className="dashboardFormError">
                  {errors2[ENTRY_REMARKS].message as any}
                </p>
              )}
            </div>
          </div>
          <div className={styles.formFieldCostContainer}>
            {/* Cost Center */}
            <div className={styles.formFieldContainer}>
              <div className={styles.inputFieldContainer}>
                <label htmlFor={COST_CENTER} className={styles.formLabel}>
                  {t("Voucher.Cost_Center")}
                  {costCenterList?.length === 0 && (
                    <span className="asterick">*</span>
                  )}
                </label>
                <Select
                  className={styles.selectInputField}
                  placeholder={
                    <span className="selectPlaceholder">
                      {t("Voucher.Cost_Center_placeholder")}
                    </span>
                  }
                  styles={reactDropdownStyles}
                  {...register2(
                    COST_CENTER,
                    costCenterList?.length
                      ? { required: false }
                      : vouchersValidators[COST_CENTER]
                  )}
                  value={watch2(COST_CENTER) || []}
                  isSearchable={true}
                  isClearable={true}
                  maxMenuHeight={200}
                  options={costCenterOption?.map((item: any) => ({
                    label: item?.label,
                    value: item?.value,
                    isDisabled: item?.isdisabled,
                  }))}
                  components={animatedComponent}
                  closeMenuOnSelect={true}
                  onChange={(e: any) => {
                    setValue2(COST_CENTER, {
                      label: e?.label,
                      value: e?.value,
                    });
                    setCostCenterValue([])
                  }}
                />
              </div>
              <div className={styles.errorContainer}>
                <span className={styles.extraSpan}></span>
                {errors2[COST_CENTER] && (
                  <p className="dashboardFormError">
                    {errors2[COST_CENTER].message as any}
                  </p>
                )}
              </div>
            </div>
            {/* Cost Center Value */}
            <div className={styles.formFieldContainer}>
              <div className={styles.inputFieldContainer}>
                <label htmlFor={COST_CENTER_VALUE} className={styles.formLabel}>
                  {t("Voucher.Cost_Center_Value")}
                  {costCenterList?.length === 0 && (
                    <span className="asterick">*</span>
                  )}
                  {/* <span className="asterick">*</span> */}
                </label>
                <Select
                  className={styles.selectInputField}
                  placeholder={
                    <span className="selectPlaceholder">
                      {t("Voucher.Cost_Center_Value_placeholder")}
                    </span>
                  }
                  {...register2(
                    COST_CENTER_VALUE,
                    costCenterList?.length
                      ? { required: false }
                      : vouchersValidators[COST_CENTER_VALUE]
                  )}
                  isSearchable={true}
                  isClearable={true}
                  options={costCenterValue?.map((item: any) => ({
                    label: item?.name
                      ? item?.name
                      : item?.doctor_name
                      ? item?.doctor_name
                      : "",
                    value: item?._id,
                  }))}
                  isMulti
                  value={watch2(COST_CENTER_VALUE) || []}
                  maxMenuHeight={200}
                  components={animatedComponent}
                  closeMenuOnSelect={false}
                  styles={reactDropdownStyles}
                  onChange={(e: any) => {
                    setValue2(COST_CENTER_VALUE, e);
                    trigger2(COST_CENTER_VALUE);
                  }}
                  isDisabled={watchCostCenter == "" ? true : false}
                />
              </div>
              <div className={styles.errorContainer}>
                <span className={styles.extraSpan}></span>
                {errors2[COST_CENTER_VALUE] && (
                  <p className="dashboardFormError">
                    {errors2[COST_CENTER_VALUE].message as any}
                  </p>
                )}
              </div>

              <div className={styles.addcCostIconStyle}>
                <PlusIcon
                  fillColor={colors?.green1}
                  customClass={styles.costIconStyle}
                  handleClick={() => {
                    if (watchCostCenter !== "" && watchCostCenterValue !== "") {
                      handleCostCenterAdd();
                    }
                  }}
                />
              </div>
            </div>
          </div>
          {/* Cost Center List Fields */}
          {costCenterList?.map((item: any) => {
            return (
              <>
                <div
                  className={styles.formFieldCostContainer}
                  key={item?.costCenter?.value}
                >
                  {/* Cost Center */}
                  <div className={styles.formFieldContainer}>
                    <div className={styles.inputFieldContainer}>
                      <label htmlFor={COST_CENTER} className={styles.formLabel}>
                        {t("Voucher.Cost_Center")}
                      </label>
                      <Select
                        className={styles.selectInputField}
                        styles={reactDropdownStyles}
                        value={{
                          label: item?.costCenter?.label,
                          value: item?.costCenter?.value,
                        }}
                        isDisabled={true}
                      />
                    </div>
                  </div>
                  {/* Cost Center Value */}
                  <div className={styles.formFieldContainer}>
                    <div className={styles.inputFieldContainer}>
                      <label
                        htmlFor={COST_CENTER_VALUE}
                        className={styles.formLabel}
                      >
                        {t("Voucher.Cost_Center_Value")}
                      </label>
                      <Select
                        className={styles.selectInputField}
                        value={item?.costCenterValue}
                        isMulti
                        styles={reactDropdownStyles}
                        isDisabled={true}
                      />
                    </div>
                    <div className={styles.addcCostIconStyle}>
                      <CloseIcon
                        customClass={styles.costIconStyle}
                        fillColor={colors.red1}
                        handleClick={() => handleCostCenterRemove(item)}
                      />
                    </div>
                  </div>
                </div>
              </>
            );
          })}
         
        </div>
        <div className={styles.formBtnContainer}>
            {isEditing ? (
              <Button
                title={t("ManageCategory.Update") || "Update"}
                customClass={styles.addBtn}
                handleClick={handleSubmit2(onSubmitData)}
              />
            ) : (
              <Button
                title={t("ActionBar.Add") || "Add"}
                type="submit"
                customClass={styles.addBtn}
                handleClick={handleSubmit2(onSubmitData)}
              />
            )}

            <Button
              title={t("BranchSetup.Reset") || "Reset"}
              type="reset"
              customClass={styles.resetBtn}
              handleClick={async () => {
                // await setIsVoucher(false)
                await handleResetEntry();
              }}
            />
          </div>
      </form>
    </>
  );
};

export default VoucherEntries;
