import { createSlice } from "@reduxjs/toolkit";
import {
  updateAllMasterImportAction,
  getAllMasterImportAction,
} from "./masterImportAsyncAction";

export interface IMasterImport {
  isLoading: boolean;
  error: any;
  getMedicine: any;
  addMedicine: any;
  getMedicineList: any;
  isSelectAll: any;
}
export const initialState: IMasterImport = {
  isLoading: false,
  error: null,
  isSelectAll: false,
  getMedicine: [],
  getMedicineList: [],
  addMedicine: {},
};
export const masterImportSlice = createSlice({
  name: "masterImport",
  initialState,
 
  reducers: {
    setMedicineIdList: (state, action) => {
      state.getMedicine = [...state.getMedicine, action?.payload];
    },
    removeFromMedicineIdList: (state, action) => {
      state.getMedicine = state.getMedicine?.filter((item: any) => {
        return item !== action?.payload;
      });
    },
    setSelectAll: (state, action) => {
      state.getMedicine = action?.payload?.map((item: any) => {
        return item?.code;
      });
      state.isSelectAll = !state.isSelectAll;
    },
    removeSelectAll: (state) => {
      state.getMedicine = [];
      state.isSelectAll = !state.isSelectAll;
    },
    clearAllStates: (state) => {
      state.isLoading = false;
      state.error = "";
      state.getMedicine = [];
      state.isSelectAll = false;
    },
  },
  extraReducers(builder) {
    // GET ALL MASTER IMPORT
    builder
      .addCase(getAllMasterImportAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllMasterImportAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.getMedicineList = action.payload
      })
      .addCase(getAllMasterImportAction.rejected, (state, error) => {
        state.isLoading = false;
        state.error = error;
      });
    // ADD ALL MASTER IMPORT
    builder
      .addCase(updateAllMasterImportAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateAllMasterImportAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.addMedicine = action?.payload;
      })
      .addCase(updateAllMasterImportAction.rejected, (state, error) => {
        state.isLoading = false;
        state.error = error;
      });
  },
});
export const {
  setMedicineIdList,
  removeFromMedicineIdList,
  setSelectAll,
  removeSelectAll,
  clearAllStates,
} = masterImportSlice.actions;
export default masterImportSlice.reducer;
