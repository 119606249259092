// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".purchaseInvoiceTableData_inputContainer__dFEGC {\n  width: 75px;\n  height: 29px;\n  border-radius: 6px;\n  border: 1px solid #CDD4D8;\n  text-align: center;\n}\n\n.purchaseInvoiceTableData_priceContainer__IpcQW {\n  width: 110px;\n}", "",{"version":3,"sources":["webpack://./src/constants/table-data/purchaseInvoiceTableData.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,kBAAA;EACA,yBAAA;EAEA,kBAAA;AAAJ;;AAGA;EACI,YAAA;AAAJ","sourcesContent":[".inputContainer {\n    width: 75px;\n    height: 29px;\n    border-radius: 6px;\n    border: 1px solid #CDD4D8;\n    // padding-left: 12px;  \n    text-align: center;\n}\n\n.priceContainer{\n    width: 110px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputContainer": "purchaseInvoiceTableData_inputContainer__dFEGC",
	"priceContainer": "purchaseInvoiceTableData_priceContainer__IpcQW"
};
export default ___CSS_LOADER_EXPORT___;
