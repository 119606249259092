import jsPDF from 'jspdf'
import 'jspdf-autotable'
import image from '../../../assets/images/healthBoxLogo.png'
import moment from 'moment'
import styles from './style.module.scss'
import { handlePadStart } from '../../../utils/utils'
import { t } from 'i18next'

export const SubmittedPiHeadersData = [
  {
    Header: t('RcptTableHeader.DATE'),
    accessor: (row: any) => {
      return moment(row?.createdAt).format('DD-MMM-YYYY') ?? '-'
    },
  },
  {
    Header: t('INVTableHeader.DOCID'),
    Cell: (props: any) => {
      const totalAmount = props.row.original.grn_item_details?.reduce(
        (acc: any, obj: any) => {
          return acc + obj.item_total_amount
        },
        0
      )

      const totalQty = props.row.original.grn_item_details?.reduce(
        (acc: any, obj: any) => {
          return acc + obj.quantity
        },
        0
      )
      const generatePdf = () => {
        const doc: any = new jsPDF('p', 'pt', 'a4')

        const width = doc.internal.pageSize.getWidth()
        let tableData: any = []
        props.row.original?.grn_item_details.forEach(
          (items: any, index: number) => {
            tableData.push([
              index + 1,
              items.item_code,
              items.item_name,
              items.base_unit_type,
              items.quantity,
              items.price,
              items?.item_total_amount,
            ])
          }
        )
        let offsetY = 4.797777777777778
        let lineHeight = 6.49111111111111

        const imgData = image
        doc.addImage(imgData, 'JPEG', width / 2, 20, 40, 40, {
          align: 'center',
        })
        doc
          .text('PURCHASE INVOICE', width / 2, 80, { align: 'center' })
          .setFontSize(10)
          .setFont(undefined, 'bold')
        doc
          .text(`Supplier: ${props.row.original.supplier_name ?? '-'}`, 60, 120)
          .setFontSize(8)
          .setFont(undefined, 'bold')
        doc
          .text(
            `Supp. Invoice No. :${props.row.original?.grn_details?.supplier_doc_no}`,
            60,
            140
          )
          .setFontSize(8)
          .setFont(undefined, 'bold')
        // doc
        //   .text(`Ref No: QAR849`, 400, 120)
        //   .setFontSize(8)
        //   .setFont(undefined, "bold");
        doc
          .text(
            `Date: ${moment(props.row.original.createdAt).format(
              'DD-MMM-YYYY'
            )}`,
            400,
            140
          )
          .setFontSize(8)
          .setFont(undefined, 'bold')

        doc.autoTable({
          startY: 155,
          styles: { halign: 'center', textColor: '#000', fillColor: '#fefefe' },
          tableLineColor: '#000',
          tableLineWidth: 0.1,
          headStyles: { lineWidth: 0.1, lineColor: '#000' },
          bodyStyles: { fontSize: '8', lineWidth: 0.1, lineColor: '#000' },
          footStyles: {
            fontSize: '8',
            halign: 'center',
            lineWidth: 0.1,
            lineColor: '#000',
          },

          head: [['Sr', 'Item', 'Name', 'Unit', 'Qty', 'Price', 'Amount']],
          body: tableData,
          foot: [
            [
              '',
              '',
              '',
              '',
              `Total Qty: ${totalQty}`,
              '',
              `Total: ${totalAmount?.toLocaleString()}`,
            ],
          ],
        })
        const dynamicHeight = doc?.autoTable?.previous?.finalY

        doc
          .text('Approved By', 500, dynamicHeight + lineHeight * 5 + offsetY)
          .setFontSize(8)
          .setFont(undefined, 'bold')

        doc.save('PI.pdf')
        window.open(doc.output('bloburl'), '_blank')
      }

      return (
        <p className={styles.link} onClick={generatePdf}>
          {props?.row?.original?.grn_details?.grn_doc_no}
        </p>
      )
    },
  },
  {
    Header: t('PurchaseInvoice.PINO'),
    accessor: (row: any) => {
      let piNO = row?.invoice_no//handlePadStart(row?.invoice_no)
      return row?.invoice_no ? <p>{piNO}</p> : '-'
    },
  },
  {
    Header: t('MainStore.SUPPLIER NAME'),
    accessor: (row: any) => {
      return row?.supplier_name ?? '-'
    },
  },
]
