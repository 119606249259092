import { createSlice } from "@reduxjs/toolkit";
import {
  createInventoryItem,
  getInventoryItemList,
  inventoryItemListWidgets,
  inventoryItemRequestsWidgets,
  updateInventoryItem,
  updateStatusInventoryItem,
} from "./inventoryItemAsyncActions";
import { IInventoryItem } from "../../../interfaces/apiInterface";
import { data } from "../../../pages/agent/agent-info-table/agent-leads/data";

export const initialState: IInventoryItem = {
  isLoading: false,
  isStatusUpdated: true,
  inventoryItemList: [],
  error: null,
  inventoryItemWidgetLists: [],
  inventoryItemRequestList: [],
  inventoryItemWidgetLoader: false,
  inventoryRequestItemWidgetLoader: false,
  inventoryItemsListDetails: {},
  inventoryRequestItemsDetails: {},
};

export const InventoryItemSlice = createSlice({
  name: "inventoryItem",
  initialState,
  reducers: {
    setInventoryItemsData: (state, action) => {
      state.inventoryItemsListDetails = {
        data: action.payload,
        total: state.inventoryItemsListDetails?.total,
      };
    },
    setInventoryRequestItemsData: (state, action) => {
      state.inventoryRequestItemsDetails = {
        data: action.payload,
        total: state.inventoryRequestItemsDetails?.total,
      };
    },
  },

  extraReducers: (builder) => {
    // GET ALL INVENTORY Item table list of SUPPLIER
    builder.addCase(getInventoryItemList.pending, (state) => {
      state.isLoading = true;
      state.isStatusUpdated = false;
    });
    builder.addCase(getInventoryItemList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isStatusUpdated = false;
      state.inventoryItemList = action.payload?.data;
    });
    builder.addCase(getInventoryItemList.rejected, (state, error) => {
      state.isLoading = false;
      state.isStatusUpdated = false;
      state.error = error;
    });

    // CREATE INVENTORY Item SUPPLIER

    builder.addCase(createInventoryItem.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createInventoryItem.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(createInventoryItem.rejected, (state, error) => {
      state.isLoading = false;
      state.error = error;
    });

    // EDIT INVENTORY Item SUPPLIER
    builder.addCase(updateInventoryItem.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateInventoryItem.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(updateInventoryItem.rejected, (state, error) => {
      state.isLoading = false;
      state.error = error;
    });

    builder.addCase(updateStatusInventoryItem.pending, (state) => {
      state.isLoading = true;
      state.isStatusUpdated = false;
    });
    builder.addCase(updateStatusInventoryItem.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isStatusUpdated = true;
    });
    builder.addCase(updateStatusInventoryItem.rejected, (state, error) => {
      state.isLoading = false;
      state.isStatusUpdated = false;
      state.error = error;
    });

    // inventory item widgets
    builder.addCase(inventoryItemListWidgets.pending, (state) => {
      state.inventoryItemWidgetLoader = true;
    });
    builder.addCase(inventoryItemListWidgets.fulfilled, (state, action) => {
      state.inventoryItemWidgetLists = action.payload?.data;
      state.inventoryItemWidgetLoader = false;
      state.inventoryItemsListDetails = {
        data: action.payload?.data,
        total: action.payload?.total,
      };
    });
    builder.addCase(inventoryItemListWidgets.rejected, (state, error) => {
      state.inventoryItemWidgetLoader = false;
    });

    // inventory item  request widgets
    builder.addCase(inventoryItemRequestsWidgets.pending, (state) => {
      state.inventoryRequestItemWidgetLoader = true;
    });
    builder.addCase(inventoryItemRequestsWidgets.fulfilled, (state, action) => {
      state.inventoryItemRequestList = action.payload?.data;
      state.inventoryRequestItemWidgetLoader = false;
      state.inventoryRequestItemsDetails = {
        data: action.payload?.data,
        total: action.payload?.total,
      };
    });
    builder.addCase(inventoryItemRequestsWidgets.rejected, (state, error) => {
      state.inventoryRequestItemWidgetLoader = false;
    });
  },
});

export const { setInventoryItemsData,setInventoryRequestItemsData } = InventoryItemSlice.actions;
export default InventoryItemSlice.reducer;
