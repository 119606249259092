import moment from "moment";
import { useNavigate } from "react-router";

import { useAppDispatch } from "../../hooks";
import { requestGenerator } from "../../utils/payloadGenerator";
import { getPatientEmrById } from "../../redux/features/patient-emr/patient/patientAsyncAction";
import { setMessage } from "../../redux/features/toast/toastSlice";
import { failure } from "../data";
import { t } from "i18next";
import MasterAppointmentStatusDropdown from "../../components/common/status-dropdown/master-appointment-status-dropdown/MasterAppoinmentStatusDropdown";
import React, { useState } from "react";
import { updateAppointmentStatus } from "../../redux/features/appointment/appointmentAsyncActions";
import { Link } from "react-router-dom";

export const viewAppointmentPopupData: any = [
  {
    Header: t("RcptTableHeader.FILE NO"),
    Cell: ({ row, onPopClose }: any) => {
      const objectId = row?.original;
      const dispatch = useAppDispatch();
      const navigate = useNavigate();
      const handleEmrRecord = (item: any) => {
        let dataPayload = {
          id: item,
        };
        dispatch(getPatientEmrById(requestGenerator(dataPayload))).then(
          (e: any) => {
            if (e.type === "patient/getPatientEmrById/fulfilled") {
              navigate("/patientemr");
              onPopClose(false);
            } else {
              let toastData = {
                message: e?.payload,
                type: failure,
              };
              dispatch(setMessage(toastData));
            }
          }
        );
      };
      return (
        <span
          onClick={() =>
            objectId?.pnt_emr_no
              ? handleEmrRecord(row?.original?.patient_id)
              : {}
          }
          style={{ cursor: "pointer", color: "#0e26a3" }}
        >
          {objectId?.pnt_emr_no ? objectId?.pnt_emr_no : "-"}
        </span>
      );
    },
  },
  {
    Header: t("RcptTableHeader.DATE"),
    // accessor: 'appointment_date',
    Cell: ({ row }: any) => {
      const originalDate = row?.original?.appointment_timestamp;
      const formattedDate = moment(originalDate).format("DD MMM YYYY");
      return <>{formattedDate}</>;
    },
  },
  {
    Header: t("RcptTableHeader.PATIENT"),
    accessor: "pnt_user_name",
  },
  {
    Header: t("RcptTableHeader.MOBILE NO"),
    accessor: "pnt_user_phone",
  },
  {
    Header: t("ViewAppointmentHeader.DOCTOR"),
    accessor: "dct_user_name",
  },
  {
    Header: t("ViewAppointmentHeader.ROOM"),
    accessor: "room",
    Cell: ({ row }: any) => {
      let Room = row?.original?.room;
      return <p>{Room ? Room : "-"}</p>;
    },
  },
  {
    Header: t("RcptTableHeader.STATUS"),
    Cell: (props: any) => {
      let appointment_id = props?.row?.original?._id;
      const [showOption, setShowOption] = useState<boolean>(false);
      const dispatch = useAppDispatch();
      const handleStatus = (item: any) => {
        let status = item.label.toUpperCase();
        if (
          status === "RESCHEDULED" ||
          status === "INPROGRESS" ||
          status === "COMPLETED"
        ) {
          return;
        } else {
          setShowOption(!showOption);
          let reqData = {
            appointment_id: appointment_id,
            status: status.replace(/\s/g, ""),
          };
          dispatch(updateAppointmentStatus(requestGenerator(reqData))).then(
            (e) => {
              if (e.type === "appointment/updateAppointmentStatus/fulfilled") {
                props?.getPropsData();
              }
            }
          );
        }
      };
      return (
        <>
          <MasterAppointmentStatusDropdown
            appointmentStatus={props?.row?.original?.status}
            showOption={showOption}
            setShowOption={setShowOption}
            handleStatus={(item: any) => handleStatus(item)}
            isDisable={
              // props?.row?.original?.status === "NOSHOW" ||
              props?.row?.original?.status === "CANCELLED" ||
              props?.row?.original?.status === "COMPLETED"
            }
          />
        </>
      );
    },
  },
  {
    Header: t("ViewAppointmentHeader.TIME IN"),
    accessor: "time_in",
    Cell: ({ row }: any) => {
      let appointment_time = row?.original?.time_in;
      const formHour = appointment_time.split(":")[0];
      const fromMin = appointment_time.split(":")[1];
      return (
        <p>{`${
          Number(formHour) > 12 ? Number(formHour) - 12 : formHour
        }:${fromMin}${Number(formHour) >= 12 ? " PM" : " AM"} `}</p>
      );
    },
  },
  {
    Header: t("ViewAppointmentHeader.TIME OUT"),
    Cell: ({ row }: any) => {
      let appointment_time = row?.original?.time_in;
      let time_duration = row?.original?.time_duration;

      let addTime = moment(appointment_time, "HH:mm");
      let timeOut = addTime.add(time_duration, "minutes").format("HH:mm");

      const toHour = timeOut.split(":")[0];
      const toMin = timeOut.split(":")[1];

      return (
        <p>{`${Number(toHour) > 12 ? Number(toHour) - 12 : toHour}:${toMin}${
          Number(toHour) >= 12 ? " PM" : " AM"
        } `}</p>
      );
    },
  },
  {
    Header: t("ViewAppointmentHeader.NOTES"),
    accessor: "problem_description",
    Cell: () => {
      return React.createElement(
        Link,
        { to: "/", className: "viewLinkStyle" },
        "View"
      );
    },
  },
];
