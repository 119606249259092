import {
  CHECK_WHATSUP_CONFIG,
  GET_WHATSUP_DETAILS,
  UPDATE_WHATSUP_CONFIG,
} from "../../../constants/asyncActionsType";
import createAsyncThunkForSlice from "../../../utils/utils";
import {
  checkWhatsUpConfigurations,
  getWhatsUpConfigurations,
  updateWhatsUpConfigurations,
} from "./whatsupCrud";

export const checkWhatsupConfigurationsDetails = createAsyncThunkForSlice(
  CHECK_WHATSUP_CONFIG,
  checkWhatsUpConfigurations,
  {
    isToast: true,
  }
);

export const updateWhatsUpConfigurationsDetails = createAsyncThunkForSlice(
  UPDATE_WHATSUP_CONFIG,
  updateWhatsUpConfigurations,
  {
    isToast: true,
  }
);

export const getWhatsupDetails = createAsyncThunkForSlice(
  GET_WHATSUP_DETAILS,
  getWhatsUpConfigurations,
);
