import { createSlice } from "@reduxjs/toolkit";
import { IAppointmentState } from "../../../interfaces/apiInterface";
import {
  getAllDoctorAppointments,
  getAllDoctors,
  getAvailableSlots,
  bookingConfirmation,
  getRecurringAvailableSlots,
  getAllPhysioAppointments,
  getAllRoomsForCalnedar,
  getDocUnavailability,
  createUnavailablity,
  updateUnavailablity,
  deleteUnavailablity,
  getAllRoomsForCalnedarDrodown,
  getAllDoctorAppointmentsWithDate,
  getAllPhysioAppointmentsWithDate,
} from "./bookingAppointmentAsyncActions";
import { flatten } from "lodash";
import moment from "moment";
import { getShiftTimeRange } from "../../../utils/utils";
import { colorSchemeData } from "../../../constants/data";
import { actions } from "react-table";

export const initialState: IAppointmentState = {
  loading: false,
  doctorLoading: false,
  doctorData: [],
  // appointmentsData: [],
  dataSource: [],
  resources: [],
  availbleSlots: [],
  totalCount: 0,
  hourStartEndTime: {},
  selectedSlots: [],
  recurringSelectedSlots: [],
  payloadForAvailableSlots: {},
  recurringAvailableSlots: [],
  colorSchemeData: [],
  currentViewDate: "",
  hasMorePage: false,
  docUnavailabilty: [],
  roomCalendarDate: "",
  filterList: [],
  allRoomsList: [],
  allDoctorsList: [],
  selectedBranch:""
};

export const appointmentSlice = createSlice({
  name: "appoinments",
  initialState,
  reducers: {
    clearData: (state) => {
      state.loading = false;
      state.doctorLoading = false;
      state.doctorData = [];
      state.hasMorePage = false;
      state.resources = [];
      state.dataSource = [];
      // state.appointmentsData = []
      state.availbleSlots = [];
      state.totalCount = 0;
      state.hourStartEndTime = {};
      state.selectedSlots = [];
      state.recurringAvailableSlots = [];
      state.payloadForAvailableSlots = {};
      state.recurringAvailableSlots = [];
      state.docUnavailabilty = [];
      state.roomCalendarDate = "";
    },
    clearSlotData: (state) => {
      state.availbleSlots = [];
      state.selectedSlots = [];
      state.recurringAvailableSlots = [];
      state.recurringSelectedSlots = [];
    },
    setAppointmentsSlots: (state, action) => {
      state.availbleSlots = action.payload;
    },
    setRecurringAppointmentsSlots: (state, action) => {
      state.recurringAvailableSlots = action.payload;
    },
    setSelectedSlots: (state, action) => {
      state.selectedSlots = action.payload || [];
    },
    setRecurringSelectedSlots: (state, action) => {
      state.recurringSelectedSlots = action.payload || [];
    },
    setAvialbleSlotsPayload: (state, action) => {
      state.payloadForAvailableSlots = action.payload;
    },
    addColorSchemeData: (state, action) => {
      state.colorSchemeData =
        action.payload && action?.payload?.length > 0
          ? action.payload
          : colorSchemeData;
    },
    setCurrentViewDate: (state, action) => {
      state.currentViewDate = action?.payload;
    },
    setRoomCalendarDate: (state, action) => {
      state.roomCalendarDate = action.payload;
    },
    setCalendarStartEndTime: (state, action) => {
      const userShiftArray = action?.payload?.map(
        (item: any) => item?.user_shift
      );
      // set doctors shift timing and hour start/end
      let shiftFlatArr = flatten(userShiftArray);
      let finalShiftStartEndArr: any = [];
      finalShiftStartEndArr =
        shiftFlatArr &&
        shiftFlatArr?.length > 0 &&
        shiftFlatArr.map((item: any) => {
          const {
            shift_one_start,
            shift_one_end,
            shift_two_start,
            shift_two_end,
          } = item;
          return {
            shift_one_start,
            shift_one_end,
            shift_two_start,
            shift_two_end,
          };
        });
      state.hourStartEndTime = getShiftTimeRange(finalShiftStartEndArr) || {};
    },
    setPaginationDoctorData: (state, action) => {
      state.resources = [
        {
          label: "Doctors",
          value: "_id",
          dataSource: action.payload || [],
        },
      ];
    },
    setFilterDoctorList: (state, action) => {
      state.filterList = action.payload;
    },
    setAllDoctorList: (state, action) => {
      state.allDoctorsList = action.payload;
    },
    setSelectedBranch: (state, action) => {
      state.selectedBranch = action.payload;
    },
  },
  extraReducers(builder) {
    //getalldoctors
    builder.addCase(getAllDoctors.pending, (state) => {
      state.doctorLoading = true;
      state.hasMorePage = false;
    });
    builder.addCase(getAllDoctors.fulfilled, (state, action) => {
      state.loading = false;
      state.doctorLoading = false;
      state.hasMorePage = action.payload?.hasMorePages ?? false;
      //set  doctors list
      // state.doctorData =
      //   action.payload?.data && action.payload?.data?.length > 0
      //     ? action.payload?.data?.filter((item: any) => {
      //         return item.is_active && checkExpiryDate(item?.expiry_date)
      //       })
      //     : []
      state.doctorData =
        action.payload?.data && action.payload?.data?.length > 0
          ? action.payload?.data?.filter((item: any) => {
              return item.is_active;
            })
          : [];

      state.totalCount = action?.payload?.total;
      const userShiftArray = action?.payload?.data?.map(
        (item: any) => item?.user_shift
      );

      // set doctors shift timing and hour start/end
      let shiftFlatArr = flatten(userShiftArray);
      let finalShiftStartEndArr: any = [];
      finalShiftStartEndArr =
        shiftFlatArr &&
        shiftFlatArr?.length > 0 &&
        shiftFlatArr.map((item: any) => {
          const {
            shift_one_start,
            shift_one_end,
            shift_two_start,
            shift_two_end,
          } = item;
          return {
            shift_one_start,
            shift_one_end,
            shift_two_start,
            shift_two_end,
          };
        });
      state.hourStartEndTime = getShiftTimeRange(finalShiftStartEndArr) || {};
      const doctorArray =
        action?.payload?.data.length > 0 &&
        action?.payload?.data.map((item: any) => {
          return {
            label: item?.doctor_name,
            id: item._id,
            user_shift: item?.user_shift,
            // backgroundColor: '#28a745',
          };
        });
      state.resources = [
        {
          label: "Doctors",
          value: "_id",
          dataSource: doctorArray || [],
        },
      ];
    });
    builder.addCase(getAllDoctors.rejected, (state, error) => {
      state.loading = false;
      state.doctorLoading = false;
    });
    // get all appointments
    builder.addCase(getAllDoctorAppointments.pending, (state) => {
      // state.loading = true
      // state.dataSource = []
    });
    builder.addCase(getAllDoctorAppointments.fulfilled, (state, action) => {
      state.loading = false;
      // state.appointmentsData = action?.payload
      const allAppointments =
        action?.payload &&
        action?.payload.length > 0 &&
        action.payload.map((item: any) => {
          return item?.appointments;
        });
      const finalAppointmentsArr =
        allAppointments && allAppointments.length > 0
          ? flatten(allAppointments) || []
          : [];
      const doctorsAppointArr =
        finalAppointmentsArr &&
        finalAppointmentsArr.length > 0 &&
        finalAppointmentsArr.map((item: any) => {
          const timeIn = item.time_in.split(":");
          const timeDuration = parseInt(item.time_duration);
          const dateStart = new Date(item.appointment_date);
          dateStart.setHours(parseInt(timeIn[0]));
          dateStart.setMinutes(parseInt(timeIn[1]));
          const dateEnd = new Date(dateStart.getTime() + timeDuration * 60000);
          const appointmentWithStautus = state?.colorSchemeData?.find(
            (colorScheme) => colorScheme?.label === item?.status
          );
          return {
            label: `${item?.pnt_user_name}  ${
              item?.pnt_emr_no ? `File no. - ${item?.pnt_emr_no}` : ""
            }`,
            _id: item?.doctor_id,
            dateStart: dateStart /*dateStart.toISOString() */,
            dateEnd:
              dateEnd /* dateEnd.toISOString()  "#B11313"  "#FFA009" "#02BF90" */,
            backgroundColor: appointmentWithStautus?.bgColor || "#5936F1",
            color: appointmentWithStautus?.colorCode || "#5936F1",
            appt_status: item?.status,
            appt_id: item?._id,
            patient_name: item?.pnt_user_name,
            file_no: item?.pnt_emr_no,
            appointment_date: item?.appointment_date,
            // patient_id: item?.pnt_user_id,
            patient_id: item?.pnt_id,
            patient_phone: item?.pnt_user_phone,
            problem_description: item?.problem_description,
            room_id: item?.room_id ?? undefined,
            doctor: item?.dct_user_name,
            room: item.room_name ?? "",
            // repeat: {
            //   repeatFreq: 'weekly',
            //   repeatInterval: 2,
            //   repeatOn: [0, 2, 5],
            //   repeatEnd: new Date(currentYear, currentMonth + 2, 24),
            // },
          };
        });
      state.dataSource = doctorsAppointArr || [];
    });
    builder.addCase(getAllDoctorAppointments.rejected, (state, error) => {
      // state.loading = false
      state.doctorLoading = false;
    });

    // get all appointments With Dates
    builder.addCase(getAllDoctorAppointmentsWithDate.pending, (state) => {
      state.loading = true;
      // state.dataSource = []
    });
    builder.addCase(
      getAllDoctorAppointmentsWithDate.fulfilled,
      (state, action) => {
        state.loading = false;
        // state.appointmentsData = action?.payload
        const allAppointments =
          action?.payload &&
          action?.payload.length > 0 &&
          action.payload.map((item: any) => {
            return item?.appointments;
          });
        const finalAppointmentsArr =
          allAppointments && allAppointments.length > 0
            ? flatten(allAppointments) || []
            : [];
        const doctorsAppointArr =
          finalAppointmentsArr &&
          finalAppointmentsArr.length > 0 &&
          finalAppointmentsArr.map((item: any) => {
            const timeIn = item.time_in.split(":");
            const timeDuration = parseInt(item.time_duration);
            const dateStart = new Date(item.appointment_date);
            dateStart.setHours(parseInt(timeIn[0]));
            dateStart.setMinutes(parseInt(timeIn[1]));
            const dateEnd = new Date(
              dateStart.getTime() + timeDuration * 60000
            );
            const appointmentWithStautus = state?.colorSchemeData?.find(
              (colorScheme) => colorScheme?.label === item?.status
            );
            return {
              label: `${item?.pnt_user_name}  ${
                item?.pnt_emr_no ? `File no. - ${item?.pnt_emr_no}` : ""
              }`,
              _id: item?.doctor_id,
              dateStart: dateStart /*dateStart.toISOString() */,
              dateEnd:
                dateEnd /* dateEnd.toISOString()  "#B11313"  "#FFA009" "#02BF90" */,
              backgroundColor: appointmentWithStautus?.bgColor || "#5936F1",
              color: appointmentWithStautus?.colorCode || "#5936F1",
              appt_status: item?.status,
              appt_id: item?._id,
              patient_name: item?.pnt_user_name,
              file_no: item?.pnt_emr_no,
              appointment_date: item?.appointment_date,
              // patient_id: item?.pnt_user_id,
              patient_id: item?.pnt_id,
              patient_phone: item?.pnt_user_phone,
              problem_description: item?.problem_description,
              room_id: item?.room_id ?? undefined,
              doctor: item?.dct_user_name,
              room: item.room_name ?? "",
              // repeat: {
              //   repeatFreq: 'weekly',
              //   repeatInterval: 2,
              //   repeatOn: [0, 2, 5],
              //   repeatEnd: new Date(currentYear, currentMonth + 2, 24),
              // },
            };
          });
        state.dataSource = doctorsAppointArr || [];
      }
    );
    builder.addCase(
      getAllDoctorAppointmentsWithDate.rejected,
      (state, error) => {
        state.loading = false;
        state.doctorLoading = false;
      }
    );

    // get avaible slots
    builder.addCase(getAvailableSlots.pending, (state) => {
      // state.loading = true
      state.availbleSlots = [];
    });
    builder.addCase(getAvailableSlots.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.slots && action?.payload?.slots?.length > 0) {
        let tempArray = action?.payload?.slots.map(
          (item: any, index: number) => {
            const startTime = item;
            const endTime = moment(startTime, "HH:mm")
              .add(15, "minutes")
              .format("HH:mm");
            const label = `${startTime} to ${endTime}`;

            return {
              label,
              value: item,
              selected: true,
            };
          }
        );
        state.availbleSlots = tempArray || [];
      } else {
        state.availbleSlots = [];
      }
    });
    builder.addCase(getAvailableSlots.rejected, (state, error) => {
      state.loading = false;
    });
    // get recurring available slots
    builder.addCase(getRecurringAvailableSlots.pending, (state) => {
      state.loading = true;
      state.availbleSlots = [];
      state.recurringAvailableSlots = [];
    });
    builder.addCase(getRecurringAvailableSlots.fulfilled, (state, action) => {
      let appointmentDuration: any =
        action?.meta?.arg?.payloadData?.requestData?.recurring_details
          ?.duration || 30;

      state.loading = false;
      if (action?.payload?.slots && action?.payload?.slots?.length > 0) {
        let tempArray = action?.payload?.slots.map(
          (item: any, index: number) => {
            const startTime = item?.time;
            const endTime = moment(startTime, "HH:mm")
              .add(appointmentDuration, "minutes")
              .format("HH:mm");
            const label =
              startTime && endTime ? `${startTime} to ${endTime}` : "-";

            return {
              label,
              date: item?.date,
              day: item?.day,
              value: item?.time,
              duration: item?.duration,
              selected: false,
            };
          }
        );
        state.recurringAvailableSlots = tempArray || [];
      } else {
        state.recurringAvailableSlots = [];
      }
    });
    builder.addCase(getRecurringAvailableSlots.rejected, (state, error) => {
      state.loading = false;
    });
    // add booking confirmation
    builder.addCase(bookingConfirmation.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(bookingConfirmation.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(bookingConfirmation.rejected, (state, error) => {
      state.loading = false;
    });

    // get all rooms

    // GET ALL BRANCH

    builder.addCase(getAllRoomsForCalnedar.pending, (state) => {});
    builder.addCase(getAllRoomsForCalnedar.fulfilled, (state, action) => {
      state.doctorLoading = false;
      state.doctorLoading = false;
      state.hasMorePage = action.payload?.hasMorePages ?? false;
      state.totalCount = action?.payload?.total;
      state.doctorData =
        action.payload?.data && action.payload?.data?.length > 0
          ? action.payload?.data?.filter((item: any) => {
              return item.is_active;
            })
          : [];
      const roomArray =
        action?.payload?.data.length > 0 &&
        action?.payload?.data
          .filter((item: any) => item.is_active)
          .map((item: any) => {
            return {
              label: item?.room_name,
              id: item._id,
            };
          });
      state.resources = [
        {
          label: "Rooms",
          value: "_id",
          dataSource: roomArray || [],
        },
      ];
    });
    builder.addCase(getAllRoomsForCalnedar.rejected, (state, error) => {
      state.doctorLoading = false;
      state.doctorLoading = false;
      state.hasMorePage = false;
    });

    builder.addCase(getAllRoomsForCalnedarDrodown.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getAllRoomsForCalnedarDrodown.fulfilled,
      (state, action) => {
        state.allRoomsList = action.payload?.data;
        state.loading = false;
      }
    );
    builder.addCase(getAllRoomsForCalnedarDrodown.rejected, (state, error) => {
      state.loading = false;
    });

    // get all physio appointments
    builder.addCase(getAllPhysioAppointments.pending, (state) => {
      // state.loading = true
    });
    builder.addCase(getAllPhysioAppointments.fulfilled, (state, action) => {
      state.loading = false;
      // state.appointmentsData = action?.payload
      const allAppointments =
        action?.payload &&
        action?.payload.length > 0 &&
        action.payload.map((item: any) => {
          return item?.appointments;
        });
      const finalAppointmentsArr =
        allAppointments && allAppointments.length > 0
          ? flatten(allAppointments) || []
          : [];
      const roomAppointArr =
        finalAppointmentsArr &&
        finalAppointmentsArr.length > 0 &&
        finalAppointmentsArr.map((item: any) => {
          const timeIn = item.time_in.split(":");
          const timeDuration = parseInt(item.time_duration);
          const dateStart = new Date(item.appointment_date);
          dateStart.setHours(parseInt(timeIn[0]));
          dateStart.setMinutes(parseInt(timeIn[1]));
          const dateEnd = new Date(dateStart.getTime() + timeDuration * 60000);
          const appointmentWithStautus = state?.colorSchemeData?.find(
            (colorScheme) => colorScheme?.label === item?.status
          );
          return {
            label: `${item?.pnt_user_name}  ${
              item?.pnt_emr_no ? `File no. - ${item?.pnt_emr_no}` : ""
            }`,
            _id: item?.room_id,
            dateStart: dateStart /*dateStart.toISOString() */,
            dateEnd:
              dateEnd /* dateEnd.toISOString()  "#B11313"  "#FFA009" "#02BF90" */,
            backgroundColor: appointmentWithStautus?.bgColor || "#5936F1",
            color: appointmentWithStautus?.colorCode || "#5936F1",
            appt_status: item?.status,
            appt_id: item?._id,
            patient_name: item?.pnt_user_name,
            file_no: item?.pnt_emr_no,
            appointment_date: item?.appointment_date,
            patient_id: item?.pnt_id,
            patient_phone: item?.pnt_user_phone,
            problem_description: item?.problem_description,
            room_id: item?.room_id ?? undefined,
            doctor: item?.dct_user_name,
            room: item.room_name ?? "",
          };
        });
      state.dataSource = roomAppointArr || [];
    });
    builder.addCase(getAllPhysioAppointments.rejected, (state, error) => {
      // state.loading = false
      state.doctorLoading = false;
    });

    // get all physio appointments with Date
    builder.addCase(getAllPhysioAppointmentsWithDate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getAllPhysioAppointmentsWithDate.fulfilled,
      (state, action) => {
        state.loading = false;
        // state.appointmentsData = action?.payload
        const allAppointments =
          action?.payload &&
          action?.payload.length > 0 &&
          action.payload.map((item: any) => {
            return item?.appointments;
          });
        const finalAppointmentsArr =
          allAppointments && allAppointments.length > 0
            ? flatten(allAppointments) || []
            : [];
        const roomAppointArr =
          finalAppointmentsArr &&
          finalAppointmentsArr.length > 0 &&
          finalAppointmentsArr.map((item: any) => {
            const timeIn = item.time_in.split(":");
            const timeDuration = parseInt(item.time_duration);
            const dateStart = new Date(item.appointment_date);
            dateStart.setHours(parseInt(timeIn[0]));
            dateStart.setMinutes(parseInt(timeIn[1]));
            const dateEnd = new Date(
              dateStart.getTime() + timeDuration * 60000
            );
            const appointmentWithStautus = state?.colorSchemeData?.find(
              (colorScheme) => colorScheme?.label === item?.status
            );
            return {
              label: `${item?.pnt_user_name}  ${
                item?.pnt_emr_no ? `File no. - ${item?.pnt_emr_no}` : ""
              }`,
              _id: item?.room_id,
              dateStart: dateStart /*dateStart.toISOString() */,
              dateEnd:
                dateEnd /* dateEnd.toISOString()  "#B11313"  "#FFA009" "#02BF90" */,
              backgroundColor: appointmentWithStautus?.bgColor || "#5936F1",
              color: appointmentWithStautus?.colorCode || "#5936F1",
              appt_status: item?.status,
              appt_id: item?._id,
              patient_name: item?.pnt_user_name,
              file_no: item?.pnt_emr_no,
              appointment_date: item?.appointment_date,
              patient_id: item?.pnt_id,
              patient_phone: item?.pnt_user_phone,
              problem_description: item?.problem_description,
              room_id: item?.room_id ?? undefined,
              doctor: item?.dct_user_name,
              room: item.room_name ?? "",
            };
          });
        state.dataSource = roomAppointArr || [];
      }
    );
    builder.addCase(
      getAllPhysioAppointmentsWithDate.rejected,
      (state, error) => {
        state.loading = false;
        state.doctorLoading = false;
      }
    );

    // get doctor unavailabilty
    builder.addCase(getDocUnavailability.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getDocUnavailability.fulfilled, (state, action) => {
      state.loading = false;
      state.docUnavailabilty = action.payload?.data || [];
    });
    builder.addCase(getDocUnavailability.rejected, (state, error) => {
      state.loading = false;
    });

    // add unavailability
    builder.addCase(createUnavailablity.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createUnavailablity.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(createUnavailablity.rejected, (state, error) => {
      state.loading = false;
    });

    // update unavailability
    builder.addCase(updateUnavailablity.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateUnavailablity.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(updateUnavailablity.rejected, (state, error) => {
      state.loading = false;
    });

    // delete unavailability
    builder.addCase(deleteUnavailablity.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteUnavailablity.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(deleteUnavailablity.rejected, (state, error) => {
      state.loading = false;
    });
  },
});

export const {
  clearData,
  clearSlotData,
  setAppointmentsSlots,
  setSelectedSlots,
  setAvialbleSlotsPayload,
  setRecurringSelectedSlots,
  setRecurringAppointmentsSlots,
  addColorSchemeData,
  setCurrentViewDate,
  setCalendarStartEndTime,
  setRoomCalendarDate,
  setPaginationDoctorData,
  setFilterDoctorList,
  setAllDoctorList,
  setSelectedBranch
} = appointmentSlice.actions;
export default appointmentSlice.reducer;
