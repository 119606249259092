import { t } from 'i18next'
import { DeleteIcon, EditIcon } from '../../../components/common/svg-components'
import { colors } from '../../../constants/color'
import styles from './vouchers.module.scss'

export const voucherTableHeaderData: any = [
  {
    Header: t('DocUploadHeader.ACTION'),
    Cell: (props: any) => {
      return (
        <div className={styles.voucherTableActions}>
          <DeleteIcon
            fillColor={colors.grey4}
            customClass={styles.deleteIcon}
            handleClick={() => props.onClicks(props?.row?.original?.entry_id)}
          />
          <EditIcon
            fillColor={colors.grey4}
            customClass={styles.deleteIcon}
            handleClick={() => props?.onRowClick(props?.row?.original)}
          />
        </div>
      )
    },
  },
  {
    Header: t('Voucher.ACCOUNT_CODE'),
    Cell: (props: any) => {
      return <span>{props?.row?.original?.accounts_data?.code_id ?? '-'}</span>
    },
  },

  {
    Header: t('Voucher.ACCOUNT_NAME'),
    Cell: (props: any) => {
      return <span>{props?.row?.original?.accounts_data?.name ?? '-'}</span>
    },
  },
  {
    Header: t('Voucher.DEBIT'),
    accessor: 'debit_amount',
  },
  {
    Header: t('Voucher.CREDIT'),
    accessor: 'credit_amount',
  },
  {
    Header: t('Voucher.COST_CENTER'),
    Cell: (props: any) => {
      return (
        <span
          className={styles.voucherCostCenter}
          onClick={() => props.onClick(props?.row?.original)}
        >
          {t('Common.View')}
        </span>
      )
    },
  },
  {
    Header: t('Voucher.ENTRY_REMARKS'),
    Cell: (props: any) => {
      return (
        <span>
          {props?.row?.original?.entry_remarks
            ? props?.row?.original?.entry_remarks
            : '-'}
        </span>
      )
    },
  },
  
]
export const costCenterTableHeader: any = [
  {
    Header: t('Voucher.COST_CENTER'),
    accessor: 'label',
  },
  {
    Header: t('Voucher.COST_CENTER_VALUE'),
    Cell: (props: any) => {
      const values = props?.row?.original?.values
      return (
        <>
          {values && values?.length > 0
            ? values?.map((item: any, index: number) => {
                return (
                  <p key={`cc-key${index}`}>
                    {index + 1}. {item?.label_values}
                  </p>
                )
              })
            : '-'}
        </>
      )
    },
  },
]
