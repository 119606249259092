import { createSlice } from '@reduxjs/toolkit'
import { IVoucherState } from '../../../interfaces/apiInterface'
import {
  addVoucher,
  getVoucher,
  getAccountNumber,
  getreferenceDoc,
  getvoucherData,
  deletevoucherData,
  updatevoucherData,
  submitVoucherData,
  getvoucherNo,
  getVoucherLog,
  getAllBranch,
  getAllDepartment,
} from './vouchersAsynActions'

export const initialState: IVoucherState = {
  loading: false,
  CostView: [],
  addVoucherName: {},
  addVoucherData: [],
  addVoucherListDataObject: {},
  getVoucherData: [],
  voucherListData: [],
  getVoucherName: {},
  updateVoucherData: {},
  deleteVoucherData: {},
  submitAllVoucherData: {},
  getAccountNum: {},
  getReferenceDoc: {},
  getVoucherNum: {},
  voucherLogData: [],
  branchData: [],
  departmentData: [],
  isLoggedin: false,
  costCenterList: [],
}

export const vouchersSlice = createSlice({
  name: 'vouchersSlice',
  initialState,
  reducers: {
    setAddVoucherData: (state, action) => {
      state.addVoucherData = [
        ...state.addVoucherData,
        action.payload.payloadData.requestData,
      ]
    },
    emptyAddVoucherData: (state) => {
      state.addVoucherData = []
    },
    setDeleteVoucherData: (state, action) => {
      state.addVoucherData = state.addVoucherData.filter(
        (data: any, id: number) => id !== action.payload
      )
    },
    clearSubmitVoucherData: (state) => {
      state.addVoucherData = []
    },
    clearVoucherNum: (state) => {
      state.getVoucherNum = {}
    },
    clearVoucherListData: (state) => {
      state.voucherListData = []
    },
    clearCostCenterData: (state) => {
      state.costCenterList = []
    },

    setCostCenterData: (state: any, action) => {
      state.costCenterList = [...state.costCenterList, action.payload]
    },

    setEditCostCenterData: (state: any, action) => {
      state.costCenterList = action.payload
    },

    removeCostCenterData: (state: any, action) => {
      state.costCenterList = action.payload
    },
    clearVoucherData: (state: any) => {
      state.getVoucherData = [];
    },
  },
  extraReducers: (builder) => {
    builder
      // Add Voucher Name
      .addCase(addVoucher.pending, (state) => {
        state.loading = true
        state.addVoucherName = {}
      })
      .addCase(addVoucher.fulfilled, (state, action) => {
        state.loading = false
        state.addVoucherName = action.payload
      })
      .addCase(addVoucher.rejected, (state, action) => {
        state.loading = false
        state.addVoucherName = {}
      })

    builder
      // Delete Voucher Data
      .addCase(deletevoucherData.pending, (state) => {
        state.loading = true
        state.deleteVoucherData = {}
      })
      .addCase(deletevoucherData.fulfilled, (state, action) => {
        state.loading = false
        state.deleteVoucherData = action.payload
      })
      .addCase(deletevoucherData.rejected, (state, action) => {
        state.loading = false
        state.deleteVoucherData = {}
      })

    builder
      // Update Voucher Data
      .addCase(updatevoucherData.pending, (state) => {
        state.loading = true
        state.updateVoucherData = {}
      })
      .addCase(updatevoucherData.fulfilled, (state, action) => {
        state.loading = false
        state.updateVoucherData = action.payload
      })
      .addCase(updatevoucherData.rejected, (state, action) => {
        state.loading = false
        state.updateVoucherData = {}
      })

    // Get Voucher Name
    builder
      .addCase(getVoucher.pending, (state) => {
        state.loading = true
        state.getVoucherName = {}
      })
      .addCase(getVoucher.fulfilled, (state, action) => {
        state.loading = false
        state.getVoucherName = action.payload
      })
      .addCase(getVoucher.rejected, (state, action) => {
        state.loading = false
        state.getVoucherName = {}
      })
    builder
      // Get Voucher Data
      .addCase(getvoucherData.pending, (state) => {
        state.loading = true
        state.getVoucherData = {}
      })
      .addCase(getvoucherData.fulfilled, (state, action) => {
        state.loading = false
        state.getVoucherData = action.payload.data
        state.voucherListData = action.payload.data
      })
      .addCase(getvoucherData.rejected, (state, action) => {
        state.loading = false
        state.getVoucherData = {}
      })

    builder
      // Get  Account No
      .addCase(getAccountNumber.pending, (state) => {
        state.loading = true
        state.getAccountNum = {}
      })
      .addCase(getAccountNumber.fulfilled, (state, action) => {
        state.loading = false
        state.getAccountNum = action.payload
      })
      .addCase(getAccountNumber.rejected, (state, action) => {
        state.loading = false
        state.getAccountNum = {}
      })

    // GET ALL BRANCH
    builder.addCase(getAllBranch.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getAllBranch.fulfilled, (state, action) => {
      state.loading = false
      state.branchData = action.payload?.data
    })
    builder.addCase(getAllBranch.rejected, (state, error) => {
      state.loading = false
    })
    // GET DEPARTMENT
    builder.addCase(getAllDepartment.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getAllDepartment.fulfilled, (state, action) => {
      state.loading = false
      state.departmentData = action.payload?.data
    })
    builder.addCase(getAllDepartment.rejected, (state, error) => {
      state.loading = false
    })

    builder
      // Get Refrerence Doc
      .addCase(getreferenceDoc.pending, (state) => {
        state.loading = true
        state.getReferenceDoc = {}
      })
      .addCase(getreferenceDoc.fulfilled, (state, action) => {
        state.loading = false
        state.getReferenceDoc = action.payload
      })
      .addCase(getreferenceDoc.rejected, (state, action) => {
        state.loading = false
        state.getReferenceDoc = {}
      })

    builder
      //Submit Voucher
      .addCase(submitVoucherData.pending, (state) => {
        state.loading = true
        state.submitAllVoucherData = {}
      })
      .addCase(submitVoucherData.fulfilled, (state, action) => {
        state.loading = false
        state.submitAllVoucherData = action.payload
      })
      .addCase(submitVoucherData.rejected, (state, action) => {
        state.loading = false
        state.submitAllVoucherData = {}
      })
    builder
      //get Voucher no
      .addCase(getvoucherNo.pending, (state) => {
        state.loading = true
        state.getVoucherNum = {}
      })
      .addCase(getvoucherNo.fulfilled, (state, action) => {
        state.loading = false
        state.getVoucherNum = action.payload
      })
      .addCase(getvoucherNo.rejected, (state, action) => {
        state.loading = false
        state.getVoucherNum = {}
      })
    // get Voucher log
    builder
      .addCase(getVoucherLog.pending, (state) => {
        state.loading = true
        state.voucherLogData = []
      })
      .addCase(getVoucherLog.fulfilled, (state, action) => {
        state.loading = false
        state.voucherLogData = action.payload?.data
      })
      .addCase(getVoucherLog.rejected, (state, action) => {
        state.loading = false
        state.voucherLogData = []
      })
  },
})

export const {
  // setCostView,
  clearVoucherNum,
  setAddVoucherData,
  setDeleteVoucherData,
  clearSubmitVoucherData,
  clearVoucherListData,
  emptyAddVoucherData,
  clearCostCenterData,
  setCostCenterData,
  removeCostCenterData,
  setEditCostCenterData,
  clearVoucherData
} = vouchersSlice.actions

export default vouchersSlice.reducer
