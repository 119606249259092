import { createSlice } from "@reduxjs/toolkit";
import { IwhatsUp } from "../../../interfaces/apiInterface";
import {
  checkWhatsupConfigurationsDetails,
  createWappiTemplates,
  getWaapiTemplates,
  getWhatsupDetails,
  updateWhatsUpConfigurationsDetails,
} from "./whatsupAsyncActions";

export const initialState: IwhatsUp = {
  isLoading: false,
  message: "",
  waapiTemplates:[]
};

export const whatsUpSlice = createSlice({
  name: "whatsUpSlice",
  initialState,
  reducers: {},
  extraReducers(builder) {
    // Check
    builder.addCase(checkWhatsupConfigurationsDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      checkWhatsupConfigurationsDetails.fulfilled,(state, action) => {
        state.isLoading = false;
      }
    );
    builder.addCase(
      checkWhatsupConfigurationsDetails.rejected,
      (state, error) => {
        state.isLoading = false;
      }
    );

    // Get
    builder.addCase(getWhatsupDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getWhatsupDetails.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(getWhatsupDetails.rejected, (state, error) => {
      state.isLoading = false;
    });

    // Update

    builder.addCase(updateWhatsUpConfigurationsDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      updateWhatsUpConfigurationsDetails.fulfilled,
      (state, action) => {
        state.isLoading = false;
      }
    );
    builder.addCase(
      updateWhatsUpConfigurationsDetails.rejected,
      (state, error) => {
        state.isLoading = false;
      }
    );

    // create waapi template
    builder.addCase(createWappiTemplates.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      createWappiTemplates.fulfilled,
      (state, action) => {
        state.isLoading = false;
      }
    );
    builder.addCase(
      createWappiTemplates.rejected,
      (state, error) => {
        state.isLoading = false;
      }
    );

    // get waapi tempalte

    builder.addCase(getWaapiTemplates.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getWaapiTemplates.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.waapiTemplates = action.payload[0]?.templates
      }
    );
    builder.addCase(
      getWaapiTemplates.rejected,
      (state, error) => {
        state.isLoading = false;
      }
    );

  },
});

export const {} = whatsUpSlice.actions;
export default whatsUpSlice.reducer;
