import { FC, useEffect, useState } from 'react'
import styles from './createNewFormAddEditDialog.module.scss'
import {
  CloseIcon,
  DropDownArrowIcon,
  DropDownIcon,
} from '../../../components/common/svg-components'
import { colors } from '../../../constants/color'
import Divider from '../../../components/common/divider/Divider'
import Search from '../../../components/common/search/Search'
import DropDown from '../../../components/common/dropdown/DropDown'
import Button from '../../../components/common/button/Button'
import Select from 'react-select'
import { useNavigate } from 'react-router-dom'
import { useForm, useController, Controller } from 'react-hook-form'
import { searchableSelectStyle } from '../../../utils/utils'
import { FORM_LABEL_NAME, ROLE } from '../../../constants/constant'
import { manageCreateNewFormsValidators } from '../../../form-validators/manageCreateNewFormValidatiors'
import { components } from 'react-select'
import { trimValue } from '../../../utils/utils'
import { saveDataForFormBuilderHeader } from '../../../redux/features/create-new-form/createNewFormSlice'
import {
  getAllCreateNewFormById,
  updateCreateNewFormById,
} from '../../../redux/features/create-new-form/createNewFormAsynActions'
import { requestGenerator } from '../../../utils/payloadGenerator'
import { useAppDispatch, useAppSelector } from '../../../hooks/index'
import { useTranslation } from 'react-i18next'

interface ICreateNewFormAddEditDialog {
  selectedCreateNewFormId: any
  popData?: {}
  handleClose?: any
}

const CreateNewFormAddEditDialog: FC<ICreateNewFormAddEditDialog> = ({
  popData,
  handleClose,
  selectedCreateNewFormId,
}) => {
  const { t } = useTranslation()
  /* Dependency to navigate between pages */
  const navigate = useNavigate()
  /* Dependency to navigate between pages */

  /* Dependency to dispatch an action */
  const dispatch = useAppDispatch()
  /* Dependency to dispatch an action */

  // Dependencies for searchable select */
  const [roles, setRoles] = useState([])
  // Dependencies for searchable select */

  const [selectedCreateNewFormAddEditId, setSelectedCreateNewFormAddEditId] =
    useState()

  /* Form submission dependencies */
  const { control, handleSubmit, formState, register, reset, watch, setValue } =
    useForm({
      mode: 'all',
    })
  const form = watch()
  const { errors } = formState

  const { userRole } = useAppSelector((state) => state.roleUser)

  /* Initial API call for select list */
  useEffect(() => {
    if (userRole && userRole?.length > 0) {
      const filteredSelectListData = userRole?.map((_element: any) => {
        return {
          value: _element._id,
          label: _element.name,
        }
      })
      setRoles(filteredSelectListData)
    } else {
      setRoles([])
    }
  }, [userRole])
  /* Initial API call for select list */

  /* Initially Re-setting the data when component is loaded - Edit component */
  useEffect(() => {
    if (
      selectedCreateNewFormId &&
      selectedCreateNewFormId !== undefined &&
      selectedCreateNewFormId !== null
    ) {
      setSelectedCreateNewFormAddEditId(selectedCreateNewFormId)
      let dataToBeSent = {
        id: selectedCreateNewFormId,
      }
      dispatch(getAllCreateNewFormById(requestGenerator(dataToBeSent))).then(
        (result) => {
          const tempCreateNewFormByIdDataDetails = result?.payload

          let role = tempCreateNewFormByIdDataDetails?.role_id?.map(
            (item: any) => {
              return {
                label: item?.name,
                value: item?._id,
              }
            }
          )
          reset({
            _id: tempCreateNewFormByIdDataDetails?._id,
            name: tempCreateNewFormByIdDataDetails?.name || '',
            role_id: role,
            form: tempCreateNewFormByIdDataDetails?.form || '',
            note: tempCreateNewFormByIdDataDetails?.note || '',
            is_questionnaire_form:
              tempCreateNewFormByIdDataDetails?.is_questionnaire_form,
          })
        }
      )
    }
  }, [])
  /* Initially Re-setting the data when component is loaded - Edit component */

  /* Function definition for form submission */
  const onSubmit = (formData: any) => {
    navigate('createNewFormBuilder', {
      state: { id: selectedCreateNewFormId ?? '' },
    })
    dispatch(saveDataForFormBuilderHeader(formData))
  }
  /* Function definition for form submission */

  /* Function definition for form submission of form data only */
  const onSubmitFormData = (formData: any) => {
    const rolesArray = formData?.role_id?.map((item: any) => item?.value)
    const dataToBeSent = {
      id: formData._id,
      data: {
        name: formData?.name,
        role_id: rolesArray,
        note: formData?.note,
        form: formData?.form,
        is_questionnaire_form: formData?.is_questionnaire_form,
      },
    }

    dispatch(updateCreateNewFormById(requestGenerator(dataToBeSent))).then(
      (result) => {
        handleClose()
      }
    )
  }
  /* Function definition for form submission of form data only */

  return (
    <>
      {/* Create New Form Dialog */}
      <div className="dialog" onClick={(e) => e.stopPropagation()}>
        <div
          className={styles.mainContainer}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={styles.iconContainer}>
            <CloseIcon
              customClass={styles.closeIconStyle}
              fillColor={colors.green1}
              handleClick={handleClose}
            />
          </div>

          <header className={styles.headerContainer}>
            <span className={styles.title}>
              {selectedCreateNewFormId &&
              selectedCreateNewFormId !== '' &&
              selectedCreateNewFormId !== undefined &&
              selectedCreateNewFormId !== null
                ? t('FormBuilder.Edit Form')
                : t('FormBuilder.Create New Form')}
            </span>
            <Divider customClass={styles.dividerStyle} />
          </header>

          <form onSubmit={handleSubmit(onSubmit)}>
            <section className={styles.sectionContainer}>
              <div className="common-input-wrapper">
                <label className="common-input-wrapper__label">
                  {t('FormBuilder.Form')}
                  <span className="asterick">*</span>{' '}
                </label>
                <input
                  type="text"
                  placeholder={t('FormBuilder.Form') || 'Form'}
                  className="common-input-wrapper__input"
                  {...register(
                    'name',
                    manageCreateNewFormsValidators[FORM_LABEL_NAME]
                  )}
                  onChange={(e) => trimValue(e)}
                />
                <div className="common-input-wrapper__error-container">
                  {errors[FORM_LABEL_NAME] && (
                    <p className="dashboardFormError">
                      {errors[FORM_LABEL_NAME].message?.toString()}
                    </p>
                  )}
                </div>
              </div>

              <div className="common-input-wrapper">
                <label className="common-input-wrapper__label">
                  {t('FormBuilder.Role')}
                  <span className="asterick">*</span>
                </label>
                <div className="common-input-wrapper__searchable-select">
                  {
                    <Controller
                      name="role_id"
                      control={control}
                      render={({ field }) => {
                        return (
                          <Select
                            options={roles}
                            value={field.value}
                            onChange={(option: any) => {
                              field.onChange(option)
                              setValue('is_questionnaire_form', false)
                            }}
                            placeholder={t('FormBuilder.Role') || 'Role'}
                            components={{ DropdownIndicator }}
                            isClearable={true}
                            backspaceRemovesValue={true}
                            styles={searchableSelectStyle}
                            maxMenuHeight={200}
                            closeMenuOnSelect={false}
                            isMulti
                          />
                        )
                      }}
                      rules={{ required: true }}
                    />
                  }
                </div>
                <div className="common-input-wrapper__error-container">
                  {errors[ROLE] && (
                    <p className="dashboardFormError">
                      {manageCreateNewFormsValidators[ROLE].required}
                    </p>
                  )}
                </div>
              </div>

              <div className="common-input-wrapper">
                <label className="common-input-wrapper__label">
                  {t('FormBuilder.Note')}
                </label>
                <textarea
                  rows={5}
                  placeholder="Enter Note"
                  className="common-input-wrapper__textarea"
                  {...register('note')}
                  onChange={(e) => trimValue(e)}
                />
              </div>
              {form?.role_id &&
                form?.role_id?.length === 1 &&
                form?.role_id[0]?.label === 'RECEPTIONIST' && (
                  <div className="common-input-wrapper">
                    <label className="common-input-wrapper__label">
                      {t('FormBuilder.Questionnaire Form')}{' '}
                    </label>
                    <input
                      type="checkbox"
                      {...register('is_questionnaire_form')}
                      style={{
                        justifySelf: 'self-start',
                        height: '20px',
                        width: '20px',
                      }}
                    />
                  </div>
                )}

              <div className={styles.buttonContainer}>
                <button onClick={handleSubmit(onSubmit)}>{`${
                  selectedCreateNewFormId &&
                  selectedCreateNewFormId !== '' &&
                  selectedCreateNewFormId !== undefined &&
                  selectedCreateNewFormId !== null
                    ? t('FormBuilder.Edit Form')
                    : t('FormBuilder.Create New Form')
                }`}</button>

                {selectedCreateNewFormId &&
                  selectedCreateNewFormId !== '' &&
                  selectedCreateNewFormId !== undefined &&
                  selectedCreateNewFormId !== null && (
                    <button onClick={handleSubmit(onSubmitFormData)}>
                      {t('Common.Submit')}
                    </button>
                  )}
              </div>
            </section>
          </form>
        </div>
      </div>
      {/* Create New Form Dialog */}
    </>
  )
}
export default CreateNewFormAddEditDialog

export const DropdownIndicator = (props: any) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        {props.selectProps.menuIsOpen ? (
          <DropDownArrowIcon fillColor="#797979" />
        ) : (
          <DropDownIcon fillColor="#797979" />
        )}
      </components.DropdownIndicator>
    )
  )
}
