// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".primaryUsergroup_tableContainer__euT7i {\n  width: 90%;\n  margin: 30px auto;\n}\n.primaryUsergroup_tableContainer__euT7i .primaryUsergroup_tableHeading__w3UD2 {\n  padding-left: 20px;\n}\n.primaryUsergroup_tableContainer__euT7i .primaryUsergroup_tableColumn__m21xH {\n  padding-left: 20px;\n}", "",{"version":3,"sources":["webpack://./src/pages/manage-usergroups/usergrouplist/manage-usergroup/primary/primaryUsergroup.module.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,iBAAA;AACF;AAAE;EAEE,kBAAA;AACJ;AAKE;EAEE,kBAAA;AAJJ","sourcesContent":[".tableContainer {\n  width: 90%;\n  margin: 30px auto;\n  .tableHeading {\n    // text-align: start !important;\n    padding-left: 20px;\n    // &:last-child {\n    //   text-align: end !important;\n    //   padding-right: 20px;\n    // }\n  }\n  .tableColumn {\n    // text-align: start;\n    padding-left: 20px;\n\n    // &:last-child {\n    //   text-align: end;\n    //   padding-right: 15px;\n    // }\n  }\n  // .customClasssForViewSlotTrHead {\n  //   display: block !important;\n  // }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableContainer": "primaryUsergroup_tableContainer__euT7i",
	"tableHeading": "primaryUsergroup_tableHeading__w3UD2",
	"tableColumn": "primaryUsergroup_tableColumn__m21xH"
};
export default ___CSS_LOADER_EXPORT___;
