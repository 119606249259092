import { createSlice } from "@reduxjs/toolkit";
import { IRoleUser } from "../../../interfaces/apiInterface";
import {
  getUserRole,
  updateReportMapping,
  updateRoleStatus,
  reportPocAsync,
  addReportQuery,
  updateReportQuery,
  getReportBuilderConfigList,
  getReportBuilderList,
  getReportsList,
  getDynamicReportQueryData,
  updateReportStatusById,
  getAllPatientListsReport,
  invoiceWiseSale,
  serviceWiseSaleSummary,
  collectionsDayWiseSummary,
  doctorWiseCollection,
  serviceWiseSale,
  patientStatement,
  patientSummaryReport,
  revenueDetail,
  patientSummaryFilterUrl,
  RevenueAnylticsFilterUrl,
  patientLedgerReport,
  patientFutureAppoinmentReport,
  pharmcyMedicinesSalesSummaryReport,
} from "./roleAsynActions";
import { removeDollarSign } from "../../../utils/utils";

export const initialState: IRoleUser = {
  isLoading: false,
  userRole: [],
  error: null,
  isStatusUpdated: false,
  reportBuilderConfigData: [],
  reportBuilderData: [],
  reportTypeList: [],
  dynamicReportData: [],
  selctedBaseTableColumns: [],
  selctedChildTableColumns: [],
  selctedBaseTableOrders: [],
  reportBuilderFormValues: {},
  selectedOrderIncrement: [],
  revenueData: [],
  patientSummary: {},
  patientSummaryFilterList: [],
  patientRevenueFilterList: [],
};

export const roleSlice = createSlice({
  name: "roleuser",
  initialState,
  reducers: {
    addBaseTableColumn: (state, action) => {
      const selectedBaseColumn = { [action.payload]: `$${action.payload}` };
      const columnArray: any[] = state.selctedBaseTableColumns?.map(
        (item: any) => {
          const valueArray = Object.values(item).map((valueItem: any) =>
            removeDollarSign(valueItem)
          );
          return valueArray[0];
        }
      );
      const isExist = columnArray.some((item) => item === action.payload);
      if (!isExist) {
        state.selctedBaseTableColumns = [
          ...state.selctedBaseTableColumns,
          selectedBaseColumn,
        ];
      }
    },
    removeBaseTableColumn: (state, action) => {
      const removedBaseTableColumnData = state.selctedBaseTableColumns?.filter(
        (item: any) => Object.values(item)[0] !== `$${action.payload}`
      );
      state.selctedBaseTableColumns = removedBaseTableColumnData;
    },
    updateBaseTableColumn: (state, action) => {
      state.selctedBaseTableColumns = action.payload;
    },
    addChildTableColumn: (state, action) => {
      const selectedChildColumn = { [action.payload]: `$${action.payload}` };
      state.selctedChildTableColumns = [
        ...state.selctedChildTableColumns,
        selectedChildColumn,
      ];
    },
    removeChildTableColumn: (state, action) => {
      const removedChildTableColumnData =
        state.selctedChildTableColumns?.filter(
          (item: any) => Object.values(item)[0] !== `$${action.payload}`
        );
      state.selctedChildTableColumns = removedChildTableColumnData;
    },
    updateChildTableColumn: (state, action) => {
      state.selctedChildTableColumns = action.payload;
    },
    addBaseTableOrder: (state, action) => {
      const selectedBaseOrder = {
        [action.payload.original]: String(
          state.selectedOrderIncrement.length + 1
        ),
      };
      state.selctedBaseTableOrders = [
        ...state.selctedBaseTableOrders,
        selectedBaseOrder,
      ];
      state.selectedOrderIncrement = [
        ...state.selectedOrderIncrement,
        selectedBaseOrder,
      ];
    },
    removeBaseTableOrder: (state, action) => {
      const removedBaseTableOrdersData = state.selctedBaseTableOrders?.filter(
        (item: any) => Object.keys(item)[0] !== action.payload.original
      );
      state.selctedBaseTableOrders = removedBaseTableOrdersData;
    },
    updateBaseTableOrder: (state, action) => {
      state.selctedBaseTableOrders = action.payload;
      state.selectedOrderIncrement = action.payload;
    },
    reportBuilderFormData: (state, action) => {
      state.reportBuilderFormValues = action.payload;
    },
  },
  extraReducers(builder) {
    // SHARE QUESTIONNAIRE LINK
    builder
      .addCase(getUserRole.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserRole.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userRole = action.payload || [];
      })
      .addCase(getUserRole.rejected, (state, error) => {
        state.isLoading = false;
        state.userRole = [];
        state.error = error;
      })
      .addCase(updateRoleStatus.pending, (state) => {
        state.isLoading = true;
        state.isStatusUpdated = false;
      })
      .addCase(updateRoleStatus.fulfilled, (state) => {
        state.isLoading = false;
        state.isStatusUpdated = true;
      })
      .addCase(updateRoleStatus.rejected, (state) => {
        state.isLoading = false;
        state.isStatusUpdated = false;
      })
      .addCase(updateReportMapping.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateReportMapping.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(updateReportMapping.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(reportPocAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(reportPocAsync.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(reportPocAsync.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(addReportQuery.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addReportQuery.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(addReportQuery.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(updateReportQuery.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateReportQuery.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(updateReportQuery.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getReportBuilderConfigList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getReportBuilderConfigList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.reportBuilderConfigData = action.payload;
      })
      .addCase(getReportBuilderConfigList.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getReportBuilderList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getReportBuilderList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.reportBuilderData = action.payload.data;
      })
      .addCase(getReportBuilderList.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getReportsList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getReportsList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.reportTypeList = action.payload;
      })
      .addCase(getReportsList.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getDynamicReportQueryData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDynamicReportQueryData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.dynamicReportData = action.payload;
      })
      .addCase(getDynamicReportQueryData.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(updateReportStatusById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateReportStatusById.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(updateReportStatusById.rejected, (state) => {
        state.isLoading = false;
      })
      // get all patient list report
      .addCase(getAllPatientListsReport.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllPatientListsReport.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(getAllPatientListsReport.rejected, (state) => {
        state.isLoading = false;
      })
      // get all invoice wise sales
      .addCase(invoiceWiseSale.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(invoiceWiseSale.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(invoiceWiseSale.rejected, (state) => {
        state.isLoading = false;
      })
      // get all service wise sales summary
      .addCase(serviceWiseSaleSummary.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(serviceWiseSaleSummary.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(serviceWiseSaleSummary.rejected, (state) => {
        state.isLoading = false;
      })
      // get all collection day wise summary
      .addCase(collectionsDayWiseSummary.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(collectionsDayWiseSummary.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(collectionsDayWiseSummary.rejected, (state) => {
        state.isLoading = false;
      })
      // get doctor wise collection summary
      .addCase(doctorWiseCollection.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(doctorWiseCollection.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(doctorWiseCollection.rejected, (state) => {
        state.isLoading = false;
      })
      // get service wise sales
      .addCase(serviceWiseSale.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(serviceWiseSale.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(serviceWiseSale.rejected, (state) => {
        state.isLoading = false;
      })
      // get patient wise collection
      .addCase(patientStatement.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(patientStatement.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(patientStatement.rejected, (state) => {
        state.isLoading = false;
      })
      // patient summary
      .addCase(patientSummaryReport.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(patientSummaryReport.fulfilled, (state, action) => {
        state.isLoading = false;
        state.patientSummary = action.payload;
      })
      .addCase(patientSummaryReport.rejected, (state) => {
        state.isLoading = false;
      })
      // revenue
      .addCase(revenueDetail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(revenueDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.revenueData = action.payload;
      })
      .addCase(revenueDetail.rejected, (state) => {
        state.isLoading = false;
      })

      // patientSummary Filter

      .addCase(patientSummaryFilterUrl.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(patientSummaryFilterUrl.fulfilled, (state, action) => {
        state.isLoading = false;
        state.patientSummaryFilterList = action.payload;
      })
      .addCase(patientSummaryFilterUrl.rejected, (state) => {
        state.isLoading = false;
        state.patientSummaryFilterList = [];
      })

      // patientRevunue Filter
      .addCase(RevenueAnylticsFilterUrl.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(RevenueAnylticsFilterUrl.fulfilled, (state, action) => {
        state.isLoading = false;
        state.patientRevenueFilterList = action.payload;
      })
      .addCase(RevenueAnylticsFilterUrl.rejected, (state) => {
        state.isLoading = false;
        state.patientRevenueFilterList = [];
      })

      // patient ledger report
      .addCase(patientLedgerReport.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(patientLedgerReport.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(patientLedgerReport.rejected, (state) => {
        state.isLoading = false;
      })

      //patient FutureAppoinmentReport
      .addCase(patientFutureAppoinmentReport.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(patientFutureAppoinmentReport.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(patientFutureAppoinmentReport.rejected, (state) => {
        state.isLoading = false;
      })

      // pharmacy medicines sales report
      .addCase(pharmcyMedicinesSalesSummaryReport.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(pharmcyMedicinesSalesSummaryReport.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(pharmcyMedicinesSalesSummaryReport.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const {
  addBaseTableColumn,
  removeBaseTableColumn,
  updateBaseTableColumn,
  addChildTableColumn,
  removeChildTableColumn,
  updateChildTableColumn,
  addBaseTableOrder,
  removeBaseTableOrder,
  updateBaseTableOrder,
  reportBuilderFormData,
} = roleSlice.actions;

export default roleSlice.reducer;
