import moment from 'moment'
import { CheckIcon, UncheckIcon } from '../../components/common/svg-components'
import { colors } from '../color'
import { useDispatch } from 'react-redux'
import {
  removeFromInvoiceList,
  setPurchaseInvoiceList,
  setTestAddText,
} from '../../redux/features/purchase-invoice/purchaseInvoiceSlice'
import { useAppSelector } from '../../hooks'
import { handlePadStart } from '../../utils/utils'
import { t } from 'i18next'

export const pendingGrnHeaderData: any = [
  {
    Header: t('RcptTableHeader.DATE'),
    Cell: ({ row }: any) => {
      const formattedDate = moment(row?.original?.receive_date).format(
        'DD MMM YYYY'
      )
      return <>{formattedDate}</>
    },
  },
  {
    Header: t('MainStore.SUPPLIER NAME'),
    accessor: 'supplier_name',
  },
  {
    Header: t('SettledInvoice.INVOICE NO'),
    // accessor: 'invoice_no',
    accessor: (row: any) => {
      let piNO = handlePadStart(row?.invoice_no)
      return row?.invoice_no ? <p>{piNO}</p> : '-'
    },
    // Cell: (props: any) => {
    //   return (
    //     <>
    //       <span>
    //         -
    //       </span>
    //     </>
    //   )
    // }
  },

  {
    Header: t('MainStore.GRN'),
    accessor: 'grn_no',
  },

  {
    Header: t('INVTableHeader.ITEM'),
    // accessor: "_item",
    Cell: (props: any) => {
      let receivedItems = props?.row?.original?.received_items
      return (
        <>
          <div>
            {receivedItems?.map((items: any) => {
              return <p>{items?.name}</p>
            })}
          </div>
        </>
      )
    },
  },

  {
    Header: t('MedicationHeader.QTY'),
    // accessor: "_qty",
    Cell: (props: any) => {
      let receivedItems = props?.row?.original?.received_items
      return (
        <>
          {receivedItems?.map((item: any) => {
            return <p>{item?.qty}</p>
          })}
        </>
      )
    },
  },
  {
    Header: t('PurchaseInvoice.INVOICE STATUS'),
    accessor: 'invoice_status',
  },

  {
    Header: t('InvoiceService.AMOUNT'),
    accessor: 'total_amount',
  },

  {
    Header: t('AllAttachmentHeader.SELECT'),
    // accessor: "status",
    Cell: ({ row }: any) => {
      const dispatch = useDispatch()
      const { addTestText } = useAppSelector((state) => state.purchaseInvoice)

      const handleImageSelection = (item: any) => {
        dispatch(
          setPurchaseInvoiceList({
            ...item,
            po_no: row?.original?.po_id,
            received_items: item?.received_items?.map((items: any) => {
              return {
                ...items,
                discount: items?.discount ?? 0,
                netAmount: items?.total_amount,
              }
            }),
          })
        )
      }

      const handleAdd = (id: any) => {
        dispatch(setTestAddText(id))
      }

      const handleRemovce = (id: any) => {
        dispatch(removeFromInvoiceList(id))
      }

      return (
        <>
          <div onClick={() => handleAdd(row?.original?._id)}>
            {!addTestText.includes(row?.original?._id) ? (
              <UncheckIcon
                fillColor={colors.grey1}
                handleClick={() => handleImageSelection(row?.original)}
              />
            ) : (
              <CheckIcon
                fillColor={colors.green1}
                handleClick={() => handleRemovce(row?.original?._id)}
              />
            )}
          </div>
        </>
      )
    },
  },
]

export const settledGrnHeaderData: any = [
  {
    Header: t('RcptTableHeader.DATE'),
    Cell: ({ row }: any) => {
      const formattedDate = moment(row?.original?.receive_date).format(
        'DD MMM YYYY'
      )
      return <>{formattedDate}</>
    },
  },
  {
    Header: t('MainStore.SUPPLIER NAME'),
    accessor: 'supplier_name',
  },
  {
    Header: t('SettledInvoice.INVOICE NO'),
    // accessor: 'invoice_no',
    accessor: (row: any) => {
      let piNO = row?.invoice_no;//handlePadStart(row?.invoice_no)
      return row?.invoice_no ? <p>{piNO}</p> : '-'
    },
  },

  {
    Header: t('MainStore.GRN'),
    accessor: 'grn_no',
  },

  {
    Header: t('INVTableHeader.ITEM'),
    // accessor: "_item",
    Cell: (props: any) => {
      let receivedItems = props?.row?.original?.received_items
      return (
        <>
          <div>
            {receivedItems?.map((items: any) => {
              return <p>{items?.name}</p>
            })}
          </div>
        </>
      )
    },
  },

  {
    Header: t('MedicationHeader.QTY'),
    // accessor: "_qty",
    Cell: (props: any) => {
      let receivedItems = props?.row?.original?.received_items
      return (
        <>
          {receivedItems?.map((item: any) => {
            return <p>{item?.qty}</p>
          })}
        </>
      )
    },
  },

  {
    Header: t('PurchaseInvoice.INVOICE STATUS'),
    accessor: 'invoice_status',
  },

  {
    Header: t('InvoiceService.AMOUNT'),
    accessor: 'total_amount',
  },
]
