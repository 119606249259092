import styles from './dynamicFormLayout.module.scss'
import healthBoxLogo from '../../assets/images/healthBoxLogo.png'
import { CallIcon, EmailIcon } from '../../components/common/svg-components'
import { colors } from '../../constants/color'
import { Outlet } from 'react-router-dom'

const DynamicFormLayout = () => {
  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.backContainer}></div>
        <div className={styles.headerContainer}>
          <div className={styles.header}>
            <div className={styles.logoContainer}>
              <img
                src={healthBoxLogo}
                alt="profile_img"
                className={styles.logoStyle}
              />
              <p className={styles.title}>Medical Center</p>
            </div>
            <div className={styles.contactContainer}>
              <CallIcon fillColor={colors.blue2} />
              <p className={styles.phoneNumber}>+971 55 410 2865</p>
              <EmailIcon width={25} height={20} fillColor={colors.blue2} />
              <p className={styles.emailText}>Inquiry@medicalcenter.com</p>
            </div>
          </div>
          <Outlet />
        </div>
        <div className={styles.rightEmpty}></div>
      </div>
    </>
  )
}

export default DynamicFormLayout
