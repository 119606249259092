import { lazy } from "react";

export const LazyLandingPage = lazy(
  () => import("../../components/common/landing-page/LandingPage")
);
export const Login = lazy(() => import("../../pages/login/Login"));
export const ForgotPassword = lazy(
  () => import("../../pages/login/forgot-password/ForgotPassword")
);
export const LazyOtpVerfication = lazy(
  () => import("../../pages/login/otp-verfication/OtpVerfication")
);
export const LazyRecoveryPassword = lazy(
  () => import("../../pages/login/recovery-password/RecoveryPassword")
);
export const LazySubmitQuestionnaireLayout = lazy(
  () =>
    import(
      "../../pages/submit-questionnaire/submit-questionnaire-layout/SubmitQuestionnaireLayout"
    )
);
export const LazySubmitQuestionnaireForm = lazy(
  () =>
    import(
      "../../pages/submit-questionnaire/submit-questionnaire-form/SubmitQuestionnaireForm"
    )
);
export const LazyQuestionnaireSubmit = lazy(
  () =>
    import(
      "../../pages/submit-questionnaire/questionnaire-submit/QuestionnaireSubmit"
    )
);
export const LazySubmitOtp = lazy(
  () => import("../../pages/submit-otp/SubmitOtp")
);
export const AuthWrapper = lazy(
  () => import("../../components/app/routes/AuthWrapper")
);

export const MainLayout = lazy(
  () => import("../../pages/main-layout/MainLayout")
);

export const LazyMobileAppConfigLayout = lazy(
  () => import("../../pages/mobileapp-configuraion/MobileAppConfigLayout")
);

// *******************************************************************************

// Lazy load components
export const MobileAppAppointment = lazy(
  () =>
    import(
      "../../pages/mobileapp-configuraion/mobile-app-appointment/MobileAppAppointment"
    )
);
export const MobileAppNews = lazy(
  () =>
    import("../../pages/mobileapp-configuraion/mobile-app-news/MobileAppNews")
);
export const ManageUsersLayout = lazy(
  () => import("../../pages/manage-users/ManageUsersLayout")
);
export const ManageUsers = lazy(
  () => import("../../pages/manage-users/manage-user-grid/ManageUsers")
);
export const CreateUsers = lazy(
  () => import("../../pages/manage-users/create-user/CreateUsers")
);
export const CreateUserPrimary = lazy(
  () =>
    import(
      "../../pages/manage-users/create-user/create-user-primary/CreateUserPrimary"
    )
);
export const CreateUserSecondary = lazy(
  () =>
    import(
      "../../pages/manage-users/create-user/create-user-secondary/CreateUserSecondary"
    )
);
export const ManageUserGroupsLayout = lazy(
  () => import("../../pages/manage-usergroups/ManageUserGroupsLayout")
);
export const UserGroupList = lazy(
  () => import("../../pages/manage-usergroups/usergrouplist/UserGroupList")
);
export const ManageUserGroup = lazy(
  () =>
    import(
      "../../pages/manage-usergroups/usergrouplist/manage-usergroup/ManageUsergroup"
    )
);
export const PrimaryUserGroup = lazy(
  () =>
    import(
      "../../pages/manage-usergroups/usergrouplist/manage-usergroup/primary/PrimaryUsergroup"
    )
);
export const SecondaryUsergroup = lazy(
  () =>
    import(
      "../../pages/manage-usergroups/usergrouplist/manage-usergroup/secondary/SecondaryUsergroup"
    )
);
export const DashboardLayout = lazy(
  () => import("../../pages/dashboard/DashboardLayout")
);
export const MedicalCenterBranch = lazy(
  () => import("../../pages/dashboard/branch/MedicalCenterBranch")
);
export const BranchList = lazy(
  () => import("../../pages/dashboard/branch/branch-list/BranchList")
);
export const ManageBranch = lazy(
  () => import("../../pages/dashboard/branch/manage-branch/ManageBranch")
);
export const MedicalCenterDepartment = lazy(
  () => import("../../pages/dashboard/department/MedicalCenterDepartment")
);
export const DepartmentList = lazy(
  () =>
    import("../../pages/dashboard/department/department-list/DepartmentList")
);
export const ManageDepartment = lazy(
  () =>
    import(
      "../../pages/dashboard/department/manage-department/ManageDepartment"
    )
);
export const NotFound = lazy(
  () => import("../../components/common/not-found/NotFound")
);
export const PatientEmrLayout = lazy(
  () => import("../../pages/patient-emr/PatientEmrLayout")
);
export const PatientEmrDashboard = lazy(
  () =>
    import("../../pages/patient-emr/patient-emr-dashboard/PatientEmrDashboard")
);
export const ShareQuestionEmail = lazy(
  () =>
    import("../../pages/patient-emr/share-question-email/ShareQuestionEmail")
);
export const MainSchedular = lazy(
  () => import("../../pages/appointment/SchedularMainLayout")
);

export const ViewAppointment = lazy(
  () => import("../../pages/viewAppointment/viewAppointment")
);

export const BookingSchedularFunctional = lazy(
  () => import("../../pages/appointment/BookingFunctional")
);
export const Receptionist = lazy(
  () => import("../../pages/receptionist/Receptionist")
);
export const InvoiceLayout = lazy(
  () => import("../../pages/invoice/InvoiceLayout")
);

// *************************************************************

export const InvoiceInformation = lazy(
  () => import("../../pages/invoice/invoice-information/InvoiceInformation")
);
export const InvoiceServices = lazy(
  () => import("../../pages/invoice/invoice-services/InvoiceServices")
);
export const InvoicePayment = lazy(
  () => import("../../pages/invoice/invoice-payment/InvoicePayment")
);
export const ReceiptLayout = lazy(
  () => import("../../pages/receipt/ReceiptLayout")
);
export const Receipt = lazy(
  () => import("../../pages/receipt/receipt-landing-page/Receipt")
);
export const CreateNewFormsLayout = lazy(
  () => import("../../pages/create-new-forms/CreateNewFormsLayout")
);
export const CreateNewFormList = lazy(
  () =>
    import(
      "../../pages/create-new-forms/create-new-form-list/CreateNewFormList"
    )
);
export const CreateNewFormBuilder = lazy(
  () =>
    import(
      "../../pages/create-new-forms/create-new-form-builder/CreateNewFormBuilder"
    )
);
export const Invoice = lazy(
  () => import("../../pages/doctor-diagnosis/invoice/Invoice")
);
export const TreatmentPlanList = lazy(
  () =>
    import(
      "../../pages/doctor-diagnosis/treatment/treatment-plan-list/TreatmentPlanList"
    )
);
export const Referral = lazy(
  () => import("../../pages/doctor-diagnosis/referal/Referral")
);
export const Request = lazy(
  () => import("../../pages/doctor-diagnosis/request/Request")
);
export const Medication = lazy(
  () => import("../../pages/doctor-diagnosis/medication/Medication")
);
export const DiagnosisForm = lazy(
  () =>
    import(
      "../../pages/doctor-diagnosis/diagnosis/diagnosis-form/DiagnosisForm"
    )
);
export const DoctorDiagnosisLayout = lazy(
  () => import("../../pages/doctor-diagnosis/DoctorDiagnosisLayout")
);
export const DoctorAppointmentDashboard = lazy(
  () =>
    import(
      "../../pages/doctor-dashboard/doctor-appointments/DoctorAppointmentDashboard"
    )
);
export const LabDashboard = lazy(
  () => import("../../pages/lab-dashboard/LabDashboard")
);
export const LabRequestLayout = lazy(
  () => import("../../pages/lab-request/LabRequestLayout")
);
export const LabRequest = lazy(
  () => import("../../pages/lab-request/labRequest/LabRequest")
);
export const LabInvoiceLayout = lazy(
  () => import("../../pages/lab-invoice/LabInvoiceLayout")
);
export const LabInformation = lazy(
  () => import("../../pages/lab-invoice/lab-information/LabInformation")
);
export const LabServices = lazy(
  () => import("../../pages/lab-invoice/lab-services/LabServices")
);
export const Configuration = lazy(
  () => import("../../pages/configuration/Configuration")
);
export const JobLayout = lazy(() => import("../../pages/job/JobLayout"));
export const CreateJobs = lazy(
  () => import("../../pages/job/create-jobs/CreateJobs")
);
export const ViewJobs = lazy(
  () => import("../../pages/job/view-jobs/ViewJobs")
);
export const RadiologyDashboard = lazy(
  () => import("../../pages/radiology-dashboard/RadiologyDashboard")
);
export const RadiologyJobLayout = lazy(
  () => import("../../pages/radiology-jobs/JobLayout")
);
export const RadioLogyRequestLayout = lazy(
  () => import("../../pages/radiology-request/RadioLogyRequestLayout")
);
export const RadiologyInvoiceLayout = lazy(
  () => import("../../pages/radiology-invoice/RadiologyInvoiceLayout")
);
export const RadiologyConfiguration = lazy(
  () => import("../../pages/radiology-configuration/RadiologyConfiguration")
);

// ******************************************************************
export const RadiologyInvoicePayment = lazy(
  () =>
    import(
      "../../pages/radiology-invoice/radiology-invoice-payment/RadiologyInvoicePayment"
    )
);
export const RadiologyInvoiceService = lazy(
  () =>
    import(
      "../../pages/radiology-invoice/radiology-invoice-service/RadiologyInvoiceService"
    )
);
export const RadiologyInvoiceInformation = lazy(
  () =>
    import(
      "../../pages/radiology-invoice/radiology-invoice-information/RadiologyInvoiceInformation"
    )
);
export const RadiologyRequest = lazy(
  () =>
    import("../../pages/radiology-request/radioLogyRequest/RadioLogyRequest")
);
export const RadiologyCreateJobs = lazy(
  () => import("../../pages/radiology-jobs/create-jobs/CreateJobs")
);
export const RadiologyViewJobs = lazy(
  () => import("../../pages/radiology-jobs/view-jobs/ViewJobs")
);
export const ManageMasterTableNewtLayout = lazy(
  () => import("../../pages/manageMasterTable-New/ManageMasterTableNewLayout")
);
export const InsuranceMaster = lazy(
  () => import("../../pages/insurance-master/InsuranceMaster")
);
export const OnGoingClaims = lazy(
  () => import("../../pages/insurance/ongoing-claims/onGoingClaims")
);
export const Services = lazy(
  () => import("../../pages/PatientEmrServices/service-landing-page/Service")
);
export const ManageMasterNew = lazy(
  () =>
    import("../../pages/manageMasterTable-New/manageMasterNew/ManageMasterNew")
);
export const ManageAppoinmentStatus = lazy(
  () =>
    import(
      "../../pages/manageMasterTable-New/manageAppoinmentStatus/ManageAppoinmentStatus"
    )
);
export const ManageCategoryValues = lazy(
  () =>
    import(
      "../../pages/manageMasterTable-New/MasterCategoryValues/ManageCategoryValues"
    )
);
export const ManageAssignTag = lazy(
  () =>
    import("../../pages/manageMasterTable-New/manageAssignTag/ManageAssignTag")
);
export const LabPayment = lazy(
  () => import("../../pages/lab-invoice/lab-payment/LabPayment")
);
export const RequestLayout = lazy(
  () => import("../../pages/request/RequestLayout")
);
export const BranchStoreLayout = lazy(
  () => import("../../pages/branchstore/BranchStoreLayout")
);
export const BranchStoreDashbopard = lazy(
  () => import("../../pages/branchstore/dashboard/BranchStoreDashboard")
);
export const BranchStore = lazy(
  () => import("../../pages/branchstore/branchstore/BranchStore")
);
export const MainStoreDashboard = lazy(
  () => import("../../pages/main-store-dashboard/MainStoreDashboard")
);
export const MainStoreLayout = lazy(
  () => import("../../pages/mainstore/MainStoreLayout")
);
export const MainStore = lazy(
  () => import("../../pages/mainstore/mainstore/MainStore")
);
export const PurchaseInvoiceLayout = lazy(
  () => import("../../pages/purchase-invoice/PurchaseInvoiceLayout")
);
export const PurchaseInvoice = lazy(
  () => import("../../pages/purchase-invoice/purchase-invoice/PurchaseInvoice")
);
export const InventoryRequest = lazy(
  () => import("../../pages/request/request/Request")
);
export const DentistAppointmentDashboard = lazy(
  () =>
    import(
      "../../pages/dentist-dashboard/dentist-appointments/DentistAppointmentDashboard"
    )
);
export const DentistDiagnosisLayout = lazy(
  () => import("../../pages/dentist-diagnosis/DentistDiagnosisLayout")
);
export const IPD = lazy(() => import("../../pages/ipd/Ipd"));
export const IpdDashboard = lazy(
  () => import("../../pages/ipd/ipd-dashboard/IpdDashboard")
);
export const CreateIpdMainLayout = lazy(
  () => import("../../pages/ipd/create-ipd-main-layout/CreateIpdMainLayout")
);
export const IpdInvoiceLayout = lazy(
  () => import("../../pages/ipd-invoice/IpdInvoiceLayout")
);
export const PatientIPDInfo = lazy(
  () => import("../../pages/ipd-invoice/patient-ipd-info/PatientIPDInfo")
);
export const PatientConsumble = lazy(
  () => import("../../pages/ipd-invoice/patient-consumable/PatientConsumble")
);
export const PatientCharges = lazy(
  () => import("../../pages/ipd-invoice/patient-charges/PatientCharges")
);
export const PatientIPDServices = lazy(
  () => import("../../pages/ipd-invoice/patient-services/PatientIPDServices")
);

// **********************************************************

export const Consultation = lazy(
  () =>
    import(
      "../../pages/ipd/create-ipd-main-layout/ipd-timeline/consultation/Consultation"
    )
);
export const DentalDiagnosisForm = lazy(
  () =>
    import(
      "../../pages/dentist-diagnosis/diagnosis/diagnosis-form/DiagnosisForm"
    )
);
export const DentalMedication = lazy(
  () => import("../../pages/dentist-diagnosis/medication/Medication")
);
export const DentalRequest = lazy(
  () => import("../../pages/dentist-diagnosis/request/Request")
);
export const DentalReferral = lazy(
  () => import("../../pages/dentist-diagnosis/referal/Referral")
);
export const DentalTreatmentPlanList = lazy(
  () =>
    import(
      "../../pages/dentist-diagnosis/treatment/treatment-plan-list/TreatmentPlanList"
    )
);
export const UnitTypeMainLayout = lazy(
  () => import("../../pages/unit-type-master/UnitTypeMainLayout")
);
export const MedicalCenterWard = lazy(
  () => import("../../pages/dashboard/ward/MedicalCenterWard")
);
export const WardList = lazy(
  () => import("../../pages/dashboard/ward/ward-list/WardList")
);
export const ManageWard = lazy(
  () => import("../../pages/dashboard/ward/manage-ward/ManageWard")
);
export const MedicalCenterRoom = lazy(
  () => import("../../pages/dashboard/room/MedicalCenterRoom")
);
export const ManageRoom = lazy(
  () => import("../../pages/dashboard/room/manage-room/ManageRoom")
);
export const RoomList = lazy(
  () => import("../../pages/dashboard/room/room-list/RoomList")
);
export const MedicalCenterBed = lazy(
  () => import("../../pages/dashboard/bed/MedicalCenterBed")
);
export const ManageBed = lazy(
  () => import("../../pages/dashboard/bed/manage-bed/ManageBed")
);
export const BedList = lazy(
  () => import("../../pages/dashboard/bed/bed-list/BedList")
);
export const ManageInventoryUnitType = lazy(
  () =>
    import(
      "../../pages/manageMasterTable-New/manageInventoryUnitType/ManageInventoryUnitType"
    )
);
export const BedSchedular = lazy(
  () => import("../../pages/appointment/bed/BedSchedular")
);
export const PatientPayment = lazy(
  () => import("../../pages/ipd-invoice/patient-payment/PatientPayment")
);
export const InventoryMasterTableLayout = lazy(
  () => import("../../pages/admin-inventory-master/InventoryMasterTableLayout")
);
export const InventoryMasterTable = lazy(
  () => import("../../pages/admin-inventory-master/InventoryMasterTable")
);
export const InventoryItemTableLayout = lazy(
  () =>
    import("../../pages/inventory-item-master/InventoryItemMasterTableLayout")
);
export const InventoryItemTable = lazy(
  () => import("../../pages/inventory-item-master/InventoryItemMasterTable")
);
export const AddNewSuplier = lazy(
  () =>
    import("../../pages/admin-inventory-master/add-new-supplier/AddNewSuplier")
);
export const AddNewItem = lazy(
  () => import("../../pages/inventory-item-master/add-new-item/AddNewItem")
);
export const IpdCreateForm = lazy(
  () =>
    import(
      "../../pages/ipd/create-ipd-main-layout/ipd-create-form/IpdCreateForm"
    )
);
export const ReportBuilder = lazy(
  () => import("../../pages/report-builder/ReportBuilder")
);
// *********************************************************************
export const CallCenterDashboardLayout = lazy(
  () => import("../../pages/call-center-admin/admin/CallCenterLayout")
);
export const Call = lazy(
  () => import("../../pages/call-center-admin/admin/admin/CallCenterDashboard")
);
export const LeadLayout = lazy(
  () => import("../../pages/call-center-admin/lead/LeadLayout")
);
export const Internal = lazy(
  () => import("../../pages/call-center-admin/lead/internal/Internal")
);
export const External = lazy(
  () => import("../../pages/call-center-admin/lead/external/External")
);
export const AgentsLayout = lazy(
  () => import("../../pages/call-center-admin/agents/AgentsLayout")
);
export const CampaignsLayout = lazy(
  () => import("../../pages/call-center-admin/campaign/CampaignsLayout")
);
export const CreateCampaigns = lazy(
  () =>
    import(
      "../../pages/call-center-admin/campaign/create-campaigns/CreateCampaigns"
    )
);
export const OnGoingCampaigns = lazy(
  () =>
    import(
      "../../pages/call-center-admin/campaign/on-going-campaigns/OnGoingCampaigns"
    )
);
export const Agents = lazy(
  () => import("../../pages/call-center-admin/agents/agents/Agents")
);
export const Leads = lazy(
  () => import("../../pages/call-center-admin/lead/total-leads/Leads")
);
export const AgentDashboardLayout = lazy(
  () => import("../../pages/agent/AgentDashboardLayout")
);
export const AgentLeads = lazy(
  () => import("../../pages/agent/agent-info-table/agent-leads/page")
);
export const FollowUp = lazy(
  () => import("../../pages/agent/agent-info-table/follow-up/page")
);
// ****************************************************************
export const CrmAdminLayout = lazy(() => import("../../pages/crm-admin/page"));
export const CrmNew = lazy(
  () => import("../../pages/crm-admin/crm-new-tab/page")
);
export const CrmSent = lazy(
  () => import("../../pages/crm-admin/crm-sent-tab/page")
);

// Pharmacy
export const PharmacyDashboard = lazy(
  () => import("../../pages/pharmacy-dashboard/PharmacyDashboard")
);
export const PharmacyLayout = lazy(
  () => import("../../pages/pharmacy/PharmacyLayout")
);
export const PharmacyInfoForm = lazy(
  () => import("../../pages/pharmacy/pharmacy-info/PharmacyInfoForm")
);
export const PharmacyStore = lazy(
  () => import("../../pages/pharmacy/pharmacy-store/PharmacyStore")
);
export const PharmacyPaymentNew = lazy(
  () => import("../../pages/pharmacy/pharmacy-payment-new/PharmacyPaymentNew")
);
export const PharmacyOnhold = lazy(
  () => import("../../pages/pharmacy/pharmacy-onhold/PharmacyOnhold")
);

// Dashboard
export const ManageSpecialitiesNew = lazy(
  () =>
    import(
      "../../pages/dashboard/department/manage-specialities/manageSpecialityNew"
    )
);

// HOC
export const ErrorBoundaryHoc = lazy(
  () => import("../../components/hoc/error-boundary/ErrorBoundaryHoc")
);

// Dynamic Form
export const DynamicFormLayout = lazy(
  () => import("../../pages/dynamic-form-layout/DynamicFormLayout")
);
export const DynamicFormSubmit = lazy(
  () =>
    import(
      "../../pages/dynamic-form-layout/dynamic-form-submit/DynamicFormSubmit"
    )
);

// Reports
export const ReportMapping = lazy(
  () => import("../../pages/report-mapping/ReportMapping")
);
export const ReportBuilderLayout = lazy(
  () => import("../../pages/reports-poc/ReportBuilderLayout")
);
export const ReportBuilderList = lazy(
  () => import("../../pages/reports-poc/ReportBuilderList")
);
export const CreateReport = lazy(
  () => import("../../pages/reports-poc/CreateReport")
);

// Login
export const PrivacyPolicy = lazy(
  () => import("../../pages/login/privacy-policy/PrivacyPolicy")
);

// HR Dashboard
export const HRDashboardLayout = lazy(
  () => import("../../pages/hr-dashboard/HRDashboardLayout")
);
export const HrDashboard = lazy(
  () => import("../../pages/hr-dashboard/dashboard/HrDashboard")
);
export const Departments = lazy(
  () => import("../../pages/hr-dashboard/departments/Departments")
);

// Employees
export const EmployeesLayout = lazy(
  () => import("../../pages/employees/EmployeesLayout")
);
export const Employees = lazy(
  () => import("../../pages/employees/employees/Employees")
);
export const AddEmployee = lazy(
  () => import("../../pages/employees/add-employee/AddEmployee")
);

// Documents
export const DocumentsLayout = lazy(
  () => import("../../pages/documents/DocumnetsLayout")
);
export const Documents = lazy(
  () => import("../../pages/documents/documnets/Documents")
);
export const AddDocument = lazy(
  () => import("../../pages/documents/add-new/AddDocument")
);

// Payroll
export const PayrollLayout = lazy(
  () => import("../../pages/payroll/PayrollLayout")
);
export const Payroll = lazy(
  () => import("../../pages/payroll/payroll/Payroll")
);

// ****************************************************

export const Create = lazy(
  () => import("../../pages/payroll/creare-salary-slip/Create-salary")
);
export const EndofServices = lazy(
  () => import("../../pages/payroll/end-of-services/End-of-services")
);
export const New = lazy(() => import("../../pages/payroll/add-new/Add-new"));

// Attendance components
export const AttendaceLayout = lazy(
  () => import("../../pages/attendance/AttendanceLayout")
);
export const ManageAttendance = lazy(
  () => import("../../pages/attendance/manage-attendance/ManageAttendance")
);

// Leave Management components
export const LeaveManagementLayout = lazy(
  () => import("../../pages/leave-management/LeaveManagementLayout")
);
export const LeaveManagement = lazy(
  () => import("../../pages/leave-management/leave-management/LeaveManagement")
);
export const LeaveEncashment = lazy(
  () => import("../../pages/leave-management/leave-encashment/LeaveEncashment")
);
export const LeaveFormData = lazy(
  () =>
    import(
      "../../pages/leave-management/leave-encashment/leave-form-data/LeaveFormData"
    )
);
export const ApplyLeave = lazy(
  () => import("../../pages/leave-management/apply-leave/ApplyLeave")
);

// Account Dashboard components
export const AccountDashboardLayout = lazy(
  () => import("../../pages/account-dashboard/dashboard/DashboardLayout")
);
export const Dashboard = lazy(
  () => import("../../pages/account-dashboard/dashboard/dashboard/Dashboard")
);

// Chart of Accounts components
export const ViewChartOfAccountsLayout = lazy(
  () => import("../../pages/view-chart-of-accounts/ViewChartOfAccountsLayout")
);
export const Level2List = lazy(
  () => import("../../pages/view-chart-of-accounts/level2/Level2List")
);
export const Level3List = lazy(
  () => import("../../pages/view-chart-of-accounts/level3/Level3List")
);
export const Level4List = lazy(
  () => import("../../pages/view-chart-of-accounts/level4/Level4List")
);
export const Level5List = lazy(
  () => import("../../pages/view-chart-of-accounts/level5/Level5List")
);

// Vouchers components
export const VouchersLayout = lazy(
  () => import("../../pages/vouchers/VouchersLayout")
);
export const Vouchers = lazy(
  () => import("../../pages/vouchers/vouchers/Vouchers")
);

// Reports components
export const ReportsLayout = lazy(
  () => import("../../pages/reports/ReportsLayout")
);
export const Ledger = lazy(() => import("../../pages/reports/ledger/Ledger"));
export const TrialBalance = lazy(
  () => import("../../pages/reports/trial-balance/TrialBalance")
);
export const ProfitLoss = lazy(
  () => import("../../pages/reports/profit-loss/ProfitLoss")
);
export const BalanceSheet = lazy(
  () => import("../../pages/reports/balance-sheet/BalanceSheet")
);

// HL7 components
export const HL7Layout = lazy(() => import("../../pages/hl7/HL7Layout"));

// ***********************************

export const HL7List = lazy(() => import("../../pages/hl7/HL7List"));
export const CreateHL7 = lazy(() => import("../../pages/hl7/CreateHL7"));
export const ContactUS = lazy(
  () => import("../../pages/login/contact-us/ContactUs")
);
export const ManagePaymentModes = lazy(
  () =>
    import(
      "../../pages/manageMasterTable-New/managePaymentmodes/ManagePaymentModes"
    )
);
export const RoomSchedular = lazy(
  () => import("../../pages/appointment/room/RoomSchedular")
);
export const UnavailabilityLayout = lazy(
  () => import("../../pages/unavailability/UnavailabilityLayout")
);
export const DoctorUnavailablity = lazy(
  () => import("../../pages/unavailability/doctor/DoctorUnavailablity")
);
export const UpayConfiguration = lazy(
  () => import("../../pages/upay/UpayConfiguration")
);
export const ImportUtility = lazy(
  () => import("../../pages/import-utility/ImportUtility")
);
export const MasterImport = lazy(
  () => import("../../pages/master-import/MasterImport")
);
export const DynamicTemplateLayout = lazy(
  () => import("../../pages/dynamic_template/DynamicTemplateLayout")
);
export const DynamicTemplate = lazy(
  () =>
    import(
      "../../pages/dynamic_template/dynamic_template_grid/DynamicTemplateGrid"
    )
);
export const ManageDynamicTemplate = lazy(
  () =>
    import(
      "../../pages/dynamic_template/manage-dynamic-template/ManageDynamicTemplate"
    )
);
export const StaffPrivacyPolicy = lazy(
  () => import("../../pages/login/privacy-policy/StaffPrivacyPolicy")
);
export const Configurations = lazy(
  () => import("../../pages/configurations/Configurations")
);
export const Whatsup = lazy(
  () => import("../../pages/configurations/whatsup/Whatsup")
);
 
export const TaxConfiguration = lazy(()=> import("../../pages/configurations/tax/taxList"))
export const ComplianceDetails = lazy(()=> import("../../pages/configurations/compliance/ComplianceDetails"))

export const Wapi = lazy(
  () => import("../../pages/configurations/wapi-templates/wapi-template")
);
export const CurrencyDetails = lazy(
  () =>
    import(
      "../../pages/configurations/configurationsDetails/ConfigurationDetails"
    )
);
export const ConfigurationDetails = lazy(
  () =>
    import(
      "../../pages/configurations/configurationsDetails/ConfigurationDetails"
    )
);

// mc-admin dashboard
export const MCAdminDashbord = lazy(
  () => import("../../pages/admin-dashboard/MedicalCenterAdminDashboard")
);
