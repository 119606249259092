import { FC, useRef } from "react";
import styles from "./header.module.scss";

import {
  CalanderIcon,
  TranslationIcon,
  QrcodeImg,
  NotificationNewIcon,
  TagPatientIcon,
  FormIcon,
  KpiRevenueIcon,
  StaffChatMessageIcon,
  ReportsIcons,
} from "../../../components/common/svg-components";
import { colors } from "../../../constants/color";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LogoutModal from "../../../components/common/modal/logout-modal/LogoutModal";
import PatientActivityLogModal from "../../../pages/patient-activity-log/patient-activity-log-modal/PatientActivityLogModal";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import HeaderMenu from "../../../components/header-menu/HeaderMenu";
import Popup from "../../../components/common/popup/Popup";
import { ReportsMenuData, appointMenuData } from "../../../constants/data";
import {
  IAppointmenuHeaderMenu,
  MyRoutes,
} from "../../../interfaces/interfaces";
import QrCodeModal from "../../../components/common/modal/qr-code-modal/QrCodeModal";
import ViewAppointmentPopup from "../../../components/common/modal/view-appointment-popup/ViewAppointmentPopup";
import proactlatestlogo from "../../../assets/images/proactlatestlogo.png";
import NotificationModal from "../../../components/common/modal/notification-modal/NotificationModal";
import usePermissions from "../../../hooks/usePermissions";
import TaggedPatientModal from "../../../components/common/tagged-patient-model/TaggedPatientModal";
import TagsListModal from "../../../components/common/modal/tags_list_modal/TagsListModal";
import FormBuilderNameListModal from "../../../components/common/modal/form-builder-nameList-modal/FormBuilderNameListModal";
import PreviewFormDialog from "../../create-new-forms/preview-form-dialog/PreviewFormDialog";
import SelectImage from "../../../assets/images/Default Image.png";
import Select from "react-select";
import { setActiveRole } from "../../../redux/features/login/loginSlice";
import { getSideBarData } from "../../../redux/features/login/loginAsynActions";
import { requestGenerator } from "../../../utils/payloadGenerator";
import LaboratoryReportModal from "../../../components/common/modal/laboratory-report-modal/LaboratoryReportModal";
import ViewReportModal from "../../../components/common/modal/laboratory-report-modal/view-report/ViewReportModal";
import ViewLabReportNotes from "../../../components/common/modal/laboratory-report-modal/view-lab-notes-popup";
import { CustomModal } from "../../../components/common/custom-modal/modal";
import RadiologyReportModal from "../../../components/common/modal/radiology-report-modal/RadiologyReportModal";
import ViewRadiologyReportModal from "../../../components/common/modal/radiology-report-modal/view-report/ViewReportModal";
import ViewLRadiologyReportNotes from "../../../components/common/modal/radiology-report-modal/view-radiology-notes-popup";
import KPIRevenueModal from "../../../components/common/modal/kpi-revenue/KPIRevenue";
import TranslationMenu from "../../../components/common/translation-menu/TranslationMenu";
import HeaderPopup from "../../call-center-admin/admin/header/HeaderPopup";
import ViewInventory from "../../mainstore/view-inventory/page";
import healthBoxLogo from '../../../assets/images/healthBoxLogo.png'
import {
  DYNAMIC_FORM_LIST,
  GET_QR_CODE,
  KPI_REVENUE,
  PATIENT_ACTIVITY_LOG,
  REPORT,
  SCHEDULAR,
  TAGGED_PATIENT_MODULE,
} from "../../../config/modules";
import { filterSideBarData, hasHeaderMenuAccess } from "../../../utils/utils";
import { submitDynamicFormData } from "../../../redux/features/create-new-form/createNewFormAsynActions";
import {
  GET_SIDEBAR_TYPE,
  SUBMIT_FORM_DATA_TYPE,
} from "../../../constants/asyncActionsType";
import PatientHistoryModal from "../../doctor-diagnosis/patient_history_modal/PatientHistoryModal";
import {
  clearPatientAttachmentsData,
  clearPatientCompareImagesData,
  clearPatientDiagnosisDetailData,
  clearPatientHistoryData,
  clearPatientImagesData,
  setSelectedDiagnosisType,
} from "../../../redux/features/patient-history/patientHistorySlice";
import {
  getCompareDocumentsById,
  getDentalCompareDocumentsById,
  getPatientAttachmentsById,
  getPatientDiagnosisDetailById,
  getPatientImagesById,
} from "../../../redux/features/patient-history/patientHistoryAsyncActions";
import {
  getDentalDiagnosisByIdAction,
  getDentalPatientAttachmentsById,
  getDentalPatientImagesById,
} from "../../../redux/features/dentist-diagnosis/dentistDiagnosisAsyncActions";
import DiagnosisModal from "../../../components/common/modal/diagnosis_modal/DiagnosisModal";
import ImageViewerModal from "../../../components/common/modal/image-viewer-modal/ImageViewerModal";
import ImageZoomInOutModal from "../../../components/common/modal/image-zoom-in-out-modal/ImageZoomInOutModal";
import MedicationModal from "../../../components/common/modal/medication-modal/MedicationModal";
import { medicationModalHeaderData } from "../../../constants/table-data/medicationModalTableData";
import PatientHistoryNotesModal from "../../../components/common/modal/patient-history-notes-modal/PatientHistoryNotesModal";
import ViewDocumentsModal from "../../../components/common/modal/view-documents-modal/ViewDocumentsModal";
import { attachmentsModalHeaderData } from "../../../constants/table-data/attachmentsModalTableData";
import DocumentsViewerModal from "../../../components/common/modal/documents-viewer-modal/DocumentsViewerModal";
import ViewAllAttachmentsModal from "../../../components/common/modal/viewAll-attachments-modal/ViewAllAttachmentsModal";
import { viewAllAttachmentsModalHeaderData } from "../../../constants/table-data/viewAllAttachmentsTableData";
import { viewAllDocumentsList } from "../../dentist-diagnosis/patient_information_form/patientInformationDummyData";
import CompareDocModal from "../../../components/common/modal/compare-modal/compare-docs/CompareDocs";
import { imagesModalHeaderData } from "../../../constants/table-data/imagesModalTableData";
import ViewAllImagesModal from "../../../components/common/modal/viewAll-images-modal/ViewAllImagesModal";
import { viewAllImagesHeaderData } from "../../../constants/table-data/viewAllImagesTableData";
import CompareModal from "../../../components/common/modal/compare-modal/CompareModal";
import PatientHistoryForms from "../../../components/common/modal/patient-history-forms/PatientHistoryForm";
import { patientHistoryFormsHeader } from "../../../constants/table-data/patientHistoryFormsHeader";
import { useTranslation } from "react-i18next";
import { mainSidebarData } from "../../../constants/routesPermission/mainSidebarData";
import Loader from "../../../components/common/spinner/Loader";

interface Iheader {}
const Header: FC<Iheader> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const state = useLocation().state;
  const { userData, branchData, activeRole, sidebarData } = useAppSelector(
    (state) => state.login
  );

  const [pageTitle, setPageTitle] = useState<string>("Promed8.0");
  const { notificationListData } = useAppSelector(
    (state) => state.notifications
  );
  const { patientFormData, selectedDiagnosisType } = useAppSelector(
    (state) => state.patientHistory
  );
  const { createdDiagnosisId } = useAppSelector(
    (state) => state.doctorDiagnosis
  );
  const diagnosisSliceState = useAppSelector((state) => state.dentistDiagnosis);
  const { isLoading } = useAppSelector((state) => state.roleUser);
  const [translationMenu, settranslationMenu] = useState<boolean>(false);
  const [kpiRevenue, setKPIRevenue] = useState<boolean>(false);

  const [showPatientActivityLog, setShowPatientActivityLog] =
    useState<boolean>(false);
  const [showQrcodeModal, setShowQrcodeModal] = useState<boolean>(false);
  const [showDashModal, setShowDashModal] = useState<boolean>(false);
  const [logoutModal, setLogoutModal] = useState<boolean>(false);
  const [notificationModalOpen, setNotificationModalOpen] =
    useState<boolean>(false);
  const [taggedPatientModal, setTaggedPatientModal] = useState<boolean>(false);
  const [showSymptomsModal, setShowSymptomsModal] = useState<boolean>(false);
  const [symptomsPopupData, setSymptomsPopupData] = useState<any>({});
  const [showFormModal, setShowFormModal] = useState<boolean>(false);
  const { routeData, sidebar } = usePermissions();
  useState<boolean>(false);
  const [appointmentMenu, setAppointmentMenu] = useState<boolean>(false);
  const [viewAppointment, setViewAppointment] = useState<boolean>(false);
  const [reportMenu, setreportMenu] = useState<boolean>(false);

  const [laboratoryReportPopup, setLaboratoryReportPopup] =
    useState<boolean>(false);
  const [viewPopup, setViewPopup] = useState<boolean>(false);
  const [radiologyReportPopup, setradiologyReportPopup] =
    useState<boolean>(false);

  const [inventoryModal, setInventoryModal] = useState(false);

  const [viewRadiologyReportPopup, setViewRadiologyReportPopup] =
    useState(false);
  const [chatNotification, setChatNotification] = useState<any>(0);
  const [selectedFormDetails, setSelectedFormDetails] = useState<any>();
  const [showPreviewFormDialog, setShowPreviewFormDialog] =
    useState<boolean>(false);

  const [roleOptions, setRoleOptions] = useState([]);
  const scrollToSection = (event: any, sectionId: any) => {
    event.preventDefault();
    const section: any = document.getElementById(sectionId);
    section.scrollIntoView({ behavior: "smooth" });
  };
  const [labReport, setLabReport] = useState([]);
  const [radiologyReport, setRadiologyReport] = useState([]);
  const [showNotes, setShowNotes] = useState(false);
  const [radiologyShowNotes, setRadiologyShowNotes] = useState(false);
  const [items, setItems] = useState({});
  const [radiologyItems, setRadiologyItems] = useState({});
  const { patientDataObjectById } = useAppSelector((state) => state.patient);

  // patient history modals states

  const [showPatientHistoryModal, setShowPatientHistoryModal] =
    useState<boolean>(false);
  const [showDianosisModal, setShowDianosisModal] = useState<boolean>(false);
  const [diagnosisPopupData, setDiagnosisPopupData] = useState<any>({});
  const [diagnosisScribeNotesPopupData, setDiagnosisScribeNotesPopupData] =
    useState<any>("");
  const [diagnosisScribeImagePopupData, setDiagnosisScribeImagePopupData] =
    useState<any>("");
  const [showDianosisScribeImageModal, setShowDianosisScribeImageModal] =
    useState<boolean>(false);
  const [showDianosisScribeNotesModal, setShowDianosisScribeNotesModal] =
    useState<boolean>(false);
  const [showMedicationModal, setShowMedicationModal] =
    useState<boolean>(false);
  const [medicationPopupData, setMedicationPopupData] = useState<any>({});
  const [showPatientHistoryNotesModal, setShowPatientHistoryNotesModal] =
    useState<boolean>(false);
  const [patientHistoryNotesPopupData, setPatientHistoryNotes] = useState<any>(
    {}
  );
  const [showAttachmentsModal, setShowAttachmentsModal] =
    useState<boolean>(false);
  const [attachmentsPopupData, setAttachmentsPopupData] = useState<any>({});
  const [showSingleAttachmentModal, setShowSingleAttachmentModal] =
    useState<boolean>(false);
  const [singleAttachmentPopupData, setSingleAttachmentPopupData] =
    useState<string>("");
  const [showViewAllAttachementsModal, setShowViewAllAttachementsModal] =
    useState<boolean>(false);
  const [viewAllAttchmentnsPopupData, setViewAllAttchmentnsPopupData] =
    useState<any>({});
  const [showAttachmentsNotesModal, setShowAttachmentsNotesModal] =
    useState<boolean>(false);
  const [attachmentnsNotesPopupData, setAttachmentnsNotesPopupData] =
    useState<any>({});
  const [compareDocsModal, setCompareDocsModal] = useState<boolean>(false);
  const [
    showViewAllSingleAttachmentModal,
    setShowViewAllSingleAttachmentModal,
  ] = useState<boolean>(false);
  const [
    viewAllSingleAttachmentPopupData,
    setViewAllSingleAttachmentPopupData,
  ] = useState<any>("");
  const [singleCompareDocumentModal, setSingleCompareDocumentModal] =
    useState<boolean>(false);
  const [compareDocsModalPopupData, setCompareDocModalPopupData] =
    useState<any>("");
  const [showImagesModal, setShowImagesModal] = useState<boolean>(false);
  const [imagesPopupData, setImagesPopupData] = useState<any>({});
  const [showSingleImageModal, setShowSingleImageModal] =
    useState<boolean>(false);
  const [singleImagePopupData, setSingleImagePopupData] = useState<string>("");
  const [showViewAllImagesModal, setShowViewAllImagesModal] =
    useState<boolean>(false);
  const [viewAllImagesPopupData, setViewAllImagesPopupData] = useState<any>({});
  useState<any>("");
  const [compareImagesModal, setCompareImageModal] = useState<boolean>(false);
  const [showImagesNotesModal, setShowImagesNotesModal] =
    useState<boolean>(false);
  const [imagesNotesPopupData, setImagesNotesPopupData] = useState<any>({});
  useState<any>("");
  const [showViewAllSingleImageModal, setShowViewAllSingleImageModal] =
    useState<boolean>(false);
  const [viewAllSingleImagePopupData, setViewAllSingleImagePopupData] =
    useState<any>("");
  const [formPopupData, setFormPopupData] = useState<any>({});
  const [formPopup, setFormPopup] = useState<boolean>(false);
  const [filledFormData, setFiledFormData] = useState<any>({});
  const [previewFormPopup, setPreviewFormPopup] = useState<boolean>(false);
  const [preViewFormPopupData, setPreviewFormPopupData] = useState<any>({});
  const [historyData, setHistoryData] = useState<any>({});
  // patient history modals states

  const findActiveRoute = useCallback(
    (routes: MyRoutes[], pathname: string): void => {
      routes.forEach((item) => {
        if (pathname === item.location) {
          setPageTitle(item.header);
        }
        if (item.children.length > 0) {
          findActiveRoute(item.children, pathname);
        }
      });
    },
    []
  );

  // Find the active route based on the current location

  useEffect(() => {
    findActiveRoute(routeData || [], location.pathname);
  }, [findActiveRoute, location, routeData]);

  const handleOpenModal = () => {
    setLogoutModal(!logoutModal);
    setNotificationModalOpen(false);
    setTaggedPatientModal(false);
    setShowFormModal(false);
    settranslationMenu(false);
  };

  // open close notification modal
  const handleOpenNotificationModal = () => {
    setNotificationModalOpen(!notificationModalOpen);
    setLogoutModal(false);
    setTaggedPatientModal(false);
    setShowFormModal(false);
    settranslationMenu(false);
  };

  const handlePatientActivityModal = () => {
    setShowPatientActivityLog((prevState) => !prevState);
  };
  const handleQrcodeModal = () => {
    setShowQrcodeModal((prevState) => !prevState);
  };
  const handleDashModal = () => {
    setShowDashModal((prevState) => !prevState);
  };

  const handleTaggedPatientModalOpen = () => {
    setTaggedPatientModal(!taggedPatientModal);
    setLogoutModal(false);
    setNotificationModalOpen(false);
    setShowFormModal(false);
    settranslationMenu(false);
  };

  const handleSymptomsModalOpen = (item: any) => {
    const popupDataPayload = {
      diag_apt_date: item?.appointment_date,
      diag_symptom_tags: item?.symptom_tags,
    };
    setShowSymptomsModal(!showSymptomsModal);
    setSymptomsPopupData(popupDataPayload);
  };

  const symptomsModalClose = () => {
    setSymptomsPopupData({});
    setShowSymptomsModal((prevState) => !prevState);
  };

  const handleFormModalOpen = () => {
    setShowFormModal(!showFormModal);
    setTaggedPatientModal(false);
    setLogoutModal(false);
    setNotificationModalOpen(false);
    setLogoutModal(false);
    settranslationMenu(false);
  };

  const ref = useRef<any>();
  const notificationRef = useRef<any>();
  const viewAppointmentRef = useRef<any>();
  const laboratoryRef = useRef<any>();
  const formRef = useRef<any>();
  const translationRef = useRef<any>();
  const kpiRef = useRef<any>();
  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (logoutModal && ref.current && !ref.current.contains(e.target)) {
        setLogoutModal(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [logoutModal]);

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (
        notificationModalOpen &&
        notificationRef.current &&
        !notificationRef.current.contains(e.target)
      ) {
        setNotificationModalOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [notificationModalOpen]);

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (
        appointmentMenu &&
        viewAppointmentRef.current &&
        !viewAppointmentRef.current.contains(e.target)
      ) {
        setAppointmentMenu(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [appointmentMenu]);

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (
        reportMenu &&
        laboratoryRef.current &&
        !laboratoryRef.current.contains(e.target)
      ) {
        setreportMenu(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [reportMenu]);

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (
        showFormModal &&
        formRef.current &&
        !formRef.current.contains(e.target)
      ) {
        setShowFormModal(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showFormModal]);

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (
        translationMenu &&
        translationRef.current &&
        !translationRef.current.contains(e.target)
      ) {
        settranslationMenu(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [translationMenu]);

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (kpiRevenue && kpiRef.current && !kpiRef.current.contains(e.target)) {
        setKPIRevenue(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [kpiRevenue]);

  const handleAppOpen = () => {
    setAppointmentMenu(!appointmentMenu);
  };
  const handleNavigate = (item: IAppointmenuHeaderMenu) => {
    if (item.navigate) {
      navigate(item.navigate, { state: { notRenderSidebar: true } });
    } else {
      setViewAppointment(true);
    }
    setAppointmentMenu(false);
  };

  const handleRadiologyReportPopup = () => {
    setradiologyReportPopup(true);
  };
  const handleReport = (item: IAppointmenuHeaderMenu) => {
    if (item.onPopupOpen) {
      setradiologyReportPopup(true);
    } else {
      setLaboratoryReportPopup(true);
    }
    setreportMenu(false);
  };
  const handleReportOpen = () => {
    setreportMenu(!reportMenu);
  };
  const handleNavigateToHome = () => {
    // const { navigateTo } = navigateAfterLogin(userData?.role)
    navigate(sidebar[0]?.navigate ? sidebar[0]?.navigate : "/notfound");
  };

  const handlePreviewFormDialogClose = (_element: any) => {
    setShowPreviewFormDialog(false);
    setSelectedFormDetails("");
  };

  // set active role and dropdown options

  useEffect(() => {
    if (branchData?.rolesArray && branchData?.rolesArray?.length > 0) {
      let roles = branchData?.rolesArray?.map((item: any) => ({
        label: item.name,
        value: item._id,
      }));
      setRoleOptions(roles);
      dispatch(setActiveRole(roles[0]));
    }
  }, [branchData?.rolesArray]);

  const handleFormSubmit = ({ formData, form }: any) => {
    const reqPayload = {
      form_used_in:
        location.pathname.split("/")[1] === "patientdiagnosis"
          ? "GENERAL_DIAGNOSIS"
          : "DENTAL_DIAGNOSIS",
      diagnosis_id:
        createdDiagnosisId?.length > 0
          ? createdDiagnosisId
          : diagnosisSliceState?.createdDiagnosisId?.length > 0
          ? diagnosisSliceState?.createdDiagnosisId
          : patientFormData?.diag_id
          ? patientFormData?.diag_id
          : "",
      payload: formData?.data ? formData?.data : {},
      form_builder_id: form?._id,
    };
    if (reqPayload?.diagnosis_id) {
      dispatch(submitDynamicFormData(requestGenerator(reqPayload))).then(
        (e) => {
          if (e.type === `${SUBMIT_FORM_DATA_TYPE}/fulfilled`) {
            handlePreviewFormDialogClose("");
          }
        }
      );
    }
  };

  // function for handling Diagnosis modal
  const diagnosisModalOpen = (item: any) => {
    const payloadData = {
      diagnosis_id: item?.diag_id,
    };
    if (item?.diag_id && item?.appointment_type === "diagnosis_detail") {
      dispatch(
        getPatientDiagnosisDetailById(requestGenerator(payloadData))
      ).then((result) => {
        const diagnosisModalData = {
          ...result.payload,
          diag_apt_date: item?.convertDate,
        };
        setDiagnosisPopupData(diagnosisModalData);
        setShowDianosisModal((prevState) => !prevState);
      });
    } else if (
      item?.diag_id &&
      item?.appointment_type === "dental_diagnosis_detail"
    ) {
      dispatch(
        getDentalDiagnosisByIdAction(requestGenerator(payloadData))
      ).then((result) => {
        const diagnosisModalData = {
          ...result.payload,
          diag_apt_date: item?.convertDate,
        };
        setDiagnosisPopupData(diagnosisModalData);
        setShowDianosisModal((prevState) => !prevState);
      });
    }
  };

  const diagnosisModalClose = () => {
    dispatch(clearPatientDiagnosisDetailData());
    setDiagnosisPopupData({});
    setShowDianosisModal((prevState) => !prevState);
  };

  // function for handling symptoms modal
  const symptomsModalOpen = (item: any) => {
    const symtomsData = {
      diag_symptom_tags: item?.diag_symptom_tags,
      diag_apt_date: item?.convertDate,
    };
    setSymptomsPopupData(symtomsData);
    setShowSymptomsModal((prevState) => !prevState);
  };

  // function for handling Medication modal
  const medicationModalOpen = (item: any) => {
    const medicationData = {
      diag_medicine_prescription: item?.diag_medicine_prescription,
      diag_apt_date: item?.convertDate,
    };
    setMedicationPopupData(medicationData);
    setShowMedicationModal((prevState) => !prevState);
  };

  const medicationModalClose = () => {
    setMedicationPopupData({});
    setShowMedicationModal((prevState) => !prevState);
  };
  // function for handling Patient History notes modal
  const patientHistoryNotesModalOpen = (item: any) => {
    const patientHistoryNotesData = {
      diag_note: item?.diag_note,
      diag_apt_date: item?.convertDate,
    };
    setPatientHistoryNotes(patientHistoryNotesData);
    setShowPatientHistoryNotesModal((prevState) => !prevState);
  };

  const patientHistoryNotesModalClose = () => {
    setPatientHistoryNotes({});
    setShowPatientHistoryNotesModal((prevState) => !prevState);
  };

  // function for handling Attachments modal
  const attachmentsModalOpen = (item: any) => {
    const payloadData = {
      diagnosis_id: item?.diag_id,
    };
    if (item?.diag_id && item?.appointment_type === "diagnosis_detail") {
      dispatch(getPatientAttachmentsById(requestGenerator(payloadData))).then(
        (result) => {
          const attachmentsData = {
            docs_list: result.payload,
            diag_apt_date: item?.convertDate,
          };
          setAttachmentsPopupData(attachmentsData);
          setShowAttachmentsModal((prevState) => !prevState);
        }
      );
    } else if (
      item?.diag_id &&
      item?.appointment_type === "dental_diagnosis_detail"
    ) {
      dispatch(
        getDentalPatientAttachmentsById(requestGenerator(payloadData))
      ).then((result) => {
        const attachmentsData = {
          docs_list: result.payload,
          diag_apt_date: item?.convertDate,
        };
        setAttachmentsPopupData(attachmentsData);
        setShowAttachmentsModal((prevState) => !prevState);
      });
    }
  };

  const attachmentsModalClose = () => {
    dispatch(clearPatientAttachmentsData());
    setAttachmentsPopupData({});
    setShowAttachmentsModal((prevState) => !prevState);
  };

  // function for handling View All Attachments modal
  const viewAllAttachmentsModalOpen = (item: any) => {
    const viewAllAttachments = { viewAllDocumentsList };
    setViewAllAttchmentnsPopupData(viewAllAttachments);
    setShowViewAllAttachementsModal((prevState) => !prevState);
  };

  const viewAllAttachmentsModalClose = () => {
    dispatch(
      setSelectedDiagnosisType({ label: "General Diagnosis", value: "GENERAL" })
    );
    setViewAllAttchmentnsPopupData({});
    setShowViewAllAttachementsModal((prevState) => !prevState);
  };

  // function for handling Images modal
  const imagesModalOpen = (item: any) => {
    const payloadData = {
      diagnosis_id: item?.diag_id,
    };
    if (item?.diag_id && item?.appointment_type === "diagnosis_detail") {
      dispatch(getPatientImagesById(requestGenerator(payloadData))).then(
        (result) => {
          const imagesData = {
            docs_list: result.payload,
            diag_apt_date: item?.convertDate,
          };
          setImagesPopupData(imagesData);
          setShowImagesModal((prevState) => !prevState);
        }
      );
    } else if (
      item?.diag_id &&
      item?.appointment_type === "dental_diagnosis_detail"
    ) {
      dispatch(getDentalPatientImagesById(requestGenerator(payloadData))).then(
        (result) => {
          const imagesData = {
            docs_list: result.payload,
            diag_apt_date: item?.convertDate,
          };

          setImagesPopupData(imagesData);
          setShowImagesModal((prevState) => !prevState);
        }
      );
    }
  };

  const imagesModalClose = () => {
    dispatch(clearPatientImagesData());
    setImagesPopupData({});
    setShowImagesModal((prevState) => !prevState);
  };

  // function for handling Single Image modal
  const singleImageModalOpen = (rowData: any) => {
    setSingleImagePopupData(rowData?.img_url);
    setShowSingleImageModal((prevState) => !prevState);
  };

  const singleImageModalClose = () => {
    setSingleImagePopupData("");
    setShowSingleImageModal((prevState) => !prevState);
  };

  // function for handling View All Images modal
  const viewAllImagesModalOpen = (item: any) => {
    const viewAllImages = { viewAllDocumentsList };
    setViewAllImagesPopupData(viewAllImages);
    setShowViewAllImagesModal((prevState) => !prevState);
  };

  const viewAllImagesModalClose = () => {
    dispatch(
      setSelectedDiagnosisType({ label: "General Diagnosis", value: "GENERAL" })
    );
    setViewAllImagesPopupData({});
    setShowViewAllImagesModal((prevState) => !prevState);
  };

  const formOpenModal = (item: any) => {
    const formData = {
      ...item,
      emr_no: patientFormData?.emr_no,
      patient_id: patientFormData?.patient_id,
      patient_name: patientFormData?.patient_name,
      diag_apt_date: item?.convertDate,
    };
    setFormPopupData(formData);
    setFormPopup((prevState) => !prevState);
  };

  const formCloseModal = () => {
    setFormPopupData({});
    setFormPopup((prevState) => !prevState);
  };
  // funtion for finding modal
  const findModal = (item: any) => {
    switch (item?.headerName) {
      case "DIAGNOSIS":
        diagnosisModalOpen(item);
        break;
      case "SYMPTOMS":
        symptomsModalOpen(item);
        break;
      case "MEDICATION":
        medicationModalOpen(item);
        break;
      case "NOTES":
        patientHistoryNotesModalOpen(item);
        break;
      case "ATTACHMENTS":
        attachmentsModalOpen(item);
        break;
      case "ATTACHMENTS_HEADER":
        viewAllAttachmentsModalOpen(item);
        break;
      case "IMAGES":
        imagesModalOpen(item);
        break;
      case "IMAGES_HEADER":
        viewAllImagesModalOpen(item);
        break;
      case "FORMS":
        formOpenModal(item);
        break;
    }
  };

  // funtion for handling modal
  const handlePatientHistoryViewBtn = (item: any) => {
    findModal(item);
  };
  // funtion for handling patient history modal
  const handlePatientHistoryModalOpen = (item: any) => {
    setShowPatientHistoryModal((prevState) => !prevState);
    setHistoryData(item);
  };
  const handlePatientHistoryModalClose = () => {
    setShowPatientHistoryModal((prevState) => !prevState);
    dispatch(clearPatientHistoryData());
  };

  // function for handling Diagnosis Scribe Notes modal
  const diagnosisScribeNotesModalOpen = (item: any) => {
    setDiagnosisScribeNotesPopupData(item);
    setShowDianosisScribeNotesModal((prevState) => !prevState);
  };

  const diagnosisScribeNotesModalClose = () => {
    setDiagnosisScribeNotesPopupData("");
    setShowDianosisScribeNotesModal((prevState) => !prevState);
  };

  // function for handling Diagnosis Scribe Image modal
  const diagnosisScribeImageModalOpen = (item: any) => {
    setDiagnosisScribeImagePopupData(item);
    setShowDianosisScribeImageModal((prevState) => !prevState);
  };

  const diagnosisScribeImageModalClose = () => {
    setDiagnosisScribeImagePopupData("");
    setShowDianosisScribeImageModal((prevState) => !prevState);
  };

  // function for handling Single Attachment modal
  const singleAttachmentModalOpen = (rowData: any) => {
    setSingleAttachmentPopupData(rowData?.doc_url);
    setShowSingleAttachmentModal((prevState) => !prevState);
  };

  const singleAttachmentModalClose = () => {
    setSingleAttachmentPopupData("");
    setShowSingleAttachmentModal((prevState) => !prevState);
  };
  // function for handling Attachments notes modal
  const attachmentsNotesModalOpen = (item: any) => {
    const attachmentNotesData = {
      diag_note: item?.diag_note,
      diag_apt_date: item?.date,
    };
    setAttachmentnsNotesPopupData(attachmentNotesData);
    setShowAttachmentsNotesModal((prevState) => !prevState);
  };

  const attachmentsNotesModalClose = () => {
    setAttachmentnsNotesPopupData({});
    setShowAttachmentsNotesModal((prevState) => !prevState);
  };
  // function for handling View All Single Attachment modal
  const viewAllSingleAttachmentModalOpen = (item: any) => {
    const payloadData = {
      img_ids: [
        {
          diag_id: item?._id,
          img_id: item?.diag?.doc_id,
        },
      ],
      flag: true,
    };
    if (item?._id && selectedDiagnosisType?.value === "GENERAL") {
      dispatch(getCompareDocumentsById(requestGenerator(payloadData))).then(
        (result) => {
          const viewAllAttachmentModalData = {
            ...result.payload,
          };
          setViewAllSingleAttachmentPopupData(
            viewAllAttachmentModalData?.[0] || ""
          );
          setShowViewAllSingleAttachmentModal((prevState) => !prevState);
        }
      );
    } else if (item?._id && selectedDiagnosisType?.value === "DENTIST") {
      dispatch(
        getDentalCompareDocumentsById(requestGenerator(payloadData))
      ).then((result) => {
        const viewAllAttachmentModalData = {
          ...result.payload,
        };
        setViewAllSingleAttachmentPopupData(
          viewAllAttachmentModalData?.[0] || ""
        );
        setShowViewAllSingleAttachmentModal((prevState) => !prevState);
      });
    }
  };

  const viewAllSingleAttachmentModalClose = () => {
    dispatch(clearPatientCompareImagesData());
    setViewAllSingleAttachmentPopupData("");
    setShowViewAllSingleAttachmentModal((prevState) => !prevState);
  };

  // function for handling Single Compare Doc modal
  const singleCompareDocModalOpen = (item: any) => {
    setCompareDocModalPopupData(item);
    setSingleCompareDocumentModal((prevState) => !prevState);
  };

  const singleCompareDocModalClose = () => {
    setCompareDocModalPopupData("");
    setSingleCompareDocumentModal((prevState) => !prevState);
  };
  // function for handling Images notes modal
  const imagesNotesModalOpen = (item: any) => {
    const imageNotesData = {
      diag_note: item?.diag_note,
      diag_apt_date: item?.date,
    };
    setImagesNotesPopupData(imageNotesData);
    setShowImagesNotesModal((prevState) => !prevState);
  };

  const imagesNotesModalClose = () => {
    setImagesNotesPopupData({});
    setShowImagesNotesModal((prevState) => !prevState);
  };

  // function for handling View All Single Image modal
  const viewAllSingleImageModalOpen = (item: any) => {
    const payloadData = {
      img_ids: [
        {
          diag_id: item?._id,
          img_id: item?.diag?.img_id,
        },
      ],
      flag: false,
    };
    if (item?._id && selectedDiagnosisType?.value === "GENERAL") {
      dispatch(getCompareDocumentsById(requestGenerator(payloadData))).then(
        (result) => {
          const viewAllImageModalData = {
            ...result.payload,
          };
          setViewAllSingleImagePopupData(viewAllImageModalData?.[0] || "");
          setShowViewAllSingleImageModal((prevState) => !prevState);
        }
      );
    } else if (item?._id && selectedDiagnosisType?.value === "DENTIST") {
      dispatch(
        getDentalCompareDocumentsById(requestGenerator(payloadData))
      ).then((result) => {
        const viewAllImageModalData = {
          ...result.payload,
        };
        setViewAllSingleImagePopupData(viewAllImageModalData?.[0] || "");
        setShowViewAllSingleImageModal((prevState) => !prevState);
      });
    }
  };

  const viewAllSingleImageModalClose = () => {
    dispatch(clearPatientCompareImagesData());
    setViewAllSingleImagePopupData("");
    setShowViewAllSingleImageModal((prevState) => !prevState);
  };
  const handleFormPreview = (form: any, index: any) => {
    setPreviewFormPopup(true);
    setPreviewFormPopupData({
      form: form?.diag_forms_submitted[index]?.form,
    });
    setFiledFormData({ data: form?.diag_forms_submitted[index]?.payload_data });
  };
  const handleFormPreviewClose = (_element: any) => {
    setPreviewFormPopup(false);
    setPreviewFormPopupData({});
    setFiledFormData({});
  };
  return (
    <>
      <PreviewFormDialog
        open={showPreviewFormDialog}
        selectedFormDetails={selectedFormDetails}
        handleClose={handlePreviewFormDialogClose}
        submit={true}
        handleSubmit={(payload: any) => handleFormSubmit(payload)}
        readOnly={
          createdDiagnosisId?.length > 0
            ? false
            : diagnosisSliceState?.createdDiagnosisId?.length > 0
            ? false
            : patientFormData?.diag_id
            ? false
            : true
        }
      />
      {showPatientActivityLog && (
        <Popup
          Children={PatientActivityLogModal}
          handleClose={handlePatientActivityModal}
        />
      )}
      {showQrcodeModal && (
        <Popup Children={QrCodeModal} handleClose={handleQrcodeModal} />
      )}
      {showDashModal && (
        <Popup Children={HeaderPopup} handleClose={handleDashModal} />
      )}
      {viewAppointment && (
        <Popup
          Children={ViewAppointmentPopup}
          handleClose={() => setViewAppointment(false)}
          setModelOpenClose={setViewAppointment}
          // handleStatusSummary={() => setStatusSummary(true)}
        />
      )}
      {laboratoryReportPopup && (
        <Popup
          Children={LaboratoryReportModal}
          handleClose={() => setLaboratoryReportPopup(false)}
          handleOpen={(item: any) => (setViewPopup(true), setLabReport(item))}
          setModelOpenClose={setLaboratoryReportPopup}
        />
      )}
      {viewPopup && (
        <Popup
          Children={ViewReportModal}
          handleClose={() => setViewPopup(false)}
          setModelOpenClose={setViewPopup}
          popData={labReport}
          handleOpen={(item: any) => (setShowNotes(true), setItems(item))}
          // handleStatusSummary={() => setStatusSummary(true)}
        />
      )}
      {showNotes && (
        <Popup
          Children={ViewLabReportNotes}
          handleClose={() => setShowNotes(false)}
          popData={items}
        />
      )}

      <CustomModal
        title={t("RadioReport.Radiology Reports") || "Radiology Reports"}
        showModal={radiologyReportPopup}
        closeModal={() => setradiologyReportPopup(false)}
        width="60%"
        height="600px"
      >
        <RadiologyReportModal
          handleOpen={(item: any) => (
            setViewRadiologyReportPopup(true), setRadiologyReport(item)
          )}
        />
      </CustomModal>

      {/* kpi revenue popup */}
      <CustomModal
        title={t("KPI.KPI") || "KPI | Revenue"}
        showModal={kpiRevenue}
        closeModal={() => setKPIRevenue(false)}
        width="90%"
        // height="800px"
        customModalClass={styles.kpiModal}
      >
        <KPIRevenueModal />
      </CustomModal>
      {isLoading && <Loader />}

      {viewRadiologyReportPopup && (
        <Popup
          Children={ViewRadiologyReportModal}
          handleClose={() => setViewRadiologyReportPopup(false)}
          setModelOpenClose={setViewRadiologyReportPopup}
          handleOpen={(item: any) => (
            setRadiologyShowNotes(true), setRadiologyItems(item)
          )}
          popData={radiologyReport}
          // handleStatusSummary={() => setStatusSummary(true)}
        />
      )}
      {radiologyShowNotes && (
        <Popup
          Children={ViewLRadiologyReportNotes}
          handleClose={() => setRadiologyShowNotes(false)}
          popData={radiologyItems}
        />
      )}
      {/* tagged Patient Modal */}
      {taggedPatientModal && (
        <Popup
          Children={TaggedPatientModal}
          handleClose={handleTaggedPatientModalOpen}
          handleOpen={handleSymptomsModalOpen}
          setModelOpenClose={(item: any) => {
            handlePatientHistoryModalOpen(item);
          }}
        />
      )}
      {/* patient history modals */}
      {showPatientHistoryModal && (
        <Popup
          Children={PatientHistoryModal}
          handleClose={handlePatientHistoryModalClose}
          handleOpen={handlePatientHistoryViewBtn}
          popData={historyData}
        />
      )}
      {showDianosisModal && (
        <Popup
          Children={DiagnosisModal}
          handleClose={diagnosisModalClose}
          handleOpen={diagnosisScribeNotesModalOpen}
          setModelOpenClose={diagnosisScribeImageModalOpen}
          popData={diagnosisPopupData}
          heading={t("Common.Diagnosis") || "Diagnosis"}
        />
      )}
      {showDianosisScribeNotesModal && (
        <Popup
          Children={ImageViewerModal}
          handleClose={diagnosisScribeNotesModalClose}
          popData={diagnosisScribeNotesPopupData}
        />
      )}
      {showDianosisScribeImageModal && (
        <Popup
          Children={ImageZoomInOutModal}
          handleClose={diagnosisScribeImageModalClose}
          popData={diagnosisScribeImagePopupData}
        />
      )}
      {showPatientHistoryNotesModal && (
        <Popup
          Children={PatientHistoryNotesModal}
          handleClose={patientHistoryNotesModalClose}
          popData={patientHistoryNotesPopupData}
          heading={t("Common.Notes") || "Notes"}
        />
      )}
      {showAttachmentsModal && (
        <Popup
          Children={ViewDocumentsModal}
          handleClose={attachmentsModalClose}
          popData={attachmentsPopupData}
          heading={t("Common.Attachments") || "Attachments"}
          headerData={attachmentsModalHeaderData}
          handleOpen={singleAttachmentModalOpen}
        />
      )}
      {showSingleAttachmentModal && (
        <Popup
          Children={DocumentsViewerModal}
          handleClose={singleAttachmentModalClose}
          popData={singleAttachmentPopupData}
        />
      )}
      {showViewAllAttachementsModal && (
        <Popup
          Children={ViewAllAttachmentsModal}
          handleClose={viewAllAttachmentsModalClose}
          heading={t("Common.Attachments") || "Attachments"}
          popData={historyData?.patient_id}
          headerData={viewAllAttachmentsModalHeaderData}
          handleOpen={attachmentsNotesModalOpen}
          handleSubmit={viewAllSingleAttachmentModalOpen}
          setModelOpenClose={setCompareDocsModal}
        />
      )}
      {showAttachmentsNotesModal && (
        <Popup
          Children={PatientHistoryNotesModal}
          handleClose={attachmentsNotesModalClose}
          popData={attachmentnsNotesPopupData}
          heading={t("Common.Notes") || "Notes"}
        />
      )}
      {showViewAllSingleAttachmentModal && (
        <Popup
          Children={DocumentsViewerModal}
          handleClose={viewAllSingleAttachmentModalClose}
          popData={viewAllSingleAttachmentPopupData}
        />
      )}
      {compareDocsModal && (
        <Popup
          Children={CompareDocModal}
          handleClose={() => {
            setCompareDocsModal(false);
          }}
          handleOpen={singleCompareDocModalOpen}
          heading={t("Common.Compare") || "Compare"}
        />
      )}
      {singleCompareDocumentModal && (
        <Popup
          Children={DocumentsViewerModal}
          handleClose={singleCompareDocModalClose}
          popData={compareDocsModalPopupData}
        />
      )}
      {showImagesModal && (
        <Popup
          Children={ViewDocumentsModal}
          handleClose={imagesModalClose}
          popData={imagesPopupData}
          heading={t("ImageUpload.Images") || "Images"}
          headerData={imagesModalHeaderData}
          handleOpen={singleImageModalOpen}
        />
      )}
      {showSingleImageModal && (
        <Popup
          Children={ImageZoomInOutModal}
          handleClose={singleImageModalClose}
          popData={singleImagePopupData}
        />
      )}
      {showViewAllImagesModal && (
        <Popup
          Children={ViewAllImagesModal}
          handleClose={viewAllImagesModalClose}
          heading={t("ImageUpload.Images") || "Images"}
          popData={historyData?.patient_id}
          headerData={viewAllImagesHeaderData}
          setModelOpenClose={setCompareImageModal}
          handleOpen={imagesNotesModalOpen}
          handleSubmit={viewAllSingleImageModalOpen}
        />
      )}
      {compareImagesModal && (
        <Popup
          Children={CompareModal}
          handleClose={() => {
            setCompareImageModal(false);
          }}
          // handleOpen={singleCompareImageModalOpen}
          heading={t("Common.Compare") || "Compare"}
        />
      )}
      {showImagesNotesModal && (
        <Popup
          Children={PatientHistoryNotesModal}
          handleClose={imagesNotesModalClose}
          popData={imagesNotesPopupData}
          heading={t("Common.Notes") || "Notes"}
        />
      )}
      {showViewAllSingleImageModal && (
        <Popup
          Children={ImageZoomInOutModal}
          handleClose={viewAllSingleImageModalClose}
          popData={viewAllSingleImagePopupData}
        />
      )}
      {formPopup && (
        <Popup
          Children={PatientHistoryForms}
          handleClose={formCloseModal}
          popData={formPopupData}
          heading={t("PatientHistoryTableHeader.FORMS") || "FORMS"}
          headerData={patientHistoryFormsHeader}
          handleOpen={(form: any, index: number) =>
            handleFormPreview(form, index)
          }
        />
      )}
      <PreviewFormDialog
        open={previewFormPopup}
        selectedFormDetails={preViewFormPopupData}
        handleClose={handleFormPreviewClose}
        formData={filledFormData}
        submit={false}
        handleSubmit={(payload: any) => {}}
        readOnly={true}
      />
      {/* patient history modals */}
      {/* tagged Patient symptoms Modal */}
      {showSymptomsModal && (
        <Popup
          Children={TagsListModal}
          handleClose={symptomsModalClose}
          popData={symptomsPopupData}
          heading={t("Common.Symptoms") || "Symptoms"}
        />
      )}
      {showMedicationModal && (
        <Popup
          Children={MedicationModal}
          handleClose={medicationModalClose}
          popData={medicationPopupData}
          heading={t("Common.Medication") || "Medication"}
          headerData={medicationModalHeaderData}
        />
      )}
      <CustomModal
        title={t("ViewInventory.View Inventory") || "View Inventory"}
        showModal={inventoryModal}
        closeModal={() => setInventoryModal(false)}
        width="80dvw"
        height="85dvh"
      >
        <ViewInventory isPharmacy={false} />
      </CustomModal>

      <div className={styles.headerWithLogo} id="header_container">
        {state?.notRenderSidebar && (
          <div className={styles.sidebarContainer}>
            <img
              src={healthBoxLogo}
              alt="healthbox_logo"
              className={styles.logoStyle}
              onClick={() => handleNavigateToHome()}
            />
          </div>
        )}

        <div className={styles.mainContainer}>
          <div className={styles.headerContainer}>
            <div className={styles.roleTitleContainer}>
              <Select
                // className={styles.selectInputField}
                isSearchable={true}
                value={activeRole}
                // isClearable={true}
                options={roleOptions}
                maxMenuHeight={200}
                closeMenuOnSelect={true}
                placeholder="Select Role"
                onChange={(e: any) => {
                  dispatch(setActiveRole(e));
                  dispatch(
                    getSideBarData(requestGenerator({ role_id: e?.value }))
                  ).then((sidebarEvent) => {
                    if (sidebarEvent.type === `${GET_SIDEBAR_TYPE}/fulfilled`) {
                      const moduleIdArr = sidebarEvent.payload?.map(
                        (item: any) => item?.moduleId
                      );
                      const data = filterSideBarData(
                        moduleIdArr,
                        mainSidebarData
                      );

                      navigate(
                        data[0]?.navigateAfterLogin
                          ? data[0]?.navigateAfterLogin
                          : data[0]?.navigate
                          ? data[0]?.navigate
                          : "/"
                      );
                    }
                  });
                }}
              />
              <p className={styles.headerText}>
                {t(`Header Title.${pageTitle}`).startsWith("Header")
                  ? pageTitle
                  : t(`Header Title.${pageTitle}`)}
                {/* {t(`Header Title.${pageTitle}`)} */}
              </p>
            </div>

            {/* <div className={styles.branchContainer}>
              <p className={styles.mcName}>{userData?.mc_name}</p>
            </div> */}
            <div className={styles.profileContainer}>
              {/* <p className={styles.title}>Forms</p> */}

              {hasHeaderMenuAccess(sidebarData, TAGGED_PATIENT_MODULE) && (
                <div
                  className={styles.appointmentContainer}
                  onClick={handleTaggedPatientModalOpen}
                >
                  <TagPatientIcon />
                  <span className={styles.appointmentName}>
                    {t("TaggedPatient.Tagged Patients")}
                  </span>
                </div>
              )}
              {hasHeaderMenuAccess(sidebarData, SCHEDULAR) && (
                <div
                  className={styles.appointmentContainer}
                  onClick={() => handleAppOpen()}
                >
                  <div className={styles.inlineItems}>
                    <CalanderIcon />
                    <span className={styles.appointmentName}>
                      {t("Header.Appointment")}
                    </span>
                  </div>
                  {appointmentMenu && (
                    <HeaderMenu
                      menuData={appointMenuData}
                      handleClick={(item) => handleNavigate(item)}
                      viewRef={viewAppointmentRef}
                    />
                  )}
                </div>
              )}
              {hasHeaderMenuAccess(sidebarData, DYNAMIC_FORM_LIST) && (
                <div
                  className={styles.appointmentContainer}
                  onClick={handleFormModalOpen}
                >
                  <div>
                    <FormIcon />
                    <span className={styles.appointmentName}>
                      {t("Header.Forms")}
                    </span>
                  </div>
                  {showFormModal && (
                    <FormBuilderNameListModal
                      formBuilderRef={formRef}
                      handleOpen={setShowPreviewFormDialog}
                      selectField={setSelectedFormDetails}
                      handleClose={setShowFormModal}
                      handleInsurancePlan={() => {
                        setShowFormModal(false);
                      }}
                    />
                  )}
                </div>
              )}

              {/* dynamic access of KPI & do not remove */}
              {hasHeaderMenuAccess(sidebarData, KPI_REVENUE) && (
                <div
                  className={styles.appointmentContainer}
                  onClick={() => setKPIRevenue(!kpiRevenue)}
                >
                  <KpiRevenueIcon />
                  <span className={styles.appointmentName}>{t("KPI.KPI")}</span>
                </div>
              )}

              {/* {['DOCTOR', 'DENTIST'].includes(userData?.role) && (
                <div
                  className={styles.appointmentContainer}
                  onClick={() => setKPIRevenue(!kpiRevenue)}
                >
                  <KpiRevenueIcon />
                  <span className={styles.appointmentName}>{t('KPI.KPI')}</span>
                </div>
              )} */}

              {(location.pathname === "/mainstore" ||
                location.pathname === "/purchaseinvoice") && (
                <p
                  className={styles.inventoryName}
                  onClick={() => setInventoryModal(true)}
                >
                  {t("ViewInventory.View Inventory")}
                </p>
              )}

              {hasHeaderMenuAccess(sidebarData, REPORT) && (
                <div
                  className={styles.appointmentContainer}
                  onClick={handleReportOpen}
                >
                  <div className={styles.inlineItems}>
                    <ReportsIcons />
                    <span className={styles.appointmentName}>
                      {t("Header.Reports")}
                    </span>
                  </div>
                  {reportMenu && (
                    <HeaderMenu
                      menuData={ReportsMenuData}
                      handleClick={(onPopupOpen) => handleReport(onPopupOpen)}
                      viewRef={laboratoryRef}
                    />
                  )}
                </div>
              )}
              {hasHeaderMenuAccess(sidebarData, PATIENT_ACTIVITY_LOG) && (
                <div
                  className={styles.appointmentContainer}
                  onClick={handlePatientActivityModal}
                >
                  <div className={styles.inlineItems}>
                    <CalanderIcon />
                    <span className={styles.appointmentName}>
                      {t("Header.Patient Activity Log")}
                    </span>
                  </div>
                </div>
              )}

              {hasHeaderMenuAccess(sidebarData, GET_QR_CODE) && (
                <div
                  className={styles.qrcodeContainer}
                  onClick={handleQrcodeModal}
                >
                  <div className={styles.qrcodeImg}>
                    <QrcodeImg />
                  </div>
                  <div className={styles.qrcodeText}>
                    <span>{t("QrCode.Get QR code")}</span>
                  </div>
                </div>
              )}

              <div className={styles.profileTranslationContainer}>
                {location.pathname === "/doctor" ||
                location.pathname === "/receptionist" ||
                location.pathname === "/dentist" ? (
                  <>
                    <a href="#chat" onClick={(e) => scrollToSection(e, "chat")}>
                      <StaffChatMessageIcon fillColor="#02BF90" />
                    </a>

                    {chatNotification > 0 && (
                      <div
                        className={
                          chatNotification
                            ? styles.chatNotificationCount
                            : styles.disable
                        }
                      >
                        {chatNotification}
                      </div>
                    )}
                  </>
                ) : (
                  ""
                )}
                <div className={styles.translactionMenuContainer}>
                  <TranslationIcon
                    // fillColor={colors.grey2}
                    fillColor="#02BF90"
                    handleClick={() => settranslationMenu(!translationMenu)}
                  />
                  {translationMenu && (
                    <TranslationMenu
                      translationRef={translationRef}
                      handleClose={() => settranslationMenu(false)}
                    />
                  )}
                </div>
                <div className={styles.notiifcationConatiner}>
                  {notificationListData?.notificationCount > 0 ? (
                    <div
                      className={styles.circle}
                      onClick={handleOpenNotificationModal}
                    >
                      <p className={styles.countTextStyle}>
                        {notificationListData?.notificationCount > 99
                          ? notificationListData?.notificationCount + "+"
                          : notificationListData?.notificationCount}
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                  <NotificationNewIcon
                    customClass={styles.notiifcationIconStyle}
                    handleClick={handleOpenNotificationModal}
                    iconRef={notificationRef}
                    fillColor="#02BF90"
                  />
                </div>

                <img
                  ref={ref}
                  src={
                    branchData?.profile_pic
                      ? branchData?.profile_pic
                      : SelectImage
                  }
                  alt="profile_img"
                  onClick={handleOpenModal}
                  className={styles.profileIconStyle}
                />
              </div>
            </div>
          </div>
          <div style={{ position: "relative" }}>
            {logoutModal && <LogoutModal logoutRef={ref} />}
          </div>
          {notificationModalOpen && (
            <NotificationModal
              setNotificationModalOpen={setNotificationModalOpen}
              notiRef={notificationRef}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Header;
