import { EditIcon } from "../../components/common/svg-components/index";
export const reportCategory = [
  {
    label: "Doctor",
    value: "doctor",
    reportType: [
      {
        label: "Dept report",
        value: "DEPT REPORT",
      },
      {
        label: "Doctor report",
        value: "Doctor REPORT",
      },
    ],
  },
  {
    label: "Patient",
    value: "patient",
    reportType: [
      {
        label: "Patient-source",
        value: "Patient-source",
      },
      {
        label: "Patient-outstanding",
        value: "patient-outstanding",
      },
    ],
  },
  {
    label: "Sales",
    value: "sales",
    reportType: [
      {
        label: "Card-summary",
        value: "card-summary",
      },
    ],
  },
  // {
  //   label: "Appointnment",
  //   value: "appointnment",
  //   reportType: [],
  // },
  // {
  //   label: "Inventory",
  //   value: "inventory",
  //   reportType: [],
  // },
  // {
  //   label: "Room",
  //   value: "room",
  //   reportType: [],
  // },
  // {
  //   label: "Insurance",
  //   value: "insurance",
  //   reportType: [],
  // },
];

export const cards = [
  {
    label: "Select All",
    value: "",
  },
  {
    label: "Cash",
    value: "cash",
  },
  {
    label: "Master Card",
    value: "master",
  },
  {
    label: "Credit",
    value: "credit",
  },
  {
    label: "Knet",
    value: "knet",
  },
  {
    label: "Pay with Advance",
    value: "Pay with Advance",
  },
  {
    label: "Loyalty points",
    value: "Loyalty points",
  },
  {
    label: "Visa",
    value: "visa",
  },
  {
    label: "Upay",
    value: "upay",
  },
  {
    label: "AMERICAN EXPRESS",
    value: "AMERICAN EXPRESS",
  },
];

export const reportType = [
  {
    label: "Dept report",
    value: "DEPT REPORT",
    type: "Sales",
  },
  {
    label: "Doctor report",
    value: "Doctor REPORT",
    type: "Sales",
  },
  {
    label: "Patient-source",
    value: "Patient-source",
    type: "Patient",
  },
  {
    label: "Patient-outstanding",
    value: "patient-outstanding",
    type: "Patient",
  },
  {
    label: "Card-summary",
    value: "card-summary",
    type: "Collection",
  },
  // {
  //   label: "Sales Summary",
  //   value: "sales-summary",
  //   type: "Sales",
  // },
  // {
  //   label: "Insurance Invoice Summary",
  //   value: "insurance-invoice-summary",
  //   type: "Sales",
  // },
  {
    label: "Patient List",
    value: "patient-list",
    type: "Patient",
  },
  {
    label: "Invoice Wise Sales",
    value: "invoice-wise-sales",
    type: "Sales",
  },
  {
    label: "Service Wise Sales Summary",
    value: "service-wise-sales-summary",
    type: "Sales",
  },
  {
    label: "Service Wise Sales",
    value: "service-wise-sales",
    type: "Sales",
  },
  {
    label: "Patient Statement",
    value: "patient-statement",
    type: "Patient",
  },
  {
    label: "Day Wise Collection Summary",
    value: "day-wise-collection-summary",
    type: "Collection",
  },
  {
    label: "Collection Summary",
    value: "collection-summary",
    type: "Collection",
  },
  {
    label: "User Wise Collection Summary",
    value: "user-wise-collection-summary",
    type: "Collection",
  },
  {
    label: "Patient Ledger Transaction",
    value: "patient-ledger-transaction",
    type: "Patient",
  },
  {
    label: "Future Appointment",
    value: "future-appointment",
    type: "Patient",
  },
  {
    label: "Pharmacy Sales Report",
    value: "pharmacy-sales-summary",
    type: "Sales",
  },
  {
    label: "IPD Sales Report",
    value: "ipd-sales-summary",
    type: "Sales",
  },
  {
    label: "Laboratory Sales Report",
    value: "laboratory-sales-summary",
    type: "Sales",
  },
  {
    label: "Radiology Sales Report",
    value: "radiology-sales-summary",
    type: "Sales",
  },
];

export const typeOfReport = [
  {
    label: "Pre Build Report",
    value: "PRE_BUILD_REPORT",
  },
  {
    label: "Dynamic Report",
    value: "DYNAMIC_REPORT",
  },
];

export const docTypes = ["pdf", "txt", "plain"];
export const imgTypes = ["png", "jpeg", "jpg"];

export const trialTetsdata = [
  {
    id: 2,
    name: "liabilities",
    level_2: [
      {
        account_code: 201,
        name: "current_libilities",
        voucher: [
          {
            account_code: 20101,
            name: "al-sayafi company",
            credit: 100,
            debit: 100,
            closing_credit: 100,
            closing_debit: 0,
            opening_credit: 100,
            opening_debit: 0,
          },
          {
            account_code: 20102,
            name: "Advance technology company KFMC",
            credit: 100,
            debit: 100,
            closing_credit: 100,
            closing_debit: 0,
            opening_credit: 100,
            opening_debit: 0,
          },
          {
            account_code: 20103,
            name: "Noon medical company",
            credit: 100,
            debit: 100,
            closing_credit: 100,
            closing_debit: 0,
            opening_credit: 100,
            opening_debit: 0,
          },
        ],
      },
      {
        account_code: 202,
        name: "Test Voucher Liabilities",
        voucher: [
          {
            account_code: 20201,
            name: "DISCOUNT_DIAGNOSIS",
            credit: 100,
            debit: 100,
            closing_credit: 100,
            closing_debit: 0,
            opening_credit: 100,
            opening_debit: 0,
          },
          {
            account_code: 20202,
            name: "DISCOUNT_DENTAL_DIAGNOSIS",
            credit: 100,
            debit: 100,
            closing_credit: 100,
            closing_debit: 0,
            opening_credit: 100,
            opening_debit: 0,
          },
          {
            account_code: 20203,
            name: "DISCOUNT_IPD",
            credit: 100,
            debit: 100,
            closing_credit: 100,
            closing_debit: 0,
            opening_credit: 100,
            opening_debit: 0,
          },
        ],
      },
    ],
  },
  {
    id: 3,
    name: "Assets",
    level_2: [
      {
        account_code: 301,
        name: "FIXED ASSETS",
        voucher: [
          {
            account_code: 30101,
            name: "Equipment & devices",
            credit: 100,
            debit: 100,
            closing_credit: 100,
            closing_debit: 0,
            opening_credit: 100,
            opening_debit: 0,
          },
          {
            account_code: 30102,
            name: "New Test",
            credit: 100,
            debit: 100,
            closing_credit: 100,
            closing_debit: 0,
            opening_credit: 100,
            opening_debit: 0,
          },
        ],
      },
      {
        account_code: 302,
        name: "CURRENT ASSETS",
        voucher: [
          {
            account_code: 30201,
            name: "KFMC XXXXXXXXXX8836",
            credit: 100,
            debit: 100,
            closing_credit: 100,
            closing_debit: 0,
            opening_credit: 100,
            opening_debit: 0,
          },
          {
            account_code: 30201,
            name: "KFMC XXXXX1010001456",
            credit: 100,
            debit: 100,
            closing_credit: 100,
            closing_debit: 0,
            opening_credit: 100,
            opening_debit: 0,
          },
        ],
      },
    ],
  },
];
