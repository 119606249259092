import { FC } from "react";
import styles from "./tableV2.module.scss";
import * as React from "react";
import {
  Column,
  TableOptions,
  useGlobalFilter,
  useSortBy,
  useTable,
} from "react-table";
import { Cols } from "../../../../interfaces/interfaces";
import { SortDown } from "../../svg-components";
import { t } from "i18next";

interface ITable {
  tableHeaderData: Column<any>[];
  tableRowData: any;
  handleNotes?: any;
  customClassForTh?: string;
  customClassForTd?: string;
  customClasssForTrHead?: string;
  customClasssForViewSlotTrHead?: string;
  customClasssForTrBody?: string;
  handleToggle?: (id: number, isToggled: boolean) => void;
  handleClick?: any;
  handleEmrRecord?: any;
  handleRow?: any;
  setModelOpenClose?: any;
  handleRowClick?: any;
  active?: any;
  setActive?: any;
  colSpan?: any;
  tableCustomClass?: any;
  hadleData?: any;
  customoTableMsg?: any;
  ref?: any;
  handleClicks?: any;
  showTableFoot?: boolean;
  children?: React.ReactNode;
  isViewChartHoverRow?: boolean;
  isPostCondition?: boolean
}

const 
TableV2: FC<ITable> = ({
  tableHeaderData,
  tableRowData,
  handleNotes,
  customClasssForTrBody,
  customClasssForTrHead,
  customClasssForViewSlotTrHead,
  customClassForTd,
  customClassForTh,
  handleClick,
  handleRow,
  setModelOpenClose,
  handleRowClick,
  active,
  setActive,
  colSpan,
  hadleData,
  tableCustomClass,
  customoTableMsg,
  ref,
  handleClicks,
  showTableFoot = false,
  children,
  isViewChartHoverRow,
  isPostCondition,
}) => {
  const data: Cols[] = tableRowData;
  const columns: Column<any>[] = tableHeaderData;
  const options: TableOptions<any> = {
    data,
    columns,
  };
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(options, useGlobalFilter, useSortBy);

  return (
    <>
      <table
        {...getTableProps()}
        className={[styles.table, tableCustomClass].join(" ")}
        ref={ref}
      >
        <thead>
          {headerGroups?.map((headerGroup: any) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              className={[styles.tableHeadingRow, customClasssForTrHead].join(
                " "
              )}
            >
              {headerGroup.headers.map((column: any) => {
                return (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={[styles.tableHeading, customClassForTh].join(
                      " "
                    )}
                  >
                    <div
                      className={[
                        styles.headerContent,
                        customClasssForViewSlotTrHead,
                      ].join(" ")}
                    >
                      {column.render("Header", {
                        onClick: handleClick,
                      })}
                      {column.canSort && (
                        <span style={{ paddingLeft: "7px", cursor: "pointer" }}>
                          <SortDown />
                        </span>
                      )}
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        {rows?.length > 0 ? (
          <tbody {...getTableBodyProps()}>
            {rows?.map((row: any) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className={
                    active === row?.original?._id
                      ? styles.activeRow
                      : isViewChartHoverRow
                      ? row?.original?.isFinalLevel
                        ? [(styles.tableRow, customClasssForTrBody)]
                        : styles.rowHoverStyle
                      : [(styles.tableRow, customClasssForTrBody)]
                  }
                  onClick={() =>
                    handleRow &&
                    handleRow(row?.original?._id, row.original, row)
                  }
                >
                  {row?.cells?.map((cell: any, i: number) => {
                    return (
                      <React.Fragment key={i}>
                        {cell.column.Header === "NOTES"   ? (
                          <td style={{ cursor: "pointer", color: " #0e26a3" }}>
                            {cell?.row?.original?.problem_description ? (
                              <>
                                <p
                                  className={styles.view}
                                  onClick={(e) =>
                                    handleNotes(
                                      cell?.row?.original?.problem_description
                                    )
                                  }
                                >
                                  {t("Common.View")}
                                </p>
                              </>
                            ) : (
                              <>
                                <p className={styles.notView}>-</p>
                              </>
                            )}
                          </td>
                        ) 
                        : cell.column.Header === "ملحوظات" ? (
                          <td style={{ cursor: "pointer", color: " #0e26a3" }}>
                            {cell?.row?.original?.problem_description ? (
                              <>
                                <p
                                  className={styles.view}
                                  onClick={(e) =>
                                    handleNotes(
                                      cell?.row?.original?.problem_description
                                    )
                                  }
                                >
                                  {t("Common.View")}
                                </p>
                              </>
                            ) : (
                              <>
                                <p className={styles.notView}>-</p>
                              </>
                            )}
                          </td>
                        ) : (
                          <td
                            {...cell.getCellProps()}
                            className={[
                              styles.tableColumn,
                              customClassForTd,
                            ].join(" ")}
                          >
                            {cell.accessor
                              ? row[cell.accessor]
                              : cell.render("Cell", {
                                  onClick: handleClick,
                                  onPopClose: setModelOpenClose,
                                  onRowClick: handleRowClick,
                                  getPropsData: hadleData,
                                  onClicks: isPostCondition ? undefined : handleClicks,
                                })}
                          </td>
                        )}
                      </React.Fragment>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td colSpan={colSpan ?? 12} className={styles.noReordFoundText}>
                {customoTableMsg
                  ? customoTableMsg
                  : t("Common.No records found")}
              </td>
            </tr>
          </tbody>
        )}
        {showTableFoot && <tfoot>{children}</tfoot>}
      </table>
    </>
  );
};

export default TableV2;
